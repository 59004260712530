import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import styles from './styles';

interface Props {
  children: any;
  classes?: any;
  className?: string;
}

const FormContainer = (props: Props) => {
  let className = props.classes.blocFormulaire;
  if (props.className) {
    className += ' ' + props.className;
  }
  return (
    <Grid container className={className} spacing={2}>
      {props.children}
    </Grid>
  );
};

export default withStyles(styles)(FormContainer);
