import { KeycloakError } from 'keycloak-js';
import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteProps } from 'react-router';
import { Types } from 'Types';
import { Selectors as DossierLocationSelectors } from '../../redux/DossierLocation';
import { Selectors as UtilisateursSelectors } from '../../redux/Utilisateurs';
import { Profil } from '../../services/Api/swagger';
import * as authService from '../../services/Authentification/authentification';
import { isPathAuthorizedForUser } from '../../services/Route';
import { history } from '../../helpers/history';
import Spinner from '../spinner/Spinner';

interface CustomProps {
  infoProfil?: Profil;
  refBien?: string;
  statutDossier?: string;
}

type Props = RouteProps & CustomProps;

interface State {
  // Etat du statut d'authentification
  authenticated: boolean;
  // Erreur Keycloak
  error?: KeycloakError;
  unauthorized: boolean; // Accès non autorisé
  shouldRedirectToDashboard: boolean; // Doit-on rediriger vers le dashboard
}

/**
 * Composant définissant une route privée, c'est-à-dire nécessitant une authentification.
 */
class PrivateRoute extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      error: undefined,
      shouldRedirectToDashboard: false,
      unauthorized: false,
    };
  }

  componentDidMount() {
    if (!authService.isAuthenticated()) {
      authService
        .loginIfRequired()
        .then(authenticated => {
          if (authenticated) {
            // Si on n'a pas de profil de chargé et qu'on n'est pas sur l'url du dashboard
            // on a une redirection pour déclencher le chargement des profils
            if (
              this.props.path !== '/dashboard' &&
              this.props.infoProfil === undefined
            ) {
              this.setState({
                shouldRedirectToDashboard: true,
              });
            } else {
              const authorized = isPathAuthorizedForUser(
                this.props.path,
                this.props.infoProfil,
                this.props.refBien,
                this.props.statutDossier,
              );
              this.setState({
                authenticated,
                shouldRedirectToDashboard: false,
                unauthorized: !authorized,
              });
            }
          } else {
            this.setState({ authenticated });
          }
        })
        .catch(error => {
          this.setState({ error });
        });
    } else {
      this.setState({
        authenticated: true,
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.path !== this.props.path) {
      if (this.state.authenticated) {
        const authorized = isPathAuthorizedForUser(
          this.props.path,
          this.props.infoProfil,
          this.props.refBien,
          this.props.statutDossier,
        );
        this.setState({
          unauthorized: !authorized,
        });
      }
    }
  }
  render() {
    const {
      authenticated,
      error,
      shouldRedirectToDashboard,
      unauthorized,
    } = this.state;
    if (error) {
      return <div>Impossible de se connecter !</div>;
    } else if (shouldRedirectToDashboard) {
      const refPath = new URLSearchParams(history.location.search).get("refDossier");
      // Si l'on vient d'un lien qui contient l'url d'un dossier (après authentification)
      // on l'ajoute au dashboard pour rediriger une fois le profil utilisateur chargé
      if (refPath) {
        // eslint-disable-next-line no-restricted-globals
        location.href = '/dashboard?refDossier=' + refPath;
      } else {
        // eslint-disable-next-line no-restricted-globals
        location.href = '/dashboard';
      }
      return null;
    } else if (authenticated) {
      if (unauthorized) {
        // eslint-disable-next-line no-restricted-globals
        location.href = '/accesInterdit';
        return null;
      } else {
        const { component, ...rest } = this.props;
        const Component: typeof React.Component = component as any;
        // tslint:disable-next-line: jsx-no-lambda
        return <Route {...rest} render={props => <Component {...props} />} />;
      }
    } else {
      return <Spinner />;
    }
  }
}

const mapStateToProps = (state: Types.RootState) => ({
  infoProfil: UtilisateursSelectors.getProfilUtilisateur(state),
  // acces page situation perso et situation pro
  refBien: DossierLocationSelectors.getSelectedDossier(state),
  statutDossier: DossierLocationSelectors.getStatutDossier(state),
});

export default connect(mapStateToProps)(PrivateRoute);
