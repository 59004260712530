import { Theme } from '@material-ui/core/styles';
import {
  couleurPrimaire,
  erreur,
  gris2,
  yellow,
} from '../../../../theme';

const styles = (theme: Theme) =>
({
  synthese: {
    '& p': {
      fontSize: 12,
      margin: 0,
    },
    '& strong': {
      marginLeft: 4,
    },
    '& h4': {
      position: 'relative',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      display: 'flex',
      '& svg': {
        marginRight: 4,
      },
      '& img': {
        left: -34,
        position: 'absolute',
        top: -4,
      },
    },
    '& h5': {
      fontSize: 12,
      textTransform: 'uppercase',
      marginTop: 8,
      marginBottom: 0,
    },
  },
  statut: {
    marginBottom: 12,
    '& p': {
      marginRight: 4,
      fontSize: 13,
    },
    '& div': {
      fontSize: 13,
    },
  },
  messageStatut: {
    color: gris2,
    '& small': {
      marginLeft: 4,
      fontStyle: 'italic',
    },
  },
  loyermaxConseille: {
    '& p': {
      marginRight: 4,
      fontSize: 13,
    },
    '& div': {
      fontSize: 13,
    },
    '& a': {
      color: gris2,
      marginLeft: 4,
    },
    '& label': {
      marginLeft: '4px',
      '& div': {
        border: '2px solid ' + couleurPrimaire,
      },
    },
  },
  textRight: {
    textAlign: 'right',
    flex: 1,
    '& h4': {
      justifyContent: 'flex-end',
    },
  },
  candidats: {
    marginTop: 16,
    '& button': {
      marginLeft: 24,
    },
    '& .bouton-garant': {
      marginLeft: 0,
      marginTop: 24,
    },
  },
  candidat: {
    background: 'white',
    padding: 24,
    paddingLeft: 56,
  },
  blocCandidat: {
    marginTop: 24,
  },
  noMargin: {
    '&$strong': {
      margin: 0,
    },
  },
  modifyIcon: {
    marginLeft: 12,
    position: 'initial !important',
  },
  contentChips: {
    flex: 1,
  },
  contentBien: {
    paddingLeft: 36,
    flex: 1,
  },
  pieces: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 14,
    marginRight: 4,
  },
  iconValid: {
    color: theme.palette.primary.main,
  },
  iconFailed: {
    color: erreur,
  },
  iconWait: {
    color: yellow,
  },
  errorPiece: {
    margin: '8px 12px 12px 18px',
    width: 'fit-content',
    maxWidth: '100%',
  },
  statutNetheos: {
    display: 'flex',
    fontSize: 12,
    marginBottom: 12,
    '& > div': {
      marginLeft: 4,
    },
  },
  downloadArchive: {
    paddingBottom: 12,
    '& button': {
      marginLeft: '4px !important',
      color: gris2,
      fontSize: 12,
      marginRight: 8,
    },
  },
  ajoutColoc: {
    marginBottom: 12,
  },
  containerStatut: {
    borderLeft: '5px solid ' + theme.palette.secondary.main,
    paddingLeft: 12,
    marginBottom: 24,
  },
  buttonPieces: {
    padding: 0,
    minWidth: 0,
    margin: 0,
    '&:hover, &:focus, &:active': {
      background: 'transparent',
    },
  },
  modifyStatut: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 24,
    '& small': {
      paddingRight: 16,
    },
    '& > div': {
      flex: 1,
    },
    '& select': {
      width: 222,
      height: 30,
    },
    '& button': {
      marginLeft: 16,
    },
  },
} as any);

export default styles;
