import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  ActionCreators,
  Selectors,
} from '../../../../redux/NousContacterRedux';
import { Contact } from '../../../../services/Api/swagger';
import ContactForm, { ContactFormData } from './FormComponent';

interface Props {
  postNousContacter(params: Contact): void;
  fetching?: boolean;
  cookiesValid: boolean;
}

interface State {
  captchaValue?: string;
  errorCaptcha?: string;
}
class ContactPageForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      captchaValue: undefined,
    };
  }

  handleSubmit = (contactForm: ContactFormData) => {
    if (this.state.captchaValue) {
      contactForm.response = this.state.captchaValue;
      this.props.postNousContacter(contactForm);
      if (window.MatomoTagManager) {
        window._paq.push([
          'trackEvent',
          'Envoi formulaire',
          'Clic - CTA',
          'Contact- Envoi formulaire',
        ]);
      }
    } else {
      this.setState({
        errorCaptcha: "Veuillez prouver que vous n'êtes pas un robot",
      });
    }
  };

  handleCaptchaValue = value => {
    this.setState({
      captchaValue: value,
    });
  };
  render() {
    return (
      <div id="contact">
        <ContactForm
          onSubmit={this.handleSubmit}
          fetching={this.props.fetching}
          errorCaptcha={this.state.errorCaptcha}
          onCaptchaValue={this.handleCaptchaValue}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fetching: Selectors.getNousContacterFetching(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  postNousContacter: (params: Contact) =>
    dispatch(ActionCreators.postNousContacter.request(params)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactPageForm);
