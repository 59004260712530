import { Theme } from '@material-ui/core/styles';
import { gris2, gris3 } from '../../theme';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
  ({
    buttonModify: {
      color: gris2,
      marginLeft: 16,
    },
    modifyStatuts: {
      '& h6': {
        textTransform: 'uppercase',
      },
      '& .MuiDialog-paperWidthXs': {
        background: gris3,
      },
      '& .MuiFormControl-root': {
        marginBottom: 16,
      },
      '& .MuiInputBase-root': {
        background: 'white',
      },
    },
    actionButton: {
      padding: '0 24px 24px 24px',
      '& button': {
        marginLeft: 24,
      },
    },
  } as any);
// tslint:enable: quotemark

export default styles;
