import { withStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';

interface IProps {
  classes?: any;
  cookiesValid: boolean;
}

class BlocDevis extends React.Component<IProps> {
  onClickEvent = () => {
    if (window.MatomoTagManager) {
      window._paq.push([
        'trackEvent',
        'Contact',
        'Clic - CTA',
        'En savoir plus - Essayer Vertuloo gratuitement',
      ]);
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.containerTexte}>
          <div className={classes.contentTexte}>
            <p>Si vous souhaitez</p>
            <ul>
              <li>
                <strong>
                  Sécuriser votre processus de traitement des dossiers
                </strong>{' '}
                de vos candidats locataires,
              </li>
              <li>
                <strong>Élargir le nombre de candidats</strong> locataires
                éligibles à une GLI,
              </li>
              <li>
                <strong>Réduire la vacance locative</strong>,
              </li>
              <li>
                <strong>
                  être certain que 100% des locataires éligibles sont garantis
                </strong>
              </li>
            </ul>
            <p>
              Alors <span className="vert">vertuloo</span>{' '}
              <strong>est fait pour vous !</strong>
            </p>
          </div>
        </div>{' '}
        <div className={classes.container}>
          <a href="/en-savoir-plus/#contact" onClick={this.onClickEvent}>
            <span>
              <span>
                {' '}
                Essayez <span className="vertuloo-font-sm vert">vertuloo</span>
              </span>
              <span>gratuitement</span>
            </span>
          </a>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(BlocDevis);
