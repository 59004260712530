import {
  Dialog as MaterialDialog,
  Grid,
  IconButton,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import styles from './styles';

interface Props {
  classes?: any;
  image?: ImageBitmap | any;
}

export interface State {
  open: boolean;
}
class Dialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { classes, image } = this.props;
    const { open } = this.state;
    return (
      <div className={classes.containerDialog}>
        <IconButton
          color="default"
          onClick={this.handleOpen}
          className={classes.iconButton}
        >
          <Tooltip title="Où trouver cette info ?">
            <InfoIcon />
          </Tooltip>
        </IconButton>
        <MaterialDialog
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
        >
          <Grid container justify="flex-end">
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          {image && <img src={image} alt="" />}
        </MaterialDialog>
      </div>
    );
  }
}

export default withStyles(styles)(Dialog);
