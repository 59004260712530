import { push } from 'connected-react-router';
import { call, put, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  ActionCreators as AdbActionCreator,
  Selectors as AdbSelector,
} from '../../redux/AdministrateurDeBien';
import {
  ActionCreators as DossierLocationActionCreator,
  Selectors as DossierLocationSelector,
} from '../../redux/DossierLocation';
import { ActionCreators as PiecesActionCreators } from '../../redux/Pieces';
import { ActionCreators as SituationPersonnelleActionCreator } from '../../redux/SituationPersonnelle';
import { Selectors as UtilisateurSelector } from '../../redux/Utilisateurs';
import { IApi } from '../../services/Api';
import {
  AdministrateurBienDetail,
  DossierLocation,
  PageDossiersLocation,
  RapportCalculAlgo,
  RetourLocationAcceptee,
  Utilisateur,
} from '../../services/Api/swagger';
import { getAdbDetail } from '../AdministrateurDeBien';

export function* redirectionPageCreationBien(
  api: IApi,
  action: ActionType<
    typeof DossierLocationActionCreator.redirectSelectAdbSitePage
  >,
) {
  try {
    let adbDetail: AdministrateurBienDetail[] = yield select(
      AdbSelector.getADBDetail,
    );
    const utilisateurConnecte: Utilisateur = yield select(
      UtilisateurSelector.getUtilisateurConnecte,
    );
    const idAdb = yield select(AdbSelector.getAdbId);
    // si l'utilisateur ADB possède un seul ADB qui lui possède un seul site
    if (
      utilisateurConnecte &&
      utilisateurConnecte.role === 'ADB' &&
      utilisateurConnecte.detailAdbProfilSites &&
      utilisateurConnecte.detailAdbProfilSites.length === 1 &&
      utilisateurConnecte.detailAdbProfilSites[0] &&
      utilisateurConnecte.detailAdbProfilSites[0].sites &&
      utilisateurConnecte.detailAdbProfilSites[0].sites.length === 1
    ) {
      let selectedAdb =
        adbDetail &&
        adbDetail.find(
          s =>
            utilisateurConnecte &&
            utilisateurConnecte.detailAdbProfilSites &&
            utilisateurConnecte.detailAdbProfilSites[0] &&
            s.identifiant ===
            utilisateurConnecte.detailAdbProfilSites[0].refAdb,
        );
      // si l'adb n'existe pas dans detail, il faut le charger

      if (!selectedAdb) {
        const refAdb = utilisateurConnecte.detailAdbProfilSites[0].refAdb || 0;
        yield* getAdbDetail(
          api,
          AdbActionCreator.getADBIdDetail.request(refAdb),
        );
        adbDetail = yield select(AdbSelector.getADBDetail);
        selectedAdb =
          adbDetail && adbDetail.find(s => s.identifiant === refAdb);
      }
      // après chargement ou si l'adb existait déja dans le tableau de details des adbs
      if (selectedAdb) {
        const refSite = utilisateurConnecte.detailAdbProfilSites[0].sites[0];
        const selectedSite =
          selectedAdb.sites &&
          selectedAdb.sites.find(s => s.numero === refSite);
        if (selectedSite) {
          yield put(AdbActionCreator.setSelectedSite(selectedSite));
          yield put(push('/dossier-location/pre-formulaire'));
        }
        // si l'adb n'existe pas dans detail et que le chargement s'est mal passé
      } else {
        yield put(push('/dossier-location/selection-adb'));
      }
      // si l'utilisateur BESSE est que l'ADB selectionné possède qu'un seul Site
    } else if (
      utilisateurConnecte &&
      utilisateurConnecte.role === 'BESSE' &&
      idAdb &&
      adbDetail.find(adb => adb.identifiant === idAdb) &&
      // @ts-ignore
      adbDetail.find(adb => adb.identifiant === idAdb).sites &&
      // @ts-ignore
      adbDetail.find(adb => adb.identifiant === idAdb).sites.length === 1
    ) {
      yield put(
        AdbActionCreator.setSelectedSite(
          // @ts-ignore
          adbDetail.find(adb => adb.identifiant === idAdb).sites[0],
        ),
      );
      yield put(push('/dossier-location/pre-formulaire'));
    } else {
      yield put(push('/dossier-location/selection-adb'));
    }
  } catch (error) {
    yield put(push('/dossier-location/selection-adb'));
  }
}

export function* addOrUpdateDossierLocation(
  api: IApi,
  action: ActionType<
    typeof DossierLocationActionCreator.createOrUpdateDossierLocationAsync.request
  >,
) {
  try {
    // dossier de location est celui du state local du component
    // old dossier location est celui qui est en store en cas de modification
    // le dossier store ne sera modifié que si la création ou modification
    // c-à-dire (appel HTTP) se passe bien
    // à la création on n'a pas de dossier dans le store
    const dossierLocation: DossierLocation = action.payload;
    let resultat;
    // en cas de modification
    if (dossierLocation.reference) {
      const oldDossierLocation: DossierLocation = yield select(
        DossierLocationSelector.getDossierLocation,
      );
      // si on detect un changement de type de dossier on supprime le conjoint
      // côté back, il y aura une suppression des dossiers secondaires
      if (
        oldDossierLocation &&
        oldDossierLocation.compositionLocation !==
        dossierLocation.compositionLocation
      ) {
        yield put(
          SituationPersonnelleActionCreator.removeSituationPersonnelleConjoint(),
        );
      }
      yield call(api.updateDossierLocation, dossierLocation);
      resultat = dossierLocation;
      // en cas de création
    } else {
      resultat = yield call(api.addDossierLocation, dossierLocation);
    }
    yield put(
      DossierLocationActionCreator.createOrUpdateDossierLocationAsync.success(
        resultat,
      ),
    );
    yield put(push('/dossier-location/bien'));
  } catch (error) {
    yield put(
      DossierLocationActionCreator.createOrUpdateDossierLocationAsync.failure(
        error,
      ),
    );
  }
}

export function* getSyntheseDossier(
  api: IApi,
  action: ActionType<
    typeof DossierLocationActionCreator.getSyntheseDossier.request
  >,
) {
  try {
    const result: DossierLocation = yield call(
      api.getSyntheseDossier,
      action.payload,
    );
    yield put(DossierLocationActionCreator.getSyntheseDossier.success(result));
  } catch (error) {
    yield put(DossierLocationActionCreator.getSyntheseDossier.failure(error));
  }
}

export function* getDossiersLocation(
  api: IApi,
  action: ActionType<
    typeof DossierLocationActionCreator.getDossiersLocation.request
  >,
) {
  try {
    const result: PageDossiersLocation = yield call(
      api.getDossiersLocation,
      action.payload,
    );
    yield put(DossierLocationActionCreator.getDossiersLocation.success(result));
  } catch (error) {
    yield put(DossierLocationActionCreator.getDossiersLocation.failure(error));
  }
}

export function* getArchiveNetheos(
  api: IApi,
  action: ActionType<typeof PiecesActionCreators.getArchiveNetheos.request>,
) {
  try {
    const result: Blob = yield call(api.getArchiveNetheos, action.payload);
    yield put(PiecesActionCreators.getArchiveNetheos.success(result));

    const blob = new Blob([result], { type: 'application/zip' });
    const link = document.createElement('a');
    link.setAttribute('download', 'documents');
    link.setAttribute('href', window.URL.createObjectURL(blob));
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    yield put(PiecesActionCreators.getArchiveNetheos.failure(error));
  }
}

export function* getRapportAlgo(
  api: IApi,
  action: ActionType<
    typeof DossierLocationActionCreator.getRapportAlgo.request
  >,
) {
  try {
    const result: RapportCalculAlgo = yield call(
      api.getRapportAlgo,
      action.payload,
    );
    yield put(DossierLocationActionCreator.getRapportAlgo.success(result));
  } catch (error) {
    yield put(DossierLocationActionCreator.getRapportAlgo.failure(error));
  }
}

export function* rouvrirDossierNetheos(
  api: IApi,
  action: ActionType<typeof PiecesActionCreators.rouvrirDossierNetheos.request>,
) {
  try {
    yield call(api.rouvrirDossierNetheos, action.payload);
    yield put(PiecesActionCreators.rouvrirDossierNetheos.success());
  } catch (error) {
    yield put(PiecesActionCreators.rouvrirDossierNetheos.failure(error));
  }
}

export function* calculLoyerMax(
  api: IApi,
  action: ActionType<
    typeof DossierLocationActionCreator.calculLoyerMax.request
  >,
) {
  try {
    const selectedDossier = yield select(
      DossierLocationSelector.getSelectedDossier,
    );
    yield call(api.calculLoyerMax, action.payload);
    yield put(DossierLocationActionCreator.calculLoyerMax.success());

    yield put(
      DossierLocationActionCreator.getSyntheseDossier.request(selectedDossier),
    );
  } catch (error) {
    yield put(DossierLocationActionCreator.calculLoyerMax.failure(error));
  }
}

export function* deleteDossierLocation(
  api: IApi,
  action: ActionType<
    typeof DossierLocationActionCreator.deleteDossierLocation.request
  >,
) {
  try {
    yield call(api.deleteDossierLocation, action.payload);
    yield put(DossierLocationActionCreator.deleteDossierLocation.success());
  } catch (error) {
    yield put(
      DossierLocationActionCreator.deleteDossierLocation.failure(error),
    );
  }
}

export function* genererCertification(
  api: IApi,
  action: ActionType<
    typeof DossierLocationActionCreator.genererCertification.request
  >,
) {
  try {
    const result: any = yield call(api.genererCertification, action.payload);
    yield put(
      DossierLocationActionCreator.genererCertification.success(result),
    );

    // const url = window.URL.createObjectURL(b64toBlob(result));
    const link = document.createElement('a');
    link.href = 'data:application/pdf;base64,' + result;
    link.setAttribute('download', 'certificatAgrement.pdf');
    document.body.appendChild(link);
    link.target = '_blank';
    link.click();
  } catch (error) {
    yield put(DossierLocationActionCreator.genererCertification.failure(error));
  }
}

export function* dupliquerDossier(
  api: IApi,
  action: ActionType<
    typeof DossierLocationActionCreator.dupliquerDossier.request
  >,
) {
  try {
    const result: DossierLocation = yield call(
      api.dupliquerDossier,
      action.payload,
    );
    yield put(DossierLocationActionCreator.dupliquerDossier.success(result));

    if (result.reference) {
      yield* getSyntheseDossier(
        api,
        DossierLocationActionCreator.getSyntheseDossier.request(
          result.reference,
        ),
      );
    }
    yield put(push('/dossier-location/synthese'));
  } catch (error) {
    yield put(DossierLocationActionCreator.dupliquerDossier.failure(error));
  }
}

export function* accepterDossier(
  api: IApi,
  action: ActionType<
    typeof DossierLocationActionCreator.accepterDossier.request
  >,
) {
  try {
    const response: RetourLocationAcceptee = yield call(
      api.accepterDossier,
      action.payload,
    );
    yield put(DossierLocationActionCreator.accepterDossier.success(response));

    const filtreRecherche = yield select(
      DossierLocationSelector.getFiltreRechercheDossierLocation,
    );

    yield put(
      DossierLocationActionCreator.getDossiersLocation.request(filtreRecherche),
    );
  } catch (error) {
    yield put(DossierLocationActionCreator.accepterDossier.failure(error));
  }
}

export function* annulerAcceptationDossier(
  api: IApi,
  action: ActionType<
    typeof DossierLocationActionCreator.annulerAcceptationDossier.request
  >,
) {
  try {
    yield call(api.annulerAcceptationDossier, action.payload);
    yield put(DossierLocationActionCreator.annulerAcceptationDossier.success());

    const filtreRecherche = yield select(
      DossierLocationSelector.getFiltreRechercheDossierLocation,
    );

    yield put(
      DossierLocationActionCreator.getDossiersLocation.request(filtreRecherche),
    );
  } catch (error) {
    yield put(
      DossierLocationActionCreator.annulerAcceptationDossier.failure(error),
    );
  }
}

export function* forcerStatutDossier(
  api: IApi,
  action: ActionType<
    typeof DossierLocationActionCreator.forcerStatutDossier.request
  >,
) {
  try {
    const selectedDossier = yield select(
      DossierLocationSelector.getSelectedDossier,
    );
    yield call(api.forcerStatutDossier, action.payload);
    yield put(DossierLocationActionCreator.forcerStatutDossier.success());
    yield put(
      DossierLocationActionCreator.getSyntheseDossier.request(selectedDossier),
    );
  } catch (error) {
    yield put(DossierLocationActionCreator.forcerStatutDossier.failure(error));
  }
}
