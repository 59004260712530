import { Theme } from '@material-ui/core/styles';
const styles = (theme: Theme) =>
  ({
    classNote: {
      whiteSpace: 'nowrap',
  },
    classNoteBold: {
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      fontStyle: 'italic',
  },
  } as any);

export default styles;
