import { Grid, Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { Field } from 'redux-form';
import FormContainer from '../../../../../../components/FormContainer/index';
import InputField from '../../../../../../components/input/InputField';
import InputCalendrier from '../../../../../../components/inputCalendrier/InputCalendrier';
import MessageInfo from '../../../../../../components/MessageInfo';
import Select, {
  DropdownItem,
} from '../../../../../../components/select/Select';
import { maskNumSiret, showMonth } from '../../../../../../helpers/formatters';
import { getDateApiFromString } from '../../../../../../helpers/validators';
import cumulAnnee from '../../../../../../ressources/images/cum_impo_m1.png';
import salaireMois from '../../../../../../ressources/images/net_impo_m1.png';
import { SituationProfessionnelle } from '../../../../../../services/Api/swagger/api';
import Libelle from '../../../../../../_i18n/fr.json';
import {
  getConditionsAffichage,
  getId,
  ISituationProfessionnellePresentation,
  memoizeConstruireSituationsProfessionnelles,
  memoizeConstruireStatutsProfessionnels,
} from '../../../helpers';
import * as Messages from '../../../messages';
import styles from '../../../style';
import InputCheckbox from '../../../../../../components/inputCheckbox/InputCheckbox';

interface Props {
  situationsPros?: SituationProfessionnelle[];
  formValues?: ISituationProfessionnellePresentation;

  change(field: string, value: any): any;

  prefixe: 'principal' | 'secondaire';
  classes?: any;
  isCumulsUtilises: boolean;
}

interface State {
  statutsProOptions?: DropdownItem[];
  selectedSituation?: SituationProfessionnelle;
}

class SituationProfessionnelleUnePersonne extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      statutsProOptions: [],
      selectedSituation: undefined,
    };
  }

  onChangeSituation = situation => {
    const { situationsPros } = this.props;
    const selectedSituation: SituationProfessionnelle | undefined =
      situationsPros && situationsPros.find(s => s.code === situation);
    const statutsOptions = memoizeConstruireStatutsProfessionnels(
      selectedSituation && selectedSituation.statutsProfessionnels,
    );
    this.setState(
      {
        statutsProOptions: statutsOptions,
        selectedSituation,
      },
      () =>
        this.nbrMoisTravaillesListener(),
    );
  };

  componentDidMount() {
    if (this.props.formValues && this.props.formValues.situation) {
      this.onChangeSituation(this.props.formValues.situation);
    }
  }

  componentDidUpdate = prevProps => {
    const { formValues, prefixe } = this.props;
    const selectedSituation = this.state.selectedSituation;

    if (
      prevProps.formValues &&
      formValues &&
      prevProps.formValues.situation !== formValues.situation
    ) {
      const statut =
        formValues.situation &&
        selectedSituation &&
        selectedSituation.statutsProfessionnels &&
        selectedSituation.statutsProfessionnels[0] &&
        selectedSituation.statutsProfessionnels[0].code;
      this.props.change(getId(prefixe, 'codeStatutProfessionnel'), statut);
    }
  };

  nbrMoisTravaillesListener = () => {
    const { formValues, prefixe } = this.props;

    const {
      isDateActiviteAvant45j,
      isDateActiviteAvant75j,
      isDateActiviteAvant105j
    } = getConditionsAffichage(formValues);

    if (formValues && !isDateActiviteAvant45j) {
      this.props.change(getId(prefixe, 'salaireNetImposableM1'), null);
      this.props.change(getId(prefixe, 'cumulSalaireNetImposableM1'), null);
    }
    if (formValues && !isDateActiviteAvant75j) {
      this.props.change(getId(prefixe, 'salaireNetImposableM2'), null);
      this.props.change(getId(prefixe, 'cumulSalaireNetImposableM2'), null);
    }
    if (formValues && !isDateActiviteAvant105j) {
      this.props.change(getId(prefixe, 'salaireNetImposableM3'), null);
      this.props.change(getId(prefixe, 'cumulSalaireNetImposableM3'), null);
    }
  };

  render() {
    const {
      prefixe,
      classes,
      situationsPros,
      formValues,
      isCumulsUtilises,
    } = this.props;
    const { statutsProOptions } = this.state;
    const {
      isSalarie,
      isNonSalarie,
      isDteDbtStAt30jDteJr,
      isDateActiviteAvant45j,
      isDateActiviteAvant75j,
      isDateActiviteAvant105j,
      isDateDebutActivitePlus1an,
      isNonSalarieOrAutre,
      isCdiEssaiOrCddOrInterimaire,
      isRechercheEmploi,
      isEtudiantOuSansActivite,
    } = getConditionsAffichage(formValues);

    const situationsProOptions = memoizeConstruireSituationsProfessionnelles(
      situationsPros,
    );

    let limitInfDateFinSituation = getDateApiFromString(
      formValues && formValues.dateDebutSituationProfessionnelle,
    );

    limitInfDateFinSituation =
      limitInfDateFinSituation && limitInfDateFinSituation.add(1, 'days');

    let titleDerniersMois = '';
    if (isDateActiviteAvant105j) {
      titleDerniersMois = Libelle.pageSituationPro.sections.sousSectionMois3;
    } else if (isDateActiviteAvant75j) {
      titleDerniersMois = Libelle.pageSituationPro.sections.sousSectionMois2;
    } else if (isDateActiviteAvant45j) {
      titleDerniersMois = Libelle.pageSituationPro.sections.sousSectionMois1;
    }

    return (
      <>
        <FormContainer>
          <>
            <h4>{Libelle.pageSituationPro.sections.sousSection1}</h4>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Field
                  name={getId(prefixe, 'situation')}
                  component={Select}
                  options={situationsProOptions}
                  label="Situation"
                  onChange={this.onChangeSituation}
                  required
                />
              </Grid>
              {(isSalarie || isNonSalarieOrAutre) && (
                <Grid item>
                  <Field
                    name={getId(prefixe, 'codeStatutProfessionnel')}
                    component={Select}
                    options={statutsProOptions}
                    label="Statut"
                    required
                  />
                </Grid>
              )}

              <Grid item>
                <Field
                  name={getId(prefixe, 'dateDebutSituationProfessionnelle')}
                  component={InputCalendrier}
                  maxDate={moment(new Date())
                    .endOf('days')
                    .add(60, 'days')}
                  label="Date de début"
                  required
                  variante="inline"
                  varianteInput="filled"
                  onChange={this.nbrMoisTravaillesListener}
                  minDateMessage={Messages.default.maxDateMessage}
                  maxDateMessage={Messages.default.minDateMessage}
                  invalidDateMessage={Messages.default.invalidDateMessage}
                />
              </Grid>
              {isCdiEssaiOrCddOrInterimaire && (
                <Grid item>
                  <Field
                    name={getId(prefixe, 'dateFinSituationProfessionnelle')}
                    component={InputCalendrier}
                    maxDate={moment(new Date())
                      .endOf('month')
                      .add(5, 'years')}
                    minDate={limitInfDateFinSituation}
                    label="Date de fin"
                    required
                    variante="inline"
                    varianteInput="filled"
                    minDateMessage={Messages.default.maxDateMessage}
                    invalidDateMessage={Messages.default.invalidDateMessage}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2}>
              {(isSalarie || isNonSalarieOrAutre || isRechercheEmploi) && (
                <Grid item xs={3}>
                  <Field
                    name={getId(prefixe, 'nombreMoisTravailles')}
                    component={InputField}
                    label="Nombre de mois travaillés"
                    type="number"
                    required
                    note="(sur les 24 derniers mois, tous emplois confondus)"
                    classNote={classes.classNoteBold}
                    numberAfterDecimal={0}
                  />
                </Grid>
              )}
              {isSalarie && isDteDbtStAt30jDteJr && (
                <Grid item xs={3}>
                  <Field
                    name={getId(prefixe, 'dateDernierBulletin')}
                    component={InputCalendrier}
                    isYearMonth={true}
                    minDate={moment(new Date())
                      .startOf('month')
                      .add(-2, 'month')}
                    maxDate={moment(new Date()).endOf('month')}
                    label="Dernier bulletin de salaire"
                    required
                    variante="inline"
                    varianteInput="filled"
                    onChange={this.nbrMoisTravaillesListener}
                    minDateMessage={
                      Messages.default.maxDatedernierBulletinMessage
                    }
                    maxDateMessage={
                      Messages.default.minDatedernierBulletinMessage
                    }
                    invalidDateMessage={Messages.default.invalidDateMessage}
                  />
                </Grid>
              )}
              {formValues &&
                (formValues.situation === 'SPR' ||
                  formValues.situation === 'TNS') && (
                  <Grid item xs={3}>
                    <Field
                      name={getId(prefixe, 'codeApe')}
                      component={InputField}
                      maxLength={5}
                      label="Code APE (NAF)"
                      type="text"
                      required
                    />
                  </Grid>
                )}

              {isNonSalarie && (
                <Grid item xs={3}>
                  <Field
                    name={getId(prefixe, 'siret')}
                    component={InputField}
                    mask={maskNumSiret()}
                    label="SIRET"
                    type="text"
                    required
                  />
                </Grid>
              )}
            </Grid>
            {!isEtudiantOuSansActivite && (
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Field
                    name={getId(prefixe, 'pasAvisImposition')}
                    component={InputCheckbox}
                    label="Ne possède pas d’avis d’imposition"
                  />
                  <Typography
                    className={classes.classNoteBold}
                    variant="caption"
                    dangerouslySetInnerHTML={{ __html: "(toute personne de plus de 26 ans a un avis d’imposition ou de non-imposition)" }}
                  />
                </Grid>
              </Grid>
            )}
            {/* tslint:disable-next-line:max-line-length */}
            {/*<MessageInfo messageUrl="Attention, la modification de certains champs peut provoquer la suppression des pièces justificatives concernées." />*/}
          </>
        </FormContainer>
        {isSalarie && isDateActiviteAvant45j && (
          <FormContainer>
            <h4>{titleDerniersMois}</h4>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Field
                  name={getId(prefixe, 'salaireNetImposableM1')}
                  component={InputField}
                  label={
                    formValues &&
                    'Salaire net imposable ' +
                    showMonth(formValues.dateDernierBulletin)
                  }
                  type="number"
                  max={99999}
                  required
                  numberAfterDecimal={0}
                  imageHelpInfo={salaireMois}
                />
              </Grid>
              {isCumulsUtilises && isDateDebutActivitePlus1an && (
                <Grid item xs={3}>
                  <Field
                    name={getId(prefixe, 'cumulSalaireNetImposableM1')}
                    component={InputField}
                    label={
                      formValues &&
                      formValues.dateDernierBulletin &&
                      'Cumul salaire net imposable ' +
                      showMonth(formValues.dateDernierBulletin)
                    }
                    type="number"
                    max={999999}
                    required
                    numberAfterDecimal={0}
                    imageHelpInfo={cumulAnnee}
                  />
                </Grid>)}
            </Grid>
            {isDateActiviteAvant75j && (
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Field
                    name={getId(prefixe, 'salaireNetImposableM2')}
                    component={InputField}
                    label={
                      formValues &&
                      formValues.dateDernierBulletin &&
                      'Salaire net imposable ' +
                      showMonth(
                        moment(new Date(formValues.dateDernierBulletin)).add(
                          -1,
                          'month',
                        ),
                      )
                    }
                    type="number"
                    max={99999}
                    numberAfterDecimal={0}
                    required
                  />
                </Grid>
                {isCumulsUtilises && isDateDebutActivitePlus1an && (
                  <Grid item xs={3}>
                    <Field
                      name={getId(prefixe, 'cumulSalaireNetImposableM2')}
                      component={InputField}
                      label={
                        formValues &&
                        formValues.dateDernierBulletin &&
                        'Cumul salaire net imposable ' +
                        showMonth(
                          moment(new Date(formValues.dateDernierBulletin)).add(
                            -1,
                            'month',
                          ),
                        )
                      }
                      type="number"
                      max={999999}
                      numberAfterDecimal={0}
                      required
                    />
                  </Grid>
                )}
              </Grid>
            )}
            {isDateActiviteAvant105j && (
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Field
                    name={getId(prefixe, 'salaireNetImposableM3')}
                    component={InputField}
                    label={
                      formValues &&
                      formValues.dateDernierBulletin &&
                      'Salaire net imposable ' +
                      showMonth(
                        moment(new Date(formValues.dateDernierBulletin)).add(
                          -2,
                          'month',
                        ),
                      )
                    }
                    type="number"
                    max={99999}
                    numberAfterDecimal={0}
                    required
                  />
                </Grid>
                {isCumulsUtilises && isDateDebutActivitePlus1an && (
                  <Grid item xs={3}>
                    <Field
                      name={getId(prefixe, 'cumulSalaireNetImposableM3')}
                      component={InputField}
                      label={
                        formValues &&
                        formValues.dateDernierBulletin &&
                        'Cumul salaire net imposable ' +
                        showMonth(
                          moment(new Date(formValues.dateDernierBulletin)).add(
                            -2,
                            'month',
                          ),
                        )
                      }
                      type="number"
                      max={999999}
                      numberAfterDecimal={0}
                      required
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </FormContainer>
        )}
      </>
    );
  }
}

export default withStyles(styles)(SituationProfessionnelleUnePersonne);
