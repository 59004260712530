import { AppBar, Link, Toolbar, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as Sentry from '@sentry/browser';
import React, { Component } from 'react';
import theme from '../../theme';
import Bouton from '../bouton/Bouton';
import LogoHeader from '../../ressources/images/logo_vertuloo_vert.png';
import styles from './styles';

interface IProps {
  classes: any;
}

interface IState {
  hasError: boolean;
  eventId?: any;
}

class ErrorBoundary extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      eventId: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  handleClick() {
    window.location.reload();
  }

  render() {
    const { classes, children } = this.props;
    if (this.state.hasError) {
      return (
        <MuiThemeProvider theme={theme}>
          <AppBar position="static" className={classes.containerError}>
            <Toolbar className={classes.header}>
              <Link href={'/'} className={classes.logoConteneur}>
                <img src={LogoHeader} className={classes.logo} alt="" />
              </Link>
            </Toolbar>
          </AppBar>
          <div style={{ textAlign: 'center' }}>
            <Typography align="center" variant="body2">
              Une erreur inattendue s'est produite
            </Typography>
            <Bouton
              color="primary"
              type="button"
              label={'Réessayer'}
              onClick={this.handleClick}
              className={classes.button}
            />
          </div>
        </MuiThemeProvider>
      );
    }
    return children;
  }
}

export default withStyles(styles)(ErrorBoundary);
