import React from 'react';
import MentionsLegales from './MentionsLegales';
import Libelle from '../../_i18n/fr.json';
import Layout from '../../components/Layout';

class MentionsLegalesPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout title={Libelle.pageMentionsLegales.titre}>
          <MentionsLegales />
        </Layout>
      </React.Fragment>
    );
  }
}

export default MentionsLegalesPage;
