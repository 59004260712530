import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import Bouton from '../../../../../components/bouton/Bouton';
import Chip from '../../../../../components/Chip';
import { Selectors as PartenaireSelectors } from '../../../../../redux/Partenaire';
import { DossierParticulierDetail, Partenaire } from '../../../../../services/Api/swagger';
import styles from '../styles';
import InfoCandidat from './InfoCandidat';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  data?: DossierParticulierDetail[];
  typeDossier: string;
  cantModify: boolean;
  addColoc(ref: string): void;
  addLocataire(ref: string): void;
  selectedDossier?: string;
  setSelectedPerson(ref: string): void;
  statutDossier?: string;
  partenaire?: Partenaire;
}

interface State {
  personSelected: string;
}
class InfoCandidats extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const defaultPersonSelected =
      props.data && props.data[0] && props.data[0].reference;
    this.state = {
      personSelected: defaultPersonSelected || '',
    };
    if (defaultPersonSelected) {
      this.props.setSelectedPerson(defaultPersonSelected);
    }
  }

  handleSelectPerson = id => {
    this.setState({
      personSelected: id,
    });
    this.props.setSelectedPerson(id);
  };

  addColoc = () => {
    if (this.props.addColoc && this.props.selectedDossier) {
      this.props.addColoc(this.props.selectedDossier);
    }
  };

  addLocataire = () => {
    if (this.props.addLocataire && this.props.selectedDossier) {
      this.props.addLocataire(this.props.selectedDossier);
    }
  };
  render() {
    const {
      data,
      classes,
      typeDossier,
      cantModify,
      statutDossier,
      partenaire,
    } = this.props;
    let personSelected;
    if (data && data.length > 0) {
      personSelected = this.state.personSelected
        ? data.find(person => person.reference === this.state.personSelected)
        : data[0];
    }
    const isConjoint =
      (personSelected &&
        typeDossier === 'COUPLE' &&
        !personSelected.principal &&
        personSelected.typeCompte === 'LOCATAIRE') ||
      false;
    const canAddColocPart =
      !partenaire ||
      (statutDossier !== 'EN_TRANSFERT' && statutDossier !== 'VALIDE' && statutDossier !== 'VALIDE_NON_AGREE' &&
       statutDossier !== 'VALIDE_AGREE_DIFFERE' && statutDossier !== 'VALIDE_AGREE');

    return (
      <Grid
        container
        alignItems="flex-start"
        justify="flex-start"
        className={classes.candidats}
      >
        {data && data.length === 0 && statutDossier !== 'NON_VALIDE' && (
          <Grid container className={classes.ajoutColoc}>
            <Bouton
              label="+ Ajouter un locataire"
              color="secondary"
              variant="contained"
              size="small"
              onClick={this.addLocataire}
            />
          </Grid>
        )}
        {data &&
          data.length === 1 &&
          typeDossier === 'SEUL' &&
          statutDossier !== 'NON_VALIDE' ? (
          <>
            {canAddColocPart && (
              <Grid container justify="flex-end" className={classes.ajoutColoc}>
                <Bouton
                  label="+ Ajouter un(e) colocataire"
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={this.addColoc}
                />
              </Grid>
            )}
            <InfoCandidat
              isConjoint={false}
              personne={data[0]}
              cantModify={cantModify}
              allEtudiant={data
                .filter(d => d.typeCompte === 'LOCATAIRE')
                .every(e => e.codeStatutProfessionnel === 'ETU')}
              canAddGarant={statutDossier !== 'EN_TRANSFERT'}
            />
          </>
        ) : (
          <>
            <Grid container wrap="nowrap" alignItems="flex-start">
              <div className={classes.contentChips}>
                {/* Selection de la personne */}
                {data &&
                  data.map((personne, i) => {
                    let nomCandidat =
                      'Candidat(e)' +
                      ' ' +
                      personne.prenom +
                      ' ' +
                      personne.nom;
                    if (
                      typeDossier === 'COLOCATION' &&
                      personne.typeCompte !== 'GARANT'
                    ) {
                      nomCandidat =
                        'Locataire' +
                        ' ' +
                        personne.prenom +
                        ' ' +
                        personne.nom;
                    } else if (personne.typeCompte === 'GARANT') {
                      const locataireConcerne = data.find(
                        dos => dos.reference === personne.refDossierLocataire,
                      );
                      nomCandidat = locataireConcerne
                        ? 'Garant de ' +
                        locataireConcerne.prenom +
                        ' ' +
                        locataireConcerne.nom
                        : '';
                    } else if (
                      typeDossier === 'COUPLE' &&
                      !personne.principal
                    ) {
                      nomCandidat =
                        'Conjoint(e)' +
                        ' ' +
                        personne.prenom +
                        ' ' +
                        personne.nom;
                    }
                    return (
                      <Chip
                        active={
                          this.state.personSelected === personne.reference
                        }
                        key={i}
                        invalid={
                          personne.statut === 'BLOQUE' ||
                          personne.statut === 'NON_VALIDE' ||
                          personne.statut === 'INCOMPLET' ||
                          personne.statut === 'EN_TRANSFERT'
                        }
                        valid={personne.statut === 'VALIDE'}
                        onClick={this.handleSelectPerson}
                        label={nomCandidat}
                        id={personne.reference || ''}
                      />
                    );
                  })}
              </div>
              {statutDossier !== 'NON_VALIDE' &&
                typeDossier &&
                canAddColocPart &&
                (typeDossier === 'COLOCATION' || typeDossier === 'SEUL') &&
                data &&
                data.filter(d => d.typeCompte !== 'GARANT').length < 5 && (
                  <Bouton
                    label="+ Ajouter un(e) colocataire"
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={this.addColoc}
                  />
                )}
            </Grid>
            <InfoCandidat
              isConjoint={isConjoint}
              personne={personSelected}
              cantModify={cantModify || statutDossier === 'VALIDE'}
              hasGarant={
                data &&
                  data.find(
                    d => d.refDossierLocataire === this.state.personSelected,
                  )
                  ? true
                  : false
              }
              allEtudiant={
                data &&
                data
                  .filter(d => d.typeCompte === 'LOCATAIRE')
                  .every(
                    e =>
                      e.codeStatutProfessionnel === 'ETU' ||
                      !e.codeStatutProfessionnel,
                  )
              }
              canAddGarant={statutDossier !== 'EN_TRANSFERT'}
            />
          </>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  partenaire: PartenaireSelectors.getPartenaire(state),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
  )(InfoCandidats),
);
