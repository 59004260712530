import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    root: {
      padding: '1.25rem 0',
    },
  } as any);

export default styles;
