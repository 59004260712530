import { withStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';

interface IProps {
  classes?: any;
  cookiesValid: boolean;
}

class BlocDevis extends React.Component<IProps> {
  onClickEvent = () => {
    if (window.MatomoTagManager) {
      window._paq.push([
        'trackEvent',
        'Contact',
        'Clic - CTA',
        'Accueil - Essayer Vertuloo gratuitement',
      ]);
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.container}>
          <a href="/#contact" onClick={this.onClickEvent}>
            <span>
              <span>
                {' '}
                Essayez <span className="vertuloo-font-sm vert">vertuloo</span>
              </span>
              <span>gratuitement</span>
            </span>
          </a>
        </div>
        <div className={classes.containerTexte}>
          <h2>
            <span>Bessé</span> Immobilier & Construction vous accompagne
          </h2>
          <h4>
            dans la mise en place de <span className="vert">vertuloo</span>
          </h4>
          <div className={classes.divider} />
          <p>
            Validation avec votre assureur et{' '}
            <strong>
              émission d'un avenant entérinant la mise en place de Vertuloo
            </strong>
          </p>
          <p>
            Déploiement du nouvel outil, intégrant{' '}
            <strong>formation et accompagnements de vos équipes</strong>
          </p>
          <p>
            Construction de courriers à l'attention de{' '}
            <strong>
              vos propriétaires/bailleurs
              <br />
              assurés et non-assurés
            </strong>{' '}
            (assistance à la rédaction des discours)
          </p>
          <p>
            <strong>Hot-line</strong>
          </p>
          <p>
            <strong>Assistance</strong> pré-contentieux et contentieux
          </p>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(BlocDevis);
