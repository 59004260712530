import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { ActionCreators as LocalisationCreators } from '../../redux/Localisation';
import { ActionCreators as TypeVoieCreators } from '../../redux/TypeVoie';
import { IApi } from '../../services/Api';



export function* lancerChangementLocalisation(
    api: IApi,
    action: ActionType<typeof LocalisationCreators.loadLocalisationsAsync.request>,
) {
    try {
        const resultats = yield call(api.getCommunes, action.payload);
        yield put(LocalisationCreators.loadLocalisationsAsync.success(resultats));
    } catch (error) {
        yield put(LocalisationCreators.loadLocalisationsAsync.failure(error));
    }
}


export function* lancerRecupererListTypeVoie(
    api: IApi,
    action: ActionType<typeof TypeVoieCreators.loadTypeVoieAsync.request>,
) {
    try {
        const result = yield call(api.getTypesVoie);
        yield put(TypeVoieCreators.loadTypeVoieAsync.success(result));
    } catch (error) {
        yield put(TypeVoieCreators.loadTypeVoieAsync.failure(error));
    }
}