import { Theme } from '@material-ui/core/styles';
import { couleurPrimaire } from '../../../theme';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
  ({
    container: {
      display: 'block',
    },
    logoFI: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        paddingTop: 12,
        width: 300,
      },
    },
    texte: {
      display: 'flex',
      maxWidth: '880px',
      font: '16 bold',
      padding: '20px 12px 48px 12px',
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
    },
    vertuloo: {
      color: couleurPrimaire,
    },
    '@media all and (max-width: 1000px)': {
      container: {
        flexDirection: 'column',
        '& iframe': {
          marginRight: 0,
          marginBottom: 24,
        },
      },
    },
  } as any);
// tslint:enable: quotemark

export default styles;
