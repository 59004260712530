import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
({
  container: {
    padding: '62px 12px',
    margin: 'auto',
    maxWidth: 880,
    '& h2': {
      fontSize: 26,
      margin: 0,
      fontWeight: 'bold',
    },
    '& h3': {
      textTransform: 'initial',
      fontSize: 14,
      margin: 0,
    },
  },
  divider: {
    height: 2,
    background: theme.palette.primary.main,
    maxWidth: 260,
    width: '30%',
    margin: '32px auto',
  },
  contentFonctions: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
    '& img': {
      marginRight: 24,
    },
  },
  contentTexte: {
    '& p': {
      fontSize: 15,
      margin: 0,
    },
    '& li': {
      paddingLeft: 24,
      position: 'relative',
      '&:before': {
        content: '""',
        width: 5,
        height: 5,
        background: '#808080',
        position: 'absolute',
        borderRadius: '100%',
        top: 8,
        left: 10,
      },
    },
  },
} as any);

export default styles;
