import { Theme } from '@material-ui/core/styles';

// tslint:disable: quotemark
const styles = (_theme: Theme) =>
({
  blocFormulaire: {
    padding: 24,
    paddingBottom: 12,
    marginBottom: 24,
    background: 'rgb(214, 212, 221)',
    '& > div': {
      marginBottom: 8,
    },
    '& h4': {
      marginTop: 0,
    },
  },
} as any);
// tslint:enable: quotemark

export default styles;
