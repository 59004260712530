import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    blocFormulaire: {
      backgroundColor: theme.palette.grey[300],
      padding: theme.spacing(1),
      marginBottom: '5px',
      flexGrow: 1,
    },
  } as any);

export default styles;
