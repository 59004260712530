/**
 * Création du store Redux
 */
import { History } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleWare from 'redux-saga';
import rootReducer from './rootReducers';

export default function configureStore(initialState = {}, historique: History) {
  // Création d'un middleware pour nos Sagas :
  const sagaMiddleware = createSagaMiddleWare();
  // On définit deux middlewares :
  // 1. sagaMiddleware: pour faire fonctionner redux-saga
  // 2. routerMiddleware: pour synchroniser URL paths avec le state
  const middlewares = [sagaMiddleware, routerMiddleware(historique)];

  const enhancers = [applyMiddleware(...middlewares)];

  // Si l'extension Redux DevTools est installée on l'utilise
  // Sinon on utilise le compose de Redux
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  // Création du store
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(...enhancers),
  ) as any;

  // On ajoute une extension runSaga pour pouvoir démarrer le middleware saga après la création du store
  store.runSaga = sagaMiddleware.run;

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./rootReducers', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
}
