import { withStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';
import logoBlanc from '../../../../ressources/images/logo_vertuloo_blanc.png';

interface IProps {
  classes?: any;
}

const BlocDescription = (props: IProps) => (
  <div className={props.classes.container}>
    <img src={logoBlanc} width={392} alt="" />
    <p>
      la nouvelle <strong>plateforme digitale</strong> dédiée aux
      administrateurs de biens pour la{' '}
      <strong>
        sécurisation et le développement de leur activité de gestion locative
      </strong>
    </p>
  </div>
);

export default withStyles(styles)(BlocDescription);
