import React from 'react';
import Snack from '../../components/Snack';

interface IProps {
    errors: any;
    onClose(): any;

}
const RenderError = (props: IProps) => {
    const { errors, onClose } = props;
    const errorComponent = [] as any[];
    // erreur metier
    if (errors && errors.content && errors.code === 'ErreurMetier') {
        // tslint:disable-next-line: forin
        for (const element in errors.content) {
            errorComponent.push(
                // tslint:disable-next-line: jsx-wrap-multiline
                <Snack
                    key={element}
                    onClose={onClose}
                    open={true}
                    variant="error"
                    message={errors.content[element]}
                />,
            );
        }
    } else if (errors && errors.content && errors.code === 'UnexpectedError') {
        errorComponent.push(
            <Snack
                key={1}
                onClose={onClose}
                open={true}
                variant="error"
                message={errors.content}
            />
        )
    }
    return (
        <React.Fragment>{errorComponent}</React.Fragment>
    );
};

export default RenderError;
