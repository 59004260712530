import { Grid } from '@material-ui/core';
import React from 'react';
import { Field } from 'redux-form';
import FormContainer from '../../../components/FormContainer';
import Select from '../../../components/select/Select';
import TransferList from '../../../components/TransferList';
import { AdministrateurBienDetail } from '../../../services/Api/swagger/api.js';
import { construireListAdbDetail, construireListProfils } from '../helpers';

interface Props {
  adbDetail?: AdministrateurBienDetail[];
  formValues: any;
  fields: any[];
  utilisateurConnecteAdb: any;
  getAdbDetail(id): void;
  index: number;
  adb: any;
  disabled?: boolean;
}

class AjouterSitesAdb extends React.Component<Props> {
  componentDidMount() {
    const getValuesAdb =
      this.props.formValues &&
      this.props.formValues.adbs &&
      this.props.formValues.adbs[this.props.index];
    this.props.getAdbDetail(getValuesAdb.refAdb);
  }
  render() {
    const {
      adbDetail,
      formValues,
      index,
      adb,
      disabled,
      utilisateurConnecteAdb,
    } = this.props;

    const getValuesAdb =
      formValues && formValues.adbs && formValues.adbs[index];
    const filtrerAdbDetail =
      adbDetail &&
      getValuesAdb &&
      adbDetail.find(adb2 => adb2.identifiant === Number(getValuesAdb.refAdb));

    // Si utilisateur connecté a un role ADB :
    //  On récupère les infos de l'ADB sélectionné pour avoir les sites autorisés par cet utilisateur

    const filtrerAdb =
      filtrerAdbDetail &&
      utilisateurConnecteAdb &&
      utilisateurConnecteAdb.find(
        detail => detail.refAdb === filtrerAdbDetail.identifiant,
      );

    // La liste des sites accessibles à l'utilisateur connecté SI utilisateur ADB
    const sites = filtrerAdb && filtrerAdb.sites;
    const filtrerSites =
      sites && filtrerAdbDetail && filtrerAdbDetail.sites
        ? filtrerAdbDetail.sites.filter(
            site => site.numero && sites.includes(site.numero),
          )
        : filtrerAdbDetail && filtrerAdbDetail.sites;

    const optionsAdbDetail =
      (filtrerAdbDetail &&
        construireListAdbDetail(filtrerSites, filtrerAdbDetail.identifiant)) ||
      [];
    // on construit la liste des profils (ils sont tous autorisés par défaut pour utilisateur BESSE OU ADB)
    const optionsProfils =
      (filtrerAdbDetail &&
        filtrerAdbDetail.applications &&
        filtrerAdbDetail.applications[0] &&
        filtrerAdbDetail.applications[0].profils &&
        construireListProfils(filtrerAdbDetail.applications[0].profils)) ||
      [];
    return (
      <>
        <h3>
          {filtrerAdbDetail &&
            filtrerAdbDetail.nom + ' - ' + filtrerAdbDetail.ville}
        </h3>
        <FormContainer key={index}>
          <Grid container spacing={1}>
            <Grid item>
              <Field
                name={`${adb}.profil`}
                component={Select}
                label="Profil"
                options={optionsProfils}
                required
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            {optionsAdbDetail && optionsAdbDetail.length > 0 && (
              <Grid item>
                <Field
                  name={`${adb}.sites`}
                  component={TransferList}
                  label="Sites"
                  required
                  options={optionsAdbDetail}
                  disabled={disabled}
                />
              </Grid>
            )}
          </Grid>
        </FormContainer>
      </>
    );
  }
}

export default AjouterSitesAdb;
