import React from 'react';

// tslint:disable: max-line-length
export default class MonBienSVG extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="83"
        height="83"
        viewBox="0 0 67 67"
      >
        <g
          id="Mask"
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          fill="rgba(92, 81, 117, 0.25)"
        >
          <circle cx="33.5" cy="33.5" r="33.5" />
          <path
            fill="#FFF"
            d="M47.13 17.448h-5.593v2.688l5.592 4.986zM33.718 18.53L16.523 33.86v19.594h5.067V37.088h7.62v16.366h21V32.737L34.033 18.314l-.314.215zm.331 28.547h10.625v-9.944H34.05v9.944z"
          />
          <path
            fill="#000"
            d="M34.01 13.4l-.014.013-.014-.013-.11.123-21.503 19.17 2.419 2.714 1.734-1.546L33.72 18.529l.277-.247.036.032 16.179 14.423 2.993 2.67 2.418-2.713-8.493-7.572-5.59-4.985-7.42-6.614z"
          />
        </g>
      </svg>
    );
  }
}
// tslint:enable: max-line-length
