import { Grid, withStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import {
  change,
  Field,
  getFormValues,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import Divider from '../../../../../components/Divider';
import FormButtons from '../../../../../components/FormButtons';
import FormContainer from '../../../../../components/FormContainer';
import InputField from '../../../../../components/input/InputField';
import MessageInfo from '../../../../../components/MessageInfo';
import { getTitleCandidat } from '../../../../../helpers/utils';
import {
  compareDates,
  dateFormatApi,
  estApeValide,
  estSiretValide,
  isDateInfAujourdhui,
  isInteger,
} from '../../../../../helpers/validators';
import { Selectors as DossierLocationSelectors } from '../../../../../redux/DossierLocation';
import { Selectors as PartenaireSelectors } from '../../../../../redux/Partenaire';
import { Selectors as SituationPersoSelectors } from '../../../../../redux/SituationPersonnelle';
import revenuFiscal from '../../../../../ressources/images/rev-fisc-ref.png';
import revenuIndividuelDeclare from '../../../../../ressources/images/rev-ind-dec.png';
import {
  DossierLocation,
  DossierLocationCompositionLocationEnum,
  DossierParticulierDetailTypeCompteEnum,
  SituationProfessionnelle,
} from '../../../../../services/Api/swagger';
import ErreursConstante from '../../../../../_constantes/erreursConstantes.json';
import Libelle from '../../../../../_i18n/fr.json';
import {
  getConditionsAffichage,
  IConditionSituationPro,
  ISituationProfessionnellePresentation,
  isRevenuFiscalOk,
  isRevenuDeclareOk,
  showRevenusAnnuels,
  SituationProfessionnelleFormData,
  SituationProfessionnelleFormError,
  situationProfessionnelleModelToForm,
} from '../../helpers';
import styles from '../../style';
import AjouterSituationProfessionnelleUnePersonne from './AjouterSituationProfessionnelleUnePersonne';

interface CustomProps {
  formValues?: SituationProfessionnelleFormData;
  initValues?: SituationProfessionnelleFormData;
  listSituationProfessionnelle: SituationProfessionnelle[];
  dossierLocation: DossierLocation;
  handleChangeStatutProfessionnelle?: any;
  change: any;
  situationProFetching: boolean;
  onPrecedent?: any;
  typeCompte?: DossierParticulierDetailTypeCompteEnum;
  refDossierLocataire?: string;
  selectedDossier?: string;
  selectedPerson?: string;
  nomPrenomLocataire?: string;
  classes?: any;
  isCumulsUtilises: boolean;
}

type Props = InjectedFormProps<SituationProfessionnelleFormData> & CustomProps;

class AjouterSituationProfessionnelleForm extends React.Component<Props> {
  componentDidUpdate = () => {
    const { formValues } = this.props;
    if (
      formValues &&
      formValues.principal &&
      !showRevenusAnnuels(formValues.principal) &&
      formValues.principal.revenuIndividuelDeclare &&
      formValues.principal.codeStatutProfessionnel
    ) {
      this.props.change('principal.revenuIndividuelDeclare', null);
      this.props.change('principal.revenuFiscalReference', null);
    }

    if (
      formValues &&
      formValues.secondaire &&
      !showRevenusAnnuels(formValues.secondaire) &&
      formValues.secondaire.revenuIndividuelDeclare &&
      formValues.secondaire.codeStatutProfessionnel
    ) {
      this.props.change('secondaire.revenuIndividuelDeclare', null);
      this.props.change('secondaire.revenuFiscalReference', null);
    }
  };
  change = (field: string, value: any) => {
    this.props.change(field, value);
  };

  render() {
    const {
      listSituationProfessionnelle,
      handleSubmit,
      formValues,
      dossierLocation,
      onPrecedent,
      situationProFetching,
      typeCompte,
      refDossierLocataire,
      selectedDossier,
      selectedPerson,
      nomPrenomLocataire,
      classes,
      isCumulsUtilises,
    } = this.props;

    // calcul revenus fiscal de reference si célibataire
    const messageRevenusFiscal =
      formValues &&
      formValues.principal &&
      isRevenuFiscalOk(
        formValues,
        formValues.principal.revenuFiscalReference,
        dossierLocation.compositionLocation,
      );

    const messageRevenusDeclarePrincipal =
      formValues &&
      formValues.principal &&
      isRevenuDeclareOk(
        formValues.principal.revenuIndividuelDeclare,
        formValues.principal.salaireNetImposableM1,
        formValues.principal.salaireNetImposableM2,
        formValues.principal.salaireNetImposableM3,
        ' du candidat.',
      );

    const messageRevenusDeclareSecondaire =
      formValues &&
      formValues.secondaire &&
      isRevenuDeclareOk(
        formValues.secondaire.revenuIndividuelDeclare,
        formValues.secondaire.salaireNetImposableM1,
        formValues.secondaire.salaireNetImposableM2,
        formValues.secondaire.salaireNetImposableM3,
        ' du conjoint.',
      );

    const isCouple =
      dossierLocation &&
      dossierLocation.compositionLocation ===
      DossierLocationCompositionLocationEnum['COUPLE'];
    const showRevenusCandidat =
      formValues &&
      formValues.principal &&
      showRevenusAnnuels(formValues.principal);

    const showRevenusConjoint =
      formValues &&
      formValues.principal &&
      showRevenusAnnuels(formValues.secondaire);

    const labelRevenu = isCouple
      ? 'Revenu individuel déclaré candidat'
      : 'Revenu individuel déclaré';

    const titlePage =
      dossierLocation &&
      formValues &&
      getTitleCandidat(
        dossierLocation,
        formValues.principal.typeCompte || typeCompte,
        dossierLocation.dossiersParticuliers,
        refDossierLocataire || undefined,
        selectedPerson || undefined,
        nomPrenomLocataire || undefined,
      );

    const textSection1 =
      (formValues &&
        formValues.principal.typeCompte &&
        formValues.principal.typeCompte === 'GARANT') ||
        typeCompte === 'GARANT'
        ? 'sectionGarant'
        : 'section1';

    const isInvalid =
      formValues &&
      (formValues.principal.codeStatutProfessionnel === 'DIPLOM' ||
        formValues.principal.codeStatutProfessionnel === 'AMBASS');

    const isInvalidSecondaire =
      formValues &&
      formValues.secondaire &&
      (formValues.secondaire.codeStatutProfessionnel === 'DIPLOM' ||
        formValues.secondaire.codeStatutProfessionnel === 'AMBASS');

    const isDossierCouple =
      dossierLocation.compositionLocation ===
      DossierLocationCompositionLocationEnum['COUPLE'];

    return (
      <>
        <h5 className="titlePage">{titlePage}</h5>
        <form onSubmit={handleSubmit} noValidate>
          <h3>{Libelle.pageSituationPerso.sections[textSection1]}</h3>
          <AjouterSituationProfessionnelleUnePersonne
            prefixe="principal"
            situationsPros={listSituationProfessionnelle}
            formValues={formValues && formValues.principal}
            change={this.change}
            isCumulsUtilises={isCumulsUtilises}
          />
          {isCouple &&
            ((typeCompte && typeCompte !== 'GARANT') ||
              (formValues &&
                formValues.principal.typeCompte &&
                formValues.principal.typeCompte !== 'GARANT')) && (
              <>
                <Divider />
                <h3>{Libelle.pageSituationPro.sections.section2}</h3>
                <AjouterSituationProfessionnelleUnePersonne
                  prefixe="secondaire"
                  situationsPros={listSituationProfessionnelle}
                  formValues={formValues && formValues.secondaire}
                  change={this.change}
                  isCumulsUtilises={isCumulsUtilises}
                />
              </>
            )}

          {(showRevenusCandidat || showRevenusConjoint) && (
            <>
              <h3>{Libelle.pageSituationPro.sections.section3}</h3>
              <FormContainer>
                <Grid container spacing={2}>
                  {showRevenusCandidat && (
                    <>
                      <Grid item xs={3}>
                        <Field
                          name="principal.revenuIndividuelDeclare"
                          component={InputField}
                          type="number"
                          max={9999999}
                          label={labelRevenu}
                          required
                          imageHelpInfo={revenuIndividuelDeclare}
                          numberAfterDecimal={0}
                        />
                      </Grid>
                    </>
                  )}
                  {showRevenusConjoint && (
                    <Grid item xs={3}>
                      <Field
                        name="secondaire.revenuIndividuelDeclare"
                        component={InputField}
                        type="number"
                        max={9999999}
                        label="Revenu individuel déclaré conjoint"
                        required
                        imageHelpInfo={revenuIndividuelDeclare}
                        numberAfterDecimal={0}
                      />
                    </Grid>
                  )}
                  {(formValues &&
                    ((showRevenusConjoint && !showRevenusCandidat && formValues.secondaire && formValues.secondaire.revenuFiscalReference) ||
                     formValues.principal.revenuFiscalReference)) && (
                    <Grid item xs={3}>
                      <Field
                        name={
                          showRevenusConjoint && !showRevenusCandidat
                            ? 'secondaire.revenuFiscalReference'
                            : 'principal.revenuFiscalReference'
                        }
                        component={InputField}
                        type="number"
                        max={9999999}
                        label="Revenu fiscal de référence imposable"
                        imageHelpInfo={revenuFiscal}
                        required={isDossierCouple}
                        note={
                          !isDossierCouple &&
                          ErreursConstante.formulaire.generale
                            .referenceFiscalAvertissementCasAutreQueCouple
                        }
                        classNote={!isDossierCouple && classes.classNote}
                        numberAfterDecimal={0}
                      />
                    </Grid>
                  )}
                </Grid>
                {messageRevenusFiscal && (
                  <MessageInfo messageUrl={messageRevenusFiscal} />
                )}
                {messageRevenusDeclarePrincipal && (
                  <MessageInfo messageUrl={messageRevenusDeclarePrincipal} />
                )}
                {messageRevenusDeclareSecondaire && (
                  <MessageInfo messageUrl={messageRevenusDeclareSecondaire} />
                )}
              </FormContainer>
            </>
          )}
          <FormButtons
            onPrecedent={onPrecedent}
            fetching={situationProFetching}
            canCancel={selectedDossier}
            disabledButton={isInvalid || isInvalidSecondaire}
            messageButton={
              "Votre dossier ne peut être traité dans l'immédiat. Merci de bien vouloir contacter votre plateforme Vertuloo"
            }
          />
        </form>
      </>
    );
  }
}

const validerSituationProfessionnelle = (
  presentation: ISituationProfessionnellePresentation,
  conditionsValidation: IConditionSituationPro,
  compositionLocation: DossierLocationCompositionLocationEnum,
) => {
  const errors = {} as any;

  if (presentation && !presentation.situation) {
    errors.situation = ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (
    presentation &&
    (conditionsValidation.isSalarie ||
      conditionsValidation.isNonSalarieOrAutre) &&
    !presentation.codeStatutProfessionnel
  ) {
    errors.codeStatutProfessionnel =
      ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (presentation) {
    if (!presentation.dateDebutSituationProfessionnelle) {
      errors.dateDebutSituationProfessionnelle =
        ErreursConstante.formulaire.generale.champObligatoire;
    }
  }

  if (presentation && conditionsValidation.isCdiEssaiOrCddOrInterimaire) {
    if (!presentation.dateFinSituationProfessionnelle) {
      errors.dateFinSituationProfessionnelle =
        ErreursConstante.formulaire.generale.champObligatoire;
    } else if (
      presentation.dateDebutSituationProfessionnelle &&
      !compareDates(
        presentation.dateFinSituationProfessionnelle,
        presentation.dateDebutSituationProfessionnelle,
        true,
      )
    ) {
      errors.dateFinSituationProfessionnelle =
        ErreursConstante.formulaire.generale.dateSituationIncorrecte;
    }
  }

  if (
    presentation &&
    presentation.dateFinSituationProfessionnelle &&
    isDateInfAujourdhui(presentation.dateFinSituationProfessionnelle)
  ) {
    errors.dateFinSituationProfessionnelle =
      ErreursConstante.formulaire.generale.dateFinSituationAujourdhui;
  }

  if (
    presentation &&
    (conditionsValidation.isSalarie ||
      conditionsValidation.isNonSalarie ||
      conditionsValidation.isRechercheEmploi)
  ) {
    if (!presentation.nombreMoisTravailles) {
      errors.nombreMoisTravailles =
        ErreursConstante.formulaire.generale.champObligatoire;
    } else if (!isInteger(presentation.nombreMoisTravailles)) {
      errors.nombreMoisTravailles =
        ErreursConstante.formulaire.generale.champEntierErreur;
    } else if (Number(presentation.nombreMoisTravailles) > 24) {
      errors.nombreMoisTravailles =
        ErreursConstante.formulaire.generale.nombreMoisTravaillesSup24Mois;
    }
  }

  const dateDernierBulletinDateMin = moment(new Date())
    .startOf('month')
    .add(-2, 'month')
    .format(dateFormatApi);
  if (presentation && conditionsValidation.isSalarie) {
    if (
      !presentation.dateDernierBulletin ||
      (!isDateInfAujourdhui(presentation.dateDernierBulletin) ||
        !compareDates(
          presentation.dateDernierBulletin,
          dateDernierBulletinDateMin,
          false,
        ))
    ) {
      errors.dateDernierBulletin =
        ErreursConstante.formulaire.generale.champObligatoire;
    }
  }

  if (
    presentation &&
    conditionsValidation.isSalarie &&
    conditionsValidation.isDateActiviteAvant45j
  ) {
    if (!presentation.salaireNetImposableM1) {
      errors.salaireNetImposableM1 =
        ErreursConstante.formulaire.generale.champObligatoire;
    } else if (!isInteger(presentation.salaireNetImposableM1)) {
      errors.salaireNetImposableM1 =
        ErreursConstante.formulaire.generale.champEntierErreur;
    }
  }

  if (
    presentation &&
    conditionsValidation.isSalarie &&
    conditionsValidation.isDateActiviteAvant75j
  ) {
    if (!presentation.salaireNetImposableM2) {
      errors.salaireNetImposableM2 =
        ErreursConstante.formulaire.generale.champObligatoire;
    } else if (!isInteger(presentation.salaireNetImposableM2)) {
      errors.salaireNetImposableM2 =
        ErreursConstante.formulaire.generale.champEntierErreur;
    }
  }

  if (
    presentation &&
    conditionsValidation.isSalarie &&
    conditionsValidation.isDateActiviteAvant105j
  ) {
    if (!presentation.salaireNetImposableM3) {
      errors.salaireNetImposableM3 =
        ErreursConstante.formulaire.generale.champObligatoire;
    } else if (!isInteger(presentation.salaireNetImposableM3)) {
      errors.salaireNetImposableM3 =
        ErreursConstante.formulaire.generale.champEntierErreur;
    }
  }

  if (
    presentation &&
    conditionsValidation.isSalarie &&
    conditionsValidation.isDateDebutActivitePlus1an &&
    conditionsValidation.isDateActiviteAvant45j &&
    presentation.isCumulsUtilises
  ) {
    if (!presentation.cumulSalaireNetImposableM1) {
      errors.cumulSalaireNetImposableM1 =
        ErreursConstante.formulaire.generale.champObligatoire;
    } else if (!isInteger(presentation.cumulSalaireNetImposableM1)) {
      errors.cumulSalaireNetImposableM1 =
        ErreursConstante.formulaire.generale.champEntierErreur;
    }
  }

  if (
    presentation &&
    conditionsValidation.isSalarie &&
    conditionsValidation.isDateDebutActivitePlus1an &&
    conditionsValidation.isDateActiviteAvant75j &&
    presentation.isCumulsUtilises
  ) {
    if (!presentation.cumulSalaireNetImposableM2) {
      errors.cumulSalaireNetImposableM2 =
        ErreursConstante.formulaire.generale.champObligatoire;
    } else if (!isInteger(presentation.cumulSalaireNetImposableM2)) {
      errors.cumulSalaireNetImposableM2 =
        ErreursConstante.formulaire.generale.champEntierErreur;
    }
  }

  if (
    presentation &&
    conditionsValidation.isSalarie &&
    conditionsValidation.isDateDebutActivitePlus1an &&
    conditionsValidation.isDateActiviteAvant105j &&
    presentation.isCumulsUtilises
  ) {
    if (!presentation.cumulSalaireNetImposableM3) {
      errors.cumulSalaireNetImposableM3 =
        ErreursConstante.formulaire.generale.champObligatoire;
    } else if (!isInteger(presentation.cumulSalaireNetImposableM3)) {
      errors.cumulSalaireNetImposableM3 =
        ErreursConstante.formulaire.generale.champEntierErreur;
    }
  }

  if (
    presentation &&
    (conditionsValidation.isSalarie || conditionsValidation.isNonSalarie)
  ) {
    if (!presentation.codeApe) {
      errors.codeApe = ErreursConstante.formulaire.generale.champObligatoire;
    } else if (!estApeValide(presentation.codeApe)) {
      errors.codeApe = ErreursConstante.formulaire.generale.formatChampInvalide;
    }
  }

  if (presentation && conditionsValidation.isNonSalarie) {
    if (!presentation.siret) {
      errors.siret = ErreursConstante.formulaire.generale.champObligatoire;
    } else if (!estSiretValide(presentation.siret)) {
      errors.siret = ErreursConstante.formulaire.generale.formatChampInvalide;
    }
  }

  if (presentation && presentation.revenuFiscalReference && showRevenusAnnuels(presentation)) {
    if (
      !presentation.revenuFiscalReference &&
      compositionLocation === DossierLocationCompositionLocationEnum['COUPLE']
    ) {
      errors.revenuFiscalReference =
        ErreursConstante.formulaire.generale.champObligatoire;
    } else if (
      presentation.revenuFiscalReference &&
      !isInteger(presentation.revenuFiscalReference)
    ) {
      errors.revenuFiscalReference =
        ErreursConstante.formulaire.generale.champEntierErreur;
    }
  }

  if (presentation && showRevenusAnnuels(presentation)) {
    if (!presentation.revenuIndividuelDeclare) {
      errors.revenuIndividuelDeclare =
        ErreursConstante.formulaire.generale.champObligatoire;
    } else if (!isInteger(presentation.revenuIndividuelDeclare)) {
      errors.revenuIndividuelDeclare =
        ErreursConstante.formulaire.generale.champEntierErreur;
    }
  }
  return errors;
};

const validateForm = (
  values: SituationProfessionnelleFormData,
  props: Props,
): SituationProfessionnelleFormError => {
  const errors: SituationProfessionnelleFormError = {} as SituationProfessionnelleFormError;
  const conditionsAffichagePrincipal = getConditionsAffichage(
    values && values.principal,
  );
  const conditionsAffichageSecondaire = getConditionsAffichage(
    values && values.secondaire,
  );
  errors.principal = validerSituationProfessionnelle(
    values.principal,
    conditionsAffichagePrincipal,
    props.dossierLocation.compositionLocation,
  );
  errors.secondaire =
    values.secondaire &&
    validerSituationProfessionnelle(
      values.secondaire,
      conditionsAffichageSecondaire,
      props.dossierLocation.compositionLocation,
    );
  return errors;
};

const mapStateToProps = (state, props) => {
  const modifSituationProData = DossierLocationSelectors.getSituationProData(
    state,
  );
  const situationProData = props.initValues
    ? situationProfessionnelleModelToForm(
      props.initValues,
      props.listSituationProfessionnelle,
    )
    : modifSituationProData;
  return {
    initialValues: situationProData,
    formValues: getFormValues('situationProfessionnelleForm')(state),
    typeCompte: SituationPersoSelectors.getTypeCompte(state),
    refDossierLocataire: SituationPersoSelectors.getRefLocataireGarant(state),
    // si selectedDossier existe (et non la référence de l'object data, ça signifie qu'on est en modification d'un dossier)
    selectedDossier: DossierLocationSelectors.getSelectedDossier(state),
    selectedPerson: DossierLocationSelectors.getSelectedPerson(state),
    nomPrenomLocataire: SituationPersoSelectors.getNomLocataire(state),
    isCumulsUtilises: PartenaireSelectors.getCumulsUtilises(state),
  };
};

const mapDispatchToProps = () => ({
  change,
});

const reduxConnection = reduxForm<SituationProfessionnelleFormData>({
  form: 'situationProfessionnelleForm',
  validate: validateForm,
})(withStyles(styles)(AjouterSituationProfessionnelleForm));

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxConnection);
