import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import React from 'react';
import { Cookies, withCookies } from 'react-cookie';
import {
  getMatomoAnalyticsManager,
  getAcceptioCookieManager,
} from '../../services/Config';
import IFooter from '../Footer';
import IHeader from '../Header';
import MetaDatas from '../MetaDatas';
import stylesCommun from './styles';
import AxeptioInjector from '../../axeptio.injector';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  // Compossant souhaitant être rendu
  children: JSX.Element[] | JSX.Element;

  title?: string;
  classes: any;

  // Permet de varier le style CSS
  variant?: boolean;
  cookies: Cookies;
  noFollow?: boolean;
}

interface State {
  cookieValid: string;
  matomoInstance: string;
}
class Layout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const cookies = this.getCookies();
    this.state = {
      cookieValid: cookies,
      matomoInstance: '',
    };
  }
  /**
   * Récupération d'un cookie coté client
   */
  getCookies = () => {
    return this.props.cookies.get('performanceIsCheck');
  };

  componentWillMount() {
    const clientId = getAcceptioCookieManager();
    const instance = getMatomoAnalyticsManager();
    this.setState({ matomoInstance: instance });

    AxeptioInjector(clientId);
  }
  render() {
    const { classes, variant, children, title, noFollow } = this.props;
    return (
      <>
        <MetaDatas
          title={title}
          noFollow={noFollow}
          matomoTag={this.state.matomoInstance}
        />
        <IHeader cookiesValid={this.state.cookieValid === 'true'} />
        <main className={cx(classes.layout, { full: variant })}>
          {children}
        </main>
        <IFooter />
      </>
    );
  }
}

export default withCookies(withStyles(stylesCommun)(Layout));
