import Types from 'Types';
import { createAsyncAction, ActionType, getType } from 'typesafe-actions';
import { TypeVoie } from '../../services/Api/swagger';

export interface TypeVoieState {
  data?: TypeVoie[];
  fetchInfo?: Types.IFetchInfos;
}

export const initialState: TypeVoieState = {
  data: [],
  fetchInfo: {
    fetching: true,
    error: undefined,
  },
};

export const loadTypeVoieAsync = createAsyncAction(
  '@@App/typeVoie/LOAD_REQUEST',
  '@@App/typeVoie/LOAD_SUCCESS',
  '@@App/typeVoie/LOAD_FAILURE',
)<void, TypeVoie[], any>();

export const ActionCreators = {
  loadTypeVoieAsync,
};

const getListTypeVoie = (state: any): TypeVoie[] => state.typeVoie.data;

const getListTypeVoieFetching = (state: any): boolean =>
  state.typeVoie.fetchInfo && state.typeVoie.fetchInfo.fetching;

const getListTypeVoieError = (state: any): any =>
  state.typeVoie.fetchInfo && state.typeVoie.fetchInfo.error;

export const Selectors = {
  getListTypeVoie,
  getListTypeVoieFetching,
  getListTypeVoieError,
};

export const reducer = (
  state: TypeVoieState = initialState,
  action: ActionType<typeof ActionCreators>,
): TypeVoieState => {
  if (action.type === getType(loadTypeVoieAsync.request)) {
    return {
      ...state,
      data: undefined,
      fetchInfo: {
        fetching: true,
        error: undefined,
      },
    };
  } else if (action.type === getType(loadTypeVoieAsync.success)) {
    return {
      ...state,
      data: action.payload,
      fetchInfo: {
        fetching: false,
        error: undefined,
      },
    };
  } else if (action.type === getType(loadTypeVoieAsync.failure)) {
    return {
      ...state,
      data: undefined,
      fetchInfo: {
        fetching: false,
        error: action.payload,
      },
    };
  }
  return state;
};
