import { Grid, Link, Typography, withStyles } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import React from 'react';
import styles from './style';

interface IProps {
  messageUrl: string;
  url?: string;
  classes?: any;
  children?: Element | string;
}

const MessageInfo = (props: IProps) => (
  <Grid container className={props.classes.info}>
    <Info fontSize="small" className={props.classes.icon} />
    <Typography variant="body2">{props.children}</Typography>
    {props.url ? (
      <Link
        className="defaultLink"
        color="secondary"
        variant="body2"
        href={props.url}
        style={{ marginTop: '11px' }}
      >
        {props.messageUrl}
      </Link>
    ) : (
      <Typography dangerouslySetInnerHTML={{ __html: props.messageUrl }} />
    )}
  </Grid>
);

export default withStyles(styles)(MessageInfo);
