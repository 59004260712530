import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import Chip from '../../../../components/Chip';
import { RapportDossierParticulier } from '../../../../services/Api/swagger';
import styles from '../../SyntheseDossierLocationPage/Synthese/styles';
import CalculLocataire from './CalculLocataire';

interface Props {
  classes?: any;
  data?: RapportDossierParticulier[];
  typeDossier: string;
}

interface State {
  personSelected: string;
}
class CalculLocataires extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const defaultPersonSelected =
      props.data && props.data[0] && props.data[0].reference;
    this.state = {
      personSelected: defaultPersonSelected || '',
    };
  }

  handleSelectPerson = id => {
    this.setState({
      personSelected: id,
    });
  };

  render() {
    const { data, classes, typeDossier } = this.props;

    if (data && data.length > 0) {
      const personSelected = this.state.personSelected
        ? data.find(person => person.reference === this.state.personSelected)
        : data[0];
      const isConjoint =
        (personSelected &&
          typeDossier === 'COUPLE' &&
          !personSelected.principal) ||
        false;
      return (
        <Grid
          container
          alignItems="flex-start"
          justify="flex-start"
          className={classes.candidats}
        >
          {data.length === 1 && typeDossier === 'SEUL' ? (
            <CalculLocataire isConjoint={false} personne={data[0]} />
          ) : (
            <>
              <Grid container wrap="nowrap" alignItems="flex-start">
                <div className={classes.contentChips}>
                  {/* Selection de la personne */}
                  {data.map((personne, i) => {
                    let nomCandidat =
                      'Candidat(e)' +
                      ' ' +
                      personne.prenomLocataire +
                      ' ' +
                      personne.nomLocataire;
                    if (typeDossier === 'COUPLE' && !personne.principal) {
                      nomCandidat =
                        'Conjoint(e)' +
                        ' ' +
                        personne.prenomLocataire +
                        ' ' +
                        personne.nomLocataire;
                    }
                    return (
                      <Chip
                        active={
                          this.state.personSelected === personne.reference
                        }
                        key={i}
                        onClick={this.handleSelectPerson}
                        label={nomCandidat}
                        id={personne.reference || ''}
                      />
                    );
                  })}
                </div>
              </Grid>
              <CalculLocataire
                isConjoint={isConjoint}
                personne={personSelected}
              />
            </>
          )}
        </Grid>
      );
    }
    return null;
  }
}

export default withStyles(styles)(CalculLocataires);
