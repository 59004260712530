import React from 'react';

// tslint:disable: max-line-length
export default class CoupleSVG extends React.Component {
  render() {
    return (
      <svg width="83" height="83" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <circle id="path-1" cx="41.5" cy="41.5" r="41.5" />
          <polygon
            id="path-3"
            points="0.4727 0.386 33.8997 0.386 33.8997 34.1348 0.4727 34.1348"
          />
          <polygon
            id="path-5"
            points="0.0622 0.1933 36.7472 0.1933 36.7472 30 0.0622 30"
          />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <mask id="mask-2" fill="white">
            <use href="#path-1" />
          </mask>
          <use id="Mask" fill="rgba(92, 81, 117, 0.25)" href="#path-1" />
          <g id="Group-23" mask="url(#mask-2)">
            <g transform="translate(6.000000, 16.000000)">
              <g
                id="Group-3"
                strokeWidth="1"
                fill="none"
                transform="translate(1.000000, 0.614000)"
              >
                <mask id="mask-4" fill="white">
                  <use href="#path-3" />
                </mask>
                <g id="Clip-2" />
                <path
                  d="M17.1867,0.3858 C7.9567,0.3858 0.4727,7.9408 0.4727,17.2608 C0.4727,26.5798 7.9567,34.1348 17.1867,34.1348 C26.4167,34.1348 33.8997,26.5798 33.8997,17.2608 C33.8997,7.9408 26.4167,0.3858 17.1867,0.3858"
                  id="Fill-1"
                  fill="#FFFFFF"
                  mask="url(#mask-4)"
                />
              </g>
              <path
                d="M53.7267,1.1897 C44.4957,1.1897 37.0127,8.7447 37.0127,18.0647 C37.0127,27.3837 44.4957,34.9387 53.7267,34.9387 C62.9567,34.9387 70.4397,27.3837 70.4397,18.0647 C70.4397,8.7447 62.9567,1.1897 53.7267,1.1897"
                id="Fill-4"
                fill="#FFFFFF"
              />
              <path
                d="M7.884,56.7864 C13.507,61.9144 20.548,65.5054 28.347,66.9274 L29.294,38.8104 C27.868,38.1514 26.304,37.7394 24.655,37.6414 L24.339,37.9284 C20.835,41.1074 15.451,40.9714 12.113,37.6174 C10.276,37.6604 8.537,38.0954 6.967,38.8284 C7.035,38.8164 7.08,38.8104 7.08,38.8104 L7.884,56.7864 Z"
                id="Fill-6"
                fill="#FFFFFF"
              />
              <path
                d="M7.884,56.7864 L7.08,38.8104 C7.08,38.8104 7.035,38.8164 6.967,38.8284 C3.593,40.4044 1.017,43.3924 0,47.0354 C2.129,50.6734 4.787,53.9634 7.884,56.7864"
                id="Fill-8"
                fill="#000000"
              />
              <g
                id="Group-12"
                strokeWidth="1"
                fill="none"
                transform="translate(35.000000, 37.614000)"
              >
                <mask id="mask-6" fill="white">
                  <use href="#path-5" />
                </mask>
                <g id="Clip-11" />
                <path
                  d="M29.8332,1.3863 C28.4082,0.7273 26.8442,0.3153 25.1942,0.2173 L24.8792,0.5043 C21.3752,3.6833 15.9912,3.5473 12.6532,0.1933 C10.8152,0.2363 9.0772,0.6713 7.5072,1.4043 C4.5262,2.7963 2.1682,5.2903 0.9562,8.3633 C0.3812,9.8213 0.0622,11.4083 0.0622,13.0733 L0.0622,29.9803 C0.3192,29.9853 0.5762,30.0003 0.8362,30.0003 C1.1512,30.0003 1.4622,29.9833 1.7752,29.9763 C16.7232,29.6433 29.7132,21.4143 36.7472,9.2893 C35.6682,5.7783 33.1272,2.9103 29.8332,1.3863"
                  id="Fill-10"
                  fill="#FFFFFF"
                  mask="url(#mask-6)"
                />
              </g>
              <path
                d="M19.0295,45.8177 C19.0295,46.2397 18.6875,46.5827 18.2645,46.5827 C17.8425,46.5827 17.5005,46.2397 17.5005,45.8177 C17.5005,45.3957 17.8425,45.0527 18.2645,45.0527 C18.6875,45.0527 19.0295,45.3957 19.0295,45.8177"
                id="Fill-13"
                fill="#000000"
              />
              <path
                d="M19.0295,53.8235 C19.0295,54.2455 18.6875,54.5885 18.2645,54.5885 C17.8425,54.5885 17.5005,54.2455 17.5005,53.8235 C17.5005,53.4015 17.8425,53.0585 18.2645,53.0585 C18.6875,53.0585 19.0295,53.4015 19.0295,53.8235"
                id="Fill-15"
                fill="#000000"
              />
              <path
                d="M53.7267,46.7244 C53.7267,47.1464 53.3837,47.4884 52.9617,47.4884 C52.5397,47.4884 52.1977,47.1464 52.1977,46.7244 C52.1977,46.3024 52.5397,45.9594 52.9617,45.9594 C53.3837,45.9594 53.7267,46.3024 53.7267,46.7244"
                id="Fill-17"
                fill="#000000"
              />
              <path
                d="M53.7267,54.7303 C53.7267,55.1523 53.3837,55.4953 52.9617,55.4953 C52.5397,55.4953 52.1977,55.1523 52.1977,54.7303 C52.1977,54.3083 52.5397,53.9653 52.9617,53.9653 C53.3837,53.9653 53.7267,54.3083 53.7267,54.7303"
                id="Fill-19"
                fill="#000000"
              />
              <path
                d="M35.0617,67.5945 L35.0617,50.6865 C35.0617,49.0225 35.3807,47.4355 35.9567,45.9775 C34.7637,42.8025 32.3537,40.2265 29.2937,38.8105 L28.3467,66.9275 C30.5307,67.3255 32.7727,67.5525 35.0617,67.5945"
                id="Fill-21"
                fill="#000000"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
// tslint:enable: max-line-length
