import { withStyles } from '@material-ui/core';
import React from 'react';
import defaultStyles from '../../styles';
import styles from '../styles';
// import '@brainhubeu/react-carousel/lib/style.css';

interface IProps {
  image: any;
  altImage: string;
  title: string; // HTML IN IT
  nom: string;
  profession: string;
  idVideo: string;
  buttonTitle?: string;
  classes: any;
  cookiesValid: boolean;
}

const ComposantTemoignage = (props: IProps) => (
  <div className={props.classes.temoignage}>
    <div className={props.classes.containerBloc}>
      <div>
        <img src={props.image} width={195} alt={props.altImage} />{' '}
        <h3 dangerouslySetInnerHTML={{ __html: props.title }} />
        <p>{props.nom}</p>
        <p>{props.profession}</p>
      </div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={
          props.cookiesValid
            ? 'https://www.youtube.com/embed/' + props.idVideo
            : 'https://www.youtube-nocookie.com/embed/' + props.idVideo
        }
        // onClick={this.onClickEvent}
        className={props.classes.linkPlus}
      >
        {props.buttonTitle || 'Voir la vidéo'}
      </a>
    </div>
  </div>
);

export default withStyles(theme => ({
  ...styles(theme),
  ...defaultStyles(theme),
}))(ComposantTemoignage);
