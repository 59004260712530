import { Grid } from '@material-ui/core';
import React from 'react';
import { Field } from 'redux-form';
import FormContainer from '../../../components/FormContainer';
import InputField from '../../../components/input/InputField';
import InputCalendrier from '../../../components/inputCalendrier/InputCalendrier';
import Select from '../../../components/select/Select';
import { maxLocataireAge, minLocataireAge } from '../../../helpers/validators';
import Libelle from '../../../_i18n/fr.json';

interface IProps {
  qualites: [];
  classes?: any;
}

const SituationPersonnelleConjointForm = (props: IProps) => {
  return (
    <>
      <h3>{Libelle.pageSituationPerso.sections.section3}</h3>
      <FormContainer>
        <Grid container spacing={2}>
          <Grid item>
            <Field
              name="secondaire.qualite"
              component={Select}
              options={props.qualites}
              label="Qualité"
              required
            />
          </Grid>
          <Grid item>
            <Field
              name="secondaire.nom"
              component={InputField}
              label="Nom"
              maxLength={29}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name="secondaire.nomDeNaissance"
              component={InputField}
              label="Nom de naissance"
              maxLength={29}
            />
          </Grid>
          <Grid item>
            <Field
              name="secondaire.prenom"
              component={InputField}
              label="Prénom"
              maxLength={29}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name="secondaire.dateNaissance"
              component={InputCalendrier}
              label="Date de naissance"
              minDate={maxLocataireAge}
              maxDate={minLocataireAge}
              required
              variante="inline"
              varianteInput="filled"
            />
          </Grid>
        </Grid>
      </FormContainer>
    </>
  );
};

export default SituationPersonnelleConjointForm;
