import React from 'react';

// tslint:disable: max-line-length
export default class FlecheSVG extends React.Component {
  render() {
    return (
      <svg width="22" height="69" xmlns="http://www.w3.org/2000/svg">
        <path
          className="iconSVG"
          stroke="#3B3842"
          strokeWidth="2"
          d="M2 1l18 33L2 68"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}
// tslint:enable: max-line-length
