import { Theme } from '@material-ui/core/styles';
const styles = (theme: Theme) =>
  ({
    root: {},
    switchBase: {
      '&:hover': {
        backgroundColor: 'rgba(116, 107, 137, 0.2)',
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#877f99',
      },
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#7F9D00',
        opacity: 0.3,
      },
      '&.Mui-checked .MuiSwitch-thumb': {
        backgroundColor: '#7F9D00',
      },
    },
    track: {
      backgroundColor: '#5b5969',
    },
  } as any);

export default styles;
