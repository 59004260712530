import { Theme } from '@material-ui/core/styles';
import { bleu, couleurPrimaire } from '../../../../theme';

const styles = (_theme: Theme) =>
({
  container: {
    padding: '62px 12px',
    margin: 'auto',
    maxWidth: 880,
    display: 'flex',
    '& > div': {
      flex: 1,
    },
    '& h3': {
      textTransform: 'uppercase',
      color: bleu,
    },
    '& p': {
      color: '#494949',
    },
    '& h5': {
      fontSize: 16,
      fontWeight: 'bold',
      '& span': {
        fontSize: 13,
        color: '#222',
      },
    },
  },
  besse: {
    fontWeight: 'bold',
    fontSize: 26,
  },
  subtitle: {
    color: '#808080',
  },
  divider: {
    height: 2,
    background: bleu,
    width: 100,
    margin: '24px 0',
  },
  containerImage: {
    position: 'relative',
    textAlign: 'center',
    padding: 16,
    paddingTop: 42,
  },
  containerMaintenance: {
    display: 'flex',
    marginBottom: '10px',
  },
  maintenanceTexte: {
    marginLeft: '6px',
    color: '#000000',
  },
  ligne: {
    marginTop: '10px',
    color: couleurPrimaire,
    borderLeft: '4px solid',
    height: '70px',
  },
  dots: {
    left: 48,
    top: 12,
    position: 'absolute',
  },
  rectangle: {
    height: 266,
    width: 210,
    background: bleu,
    position: 'absolute',
    bottom: 46,
    right: 46,
    zIndex: -1,
  },
  '@media all and (max-width: 789px)': {
    container: {
      display: 'block',
    },
    dots: {
      display: 'none',
    },
    rectangle: {
      display: 'none',
    },
    containerImage: {
      '& img': {
        margin: 'auto',
        marginTop: 24,
        width: '90%',
      },
    },
  },
} as any);

export default styles;
