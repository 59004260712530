import { Theme } from '@material-ui/core';
import MaterialChip from '@material-ui/core/Chip';
import Check from '@material-ui/icons/Check';
import InvalidIcon from '@material-ui/icons/Error';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { erreur, gris2 } from '../../theme';
import UserIcon from '../../ressources/images/SituationPersoIconSVG';

interface Props {
  classes?: any;
  label: string;
  id: string;
  onClick(id: string): void;
  active: boolean;
  valid?: boolean;
  invalid?: boolean;
}
const styles = (theme: Theme) =>
  ({
    chip: {
      "marginRight": 4,
      "marginBottom": 16,
      "borderRadius": 0,
      '&::first-letter': {
        textTransform: 'uppercase',
      },
      '& svg': {
        marginLeft: 8,
      },
      '& span': {
        paddingLeft: 4,
      },
    },
    activeChip: {
      "background": gris2 + '!important',
      "color": 'white',
      '& path': {
        color: theme.palette.primary.main,
      },
      '& .cls-2': {
        fill: theme.palette.primary.main,
      },
    },
    validIcon: {
      fontSize: 17,
      marginLeft: 6,
      color: theme.palette.primary.main + '!important',
    },
    invalidIcon: {
      fontSize: 17,
      marginLeft: 6,
      color: erreur + '!important',
      fill: erreur + '!important',
    },
  } as any);
// tslint:enable: quotemark

const Chip = (props: Props) => {
  let statutIcon;
  if (props.valid) {
    statutIcon = <Check fontSize="small" className={props.classes.validIcon} />;
  } else if (props.invalid) {
    statutIcon = (
      <InvalidIcon fontSize="small" className={props.classes.invalidIcon} />
    );
  }
  return (
    <MaterialChip
      classes={{
        root: props.classes.chip,
        outlined: props.active && props.classes.activeChip,
      }}
      variant="outlined"
      icon={<UserIcon />}
      label={
        <>
          {props.label} {statutIcon}
        </>
      }
      clickable
      onClick={() => props.onClick(props.id)}
    />
  );
};

export default withStyles(styles)(Chip);
