import React from 'react';

// tslint:disable: max-line-length
export default class CalendarSVG extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
      >
        <g>
          <path
            id="a"
            d="M17.45 14.146l-11.6 3.41c-.743.218-1.502-.18-1.7-.892l-1.722-6.152 14.283-4.198 1.721 6.152c.2.712-.238 1.462-.981 1.68M2.549 5.756l1.795-.528.238.852c.107.382.525.616.942.494.417-.123.656-.532.544-.931L5.83 4.79 10.905 3.3l.238.852c.107.382.526.617.943.494.399-.117.656-.531.544-.93l-.238-.852 1.758-.517c.743-.219 1.501.179 1.7.892l.463 1.65L2.03 9.088l-.462-1.651c-.2-.713.238-1.462.981-1.68M13.756.937l-1.795.528-.238-.852c-.107-.383-.525-.617-.942-.494-.399.117-.656.531-.544.93l.238.852L5.418 3.39l-.238-.852c-.107-.382-.526-.616-.943-.494-.417.123-.655.531-.543.931l.238.852-1.777.522C.597 4.806-.314 6.39.1 7.868l2.582 9.227c.418 1.495 2.022 2.321 3.563 1.868l11.6-3.41c1.56-.458 2.47-2.042 2.057-3.519l-2.582-9.228c-.413-1.477-2.022-2.32-3.563-1.868"
          />
        </g>
        <use fill="#000" fillRule="evenodd" transform="translate(.667 .667)" />
      </svg>
    );
  }
}
// tslint:enable: max-line-length
