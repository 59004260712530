import { Theme } from '@material-ui/core/styles';

// tslint:disable: quotemark
const styles = (_theme: Theme) =>
({
  containerError: {
    margin: '-8px',
    padding: 0,
    width: 'calc(100% + 16px)',
    marginBottom: 24,
  },
  header: {
    margin: 0,
    width: '100%',
  },
  logoConteneur: {
    margin: 'auto',
  },
  logo: {
    width: 180,
  },
} as any);
// tslint:enable: quotemark

export default styles;
