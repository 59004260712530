import { withStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';

interface IProps {
  classes?: any;
}

const BlocBackground = (props: IProps) => (
  <div className={props.classes.container}>
    <div className={props.classes.texte}>
      <h2>Administrateurs de biens :</h2>
      <h1>
        <strong>sécurisez & développez votre activité</strong>
        <br />
        de gestion locative
      </h1>
      <h1>
        avec <span className="vertuloo-font-lg">vertuloo</span>
        <br />
        votre plateforme digitale
      </h1>
    </div>
    <div className={props.classes.imageBg} />
  </div>
);

export default withStyles(styles)(BlocBackground);
