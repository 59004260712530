import { Grid, Link, Typography, withStyles } from '@material-ui/core';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Bouton from '../../../components/bouton/Bouton';
import FormContainer from '../../../components/FormContainer';
import Layout from '../../../components/Layout';
import { Selectors as AdministrateurBienActionSelectors } from '../../../redux/AdministrateurDeBien';
import {
  ActionCreators as DossierLocationActionCreators,
  Selectors as DossierLocationActionSelectors,
} from '../../../redux/DossierLocation';
import { Selectors as UtilisateursActionSelectors } from '../../../redux/Utilisateurs';
import {
  AdministrateurBienDetail,
  DossierLocation,
  DossierLocationCompositionLocationEnum,
  Site,
  Utilisateur,
} from '../../../services/Api/swagger';
import Libelle from '../../../_i18n/fr.json';
import RenderError from '../../ErrorContainer';
import { mapTypeDossier } from './helpers';
import styles from './styles';

interface Props {
  classes?: any;
  dossierLocation?: DossierLocation;
  dossierLocationFetching: boolean;
  dossierLocationError: any;
  utilisateurConnecte?: Utilisateur;
  adbDetail?: AdministrateurBienDetail[];
  adbId?: number;
  selectedSite?: Site;
  ajouterOuModifierDossierLocation(dossierLocation: DossierLocation): void;
  changeCompositionLocation(
    composition: DossierLocationCompositionLocationEnum,
  ): void;
  supprimerErrorDossierLocation(): void;
}

interface State {
  dossierLocation?: DossierLocation;
}

class PreformulairePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let { dossierLocation } = this.props;
    // par default on créé un dossier de type SEUL
    if (!dossierLocation) {
      dossierLocation = {
        // si l'adbId n'existe pas c'est qu'il n'y a pas eu de sélection d'adb et donc que l'adb est unique
        referenceAdb:
          (this.props.adbId && this.props.adbId) ||
          (this.props.adbDetail && this.props.adbDetail[0].identifiant),
        numeroSite: this.props.selectedSite && this.props.selectedSite.numero,
        createur:
          this.props.utilisateurConnecte &&
          this.props.utilisateurConnecte.identifiant,
        compositionLocation: DossierLocationCompositionLocationEnum['SEUL'],
      } as DossierLocation;
    }

    this.state = {
      dossierLocation,
    };
  }

  handleChangerType(type, event) {
    event.preventDefault();
    const newState = {
      ...this.state,
      dossierLocation: {
        ...this.state.dossierLocation,
        compositionLocation: DossierLocationCompositionLocationEnum[type],
      } as DossierLocation,
    };
    this.setState(newState);
  }

  /**
   * Validation du formulaire.
   */
  validerFormulaire = () => {
    const { ajouterOuModifierDossierLocation } = this.props;
    if (this.state.dossierLocation) {
      ajouterOuModifierDossierLocation(this.state.dossierLocation);
    }
  };

  render() {
    const {
      classes,
      dossierLocationFetching,
      dossierLocationError,
      supprimerErrorDossierLocation,
    } = this.props;

    const { dossierLocation } = this.state;

    return (
      <Layout title={Libelle.pagePreFormulaire.titre}>
        <div className={classes.containerAll}>
          <Link href="/dashboard" color="inherit" className="backLink">
            <ArrowLeft /> Retour au dashboard
          </Link>
          <div className={classes.container}>
            <FormContainer>
              <Grid container justify="space-around">
                <Grid item xs={12}>
                  {Libelle.pagePreFormulaire.description}
                </Grid>
                {mapTypeDossier.map((type, index) => {
                  return (
                    <Grid item key={index} xs={4}>
                      <input
                        type="radio"
                        id={type.name}
                        name="typeDossier"
                        value={type.name}
                        checked={
                          dossierLocation &&
                          dossierLocation.compositionLocation ===
                            DossierLocationCompositionLocationEnum[type.name]
                        }
                        onChange={() => {}}
                        className={classes.boutonRadio}
                      />
                      <label
                        htmlFor={type.name}
                        onClick={event =>
                          this.handleChangerType(type.name, event)
                        }
                        className={classes.blocLibelleTypeDossier}
                      >
                        {type.img}
                        <Typography
                          component="span"
                          className={classes.libelleTypeDossier}
                        >
                          {type.libelle}
                        </Typography>
                        {type.libelleComplementaire.length > 0 && (
                          <Typography
                            component="span"
                            className={classes.libelleTypeDossier}
                            dangerouslySetInnerHTML={{
                              __html: type.libelleComplementaire,
                            }}
                          />
                        )}
                      </label>
                    </Grid>
                  );
                })}
              </Grid>
            </FormContainer>
          </div>
          <Grid container justify="center" className={classes.conteneurAction}>
            <Grid item>
              <Bouton
                label={Libelle.bouton.suivant}
                variant="contained"
                color="primary"
                size="medium"
                fetching={dossierLocationFetching}
                onClick={this.validerFormulaire}
              />
            </Grid>
          </Grid>
          <Grid container justify="flex-end" style={{ marginTop: 12 }}>
            {dossierLocationError ? (
              <RenderError
                errors={dossierLocationError}
                onClose={supprimerErrorDossierLocation}
              />
            ) : (
              ''
            )}
          </Grid>
        </div>
      </Layout>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ajouterOuModifierDossierLocation: (dossierLocation: DossierLocation) =>
    dispatch(
      DossierLocationActionCreators.createOrUpdateDossierLocationAsync.request(
        dossierLocation,
      ),
    ),
  changeCompositionLocation: (
    composition: DossierLocationCompositionLocationEnum,
  ) =>
    dispatch(
      DossierLocationActionCreators.changeCompositionLocation(composition),
    ),
  supprimerErrorDossierLocation: () =>
    dispatch(DossierLocationActionCreators.supprimerDossierLocationError()),
});

const mapStateToProps = state => ({
  dossierLocation: DossierLocationActionSelectors.getDossierLocation(state),
  utilisateurConnecte: UtilisateursActionSelectors.getUtilisateurConnecte(
    state,
  ),
  adbDetail: AdministrateurBienActionSelectors.getADBDetail(state),
  adbId: AdministrateurBienActionSelectors.getAdbId(state),
  selectedSite: AdministrateurBienActionSelectors.getSelectedSite(state),
  dossierLocationFetching: DossierLocationActionSelectors.getDossierLocationFetching(
    state,
  ),
  dossierLocationError: DossierLocationActionSelectors.getDossierLocationError(
    state,
  ),
});
export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PreformulairePage),
);
