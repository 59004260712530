import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from './styles';
import ContactSupport from '@material-ui/icons/ContactSupport';

interface Props {
  classes?: any;
  cookiesValid: boolean;
}

class BulleFixed extends React.Component<Props> {
  onClickEvent = () => {
    const path = window.location.pathname;
    const libelle =
      path === '/en-savoir-plus'
        ? 'En savoir plus - Bouton fixe'
        : 'Accueil - Bouton fixe contact';

    if (window.MatomoTagManager) {
      window._paq.push(['trackEvent', 'Contact', 'Clic - CTA', libelle]);
    }
  };
  render() {
    const { classes } = this.props;

    const path = window.location.pathname;
    const link =
      path === '/en-savoir-plus' ? '/en-savoir-plus/#contact' : '/#contact';
    return (
      <div className={classes.container}>
        <a href={link} onClick={this.onClickEvent}>
          <ContactSupport fontSize="default" className={classes.validIcon} />{' '}
          <span>
            Essayer <span className="vertuloo-font-mdSm">vertuloo</span>
            <br />
            <span className={classes.text2}>gratuitement</span>
          </span>
        </a>
      </div>
    );
  }
}

export default withStyles(styles)(BulleFixed);
