import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { CSSProperties, HTMLAttributes } from 'react';
import Select from 'react-select';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { ControlProps } from 'react-select/src/components/Control';
import { MenuProps, NoticeProps } from 'react-select/src/components/Menu';
import { OptionProps } from 'react-select/src/components/Option';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { ValueType } from 'react-select/src/types';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import dropdown from '../../ressources/images/dropdown.svg';
import styles from './styles';

interface OptionType {
  label: string;
  value: string;
}

interface IProps {
  disabled?: boolean;
  initialValue?: string;
  input?: WrappedFieldInputProps;
  label: string;
  meta: WrappedFieldMetaProps;
  options: [];
  readOnly?: boolean;
  required?: boolean; // Le champ est-il requis ?
  classes?: any;
  placeholder?: string;
  onChangeSelection?(value): void;
}
interface IState {
  value?: ValueType<OptionType> | number;
  options?: any;
}
function NoOptionsMessage(props: NoticeProps<OptionType>) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      Pas de suggestions
    </Typography>
  );
}

// Message de chargement des suggestions
function LoadingMessage(props) {
  return (
    <Typography
      color="textSecondary"
      {...props.innerProps}
      className={props.selectProps.classes.messages}
    >
      Chargement...
    </Typography>
  );
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> &
  HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<OptionType>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      value="dfdf"
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

function Option(props: OptionProps<OptionType>) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function SingleValue(props: SingleValueProps<OptionType>) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props: ValueContainerProps<OptionType>) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props: MenuProps<OptionType>) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  LoadingMessage,
  Option,
  SingleValue,
  ValueContainer,
};

const svgIcon = props => <img src={dropdown} {...props} alt="" />;
class AutoComplete extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      value:
        (props.input && props.input.value) ||
        (props.meta && props.meta.initial) ||
        '',
      options: [],
    };
  }

  componentDidUpdate(prevProps: IProps) {
    const value =
      (this.props.input && this.props.input.value) ||
      (this.props.meta && this.props.meta.initial) ||
      '';
    if (prevProps.options !== this.props.options) {
      this.setState({
        options: this.props.options,
      });
    }
    if (prevProps && prevProps.meta && prevProps.meta.initial !== value) {
      this.setState({
        value,
      });
    }
  }

  handleSearch = value => {
    if (value && value.length > 1 && this.props.onChangeSelection) {
      if (isNaN(value)) {
        this.props.onChangeSelection({ nom: value });
      } else {
        this.props.onChangeSelection({ codePostal: value });
      }
    }
    if (this.props.options !== this.state.options) {
      this.setState({
        options: this.props.options,
      });
    }
    this.setState({
      options: this.props.options,
    });
  };

  handleChange = value => {
    this.setState({
      value,
      options: [],
    });
    if (this.props.input) {
      this.props.input.onChange(value);
    }
  };

  handleBlur = () => {
    this.setState({
      options: [],
    });
  };
  render() {
    const { meta, classes, required } = this.props;
    const { value, options } = this.state;

    const selectStyles = {
      input: (base: CSSProperties) => ({
        ...base,
        color: '#000',
        padding: '15px 12px 7px 0',
        'font-size': '0.9rem',
        'font-weight': 'bold',
        '& input': {
          font: 'inherit',
        },
      }),
      indicatorsContainer: (base: CSSProperties) => ({
        ...base,
        width: '40px',
        background: 'url(' + dropdown + ')',
        '& svg': { opacity: '0' },
        '& span': { opacity: 0 },
      }),
    };
    return (
      <div className={classes.root}>
        <Select
          classes={classes}
          styles={selectStyles}
          inputId="react-select-single"
          TextFieldProps={{
            label: 'Ville (Code postal)',
            required: required,
            InputLabelProps: {
              htmlFor: 'react-select-single',
              shrink: true,
              className: value
                ? 'AutoComplete-label AutoComplete-labelShrink'
                : 'AutoComplete-label',
            },
          }}
          required={required}
          isClearable
          onBlur={this.handleBlur}
          placeholder=""
          options={options}
          components={components}
          value={value}
          onInputChange={this.handleSearch}
          onChange={this.handleChange}
          variant="filled"
          InputProps={{
            classes: {
              underline: classes.underline,
            },
          }}
          SelectProps={{
            classes: {
              root: classes.textField,
              icon: classes.iconSelect,
              select: classes.select,
            },
            native: true,
            IconComponent: svgIcon,
          }}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabel,
              focused: classes.textFieldLabelFocused,
              error: classes.textFieldLabelError,
              asterisk: classes.textFieldLabelAsterisk,
            },
          }}
        />
        {meta && meta.touched && meta.error && (
          <Typography color="error" variant="caption">
            {meta.error}
          </Typography>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(AutoComplete);
