import { Grid, Link, Theme } from '@material-ui/core';
import PrevIcon from '@material-ui/icons/ChevronLeft';
import NextIcon from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import Bouton from '../bouton/Bouton';
import Error from '../Error';

interface Props {
  classes?: any;
  fetching: boolean;
  onPrecedent(): void;
  onClick?(): void;
  canCancel?: string;
  disabledButton?: boolean;
  messageButton?: string;
}
const styles = (theme: Theme) => ({} as any);
// tslint:enable: quotemark

const FormButtons = (props: Props) => {
  return (
    <Grid container spacing={10} justify="space-between">
      <Grid item>
        <Bouton
          label="Précédent"
          variant="contained"
          color="default"
          onClick={props.onPrecedent}
          size="medium"
          icon={<PrevIcon />}
        />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="flex-end">
          {props.onClick && !props.disabledButton && (
            <Bouton
              label="Enregistrer et suivant"
              variant="contained"
              color="primary"
              size="medium"
              onClick={props.onClick}
              fetching={props.fetching}
              icon={<NextIcon />}
            />
          )}
          {!props.onClick && !props.disabledButton && (
            <Bouton
              label="Enregistrer et suivant"
              variant="contained"
              color="primary"
              size="medium"
              type="submit"
              fetching={props.fetching}
              icon={<NextIcon />}
            />
          )}
          {props.disabledButton && props.messageButton && (
            <Error text={props.messageButton} />
          )}
          {props.canCancel && (
            <Link
              href="/dossier-location/synthese"
              className="defaultLink cancelLink"
            >
              Retour à la synthèse
            </Link>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(FormButtons);
