import { TextField, withStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import isEqual from 'lodash.isequal';
import React, { ChangeEvent, ComponentType } from 'react';
import {
  WrappedFieldInputProps,
  WrappedFieldMetaProps,
  WrappedFieldProps,
} from 'redux-form';
import dropdown from '../../ressources/images/dropdown.svg';
import styles from './styles';

export interface DropdownItem {
  key: string | number;
  text: string;
  value: string | number;
  simpleField?: boolean;
  size?: number;
}

interface IOwnProps {
  disabled?: boolean;
  initialValue?: string;
  input?: WrappedFieldInputProps;
  label: string;
  meta?: WrappedFieldMetaProps;
  options: DropdownItem[];
  readOnly?: boolean;
  required?: boolean; // Le champ est-il requis ?
  classes?: any;
  placeholder?: string;
}

interface IState {
  value?: string | number;
}

const svgIcon = props => <img src={dropdown} {...props} alt="" />;

type IProps = IOwnProps & ComponentType<WrappedFieldProps>;

class Select extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    let value =
      (props.options.length > 0 &&
        ((props.input && props.input.value) ||
          (props.meta && props.meta.initial))) ||
      '';
    if (value && props.options.length > 0) {
      const findOption =
        props.options.filter(o => o.value === value).length > 0;
      if (!findOption) {
        value = '';
      }
    }
    this.state = {
      value,
    };
  }

  updateValue(value) {
    this.setState({
      value,
    });
    if (this.props.input) {
      this.props.input.onChange(value);
    }
  }

  componentDidUpdate(prevProps) {
    const props = this.props;
    const value =
      (props.options.length > 0 &&
        ((props.input && props.input.value) ||
          (props.meta && props.meta.initial))) ||
      '';

    if (prevProps && props && !isEqual(prevProps.options, props.options)) {
      this.updateValue('');
    } else if (
      prevProps &&
      prevProps.input.value !== value &&
      prevProps.options.length > 0 &&
      prevProps.options
    ) {
      this.updateValue(value);
    }
  }

  handleChange = (e: ChangeEvent<any>) => {
    e.preventDefault();
    this.setState({ value: e.target.value });
    if (this.props.input) {
      this.props.input.onChange(e.target.value);
    }
  };

  render() {
    const {
      disabled,
      label,
      meta,
      options,
      required,
      readOnly,
      placeholder,
      classes,
    } = this.props;

    const { value } = this.state;

    return (
      <FormControl className={classes.root}>
        <TextField
          id="outlined-select"
          error={meta && meta.touched && !!meta.error}
          select
          label={label}
          value={value}
          onChange={this.handleChange}
          required={required}
          disabled={disabled || readOnly}
          InputProps={{
            classes: {
              underline: classes.underline,
            },
          }}
          SelectProps={{
            classes: {
              root: classes.textField,
              icon: classes.iconSelect,
              select: classes.select,
            },
            native: true,
            IconComponent: svgIcon,
          }}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabel,
              focused: classes.textFieldLabelFocused,
              error: classes.textFieldLabelError,
              asterisk: classes.textFieldLabelAsterisk,
            },
          }}
          variant="filled"
          className={classes.textFieldRoot}
        >
          <option disabled value="">
            {placeholder}
          </option>
          {options.map(option => (
            <option key={option.key} value={option.value}>
              {option.text}
            </option>
          ))}
        </TextField>
        {meta && meta.touched && meta.error && (
          <Typography color="error" variant="caption">
            {meta.error}
          </Typography>
        )}
      </FormControl>
    );
  }
}
export default withStyles(styles)(Select);
