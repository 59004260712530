import { CircularProgress } from '@material-ui/core';
import { ConnectedRouter } from 'connected-react-router';
import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { Persistor, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { history } from '../../helpers/history';
import configureStore from '../../redux/configureStore';
import rootSaga from '../../sagas';

interface Props {
  children: ReactNode;
}

function ReduxProvider(props: Props) {
  let store: Store;
  let persistor: Persistor;

  // Création du store Redux
  const initialState = {};
  store = configureStore(initialState, history);

  // Démarrage de la root saga
  //@ts-ignore
  store.runSaga(rootSaga);

  persistor = persistStore(store);

  return (
    <Provider store={store}>
      <PersistGate loading={<CircularProgress />} persistor={persistor}>
        <ConnectedRouter history={history}>{props.children}</ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default ReduxProvider;
