import memoizeOne from 'memoize-one';
import { formatterEspaces } from '../../../helpers/formatters';
import {
  isActiviteAvantXjours,
  isActiviteAvantDernierBulletin,
  isDateDebutSituationPlus1an,
} from '../../../helpers/validators';
import {
  DossierLocationCompositionLocationEnum,
  DossierParticulierDetail,
  SituationProfessionnelle,
  SituationProfessionnelleDossierParticulier,
  StatutProfessionnel,
  DossierLocation,
} from '../../../services/Api/swagger';

export interface ISituationProfessionnellePresentation {
  situation: string;
  codeStatutProfessionnel?: string;
  dateDebutSituationProfessionnelle?: string;
  dateFinSituationProfessionnelle?: string;
  nombreMoisTravailles?: number;
  pasAvisImposition?: boolean;
  salaireNetImposableM1?: number;
  salaireNetImposableM2?: number;
  salaireNetImposableM3?: number;
  cumulSalaireNetImposableM1?: number;
  cumulSalaireNetImposableM2?: number;
  cumulSalaireNetImposableM3?: number;
  revenuIndividuelDeclare?: number;
  revenuFiscalReference?: number;
  dateDernierBulletin?: string;
  codeApe?: string;
  siret?: string;
  reference?: string;
  typeCompte?: 'LOCATAIRE' | 'GARANT';
  isCumulsUtilises?: boolean;
}

export interface SituationProfessionnelleFormData {
  principal: ISituationProfessionnellePresentation;
  secondaire?: ISituationProfessionnellePresentation;
}

export interface SituationProfessionnelleFormError {
  principal: any;
  secondaire?: any;
}

export const getId = (
  typeCompte: 'principal' | 'secondaire',
  attribut: string,
): string => typeCompte + '.' + attribut;

const construireSituationsProfessionnelles = (
  situationsProfessionnelles: SituationProfessionnelle[],
) => {
  return situationsProfessionnelles
    ? situationsProfessionnelles.map(s => ({
      key: s.code,
      text: s.libelle,
      value: s.code,
    }))
    : [];
};

const construireStatutsProfessionnels = (
  statutsProfessionnelles: StatutProfessionnel[],
) => {
  return statutsProfessionnelles
    ? statutsProfessionnelles.map(s => ({
      key: s.code,
      text: s.libelle,
      value: s.code,
    }))
    : [];
};

export const memoizeConstruireSituationsProfessionnelles = memoizeOne(
  construireSituationsProfessionnelles,
);

export const memoizeConstruireStatutsProfessionnels = memoizeOne(
  construireStatutsProfessionnels,
);

export const situationProfessionnelleFormToModel = (
  situationProfessionnelleForm: SituationProfessionnelleFormData,
  dossierLocation: DossierLocation,
): SituationProfessionnelleDossierParticulier => {
  let model = {};
  const { principal, secondaire } = situationProfessionnelleForm;
  const dossierLocationPrincipal =
    dossierLocation !== undefined &&
    dossierLocation.dossiersParticuliers !== undefined &&
    dossierLocation.dossiersParticuliers.find(
      locataire =>
        situationProfessionnelleForm &&
        locataire.reference ===
        situationProfessionnelleForm.principal.reference,
    );

  const situationPrincipaleFormattage = {
    ...principal,
    nombreMoisTravailles: Number(principal.nombreMoisTravailles),
    siret: formatterEspaces(principal.siret),
    statut: dossierLocationPrincipal ? dossierLocationPrincipal.statut : '',
  };
  model = {
    principal: situationPrincipaleFormattage,
  };

  if (secondaire) {
    const dossierLocationSecondaire =
      dossierLocation !== undefined &&
      dossierLocation.dossiersParticuliers !== undefined &&
      situationProfessionnelleForm.secondaire !== undefined &&
      dossierLocation.dossiersParticuliers.find(
        locataire =>
          situationProfessionnelleForm &&
          locataire.reference ===
          // @ts-ignore
          situationProfessionnelleForm.secondaire.reference,
      );
    const situationConjointFormattage = {
      ...secondaire,
      nombreMoisTravailles: Number(secondaire.nombreMoisTravailles),
      siret: formatterEspaces(secondaire.siret),
      statut: dossierLocationSecondaire ? dossierLocationSecondaire.statut : '',
    };
    model = {
      ...model,
      secondaire: situationConjointFormattage,
    };
  }
  return model;
};

export const situationProfessionnelleModelToForm = (
  situationModel: SituationProfessionnelleFormData | undefined,
  listSituationProfessionnelle: SituationProfessionnelle[],
): SituationProfessionnelleFormData => {
  let form = {} as SituationProfessionnelleFormData;
  if (situationModel) {
    const situationPrincipal =
      situationModel.principal &&
      situationModel.principal.codeStatutProfessionnel &&
      getSituationProfessionnelleFromCodeStatut(
        situationModel.principal.codeStatutProfessionnel || '',
        listSituationProfessionnelle,
      );

    form = {
      principal: {
        situation: situationPrincipal,
        ...situationModel.principal,
      },
    } as SituationProfessionnelleFormData;

    if (situationModel.secondaire) {
      const situationConjoint =
        situationModel.secondaire &&
        situationModel.secondaire.codeStatutProfessionnel &&
        getSituationProfessionnelleFromCodeStatut(
          situationModel.secondaire.codeStatutProfessionnel || '',
          listSituationProfessionnelle,
        );
      form = {
        ...form,
        secondaire: {
          situation: situationConjoint,
          ...situationModel.secondaire,
        },
      };
    }
  }

  return form;
};

const getSituationProfessionnelleFromCodeStatut = (
  codeSituation: string,
  listSituationProfessionnelle: SituationProfessionnelle[],
): string => {
  const situation =
    (listSituationProfessionnelle &&
      listSituationProfessionnelle.length > 0 &&
      listSituationProfessionnelle.find(
        situation =>
          situation &&
          situation.statutsProfessionnels &&
          situation.statutsProfessionnels.find(
            statut => statut.code === codeSituation,
          ),
      )) ||
    undefined;

  return (situation && situation.code) || '';
};

export interface IConditionSituationPro {
  isSalarie: boolean;
  isNonSalarie: boolean;
  isRechercheEmploi: boolean;
  isDteDbtStAt30jDteJr: boolean;
  isDateActiviteAvant45j: boolean;
  isDateActiviteAvant75j: boolean;
  isDateActiviteAvant105j: boolean;
  isDateDebutActivitePlus1an: boolean;
  isNonSalarieOrAutre: boolean;
  isCdiEssaiOrCddOrInterimaire: boolean;
  isCdiOrAssistanteMatOrCddOrInterimaire: boolean;
  isIntermittentRechercheEmploi: boolean;
  isEtudiantOuSansActivite: boolean;
}

export const getConditionsAffichage = (
  formValues: ISituationProfessionnellePresentation | undefined,
) => {
  const isSalarie =
    formValues &&
    (formValues.situation === 'SPR' || formValues.situation === 'SPU');
  const isRechercheEmploi = formValues && formValues.situation === 'RCH';
  const isNonSalarie = formValues && formValues.situation === 'TNS';
  const isNonSalarieOrAutre =
    (formValues && formValues.situation === 'AUT') || isNonSalarie;
  const dateDebutActivite =
    formValues && formValues.dateDebutSituationProfessionnelle;
  const dernierBulletinSalaire = formValues && formValues.dateDernierBulletin;
  const isDateActiviteAvant45j = isActiviteAvantDernierBulletin(
    dateDebutActivite,
    dernierBulletinSalaire,
    0,
  );
  const isDateActiviteAvant75j = isActiviteAvantDernierBulletin(
    dateDebutActivite,
    dernierBulletinSalaire,
    1,
  );
  const isDateActiviteAvant105j = isActiviteAvantDernierBulletin(
    dateDebutActivite,
    dernierBulletinSalaire,
    2,
  );
  const isDateDebutActivitePlus1an = isDateDebutSituationPlus1an(dateDebutActivite);
  const isDteDbtStAt30jDteJr = isActiviteAvantXjours(dateDebutActivite, -30);
  const isCdiEssaiOrCddOrInterimaire =
    formValues &&
    (formValues.codeStatutProfessionnel === 'CDIESS' ||
      formValues.codeStatutProfessionnel === 'INTERIM' ||
      formValues.codeStatutProfessionnel === 'CDD');

  const isCdiOrAssistanteMatOrCddOrInterimaire =
    isCdiEssaiOrCddOrInterimaire ||
    (formValues &&
      (formValues.codeStatutProfessionnel === 'CDICONF' ||
        formValues.codeStatutProfessionnel === 'ASSMAT'));

  const isIntermittentRechercheEmploi =
    formValues &&
    (formValues.codeStatutProfessionnel === 'INTERMT' ||
      formValues.situation === 'RCH');

  const isEtudiantOuSansActivite =
    formValues &&
    (formValues.situation === 'ETU' || formValues.situation === 'SAC')

  return {
    isSalarie,
    isNonSalarie,
    isRechercheEmploi,
    isDateActiviteAvant45j,
    isDateActiviteAvant75j,
    isDateActiviteAvant105j,
    isDateDebutActivitePlus1an,
    isDteDbtStAt30jDteJr,
    isNonSalarieOrAutre,
    isCdiEssaiOrCddOrInterimaire,
    isCdiOrAssistanteMatOrCddOrInterimaire,
    isIntermittentRechercheEmploi,
    isEtudiantOuSansActivite,
  } as IConditionSituationPro;
};

export const isRevenuFiscalOk = (formValues, revenuFiscal, composition) => {
  if (
    composition &&
    composition !== DossierLocationCompositionLocationEnum['COUPLE']
  ) {
    const revenusIndividuels = formValues.principal.revenuIndividuelDeclare;

    const formule =
      Math.abs(revenuFiscal - revenusIndividuels) /
      Math.min(revenuFiscal, revenusIndividuels);
    return revenuFiscal &&
      revenuFiscal.length > 3 &&
      revenusIndividuels &&
      revenusIndividuels.length > 3 &&
      formule > 0.3
      ? 'Nous détectons un écart important entre vos revenus imposables et vos revenus déclarés. <br />Si le revenu fiscal de référence ne correspond pas à votre situation actuelle MERCI de ne pas le renseigner'
      : '';
  } else return '';
};

export const isRevenuDeclareOk = (
  revenuDeclare,
  salaireNetImposableM1,
  salaireNetImposableM2,
  salaireNetImposableM3,
  candidatOuConjoint,
) => {
  const x =
    (salaireNetImposableM1 ? 1 : 0) +
    (salaireNetImposableM2 ? 1 : 0) +
    (salaireNetImposableM3 ? 1 : 0);
  const somme =
    parseFloat(salaireNetImposableM1 ? salaireNetImposableM1 : 0) +
    parseFloat(salaireNetImposableM2 ? salaireNetImposableM2 : 1) +
    parseFloat(salaireNetImposableM3 ? salaireNetImposableM3 : 0);

  // tslint:disable-next-line:no-unused-expression
  const formule = Math.abs((revenuDeclare / somme) * (x / 12) - 1);
  const formule2 = Math.abs((somme / revenuDeclare) * (12 / x) - 1);

  return x > 0 &&
    revenuDeclare &&
    revenuDeclare.length > 3 &&
    (formule > 0.3 || formule2 > 0.3)
    ? 'Nous détectons un écart important entre les revenus  imposables et les salaires actuels' +
    candidatOuConjoint
    : '';
};

export const showRevenusAnnuels = data => {
  return (
    data &&
    data.situation &&
    (data.situation === 'AUT' ||
      data.situation === 'RET' ||
      data.situation === 'SPU' ||
      data.situation === 'SPR' ||
      data.situation === 'TNS' ||
      data.situation === 'RCH') &&
      !data.pasAvisImposition
  );
};

export const dossierParticulierToModificationSituationProfessionnelle = (
  dossierParticulier: DossierParticulierDetail,
  situationProConjoint,
  listesProfessionnelles,
) => {
  const {
    qualite,
    nom,
    nomDeNaissance,
    prenom,
    dateNaissance,
    email,
    numeroBatiment,
    typeVoie,
    numero,
    adresse,
    codePostal,
    ville,
    telephonePortable,
    telephoneFixe,
    dateEntree,
    statutOccupation,
    situationMaritale,
    enfants,
    statut,
    refDossierLocataire,
    refDossierLocation,
    ...sp
  } = dossierParticulier;

  let form = {} as SituationProfessionnelleFormData;

  const situation =
    sp.codeStatutProfessionnel &&
    getSituationProfessionnelleFromCodeStatut(
      sp.codeStatutProfessionnel,
      listesProfessionnelles,
    );
  form = {
    principal: {
      ...sp,
      situation: situation || '',
    },
  };

  if (situationProConjoint) {
    const {
      qualite,
      nom,
      nomDeNaissance,
      prenom,
      dateNaissance,
      email,
      numeroBatiment,
      typeVoie,
      numero,
      adresse,
      codePostal,
      ville,
      telephonePortable,
      telephoneFixe,
      dateEntree,
      statutOccupation,
      situationMaritale,
      enfants,
      statut,
      refDossierLocataire,
      refDossierLocation,
      ...spConjoint
    } = situationProConjoint;
    const situationConjoint =
      situationProConjoint.codeStatutProfessionnel &&
      getSituationProfessionnelleFromCodeStatut(
        situationProConjoint.codeStatutProfessionnel,
        listesProfessionnelles,
      );
    form = {
      ...form,
      secondaire: {
        ...spConjoint,
        situation: situationConjoint,
      },
    };
  }
  return form;
};
