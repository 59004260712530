import React from 'react';
import Layout from '../../components/Layout';
import Libelle from '../../_i18n/fr.json';
import ProtectionDonnees from './ProtectionDonnees';

class ProtectionDonneesPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout title={Libelle.pageProtection.titre}>
          <ProtectionDonnees />
        </Layout>
      </React.Fragment>
    );
  }
}

export default ProtectionDonneesPage;
