import { Grid, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Divider from '../../../../../components/Divider';
import { formatDateCourt } from '../../../../../helpers/formatters';
import { Selectors as PartenaireSelectors } from '../../../../../redux/Partenaire';
import {
  ActionCreators as SituationProActionCreators,
  Selectors as SituationProSelectors,
} from '../../../../../redux/SituationProfessionnelle';
import SituationPersoIcon from '../../../../../ressources/images/SituationPersoIcon.svg';
import {
  RapportDossierParticulier,
  SituationProfessionnelle,
} from '../../../../../services/Api/swagger';
import styles from '../../style';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  personne?: RapportDossierParticulier;
  isConjoint?: boolean;
  allEtudiant?: boolean;
  listSituationPro: SituationProfessionnelle[];
  isCumulsUtilises: boolean;

  getListSituationPro(): void;
}

class CalculLocataire extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.getListSituationPro();
  }

  render() {
    const {
      personne,
      classes,
      listSituationPro,
      isCumulsUtilises,
    } = this.props;

    if (personne) {
      const situationPro =
        listSituationPro &&
        personne.calculIndicePriseEnCharge &&
        personne.calculIndicePriseEnCharge.statut &&
        listSituationPro.find(
          situation =>
            situation.statutsProfessionnels &&
            situation.statutsProfessionnels.find(
              statut =>
                personne.calculIndicePriseEnCharge &&
                statut.code === personne.calculIndicePriseEnCharge.statut,
            ),
        );
      const statutPro =
        situationPro &&
        situationPro.statutsProfessionnels &&
        personne.calculIndicePriseEnCharge &&
        personne.calculIndicePriseEnCharge.statut &&
        situationPro.statutsProfessionnels.find(
          statut =>
            personne.calculIndicePriseEnCharge &&
            statut.code === personne.calculIndicePriseEnCharge.statut,
        );

      return (
        <Grid container justify="flex-start" className={classes.candidat}>
          <div>
            <h4>
              <img src={SituationPersoIcon} width="20" height="20" alt="" />{' '}
              {personne.prenomLocataire + ' ' + personne.nomLocataire}
            </h4>

            {personne.calculIndicePriseEnCharge && (
              <>
                <h3> Calcul de l'indice de prise en charge</h3>

                <p>
                  Date du calcul :{' '}
                  <strong>
                    {formatDateCourt(
                      personne.calculIndicePriseEnCharge.dateCalcul,
                    )}
                  </strong>
                </p>
                <p>
                  Statut : <strong>{statutPro && statutPro.libelle}</strong>
                </p>
                <p>
                  Situation professionnelle :{' '}
                  <strong>{situationPro && situationPro.libelle}</strong>
                </p>
                {personne.calculIndicePriseEnCharge.codeNaf && (
                  <p>
                    Code NAF :{' '}
                    <strong>
                      {personne.calculIndicePriseEnCharge.codeNaf}
                    </strong>
                  </p>
                )}
                {personne.calculIndicePriseEnCharge.secteurActivite && (
                  <p>
                    Secteur d'activité :{' '}
                    <strong>
                      {personne.calculIndicePriseEnCharge.secteurActivite}
                    </strong>
                  </p>
                )}
                {personne.calculIndicePriseEnCharge.codeRegion && (
                  <p>
                    Code région :{' '}
                    <strong>
                      {personne.calculIndicePriseEnCharge.codeRegion +
                        '(' +
                        personne.calculIndicePriseEnCharge.libelleRegion}
                      )
                    </strong>
                  </p>
                )}
                <p>
                  Indice du secteur d'activité :{' '}
                  <strong>
                    {personne.calculIndicePriseEnCharge.indiceSecteurActivite}
                  </strong>
                </p>
                {personne.calculIndicePriseEnCharge.dateDebutSituation && (
                  <p>
                    Date de début de situation :{' '}
                    <strong>
                      {formatDateCourt(
                        personne.calculIndicePriseEnCharge.dateDebutSituation,
                      )}
                    </strong>
                  </p>
                )}
                {personne.calculIndicePriseEnCharge.dateFinSituation && (
                  <p>
                    Date de fin de situation :{' '}
                    <strong>
                      {formatDateCourt(
                        personne.calculIndicePriseEnCharge.dateFinSituation,
                      )}
                    </strong>
                  </p>
                )}
                {personne.calculIndicePriseEnCharge.delaiDebutSituation && (
                  <p>
                    Délai de début de situation :{' '}
                    <strong>
                      {personne.calculIndicePriseEnCharge.delaiDebutSituation}
                    </strong>
                  </p>
                )}
                {personne.calculIndicePriseEnCharge.delaiFinSituation && (
                  <p>
                    Délai de fin de situation :{' '}
                    <strong>
                      {personne.calculIndicePriseEnCharge.delaiFinSituation}
                    </strong>
                  </p>
                )}
                {personne.calculIndicePriseEnCharge.nombreMoisTravailles && (
                  <p>
                    Nombre de mois travaillés :{' '}
                    <strong>
                      {personne.calculIndicePriseEnCharge.nombreMoisTravailles}
                    </strong>
                  </p>
                )}
                <p>
                  Indice Sinistre Prime :{' '}
                  <strong>
                    {personne.calculIndicePriseEnCharge.indiceSinistrePrime}
                  </strong>
                </p>
                <h5>
                  Indice de prise en charge :{' '}
                  <strong>
                    {personne.calculIndicePriseEnCharge.indicePriseEnCharge}
                  </strong>
                </h5>
              </>
            )}
            <Divider />
            {personne.calculAidesSociales && (
              <>
                <h3>Calcul des aides sociales</h3>
                <p>
                  Nombre d'enfants de moins de 14 ans ou de 14 ans :{' '}
                  <strong>
                    {personne.calculAidesSociales.nombreEnfant14 || 0}
                  </strong>
                </p>
                <p>
                  Nombre d'enfants entre 15 et 19 ans :{' '}
                  <strong>
                    {personne.calculAidesSociales.nombreEnfant14A20 || 0}
                  </strong>
                </p>
                <p>
                  Nombre d'enfants de moins de 20 ans :{' '}
                  <strong>
                    {personne.calculAidesSociales.nombreEnfantInf20 || 0}
                  </strong>
                </p>
                <p>
                  Nombre d'enfants de 20 ans :{' '}
                  <strong>
                    {personne.calculAidesSociales.nombreEnfant21 || 0}
                  </strong>
                </p>
                <p>
                  Nombre d'enfants de plus de 21 ans :{' '}
                  <strong>
                    {personne.calculAidesSociales.nombreEnfantSup21 || 0}
                  </strong>
                </p>
                <p>
                  MAB : <strong>{personne.calculAidesSociales.mab}</strong>
                </p>
                <p>
                  MMAB : <strong>{personne.calculAidesSociales.mmab}</strong>
                </p>
                <p>
                  MAF : <strong>{personne.calculAidesSociales.maf}</strong>
                </p>
                <h5>
                  Montant total des aides sociales :{' '}
                  <strong>
                    {personne.calculAidesSociales.montantTotalAllocation}€
                  </strong>
                </h5>
              </>
            )}
            <Divider />
            {personne.calculRessourcesMensuelles && (
              <>
                <h3>Calcul des ressources mensuelles</h3>

                {(situationPro &&
                  (situationPro.code === 'SPR' ||
                   situationPro.code === 'SPU')) ? (
                  <>
                    {personne.calculRessourcesMensuelles.anciennetePlus1an !== undefined ? (
                      <p>
                        {personne.calculRessourcesMensuelles.anciennetePlus1an ? (
                          <strong>
                            Plus d'un an d'ancienneté
                          </strong>
                        ) :
                          <strong>
                            Moins d'un an d'ancienneté
                          </strong>
                        }
                      </p>
                    ) : null}
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>N° du mois</TableCell>
                          <TableCell>Salaire Mensuel</TableCell>
                          {personne.calculRessourcesMensuelles.anciennetePlus1an !== undefined &&
                            ! personne.calculRessourcesMensuelles.anciennetePlus1an && (
                            <TableCell>Salaire plafonné</TableCell>
                          )}
                          {personne.calculRessourcesMensuelles.anciennetePlus1an !== undefined &&
                            personne.calculRessourcesMensuelles.anciennetePlus1an && (
                            <TableCell>Pondération salaire</TableCell>
                          )}
                          {isCumulsUtilises &&
                            personne.calculRessourcesMensuelles.anciennetePlus1an !== undefined &&
                            personne.calculRessourcesMensuelles.anciennetePlus1an && (
                            <TableCell>Cumul salaire</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            M-1
                          </TableCell>
                          <TableCell>
                            {personne.calculRessourcesMensuelles.numMoisM1}
                          </TableCell>
                          <TableCell>
                            {personne.calculRessourcesMensuelles.salaireMensuelM1}€
                          </TableCell>
                          {personne.calculRessourcesMensuelles.anciennetePlus1an !== undefined &&
                            ! personne.calculRessourcesMensuelles.anciennetePlus1an && (
                            <TableCell>
                              {personne.calculRessourcesMensuelles.salairePlafonneMensuelM1}€
                            </TableCell>
                          )}
                          {personne.calculRessourcesMensuelles.anciennetePlus1an !== undefined &&
                            personne.calculRessourcesMensuelles.anciennetePlus1an && (
                            <TableCell>
                              {personne.calculRessourcesMensuelles.ponderationSalaireMensuelM1}
                            </TableCell>
                          )}
                          {isCumulsUtilises &&
                            personne.calculRessourcesMensuelles.anciennetePlus1an !== undefined &&
                            personne.calculRessourcesMensuelles.anciennetePlus1an && (
                            <TableCell>
                              {personne.calculRessourcesMensuelles.cumulSalaireM1}€
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            M-2
                          </TableCell>
                          <TableCell>
                            {personne.calculRessourcesMensuelles.numMoisM2}
                          </TableCell>
                          <TableCell>
                            {personne.calculRessourcesMensuelles.salaireMensuelM2}€
                          </TableCell>
                          {personne.calculRessourcesMensuelles.anciennetePlus1an !== undefined &&
                            ! personne.calculRessourcesMensuelles.anciennetePlus1an && (
                            <TableCell>
                              {personne.calculRessourcesMensuelles.salairePlafonneMensuelM2}€
                            </TableCell>
                          )}
                          {personne.calculRessourcesMensuelles.anciennetePlus1an !== undefined &&
                            personne.calculRessourcesMensuelles.anciennetePlus1an && (
                            <TableCell>
                              {personne.calculRessourcesMensuelles.ponderationSalaireMensuelM2}
                            </TableCell>
                          )}
                          {isCumulsUtilises &&
                            personne.calculRessourcesMensuelles.anciennetePlus1an !== undefined &&
                            personne.calculRessourcesMensuelles.anciennetePlus1an && (
                            <TableCell>
                              {personne.calculRessourcesMensuelles.cumulSalaireM2}€
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            M-3
                          </TableCell>
                          <TableCell>
                            {personne.calculRessourcesMensuelles.numMoisM3}
                          </TableCell>
                          <TableCell>
                            {personne.calculRessourcesMensuelles.salaireMensuelM3}€
                          </TableCell>
                          {personne.calculRessourcesMensuelles.anciennetePlus1an !== undefined &&
                            ! personne.calculRessourcesMensuelles.anciennetePlus1an && (
                            <TableCell>
                              {personne.calculRessourcesMensuelles.salairePlafonneMensuelM3}€
                            </TableCell>
                          )}
                          {personne.calculRessourcesMensuelles.anciennetePlus1an !== undefined &&
                            personne.calculRessourcesMensuelles.anciennetePlus1an && (
                            <TableCell>
                              {personne.calculRessourcesMensuelles.ponderationSalaireMensuelM3}
                            </TableCell>
                          )}
                          {isCumulsUtilises &&
                            personne.calculRessourcesMensuelles.anciennetePlus1an !== undefined &&
                            personne.calculRessourcesMensuelles.anciennetePlus1an && (
                            <TableCell>
                              {personne.calculRessourcesMensuelles.cumulSalaireM3}€
                            </TableCell>
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                    {personne.calculRessourcesMensuelles.salaireMensuelMoyen ? (
                      <p>
                        Salaire mensuel moyen :{' '}
                        <strong>
                          {personne.calculRessourcesMensuelles.salaireMensuelMoyen}€
                        </strong>
                      </p>
                    ) : null}
                    {personne.calculRessourcesMensuelles.anciennetePlus1an !== undefined &&
                      personne.calculRessourcesMensuelles.anciennetePlus1an &&
                      personne.calculRessourcesMensuelles.salaireMensuelPondere ? (
                      <p>
                        Salaire mensuel ponderé :{' '}
                        <strong>
                          {personne.calculRessourcesMensuelles.salaireMensuelPondere}€
                        </strong>
                      </p>
                    ) : null}
                    {personne.calculRessourcesMensuelles.anciennetePlus1an !== undefined &&
                      personne.calculRessourcesMensuelles.anciennetePlus1an &&
                      personne.calculRessourcesMensuelles.cumulMensuel && (
                      <p>
                        Salaire issu du cumul :{' '}
                        <strong>
                          {personne.calculRessourcesMensuelles.cumulMensuel}€
                        </strong>
                      </p>
                    )}
                  </>
                ) : null}

                {personne.calculRessourcesMensuelles.revenuIndividuelDeclare ? (
                  <p>
                    Revenu individuel déclaré :{' '}
                    <strong>
                      {personne.calculRessourcesMensuelles.revenuIndividuelDeclare}€
                    </strong>
                  </p>
                ) : null}
                {personne.calculRessourcesMensuelles.revenuIndividuelMensuelDeclare ? (
                <p>
                  Revenu individuel mensuel déclaré :{' '}
                  <strong>
                    {personne.calculRessourcesMensuelles.revenuIndividuelMensuelDeclare}€
                  </strong>
                  </p>
                ) : null}

                {personne.calculRessourcesMensuelles.revenuFiscalReference ? (
                  <p>
                    Revenu fiscal de référence :{' '}
                    <strong>
                      {personne.calculRessourcesMensuelles.revenuFiscalReference}€
                    </strong>
                  </p>
                ) : null}
                {personne.calculRessourcesMensuelles.revenuFiscalReference ? (
                  <h5>
                    Revenu fiscal mensuel de référence :{' '}
                    <strong>
                      {personne.calculRessourcesMensuelles.revenuFiscalMensuelReference}€
                    </strong>
                  </h5>
                ) : null}

                {personne.calculRessourcesMensuelles.salaireMensuelRetenu ? (
                  <p>
                    REVENU RETENU :{' '}
                    <strong>
                      {personne.calculRessourcesMensuelles.salaireMensuelRetenu}€
                    </strong>
                  </p>
                ) : null}
              </>
            )}
          </div>
        </Grid>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  listSituationPro: SituationProSelectors.getListSituationProfessionnelle(state),
  isCumulsUtilises: PartenaireSelectors.getCumulsUtilises(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getListSituationPro: () =>
    dispatch(
      SituationProActionCreators.getListSituationProfessionnelleAsync.request(),
    ),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CalculLocataire),
);
