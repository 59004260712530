import { Grid } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Layout from '../../../components/Layout';
import Snack from '../../../components/Snack';
import { history } from '../../../helpers/history';
import {
  ActionCreators as UtilisateursActionsCreators,
  Selectors as UtilisateursSelectors,
} from '../../../redux/Utilisateurs';
import { Profil, Utilisateur } from '../../../services/Api/swagger';
import UtilisateurBesseForm from '../UtilisateurBesseForm';

interface Props {
  utilisateur?: Utilisateur;
  modifierUtilisateur(params: any): void;
  utilisateurConnecte?: Utilisateur;
  getProfilsBesse(params: string): void;
  profilsBesse?: Profil[];
  fetching: boolean;
  utilisateurError?: any;
  emptyErrorMessages(): void;
}

class ConsulterUtilisateurBesse extends React.Component<Props> {
  componentDidMount() {
    // on récupère la liste des profils BESSE
    this.props.getProfilsBesse('AGREMENT');
  }
  handleSubmit = (values: Utilisateur) => {
    // const transformedValues = transformUtilisateurBesseDataToModel(values);
    const params = {
      ...{ username: values.identifiant },
      ...{ body: values },
    };
    this.props.modifierUtilisateur(params);
  };

  handlePrecedent = () => {
    history.push('/mes-utilisateurs');
  };

  handleClose = () => {
    this.props.emptyErrorMessages();
  };
  renderError = (error: any) => {
    return (
      <Snack
        onClose={this.handleClose}
        open={true}
        variant="error"
        message={error.message}
      />
    );
  };

  render() {
    const {
      utilisateurError,
      utilisateurConnecte,
      profilsBesse,
      fetching,
      utilisateur,
    } = this.props;
    const utilisateurBESSE =
      utilisateurConnecte && utilisateurConnecte.role === 'BESSE';

    return (
      <Layout title="Modifier un utilisateur Bessé">
        <div>
          <h2>Modifier un utilisateur Bessé</h2>
          <UtilisateurBesseForm
            profilsBesse={profilsBesse}
            onSubmit={this.handleSubmit}
            handlePrecedent={this.handlePrecedent}
            utilisateurBESSE={utilisateurBESSE}
            isFetching={fetching}
            initialValues={utilisateur}
            isModify={true}
          />
          <Grid container justify="flex-end" style={{ marginTop: 12 }}>
            {utilisateurError && this.renderError(utilisateurError)}
          </Grid>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  utilisateurConnecte: UtilisateursSelectors.getUtilisateurConnecte(state),
  profilsBesse: UtilisateursSelectors.getProfilsBesseListe(state),
  fetching: UtilisateursSelectors.getUtilisateurFetching(state),
  utilisateurError: UtilisateursSelectors.getUtilisateurError(state),
  utilisateur: UtilisateursSelectors.getUtilisateur(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getProfilsBesse: (params: string) =>
    dispatch(UtilisateursActionsCreators.getProfilsBesse.request(params)),
  ajouterUtilisateur: (params: Utilisateur) =>
    dispatch(UtilisateursActionsCreators.ajouterUtilisateur.request(params)),
  emptyErrorMessages: () =>
    dispatch(UtilisateursActionsCreators.emptyErrorMessages()),
  modifierUtilisateur: params =>
    dispatch(UtilisateursActionsCreators.modifierUtilisateur.request(params)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConsulterUtilisateurBesse);
