import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    root: {
      margin: 'auto',
    },
    paper: {
      width: 260,
      height: 230,
      overflow: 'auto',
      borderRadius: 0,
      boxShadow: 'none',
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
      borderBottom: '1px solid ' + theme.palette.grey[200],
    },
    buttonList: {
      textAlign: 'left',
      '&:hover': {
        background: 'transparent',
      },
    },
    button: {
      margin: theme.spacing(0.5, 1),
      color: theme.palette.grey[800],
      border: 'none',
      '&:disabled': {
        border: 'none',
      },
    },
  } as any);

export default styles;
