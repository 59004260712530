import { FormControl, TextField, withStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';

export interface IOwnProps {
  label?: string;
  required?: boolean;
  type?: string;
  classes?: any;
  size?: number;
  maxLength?: number;
  max?: number;
  min?: number;
  handleChange?(id: string | number, value: string | number): void;
  id?: string;
  defaultValue?: string | number | Date;
  disabled?: boolean;
  multiline?: boolean;
  placeholder?: string;
  rows?: number;
}

interface State {
  value?: string | number;
}
class UncontrolledInputField extends React.Component<IOwnProps, State> {
  constructor(props: IOwnProps) {
    super(props);
    this.state = {
      value: undefined,
    };
  }
  handleChange = event => {
    const { type, min, max } = this.props;
    let val = event.target.value;

    const minVal = min || 0;

    if (val && max && type === 'number' && parseInt(val, 0) > max) {
      val = max;
    }
    if (val && type === 'number' && parseInt(val, 0) < minVal) {
      val = minVal;
    }
    this.setState({ value: val });
    if (this.props.handleChange) {
      this.props.handleChange(event.target.id, val);
    }
  };
  render() {
    const {
      required,
      classes,
      type,
      size,
      maxLength,
      max,
      min,
      label,
      id,
      defaultValue,
      disabled,
      multiline,
      rows,
      placeholder,
    } = this.props;

    return (
      <FormControl className={classes.root}>
        <TextField
          id={id}
          value={this.state.value}
          label={label}
          disabled={disabled}
          required={required}
          className={classes.textFieldRoot}
          onChange={this.handleChange}
          multiline={multiline}
          placeholder={placeholder}
          rows={rows}
          InputProps={{
            classes: {
              input: classes.textField,
              underline: classes.underline,
            },
          }}
          inputProps={{ size, maxLength, min, max }}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabel,
              focused: classes.textFieldLabelFocused,
              error: classes.textFieldLabelError,
              asterisk: classes.textFieldLabelAsterisk,
            },
          }}
          variant="filled"
          defaultValue={defaultValue}
          type={type ? type : 'text'}
        />
      </FormControl>
    );
  }
}

export default withStyles(styles)(UncontrolledInputField);
