import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import messages from '../../_i18n/fr.json';
import Bouton from '../bouton/Bouton';
import UncontrolledInputField from '../input/UncontrolledInputField';
import UncontrolledSelect from '../select/UncontrolledSelect';
import styles from './styles';

interface Props {
  classes?: any;
  handleForcerStatutDossier(params): void;
}

interface State {
  open: boolean;
  statut: string;
  message: string;
  errorMessage: string;
  errorStatut: string;
}

const DropdownStatuts = [
  {
    key: 'VALIDE',
    text: messages.statutDossierLocation['VALIDE'],
    value: 'VALIDE',
  },
  {
    key: 'NON_VALIDE',
    text: messages.statutDossierLocation['NON_VALIDE'],
    value: 'NON_VALIDE',
  },
  {
    key: 'VALIDE_AGREE',
    text: messages.statutDossierLocation['VALIDE_AGREE'],
    value: 'VALIDE_AGREE',
  },
  {
    key: 'VALIDE_AGREE_DIFFERE',
    text: messages.statutDossierLocation['VALIDE_AGREE_DIFFERE'],
    value: 'VALIDE_AGREE_DIFFERE',
  },
  {
    key: 'VALIDE_NON_AGREE',
    text: messages.statutDossierLocation['VALIDE_NON_AGREE'],
    value: 'VALIDE_NON_AGREE',
  },
];
class DialogStatut extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      statut: '',
      message: '',
      errorMessage: '',
      errorStatut: '',
    };
  }

  onOpen = () => {
    this.setState({
      open: true,
    });
  };

  onClose = () => {
    this.setState({
      open: false,
      errorMessage: '',
      errorStatut: '',
    });
  };

  onForcerStatutDossier = () => {
    if (!this.state.message) {
      this.setState({
        errorMessage: 'Le commentaire est obligatoire',
      });
    }
    if (!this.state.statut) {
      this.setState({
        errorStatut: 'Le statut est obligatoire',
      });
    }
    if (this.state.statut && this.state.message) {
      const params = {
        statut: this.state.statut,
        message: this.state.message,
      };
      this.props.handleForcerStatutDossier(params);
      this.onClose();
    }
  };
  render() {
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <>
        <Link
          component="button"
          className={classes.buttonModify}
          underline="always"
          onClick={this.onOpen}
        >
          Modifier le statut du dossier
        </Link>
        <Dialog
          open={open}
          onClose={this.onClose}
          className={classes.modifyStatuts}
          aria-labelledby="dialog-statut-change"
          aria-describedby="Changement de statut location"
          maxWidth="xs"
        >
          <DialogTitle id="dialog-title">
            Modifier le statut du dossier
          </DialogTitle>
          <DialogContent>
            <UncontrolledSelect
              required
              label="Statut"
              options={DropdownStatuts}
              handleChange={(id, value) => this.setState({ statut: value })}
            />
            <UncontrolledInputField
              multiline
              required
              rows={3}
              label="Commentaire"
              placeholder="Ajouter un commentaire"
              maxLength={255}
              handleChange={(id, value) =>
                this.setState({ message: value.toString() })
              }
            />
            {this.state.errorStatut && (
              <Typography color="error">{this.state.errorStatut}</Typography>
            )}
            {this.state.errorMessage && (
              <Typography color="error">{this.state.errorMessage}</Typography>
            )}
          </DialogContent>
          <DialogActions className={classes.actionButton}>
            <Bouton
              label="Annuler"
              variant="contained"
              color="secondary"
              size="small"
              onClick={this.onClose}
            />
            <Bouton
              label="Valider"
              variant="contained"
              color="primary"
              size="small"
              onClick={this.onForcerStatutDossier}
            />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
export default withStyles(styles)(DialogStatut);
