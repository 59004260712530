import { Theme } from '@material-ui/core/styles';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
  ({
    container: {
      maxWidth: 1080,
      margin: 'auto',
    },
    firstList: {
      '& li': {
        listStyle: 'inside',
        listStyleType: 'decimal',
        marginBottom: 12,
      },
    },
    secondList: {
      '& li': {
        listStyle: 'inside',
        listStyleType: '-',
        marginTop: 8,
        marginBottom: 8,
        paddingLeft: 24,
      },
    },
  } as any);
// tslint:enable: quotemark

export default styles;
