import { withStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';
import defaultStyles from '../styles';
import logoNoir from '../../../../ressources/images/logo_vertuloo_noir.png';
import avantage1 from '../../../../ressources/images/avantage_1.png';
import avantage2 from '../../../../ressources/images/avantage_2.png';
import avantage3 from '../../../../ressources/images/avantage_3.png';
import avantage4 from '../../../../ressources/images/avantage_4.png';
import avantage5 from '../../../../ressources/images/avantage_5.png';
import avantage6 from '../../../../ressources/images/avantage_6.png';
import avantage7 from '../../../../ressources/images/avantage_7.png';
import avantage8 from '../../../../ressources/images/avantage_8.png';
import avantage9 from '../../../../ressources/images/avantage_9.png';
import avantageForme from '../../../../ressources/images/avantage_forme.png';

interface IProps {
  classes?: any;
  cookiesValid: boolean;
}

class BlocAvantages extends React.Component<IProps> {

  onClickEvent = () => {
    if (window.MatomoTagManager) {
      window._paq.push([
        'trackEvent',
        'Avantages',
        'Clic - CTA',
        'Accueil - Avantages',
      ]);
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.containerBg}>
        <img
          src={avantageForme}
          className={classes.forme}
          height={300}
          alt="assurance Bessé"
        />
        <div className={classes.container}>
          <img src={logoNoir} width={100} alt="immobilier & construction" />
          <h2>Une plateforme d'avantages</h2>
          <h3>pour faciliter votre quotidien</h3>

          <div className={classes.containerAvantages}>
            <div className={classes.avantage}>
              <h2>Sécurisation</h2>
              <h4>de votre activité</h4>
              <div className={classes.containerBloc}>
                <div className={classes.bloc}>
                  <img
                    src={avantage4}
                    width={40}
                    alt="Sécurisation de l'authenticité des pièces"
                  />
                  <p>Sécurisation de l'authenticité des pièces</p>
                </div>{' '}
                <div className={classes.bloc}>
                  <img
                    src={avantage1}
                    width={40}
                    alt="certification assurance"
                  />
                  <p>Certification assurance</p>
                </div>{' '}
                <div className={classes.bloc}>
                  <img src={avantage7} width={40} alt="locataires garantis" />
                  <p>100% des locataires garantis</p>
                </div>
              </div>
            </div>
            <div className={classes.avantage}>
              <h2>Développement</h2>
              <h4>de votre activité</h4>
              <div className={classes.containerBloc}>
                <div className={classes.bloc}>
                  <img src={avantage2} width={40} alt="propriétaire/bailleur" />
                  <p>
                    Renforcement de ma relation avec le propriétaire/bailleur
                  </p>
                </div>{' '}
                <div className={classes.bloc}>
                  <img src={avantage5} width={40} alt="la vacance locative" />
                  <p>Diminution de la vacance locative</p>
                </div>{' '}
                <div className={classes.bloc}>
                  <img
                    src={avantage8}
                    width={40}
                    alt="Conquête de nouveaux mandats"
                  />
                  <p>Conquête de nouveaux mandats</p>
                </div>
              </div>
            </div>
            <div className={classes.avantage}>
              <h2>Productivité</h2>
              <h4>de votre activité</h4>
              <div className={classes.containerBloc}>
                <div className={classes.bloc}>
                  <img src={avantage3} width={40} alt="Gain de temps" />
                  <p>Gain de temps</p>
                </div>{' '}
                <div className={classes.bloc}>
                  <img src={avantage6} width={40} alt="dossier" />
                  <p>Simplification de la gestion du dossier</p>
                </div>{' '}
                <div className={classes.bloc}>
                  <img src={avantage9} width={40} alt="mise en location" />
                  <p>Accèlere la mise en location</p>
                </div>
              </div>
            </div>
          </div>
          <a
            href="/en-savoir-plus/#avantages"
            onClick={this.onClickEvent}
            className={classes.linkPlus}
          >
            en savoir plus
          </a>
        </div>
      </div>
    );
  }
}

export default withStyles(theme => ({
  ...styles(theme),
  ...defaultStyles(theme),
}))(BlocAvantages);
