import { Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { erreur, yellow } from '../../theme';
import messages from '../../_i18n/fr.json';

interface Props {
  classes?: any;
  statut?: string;
}
const styles = (theme: Theme) =>
  ({
    statut: {
      padding: '2px 8px',
      fontSize: 11,
      borderRadius: 2,
      fontWeight: 500,
      whiteSpace: 'nowrap',
      textAlign: 'center',
    },
    green: {
      background: theme.palette.primary.main,
      color: '#FFF',
    },
    orange: {
      background: yellow,
    },
    red: {
      background: erreur,
      color: 'white',
    },
  } as any);
// tslint:enable: quotemark

const Statut = (props: Props) => {
  let className = props.classes.statut;
  if (props.statut) {
    if (
      props.statut === 'VALIDE_AGREE' ||
      props.statut === 'VALIDE' ||
      props.statut === 'ACCEPTED'
    ) {
      className += ' ' + props.classes.green;
    } else if (
      props.statut === 'VALIDE_AGREE_DIFFERE' ||
      props.statut === 'COMPLET' ||
      props.statut === 'INITIALIZED' ||
      props.statut === 'PENDING' ||
      props.statut === 'WAITING' ||
      props.statut === 'SUSPENDED'
    ) {
      className += ' ' + props.classes.orange;
    } else {
      className += ' ' + props.classes.red;
    }
  }
  return (
    <div className={className}>
      {messages.statutDossierLocation[props.statut || 'INCOMPLET']}
    </div>
  );
};

export default withStyles(styles)(Statut);
