import { push } from 'connected-react-router';
import { stopSubmit } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { Selectors as administrateurBienSelectors } from '../../redux/AdministrateurDeBien';
import { ActionCreators as BienActionCreator } from '../../redux/Bien';
import { Selectors as dossierLocationSelectors } from '../../redux/DossierLocation';
import { IApi } from '../../services/Api';

export function* lancerCreationBien(
  api: IApi,
  action: ActionType<typeof BienActionCreator.addBienAsync.request>,
) {
  try {
    const dossierLocationRef = yield select(
      dossierLocationSelectors.getSelectedDossier,
    );
    const resultats = yield call(
      api.addBien,
      action.payload.bien,
      dossierLocationRef,
    );
    yield put(BienActionCreator.addBienAsync.success(resultats));
    yield put(push('/dossier-location/situation-personnelle'));
  } catch (error) {
    const { form } = action.payload;
    if (form) {
      yield put(stopSubmit(form, error.content));
    }
    yield put(BienActionCreator.addBienAsync.failure(error));
  }
}

export function* lancerModificationBien(
  api: IApi,
  action: ActionType<typeof BienActionCreator.updateBienAsync.request>,
) {
  try {
    const dossierLocationRef = yield select(
      dossierLocationSelectors.getSelectedDossier,
    );

    const statutDossier = yield select(
      dossierLocationSelectors.getStatutDossier,
    );
    const resultat = yield call(
      api.updateBien,
      action.payload.bien,
      dossierLocationRef,
    );

    yield put(BienActionCreator.updateBienAsync.success(resultat));
    if (statutDossier === 'EN_COURS_DE_CREATION') {
      yield put(push('/dossier-location/situation-personnelle'));
    } else {
      yield put(push('/dossier-location/synthese'));
    }
  } catch (error) {
    const { form } = action.payload;
    if (form) {
      yield put(stopSubmit(form, error.content));
    }
    yield put(BienActionCreator.updateBienAsync.failure(error));
  }
}

export function* lancerChargementBien(
  api: IApi,
  action: ActionType<typeof BienActionCreator.lancerChargementBien.request>,
) {
  try {
    const adbId = yield select(administrateurBienSelectors.getAdbId);
    const adbDetail = yield select(administrateurBienSelectors.getADBDetail);
    const adbSelected =
      (adbId && adbId) || (adbDetail && adbDetail[0].identifiant);
    let site = yield select(administrateurBienSelectors.getSelectedSite);

    // chargement du site de la duplication
    const refDossierDuplique = yield select(
      dossierLocationSelectors.getDossierDuplique,
    );
    if (refDossierDuplique) {
      const dossiersLocation = yield select(
        dossierLocationSelectors.getResultDossierLocation,
      );
      const dossierDuplique =
        dossiersLocation &&
        dossiersLocation.elements.find(
          dos => dos.reference === refDossierDuplique,
        );
      site = dossierDuplique.site;
    }
    const { referenceMandat, numeroLot } = action.payload;
    const resultat = yield call(
      api.getBien,
      referenceMandat,
      numeroLot,
      adbSelected,
      site.numero,
    );
    yield put(BienActionCreator.lancerChargementBien.success(resultat));
  } catch (error) {
    yield put(BienActionCreator.lancerChargementBien.failure(error));
  }
}

export function* loadTypeBienAsync(api: IApi) {
  try {
    const resultats = yield call(api.loadTypeBienAsync);
    yield put(BienActionCreator.loadTypeBienAsync.success(resultats));
  } catch (error) {
    yield put(BienActionCreator.loadTypeBienAsync.failure(error));
  }
}

export function* loadNombrePieces(api: IApi) {
  try {
    const resultats = yield call(api.loadNombrePieces);
    yield put(BienActionCreator.loadNombrePieces.success(resultats));
  } catch (error) {
    yield put(BienActionCreator.loadNombrePieces.failure(error));
  }
}
