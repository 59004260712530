import { createMuiTheme } from '@material-ui/core/styles';

export const couleurPrimaire = '#7F9D00';
export const couleurSecondaire = '#0DFF96';
export const blanc = '#FFFFFF';
export const noir = '#000000';
export const violet = '#433D4E';
export const gris1 = '#6C6C6C';
export const gris2 = '#746B89';
export const gris3 = '#dedde6';
export const gris4 = '#f6f4f9'; // TODO a adapter aux nuances violettes des autres gris
export const erreur = '#f42c2c';
export const grayGrid = '#dedde6';
export const yellow = '#ffcb62';
export const bleu = '#003366';
export const bleuClair = '#0777e7';
export const red3 = '#e6b4b4';
export const red4 = '#d65a5a !important';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: couleurPrimaire,
      light: 'rgba(127, 157, 0, 0.2)',
    },
    secondary: {
      main: gris2,
    },
    error: {
      main: erreur,
    },
    grey: {
      300: '#dedde6',
      400: '#3b3842',
      A400: '#B7B7B7',
      A700: '#1b1c1',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    common: {
      white: blanc,
      black: noir,
    },
  },
  typography: {
    h1: {
      fontSize: 35,
      fontWeight: 'bold',
    },
    h2: {
      fontSize: 30,
    },
    h3: {
      fontSize: 14,
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.15em',
      lineHeight: '1.2em',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '0.96em',
      lineHeight: '1.6em',
      fontWeight: 100,
    },
    h6: {
      fontSize: '0.9rem',
    },
    body1: {
      fontSize: '0.9rem',
    },
    subtitle1: {
      fontSize: '0.85rem',
      lineHeight: '1rem',
    },
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {},
    },
    MuiButton: {
      root: {
        color: blanc,
        '&:hover': {
          backgroundColor: couleurPrimaire,
        },
      },
    },
    MuiAppBar: {
      root: {
        alignItems: 'center',
      },
    },
    MuiToolbar: {
      root: {
        height: 94,
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: couleurPrimaire,
        },
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: violet,
      },
    },
    MuiTableRow: {
      root: {
        height: 50,
      },
    },
    MuiTableCell: {
      root: {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: '#979797',
      },
      head: {
        color: blanc,
      },
    },
    MuiTableSortLabel: {
      root: {
        '&$active': {
          color: blanc,
        },
      },
    },
  },
});

export default theme;
