import Types from 'Types';
import {
  formatDateToJanuary,
  formatterEspaces,
} from '../../../helpers/formatters';
import {
  DossierLocation,
  DossierParticulier,
  DossierParticulierDetail,
  SituationPersonnelleDossierParticulier,
  SituationPersonnelleDossierParticulierQualiteEnum,
  SituationPersonnelleDossierParticulierSituationMaritaleEnum,
  SituationPersonnelleDossierParticulierStatutOccupationEnum,
} from '../../../services/Api/swagger';

export interface ISituationPersonnellePresentation {
  reference?: string;
  refDossierLocataire?: string;
  qualite?: string;
  nom?: string;
  nomDeNaissance?: string;
  prenom?: string;
  dateNaissance?: string;
  email?: string;
  telephoneFixe?: string;
  telephonePortable?: string;
  dateEntree?: string;
  statutOccupation?: string;
  numero?: string;
  numeroBatiment?: string;
  typeVoie?: string;
  adresse?: string;
  codePostalVille?: any;
  ville?: string;
  pays?: string;
  situationMaritale?: 'CELIBATAIRE' | 'EN_COUPLE' | 'DIVORCE' | 'VEUF';
  typeCompte: 'LOCATAIRE' | 'GARANT';
  enfants?: Array<{ dateNaissance?: string }>;
  principal?: boolean;
}

export interface ISituationPersonnelleFormData {
  principal: ISituationPersonnellePresentation;
  secondaire?: {
    qualite?: string;
    nom?: string;
    nomDeNaissance?: string;
    prenom?: string;
    dateNaissance?: string;
    reference?: string;
  };
}

export interface ISituationPersonnelleError {
  principal: any;
  secondaire?: any;
}

export const situationMaritale = [
  {
    key: 'CELIBATAIRE',
    value: 'CELIBATAIRE',
    text: 'Célibataire',
  },
  {
    key: 'EN_COUPLE',
    value: 'EN_COUPLE',
    text: 'En couple',
  },
  {
    key: 'DIVORCE',
    value: 'DIVORCE',
    text: 'Divorcé(e)/En cours de séparation',
  },
  {
    key: 'VEUF',
    value: 'VEUF',
    text: 'Veuf(ve)',
  },
];

export const statutOccupation = [
  {
    key: 'LOCATAIRE',
    value: 'LOCATAIRE',
    text: 'Locataire',
  },
  {
    key: 'OCCUPANT_A_TITRE_GRATUIT',
    value: 'OCCUPANT_A_TITRE_GRATUIT',
    text: 'Occupant à titre gratuit',
  },
  {
    key: 'PROPRIETAIRE',
    value: 'PROPRIETAIRE',
    text: 'Propriétaire',
  },
];

export const qualites = [
  {
    key: 'Mme',
    value: 'MME',
    text: 'Mme',
  },
  {
    key: 'M',
    value: 'M',
    text: 'M',
  },
];

// Données a renvoyer au back
export const getSituationPersonnelleModel = (
  situationPersonnelleForm: ISituationPersonnelleFormData,
  dossierLocation: DossierLocation,
  refDossierLocation,
  compositionLocation,
): Types.SituationPersonnelleData => {
  const situationPersonnelle = {} as Types.SituationPersonnelleData;
  const { secondaire, principal } = situationPersonnelleForm;
  situationPersonnelle.situationPrincipale = {
    ...principal,
    codePostal: principal.codePostalVille.value.split('-')[0],
    ville: principal.codePostalVille.value.split('-')[1],
    telephoneFixe: formatterEspaces(principal.telephoneFixe),
    telephonePortable: formatterEspaces(principal.telephonePortable),
    refDossierLocation,
    enfants:
      principal.enfants &&
      principal.enfants.map(enfant => {
        const date = formatDateToJanuary(enfant.dateNaissance);
        return {
          ...enfant,
          dateNaissance: date,
        };
      }),
  } as Types.SituationPersonnelleDossierParticulierType;
  const getComposition = compositionLocation
    ? compositionLocation
    : dossierLocation.compositionLocation;
  if (getComposition === 'COUPLE' && principal.typeCompte !== 'GARANT') {
    situationPersonnelle.situationConjoint = {
      ...secondaire,
      principal: false,
      refDossierLocation,
    } as Types.SituationPersonnelleDossierParticulierType;
  }

  return situationPersonnelle;
};

// Données modifiées venant du back, pour réafficher le formulaire
export const copySituationPersonnelleModelToForm = (
  data: Types.SituationPersonnelleData,
): ISituationPersonnelleFormData => {
  let form = {} as ISituationPersonnelleFormData;

  const { situationPrincipale, situationConjoint } = data;

  // on enleve les elements non utilisés
  const { enfants, codePostal, ville, ...sp } = situationPrincipale;

  form = {
    principal: {
      ...sp,
      codePostalVille: codePostal &&
        ville && {
          value: codePostal + '-' + ville,
          label: ville + '(' + codePostal + ')',
        },
      enfants:
        enfants && enfants.length
          ? enfants.map(s => ({ dateNaissance: s.dateNaissance }))
          : undefined,
    },
  };

  if (situationConjoint) {
    form = {
      ...form,
      secondaire: {
        ...situationConjoint,
      },
    };
  }

  return form;
};

export const copySituationPersonnelleTypeToSituationPersonnelle = (
  situationPersonnelle: Types.SituationPersonnelleDossierParticulierType,
): SituationPersonnelleDossierParticulier => {
  let dossierParticulier = {} as SituationPersonnelleDossierParticulier;

  dossierParticulier = {
    ...situationPersonnelle,
    qualite: situationPersonnelle.qualite
      ? ((situationPersonnelle.qualite +
          '') as SituationPersonnelleDossierParticulierQualiteEnum)
      : undefined,
    situationMaritale: situationPersonnelle.situationMaritale
      ? ((situationPersonnelle.situationMaritale +
          '') as SituationPersonnelleDossierParticulierSituationMaritaleEnum)
      : undefined,
    statutOccupation: situationPersonnelle.statutOccupation
      ? ((situationPersonnelle.statutOccupation +
          '') as SituationPersonnelleDossierParticulierStatutOccupationEnum)
      : undefined,
  };
  return dossierParticulier;
};

export const copyDossierParticulierToSituationPersonnelleType = (
  dossierParticulier: DossierParticulier,
): Types.SituationPersonnelleDossierParticulierType => {
  const {
    codeStatutProfessionnel,
    dateDebutSituationProfessionnelle,
    dateFinSituationProfessionnelle,
    pasAvisImposition,
    nombreMoisTravailles,
    salaireNetImposableM1,
    salaireNetImposableM2,
    salaireNetImposableM3,
    cumulSalaireNetImposableM1,
    cumulSalaireNetImposableM2,
    cumulSalaireNetImposableM3,
    revenuFiscalReference,
    dateDernierBulletin,
    codeApe,
    siret,
    ...sp
  } = dossierParticulier;

  return {
    ...sp,
    qualite: dossierParticulier.qualite
      ? SituationPersonnelleDossierParticulierQualiteEnum[
          dossierParticulier.qualite
        ]
      : undefined,
    situationMaritale: dossierParticulier.situationMaritale
      ? ((dossierParticulier.situationMaritale +
          '') as SituationPersonnelleDossierParticulierSituationMaritaleEnum)
      : undefined,
    statutOccupation: dossierParticulier.statutOccupation
      ? ((dossierParticulier.statutOccupation +
          '') as SituationPersonnelleDossierParticulierStatutOccupationEnum)
      : undefined,

    enfants:
      dossierParticulier.enfants && dossierParticulier.enfants.length > 0
        ? dossierParticulier.enfants
        : undefined,
  };
};

export const dossierParticulierToModificationSituationPersonnelle = (
  dossierParticulier: DossierParticulierDetail,
  situationPersoConjoint,
) => {
  const {
    codeStatutProfessionnel,
    dateDebutSituationProfessionnelle,
    dateFinSituationProfessionnelle,
    pasAvisImposition,
    nombreMoisTravailles,
    salaireNetImposableM1,
    salaireNetImposableM2,
    salaireNetImposableM3,
    cumulSalaireNetImposableM1,
    cumulSalaireNetImposableM2,
    cumulSalaireNetImposableM3,
    revenuFiscalReference,
    dateDernierBulletin,
    revenuIndividuelDeclare,
    codeApe,
    siret,
    dossierPieces,
    ...sp
  } = dossierParticulier;

  let form = {} as ISituationPersonnelleFormData;

  form = {
    principal: {
      ...sp,
      codePostalVille: dossierParticulier.codePostal &&
        dossierParticulier.ville && {
          value: dossierParticulier.codePostal + '-' + dossierParticulier.ville,
          label:
            dossierParticulier.ville +
            '(' +
            dossierParticulier.codePostal +
            ')',
        },
      enfants:
        dossierParticulier.enfants && dossierParticulier.enfants.length > 0
          ? dossierParticulier.enfants.map(s => ({
              dateNaissance: s.dateNaissance,
            }))
          : undefined,
    },
  };

  if (situationPersoConjoint) {
    form = {
      ...form,
      secondaire: {
        qualite: situationPersoConjoint.qualite,
        dateNaissance: situationPersoConjoint.dateNaissance,
        nom: situationPersoConjoint.nom,
        nomDeNaissance: situationPersoConjoint.nomDeNaissance,
        prenom: situationPersoConjoint.prenom,
        reference: situationPersoConjoint.reference,
      },
    };
  }
  return form;
};
