import { Theme } from '@material-ui/core/styles';
import { blanc, bleuClair, erreur, gris3 } from '../../theme';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
({
  root: {
    boxShadow: 'none',
    marginTop: 48,
    borderRadius: 2,
    '& .iconLeft': {
      marginRight: 16,
    },
    '& .iconRight': {
      marginLeft: 16,
    },
  },
  header: {
    color: blanc,
    textTransform: 'Capitalize',
    fontSize: 10,
    padding: '4px 8px',
    lineHeight: '1rem',
    fontWeight: '500',
    borderLeft: 'none',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  headerSort: {
    color: blanc,
    '&:hover': {
      color: blanc,
    },
    '& svg': {
      fill: theme.palette.primary.main,
      opacity: 0.5,
    },
  },
  ligneTableau: {
    cursor: 'pointer',
    height: 40,
    '&:nth-of-type(odd)': {
      backgroundColor: gris3,
    },
    '&:hover': {
      backgroundColor: '#CFCED6 !important',
    },
    '& > td': {
      fontSize: 13,
      color: theme.palette.common.black,
      borderBottom: 'none',
      borderTop: 'none',
      borderLeft: 'none',
      wordBreak: 'break-word',
      '&:last-child': {
        borderRight: 'none',
      },
      padding: '4px 8px',
      '& .gliNon': {
        background: erreur,
        color: 'white',
        padding: '0 8px',
        textAlign: 'center',
      },
      '& .gliOui': {
        textAlign: 'center',
        paddingRight: '4px',
      },
      '&.enPlace': {
        textAlign: 'center',
      },
    },
    '&.disabled': {
      userSelect: 'none',
      pointerEvents: 'none',
      cursor: 'notAllowed',
      opacity: 0.6,
    },
    '& > .ignoreDisable': {
      userSelect: 'all',
      pointerEvents: 'auto',
      cursor: 'auto',
      opacity: 1,
    },
  },
  ligneTableauBleu: {
    cursor: 'pointer',
    height: 40,
    '&:nth-of-type(odd)': {
      backgroundColor: gris3,
    },
    '&:hover': {
      backgroundColor: '#CFCED6 !important',
    },
    '& > td': {
      fontSize: 13,
      fontWeight: 'bold',
      color: bleuClair,
      borderBottom: 'none',
      borderTop: 'none',
      borderLeft: 'none',
      wordBreak: 'break-word',
      '&:last-child': {
        borderRight: 'none',
      },
      padding: '4px 8px',
      '& .gliNon': {
        background: erreur,
        color: 'white',
        padding: '0 8px',
        textAlign: 'center',
      },
      '& .gliOui': {
        textAlign: 'center',
        paddingRight: '4px',
      },
      '&.enPlace': {
        textAlign: 'center',
      },
    },
    '&.disabled': {
      userSelect: 'none',
      pointerEvents: 'none',
      cursor: 'notAllowed',
      opacity: 0.6,
    },
    '& > .ignoreDisable': {
      userSelect: 'all',
      pointerEvents: 'auto',
      cursor: 'auto',
      opacity: 1,
    },
  },
  footer: {
    '& td': {
      borderLeft: 'none',
      '&:last-child': {
        borderRight: 'none',
      },
    },
  },
  menuTableau: {
    marginLeft: 20,
    marginTop: 4,
    '& a': {
      color: theme.palette.grey[800],
      minHeight: 30,
    },
  },
  disabledButton: {
    margin: 0,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 16,
    paddingRight: 16,
    color: '#AAA',
  },
  button: {
    color: '#424242',
    minHeight: 30,
    padding: '0 16px',
    textTransform: 'initial',
    fontWeight: 'normal',
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    borderRadius: 0,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  menuSpinner: {
    marginLeft: '8px',
  },
} as any);
// tslint:enable: quotemark

export default styles;
