import { Button, withStyles } from '@material-ui/core';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Types } from 'Types';
import { Selectors as AdbSelectors } from '../../../redux/AdministrateurDeBien';
import LogoHeader from '../../../ressources/images/logo_vertuloo_vert.png';
import LogoV from '../../../ressources/images/logo_vertuloo_v.png';
import {
  AdministrateurBienDetail,
  Profil,
} from '../../../services/Api/swagger/api.js';
import { Selectors as UtilisateursSelectors } from '../../../redux/Utilisateurs';
import {
  getNameToDisplay,
  logout,
} from '../../../services/Authentification/authentification';
import { ProfileIcon } from '../images';
import styles from '../styles';
import { isPathAuthorizedForUser } from '../../../services/Route';

//

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  adbDetail: AdministrateurBienDetail;
  infoProfil?: Profil;
}

class HeaderAuthenticated extends React.Component<Props> {
  /**
   * Déconnexion Keycloak
   */
  handleDeconnexion = () => {
    logout();
  };

  render() {
    const { classes, adbDetail } = this.props;

    return (
      <div className={classes.headerContainerAuthenticated}>
        <div className={classes.headerAuthenticated}>
          <div className={classes.logoAuthenticated}>
            <a href={'/dashboard'}>
              <img
                src={LogoV}
                alt="Logo Besse Immobilier et Construction"
                width={32}
                className={classes.logoV}
              />
              <img
                src={LogoHeader}
                alt="Logo Besse Immobilier et Construction"
                width={174}
              />
            </a>
          </div>
          <div className={classes.menu}>
            {' '}
            <Link to={'/dashboard'} className={classes.menuLink}>
              Home
            </Link>
            {isPathAuthorizedForUser(
              '/mes-administrateurs-de-biens',
              this.props.infoProfil,
            ) && (
              <Link
                to={'/mes-administrateurs-de-biens'}
                className={classes.menuLink}
              >
                Administrateurs de biens
              </Link>
            )}
            {isPathAuthorizedForUser(
              '/mes-utilisateurs',
              this.props.infoProfil,
            ) && (
              <Link to={'/mes-utilisateurs'} className={classes.menuLink}>
                Utilisateurs
              </Link>
            )}
            <div
              className={
                classes.connexionContainer +
                ' ' +
                classes.connexionAuthenticated
              }
            >
              {' '}
              <div className={classes.identifiant}>
                <ProfileIcon />
                <div>
                  <p>{getNameToDisplay()}</p>
                  <small> {adbDetail && adbDetail.nom}</small>
                </div>
              </div>
              <Button
                className={classes.actionsBouton}
                onClick={this.handleDeconnexion}
              >
                <PowerSettingsNew />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: Types.RootState) => ({
  adbDetail: AdbSelectors.getSelectedAdbDetail(state),
  infoProfil: UtilisateursSelectors.getProfilUtilisateur(state),
});
export default withStyles(styles)(
  connect(mapStateToProps)(HeaderAuthenticated),
);
