import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import AccesInterditPage from './AccesInterditPage';
import ConsulterAdbPage from './Adb/ConsulterAdbPage';
import RechercheAdbPage from './Adb/RechercheAdbPage';
import ActivationComptePage from './Compte/ActivationComptePage';
import ConfigurationPage from './ConfigurationPage';
import Dashboard from './DashboardPage/Loadable';
import AjouterBienPage from './DossierLocation/BienPage';
import CalculLoyerPage from './DossierLocation/CalculLoyer';
import PiecesJustificativesPage from './DossierLocation/PiecesJustificativesPage';
import PreformulairePage from './DossierLocation/PreformulairePage';
import SelectAdbSitePage from './DossierLocation/SelectAdbSitePage';
import AjouterSituationPersonnellePage from './DossierLocation/SituationPersonnellePage';
import SituationProfessionnellePage from './DossierLocation/SituationProfessionnellePage';
import SyntheseDossierLocationPage from './DossierLocation/SyntheseDossierLocationPage';
import HomePage from './HomePage';
import MentionsLegalesPage from './MentionsLegalesPage';
import PageIntrouvable from './PageIntrouvable';
import ProtectionDonneesPage from './ProtectionDonneesPage';
import AjouterUtilisateur from './Utilisateurs/AjouterUtilisateurAdb/Loadable';
import AjouterUtilisateurBesse from './Utilisateurs/AjouterUtilisateurBesse';
import IUtilisateurs from './Utilisateurs/Loadable';
import ModifierUtilisateur from './Utilisateurs/ModifierUtilisateurAdb';
import ModifierUtilisateurBesse from './Utilisateurs/ModifierUtilisateurBesse';
import EnSavoirPlusPage from './EnSavoirPlusPage';
import LandingPage from './LandingPage';
import OnParleNousPage from './OnParleNousPage';
import LabelisationPage from './LabelisationPage';

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/on-parle-de-nous" exact component={OnParleNousPage} />
        <Route path="/en-savoir-plus" exact component={EnSavoirPlusPage} />
        <Route path="/landing-page" exact component={LandingPage} />
        <Route path="/labelisation" exact component={LabelisationPage} />
        {/* PublicRoute */}
        <Route
          path="/mentions-legales/"
          exact
          component={MentionsLegalesPage}
        />
        <Route
          path="/protection-des-donnees/"
          exact
          component={ProtectionDonneesPage}
        />
        <Route path="/activer-compte/:token" component={ActivationComptePage} />
        <Route path="/accesInterdit/" exact component={AccesInterditPage} />
        {/* Dashboard connecté */}
        <PrivateRoute path="/dashboard" exact component={Dashboard} />
        {/* Selection site et adb connecté */}
        <PrivateRoute
          path="/dossier-location/selection-adb"
          exact
          component={SelectAdbSitePage}
        />
        {/* Parcours Dossier Location */}
        <PrivateRoute
          path="/dossier-location/pre-formulaire"
          exact
          component={PreformulairePage}
        />
        <PrivateRoute
          path="/dossier-location/bien"
          exact
          component={AjouterBienPage}
        />
        <PrivateRoute
          path="/dossier-location/situation-personnelle"
          exact
          component={AjouterSituationPersonnellePage}
        />
        <PrivateRoute
          path="/dossier-location/situation-professionnelle"
          exact
          component={SituationProfessionnellePage}
        />
        <PrivateRoute
          path="/dossier-location/pieces-justificatives"
          exact
          component={PiecesJustificativesPage}
        />
        <PrivateRoute
          path="/dossier-location/synthese"
          exact
          component={SyntheseDossierLocationPage}
        />
        <PrivateRoute
          path="/dossier-location/calcul-loyer"
          exact
          component={CalculLoyerPage}
        />
        {/* Adb */}
        <PrivateRoute
          path="/mes-administrateurs-de-biens"
          exact
          component={RechercheAdbPage}
        />
        <PrivateRoute
          path="/mes-administrateurs-de-biens/consulter"
          exact
          component={ConsulterAdbPage}
        />
        {/* Utilisateurs */}
        <PrivateRoute
          path="/mes-utilisateurs"
          exact
          component={IUtilisateurs}
        />
        {/* Créer Utilisateurs */}
        <PrivateRoute
          path="/mes-utilisateurs/ajouter"
          exact
          component={AjouterUtilisateur}
        />
        {/* Créer Utilisateurs Besse */}
        <PrivateRoute
          path="/mes-utilisateurs/ajouterBesse"
          exact
          component={AjouterUtilisateurBesse}
        />
        <PrivateRoute
          path="/mes-utilisateurs/modifier"
          exact
          component={ModifierUtilisateur}
        />
        <PrivateRoute
          path="/mes-utilisateurs/modifierBesse"
          exact
          component={ModifierUtilisateurBesse}
        />
        <Route path="/configuration/" exact component={ConfigurationPage} />
        {/* Page introuvable */}
        <Route component={PageIntrouvable} />
      </Switch>
    );
  }
}

export default Routes;
