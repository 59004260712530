import { AxiosInstance, AxiosResponse } from 'axios';
import { wrapError } from '../../helpers/utils';
import {
  Bien,
  BienDetail,
  BiensApiFactory,
  DossiersLocationsApiFactory,
  NombrePieces,
  TypeBien,
} from './swagger';

export interface IApi {
  addBien(bien: Bien, referenceDossierLocation: string): Promise<BienDetail>;
  updateBien(bien: Bien, referenceDossierLocation: string): Promise<BienDetail>;
  getBien(
    referenceMandat: string,
    numeroLot: string,
    refAdb: number,
    numeroSite: number,
  ): Promise<BienDetail>;
  loadTypeBienAsync(): Promise<TypeBien[]>;
  loadNombrePieces(): Promise<NombrePieces[]>;
}

const create = (endpoint: string, axiosClient: AxiosInstance) => {
  const dossierLocationApi = DossiersLocationsApiFactory(
    {},
    endpoint,
    axiosClient,
  );

  const bienApi = BiensApiFactory({}, endpoint, axiosClient);

  const addBien = (
    bien: Bien,
    referenceDossierLocation: string,
  ): Promise<BienDetail> => {
    const promise: Promise<BienDetail> = new Promise((resolve, reject) => {
      dossierLocationApi
        .dossiersLocationRefBienPost(referenceDossierLocation, bien, {})
        .then((response: AxiosResponse<BienDetail>) => {
          resolve(response.data);
        })
        .catch(error => reject(wrapError(error)));
    });
    return promise;
  };

  const updateBien = (
    bien: Bien,
    referenceDossierLocation: string,
  ): Promise<BienDetail> => {
    const promise: Promise<BienDetail> = new Promise((resolve, reject) => {
      dossierLocationApi
        .dossiersLocationRefBienPut(referenceDossierLocation, bien, {})
        .then((response: AxiosResponse<BienDetail>) => {
          resolve(response.data);
        })
        .catch(error => reject(wrapError(error)));
    });
    return promise;
  };

  const loadTypeBienAsync = (): Promise<TypeBien[]> => {
    const promise: Promise<TypeBien[]> = new Promise((resolve, reject) => {
      bienApi
        .biensTypesBiensGet({})
        .then((response: AxiosResponse<TypeBien[]>) => {
          resolve(response.data);
        })
        .catch(error => reject(error));
    });
    return promise;
  };

  const getBien = (
    referenceMandat: string,
    numeroLot: string,
    refAdb: number,
    numeroSite: number,
  ): Promise<BienDetail> => {
    const promise: Promise<BienDetail> = new Promise((resolve, reject) => {
      bienApi
        .biensGet(referenceMandat, numeroLot, refAdb, numeroSite, {})
        .then((response: AxiosResponse<BienDetail>) => {
          resolve(response.data);
        })
        .catch(error => reject(error));
    });
    return promise;
  };

  const loadNombrePieces = (): Promise<NombrePieces[]> => {
    const promise: Promise<NombrePieces[]> = new Promise((resolve, reject) => {
      bienApi
        .biensNombresPiecesGet({})
        .then((response: AxiosResponse<NombrePieces[]>) => {
          resolve(response.data);
        })
        .catch(error => reject(error));
    });
    return promise;
  };

  return {
    addBien,
    updateBien,
    loadTypeBienAsync,
    getBien,
    loadNombrePieces,
  };
};

export default create;
