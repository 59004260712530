import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { isPristine } from 'redux-form';
import LoadingBoundary from '../../../../components/LoadingBoundary';
import { history } from '../../../../helpers/history';
import { Selectors as DossierLocationSelector } from '../../../../redux/DossierLocation';
import { Selectors as PartenaireSelector } from '../../../../redux/Partenaire';
import {
  ActionCreators as SituationProfessionnelleActionCreator,
  Selectors as SituationProfessionnelleSelector,
} from '../../../../redux/SituationProfessionnelle';
import {
  DossierLocation,
  SituationProfessionnelle,
  SituationProfessionnelleDossierParticulier,
} from '../../../../services/Api/swagger';
import RenderError from '../../../ErrorContainer';
import {
  SituationProfessionnelleFormData,
  situationProfessionnelleFormToModel,
} from '../helpers';
import styles from '../style';
import AjouterSituationProfessionnelle from './AjouterSituationProfessionnelle';

interface Props {
  listSituationProfessionnelle: SituationProfessionnelle[];
  error: any;
  fetching: boolean;
  dossierLocation: DossierLocation;
  isControleDossier: boolean;
  situationProfessionnelle: SituationProfessionnelleDossierParticulier;
  situationProfessionnelleError: any;
  situationProfessionnelleFetching: boolean;

  chargerListSituationProfessionnelle(): void;

  ajouterSituationProfessionnelle(
    situationProfessionnelle: SituationProfessionnelleDossierParticulier,
  ): void;

  supprimerSituationPersonnelleError(): void;

  pristine: boolean;
}

class SituationProfessionnellePage extends React.Component<Props> {

  handlePrecedent() {
    history.push('/dossier-location/situation-personnelle');
  }

  loadInitialValues = () => {
    this.props.chargerListSituationProfessionnelle();
  };

  componentDidMount() {
    this.loadInitialValues();
  }

  handleSubmit = (values: SituationProfessionnelleFormData) => {
    if (values && !this.props.pristine) {
      const situationProfessionnelle = situationProfessionnelleFormToModel(
        values,
        this && this.props && this.props.dossierLocation,
      );
      this.props.ajouterSituationProfessionnelle(situationProfessionnelle);
    } else {
      if (this.props.isControleDossier) {
        history.push('/dossier-location/pieces-justificatives');
      } else {
        // si le dossier a été créé par un partenaire qui ne souhaite pas de contrôle de PJ
        history.push('/dossier-location/synthese');
      }
    }
  };

  render() {
    const {
      fetching,
      listSituationProfessionnelle,
      dossierLocation,
      error,
      situationProfessionnelle,
      situationProfessionnelleError,
      supprimerSituationPersonnelleError,
      situationProfessionnelleFetching,
    } = this.props;

    return (
      <LoadingBoundary
        loading={fetching}
        error={error}
        onRetry={this.loadInitialValues}
      >
        <AjouterSituationProfessionnelle
          listSituationProfessionnelle={listSituationProfessionnelle}
          initValues={situationProfessionnelle}
          dossierLocation={dossierLocation}
          onSubmit={this.handleSubmit}
          onPrecedent={this.handlePrecedent}
          situationProFetching={situationProfessionnelleFetching}
        />

        <Grid container justify="flex-end" style={{ marginTop: 12 }}>
          {situationProfessionnelleError && (
            <RenderError
              errors={situationProfessionnelleError}
              onClose={supprimerSituationPersonnelleError}
            />
          )}
        </Grid>
      </LoadingBoundary>
    );
  }
}

const mapStateToProps = state => ({
  situationProfessionnelle: SituationProfessionnelleSelector.getSituationProfessionnelle(
    state,
  ),
  situationProfessionnelleError: SituationProfessionnelleSelector.getSituationProfessionnelleError(
    state,
  ),
  situationProfessionnelleFetching: SituationProfessionnelleSelector.getSituationProfessionnelleFetching(
    state,
  ),
  listSituationProfessionnelle: SituationProfessionnelleSelector.getListSituationProfessionnelle(
    state,
  ),
  dossierLocation: DossierLocationSelector.getDossierLocation(state),
  isControleDossier: PartenaireSelector.getControleDossier(state),
  fetching: SituationProfessionnelleSelector.getListSituationProFetching(state),
  error: SituationProfessionnelleSelector.getListSituationProError(state),
  pristine: isPristine('situationProfessionnelleForm')(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  chargerListSituationProfessionnelle: () =>
    dispatch(
      SituationProfessionnelleActionCreator.getListSituationProfessionnelleAsync.request(),
    ),
  ajouterSituationProfessionnelle: (
    situationProfessionnelle: SituationProfessionnelleDossierParticulier,
  ) =>
    dispatch(
      SituationProfessionnelleActionCreator.addOrUpdateSituationProfessionnelleAsync.request(
        situationProfessionnelle,
      ),
    ),
  supprimerSituationPersonnelleError: () =>
    dispatch(
      SituationProfessionnelleActionCreator.removeSituationProfessionnelleError(),
    ),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SituationProfessionnellePage),
);
