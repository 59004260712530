import { withStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';
import logoBlanc from '../../../../ressources/images/logo_vertuloo_blanc.png';

interface IProps {
  classes?: any;
}

const BlocBackground = (props: IProps) => (
  <div className={props.classes.container}>
    <div className={props.classes.texte}>
      <h2>En savoir plus sur</h2>
      <img src={logoBlanc} width={400} alt="" />
    </div>
  </div>
);

export default withStyles(styles)(BlocBackground);
