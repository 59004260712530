import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    iframeWrapper: {
      height: '100%',
      marginBottom: 24,
    },
    infoMessage: {
      "textAlign": 'center',
      "display": 'flex',
      "alignItems": 'center',
      "justifyContent": 'center',
      "marginTop": 24,
      "padding": '12px 24px',
      "background": 'rgba(0, 255, 0, 0.1)',
      '& p': {
        fontWeight: '500',
        marginLeft: 8,
      },
    },
  } as any);

export default styles;
