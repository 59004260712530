/**
 * Titre des colonnes de notre tableau.
 */
export const adbTableauHeader = [
  {
    id: 'identifiant',
    libelle: 'identifiant',
    isTriable: true,
  },
  {
    id: 'nom',
    libelle: 'nom',
    isTriable: true,
  },
  {
    id: 'adresse',
    libelle: 'adresse',
    isTriable: false,
  },
  {
    id: 'codePostal',
    libelle: 'code postal',
    isTriable: true,
  },
  {
    id: 'ville',
    libelle: 'ville',
    isTriable: true,
  },
  {
    id: 'commercial.nom',
    libelle: 'commercial',
    isTriable: true,
  },
  {
    id: 'actif',
    libelle: 'Actif',
    isTriable: false,
  },
];
