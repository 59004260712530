import Types from 'Types';
import {
  ActionType,
  createAsyncAction,
  createStandardAction,
  getType,
} from 'typesafe-actions';
export interface SituationPersonnelleState {
  data?: Types.SituationPersonnelleData;
  fetchInfo: Types.IFetchInfos;
}

export const initialState: SituationPersonnelleState = {
  data: {
    situationPrincipale: { principal: true },
    situationConjoint: undefined,
  } as Types.SituationPersonnelleData,
  fetchInfo: {
    fetching: false,
    error: undefined,
  },
};

export const addSituationPersonnelleAsync = createAsyncAction(
  '@@App/situationPersonnelle/ADD_REQUEST',
  '@@App/situationPersonnelle/ADD_SUCCESS',
  '@@App/situationPersonnelle/ADD_FAILURE',
)<
  Types.SituationPersonnelleDossierParticulierType,
  {
    situation: Types.SituationPersonnelleDossierParticulierType;
    prefixe: Types.PrefixeCompte;
  },
  any
>();

export const updateSituationPersonnelleAsync = createAsyncAction(
  '@@App/situationPersonnelle/UPDATE_REQUEST',
  '@@App/situationPersonnelle/UPDATE_SUCCESS',
  '@@App/situationPersonnelle/UPDATE_FAILURE',
)<
  Types.SituationPersonnelleDossierParticulierType,
  {
    situation: Types.SituationPersonnelleDossierParticulierType;
    prefixe: Types.PrefixeCompte;
  },
  any
>();

export const createOrUpdateSituationPersonnelleAsync = createAsyncAction(
  '@@App/situationPersonnelle/ADD_UPDATE_REQUEST',
  '@@App/situationPersonnelle/ADD_UPDATE_SUCCESS',
  '@@App/situationPersonnelle/ADD_UPDATE_FAILURE',
)<Types.SituationPersonnelleData, void, any>();

export const resetSituationPersonnelleParcoursCreation = createStandardAction(
  '@@App/situationPersonnelle/PARCOURS_CREATION/RESET_STORE',
)<void>();

export const removeSituationPersonnelleConjoint = createStandardAction(
  '@@app/situationPersonnelle/REMOVE_CONJOINT',
)<void>();

export const removeError = createStandardAction(
  '@@app/situationPersonnelle/REMOVE_ERROR',
)<void>();

export const resetSituationPersoAndAddGarant = createStandardAction(
  '@@App/situationPersonnelle/PARCOURS_CREATION/RESET_AND_ADD_GARANT',
)<string>();

export const resetSituationPersoAndAddColoc = createStandardAction(
  '@@App/situationPersonnelle/PARCOURS_CREATION/RESET_AND_ADD_COLOC',
)<string>();
export const resetSituationPersoAndAddLocataire = createStandardAction(
  '@@App/situationPersonnelle/PARCOURS_CREATION/RESET_AND_ADD_LOCATAIRE',
)<string>();
export const ActionCreators = {
  addSituationPersonnelleAsync,
  updateSituationPersonnelleAsync,
  createOrUpdateSituationPersonnelleAsync,
  resetSituationPersonnelleParcoursCreation,
  removeSituationPersonnelleConjoint,
  resetSituationPersoAndAddGarant,
  resetSituationPersoAndAddColoc,
  resetSituationPersoAndAddLocataire,
  removeError,
};

const getSituationPersonnelle = (state): Types.SituationPersonnelleData =>
  state.situationPersonnelle.data;

const getSituationPersonnelleError = (state): any =>
  state.situationPersonnelle.fetchInfo &&
  state.situationPersonnelle.fetchInfo.error;

const getSituationPersonnelleFetching = (state): boolean =>
  state.situationPersonnelle.fetchInfo &&
  state.situationPersonnelle.fetchInfo.fetching;

const getTypeCompte = (state): string =>
  state.situationPersonnelle &&
  state.situationPersonnelle.data &&
  state.situationPersonnelle.data.situationPrincipale &&
  state.situationPersonnelle.data.situationPrincipale.typeCompte;

const getRefLocataireGarant = (state): string =>
  state.situationPersonnelle &&
  state.situationPersonnelle.data &&
  state.situationPersonnelle.data.situationPrincipale &&
  state.situationPersonnelle.data.situationPrincipale.refDossierLocataire;

const getNomLocataire = (state): string => {
  const data = state.situationPersonnelle && state.situationPersonnelle.data;
  if (data && data.situationConjoint && data.situationPrincipale) {
    return (
      data.situationPrincipale.prenom +
      ' ' +
      data.situationPrincipale.nom +
      ' et ' +
      data.situationConjoint.prenom +
      ' ' +
      data.situationConjoint.nom
    );
  }
  return (
    data &&
    data.situationPrincipale &&
    data.situationPrincipale.prenom + ' ' + data.situationPrincipale.nom
  );
};


export const Selectors = {
  getSituationPersonnelle,
  getSituationPersonnelleError,
  getSituationPersonnelleFetching,
  getTypeCompte,
  getRefLocataireGarant,
  getNomLocataire,
};

export const reducer = (
  state: SituationPersonnelleState = initialState,
  action: ActionType<typeof ActionCreators>,
): SituationPersonnelleState => {
  switch (action.type) {
    case getType(addSituationPersonnelleAsync.request):
      return {
        ...state,
        fetchInfo: {
          error: undefined,
          fetching: true,
        },
      };
    case getType(addSituationPersonnelleAsync.success):
      return {
        ...updateSituationPersonnelle(
          state,
          action.payload.situation,
          action.payload.prefixe,
        ),
        fetchInfo: {
          error: undefined,
          fetching: false,
        },
      };
    case getType(addSituationPersonnelleAsync.failure):
      return {
        ...state,
        fetchInfo: {
          error: action.payload,
          fetching: false,
        },
      };
    case getType(updateSituationPersonnelleAsync.request):
      return {
        ...state,
        fetchInfo: {
          error: undefined,
          fetching: true,
        },
      };
    case getType(updateSituationPersonnelleAsync.success):
      return {
        ...updateSituationPersonnelle(
          state,
          action.payload.situation,
          action.payload.prefixe,
        ),
        fetchInfo: {
          error: undefined,
          fetching: false,
        },
      };
    case getType(updateSituationPersonnelleAsync.failure):
      return {
        ...state,
        fetchInfo: {
          error: action.payload,
          fetching: false,
        },
      };
    case getType(createOrUpdateSituationPersonnelleAsync.request):
      return {
        ...state,
        fetchInfo: {
          error: undefined,
          fetching: true,
        },
      };
    case getType(createOrUpdateSituationPersonnelleAsync.failure):
      return {
        ...state,
        fetchInfo: {
          error: action.payload,
          fetching: false,
        },
      };
    case getType(removeError):
      return {
        ...state,
        fetchInfo: {
          error: undefined,
          fetching: false,
        },
      };
    case getType(resetSituationPersonnelleParcoursCreation):
      return {
        ...state,
        data: {
          situationPrincipale: { principal: true },
          situationConjoint: undefined,
        } as Types.SituationPersonnelleData,
        fetchInfo: {
          error: undefined,
          fetching: false,
        },
      };
    case getType(removeSituationPersonnelleConjoint):
      return {
        ...state,
        data: {
          ...state.data,
          situationConjoint: undefined,
        } as Types.SituationPersonnelleData,
      };
    case getType(resetSituationPersoAndAddGarant):
      return {
        ...state,
        data: {
          situationPrincipale: {
            principal: false,
            typeCompte: 'GARANT',
            refDossierLocataire: action.payload,
          },
          situationConjoint: undefined,
        } as Types.SituationPersonnelleData,
        fetchInfo: {
          error: undefined,
          fetching: false,
        },
      };

    case getType(resetSituationPersoAndAddColoc):
      return {
        ...state,
        data: {
          situationPrincipale: {
            principal: false,
            typeCompte: 'LOCATAIRE',
          },
          situationConjoint: undefined,
        } as Types.SituationPersonnelleData,
        fetchInfo: {
          error: undefined,
          fetching: false,
        },
      };
    case getType(resetSituationPersoAndAddLocataire):
      return {
        ...state,
        data: {
          situationPrincipale: {
            principal: true,
            typeCompte: 'LOCATAIRE',
          },
          situationConjoint: undefined,
        } as Types.SituationPersonnelleData,
        fetchInfo: {
          error: undefined,
          fetching: false,
        },
      };
    default:
      return state;
  }
};

const updateSituationPersonnelle = (
  state: SituationPersonnelleState,
  situationPersonnelle: Types.SituationPersonnelleDossierParticulierType,
  prefixe: Types.PrefixeCompte,
): SituationPersonnelleState => {
  const newState = { ...state };
  if (!newState.data) {
    newState.data = {} as Types.SituationPersonnelleData;
  }
  if (prefixe === 'principal') {
    newState.data.situationPrincipale = situationPersonnelle;
  } else {
    newState.data.situationConjoint = situationPersonnelle;
  }
  return newState;
};
