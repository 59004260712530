import { withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Layout from '../../../components/Layout';
import MessageInfo from '../../../components/MessageInfo';
import { history } from '../../../helpers/history';
import {
  ActionCreators as AdbActionCreator,
  Selectors as AdbSelector,
} from '../../../redux/AdministrateurDeBien';
import { Selectors as UtilisateurSelector } from '../../../redux/Utilisateurs';
import {
  AdministrateurBienDetail,
  Site,
  Utilisateur,
} from '../../../services/Api/swagger';
import Libelle from '../../../_i18n/fr.json';
import SelectAdbSiteForm, { ISelectAdbSiteFormData } from './selectAdbSiteForm';
import styles from './style';

interface Props {
  utilisateurConnecte?: Utilisateur;
  adbDetail?: AdministrateurBienDetail[];
  idAdb: number;
  selectedAdbDetail?: AdministrateurBienDetail;
  selectedSiteDetails?: Site;
  adbFetching: boolean;
  adbError: any;

  getAdbDetail(idAdb: number): void;

  setSelectedSite(site?: Site): void;

  updateAdbId(id: number): void;

  classes?: any;
}

class SelectAdbSitePage extends React.Component<Props> {
  handleSelectAdb = idAdb => {
    if (idAdb && idAdb !== '') {
      const { getAdbDetail, updateAdbId } = this.props;
      getAdbDetail(idAdb);
      updateAdbId(Number(idAdb));
    }
  };

  handleSelectSite = idSite => {
    const { selectedAdbDetail, setSelectedSite } = this.props;

    if (idSite && idSite !== '') {
      const sites = selectedAdbDetail && selectedAdbDetail.sites;
      const site =
        sites && sites.find(s => s.numero && s.numero === Number(idSite));
      setSelectedSite(site);
    }
  };

  handleSubmit = (selectForm: ISelectAdbSiteFormData) => {
    if (selectForm.adb && selectForm.site) {
      history.push('/dossier-location/pre-formulaire');
    }
  };

  render() {
    const {
      utilisateurConnecte,
      selectedAdbDetail,
      selectedSiteDetails,
      adbFetching,
      idAdb,
      adbDetail,
    } = this.props;

    let listAdb;
    let sitesAutoriseeDetails;
    let messageBloquerAdb;
    if (
      utilisateurConnecte &&
      utilisateurConnecte.role === 'ADB' &&
      utilisateurConnecte.detailAdbProfilSites
    ) {
      const selectedAdb = utilisateurConnecte.detailAdbProfilSites.find(
        adb =>
          adb &&
          selectedAdbDetail &&
          adb.refAdb === selectedAdbDetail.identifiant,
      );
      const sitesAutorisee = selectedAdb && selectedAdb.sites;
      sitesAutoriseeDetails =
        sitesAutorisee &&
        selectedAdbDetail &&
        selectedAdbDetail.sites &&
        selectedAdbDetail.sites.filter(
          s => s.numero && sitesAutorisee.includes(s.numero),
        );
      listAdb = utilisateurConnecte.detailAdbProfilSites.map(adb => ({
        identifiant: adb.refAdb,
        nom: adb.nomAdb,
      }));
    } else if (
      utilisateurConnecte &&
      utilisateurConnecte.role === 'BESSE' &&
      idAdb &&
      adbDetail
    ) {
      const adbfinded = adbDetail.find(s => idAdb === s.identifiant);
      listAdb = adbfinded ? [adbfinded] : [];
      sitesAutoriseeDetails = adbfinded ? adbfinded.sites : [];
      messageBloquerAdb =
        (adbfinded &&
          adbfinded.applications &&
          adbfinded.applications[0] &&
          adbfinded.applications[0].parametrage &&
          !adbfinded.applications[0].parametrage.actif) ||
          (adbfinded &&
            adbfinded.applications &&
            adbfinded.applications.length === 0) ? (
          <MessageInfo messageUrl="L'administrateur de bien que vous avez sélectionné n'est pas activé, veuillez l'activer afin de pouvoir créer un dossier de location" />
        ) : (
          ''
        );
    }

    const onlyBesse =
      this.props.utilisateurConnecte &&
      this.props.utilisateurConnecte.role === 'BESSE' &&
      !this.props.idAdb;

    const infoAdbUtilisateurs = onlyBesse ? (
      <MessageInfo
        messageUrl="sélectionner un administrateur de bien"
        url="/mes-administrateurs-de-biens"
      >
        Pour visualiser les sites d'un administrateur de bien, veuillez en
        sélectionner un :
      </MessageInfo>
    ) : (
      ''
    );

    return (
      <React.Fragment>
        <Layout title={Libelle.pageSelectAdbSite.titre}>
          <>
            {infoAdbUtilisateurs}
            {messageBloquerAdb ? (
              messageBloquerAdb
            ) : (
              <SelectAdbSiteForm
                listAdb={listAdb}
                listSite={sitesAutoriseeDetails}
                selectedAdbDetail={selectedAdbDetail}
                selectedSiteDetail={selectedSiteDetails}
                onSelectAdb={this.handleSelectAdb}
                onSelectSite={this.handleSelectSite}
                isFetching={adbFetching}
                onSubmit={this.handleSubmit}
              />
            )}
          </>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  utilisateurConnecte: UtilisateurSelector.getUtilisateurConnecte(state),
  adbDetail: AdbSelector.getADBDetail(state),
  adbFetching: AdbSelector.getADBFetching(state),
  adbError: AdbSelector.getADBError(state),
  selectedAdbDetail: AdbSelector.getSelectedAdbDetail(state),
  selectedSiteDetails: AdbSelector.getSelectedSite(state),
  idAdb: AdbSelector.getAdbId(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAdbDetail: (idAdb: number) =>
    dispatch(AdbActionCreator.getADBIdDetail.request(idAdb)),
  setSelectedSite: (site: Site) =>
    dispatch(AdbActionCreator.setSelectedSite(site)),
  updateAdbId: (id: number) => dispatch(AdbActionCreator.updateAdbId(id)),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SelectAdbSitePage),
);
