import React from 'react';
import CelibataireSVG from '../../../ressources/images/CelibataireSVG';
import ColocataireSVG from '../../../ressources/images/ColocataireSVG';
import CoupleSVG from '../../../ressources/images/CoupleSVG';
import { DossierLocationCompositionLocationEnum } from '../../../services/Api/swagger';

export const mapTypeDossier = [
  {
    name: DossierLocationCompositionLocationEnum['SEUL'],
    img: <CelibataireSVG />,
    libelle: 'Célibataire',
    libelleComplementaire: '',
  },
  {
    name: DossierLocationCompositionLocationEnum['COUPLE'],
    img: <CoupleSVG />,
    libelle: 'Couple',
    libelleComplementaire:
      '(Cette sélection concerne les couples mariés et pacsés ayant le même avis d’imposition) ',
  },
  {
    name: DossierLocationCompositionLocationEnum['COLOCATION'],
    img: <ColocataireSVG />,
    libelle: 'Colocation',
    libelleComplementaire: '',
  },
];
