import { Grid, Link, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import cx from 'classnames';
import React from 'react';
import FlecheSVG from '../../ressources/images/FlecheSVG';
import MonBienSVG from '../../ressources/images/MonBienSVG';
import PiecesSVG from '../../ressources/images/PiecesSVG';
import SituationPersoSVG from '../../ressources/images/SituationPersoSVG';
import SituationProSVG from '../../ressources/images/SituationProSVG';
import SyntheseSVG from '../../ressources/images/SyntheseSVG';
import Libelle from '../../_i18n/fr.json';
import styles from './styles';
import { Selectors as DossierLocationSelectors } from '../../redux/DossierLocation';
import { Selectors as PartenaireSelectors } from '../../redux/Partenaire';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  // Etape en cours en visualisation
  isEtape?:
  | 'monBien'
  | 'situationPersonnelle'
  | 'situationProfessionnelle'
  | 'justificatifs'
  | 'synthese';
  // Permet de faire varier le styles
  variant?: 'dossierLocation';
  // Si l'étape pièces justificatives doit être affichée ou non
  isControleDossier?: boolean;
  fetching?: boolean;
}

/**
 * Composant affichant un message d'erreur sous forme de snack.
 */
class CheminDeFer extends React.Component<Props> {
  renderMap() {
    let mapDossierLocation = [
      {
        name: 'monBien',
        img: <MonBienSVG />,
        libelle: Libelle.cheminDeFer.bien,
        description: '',
      },
      {
        name: 'situationPersonnelle',
        img: <SituationPersoSVG />,
        libelle: Libelle.cheminDeFer.situationPersonnelle,
        description: undefined,
      },
      {
        name: 'situationProfessionnelle',
        img: <SituationProSVG />,
        libelle: Libelle.cheminDeFer.situationProfessionnelle,
        description: undefined,
      },
    ];
    const justif = {
      name: 'justificatifs',
      img: <PiecesSVG />,
      libelle: Libelle.cheminDeFer.justificatifs,
      description: undefined,
    };
    const synthese = {
      name: 'synthese',
      img: <SyntheseSVG />,
      libelle: Libelle.cheminDeFer.synthese,
      description: undefined,
    };
    if (this.props.isControleDossier) {
      mapDossierLocation.push(justif);
    }
    mapDossierLocation.push(synthese);
    const maMap = mapDossierLocation;
    return maMap;
  }

  public render() {
    const { classes, isEtape, variant, fetching } = this.props;
    const maMap = this.renderMap();
    return (
      <div className={classes.container}>
        <Grid
          container
          justify="space-between"
          className={classes.containerLink}
        >
          <Link href="/dashboard" color="inherit" className="backLink">
            <ArrowLeft /> Retour au dashboard
          </Link>
        </Grid>

        {!fetching &&
          <Grid
            container
            component="ul"
            justify="center"
            className={classes.root}
          >
            {maMap.map((etape, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid
                    item
                    component="li"
                    className={cx(
                      classes.item,
                      {
                        etapeEnCours: isEtape === etape.name,
                      },
                      variant,
                    )}
                  >
                    {etape.img}
                    <Typography component="span">{etape.libelle}</Typography>
                    {etape.description && (
                      <div className={classes.etapeDescription}>
                        {etape.description}
                      </div>
                    )}
                  </Grid>
                  {maMap.length !== index + 1 && (
                    <Grid item component="li" className={classes.fleche}>
                      <FlecheSVG />
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isControleDossier: PartenaireSelectors.getControleDossier(state),
  fetching: DossierLocationSelectors.getDossierSyntheseFetching(state)
    || PartenaireSelectors.getPartenaireFetching(state),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
  )(CheminDeFer)
);

