import Types from 'Types';
import {
  ActionType,
  createAsyncAction,
  createStandardAction,
  getType,
} from 'typesafe-actions';
import { OffrePartenaire, Partenaire } from '../../services/Api/swagger';

export interface PartenaireState {
  data?: Partenaire;
  fetchInfo: Types.IFetchInfos;
}

export const initialState: PartenaireState = {
  data: undefined,
  fetchInfo: {
    error: undefined,
    fetching: false,
  },
};

export const getPartenaireParCode = createAsyncAction(
  '@@App/dossierLocation/GET_PARTENAIRE_PAR_CODE_REQUEST',
  '@@App/dossierLocation/GET_PARTENAIRE_PAR_CODE_SUCCESS',
  '@@App/dossierLocation/GET_PARTENAIRE_PAR_CODE_FAILURE',
)<string, Partenaire, any>();

export const resetPartenaire = createStandardAction(
  '@@App/dossierLocation/RESET_PARTENAIRE',
)<void>();

export const ActionCreators = {
  getPartenaireParCode,
  resetPartenaire,
};

const getPartenaire = (state: Types.RootState): Partenaire | undefined =>
  state.partenaire.data;

const getPartenaireFetching = (state: Types.RootState): boolean =>
  state.partenaire.fetchInfo.fetching;

const getOffrePartenaire = (state: Types.RootState): OffrePartenaire | undefined => {
  const codeOffre = (state.dossierLocation && state.dossierLocation.data && state.dossierLocation.data.partenaire)
    ? state.dossierLocation.data.partenaire.codeOffre
    : undefined;
  if (codeOffre && state.partenaire && state.partenaire.data && state.partenaire.data.offres) {
    const offresTrouves = state.partenaire.data.offres.filter((offre) => offre.code === codeOffre);
    if (offresTrouves && offresTrouves.length > 0) {
      return offresTrouves[0];
    }
  }
  return undefined;
}

const getControleDossier = (state: Types.RootState): boolean => {
  const offreP = getOffrePartenaire(state);
  return Boolean(!offreP || offreP.controleDossier);
}

const getCumulsUtilises = (state: Types.RootState): boolean => {
  const offreP = getOffrePartenaire(state);
  return Boolean(!offreP || offreP.utilisationCumuls);
}

export const Selectors = {
  getPartenaire,
  getPartenaireFetching,
  getControleDossier,
  getCumulsUtilises,
};

export const reducer = (
  state: PartenaireState = initialState,
  action: ActionType<typeof ActionCreators>,
): PartenaireState => {
  switch (action.type) {

    case getType(getPartenaireParCode.request):
      return {
        ...state,
        data: undefined,
        fetchInfo: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(getPartenaireParCode.success):
      return {
        ...state,
        data: action.payload,
        fetchInfo: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(getPartenaireParCode.failure):
      return {
        ...state,
        fetchInfo: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(resetPartenaire):
      return {
        ...state,
        data: undefined,
        fetchInfo: {
          fetching: false,
          error: undefined,
        },
      };

    default:
      return state;
  }
};
