import Types from 'Types';
import {
  ActionType,
  createAsyncAction,
  createStandardAction,
  getType,
} from 'typesafe-actions';
import {
  Bien,
  BienDetail,
  NombrePieces,
  TypeBien,
} from '../../services/Api/swagger';

export interface BienState {
  data?: BienDetail;
  typesBien?: TypeBien[];

  bienFetchInfo: Types.IFetchInfos;
  typeBienFetchInfo: Types.IFetchInfos;

  nombrePieces?: NombrePieces[];
  nombrePiecesFetchInfo: Types.IFetchInfos;
}

/**
 * Init value est temporaire en attentant
 * l'US de récupération du dossier de location
 */
export const initialState: BienState = {
  data: undefined,
  bienFetchInfo: {
    fetching: false,
    error: undefined,
  },
  typesBien: undefined,
  typeBienFetchInfo: {
    fetching: false,
    error: undefined,
  },
  nombrePieces: undefined,
  nombrePiecesFetchInfo: {
    fetching: false,
    error: undefined,
  },
};

export const addBienAsync = createAsyncAction(
  '@@App/bien/ADD_REQUEST',
  '@@App/bien/ADD_SUCCESS',
  '@@App/bien/ADD_FAILURE',
)<{ bien: Bien; form: string }, BienDetail, any>();

export const updateBienAsync = createAsyncAction(
  '@@App/bien/UPDATE_REQUEST',
  '@@App/bien/UPDATE_SUCCESS',
  '@@App/bien/UPDATE_FAILURE',
)<{ bien: Bien; form: string }, BienDetail, any>();

export const lancerChargementBien = createAsyncAction(
  '@@App/bien/PREREMPLIR_REQUEST',
  '@@App/bien/PREREMPLIR_SUCCESS',
  '@@App/bien/PREREMPLIR_FAILURE',
)<{ referenceMandat: string; numeroLot: string }, BienDetail, any>();

export const removeError = createStandardAction(
  '@@app/bien/supprimerBienErrors',
)<void>();

export const loadTypeBienAsync = createAsyncAction(
  '@@App/bien/types/LOAD_REQUEST',
  '@@App/bien/types/LOAD_SUCCESS',
  '@@App/bien/types/LOAD_FAILURE',
)<void, TypeBien[], any>();

export const loadNombrePieces = createAsyncAction(
  '@@App/bien/nombrePieces/LOAD_REQUEST',
  '@@App/bien/nombrePieces/LOAD_SUCCESS',
  '@@App/bien/nombrePieces/LOAD_FAILURE',
)<void, NombrePieces[], any>();

export const resetBienParcoursCreation = createStandardAction(
  '@@App/bien/PARCOURS_CREATION/RESET_STORE',
)<void>();

export const ActionCreators = {
  addBienAsync,
  updateBienAsync,
  lancerChargementBien,
  loadTypeBienAsync,
  loadNombrePieces,
  removeError,
  resetBienParcoursCreation,
};

const getBien = (state: Types.RootState): BienDetail | undefined =>
  state.bien.data;

const getBienFetching = (state: Types.RootState): boolean =>
  state.bien.bienFetchInfo.fetching;
const getBienError = (state: Types.RootState): boolean =>
  state.bien.bienFetchInfo.error;

const getTypesBien = (state: Types.RootState): any | undefined =>
  state.bien.typesBien;

const getTypesBienFetching = (state: Types.RootState): boolean =>
  state.bien.typeBienFetchInfo.fetching;

const getTypesBienError = (state: Types.RootState): any =>
  state.bien.typeBienFetchInfo.error;

const getNombrePieces = (state: Types.RootState): any | undefined =>
  state.bien.nombrePieces;

const getNombrePiecesFetching = (state: Types.RootState): boolean =>
  state.bien.nombrePiecesFetchInfo.fetching;

const getNombrePiecesError = (state: Types.RootState): any | undefined =>
  state.bien.nombrePiecesFetchInfo.error;

export const Selectors = {
  getBien,
  getBienFetching,
  getBienError,
  getTypesBien,
  getTypesBienFetching,
  getTypesBienError,
  getNombrePieces,
  getNombrePiecesFetching,
  getNombrePiecesError,
};

export const reducer = (
  state: BienState = initialState,
  action: ActionType<typeof ActionCreators>,
): BienState => {
  switch (action.type) {
    case getType(addBienAsync.request):
      return {
        ...state,
        bienFetchInfo: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(addBienAsync.success):
      return {
        ...state,
        data: action.payload,
        bienFetchInfo: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(addBienAsync.failure):
      return {
        ...state,
        bienFetchInfo: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(updateBienAsync.request):
      return {
        ...state,
        bienFetchInfo: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(updateBienAsync.success):
      return {
        ...state,
        data: action.payload,
        bienFetchInfo: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(updateBienAsync.failure):
      return {
        ...state,
        bienFetchInfo: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(lancerChargementBien.request):
      return {
        ...state,
        bienFetchInfo: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(lancerChargementBien.success):
      return {
        ...state,
        data: preremplirFormulaire(action.payload, state.data),
        bienFetchInfo: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(lancerChargementBien.failure):
      return {
        ...state,
        bienFetchInfo: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(loadTypeBienAsync.request):
      return {
        ...state,
        typeBienFetchInfo: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(loadTypeBienAsync.success):
      return {
        ...state,
        typesBien: action.payload,
        typeBienFetchInfo: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(loadTypeBienAsync.failure):
      return {
        ...state,
        typeBienFetchInfo: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(loadNombrePieces.request):
      return {
        ...state,
        nombrePiecesFetchInfo: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(loadNombrePieces.success):
      return {
        ...state,
        nombrePieces: action.payload,
        nombrePiecesFetchInfo: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(loadNombrePieces.failure):
      return {
        ...state,
        nombrePiecesFetchInfo: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(removeError):
      return {
        ...state,
        bienFetchInfo: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(resetBienParcoursCreation):
      return {
        ...state,
        data: undefined,
        bienFetchInfo: {
          fetching: false,
          error: undefined,
        },
      };
  }
  return state;
};

const preremplirFormulaire = (
  newBien: BienDetail,
  bienState?: BienDetail,
): BienDetail => {
  const { dateCreation, dateModification, identifiant, ...bien } = newBien;
  if (bienState && bienState.identifiant) {
    const bienCopie = bien as BienDetail;
    bienCopie.identifiant = bienState.identifiant;
    return bienCopie;
  } else {
    return bien;
  }
};
