import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'start',
      alignItems: 'center',
      textAlign: 'center',
      color: 'black',
      flexWrap: 'nowrap',
      '&>.Mui-focused': {
        color: theme.palette.common.black,
      },
    },

    formLabel: {
      margin: '0 12px',
      color: theme.palette.common.black,
      fontWeight: 'bold',
      fontSize: '0.8rem',
      flex: 1,
      flexBasis: '200px',
      flexGrow: 0,
      flexShrink: 0,
    },

    radioGroup: {
      margin: 'auto 0.5rem',
      flex: 1,
    },
    icon: {
      'width': 16,
      'position': 'relative',
      'height': 16,
      'borderRadius': '100%',
      'background': theme.palette.common.white,
      '& svg': {
        position: 'absolute',
        top: 2,
        left: 2,
        width: 12,
        height: 12,
        color: theme.palette.common.black,
      },
    },
  } as any);

export default styles;
