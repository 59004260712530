import { TextField, withStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import React, { ChangeEvent } from 'react';
import dropdown from '../../ressources/images/dropdown.svg';
import styles from './styles';

export interface DropdownItem {
  key: string | number;
  text: string;
  value: string | number;
  simpleField?: boolean;
  size?: number;
}

export interface IProps {
  id?: string;
  disabled?: boolean;
  value?: string;
  label: string;
  options: DropdownItem[];
  readOnly?: boolean;
  required?: boolean; // Le champ est-il requis ?
  classes?: any;
  placeholder?: string;
  handleChange?(id, value): void;
}

interface IState {
  value?: string | number;
}

const svgIcon = props => <img src={dropdown} {...props} alt="" />;

class UncontrolledSelect extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    let value = props.value || '';
    if (value && props.options.length > 0) {
      const findOption =
        props.options.filter(o => o.value === value).length > 0;
      if (!findOption) {
        value = '';
      }
    }
    this.state = {
      value,
    };
  }

  updateValue(value) {
    this.setState({
      value,
    });
  }

  componentDidUpdate(prevProps) {
    // if (prevProps && this.props && !isEqual(prevProps.options, props.options)) {
    //   this.updateValue('');
    // } else if (
    //   prevProps &&
    //   prevProps.value !== value &&
    //   prevProps.options.length > 0 &&
    //   prevProps.options
    // ) {
    //   this.updateValue(value);
    // }
  }

  handleChange = (e: ChangeEvent<any>) => {
    e.preventDefault();
    this.setState({ value: e.target.value });
    if (this.props.handleChange) {
      this.props.handleChange(e.target.id, e.target.value);
    }
  };

  render() {
    const {
      id,
      disabled,
      label,
      options,
      required,
      readOnly,
      placeholder,
      classes,
    } = this.props;

    const { value } = this.state;

    return (
      <FormControl className={classes.root}>
        <TextField
          id={id}
          select
          label={label}
          value={value}
          onChange={this.handleChange}
          required={required}
          disabled={disabled || readOnly}
          InputProps={{
            classes: {
              underline: classes.underline,
            },
          }}
          SelectProps={{
            classes: {
              root: classes.textField,
              icon: classes.iconSelect,
              select: classes.select,
            },
            native: true,
            IconComponent: svgIcon,
          }}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabel,
              focused: classes.textFieldLabelFocused,
              error: classes.textFieldLabelError,
              asterisk: classes.textFieldLabelAsterisk,
            },
          }}
          variant="filled"
          className={classes.textFieldRoot}
        >
          <option disabled value="">
            {placeholder}
          </option>
          {options.map(option => (
            <option key={option.key} value={option.value}>
              {option.text}
            </option>
          ))}
        </TextField>
      </FormControl>
    );
  }
}
export default withStyles(styles)(UncontrolledSelect);
