import { Theme } from '@material-ui/core/styles';
import { gris1 } from '../../theme';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
  ({
    root: {
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .etapeEnCours': {
        '& span': {
          fontWeight: 'bold',
          color: theme.palette.common.black,
        },
        '& svg #Mask': {
          fill: theme.palette.primary.main,
        },
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    item: {
      display: 'flex',
      marginBottom: 24,
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: 200,
      maxWidth: 258,
      listStyle: 'none',
      textAlign: 'center',
      color: gris1,
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'center',
      },
      '& span': {
        marginTop: 12,
        lineHeight: '1em',
        fontSize: '1em',
      },
    },
    etapeDescription: {
      lineHeight: '1.5em',
      fontSize: '1em',
      marginTop: '1px',
      fontWeight: 'normal',
    },
    fleche: {
      marginBottom: 24,
      minWidth: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        transform: 'rotate(90deg)',
      },
    },
    plus: {
      marginBottom: 24,
      fontSize: 60,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    containerSvg: {
      height: 170,
      background: theme.palette.primary.main,
      overflow: 'hidden',
      borderRadius: '100%',
      boxShadow:
        '0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);',
    },
    containerLink: {
      marginBottom: 16,
    },
  } as any);
// tslint:enable: quotemark

export default styles;
