import { AxiosResponse, AxiosInstance } from 'axios';

import {
    LocalisationApiFactory, Commune, TypeVoie,
} from './swagger';

export interface IApi {
    getCommunes(params: Commune): Promise<Commune[]>;
    getTypesVoie(): Promise<TypeVoie[]>;
}

const create = (endpoint: string, axiosClient: AxiosInstance) => {
    const localisationApi = LocalisationApiFactory(
        {},
        endpoint,
        axiosClient,
    );

    /**
     * Enregistrer une situation personnelle
     * On créé element par element
     * Meme si l'API permet de faire une création en masse
     *
     * @param params : Critères de recherche
     */
    const getCommunes = (params: Commune): Promise<Commune[]> => {
        const promise: Promise<Commune[]> = new Promise(
            (resolve, reject) => {
                localisationApi
                    .communesGet(
                        params.codePostal,
                        params.nom,
                        {},
                    )
                    .then((response: AxiosResponse<Commune[]>) => {
                        resolve(response.data);
                    })
                    .catch(error => reject(error));
            },
        );
        return promise;
    };

    const getTypesVoie = (): Promise<TypeVoie[]> => {
        const promise: Promise<TypeVoie[]> = new Promise(
            (resolve, reject) => {
                localisationApi
                    .typesVoiesGet(
                        {},
                    )
                    .then((response: AxiosResponse<TypeVoie[]>) => {
                        resolve(response.data);
                    })
                    .catch(error => reject(error));
            },
        );
        return promise;
    };


    return {
        getCommunes,
        getTypesVoie,
    };
};

export default create;
