import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import React from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import IconCheckbox from './IconCheckbox';
import styles from './styles';

interface Props {
  input?: WrappedFieldInputProps;
  meta?: WrappedFieldMetaProps;
  id: string | number;
  name: string;
  value: boolean;
  label: string;
  errorMessage?: string;
  onChangeValue?: any;
  required?: boolean;
  classes?: any;
}

export interface State {
  checked?: boolean;
}
class InputCheckbox extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let checked =
      (props.input && props.input.value) ||
      (props.meta && props.meta.initial) ||
      this.props.value;
    if (checked === undefined) {
      checked = false;
    }
    this.state = {
      checked,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const checked =
      (this.props.input && this.props.input.value) ||
      (this.props.meta && this.props.meta.initial) ||
      this.props.value ||
      false;

    if (prevState.checked !== checked) {
      this.setState({
        checked,
      });
    }
  }

  handleChange = e => {
    this.setState({
      checked: e.target.checked,
    });
    if (this.props.input) {
      this.props.input.onChange(e.target.checked);
    }
    if (this.props.onChangeValue) {
      this.props.onChangeValue(this.props.id, e.target.checked);
    }
  };

  render() {
    const { classes, required, name, label } = this.props;
    return (
      <FormControlLabel
        className={classes.root}
        control={
          <Checkbox
            className={classes.checkbox}
            required={required}
            name={name}
            checked={this.state.checked}
            onChange={this.handleChange}
            icon={<IconCheckbox />}
            checkedIcon={<IconCheckbox checked />}
          />
        }
        label={label}
      />
    );
  }
}

export default withStyles(styles)(InputCheckbox);
