import Types from 'Types';
import {
  ActionType,
  createAsyncAction,
  createStandardAction,
  getType,
} from 'typesafe-actions';
import {
  FonctionPoste,
  PageUtilisateurs,
  Profil,
  Token,
  Utilisateur,
} from '../../services/Api/swagger';
import { changeUtilisateurStatut } from './helpers';

export interface UtilisateursState {
  utilisateur?: Utilisateur;
  utilisateurConnecte?: Utilisateur;
  error?: any;
  fonctionsPostes?: FonctionPoste[];
  errorFonctions?: any;
  profilsBesse?: Profil[];
  utilisateurFetchInfos: Types.IFetchInfos;
  confirmationCreationUtilisateur: boolean;
  searchParams?: any;
  searchResults?: PageUtilisateurs;
  searchFetchInfos: Types.IFetchInfos;
  activationFetchInfos: Types.IFetchInfos;
  confirmationDesactivationUtilisateur: boolean;
  confirmationActivationUtilisateur: boolean;
  profilUtilisateur?: Profil;
  profilFetchInfos: Types.IFetchInfos;
  activationCompteFetchInfos: Types.IFetchInfos;
}

export const initialState: UtilisateursState = {
  utilisateur: undefined,
  utilisateurConnecte: undefined,
  error: undefined,
  fonctionsPostes: undefined,
  errorFonctions: undefined,
  profilsBesse: undefined,
  utilisateurFetchInfos: {
    fetching: false,
    error: undefined,
  },
  confirmationCreationUtilisateur: false,
  searchParams: undefined,
  searchResults: undefined,
  searchFetchInfos: {
    fetching: false,
    error: undefined,
  },
  activationFetchInfos: {
    fetching: false,
    error: undefined,
  },
  confirmationDesactivationUtilisateur: false,
  confirmationActivationUtilisateur: false,
  profilUtilisateur: undefined,
  profilFetchInfos: {
    fetching: false,
    error: undefined,
  },
  activationCompteFetchInfos: {
    fetching: false,
    error: undefined,
  },
};

const ajouterUtilisateur = createAsyncAction(
  '@@app/utilisateurs/ADD_REQUEST',
  '@@app/utilisateurs/ADD_SUCCESS',
  '@@app/utilisateurs/ADD_FAILURE',
)<Utilisateur, Utilisateur, Error>();

const getFonctionsPostes = createAsyncAction(
  '@@app/utilisateurs/GET_FONCTIONS_POSTES_REQUEST',
  '@@app/utilisateurs/GET_FONCTIONS_POSTES_SUCCESS',
  '@@app/utilisateurs/GET_FONCTIONS_POSTES_FAILURE',
)<void, FonctionPoste[], any>();

const getUtilisateurConnecteByUsername = createAsyncAction(
  '@@app/utilisateurs/GET_UTILISATEUR_CONNECTE_REQUEST',
  '@@app/utilisateurs/GET_UTILISATEUR_CONNECTE_SUCCESS',
  '@@app/utilisateurs/GET_UTILISATEUR_CONNECTE_FAILURE',
)<string, Utilisateur, any>();

const getUtilisateurByUsername = createAsyncAction(
  '@@app/utilisateurs/GET_UTILISATEUR_REQUEST',
  '@@app/utilisateurs/GET_UTILISATEUR_SUCCESS',
  '@@app/utilisateurs/GET_UTILISATEUR_FAILURE',
)<string, Utilisateur, any>();

const getProfilsBesse = createAsyncAction(
  '@@app/utilisateurs/GET_PROFILS_BESSE_REQUEST',
  '@@app/utilisateurs/GET_PROFILS_BESSE_SUCCESS',
  '@@app/utilisateurs/GET_PROFILS_BESSE_FAILURE',
)<string, Profil[], any>();

const confirmationCreationUtilisateur = createStandardAction(
  '@@app/utilisateurs/ConfirmationCreationUtilisateur',
)<boolean>();

const emptyErrorMessages = createStandardAction(
  '@@app/utilisateurs/EmptyErrorMessages',
)();

const getAllUtilisateursBesse = createAsyncAction(
  '@@app/utilisateurs/GET_UTILISATEURS_BESSE_REQUEST',
  '@@app/utilisateurs/GET_UTILISATEURS_BESSE_SUCCESS',
  '@@app/utilisateurs/GET_UTILISATEURS_BESSE_FAILURE',
)<any, PageUtilisateurs, any>();

const getAllUtilisateursAdb = createAsyncAction(
  '@@app/utilisateurs/GET_UTILISATEURS_ADB_REQUEST',
  '@@app/utilisateurs/GET_UTILISATEURS_ADB_SUCCESS',
  '@@app/utilisateurs/GET_UTILISATEURS_ADB_FAILURE',
)<any, PageUtilisateurs, any>();

const desactiverUtilisateur = createAsyncAction(
  '@@app/utilisateurs/DESACTIVER_UTILISATEURS_REQUEST',
  '@@app/utilisateurs/DESACTIVER_UTILISATEURS_SUCCESS',
  '@@app/utilisateurs/DESACTIVER_UTILISATEURS_FAILURE',
)<string, void, any>();

const activerUtilisateur = createAsyncAction(
  '@@app/utilisateurs/ACTIVER_UTILISATEURS_REQUEST',
  '@@app/utilisateurs/ACTIVER_UTILISATEURS_SUCCESS',
  '@@app/utilisateurs/ACTIVER_UTILISATEURS_FAILURE',
)<string, void, any>();

const changerUtilisateurStatut = createStandardAction(
  '@@app/utilisateurs/changerUtilisateurStatut',
)<{ username: string; actif: boolean }>();

const confirmationDesactivationUtilisateur = createStandardAction(
  '@@app/utilisateurs/confirmationDesactivationUtilisateur',
)<boolean>();

const confirmationActivationUtilisateur = createStandardAction(
  '@@app/utilisateurs/confirmationActivationUtilisateur',
)<boolean>();

const modifierUtilisateur = createAsyncAction(
  '@@app/utilisateurs/MODIFIER_UTILISATEURS_REQUEST',
  '@@app/utilisateurs/MODIFIER_UTILISATEURS_SUCCESS',
  '@@app/utilisateurs/MODIFIER_UTILISATEURS_FAILURE',
)<any, void, any>();

const getProfil = createAsyncAction(
  '@@app/utilisateurs/PROFIL_REQUEST',
  '@@app/utilisateurs/PROFIL_SUCCESS',
  '@@app/utilisateurs/PROFIL_FAILURE',
)<number, Profil, any>();

const activerCompteUtilisateur = createAsyncAction(
  '@@app/utilisateurs/ACTIVER_COMPTE_REQUEST',
  '@@app/utilisateurs/ACTIVER_COMPTE_SUCCESS',
  '@@app/utilisateurs/ACTIVER_COMPTE_FAILURE',
)<Token, void, any>();
export const ActionCreators = {
  ajouterUtilisateur,
  getFonctionsPostes,
  getUtilisateurConnecteByUsername,
  getUtilisateurByUsername,
  getProfilsBesse,
  confirmationCreationUtilisateur,
  emptyErrorMessages,
  getAllUtilisateursBesse,
  getAllUtilisateursAdb,
  desactiverUtilisateur,
  activerUtilisateur,
  changerUtilisateurStatut,
  confirmationDesactivationUtilisateur,
  confirmationActivationUtilisateur,
  modifierUtilisateur,
  getProfil,
  activerCompteUtilisateur,
};

const getUtilisateur = (state: Types.RootState): Utilisateur | undefined =>
  state.utilisateurs && state.utilisateurs.utilisateur;

const getUtilisateurError = (state: Types.RootState): any | undefined =>
  state.utilisateurs.utilisateurFetchInfos.error;

const getUtilisateurFetching = (state: Types.RootState): boolean =>
  (state.utilisateurs &&
    state.utilisateurs.utilisateurFetchInfos &&
    state.utilisateurs.utilisateurFetchInfos.fetching) ||
  false;

const getListFonctionsPostes = (
  state: Types.RootState,
): FonctionPoste[] | undefined =>
  state.utilisateurs && state.utilisateurs.fonctionsPostes;

const fonctionsPostesError = (state: Types.RootState): any | undefined =>
  state.utilisateurs.errorFonctions;

const getUtilisateurConnecte = (
  state: Types.RootState,
): Utilisateur | undefined =>
  state.utilisateurs && state.utilisateurs.utilisateurConnecte;

const getProfilsBesseListe = (state: Types.RootState): Profil[] | undefined =>
  state.utilisateurs && state.utilisateurs.profilsBesse;

const getConfirmationCreationUtilisateur = (state: Types.RootState): boolean =>
  state.utilisateurs.confirmationCreationUtilisateur;

const getSearchResults = (state: Types.RootState): Utilisateur[] =>
  (state.utilisateurs.searchResults &&
    state.utilisateurs.searchResults.elements) ||
  [];

const getSearchCount = (state: Types.RootState): number =>
  (state.utilisateurs.searchResults &&
    state.utilisateurs.searchResults.total) ||
  0;

const getSearchFetching = (state: Types.RootState): boolean =>
  state.utilisateurs.searchFetchInfos &&
  state.utilisateurs.searchFetchInfos.fetching;

const getSearchError = (state: Types.RootState): Error | undefined =>
  state.utilisateurs.searchFetchInfos &&
  state.utilisateurs.searchFetchInfos.error;

const getSearchParams = (state: Types.RootState): any | undefined =>
  state.utilisateurs.searchParams;

const getActivationFetching = (state: Types.RootState): boolean =>
  state.utilisateurs.activationFetchInfos &&
  state.utilisateurs.activationFetchInfos.fetching;

const getActivationError = (state: Types.RootState): any | undefined =>
  state.utilisateurs.activationFetchInfos &&
  state.utilisateurs.activationFetchInfos.error;

const getConfirmationDesactivationUtilisateur = (
  state: Types.RootState,
): boolean => state.utilisateurs.confirmationDesactivationUtilisateur;

const getConfirmationActivationUtilisateur = (
  state: Types.RootState,
): boolean => state.utilisateurs.confirmationActivationUtilisateur;

const getProfilUtilisateur = (state: Types.RootState): Profil | undefined =>
  state.utilisateurs && state.utilisateurs.profilUtilisateur;

const getProfilId = (state: Types.RootState): number | undefined => {
  const utilisateur =
    state.utilisateurs && state.utilisateurs.utilisateurConnecte;
  const id =
    utilisateur && utilisateur.detailAdbProfilSites
      ? utilisateur.detailAdbProfilSites[0].profil
      : utilisateur && utilisateur.profilBesse;
  return id;
};

const isChargeClienteleBesse = (state: Types.RootState): boolean => {
  const profil = state.utilisateurs && state.utilisateurs.profilUtilisateur;
  return profil && profil.identifiant === 1827 ? true : false;
};

const activationCompteFetching = (state: Types.RootState): boolean =>
  state.utilisateurs.activationCompteFetchInfos &&
  state.utilisateurs.activationCompteFetchInfos.fetching;

const activationCompteError = (state: Types.RootState): Error | undefined =>
  state.utilisateurs.activationCompteFetchInfos &&
  state.utilisateurs.activationCompteFetchInfos.error;
export const Selectors = {
  getUtilisateur,
  getUtilisateurError,
  getListFonctionsPostes,
  getUtilisateurConnecte,
  getProfilsBesseListe,
  getUtilisateurFetching,
  fonctionsPostesError,
  getConfirmationCreationUtilisateur,
  getSearchResults,
  getSearchCount,
  getSearchFetching,
  getSearchError,
  getSearchParams,
  getActivationFetching,
  getActivationError,
  getConfirmationDesactivationUtilisateur,
  getConfirmationActivationUtilisateur,
  getProfilUtilisateur,
  getProfilId,
  isChargeClienteleBesse,
  activationCompteFetching,
  activationCompteError,
};

export const reducer = (
  state: UtilisateursState = initialState,
  action: ActionType<typeof ActionCreators>,
): UtilisateursState => {
  switch (action.type) {
    case getType(ajouterUtilisateur.request):
      return {
        ...state,
        utilisateur: action.payload,
        utilisateurFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(ajouterUtilisateur.success):
      return {
        ...state,
        utilisateur: action.payload,
        utilisateurFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(ajouterUtilisateur.failure):
      return {
        ...state,
        utilisateur: undefined,
        utilisateurFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(getFonctionsPostes.request):
      return {
        ...state,
        fonctionsPostes: undefined,
        errorFonctions: undefined,
        utilisateurFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(getFonctionsPostes.success):
      return {
        ...state,
        fonctionsPostes: action.payload,
        errorFonctions: undefined,
      };
    case getType(getFonctionsPostes.failure):
      return {
        ...state,
        utilisateur: undefined,
        errorFonctions: action.payload,
      };
    case getType(getUtilisateurByUsername.request):
      return {
        ...state,
        utilisateur: undefined,
        utilisateurFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(getUtilisateurByUsername.success):
      return {
        ...state,
        utilisateur: action.payload,
        utilisateurFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(getUtilisateurByUsername.failure):
      return {
        ...state,
        utilisateur: undefined,
        utilisateurFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(getUtilisateurConnecteByUsername.request):
      return {
        ...state,
        utilisateurConnecte: undefined,
        utilisateurFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(getUtilisateurConnecteByUsername.success):
      return {
        ...state,
        utilisateurConnecte: action.payload,
        utilisateurFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(getUtilisateurConnecteByUsername.failure):
      return {
        ...state,
        utilisateurConnecte: undefined,
        utilisateurFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(getProfilsBesse.request):
      return {
        ...state,
        profilsBesse: undefined,
        utilisateurFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(getProfilsBesse.success):
      return {
        ...state,
        profilsBesse: action.payload,
        utilisateurFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(getProfilsBesse.failure):
      return {
        ...state,
        profilsBesse: undefined,
        utilisateurFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(confirmationCreationUtilisateur):
      return {
        ...state,
        confirmationCreationUtilisateur: action.payload,
      };

    case getType(emptyErrorMessages):
      return {
        ...state,
        utilisateurFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(getAllUtilisateursBesse.request):
      return {
        ...state,
        searchParams: action.payload,
        searchFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(getAllUtilisateursBesse.success):
      return {
        ...state,
        searchResults: action.payload,
        searchFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(getAllUtilisateursBesse.failure):
      return {
        ...state,
        searchResults: undefined,
        searchFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(getAllUtilisateursAdb.request):
      return {
        ...state,
        searchParams: action.payload,
        searchFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(getAllUtilisateursAdb.success):
      return {
        ...state,
        searchResults: action.payload,
        searchFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(getAllUtilisateursAdb.failure):
      return {
        ...state,
        searchResults: undefined,
        searchFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(desactiverUtilisateur.request):
      return {
        ...state,
        confirmationDesactivationUtilisateur: false,
        confirmationActivationUtilisateur: false,
        activationFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(desactiverUtilisateur.success):
      return {
        ...state,
        confirmationDesactivationUtilisateur: true,
        activationFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(desactiverUtilisateur.failure):
      return {
        ...state,
        confirmationDesactivationUtilisateur: false,
        activationFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(activerUtilisateur.request):
      return {
        ...state,
        confirmationActivationUtilisateur: false,
        confirmationDesactivationUtilisateur: false,
        activationFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(activerUtilisateur.success):
      return {
        ...state,
        confirmationActivationUtilisateur: true,
        activationFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(activerUtilisateur.failure):
      return {
        ...state,
        confirmationActivationUtilisateur: false,
        activationFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(changerUtilisateurStatut):
      return {
        ...state,
        searchResults: changeUtilisateurStatut(
          state.searchResults,
          action.payload.username,
          action.payload.actif,
        ),
      };
    case getType(confirmationDesactivationUtilisateur):
      return {
        ...state,
        confirmationDesactivationUtilisateur: false,
      };

    case getType(confirmationActivationUtilisateur):
      return {
        ...state,
        confirmationActivationUtilisateur: false,
      };

    case getType(modifierUtilisateur.request):
      return {
        ...state,
        utilisateurFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(modifierUtilisateur.success):
      return {
        ...state,
        utilisateurFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(modifierUtilisateur.failure):
      return {
        ...state,
        searchResults: undefined,
        utilisateurFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(getProfil.request):
      return {
        ...state,
        profilFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(getProfil.success):
      return {
        ...state,
        profilUtilisateur: action.payload,
        profilFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(getProfil.failure):
      return {
        ...state,
        profilFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(activerCompteUtilisateur.request):
      return {
        ...state,
        activationCompteFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(activerCompteUtilisateur.success):
      return {
        ...state,
        activationCompteFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(activerCompteUtilisateur.failure):
      return {
        ...state,
        activationCompteFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
