import React from 'react';
import BlocBackground from './BlocBackground';
import imageFI from '../../../ressources/images/FINANCE-INNOVATION-LOGO.png';
import { withStyles } from '@material-ui/core';
import styles from './styles';

interface Props {
  classes?: any;
}

class Blocs extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <>
        {/** Titre */}
        <BlocBackground />
        <div className={classes.container}>
          <div className={classes.logoFI}>
            <img src={imageFI} alt="" />
          </div>
          <div className={classes.texte}>
            <p>
              {' '}
              <span className={classes.vertuloo}>Vertuloo </span> est fier
              d’annoncer avoir obtenu le Label FinTech Finance Innovation,
              leader français de l’accompagnement des Fintechs, Insurtechs et
              startups financières. La startup avait rejoint le pôle de
              compétitivité mondial installé au Palais Brongniart en mars 2021.{' '}
              <br /> <br />
              Créé par l’Etat en 2007, Finance Innovation compte plus de 550
              adhérents et dispose d’une légitimité naturelle pour permettre le
              développement de l’écosystème financier de manière rigoureuse et
              désintéressée. Alliant la force de son label d’Etat au dynamisme
              de ses équipes, le pôle est le garant d’un accompagnement de
              qualité en mettant en relation les startups, laboratoires de
              recherche, petites, moyennes et grandes entreprises, centres
              d’excellence académiques et investisseurs. <br /> <br />
              Les startups innovantes et stratégiques pour le secteur financier
              reçoivent le Label FinTech de Finance Innovation qui leur permet
              d’améliorer leur visibilité et leur crédibilité en France comme à
              l’international auprès de ses partenaires. <br /> <br />
              Le Label c’est : <br />. Plus de 200 experts mobilisés pour
              sélectionner les startups éligibles au label ; <br />. Plus de 660
              projets labellisés depuis le lancement de Innovation en 2007 ;{' '}
              <br />. Une startup sur deux labellisée a trouvé des clients grâce
              au label Finance Innovation.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Blocs);
