import { Grid, Link } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import Layout from '../../../components/Layout';
import Spinner from '../../../components/spinner/Spinner';
import { ActionCreators, Selectors } from '../../../redux/Utilisateurs';
import { Token } from '../../../services/Api/swagger';
import Libelle from '../../../_i18n/fr.json';

interface Props {
  match: any;
  activerCompteFetching: boolean;
  activerCompteError?: any;
  activerCompteUtilisateur(token: Token): void;
}

class ActivationComptePage extends React.Component<
  RouteComponentProps<{}> & Props
> {
  constructor(props: RouteComponentProps<{}> & Props) {
    super(props);
    this.state = {
      token: this.props.match.params.token,
      actif: false,
      isLoading: true,
      message: '',
    };
  }

  componentDidMount() {
    const token = this.props.match.params.token;
    const payload = { token };
    this.props.activerCompteUtilisateur(payload);
  }

  render() {
    const { activerCompteError, activerCompteFetching } = this.props;
    return (
      <React.Fragment>
        <Layout title={Libelle.pageActivation.titre}>
          <Grid>
            {activerCompteFetching && <Spinner />}
            {activerCompteError && <p>{activerCompteError}</p>}
            {!activerCompteError && !activerCompteFetching && (
              <>
                {' '}
                <p>Félicitation, votre compte a bien été activé.</p>
                <Link href="/dossier-location/dashboard">
                  Cliquez ici pour vous connecter
                </Link>
              </>
            )}
          </Grid>
        </Layout>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state, props) => ({
  activerCompteFetching: Selectors.activationCompteFetching(state),
  activerCompteError: Selectors.activationCompteError(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  activerCompteUtilisateur: token =>
    dispatch(ActionCreators.activerCompteUtilisateur.request(token)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivationComptePage);
