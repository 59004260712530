import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    root: {
      '& span': {
        color: theme.palette.common.black,
        fontSize: '0.8rem',
        fontWeight: 'bold',
      },
    },
    checkbox: {
      marginLeft: 4,
    },
    icon: {
      "width": 16,
      "height": 16,
      "background": theme.palette.common.white,
      '& svg': {
        marginTop: '-4px',
        marginLeft: '-4px',
        width: 24,
        height: 24,
      },
    },
  } as any);

export default styles;
