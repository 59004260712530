// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * API Agrément Bessé
 * Définition de l\'interface de programmation applicative de l\'application Agrément
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: benoit.grimaud@besse.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActionDossierLocation
 */
export interface ActionDossierLocation {
    /**
     * 
     * @type {string}
     * @memberof ActionDossierLocation
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionDossierLocation
     */
    utilisateur?: string;
    /**
     * 
     * @type {Date}
     * @memberof ActionDossierLocation
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof ActionDossierLocation
     */
    typeAction?: ActionDossierLocationTypeActionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ActionDossierLocationTypeActionEnum {
    FORCERSTATUT = 'FORCER_STATUT'
}

/**
 * 
 * @export
 * @interface AdministrateurBien
 */
export interface AdministrateurBien {
    /**
     * 
     * @type {number}
     * @memberof AdministrateurBien
     */
    identifiant?: number;
    /**
     * 
     * @type {string}
     * @memberof AdministrateurBien
     */
    nom?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrateurBien
     */
    adresse1?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrateurBien
     */
    adresse2?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrateurBien
     */
    adresse3?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrateurBien
     */
    codePostal?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrateurBien
     */
    ville?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdministrateurBien
     */
    actif?: boolean;
    /**
     * 
     * @type {Commercial}
     * @memberof AdministrateurBien
     */
    commercial?: Commercial;
}
/**
 * 
 * @export
 * @interface AdministrateurBienDetail
 */
export interface AdministrateurBienDetail {
    /**
     * 
     * @type {number}
     * @memberof AdministrateurBienDetail
     */
    identifiant?: number;
    /**
     * 
     * @type {string}
     * @memberof AdministrateurBienDetail
     */
    nom?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrateurBienDetail
     */
    adresse1?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrateurBienDetail
     */
    adresse2?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrateurBienDetail
     */
    adresse3?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrateurBienDetail
     */
    codePostal?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrateurBienDetail
     */
    ville?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdministrateurBienDetail
     */
    actif?: boolean;
    /**
     * 
     * @type {Commercial}
     * @memberof AdministrateurBienDetail
     */
    commercial?: Commercial;
    /**
     * 
     * @type {TitreAdb}
     * @memberof AdministrateurBienDetail
     */
    titreAdb?: TitreAdb;
    /**
     * 
     * @type {Array<ApplicationImmobilierDetail>}
     * @memberof AdministrateurBienDetail
     */
    applications?: Array<ApplicationImmobilierDetail>;
    /**
     * 
     * @type {Array<ContratLoyerImpaye>}
     * @memberof AdministrateurBienDetail
     */
    contratsLoyerImpaye?: Array<ContratLoyerImpaye>;
    /**
     * 
     * @type {Array<Site>}
     * @memberof AdministrateurBienDetail
     */
    sites?: Array<Site>;
}
/**
 * 
 * @export
 * @interface AdministrateurBienDetailAllOf
 */
export interface AdministrateurBienDetailAllOf {
    /**
     * 
     * @type {TitreAdb}
     * @memberof AdministrateurBienDetailAllOf
     */
    titreAdb?: TitreAdb;
    /**
     * 
     * @type {Array<ApplicationImmobilierDetail>}
     * @memberof AdministrateurBienDetailAllOf
     */
    applications?: Array<ApplicationImmobilierDetail>;
    /**
     * 
     * @type {Array<ContratLoyerImpaye>}
     * @memberof AdministrateurBienDetailAllOf
     */
    contratsLoyerImpaye?: Array<ContratLoyerImpaye>;
    /**
     * 
     * @type {Array<Site>}
     * @memberof AdministrateurBienDetailAllOf
     */
    sites?: Array<Site>;
}
/**
 * 
 * @export
 * @interface AdministrateurBienList
 */
export interface AdministrateurBienList {
    /**
     * 
     * @type {Array<AdministrateurBien>}
     * @memberof AdministrateurBienList
     */
    resultat?: Array<AdministrateurBien>;
    /**
     * 
     * @type {number}
     * @memberof AdministrateurBienList
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ApplicationImmobilier
 */
export interface ApplicationImmobilier {
    /**
     * 
     * @type {number}
     * @memberof ApplicationImmobilier
     */
    identifiant?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationImmobilier
     */
    nom?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationImmobilier
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationImmobilier
     */
    accessible?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationImmobilierDetail
 */
export interface ApplicationImmobilierDetail {
    /**
     * 
     * @type {number}
     * @memberof ApplicationImmobilierDetail
     */
    identifiant?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationImmobilierDetail
     */
    nom?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationImmobilierDetail
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationImmobilierDetail
     */
    accessible?: boolean;
    /**
     * 
     * @type {ParametrageApplicationAdb}
     * @memberof ApplicationImmobilierDetail
     */
    parametrage?: ParametrageApplicationAdb;
    /**
     * 
     * @type {Array<Profil>}
     * @memberof ApplicationImmobilierDetail
     */
    profils?: Array<Profil>;
}
/**
 * 
 * @export
 * @interface ApplicationImmobilierDetailAllOf
 */
export interface ApplicationImmobilierDetailAllOf {
    /**
     * 
     * @type {ParametrageApplicationAdb}
     * @memberof ApplicationImmobilierDetailAllOf
     */
    parametrage?: ParametrageApplicationAdb;
    /**
     * 
     * @type {Array<Profil>}
     * @memberof ApplicationImmobilierDetailAllOf
     */
    profils?: Array<Profil>;
}
/**
 * 
 * @export
 * @interface Bien
 */
export interface Bien {
    /**
     * 
     * @type {number}
     * @memberof Bien
     */
    identifiant?: number;
    /**
     * 
     * @type {string}
     * @memberof Bien
     */
    numeroBatiment?: string;
    /**
     * 
     * @type {CodeTypeVoie}
     * @memberof Bien
     */
    typeVoie: CodeTypeVoie;
    /**
     * 
     * @type {string}
     * @memberof Bien
     */
    numero?: string;
    /**
     * 
     * @type {string}
     * @memberof Bien
     */
    adresse: string;
    /**
     * 
     * @type {string}
     * @memberof Bien
     */
    codePostal: string;
    /**
     * 
     * @type {string}
     * @memberof Bien
     */
    ville: string;
    /**
     * 
     * @type {string}
     * @memberof Bien
     */
    numeroLot: string;
    /**
     * 
     * @type {CodeTypeBien}
     * @memberof Bien
     */
    typeBien: CodeTypeBien;
    /**
     * 
     * @type {boolean}
     * @memberof Bien
     */
    meuble: boolean;
    /**
     * 
     * @type {number}
     * @memberof Bien
     */
    superficie: number;
    /**
     * 
     * @type {CodeNombrePieces}
     * @memberof Bien
     */
    nombrePieces: CodeNombrePieces;
    /**
     * 
     * @type {number}
     * @memberof Bien
     */
    loyerMensuel: number;
    /**
     * 
     * @type {number}
     * @memberof Bien
     */
    chargesMensuelles: number;
    /**
     * 
     * @type {boolean}
     * @memberof Bien
     */
    garantieLi: boolean;
    /**
     * 
     * @type {string}
     * @memberof Bien
     */
    typeResidence?: BienTypeResidenceEnum;
    /**
     * 
     * @type {string}
     * @memberof Bien
     */
    referenceMandat: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BienTypeResidenceEnum {
    PRINCIPALE = 'PRINCIPALE',
    SECONDAIRE = 'SECONDAIRE'
}

/**
 * 
 * @export
 * @interface BienComplement
 */
export interface BienComplement {
    /**
     * 
     * @type {Date}
     * @memberof BienComplement
     */
    dateCreation?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BienComplement
     */
    dateModification?: Date;
}
/**
 * 
 * @export
 * @interface BienDetail
 */
export interface BienDetail {
    /**
     * 
     * @type {number}
     * @memberof BienDetail
     */
    identifiant?: number;
    /**
     * 
     * @type {string}
     * @memberof BienDetail
     */
    numeroBatiment?: string;
    /**
     * 
     * @type {CodeTypeVoie}
     * @memberof BienDetail
     */
    typeVoie: CodeTypeVoie;
    /**
     * 
     * @type {string}
     * @memberof BienDetail
     */
    numero?: string;
    /**
     * 
     * @type {string}
     * @memberof BienDetail
     */
    adresse: string;
    /**
     * 
     * @type {string}
     * @memberof BienDetail
     */
    codePostal: string;
    /**
     * 
     * @type {string}
     * @memberof BienDetail
     */
    ville: string;
    /**
     * 
     * @type {string}
     * @memberof BienDetail
     */
    numeroLot: string;
    /**
     * 
     * @type {CodeTypeBien}
     * @memberof BienDetail
     */
    typeBien: CodeTypeBien;
    /**
     * 
     * @type {boolean}
     * @memberof BienDetail
     */
    meuble: boolean;
    /**
     * 
     * @type {number}
     * @memberof BienDetail
     */
    superficie: number;
    /**
     * 
     * @type {CodeNombrePieces}
     * @memberof BienDetail
     */
    nombrePieces: CodeNombrePieces;
    /**
     * 
     * @type {number}
     * @memberof BienDetail
     */
    loyerMensuel: number;
    /**
     * 
     * @type {number}
     * @memberof BienDetail
     */
    chargesMensuelles: number;
    /**
     * 
     * @type {boolean}
     * @memberof BienDetail
     */
    garantieLi: boolean;
    /**
     * 
     * @type {string}
     * @memberof BienDetail
     */
    typeResidence?: BienDetailTypeResidenceEnum;
    /**
     * 
     * @type {string}
     * @memberof BienDetail
     */
    referenceMandat: string;
    /**
     * 
     * @type {Date}
     * @memberof BienDetail
     */
    dateCreation?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BienDetail
     */
    dateModification?: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum BienDetailTypeResidenceEnum {
    PRINCIPALE = 'PRINCIPALE',
    SECONDAIRE = 'SECONDAIRE'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CodeNombrePieces {
    P1 = 'P1',
    P2 = 'P2',
    P3 = 'P3',
    P4 = 'P4',
    P5 = 'P5',
    P6ETPLUS = 'P6_ET_PLUS'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CodeTypeBien {
    APPARTEMENT = 'APPARTEMENT',
    MAISON = 'MAISON',
    COMMERCE = 'COMMERCE'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CodeTypeVoie {
    RUE = 'RUE',
    AVENUE = 'AVENUE',
    BOULEVARD = 'BOULEVARD',
    IMPASSE = 'IMPASSE',
    PLACE = 'PLACE',
    SQUARE = 'SQUARE',
    QUAI = 'QUAI',
    ALLEE = 'ALLEE',
    CHEMIN = 'CHEMIN',
    COURS = 'COURS',
    PASSAGE = 'PASSAGE',
    ROUTE = 'ROUTE',
    VILLA = 'VILLA',
    VOIE = 'VOIE',
    BUTTE = 'BUTTE',
    CARREFOUR = 'CARREFOUR',
    CHAUSSEE = 'CHAUSSEE',
    CITE = 'CITE',
    FAUBOURG = 'FAUBOURG',
    LIEUDIT = 'LIEUDIT',
    MAIL = 'MAIL',
    RONDPOINT = 'RONDPOINT',
    SENTIER = 'SENTIER'
}

/**
 * 
 * @export
 * @interface Commercial
 */
export interface Commercial {
    /**
     * 
     * @type {number}
     * @memberof Commercial
     */
    identifiant?: number;
    /**
     * 
     * @type {string}
     * @memberof Commercial
     */
    nom?: string;
    /**
     * 
     * @type {string}
     * @memberof Commercial
     */
    prenom?: string;
}
/**
 * 
 * @export
 * @interface Commune
 */
export interface Commune {
    /**
     * 
     * @type {string}
     * @memberof Commune
     */
    codePostal?: string;
    /**
     * 
     * @type {string}
     * @memberof Commune
     */
    nom?: string;
}
/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    nom: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    prenom: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    societe: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    response: string;
}
/**
 * 
 * @export
 * @interface ContratLoyerImpaye
 */
export interface ContratLoyerImpaye {
    /**
     * 
     * @type {string}
     * @memberof ContratLoyerImpaye
     */
    numero?: string;
    /**
     * 
     * @type {number}
     * @memberof ContratLoyerImpaye
     */
    tauxEffort?: number;
}
/**
 * 
 * @export
 * @interface Departement
 */
export interface Departement {
    /**
     * 
     * @type {string}
     * @memberof Departement
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Departement
     */
    nom?: string;
}
/**
 * 
 * @export
 * @interface DepotPartenaire
 */
export interface DepotPartenaire {
    /**
     * 
     * @type {string}
     * @memberof DepotPartenaire
     */
    nomCandidat?: string;
    /**
     * 
     * @type {string}
     * @memberof DepotPartenaire
     */
    prenomCandidat?: string;
    /**
     * 
     * @type {string}
     * @memberof DepotPartenaire
     */
    dateNaissanceCandidat?: string;
    /**
     * 
     * @type {Array<DetailPiece>}
     * @memberof DepotPartenaire
     */
    pieces?: Array<DetailPiece>;
}
/**
 * Fichier binaire encode Base 64
 * @export
 * @interface DetailPiece
 */
export interface DetailPiece {
    /**
     * Type de pièce justificative à ajouter
     * @type {string}
     * @memberof DetailPiece
     */
    typePiece: DetailPieceTypePieceEnum;
    /**
     * 
     * @type {Array<Fichier>}
     * @memberof DetailPiece
     */
    fichiers: Array<Fichier>;
}

/**
    * @export
    * @enum {string}
    */
export enum DetailPieceTypePieceEnum {
    IDCARD = 'ID_CARD',
    PASSPORT = 'PASSPORT',
    RESIDENCEPERMIT = 'RESIDENCE_PERMIT',
    RIB = 'RIB',
    ENERGYINVOICE = 'ENERGY_INVOICE',
    ENERGYSCHEDULE = 'ENERGY_SCHEDULE',
    PHONEINVOICE = 'PHONE_INVOICE',
    PROVIDERINVOICE = 'PROVIDER_INVOICE',
    PROVIDERINVOICEORSCHEDULE = 'PROVIDER_INVOICE_OR_SCHEDULE',
    LOCALRESIDENCETAXNOTICE = 'LOCAL_RESIDENCE_TAX_NOTICE',
    TAXNOTICE = 'TAX_NOTICE',
    TAXJUSTIFICATION = 'TAX_JUSTIFICATION',
    TAXNOTICEORJUSTIFICATION = 'TAX_NOTICE_OR_JUSTIFICATION',
    PROPERTYTAXNOTICE = 'PROPERTY_TAX_NOTICE',
    PAYSLIP = 'PAYSLIP',
    PHOTOGRAPH = 'PHOTOGRAPH',
    DRIVERLICENSE = 'DRIVER_LICENSE',
    VEHICLEREGISTRATIONCERTIFICATE = 'VEHICLE_REGISTRATION_CERTIFICATE',
    CARTEETUDIANT = 'CARTE_ETUDIANT',
    UNDEFINED = 'UNDEFINED'
}

/**
 * 
 * @export
 * @interface DossierLocation
 */
export interface DossierLocation {
    /**
     * Champ en lecture seule
     * @type {string}
     * @memberof DossierLocation
     */
    statut?: string;
    /**
     * Champ en lecture seule
     * @type {Date}
     * @memberof DossierLocation
     */
    dateCreation?: Date;
    /**
     * Champ en lecture seule
     * @type {Date}
     * @memberof DossierLocation
     */
    dateDemandeValidation?: Date;
    /**
     * Généré à la création
     * @type {string}
     * @memberof DossierLocation
     */
    reference?: string;
    /**
     * Identifiant de l\'ADB
     * @type {number}
     * @memberof DossierLocation
     */
    referenceAdb: number;
    /**
     * numero de site
     * @type {number}
     * @memberof DossierLocation
     */
    numeroSite: number;
    /**
     * username de l\'utilisateur - création
     * @type {string}
     * @memberof DossierLocation
     */
    createur: string;
    /**
     * Location en attente, accepte ou refuse
     * @type {number}
     * @memberof DossierLocation
     */
    locationAcceptee?: number;
    /**
     * Composition de la location
     * @type {string}
     * @memberof DossierLocation
     */
    compositionLocation: DossierLocationCompositionLocationEnum;
    /**
     * 
     * @type {Bien}
     * @memberof DossierLocation
     */
    bien?: Bien;
    /**
     * 
     * @type {Array<DossierParticulierDetail>}
     * @memberof DossierLocation
     */
    dossiersParticuliers?: Array<DossierParticulierDetail>;
    /**
     * Loyer max calculé
     * @type {number}
     * @memberof DossierLocation
     */
    loyerMaxConseille?: number;
    /**
     * 
     * @type {Array<ActionDossierLocation>}
     * @memberof DossierLocation
     */
    actions?: Array<ActionDossierLocation>;
    /**
     * 
     * @type {PartenaireReferenceDossierLocation}
     * @memberof DossierLocation
     */
    partenaire?: PartenaireReferenceDossierLocation;
}

/**
    * @export
    * @enum {string}
    */
export enum DossierLocationCompositionLocationEnum {
    SEUL = 'SEUL',
    COUPLE = 'COUPLE',
    COLOCATION = 'COLOCATION'
}

/**
 * 
 * @export
 * @interface DossierLocationItem
 */
export interface DossierLocationItem {
    /**
     * Champ en lecture seule
     * @type {string}
     * @memberof DossierLocationItem
     */
    statut?: string;
    /**
     * Champ en lecture seule
     * @type {Date}
     * @memberof DossierLocationItem
     */
    dateCreation?: Date;
    /**
     * Champ en lecture seule
     * @type {Date}
     * @memberof DossierLocationItem
     */
    dateDemandeValidation?: Date;
    /**
     * Généré à la création
     * @type {string}
     * @memberof DossierLocationItem
     */
    reference?: string;
    /**
     * Identifiant de l\'ADB
     * @type {number}
     * @memberof DossierLocationItem
     */
    referenceAdb?: number;
    /**
     * 
     * @type {string}
     * @memberof DossierLocationItem
     */
    nomLocataire?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierLocationItem
     */
    nomDeNaissanceLocataire?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierLocationItem
     */
    prenomLocataire?: string;
    /**
     * Location en attente, accepte ou refuse
     * @type {number}
     * @memberof DossierLocationItem
     */
    locationAcceptee?: number;
    /**
     * Composition de la location
     * @type {string}
     * @memberof DossierLocationItem
     */
    compositionLocation?: DossierLocationItemCompositionLocationEnum;
    /**
     * 
     * @type {Bien}
     * @memberof DossierLocationItem
     */
    bien?: Bien;
    /**
     * 
     * @type {Site}
     * @memberof DossierLocationItem
     */
    site?: Site;
    /**
     * 
     * @type {number}
     * @memberof DossierLocationItem
     */
    loyerMaxConseille?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DossierLocationItem
     */
    enCoursSuppression?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum DossierLocationItemCompositionLocationEnum {
    SEUL = 'SEUL',
    COUPLE = 'COUPLE',
    COLOCATION = 'COLOCATION'
}

/**
 * 
 * @export
 * @interface DossierParticulier
 */
export interface DossierParticulier {
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    typeCompte: DossierParticulierTypeCompteEnum;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    refDossierLocation?: string;
    /**
     * Si typeCompte=LOCATAIRE
     * @type {boolean}
     * @memberof DossierParticulier
     */
    principal?: boolean;
    /**
     * Obligatoire si typeCompte=GARANT
     * @type {string}
     * @memberof DossierParticulier
     */
    refDossierLocataire?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    statut?: DossierParticulierStatutEnum;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    qualite?: DossierParticulierQualiteEnum;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    nom?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    nomDeNaissance?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    prenom?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    dateNaissance?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    numeroBatiment?: string;
    /**
     * 
     * @type {CodeTypeVoie}
     * @memberof DossierParticulier
     */
    typeVoie?: CodeTypeVoie;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    numero?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    adresse?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    codePostal?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    ville?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    telephoneFixe?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    telephonePortable?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    dateEntree?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    statutOccupation?: DossierParticulierStatutOccupationEnum;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    situationMaritale?: DossierParticulierSituationMaritaleEnum;
    /**
     * 
     * @type {Array<Enfant>}
     * @memberof DossierParticulier
     */
    enfants?: Array<Enfant>;
    /**
     * 
     * @type {boolean}
     * @memberof DossierParticulier
     */
    pasAvisImposition?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    codeStatutProfessionnel?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    dateDebutSituationProfessionnelle?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    dateFinSituationProfessionnelle?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulier
     */
    nombreMoisTravailles?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulier
     */
    salaireNetImposableM1?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulier
     */
    salaireNetImposableM2?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulier
     */
    salaireNetImposableM3?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulier
     */
    cumulSalaireNetImposableM1?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulier
     */
    cumulSalaireNetImposableM2?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulier
     */
    cumulSalaireNetImposableM3?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulier
     */
    revenuIndividuelDeclare?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulier
     */
    revenuFiscalReference?: number;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    dateDernierBulletin?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    codeApe?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulier
     */
    siret?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DossierParticulierTypeCompteEnum {
    LOCATAIRE = 'LOCATAIRE',
    GARANT = 'GARANT'
}
/**
    * @export
    * @enum {string}
    */
export enum DossierParticulierStatutEnum {
    INCOMPLET = 'INCOMPLET',
    ENTRANSFERT = 'EN_TRANSFERT',
    COMPLET = 'COMPLET',
    BLOQUE = 'BLOQUE',
    NONVALIDE = 'NON_VALIDE',
    VALIDE = 'VALIDE'
}
/**
    * @export
    * @enum {string}
    */
export enum DossierParticulierQualiteEnum {
    MME = 'MME',
    M = 'M'
}
/**
    * @export
    * @enum {string}
    */
export enum DossierParticulierStatutOccupationEnum {
    LOCATAIRE = 'LOCATAIRE',
    OCCUPANTATITREGRATUIT = 'OCCUPANT_A_TITRE_GRATUIT',
    PROPRIETAIRE = 'PROPRIETAIRE'
}
/**
    * @export
    * @enum {string}
    */
export enum DossierParticulierSituationMaritaleEnum {
    CELIBATAIRE = 'CELIBATAIRE',
    ENCOUPLE = 'EN_COUPLE',
    DIVORCE = 'DIVORCE',
    VEUF = 'VEUF'
}

/**
 * 
 * @export
 * @interface DossierParticulierAllOf
 */
export interface DossierParticulierAllOf {
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierAllOf
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierAllOf
     */
    typeCompte: DossierParticulierAllOfTypeCompteEnum;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierAllOf
     */
    refDossierLocation?: string;
    /**
     * Si typeCompte=LOCATAIRE
     * @type {boolean}
     * @memberof DossierParticulierAllOf
     */
    principal?: boolean;
    /**
     * Obligatoire si typeCompte=GARANT
     * @type {string}
     * @memberof DossierParticulierAllOf
     */
    refDossierLocataire?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierAllOf
     */
    statut?: DossierParticulierAllOfStatutEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DossierParticulierAllOfTypeCompteEnum {
    LOCATAIRE = 'LOCATAIRE',
    GARANT = 'GARANT'
}
/**
    * @export
    * @enum {string}
    */
export enum DossierParticulierAllOfStatutEnum {
    INCOMPLET = 'INCOMPLET',
    ENTRANSFERT = 'EN_TRANSFERT',
    COMPLET = 'COMPLET',
    BLOQUE = 'BLOQUE',
    NONVALIDE = 'NON_VALIDE',
    VALIDE = 'VALIDE'
}

/**
 * 
 * @export
 * @interface DossierParticulierDetail
 */
export interface DossierParticulierDetail {
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    typeCompte: DossierParticulierDetailTypeCompteEnum;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    refDossierLocation?: string;
    /**
     * Si typeCompte=LOCATAIRE
     * @type {boolean}
     * @memberof DossierParticulierDetail
     */
    principal?: boolean;
    /**
     * Obligatoire si typeCompte=GARANT
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    refDossierLocataire?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    statut?: DossierParticulierDetailStatutEnum;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    qualite?: DossierParticulierDetailQualiteEnum;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    nom?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    nomDeNaissance?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    prenom?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    dateNaissance?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    numeroBatiment?: string;
    /**
     * 
     * @type {CodeTypeVoie}
     * @memberof DossierParticulierDetail
     */
    typeVoie?: CodeTypeVoie;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    numero?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    adresse?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    codePostal?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    ville?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    telephoneFixe?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    telephonePortable?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    dateEntree?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    statutOccupation?: DossierParticulierDetailStatutOccupationEnum;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    situationMaritale?: DossierParticulierDetailSituationMaritaleEnum;
    /**
     * 
     * @type {Array<Enfant>}
     * @memberof DossierParticulierDetail
     */
    enfants?: Array<Enfant>;
    /**
     * 
     * @type {boolean}
     * @memberof DossierParticulierDetail
     */
    pasAvisImposition?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    codeStatutProfessionnel?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    dateDebutSituationProfessionnelle?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    dateFinSituationProfessionnelle?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulierDetail
     */
    nombreMoisTravailles?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulierDetail
     */
    salaireNetImposableM1?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulierDetail
     */
    salaireNetImposableM2?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulierDetail
     */
    salaireNetImposableM3?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulierDetail
     */
    cumulSalaireNetImposableM1?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulierDetail
     */
    cumulSalaireNetImposableM2?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulierDetail
     */
    cumulSalaireNetImposableM3?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulierDetail
     */
    revenuIndividuelDeclare?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierParticulierDetail
     */
    revenuFiscalReference?: number;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    dateDernierBulletin?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    codeApe?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    siret?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetail
     */
    statutDossierLocation?: DossierParticulierDetailStatutDossierLocationEnum;
    /**
     * 
     * @type {DossierPieces}
     * @memberof DossierParticulierDetail
     */
    dossierPieces?: DossierPieces;
}

/**
    * @export
    * @enum {string}
    */
export enum DossierParticulierDetailTypeCompteEnum {
    LOCATAIRE = 'LOCATAIRE',
    GARANT = 'GARANT'
}
/**
    * @export
    * @enum {string}
    */
export enum DossierParticulierDetailStatutEnum {
    INCOMPLET = 'INCOMPLET',
    ENTRANSFERT = 'EN_TRANSFERT',
    COMPLET = 'COMPLET',
    BLOQUE = 'BLOQUE',
    NONVALIDE = 'NON_VALIDE',
    VALIDE = 'VALIDE'
}
/**
    * @export
    * @enum {string}
    */
export enum DossierParticulierDetailQualiteEnum {
    MME = 'MME',
    M = 'M'
}
/**
    * @export
    * @enum {string}
    */
export enum DossierParticulierDetailStatutOccupationEnum {
    LOCATAIRE = 'LOCATAIRE',
    OCCUPANTATITREGRATUIT = 'OCCUPANT_A_TITRE_GRATUIT',
    PROPRIETAIRE = 'PROPRIETAIRE'
}
/**
    * @export
    * @enum {string}
    */
export enum DossierParticulierDetailSituationMaritaleEnum {
    CELIBATAIRE = 'CELIBATAIRE',
    ENCOUPLE = 'EN_COUPLE',
    DIVORCE = 'DIVORCE',
    VEUF = 'VEUF'
}
/**
    * @export
    * @enum {string}
    */
export enum DossierParticulierDetailStatutDossierLocationEnum {
    ENCOURSDECREATION = 'EN_COURS_DE_CREATION',
    ENTRANSFERT = 'EN_TRANSFERT',
    INCOMPLET = 'INCOMPLET',
    NONVALIDE = 'NON_VALIDE',
    VALIDE = 'VALIDE',
    VALIDENONAGREE = 'VALIDE_NON_AGREE',
    VALIDEAGREEDIFFERE = 'VALIDE_AGREE_DIFFERE',
    VALIDEAGREE = 'VALIDE_AGREE'
}

/**
 * 
 * @export
 * @interface DossierParticulierDetailAllOf
 */
export interface DossierParticulierDetailAllOf {
    /**
     * 
     * @type {string}
     * @memberof DossierParticulierDetailAllOf
     */
    statutDossierLocation?: DossierParticulierDetailAllOfStatutDossierLocationEnum;
    /**
     * 
     * @type {DossierPieces}
     * @memberof DossierParticulierDetailAllOf
     */
    dossierPieces?: DossierPieces;
}

/**
    * @export
    * @enum {string}
    */
export enum DossierParticulierDetailAllOfStatutDossierLocationEnum {
    ENCOURSDECREATION = 'EN_COURS_DE_CREATION',
    ENTRANSFERT = 'EN_TRANSFERT',
    INCOMPLET = 'INCOMPLET',
    NONVALIDE = 'NON_VALIDE',
    VALIDE = 'VALIDE',
    VALIDENONAGREE = 'VALIDE_NON_AGREE',
    VALIDEAGREEDIFFERE = 'VALIDE_AGREE_DIFFERE',
    VALIDEAGREE = 'VALIDE_AGREE'
}

/**
 * 
 * @export
 * @interface DossierPieces
 */
export interface DossierPieces {
    /**
     * 
     * @type {string}
     * @memberof DossierPieces
     */
    dossierUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierPieces
     */
    participantUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierPieces
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierPieces
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierPieces
     */
    parcoursNetheos?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierPieces
     */
    nombreReouverture?: number;
    /**
     * 
     * @type {string}
     * @memberof DossierPieces
     */
    statut?: DossierPiecesStatutEnum;
    /**
     * 
     * @type {string}
     * @memberof DossierPieces
     */
    statutParticipant?: DossierPiecesStatutParticipantEnum;
    /**
     * 
     * @type {Array<PieceJustificative>}
     * @memberof DossierPieces
     */
    piecesJustificatives?: Array<PieceJustificative>;
}

/**
    * @export
    * @enum {string}
    */
export enum DossierPiecesStatutEnum {
    INITIALIZED = 'INITIALIZED',
    PENDING = 'PENDING',
    WAITING = 'WAITING',
    SUSPENDED = 'SUSPENDED',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED'
}
/**
    * @export
    * @enum {string}
    */
export enum DossierPiecesStatutParticipantEnum {
    PENDING = 'PENDING',
    WAITING = 'WAITING',
    DONE = 'DONE'
}

/**
 * 
 * @export
 * @interface DossierPiecesAllOf
 */
export interface DossierPiecesAllOf {
    /**
     * 
     * @type {Array<PieceJustificative>}
     * @memberof DossierPiecesAllOf
     */
    piecesJustificatives?: Array<PieceJustificative>;
}
/**
 * 
 * @export
 * @interface DossierPiecesId
 */
export interface DossierPiecesId {
    /**
     * 
     * @type {string}
     * @memberof DossierPiecesId
     */
    dossierUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierPiecesId
     */
    participantUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierPiecesId
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierPiecesId
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierPiecesId
     */
    parcoursNetheos?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierPiecesId
     */
    nombreReouverture?: number;
    /**
     * 
     * @type {string}
     * @memberof DossierPiecesId
     */
    statut?: DossierPiecesIdStatutEnum;
    /**
     * 
     * @type {string}
     * @memberof DossierPiecesId
     */
    statutParticipant?: DossierPiecesIdStatutParticipantEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DossierPiecesIdStatutEnum {
    INITIALIZED = 'INITIALIZED',
    PENDING = 'PENDING',
    WAITING = 'WAITING',
    SUSPENDED = 'SUSPENDED',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED'
}
/**
    * @export
    * @enum {string}
    */
export enum DossierPiecesIdStatutParticipantEnum {
    PENDING = 'PENDING',
    WAITING = 'WAITING',
    DONE = 'DONE'
}

/**
 * 
 * @export
 * @interface Enfant
 */
export interface Enfant {
    /**
     * 
     * @type {string}
     * @memberof Enfant
     */
    dateNaissance?: string;
}
/**
 * 
 * @export
 * @interface ErreurPieceJustificative
 */
export interface ErreurPieceJustificative {
    /**
     * 
     * @type {number}
     * @memberof ErreurPieceJustificative
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof ErreurPieceJustificative
     */
    libelle?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ErreurPieceJustificative
     */
    critique?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ErreurPieceJustificative
     */
    type?: ErreurPieceJustificativeTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ErreurPieceJustificativeTypeEnum {
    WARNING = 'WARNING',
    ERREUR = 'ERREUR'
}

/**
 * 
 * @export
 * @interface ErreursPieceJustificativePartenaire
 */
export interface ErreursPieceJustificativePartenaire {
    /**
     * 
     * @type {string}
     * @memberof ErreursPieceJustificativePartenaire
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ErreursPieceJustificativePartenaire
     */
    libelle?: string;
}
/**
 * 
 * @export
 * @interface Error
 */
export interface Error {
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    element?: string;
    /**
     * 
     * @type {string}
     * @memberof Error
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface FamilleJustificatif
 */
export interface FamilleJustificatif {
    /**
     * 
     * @type {string}
     * @memberof FamilleJustificatif
     */
    libelle?: string;
    /**
     * 
     * @type {Array<TypeJustificatif>}
     * @memberof FamilleJustificatif
     */
    typesJustificatifs?: Array<TypeJustificatif>;
}
/**
 * Fichier binaire encode Base 64
 * @export
 * @interface Fichier
 */
export interface Fichier {
    /**
     * Nom du fichier
     * @type {string}
     * @memberof Fichier
     */
    nom: string;
    /**
     * date de creation du fichier
     * @type {string}
     * @memberof Fichier
     */
    dateCreation?: string;
    /**
     * Type MIME du fichier
     * @type {string}
     * @memberof Fichier
     */
    typeMime?: string;
    /**
     * Contenu du fichier encode en base64
     * @type {string}
     * @memberof Fichier
     */
    content: string;
}
/**
 * 
 * @export
 * @interface FonctionPoste
 */
export interface FonctionPoste {
    /**
     * 
     * @type {number}
     * @memberof FonctionPoste
     */
    identifiant?: number;
    /**
     * 
     * @type {string}
     * @memberof FonctionPoste
     */
    libelle?: string;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    statut: InlineObjectStatutEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    message: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineObjectStatutEnum {
    VALIDE = 'VALIDE',
    NONVALIDE = 'NON_VALIDE',
    VALIDENONAGREE = 'VALIDE_NON_AGREE',
    VALIDEAGREEDIFFERE = 'VALIDE_AGREE_DIFFERE',
    VALIDEAGREE = 'VALIDE_AGREE'
}

/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    username?: string;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    password: string;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject3
     */
    codeDeblocage?: string;
}
/**
 * 
 * @export
 * @interface NombrePieces
 */
export interface NombrePieces {
    /**
     * 
     * @type {CodeNombrePieces}
     * @memberof NombrePieces
     */
    code?: CodeNombrePieces;
    /**
     * 
     * @type {string}
     * @memberof NombrePieces
     */
    libelle?: string;
}
/**
 * 
 * @export
 * @interface NotificationTrustAndSign
 */
export interface NotificationTrustAndSign {
    /**
     * 
     * @type {number}
     * @memberof NotificationTrustAndSign
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof NotificationTrustAndSign
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof NotificationTrustAndSign
     */
    event?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationTrustAndSign
     */
    clientFileUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationTrustAndSign
     */
    externalId?: string;
}
/**
 * Offre du Partenaire de VERTULOO
 * @export
 * @interface OffrePartenaire
 */
export interface OffrePartenaire {
    /**
     * nom de l\'offre
     * @type {string}
     * @memberof OffrePartenaire
     */
    nom?: string;
    /**
     * code de l\'offre
     * @type {string}
     * @memberof OffrePartenaire
     */
    code?: string;
    /**
     * s\'il faut contrôler les pièces du dossier
     * @type {boolean}
     * @memberof OffrePartenaire
     */
    controleDossier?: boolean;
    /**
     * s\'il faut utiliser les cumuls dans l\'algo
     * @type {boolean}
     * @memberof OffrePartenaire
     */
    utilisationCumuls?: boolean;
}
/**
 * 
 * @export
 * @interface PageDepotPartenaire
 */
export interface PageDepotPartenaire {
    /**
     * 
     * @type {number}
     * @memberof PageDepotPartenaire
     */
    total?: number;
    /**
     * 
     * @type {Array<DepotPartenaire>}
     * @memberof PageDepotPartenaire
     */
    elements?: Array<DepotPartenaire>;
}
/**
 * 
 * @export
 * @interface PageDossiersLocation
 */
export interface PageDossiersLocation {
    /**
     * 
     * @type {number}
     * @memberof PageDossiersLocation
     */
    total?: number;
    /**
     * 
     * @type {Array<DossierLocationItem>}
     * @memberof PageDossiersLocation
     */
    elements?: Array<DossierLocationItem>;
    /**
     * 
     * @type {boolean}
     * @memberof PageDossiersLocation
     */
    alerteEnCoursSuppression?: boolean;
}
/**
 * 
 * @export
 * @interface PageUtilisateurs
 */
export interface PageUtilisateurs {
    /**
     * 
     * @type {number}
     * @memberof PageUtilisateurs
     */
    total?: number;
    /**
     * 
     * @type {Array<Utilisateur>}
     * @memberof PageUtilisateurs
     */
    elements?: Array<Utilisateur>;
}
/**
 * 
 * @export
 * @interface ParametrageApplicationAdb
 */
export interface ParametrageApplicationAdb {
    /**
     * 
     * @type {number}
     * @memberof ParametrageApplicationAdb
     */
    identifiant?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParametrageApplicationAdb
     */
    actif?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParametrageApplicationAdb
     */
    codeDeblocage?: string;
    /**
     * 
     * @type {Date}
     * @memberof ParametrageApplicationAdb
     */
    dateActivation?: Date;
    /**
     * 
     * @type {UtilisateurInfo}
     * @memberof ParametrageApplicationAdb
     */
    utilisateurActivant?: UtilisateurInfo;
}
/**
 * Partenaire de VERTULOO
 * @export
 * @interface Partenaire
 */
export interface Partenaire {
    /**
     * nom du partenaire
     * @type {string}
     * @memberof Partenaire
     */
    nom?: string;
    /**
     * code du partenaire
     * @type {string}
     * @memberof Partenaire
     */
    code?: string;
    /**
     * url du domaine
     * @type {string}
     * @memberof Partenaire
     */
    urlDomaine?: string;
    /**
     * url de base de l\'api
     * @type {string}
     * @memberof Partenaire
     */
    urlBaseApi?: string;
    /**
     * url du front
     * @type {string}
     * @memberof Partenaire
     */
    urlFront?: string;
    /**
     * 
     * @type {Array<OffrePartenaire>}
     * @memberof Partenaire
     */
    offres?: Array<OffrePartenaire>;
}
/**
 * Référence dossier location pour le partenaire
 * @export
 * @interface PartenaireReferenceDossierLocation
 */
export interface PartenaireReferenceDossierLocation {
    /**
     * Référence du dossier location chez le partenaire
     * @type {string}
     * @memberof PartenaireReferenceDossierLocation
     */
    reference: string;
    /**
     * Code du partenaire
     * @type {string}
     * @memberof PartenaireReferenceDossierLocation
     */
    codePartenaire: string;
    /**
     * Code de l\'offre du partenaire pour ce dossier particulier
     * @type {string}
     * @memberof PartenaireReferenceDossierLocation
     */
    codeOffre: string;
    /**
     * Url du front du partenaire
     * @type {string}
     * @memberof PartenaireReferenceDossierLocation
     */
    urlFront?: string;
}
/**
 * 
 * @export
 * @interface Pays
 */
export interface Pays {
    /**
     * 
     * @type {number}
     * @memberof Pays
     */
    numIso?: number;
    /**
     * 
     * @type {string}
     * @memberof Pays
     */
    codeIso2?: string;
    /**
     * 
     * @type {string}
     * @memberof Pays
     */
    codeIso3?: string;
    /**
     * 
     * @type {string}
     * @memberof Pays
     */
    nomPaysFrancais?: string;
    /**
     * 
     * @type {string}
     * @memberof Pays
     */
    nomPaysAnglais?: string;
}
/**
 * 
 * @export
 * @interface PieceInvalideError
 */
export interface PieceInvalideError {
    /**
     * 
     * @type {string}
     * @memberof PieceInvalideError
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof PieceInvalideError
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PieceInvalideError
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface PieceJustificative
 */
export interface PieceJustificative {
    /**
     * 
     * @type {string}
     * @memberof PieceJustificative
     */
    libelle?: string;
    /**
     * 
     * @type {string}
     * @memberof PieceJustificative
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof PieceJustificative
     */
    attachmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof PieceJustificative
     */
    pieceId?: string;
    /**
     * 
     * @type {string}
     * @memberof PieceJustificative
     */
    statut?: PieceJustificativeStatutEnum;
    /**
     * 
     * @type {Array<ErreurPieceJustificative>}
     * @memberof PieceJustificative
     */
    pieceJustificativeErreurs?: Array<ErreurPieceJustificative>;
    /**
     * 
     * @type {Array<PieceJustificativeDetail>}
     * @memberof PieceJustificative
     */
    pieceJustificativeDetails?: Array<PieceJustificativeDetail>;
}

/**
    * @export
    * @enum {string}
    */
export enum PieceJustificativeStatutEnum {
    VALIDATED = 'VALIDATED',
    VALIDATIONFAILURE = 'VALIDATION_FAILURE',
    NOTVALIDATED = 'NOT_VALIDATED'
}

/**
 * 
 * @export
 * @interface PieceJustificativeDetail
 */
export interface PieceJustificativeDetail {
    /**
     * 
     * @type {string}
     * @memberof PieceJustificativeDetail
     */
    nom?: string;
    /**
     * 
     * @type {string}
     * @memberof PieceJustificativeDetail
     */
    mimeType?: string;
}
/**
 * 
 * @export
 * @interface PiecesJustificativePartenaire
 */
export interface PiecesJustificativePartenaire {
    /**
     * 
     * @type {string}
     * @memberof PiecesJustificativePartenaire
     */
    typePiece?: string;
    /**
     * 
     * @type {string}
     * @memberof PiecesJustificativePartenaire
     */
    statut?: PiecesJustificativePartenaireStatutEnum;
    /**
     * 
     * @type {Array<ErreursPieceJustificativePartenaire>}
     * @memberof PiecesJustificativePartenaire
     */
    erreursPieceJustificative?: Array<ErreursPieceJustificativePartenaire>;
}

/**
    * @export
    * @enum {string}
    */
export enum PiecesJustificativePartenaireStatutEnum {
    VALIDATED = 'VALIDATED',
    VALIDATIONFAILURE = 'VALIDATION_FAILURE',
    REJECTED = 'REJECTED'
}

/**
 * 
 * @export
 * @interface PiecesJustificatives
 */
export interface PiecesJustificatives {
    /**
     * 
     * @type {string}
     * @memberof PiecesJustificatives
     */
    referenceDossierLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof PiecesJustificatives
     */
    referenceDossierParticulier?: string;
    /**
     * 
     * @type {Array<FamilleJustificatif>}
     * @memberof PiecesJustificatives
     */
    piecesJustificatives?: Array<FamilleJustificatif>;
}
/**
 * 
 * @export
 * @interface Profil
 */
export interface Profil {
    /**
     * 
     * @type {number}
     * @memberof Profil
     */
    identifiant?: number;
    /**
     * Nom du profil
     * @type {string}
     * @memberof Profil
     */
    nom?: string;
    /**
     * Description du profil
     * @type {string}
     * @memberof Profil
     */
    description?: string;
    /**
     * Codes des fonctions (droits) du profil
     * @type {Array<string>}
     * @memberof Profil
     */
    fonctions?: Array<string>;
}
/**
 * 
 * @export
 * @interface RapportBien
 */
export interface RapportBien {
    /**
     * 
     * @type {string}
     * @memberof RapportBien
     */
    codePostal?: string;
    /**
     * 
     * @type {string}
     * @memberof RapportBien
     */
    ville?: string;
    /**
     * 
     * @type {string}
     * @memberof RapportBien
     */
    adresse?: string;
}
/**
 * 
 * @export
 * @interface RapportCalculAidesSociales
 */
export interface RapportCalculAidesSociales {
    /**
     * 
     * @type {number}
     * @memberof RapportCalculAidesSociales
     */
    nombreEnfantInf20?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportCalculAidesSociales
     */
    nombreEnfant14?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportCalculAidesSociales
     */
    nombreEnfant14A20?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportCalculAidesSociales
     */
    nombreEnfant21?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportCalculAidesSociales
     */
    nombreEnfantSup21?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportCalculAidesSociales
     */
    mab?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportCalculAidesSociales
     */
    mmab?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportCalculAidesSociales
     */
    maf?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportCalculAidesSociales
     */
    montantTotalAllocation?: number;
}
/**
 * 
 * @export
 * @interface RapportCalculAlgo
 */
export interface RapportCalculAlgo {
    /**
     * 
     * @type {RapportDossierLocation}
     * @memberof RapportCalculAlgo
     */
    dossierLocation?: RapportDossierLocation;
}
/**
 * 
 * @export
 * @interface RapportCalculIndicePriseEnCharge
 */
export interface RapportCalculIndicePriseEnCharge {
    /**
     * 
     * @type {string}
     * @memberof RapportCalculIndicePriseEnCharge
     */
    statut?: string;
    /**
     * 
     * @type {string}
     * @memberof RapportCalculIndicePriseEnCharge
     */
    situationProfessionnelle?: string;
    /**
     * 
     * @type {string}
     * @memberof RapportCalculIndicePriseEnCharge
     */
    codeNaf?: string;
    /**
     * 
     * @type {string}
     * @memberof RapportCalculIndicePriseEnCharge
     */
    secteurActivite?: string;
    /**
     * 
     * @type {number}
     * @memberof RapportCalculIndicePriseEnCharge
     */
    codeRegion?: number;
    /**
     * 
     * @type {string}
     * @memberof RapportCalculIndicePriseEnCharge
     */
    libelleRegion?: string;
    /**
     * 
     * @type {number}
     * @memberof RapportCalculIndicePriseEnCharge
     */
    indiceSecteurActivite?: number;
    /**
     * 
     * @type {Date}
     * @memberof RapportCalculIndicePriseEnCharge
     */
    dateDebutSituation?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RapportCalculIndicePriseEnCharge
     */
    dateFinSituation?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RapportCalculIndicePriseEnCharge
     */
    dateCalcul?: Date;
    /**
     * 
     * @type {number}
     * @memberof RapportCalculIndicePriseEnCharge
     */
    delaiDebutSituation?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportCalculIndicePriseEnCharge
     */
    delaiFinSituation?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportCalculIndicePriseEnCharge
     */
    nombreMoisTravailles?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportCalculIndicePriseEnCharge
     */
    indiceSinistrePrime?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportCalculIndicePriseEnCharge
     */
    indicePriseEnCharge?: number;
}
/**
 * 
 * @export
 * @interface RapportDossierLocation
 */
export interface RapportDossierLocation {
    /**
     * 
     * @type {string}
     * @memberof RapportDossierLocation
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof RapportDossierLocation
     */
    compositionLocation?: RapportDossierLocationCompositionLocationEnum;
    /**
     * 
     * @type {number}
     * @memberof RapportDossierLocation
     */
    aidesSocialesMensuelles?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportDossierLocation
     */
    revenusMensuelsDossier?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportDossierLocation
     */
    ressourcesMensuellesDossier?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportDossierLocation
     */
    chargesMensuellesDossier?: number;
    /**
     * 
     * @type {RapportBien}
     * @memberof RapportDossierLocation
     */
    bien?: RapportBien;
    /**
     * 
     * @type {Array<RapportDossierParticulier>}
     * @memberof RapportDossierLocation
     */
    dossiersParticuliers?: Array<RapportDossierParticulier>;
    /**
     * 
     * @type {RapportLoyerMaxConseille}
     * @memberof RapportDossierLocation
     */
    calculLoyerMaxConseille?: RapportLoyerMaxConseille;
}

/**
    * @export
    * @enum {string}
    */
export enum RapportDossierLocationCompositionLocationEnum {
    SEUL = 'SEUL',
    COUPLE = 'COUPLE',
    COLOCATION = 'COLOCATION'
}

/**
 * 
 * @export
 * @interface RapportDossierParticulier
 */
export interface RapportDossierParticulier {
    /**
     * 
     * @type {string}
     * @memberof RapportDossierParticulier
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof RapportDossierParticulier
     */
    nomLocataire?: string;
    /**
     * 
     * @type {string}
     * @memberof RapportDossierParticulier
     */
    prenomLocataire?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RapportDossierParticulier
     */
    principal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RapportDossierParticulier
     */
    typeDossierParticulier?: RapportDossierParticulierTypeDossierParticulierEnum;
    /**
     * 
     * @type {RapportCalculIndicePriseEnCharge}
     * @memberof RapportDossierParticulier
     */
    calculIndicePriseEnCharge?: RapportCalculIndicePriseEnCharge;
    /**
     * 
     * @type {RapportCalculAidesSociales}
     * @memberof RapportDossierParticulier
     */
    calculAidesSociales?: RapportCalculAidesSociales;
    /**
     * 
     * @type {RapportRessourcesMensuelles}
     * @memberof RapportDossierParticulier
     */
    calculRessourcesMensuelles?: RapportRessourcesMensuelles;
}

/**
    * @export
    * @enum {string}
    */
export enum RapportDossierParticulierTypeDossierParticulierEnum {
    LOCATAIRE = 'LOCATAIRE',
    GARANT = 'GARANT'
}

/**
 * 
 * @export
 * @interface RapportLoyerMaxConseille
 */
export interface RapportLoyerMaxConseille {
    /**
     * 
     * @type {number}
     * @memberof RapportLoyerMaxConseille
     */
    chargeUc?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportLoyerMaxConseille
     */
    nombreUc?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportLoyerMaxConseille
     */
    nombreUcAdultes?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportLoyerMaxConseille
     */
    nombreUcEnfants?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportLoyerMaxConseille
     */
    nombreUcEnfants14AnsEtMoins?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportLoyerMaxConseille
     */
    nombreUcEnfantsPlusDe14Ans?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportLoyerMaxConseille
     */
    loyerMaxConseille?: number;
}
/**
 * 
 * @export
 * @interface RapportRessourcesMensuelles
 */
export interface RapportRessourcesMensuelles {
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    cumulSalaireM1?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    cumulSalaireM2?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    cumulSalaireM3?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    cumulMensuel?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RapportRessourcesMensuelles
     */
    anciennetePlus1an?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    numMoisM1?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    numMoisM2?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    numMoisM3?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    salaireMensuelM1?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    salaireMensuelM2?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    salaireMensuelM3?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    salairePlafonneMensuelM1?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    salairePlafonneMensuelM2?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    salairePlafonneMensuelM3?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    ponderationSalaireMensuelM1?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    ponderationSalaireMensuelM2?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    ponderationSalaireMensuelM3?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    salaireMensuelPondere?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    salaireMensuelRetenu?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    salaireMensuelMoyen?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    revenuIndividuelDeclare?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    revenuIndividuelMensuelDeclare?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    revenuFiscalReference?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportRessourcesMensuelles
     */
    revenuFiscalMensuelReference?: number;
}
/**
 * 
 * @export
 * @interface RetourLocationAcceptee
 */
export interface RetourLocationAcceptee {
    /**
     * 
     * @type {string}
     * @memberof RetourLocationAcceptee
     */
    referenceDossier?: string;
    /**
     * 
     * @type {string}
     * @memberof RetourLocationAcceptee
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface Site
 */
export interface Site {
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    numero?: number;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    nom?: string;
}
/**
 * 
 * @export
 * @interface SituationPersonnelleDossierParticulier
 */
export interface SituationPersonnelleDossierParticulier {
    /**
     * 
     * @type {string}
     * @memberof SituationPersonnelleDossierParticulier
     */
    qualite?: SituationPersonnelleDossierParticulierQualiteEnum;
    /**
     * 
     * @type {string}
     * @memberof SituationPersonnelleDossierParticulier
     */
    nom?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationPersonnelleDossierParticulier
     */
    nomDeNaissance?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationPersonnelleDossierParticulier
     */
    prenom?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationPersonnelleDossierParticulier
     */
    dateNaissance?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationPersonnelleDossierParticulier
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationPersonnelleDossierParticulier
     */
    numeroBatiment?: string;
    /**
     * 
     * @type {CodeTypeVoie}
     * @memberof SituationPersonnelleDossierParticulier
     */
    typeVoie?: CodeTypeVoie;
    /**
     * 
     * @type {string}
     * @memberof SituationPersonnelleDossierParticulier
     */
    numero?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationPersonnelleDossierParticulier
     */
    adresse?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationPersonnelleDossierParticulier
     */
    codePostal?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationPersonnelleDossierParticulier
     */
    ville?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationPersonnelleDossierParticulier
     */
    telephoneFixe?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationPersonnelleDossierParticulier
     */
    telephonePortable?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationPersonnelleDossierParticulier
     */
    dateEntree?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationPersonnelleDossierParticulier
     */
    statutOccupation?: SituationPersonnelleDossierParticulierStatutOccupationEnum;
    /**
     * 
     * @type {string}
     * @memberof SituationPersonnelleDossierParticulier
     */
    situationMaritale?: SituationPersonnelleDossierParticulierSituationMaritaleEnum;
    /**
     * 
     * @type {Array<Enfant>}
     * @memberof SituationPersonnelleDossierParticulier
     */
    enfants?: Array<Enfant>;
}

/**
    * @export
    * @enum {string}
    */
export enum SituationPersonnelleDossierParticulierQualiteEnum {
    MME = 'MME',
    M = 'M'
}
/**
    * @export
    * @enum {string}
    */
export enum SituationPersonnelleDossierParticulierStatutOccupationEnum {
    LOCATAIRE = 'LOCATAIRE',
    OCCUPANTATITREGRATUIT = 'OCCUPANT_A_TITRE_GRATUIT',
    PROPRIETAIRE = 'PROPRIETAIRE'
}
/**
    * @export
    * @enum {string}
    */
export enum SituationPersonnelleDossierParticulierSituationMaritaleEnum {
    CELIBATAIRE = 'CELIBATAIRE',
    ENCOUPLE = 'EN_COUPLE',
    DIVORCE = 'DIVORCE',
    VEUF = 'VEUF'
}

/**
 * 
 * @export
 * @interface SituationProfessionnelle
 */
export interface SituationProfessionnelle {
    /**
     * 
     * @type {string}
     * @memberof SituationProfessionnelle
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationProfessionnelle
     */
    libelle?: string;
    /**
     * 
     * @type {Array<StatutProfessionnel>}
     * @memberof SituationProfessionnelle
     */
    statutsProfessionnels?: Array<StatutProfessionnel>;
}
/**
 * 
 * @export
 * @interface SituationProfessionnelleDossierParticulier
 */
export interface SituationProfessionnelleDossierParticulier {
    /**
     * 
     * @type {boolean}
     * @memberof SituationProfessionnelleDossierParticulier
     */
    pasAvisImposition?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SituationProfessionnelleDossierParticulier
     */
    codeStatutProfessionnel?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationProfessionnelleDossierParticulier
     */
    dateDebutSituationProfessionnelle?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationProfessionnelleDossierParticulier
     */
    dateFinSituationProfessionnelle?: string;
    /**
     * 
     * @type {number}
     * @memberof SituationProfessionnelleDossierParticulier
     */
    nombreMoisTravailles?: number;
    /**
     * 
     * @type {number}
     * @memberof SituationProfessionnelleDossierParticulier
     */
    salaireNetImposableM1?: number;
    /**
     * 
     * @type {number}
     * @memberof SituationProfessionnelleDossierParticulier
     */
    salaireNetImposableM2?: number;
    /**
     * 
     * @type {number}
     * @memberof SituationProfessionnelleDossierParticulier
     */
    salaireNetImposableM3?: number;
    /**
     * 
     * @type {number}
     * @memberof SituationProfessionnelleDossierParticulier
     */
    cumulSalaireNetImposableM1?: number;
    /**
     * 
     * @type {number}
     * @memberof SituationProfessionnelleDossierParticulier
     */
    cumulSalaireNetImposableM2?: number;
    /**
     * 
     * @type {number}
     * @memberof SituationProfessionnelleDossierParticulier
     */
    cumulSalaireNetImposableM3?: number;
    /**
     * 
     * @type {number}
     * @memberof SituationProfessionnelleDossierParticulier
     */
    revenuIndividuelDeclare?: number;
    /**
     * 
     * @type {number}
     * @memberof SituationProfessionnelleDossierParticulier
     */
    revenuFiscalReference?: number;
    /**
     * 
     * @type {string}
     * @memberof SituationProfessionnelleDossierParticulier
     */
    dateDernierBulletin?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationProfessionnelleDossierParticulier
     */
    codeApe?: string;
    /**
     * 
     * @type {string}
     * @memberof SituationProfessionnelleDossierParticulier
     */
    siret?: string;
}
/**
 * 
 * @export
 * @interface StatutProfessionnel
 */
export interface StatutProfessionnel {
    /**
     * 
     * @type {string}
     * @memberof StatutProfessionnel
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof StatutProfessionnel
     */
    libelle?: string;
}
/**
 * 
 * @export
 * @interface TitreAdb
 */
export interface TitreAdb {
    /**
     * 
     * @type {number}
     * @memberof TitreAdb
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof TitreAdb
     */
    libelle?: string;
    /**
     * 
     * @type {string}
     * @memberof TitreAdb
     */
    libelleAbrege?: string;
}
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    token: string;
}
/**
 * 
 * @export
 * @interface TypeBien
 */
export interface TypeBien {
    /**
     * 
     * @type {CodeTypeBien}
     * @memberof TypeBien
     */
    code?: CodeTypeBien;
    /**
     * 
     * @type {string}
     * @memberof TypeBien
     */
    libelle?: string;
}
/**
 * 
 * @export
 * @interface TypeJustificatif
 */
export interface TypeJustificatif {
    /**
     * 
     * @type {string}
     * @memberof TypeJustificatif
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeJustificatif
     */
    libelle?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeJustificatif
     */
    idTechnique?: string;
}
/**
 * 
 * @export
 * @interface TypeVoie
 */
export interface TypeVoie {
    /**
     * 
     * @type {CodeTypeVoie}
     * @memberof TypeVoie
     */
    code?: CodeTypeVoie;
    /**
     * 
     * @type {string}
     * @memberof TypeVoie
     */
    libelle?: string;
}
/**
 * 
 * @export
 * @interface Utilisateur
 */
export interface Utilisateur {
    /**
     * 
     * @type {string}
     * @memberof Utilisateur
     */
    identifiant?: string;
    /**
     * 
     * @type {string}
     * @memberof Utilisateur
     */
    nom: string;
    /**
     * 
     * @type {string}
     * @memberof Utilisateur
     */
    prenom: string;
    /**
     * 
     * @type {Date}
     * @memberof Utilisateur
     */
    dateDerniereConnexion?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Utilisateur
     */
    actif?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Utilisateur
     */
    mail: string;
    /**
     * 
     * @type {string}
     * @memberof Utilisateur
     */
    motDePasse?: string;
    /**
     * 
     * @type {string}
     * @memberof Utilisateur
     */
    role?: UtilisateurRoleEnum;
    /**
     * Id de la fonction (poste)
     * @type {number}
     * @memberof Utilisateur
     */
    fonction?: number;
    /**
     * Id du profil Bessé
     * @type {number}
     * @memberof Utilisateur
     */
    profilBesse?: number;
    /**
     * Nom du profil Bessé
     * @type {string}
     * @memberof Utilisateur
     */
    nomProfilBesse?: string;
    /**
     * 
     * @type {Array<UtilisateurDetailAdbProfilSites>}
     * @memberof Utilisateur
     */
    detailAdbProfilSites?: Array<UtilisateurDetailAdbProfilSites>;
}

/**
    * @export
    * @enum {string}
    */
export enum UtilisateurRoleEnum {
    PARTICULIER = 'PARTICULIER',
    ADB = 'ADB',
    BESSE = 'BESSE',
    PARTENAIRE = 'PARTENAIRE'
}

/**
 * 
 * @export
 * @interface UtilisateurDetailAdbProfilSites
 */
export interface UtilisateurDetailAdbProfilSites {
    /**
     * Id de l\'administrateur de biens
     * @type {number}
     * @memberof UtilisateurDetailAdbProfilSites
     */
    refAdb?: number;
    /**
     * Nom de l\'administrateur de biens
     * @type {string}
     * @memberof UtilisateurDetailAdbProfilSites
     */
    nomAdb?: string;
    /**
     * Code application
     * @type {string}
     * @memberof UtilisateurDetailAdbProfilSites
     */
    codeApplication?: string;
    /**
     * Id du profil pour cet AdB
     * @type {number}
     * @memberof UtilisateurDetailAdbProfilSites
     */
    profil?: number;
    /**
     * Nom du profil pour cet AdB
     * @type {string}
     * @memberof UtilisateurDetailAdbProfilSites
     */
    nomProfil?: string;
    /**
     * Ids des sites
     * @type {Array<number>}
     * @memberof UtilisateurDetailAdbProfilSites
     */
    sites?: Array<number>;
}
/**
 * 
 * @export
 * @interface UtilisateurInfo
 */
export interface UtilisateurInfo {
    /**
     * 
     * @type {string}
     * @memberof UtilisateurInfo
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurInfo
     */
    nom?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilisateurInfo
     */
    prenom?: string;
}

/**
 * AdministrateurBienApi - axios parameter creator
 * @export
 */
export const AdministrateurBienApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Récupérer la liste des administrateurs de biens
         * @param {number} [numAdherent] 
         * @param {string} [nomAdherent] 
         * @param {string} [commercial] 
         * @param {string} [departement] 
         * @param {string} [codePostal] 
         * @param {string} [ville] 
         * @param {boolean} [actif] 
         * @param {number} [page] L\&#39;index de la page (l\&#39;index de la première page est 0)
         * @param {number} [taille] Le nombre d\&#39;éléments à retourner
         * @param {Array<string>} [tri] Format : attribut1:asc,attribut2:desc   Nom de l\&#39;attribut égal au nom du champ envoyé   Par defaut le tri porte sur le nom de l\&#39;ADB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrateursBiensGet(numAdherent?: number, nomAdherent?: string, commercial?: string, departement?: string, codePostal?: string, ville?: string, actif?: boolean, page?: number, taille?: number, tri?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/administrateurs-biens`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (numAdherent !== undefined) {
                localVarQueryParameter['numAdherent'] = numAdherent;
            }

            if (nomAdherent !== undefined) {
                localVarQueryParameter['nomAdherent'] = nomAdherent;
            }

            if (commercial !== undefined) {
                localVarQueryParameter['commercial'] = commercial;
            }

            if (departement !== undefined) {
                localVarQueryParameter['departement'] = departement;
            }

            if (codePostal !== undefined) {
                localVarQueryParameter['codePostal'] = codePostal;
            }

            if (ville !== undefined) {
                localVarQueryParameter['ville'] = ville;
            }

            if (actif !== undefined) {
                localVarQueryParameter['actif'] = actif;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (taille !== undefined) {
                localVarQueryParameter['taille'] = taille;
            }

            if (tri) {
                localVarQueryParameter['tri'] = tri.join(COLLECTION_FORMATS.csv);
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activation d\'un administrateur de bien pour l\'application Agrement (uniquement)
         * @param {number} id Référence de l\&#39;administrateur de bien
         * @param {InlineObject3} activerAdb 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrateursBiensIdActiverPost(id: number, activerAdb: InlineObject3, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling administrateursBiensIdActiverPost.');
            }
            // verify required parameter 'activerAdb' is not null or undefined
            if (activerAdb === null || activerAdb === undefined) {
                throw new RequiredError('activerAdb','Required parameter activerAdb was null or undefined when calling administrateursBiensIdActiverPost.');
            }
            const localVarPath = `/administrateurs-biens/{id}/activer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"InlineObject3" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(activerAdb !== undefined ? activerAdb : {}) : (activerAdb || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Désactivation d\'un administrateur de bien pour l\'application Agrement (uniquement)
         * @param {number} id Réference de l\&#39;administrateur de bien
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrateursBiensIdDesactiverPost(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling administrateursBiensIdDesactiverPost.');
            }
            const localVarPath = `/administrateurs-biens/{id}/desactiver`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Concernant les applications, seules les informations relatives à Agrément (paramétrage, profils) sont retournées.
         * @summary Retourne le détail d\'un administrateur de biens
         * @param {number} id Réference de l\&#39;administrateur de bien
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrateursBiensIdGet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling administrateursBiensIdGet.');
            }
            const localVarPath = `/administrateurs-biens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retourne l\'ensemble des administrateurs de biens appartenant au même regroupement que l\'administrateur de biens identifié
         * @param {number} id Réference de l\&#39;administrateur de bien
         * @param {string} [application] Code application. Si présent, les AdB retournés sont ceux actifs pour cette application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrateursBiensIdRegroupementGet(id: number, application?: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling administrateursBiensIdRegroupementGet.');
            }
            const localVarPath = `/administrateurs-biens/{id}/regroupement`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (application !== undefined) {
                localVarQueryParameter['application'] = application;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Récupération d\'un site d\'un AdB
         * @param {number} id Id de l\&#39;AdB
         * @param {number} numeroSite Numéro du site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteByReferenceAdbAndNumeroSite(id: number, numeroSite: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSiteByReferenceAdbAndNumeroSite.');
            }
            // verify required parameter 'numeroSite' is not null or undefined
            if (numeroSite === null || numeroSite === undefined) {
                throw new RequiredError('numeroSite','Required parameter numeroSite was null or undefined when calling getSiteByReferenceAdbAndNumeroSite.');
            }
            const localVarPath = `/administrateurs-biens/{id}/sites/{numeroSite}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"numeroSite"}}`, encodeURIComponent(String(numeroSite)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdministrateurBienApi - functional programming interface
 * @export
 */
export const AdministrateurBienApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Récupérer la liste des administrateurs de biens
         * @param {number} [numAdherent] 
         * @param {string} [nomAdherent] 
         * @param {string} [commercial] 
         * @param {string} [departement] 
         * @param {string} [codePostal] 
         * @param {string} [ville] 
         * @param {boolean} [actif] 
         * @param {number} [page] L\&#39;index de la page (l\&#39;index de la première page est 0)
         * @param {number} [taille] Le nombre d\&#39;éléments à retourner
         * @param {Array<string>} [tri] Format : attribut1:asc,attribut2:desc   Nom de l\&#39;attribut égal au nom du champ envoyé   Par defaut le tri porte sur le nom de l\&#39;ADB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrateursBiensGet(numAdherent?: number, nomAdherent?: string, commercial?: string, departement?: string, codePostal?: string, ville?: string, actif?: boolean, page?: number, taille?: number, tri?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdministrateurBienList> {
            const localVarAxiosArgs = AdministrateurBienApiAxiosParamCreator(configuration).administrateursBiensGet(numAdherent, nomAdherent, commercial, departement, codePostal, ville, actif, page, taille, tri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Activation d\'un administrateur de bien pour l\'application Agrement (uniquement)
         * @param {number} id Référence de l\&#39;administrateur de bien
         * @param {InlineObject3} activerAdb 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrateursBiensIdActiverPost(id: number, activerAdb: InlineObject3, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdministrateurBienDetail> {
            const localVarAxiosArgs = AdministrateurBienApiAxiosParamCreator(configuration).administrateursBiensIdActiverPost(id, activerAdb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Désactivation d\'un administrateur de bien pour l\'application Agrement (uniquement)
         * @param {number} id Réference de l\&#39;administrateur de bien
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrateursBiensIdDesactiverPost(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdministrateurBienDetail> {
            const localVarAxiosArgs = AdministrateurBienApiAxiosParamCreator(configuration).administrateursBiensIdDesactiverPost(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Concernant les applications, seules les informations relatives à Agrément (paramétrage, profils) sont retournées.
         * @summary Retourne le détail d\'un administrateur de biens
         * @param {number} id Réference de l\&#39;administrateur de bien
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrateursBiensIdGet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdministrateurBienDetail> {
            const localVarAxiosArgs = AdministrateurBienApiAxiosParamCreator(configuration).administrateursBiensIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retourne l\'ensemble des administrateurs de biens appartenant au même regroupement que l\'administrateur de biens identifié
         * @param {number} id Réference de l\&#39;administrateur de bien
         * @param {string} [application] Code application. Si présent, les AdB retournés sont ceux actifs pour cette application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrateursBiensIdRegroupementGet(id: number, application?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdministrateurBien>> {
            const localVarAxiosArgs = AdministrateurBienApiAxiosParamCreator(configuration).administrateursBiensIdRegroupementGet(id, application, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Récupération d\'un site d\'un AdB
         * @param {number} id Id de l\&#39;AdB
         * @param {number} numeroSite Numéro du site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteByReferenceAdbAndNumeroSite(id: number, numeroSite: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Site> {
            const localVarAxiosArgs = AdministrateurBienApiAxiosParamCreator(configuration).getSiteByReferenceAdbAndNumeroSite(id, numeroSite, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdministrateurBienApi - factory interface
 * @export
 */
export const AdministrateurBienApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Récupérer la liste des administrateurs de biens
         * @param {number} [numAdherent] 
         * @param {string} [nomAdherent] 
         * @param {string} [commercial] 
         * @param {string} [departement] 
         * @param {string} [codePostal] 
         * @param {string} [ville] 
         * @param {boolean} [actif] 
         * @param {number} [page] L\&#39;index de la page (l\&#39;index de la première page est 0)
         * @param {number} [taille] Le nombre d\&#39;éléments à retourner
         * @param {Array<string>} [tri] Format : attribut1:asc,attribut2:desc   Nom de l\&#39;attribut égal au nom du champ envoyé   Par defaut le tri porte sur le nom de l\&#39;ADB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrateursBiensGet(numAdherent?: number, nomAdherent?: string, commercial?: string, departement?: string, codePostal?: string, ville?: string, actif?: boolean, page?: number, taille?: number, tri?: Array<string>, options?: any) {
            return AdministrateurBienApiFp(configuration).administrateursBiensGet(numAdherent, nomAdherent, commercial, departement, codePostal, ville, actif, page, taille, tri, options)(axios, basePath);
        },
        /**
         * 
         * @summary Activation d\'un administrateur de bien pour l\'application Agrement (uniquement)
         * @param {number} id Référence de l\&#39;administrateur de bien
         * @param {InlineObject3} activerAdb 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrateursBiensIdActiverPost(id: number, activerAdb: InlineObject3, options?: any) {
            return AdministrateurBienApiFp(configuration).administrateursBiensIdActiverPost(id, activerAdb, options)(axios, basePath);
        },
        /**
         * 
         * @summary Désactivation d\'un administrateur de bien pour l\'application Agrement (uniquement)
         * @param {number} id Réference de l\&#39;administrateur de bien
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrateursBiensIdDesactiverPost(id: number, options?: any) {
            return AdministrateurBienApiFp(configuration).administrateursBiensIdDesactiverPost(id, options)(axios, basePath);
        },
        /**
         * Concernant les applications, seules les informations relatives à Agrément (paramétrage, profils) sont retournées.
         * @summary Retourne le détail d\'un administrateur de biens
         * @param {number} id Réference de l\&#39;administrateur de bien
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrateursBiensIdGet(id: number, options?: any) {
            return AdministrateurBienApiFp(configuration).administrateursBiensIdGet(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Retourne l\'ensemble des administrateurs de biens appartenant au même regroupement que l\'administrateur de biens identifié
         * @param {number} id Réference de l\&#39;administrateur de bien
         * @param {string} [application] Code application. Si présent, les AdB retournés sont ceux actifs pour cette application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administrateursBiensIdRegroupementGet(id: number, application?: string, options?: any) {
            return AdministrateurBienApiFp(configuration).administrateursBiensIdRegroupementGet(id, application, options)(axios, basePath);
        },
        /**
         * 
         * @summary Récupération d\'un site d\'un AdB
         * @param {number} id Id de l\&#39;AdB
         * @param {number} numeroSite Numéro du site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteByReferenceAdbAndNumeroSite(id: number, numeroSite: number, options?: any) {
            return AdministrateurBienApiFp(configuration).getSiteByReferenceAdbAndNumeroSite(id, numeroSite, options)(axios, basePath);
        },
    };
};

/**
 * AdministrateurBienApi - object-oriented interface
 * @export
 * @class AdministrateurBienApi
 * @extends {BaseAPI}
 */
export class AdministrateurBienApi extends BaseAPI {
    /**
     * 
     * @summary Récupérer la liste des administrateurs de biens
     * @param {number} [numAdherent] 
     * @param {string} [nomAdherent] 
     * @param {string} [commercial] 
     * @param {string} [departement] 
     * @param {string} [codePostal] 
     * @param {string} [ville] 
     * @param {boolean} [actif] 
     * @param {number} [page] L\&#39;index de la page (l\&#39;index de la première page est 0)
     * @param {number} [taille] Le nombre d\&#39;éléments à retourner
     * @param {Array<string>} [tri] Format : attribut1:asc,attribut2:desc   Nom de l\&#39;attribut égal au nom du champ envoyé   Par defaut le tri porte sur le nom de l\&#39;ADB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrateurBienApi
     */
    public administrateursBiensGet(numAdherent?: number, nomAdherent?: string, commercial?: string, departement?: string, codePostal?: string, ville?: string, actif?: boolean, page?: number, taille?: number, tri?: Array<string>, options?: any) {
        return AdministrateurBienApiFp(this.configuration).administrateursBiensGet(numAdherent, nomAdherent, commercial, departement, codePostal, ville, actif, page, taille, tri, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Activation d\'un administrateur de bien pour l\'application Agrement (uniquement)
     * @param {number} id Référence de l\&#39;administrateur de bien
     * @param {InlineObject3} activerAdb 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrateurBienApi
     */
    public administrateursBiensIdActiverPost(id: number, activerAdb: InlineObject3, options?: any) {
        return AdministrateurBienApiFp(this.configuration).administrateursBiensIdActiverPost(id, activerAdb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Désactivation d\'un administrateur de bien pour l\'application Agrement (uniquement)
     * @param {number} id Réference de l\&#39;administrateur de bien
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrateurBienApi
     */
    public administrateursBiensIdDesactiverPost(id: number, options?: any) {
        return AdministrateurBienApiFp(this.configuration).administrateursBiensIdDesactiverPost(id, options)(this.axios, this.basePath);
    }

    /**
     * Concernant les applications, seules les informations relatives à Agrément (paramétrage, profils) sont retournées.
     * @summary Retourne le détail d\'un administrateur de biens
     * @param {number} id Réference de l\&#39;administrateur de bien
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrateurBienApi
     */
    public administrateursBiensIdGet(id: number, options?: any) {
        return AdministrateurBienApiFp(this.configuration).administrateursBiensIdGet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Retourne l\'ensemble des administrateurs de biens appartenant au même regroupement que l\'administrateur de biens identifié
     * @param {number} id Réference de l\&#39;administrateur de bien
     * @param {string} [application] Code application. Si présent, les AdB retournés sont ceux actifs pour cette application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrateurBienApi
     */
    public administrateursBiensIdRegroupementGet(id: number, application?: string, options?: any) {
        return AdministrateurBienApiFp(this.configuration).administrateursBiensIdRegroupementGet(id, application, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Récupération d\'un site d\'un AdB
     * @param {number} id Id de l\&#39;AdB
     * @param {number} numeroSite Numéro du site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrateurBienApi
     */
    public getSiteByReferenceAdbAndNumeroSite(id: number, numeroSite: number, options?: any) {
        return AdministrateurBienApiFp(this.configuration).getSiteByReferenceAdbAndNumeroSite(id, numeroSite, options)(this.axios, this.basePath);
    }

}


/**
 * BiensApi - axios parameter creator
 * @export
 */
export const BiensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Récuperer un bien par critères de recherche
         * @param {string} referenceMandat Réference mandat
         * @param {string} numeroLot Numéro de lot
         * @param {number} refAdb Identifiant de l\&#39;ADB
         * @param {number} numeroSite Numéro du site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biensGet(referenceMandat: string, numeroLot: string, refAdb: number, numeroSite: number, options: any = {}): RequestArgs {
            // verify required parameter 'referenceMandat' is not null or undefined
            if (referenceMandat === null || referenceMandat === undefined) {
                throw new RequiredError('referenceMandat','Required parameter referenceMandat was null or undefined when calling biensGet.');
            }
            // verify required parameter 'numeroLot' is not null or undefined
            if (numeroLot === null || numeroLot === undefined) {
                throw new RequiredError('numeroLot','Required parameter numeroLot was null or undefined when calling biensGet.');
            }
            // verify required parameter 'refAdb' is not null or undefined
            if (refAdb === null || refAdb === undefined) {
                throw new RequiredError('refAdb','Required parameter refAdb was null or undefined when calling biensGet.');
            }
            // verify required parameter 'numeroSite' is not null or undefined
            if (numeroSite === null || numeroSite === undefined) {
                throw new RequiredError('numeroSite','Required parameter numeroSite was null or undefined when calling biensGet.');
            }
            const localVarPath = `/biens`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referenceMandat !== undefined) {
                localVarQueryParameter['referenceMandat'] = referenceMandat;
            }

            if (numeroLot !== undefined) {
                localVarQueryParameter['numeroLot'] = numeroLot;
            }

            if (refAdb !== undefined) {
                localVarQueryParameter['refAdb'] = refAdb;
            }

            if (numeroSite !== undefined) {
                localVarQueryParameter['numeroSite'] = numeroSite;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Récupération de la liste des nombres de pièces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biensNombresPiecesGet(options: any = {}): RequestArgs {
            const localVarPath = `/biens/nombres-pieces`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Récupération de la liste des types de biens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biensTypesBiensGet(options: any = {}): RequestArgs {
            const localVarPath = `/biens/types-biens`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Récupération d\'un nombre de pièces
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNombrePiecesByCode(code: string, options: any = {}): RequestArgs {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling getNombrePiecesByCode.');
            }
            const localVarPath = `/biens/nombres-pieces/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Récupération d\'un type de biens
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTypeBienByCode(code: string, options: any = {}): RequestArgs {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling getTypeBienByCode.');
            }
            const localVarPath = `/biens/types-biens/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BiensApi - functional programming interface
 * @export
 */
export const BiensApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Récuperer un bien par critères de recherche
         * @param {string} referenceMandat Réference mandat
         * @param {string} numeroLot Numéro de lot
         * @param {number} refAdb Identifiant de l\&#39;ADB
         * @param {number} numeroSite Numéro du site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biensGet(referenceMandat: string, numeroLot: string, refAdb: number, numeroSite: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BienDetail> {
            const localVarAxiosArgs = BiensApiAxiosParamCreator(configuration).biensGet(referenceMandat, numeroLot, refAdb, numeroSite, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Récupération de la liste des nombres de pièces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biensNombresPiecesGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NombrePieces>> {
            const localVarAxiosArgs = BiensApiAxiosParamCreator(configuration).biensNombresPiecesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Récupération de la liste des types de biens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biensTypesBiensGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TypeBien>> {
            const localVarAxiosArgs = BiensApiAxiosParamCreator(configuration).biensTypesBiensGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Récupération d\'un nombre de pièces
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNombrePiecesByCode(code: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NombrePieces> {
            const localVarAxiosArgs = BiensApiAxiosParamCreator(configuration).getNombrePiecesByCode(code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Récupération d\'un type de biens
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTypeBienByCode(code: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeBien> {
            const localVarAxiosArgs = BiensApiAxiosParamCreator(configuration).getTypeBienByCode(code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BiensApi - factory interface
 * @export
 */
export const BiensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Récuperer un bien par critères de recherche
         * @param {string} referenceMandat Réference mandat
         * @param {string} numeroLot Numéro de lot
         * @param {number} refAdb Identifiant de l\&#39;ADB
         * @param {number} numeroSite Numéro du site
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biensGet(referenceMandat: string, numeroLot: string, refAdb: number, numeroSite: number, options?: any) {
            return BiensApiFp(configuration).biensGet(referenceMandat, numeroLot, refAdb, numeroSite, options)(axios, basePath);
        },
        /**
         * 
         * @summary Récupération de la liste des nombres de pièces
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biensNombresPiecesGet(options?: any) {
            return BiensApiFp(configuration).biensNombresPiecesGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary Récupération de la liste des types de biens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        biensTypesBiensGet(options?: any) {
            return BiensApiFp(configuration).biensTypesBiensGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary Récupération d\'un nombre de pièces
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNombrePiecesByCode(code: string, options?: any) {
            return BiensApiFp(configuration).getNombrePiecesByCode(code, options)(axios, basePath);
        },
        /**
         * 
         * @summary Récupération d\'un type de biens
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTypeBienByCode(code: string, options?: any) {
            return BiensApiFp(configuration).getTypeBienByCode(code, options)(axios, basePath);
        },
    };
};

/**
 * BiensApi - object-oriented interface
 * @export
 * @class BiensApi
 * @extends {BaseAPI}
 */
export class BiensApi extends BaseAPI {
    /**
     * 
     * @summary Récuperer un bien par critères de recherche
     * @param {string} referenceMandat Réference mandat
     * @param {string} numeroLot Numéro de lot
     * @param {number} refAdb Identifiant de l\&#39;ADB
     * @param {number} numeroSite Numéro du site
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiensApi
     */
    public biensGet(referenceMandat: string, numeroLot: string, refAdb: number, numeroSite: number, options?: any) {
        return BiensApiFp(this.configuration).biensGet(referenceMandat, numeroLot, refAdb, numeroSite, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Récupération de la liste des nombres de pièces
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiensApi
     */
    public biensNombresPiecesGet(options?: any) {
        return BiensApiFp(this.configuration).biensNombresPiecesGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Récupération de la liste des types de biens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiensApi
     */
    public biensTypesBiensGet(options?: any) {
        return BiensApiFp(this.configuration).biensTypesBiensGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Récupération d\'un nombre de pièces
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiensApi
     */
    public getNombrePiecesByCode(code: string, options?: any) {
        return BiensApiFp(this.configuration).getNombrePiecesByCode(code, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Récupération d\'un type de biens
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BiensApi
     */
    public getTypeBienByCode(code: string, options?: any) {
        return BiensApiFp(this.configuration).getTypeBienByCode(code, options)(this.axios, this.basePath);
    }

}


/**
 * CommerciauxApi - axios parameter creator
 * @export
 */
export const CommerciauxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Liste des commerciaux depuis le référentiel Commerciaux
         * @summary Liste des commerciaux depuis le référentiel Commerciaux
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commerciauxGet(options: any = {}): RequestArgs {
            const localVarPath = `/commerciaux`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommerciauxApi - functional programming interface
 * @export
 */
export const CommerciauxApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Liste des commerciaux depuis le référentiel Commerciaux
         * @summary Liste des commerciaux depuis le référentiel Commerciaux
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commerciauxGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Commercial>> {
            const localVarAxiosArgs = CommerciauxApiAxiosParamCreator(configuration).commerciauxGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CommerciauxApi - factory interface
 * @export
 */
export const CommerciauxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Liste des commerciaux depuis le référentiel Commerciaux
         * @summary Liste des commerciaux depuis le référentiel Commerciaux
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commerciauxGet(options?: any) {
            return CommerciauxApiFp(configuration).commerciauxGet(options)(axios, basePath);
        },
    };
};

/**
 * CommerciauxApi - object-oriented interface
 * @export
 * @class CommerciauxApi
 * @extends {BaseAPI}
 */
export class CommerciauxApi extends BaseAPI {
    /**
     * Liste des commerciaux depuis le référentiel Commerciaux
     * @summary Liste des commerciaux depuis le référentiel Commerciaux
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommerciauxApi
     */
    public commerciauxGet(options?: any) {
        return CommerciauxApiFp(this.configuration).commerciauxGet(options)(this.axios, this.basePath);
    }

}


/**
 * DossiersLocationsApi - axios parameter creator
 * @export
 */
export const DossiersLocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Acceptation de la location
         * @param {string} ref Référence du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accepterLocation(ref: string, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling accepterLocation.');
            }
            const localVarPath = `/dossiers-location/{ref}/accepter-location`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Annulation de l\'acceptation de la location
         * @param {string} ref Référence du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annulerAcceptationLocation(ref: string, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling annulerAcceptationLocation.');
            }
            const localVarPath = `/dossiers-location/{ref}/refuser-location`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Création d\'un dossier de location
         * @param {DossierLocation} body Caractéristique du dossier de location à créer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationPost(body: DossierLocation, options: any = {}): RequestArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling dossiersLocationPost.');
            }
            const localVarPath = `/dossiers-location`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"DossierLocation" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Annuler le statut du dossier de location
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefAnnulerDossierPut(ref: string, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling dossiersLocationRefAnnulerDossierPut.');
            }
            const localVarPath = `/dossiers-location/{ref}/annuler-dossier`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Création d\'un bien
         * @param {string} ref Référence du dossier de location
         * @param {Bien} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefBienPost(ref: string, body?: Bien, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling dossiersLocationRefBienPost.');
            }
            const localVarPath = `/dossiers-location/{ref}/bien`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Bien" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modification d\'un bien
         * @param {string} ref Référence du dossier de location
         * @param {Bien} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefBienPut(ref: string, body?: Bien, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling dossiersLocationRefBienPut.');
            }
            const localVarPath = `/dossiers-location/{ref}/bien`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Bien" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Les informations professionnelles ne sont pas obligatoires. Si elles sont présentes et pertinentes, des contrôles sont effectués dessus. Les données non pertinentes sont totalement ignorées. Il s\'agit par exemple du nombre de mois travaillés selon le statut, ou certains salaires ou cumuls de salaires selon le statut et la date d\'entrée.
         * @summary Création de dossier personne
         * @param {string} ref Référence du dossier de location
         * @param {DossierParticulier} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefDossiersParticuliersPost(ref: string, body?: DossierParticulier, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling dossiersLocationRefDossiersParticuliersPost.');
            }
            const localVarPath = `/dossiers-location/{ref}/dossiers-particuliers`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"DossierParticulier" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mise à jour de la situation personnelle d\'un dossier personne
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {SituationPersonnelleDossierParticulier} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefDossiersParticuliersRefPersSituationPersonnellePut(ref: string, refPers: string, body?: SituationPersonnelleDossierParticulier, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling dossiersLocationRefDossiersParticuliersRefPersSituationPersonnellePut.');
            }
            // verify required parameter 'refPers' is not null or undefined
            if (refPers === null || refPers === undefined) {
                throw new RequiredError('refPers','Required parameter refPers was null or undefined when calling dossiersLocationRefDossiersParticuliersRefPersSituationPersonnellePut.');
            }
            const localVarPath = `/dossiers-location/{ref}/dossiers-particuliers/{refPers}/situation-personnelle`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)))
                .replace(`{${"refPers"}}`, encodeURIComponent(String(refPers)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"SituationPersonnelleDossierParticulier" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Les données non pertinentes sont totalement ignorées. Il s\'agit par exemple du nombre de mois travaillés selon le statut, ou certains salaires ou cumuls de salaires selon le statut et la date d\'entrée.
         * @summary Mise à jour de la situation professionnelle d\'un dossier personne
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {SituationProfessionnelleDossierParticulier} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefDossiersParticuliersRefPersSituationProfessionnellePut(ref: string, refPers: string, body?: SituationProfessionnelleDossierParticulier, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling dossiersLocationRefDossiersParticuliersRefPersSituationProfessionnellePut.');
            }
            // verify required parameter 'refPers' is not null or undefined
            if (refPers === null || refPers === undefined) {
                throw new RequiredError('refPers','Required parameter refPers was null or undefined when calling dossiersLocationRefDossiersParticuliersRefPersSituationProfessionnellePut.');
            }
            const localVarPath = `/dossiers-location/{ref}/dossiers-particuliers/{refPers}/situation-professionnelle`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)))
                .replace(`{${"refPers"}}`, encodeURIComponent(String(refPers)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"SituationProfessionnelleDossierParticulier" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Récupérer les références des dossiers particuliers d\'un dossier de location par sa référence
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefDossiersParticuliersReferencesGet(ref: string, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling dossiersLocationRefDossiersParticuliersReferencesGet.');
            }
            const localVarPath = `/dossiers-location/{ref}/dossiers-particuliers/references`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generer certificat d\'agrement
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefGenererCertificatGet(ref: string, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling dossiersLocationRefGenererCertificatGet.');
            }
            const localVarPath = `/dossiers-location/{ref}/generer-certificat`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Récupérer un dossier de location par sa référence
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefGet(ref: string, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling dossiersLocationRefGet.');
            }
            const localVarPath = `/dossiers-location/{ref}`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modifier un dossier de location par sa référence
         * @param {string} ref Réference du dossier de location
         * @param {DossierLocation} body Caractéristique du dossier de location à modifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefPut(ref: string, body: DossierLocation, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling dossiersLocationRefPut.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling dossiersLocationRefPut.');
            }
            const localVarPath = `/dossiers-location/{ref}`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"DossierLocation" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dupliquer dossier location
         * @param {string} ref Réference du dossier de location à dupliquer
         * @param {Bien} body Bien associé au nouveau dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dupliquerDossierLocation(ref: string, body: Bien, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling dupliquerDossierLocation.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling dupliquerDossierLocation.');
            }
            const localVarPath = `/dossiers-location/{ref}/dupliquer`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Bien" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Forcer le statut du dossier location
         * @param {string} ref Réference du dossier de location à dupliquer
         * @param {InlineObject} statut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forcerStatutDossierLocation(ref: string, statut: InlineObject, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling forcerStatutDossierLocation.');
            }
            // verify required parameter 'statut' is not null or undefined
            if (statut === null || statut === undefined) {
                throw new RequiredError('statut','Required parameter statut was null or undefined when calling forcerStatutDossierLocation.');
            }
            const localVarPath = `/dossiers-location/{ref}/forcer-statut`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"InlineObject" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(statut !== undefined ? statut : {}) : (statut || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Récupérer l\'archive des pièces justificatives
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchive(ref: string, refPers: string, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling getArchive.');
            }
            // verify required parameter 'refPers' is not null or undefined
            if (refPers === null || refPers === undefined) {
                throw new RequiredError('refPers','Required parameter refPers was null or undefined when calling getArchive.');
            }
            const localVarPath = `/dossiers-location/{ref}/dossiers-particuliers/{refPers}/archive`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)))
                .replace(`{${"refPers"}}`, encodeURIComponent(String(refPers)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Récuperer le dépôt du partenaire
         * @param {string} ref Référence du dossier de location
         * @param {string} [dateNaissance] Filtre sur la date de naissance du candidat - Format YYYY-MM-DD
         * @param {string} [nom] Filtre sur le nom du candidat
         * @param {string} [prenom] Filtre sur le prenom du candidat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepotPartenaire(ref: string, dateNaissance?: string, nom?: string, prenom?: string, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling getDepotPartenaire.');
            }
            const localVarPath = `/dossiers-location/{ref}/depot-partenaire`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateNaissance !== undefined) {
                localVarQueryParameter['dateNaissance'] = dateNaissance;
            }

            if (nom !== undefined) {
                localVarQueryParameter['nom'] = nom;
            }

            if (prenom !== undefined) {
                localVarQueryParameter['prenom'] = prenom;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Récupération d\'un dossier personne
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossierParticulierByDossierLocationAndReference(ref: string, refPers: string, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling getDossierParticulierByDossierLocationAndReference.');
            }
            // verify required parameter 'refPers' is not null or undefined
            if (refPers === null || refPers === undefined) {
                throw new RequiredError('refPers','Required parameter refPers was null or undefined when calling getDossierParticulierByDossierLocationAndReference.');
            }
            const localVarPath = `/dossiers-location/{ref}/dossiers-particuliers/{refPers}`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)))
                .replace(`{${"refPers"}}`, encodeURIComponent(String(refPers)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Récupérer la liste des dossiers de location
         * @param {number} [adb] Uniquement pour un utilisateur Bessé - ignoré sinon. Id de l\&#39;AdB en cours. Les résultats concerneront uniquement cet AdB, ainsi que les AdB de son regroupement le cas échéant.
         * @param {string} [refDossier] 
         * @param {string} [refMandat] 
         * @param {string} [ville] 
         * @param {string} [statut] 
         * @param {boolean} [gli] 
         * @param {number} [site] 
         * @param {string} [nomLocataire] 
         * @param {string} [prenomLocataire] 
         * @param {number} [page] L\&#39;index de la page (l\&#39;index de la première page est 0)
         * @param {number} [taille] Le nombre d\&#39;éléments à retourner
         * @param {Array<string>} [tri] Format : attribut1:asc,attribut2:desc   Nom de l\&#39;attribut égal au nom du champ envoyé
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossiersLocation(adb?: number, refDossier?: string, refMandat?: string, ville?: string, statut?: string, gli?: boolean, site?: number, nomLocataire?: string, prenomLocataire?: string, page?: number, taille?: number, tri?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/dossiers-location`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (adb !== undefined) {
                localVarQueryParameter['adb'] = adb;
            }

            if (refDossier !== undefined) {
                localVarQueryParameter['refDossier'] = refDossier;
            }

            if (refMandat !== undefined) {
                localVarQueryParameter['refMandat'] = refMandat;
            }

            if (ville !== undefined) {
                localVarQueryParameter['ville'] = ville;
            }

            if (statut !== undefined) {
                localVarQueryParameter['statut'] = statut;
            }

            if (gli !== undefined) {
                localVarQueryParameter['gli'] = gli;
            }

            if (site !== undefined) {
                localVarQueryParameter['site'] = site;
            }

            if (nomLocataire !== undefined) {
                localVarQueryParameter['nomLocataire'] = nomLocataire;
            }

            if (prenomLocataire !== undefined) {
                localVarQueryParameter['prenomLocataire'] = prenomLocataire;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (taille !== undefined) {
                localVarQueryParameter['taille'] = taille;
            }

            if (tri) {
                localVarQueryParameter['tri'] = tri.join(COLLECTION_FORMATS.csv);
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Récupérer le rapport d\'algo
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRapportAlgo(ref: string, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling getRapportAlgo.');
            }
            const localVarPath = `/dossiers-location/{ref}/rapport-algo`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lancer l\'algo
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lancerAlgo(ref: string, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling lancerAlgo.');
            }
            const localVarPath = `/dossiers-location/{ref}/lancer-algo`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Permet de récupérer la liste des pièces justificatives à fournir pour le dossier de location et le dossier particulier passés en paramètre dans l\'url. Le lien entre les deux dossiers est vérifié afin d\'ajouter de la sécurité à l\'appel.
         * @summary Connaitre les pièces justificatives à fournir pour une personne
         * @param {string} ref Réference du dossier de location
         * @param {string} refPers Réference du dossier particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listePiecesGet(ref: string, refPers: string, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling listePiecesGet.');
            }
            // verify required parameter 'refPers' is not null or undefined
            if (refPers === null || refPers === undefined) {
                throw new RequiredError('refPers','Required parameter refPers was null or undefined when calling listePiecesGet.');
            }
            const localVarPath = `/dossiers-location/{ref}/dossiers-particuliers/{refPers}/pieces-justificatives`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)))
                .replace(`{${"refPers"}}`, encodeURIComponent(String(refPers)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ajout piece
         * @summary ajout piece
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {Array<DetailPiece>} body Données à mettre à jour
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAjouterPiece(ref: string, refPers: string, body: Array<DetailPiece>, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling postAjouterPiece.');
            }
            // verify required parameter 'refPers' is not null or undefined
            if (refPers === null || refPers === undefined) {
                throw new RequiredError('refPers','Required parameter refPers was null or undefined when calling postAjouterPiece.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postAjouterPiece.');
            }
            const localVarPath = `/dossiers-location/{ref}/dossiers-particuliers/{refPers}/ajouter-piece`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)))
                .replace(`{${"refPers"}}`, encodeURIComponent(String(refPers)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Array&lt;DetailPiece&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ajout dans un dépôt les infos pour un candidat et ses pièces associées
         * @param {string} ref Référence du dossier de location
         * @param {DepotPartenaire} body Données du dépôt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDepotPartenaire(ref: string, body: DepotPartenaire, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling postDepotPartenaire.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postDepotPartenaire.');
            }
            const localVarPath = `/dossiers-location/{ref}/depot-partenaire`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"DepotPartenaire" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary finalise le transfert du dossier location depuis un partenaire
         * @param {string} ref Référence du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFinaliserTransfert(ref: string, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling postFinaliserTransfert.');
            }
            const localVarPath = `/dossiers-location/{ref}/finaliser-transfert`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajoute ou remplace une archive de pièces pour une personne du dossier
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {Fichier} archive Archive à ajouter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putArchivePieces(ref: string, refPers: string, archive: Fichier, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling putArchivePieces.');
            }
            // verify required parameter 'refPers' is not null or undefined
            if (refPers === null || refPers === undefined) {
                throw new RequiredError('refPers','Required parameter refPers was null or undefined when calling putArchivePieces.');
            }
            // verify required parameter 'archive' is not null or undefined
            if (archive === null || archive === undefined) {
                throw new RequiredError('archive','Required parameter archive was null or undefined when calling putArchivePieces.');
            }
            const localVarPath = `/dossiers-location/{ref}/dossiers-particuliers/{refPers}/archive-pieces`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)))
                .replace(`{${"refPers"}}`, encodeURIComponent(String(refPers)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Fichier" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(archive !== undefined ? archive : {}) : (archive || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ce service permet de réouvrir un dossier validé avec erreurs (etat = waiting)
         * @summary Réouvrir le dossier pieces validé avec erreurs
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reouvrirDossierPieces(ref: string, refPers: string, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling reouvrirDossierPieces.');
            }
            // verify required parameter 'refPers' is not null or undefined
            if (refPers === null || refPers === undefined) {
                throw new RequiredError('refPers','Required parameter refPers was null or undefined when calling reouvrirDossierPieces.');
            }
            const localVarPath = `/dossiers-location/{ref}/dossiers-particuliers/{refPers}/reouvrir-dossier-pieces`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)))
                .replace(`{${"refPers"}}`, encodeURIComponent(String(refPers)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ce service permet de synchroniser l\'état du dossier Vertuloo et la liste des pièces justificatives avec celui du dossier pieces
         * @summary Synchroniser le dossier Vertuloo avec celui de pieces
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchroniserPieces(ref: string, refPers: string, options: any = {}): RequestArgs {
            // verify required parameter 'ref' is not null or undefined
            if (ref === null || ref === undefined) {
                throw new RequiredError('ref','Required parameter ref was null or undefined when calling synchroniserPieces.');
            }
            // verify required parameter 'refPers' is not null or undefined
            if (refPers === null || refPers === undefined) {
                throw new RequiredError('refPers','Required parameter refPers was null or undefined when calling synchroniserPieces.');
            }
            const localVarPath = `/dossiers-location/{ref}/dossiers-particuliers/{refPers}/synchroniser-pieces`
                .replace(`{${"ref"}}`, encodeURIComponent(String(ref)))
                .replace(`{${"refPers"}}`, encodeURIComponent(String(refPers)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DossiersLocationsApi - functional programming interface
 * @export
 */
export const DossiersLocationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Acceptation de la location
         * @param {string} ref Référence du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accepterLocation(ref: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetourLocationAcceptee> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).accepterLocation(ref, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Annulation de l\'acceptation de la location
         * @param {string} ref Référence du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annulerAcceptationLocation(ref: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).annulerAcceptationLocation(ref, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Création d\'un dossier de location
         * @param {DossierLocation} body Caractéristique du dossier de location à créer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationPost(body: DossierLocation, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierLocation> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).dossiersLocationPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Annuler le statut du dossier de location
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefAnnulerDossierPut(ref: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).dossiersLocationRefAnnulerDossierPut(ref, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Création d\'un bien
         * @param {string} ref Référence du dossier de location
         * @param {Bien} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefBienPost(ref: string, body?: Bien, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BienDetail> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).dossiersLocationRefBienPost(ref, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Modification d\'un bien
         * @param {string} ref Référence du dossier de location
         * @param {Bien} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefBienPut(ref: string, body?: Bien, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BienDetail> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).dossiersLocationRefBienPut(ref, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Les informations professionnelles ne sont pas obligatoires. Si elles sont présentes et pertinentes, des contrôles sont effectués dessus. Les données non pertinentes sont totalement ignorées. Il s\'agit par exemple du nombre de mois travaillés selon le statut, ou certains salaires ou cumuls de salaires selon le statut et la date d\'entrée.
         * @summary Création de dossier personne
         * @param {string} ref Référence du dossier de location
         * @param {DossierParticulier} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefDossiersParticuliersPost(ref: string, body?: DossierParticulier, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierParticulier> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).dossiersLocationRefDossiersParticuliersPost(ref, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Mise à jour de la situation personnelle d\'un dossier personne
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {SituationPersonnelleDossierParticulier} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefDossiersParticuliersRefPersSituationPersonnellePut(ref: string, refPers: string, body?: SituationPersonnelleDossierParticulier, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).dossiersLocationRefDossiersParticuliersRefPersSituationPersonnellePut(ref, refPers, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Les données non pertinentes sont totalement ignorées. Il s\'agit par exemple du nombre de mois travaillés selon le statut, ou certains salaires ou cumuls de salaires selon le statut et la date d\'entrée.
         * @summary Mise à jour de la situation professionnelle d\'un dossier personne
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {SituationProfessionnelleDossierParticulier} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefDossiersParticuliersRefPersSituationProfessionnellePut(ref: string, refPers: string, body?: SituationProfessionnelleDossierParticulier, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierPiecesId> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).dossiersLocationRefDossiersParticuliersRefPersSituationProfessionnellePut(ref, refPers, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Récupérer les références des dossiers particuliers d\'un dossier de location par sa référence
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefDossiersParticuliersReferencesGet(ref: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).dossiersLocationRefDossiersParticuliersReferencesGet(ref, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary generer certificat d\'agrement
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefGenererCertificatGet(ref: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).dossiersLocationRefGenererCertificatGet(ref, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Récupérer un dossier de location par sa référence
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefGet(ref: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierLocation> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).dossiersLocationRefGet(ref, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Modifier un dossier de location par sa référence
         * @param {string} ref Réference du dossier de location
         * @param {DossierLocation} body Caractéristique du dossier de location à modifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefPut(ref: string, body: DossierLocation, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).dossiersLocationRefPut(ref, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Dupliquer dossier location
         * @param {string} ref Réference du dossier de location à dupliquer
         * @param {Bien} body Bien associé au nouveau dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dupliquerDossierLocation(ref: string, body: Bien, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierLocation> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).dupliquerDossierLocation(ref, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Forcer le statut du dossier location
         * @param {string} ref Réference du dossier de location à dupliquer
         * @param {InlineObject} statut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forcerStatutDossierLocation(ref: string, statut: InlineObject, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).forcerStatutDossierLocation(ref, statut, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Récupérer l\'archive des pièces justificatives
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchive(ref: string, refPers: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).getArchive(ref, refPers, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Récuperer le dépôt du partenaire
         * @param {string} ref Référence du dossier de location
         * @param {string} [dateNaissance] Filtre sur la date de naissance du candidat - Format YYYY-MM-DD
         * @param {string} [nom] Filtre sur le nom du candidat
         * @param {string} [prenom] Filtre sur le prenom du candidat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepotPartenaire(ref: string, dateNaissance?: string, nom?: string, prenom?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDepotPartenaire> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).getDepotPartenaire(ref, dateNaissance, nom, prenom, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Récupération d\'un dossier personne
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossierParticulierByDossierLocationAndReference(ref: string, refPers: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierParticulier> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).getDossierParticulierByDossierLocationAndReference(ref, refPers, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Récupérer la liste des dossiers de location
         * @param {number} [adb] Uniquement pour un utilisateur Bessé - ignoré sinon. Id de l\&#39;AdB en cours. Les résultats concerneront uniquement cet AdB, ainsi que les AdB de son regroupement le cas échéant.
         * @param {string} [refDossier] 
         * @param {string} [refMandat] 
         * @param {string} [ville] 
         * @param {string} [statut] 
         * @param {boolean} [gli] 
         * @param {number} [site] 
         * @param {string} [nomLocataire] 
         * @param {string} [prenomLocataire] 
         * @param {number} [page] L\&#39;index de la page (l\&#39;index de la première page est 0)
         * @param {number} [taille] Le nombre d\&#39;éléments à retourner
         * @param {Array<string>} [tri] Format : attribut1:asc,attribut2:desc   Nom de l\&#39;attribut égal au nom du champ envoyé
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossiersLocation(adb?: number, refDossier?: string, refMandat?: string, ville?: string, statut?: string, gli?: boolean, site?: number, nomLocataire?: string, prenomLocataire?: string, page?: number, taille?: number, tri?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDossiersLocation> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).getDossiersLocation(adb, refDossier, refMandat, ville, statut, gli, site, nomLocataire, prenomLocataire, page, taille, tri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Récupérer le rapport d\'algo
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRapportAlgo(ref: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RapportCalculAlgo> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).getRapportAlgo(ref, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Lancer l\'algo
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lancerAlgo(ref: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).lancerAlgo(ref, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Permet de récupérer la liste des pièces justificatives à fournir pour le dossier de location et le dossier particulier passés en paramètre dans l\'url. Le lien entre les deux dossiers est vérifié afin d\'ajouter de la sécurité à l\'appel.
         * @summary Connaitre les pièces justificatives à fournir pour une personne
         * @param {string} ref Réference du dossier de location
         * @param {string} refPers Réference du dossier particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listePiecesGet(ref: string, refPers: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PiecesJustificatives> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).listePiecesGet(ref, refPers, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ajout piece
         * @summary ajout piece
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {Array<DetailPiece>} body Données à mettre à jour
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAjouterPiece(ref: string, refPers: string, body: Array<DetailPiece>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).postAjouterPiece(ref, refPers, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Ajout dans un dépôt les infos pour un candidat et ses pièces associées
         * @param {string} ref Référence du dossier de location
         * @param {DepotPartenaire} body Données du dépôt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDepotPartenaire(ref: string, body: DepotPartenaire, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PiecesJustificativePartenaire>> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).postDepotPartenaire(ref, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary finalise le transfert du dossier location depuis un partenaire
         * @param {string} ref Référence du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFinaliserTransfert(ref: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).postFinaliserTransfert(ref, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary ajoute ou remplace une archive de pièces pour une personne du dossier
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {Fichier} archive Archive à ajouter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putArchivePieces(ref: string, refPers: string, archive: Fichier, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).putArchivePieces(ref, refPers, archive, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Ce service permet de réouvrir un dossier validé avec erreurs (etat = waiting)
         * @summary Réouvrir le dossier pieces validé avec erreurs
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reouvrirDossierPieces(ref: string, refPers: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).reouvrirDossierPieces(ref, refPers, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Ce service permet de synchroniser l\'état du dossier Vertuloo et la liste des pièces justificatives avec celui du dossier pieces
         * @summary Synchroniser le dossier Vertuloo avec celui de pieces
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchroniserPieces(ref: string, refPers: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierParticulierDetail> {
            const localVarAxiosArgs = DossiersLocationsApiAxiosParamCreator(configuration).synchroniserPieces(ref, refPers, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DossiersLocationsApi - factory interface
 * @export
 */
export const DossiersLocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Acceptation de la location
         * @param {string} ref Référence du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accepterLocation(ref: string, options?: any) {
            return DossiersLocationsApiFp(configuration).accepterLocation(ref, options)(axios, basePath);
        },
        /**
         * 
         * @summary Annulation de l\'acceptation de la location
         * @param {string} ref Référence du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annulerAcceptationLocation(ref: string, options?: any) {
            return DossiersLocationsApiFp(configuration).annulerAcceptationLocation(ref, options)(axios, basePath);
        },
        /**
         * 
         * @summary Création d\'un dossier de location
         * @param {DossierLocation} body Caractéristique du dossier de location à créer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationPost(body: DossierLocation, options?: any) {
            return DossiersLocationsApiFp(configuration).dossiersLocationPost(body, options)(axios, basePath);
        },
        /**
         * 
         * @summary Annuler le statut du dossier de location
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefAnnulerDossierPut(ref: string, options?: any) {
            return DossiersLocationsApiFp(configuration).dossiersLocationRefAnnulerDossierPut(ref, options)(axios, basePath);
        },
        /**
         * 
         * @summary Création d\'un bien
         * @param {string} ref Référence du dossier de location
         * @param {Bien} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefBienPost(ref: string, body?: Bien, options?: any) {
            return DossiersLocationsApiFp(configuration).dossiersLocationRefBienPost(ref, body, options)(axios, basePath);
        },
        /**
         * 
         * @summary Modification d\'un bien
         * @param {string} ref Référence du dossier de location
         * @param {Bien} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefBienPut(ref: string, body?: Bien, options?: any) {
            return DossiersLocationsApiFp(configuration).dossiersLocationRefBienPut(ref, body, options)(axios, basePath);
        },
        /**
         * Les informations professionnelles ne sont pas obligatoires. Si elles sont présentes et pertinentes, des contrôles sont effectués dessus. Les données non pertinentes sont totalement ignorées. Il s\'agit par exemple du nombre de mois travaillés selon le statut, ou certains salaires ou cumuls de salaires selon le statut et la date d\'entrée.
         * @summary Création de dossier personne
         * @param {string} ref Référence du dossier de location
         * @param {DossierParticulier} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefDossiersParticuliersPost(ref: string, body?: DossierParticulier, options?: any) {
            return DossiersLocationsApiFp(configuration).dossiersLocationRefDossiersParticuliersPost(ref, body, options)(axios, basePath);
        },
        /**
         * 
         * @summary Mise à jour de la situation personnelle d\'un dossier personne
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {SituationPersonnelleDossierParticulier} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefDossiersParticuliersRefPersSituationPersonnellePut(ref: string, refPers: string, body?: SituationPersonnelleDossierParticulier, options?: any) {
            return DossiersLocationsApiFp(configuration).dossiersLocationRefDossiersParticuliersRefPersSituationPersonnellePut(ref, refPers, body, options)(axios, basePath);
        },
        /**
         * Les données non pertinentes sont totalement ignorées. Il s\'agit par exemple du nombre de mois travaillés selon le statut, ou certains salaires ou cumuls de salaires selon le statut et la date d\'entrée.
         * @summary Mise à jour de la situation professionnelle d\'un dossier personne
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {SituationProfessionnelleDossierParticulier} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefDossiersParticuliersRefPersSituationProfessionnellePut(ref: string, refPers: string, body?: SituationProfessionnelleDossierParticulier, options?: any) {
            return DossiersLocationsApiFp(configuration).dossiersLocationRefDossiersParticuliersRefPersSituationProfessionnellePut(ref, refPers, body, options)(axios, basePath);
        },
        /**
         * 
         * @summary Récupérer les références des dossiers particuliers d\'un dossier de location par sa référence
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefDossiersParticuliersReferencesGet(ref: string, options?: any) {
            return DossiersLocationsApiFp(configuration).dossiersLocationRefDossiersParticuliersReferencesGet(ref, options)(axios, basePath);
        },
        /**
         * 
         * @summary generer certificat d\'agrement
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefGenererCertificatGet(ref: string, options?: any) {
            return DossiersLocationsApiFp(configuration).dossiersLocationRefGenererCertificatGet(ref, options)(axios, basePath);
        },
        /**
         * 
         * @summary Récupérer un dossier de location par sa référence
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefGet(ref: string, options?: any) {
            return DossiersLocationsApiFp(configuration).dossiersLocationRefGet(ref, options)(axios, basePath);
        },
        /**
         * 
         * @summary Modifier un dossier de location par sa référence
         * @param {string} ref Réference du dossier de location
         * @param {DossierLocation} body Caractéristique du dossier de location à modifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dossiersLocationRefPut(ref: string, body: DossierLocation, options?: any) {
            return DossiersLocationsApiFp(configuration).dossiersLocationRefPut(ref, body, options)(axios, basePath);
        },
        /**
         * 
         * @summary Dupliquer dossier location
         * @param {string} ref Réference du dossier de location à dupliquer
         * @param {Bien} body Bien associé au nouveau dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dupliquerDossierLocation(ref: string, body: Bien, options?: any) {
            return DossiersLocationsApiFp(configuration).dupliquerDossierLocation(ref, body, options)(axios, basePath);
        },
        /**
         * 
         * @summary Forcer le statut du dossier location
         * @param {string} ref Réference du dossier de location à dupliquer
         * @param {InlineObject} statut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forcerStatutDossierLocation(ref: string, statut: InlineObject, options?: any) {
            return DossiersLocationsApiFp(configuration).forcerStatutDossierLocation(ref, statut, options)(axios, basePath);
        },
        /**
         * 
         * @summary Récupérer l\'archive des pièces justificatives
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchive(ref: string, refPers: string, options?: any) {
            return DossiersLocationsApiFp(configuration).getArchive(ref, refPers, options)(axios, basePath);
        },
        /**
         * 
         * @summary Récuperer le dépôt du partenaire
         * @param {string} ref Référence du dossier de location
         * @param {string} [dateNaissance] Filtre sur la date de naissance du candidat - Format YYYY-MM-DD
         * @param {string} [nom] Filtre sur le nom du candidat
         * @param {string} [prenom] Filtre sur le prenom du candidat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepotPartenaire(ref: string, dateNaissance?: string, nom?: string, prenom?: string, options?: any) {
            return DossiersLocationsApiFp(configuration).getDepotPartenaire(ref, dateNaissance, nom, prenom, options)(axios, basePath);
        },
        /**
         * 
         * @summary Récupération d\'un dossier personne
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossierParticulierByDossierLocationAndReference(ref: string, refPers: string, options?: any) {
            return DossiersLocationsApiFp(configuration).getDossierParticulierByDossierLocationAndReference(ref, refPers, options)(axios, basePath);
        },
        /**
         * 
         * @summary Récupérer la liste des dossiers de location
         * @param {number} [adb] Uniquement pour un utilisateur Bessé - ignoré sinon. Id de l\&#39;AdB en cours. Les résultats concerneront uniquement cet AdB, ainsi que les AdB de son regroupement le cas échéant.
         * @param {string} [refDossier] 
         * @param {string} [refMandat] 
         * @param {string} [ville] 
         * @param {string} [statut] 
         * @param {boolean} [gli] 
         * @param {number} [site] 
         * @param {string} [nomLocataire] 
         * @param {string} [prenomLocataire] 
         * @param {number} [page] L\&#39;index de la page (l\&#39;index de la première page est 0)
         * @param {number} [taille] Le nombre d\&#39;éléments à retourner
         * @param {Array<string>} [tri] Format : attribut1:asc,attribut2:desc   Nom de l\&#39;attribut égal au nom du champ envoyé
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDossiersLocation(adb?: number, refDossier?: string, refMandat?: string, ville?: string, statut?: string, gli?: boolean, site?: number, nomLocataire?: string, prenomLocataire?: string, page?: number, taille?: number, tri?: Array<string>, options?: any) {
            return DossiersLocationsApiFp(configuration).getDossiersLocation(adb, refDossier, refMandat, ville, statut, gli, site, nomLocataire, prenomLocataire, page, taille, tri, options)(axios, basePath);
        },
        /**
         * 
         * @summary Récupérer le rapport d\'algo
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRapportAlgo(ref: string, options?: any) {
            return DossiersLocationsApiFp(configuration).getRapportAlgo(ref, options)(axios, basePath);
        },
        /**
         * 
         * @summary Lancer l\'algo
         * @param {string} ref Réference du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lancerAlgo(ref: string, options?: any) {
            return DossiersLocationsApiFp(configuration).lancerAlgo(ref, options)(axios, basePath);
        },
        /**
         * Permet de récupérer la liste des pièces justificatives à fournir pour le dossier de location et le dossier particulier passés en paramètre dans l\'url. Le lien entre les deux dossiers est vérifié afin d\'ajouter de la sécurité à l\'appel.
         * @summary Connaitre les pièces justificatives à fournir pour une personne
         * @param {string} ref Réference du dossier de location
         * @param {string} refPers Réference du dossier particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listePiecesGet(ref: string, refPers: string, options?: any) {
            return DossiersLocationsApiFp(configuration).listePiecesGet(ref, refPers, options)(axios, basePath);
        },
        /**
         * ajout piece
         * @summary ajout piece
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {Array<DetailPiece>} body Données à mettre à jour
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAjouterPiece(ref: string, refPers: string, body: Array<DetailPiece>, options?: any) {
            return DossiersLocationsApiFp(configuration).postAjouterPiece(ref, refPers, body, options)(axios, basePath);
        },
        /**
         * 
         * @summary Ajout dans un dépôt les infos pour un candidat et ses pièces associées
         * @param {string} ref Référence du dossier de location
         * @param {DepotPartenaire} body Données du dépôt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDepotPartenaire(ref: string, body: DepotPartenaire, options?: any) {
            return DossiersLocationsApiFp(configuration).postDepotPartenaire(ref, body, options)(axios, basePath);
        },
        /**
         * 
         * @summary finalise le transfert du dossier location depuis un partenaire
         * @param {string} ref Référence du dossier de location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFinaliserTransfert(ref: string, options?: any) {
            return DossiersLocationsApiFp(configuration).postFinaliserTransfert(ref, options)(axios, basePath);
        },
        /**
         * 
         * @summary ajoute ou remplace une archive de pièces pour une personne du dossier
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {Fichier} archive Archive à ajouter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putArchivePieces(ref: string, refPers: string, archive: Fichier, options?: any) {
            return DossiersLocationsApiFp(configuration).putArchivePieces(ref, refPers, archive, options)(axios, basePath);
        },
        /**
         * Ce service permet de réouvrir un dossier validé avec erreurs (etat = waiting)
         * @summary Réouvrir le dossier pieces validé avec erreurs
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reouvrirDossierPieces(ref: string, refPers: string, options?: any) {
            return DossiersLocationsApiFp(configuration).reouvrirDossierPieces(ref, refPers, options)(axios, basePath);
        },
        /**
         * Ce service permet de synchroniser l\'état du dossier Vertuloo et la liste des pièces justificatives avec celui du dossier pieces
         * @summary Synchroniser le dossier Vertuloo avec celui de pieces
         * @param {string} ref Référence du dossier de location
         * @param {string} refPers Référence du dossier particulier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchroniserPieces(ref: string, refPers: string, options?: any) {
            return DossiersLocationsApiFp(configuration).synchroniserPieces(ref, refPers, options)(axios, basePath);
        },
    };
};

/**
 * DossiersLocationsApi - object-oriented interface
 * @export
 * @class DossiersLocationsApi
 * @extends {BaseAPI}
 */
export class DossiersLocationsApi extends BaseAPI {
    /**
     * 
     * @summary Acceptation de la location
     * @param {string} ref Référence du dossier de location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public accepterLocation(ref: string, options?: any) {
        return DossiersLocationsApiFp(this.configuration).accepterLocation(ref, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Annulation de l\'acceptation de la location
     * @param {string} ref Référence du dossier de location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public annulerAcceptationLocation(ref: string, options?: any) {
        return DossiersLocationsApiFp(this.configuration).annulerAcceptationLocation(ref, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Création d\'un dossier de location
     * @param {DossierLocation} body Caractéristique du dossier de location à créer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public dossiersLocationPost(body: DossierLocation, options?: any) {
        return DossiersLocationsApiFp(this.configuration).dossiersLocationPost(body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Annuler le statut du dossier de location
     * @param {string} ref Réference du dossier de location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public dossiersLocationRefAnnulerDossierPut(ref: string, options?: any) {
        return DossiersLocationsApiFp(this.configuration).dossiersLocationRefAnnulerDossierPut(ref, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Création d\'un bien
     * @param {string} ref Référence du dossier de location
     * @param {Bien} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public dossiersLocationRefBienPost(ref: string, body?: Bien, options?: any) {
        return DossiersLocationsApiFp(this.configuration).dossiersLocationRefBienPost(ref, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Modification d\'un bien
     * @param {string} ref Référence du dossier de location
     * @param {Bien} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public dossiersLocationRefBienPut(ref: string, body?: Bien, options?: any) {
        return DossiersLocationsApiFp(this.configuration).dossiersLocationRefBienPut(ref, body, options)(this.axios, this.basePath);
    }

    /**
     * Les informations professionnelles ne sont pas obligatoires. Si elles sont présentes et pertinentes, des contrôles sont effectués dessus. Les données non pertinentes sont totalement ignorées. Il s\'agit par exemple du nombre de mois travaillés selon le statut, ou certains salaires ou cumuls de salaires selon le statut et la date d\'entrée.
     * @summary Création de dossier personne
     * @param {string} ref Référence du dossier de location
     * @param {DossierParticulier} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public dossiersLocationRefDossiersParticuliersPost(ref: string, body?: DossierParticulier, options?: any) {
        return DossiersLocationsApiFp(this.configuration).dossiersLocationRefDossiersParticuliersPost(ref, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Mise à jour de la situation personnelle d\'un dossier personne
     * @param {string} ref Référence du dossier de location
     * @param {string} refPers Référence du dossier particulier
     * @param {SituationPersonnelleDossierParticulier} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public dossiersLocationRefDossiersParticuliersRefPersSituationPersonnellePut(ref: string, refPers: string, body?: SituationPersonnelleDossierParticulier, options?: any) {
        return DossiersLocationsApiFp(this.configuration).dossiersLocationRefDossiersParticuliersRefPersSituationPersonnellePut(ref, refPers, body, options)(this.axios, this.basePath);
    }

    /**
     * Les données non pertinentes sont totalement ignorées. Il s\'agit par exemple du nombre de mois travaillés selon le statut, ou certains salaires ou cumuls de salaires selon le statut et la date d\'entrée.
     * @summary Mise à jour de la situation professionnelle d\'un dossier personne
     * @param {string} ref Référence du dossier de location
     * @param {string} refPers Référence du dossier particulier
     * @param {SituationProfessionnelleDossierParticulier} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public dossiersLocationRefDossiersParticuliersRefPersSituationProfessionnellePut(ref: string, refPers: string, body?: SituationProfessionnelleDossierParticulier, options?: any) {
        return DossiersLocationsApiFp(this.configuration).dossiersLocationRefDossiersParticuliersRefPersSituationProfessionnellePut(ref, refPers, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Récupérer les références des dossiers particuliers d\'un dossier de location par sa référence
     * @param {string} ref Réference du dossier de location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public dossiersLocationRefDossiersParticuliersReferencesGet(ref: string, options?: any) {
        return DossiersLocationsApiFp(this.configuration).dossiersLocationRefDossiersParticuliersReferencesGet(ref, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary generer certificat d\'agrement
     * @param {string} ref Réference du dossier de location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public dossiersLocationRefGenererCertificatGet(ref: string, options?: any) {
        return DossiersLocationsApiFp(this.configuration).dossiersLocationRefGenererCertificatGet(ref, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Récupérer un dossier de location par sa référence
     * @param {string} ref Réference du dossier de location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public dossiersLocationRefGet(ref: string, options?: any) {
        return DossiersLocationsApiFp(this.configuration).dossiersLocationRefGet(ref, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Modifier un dossier de location par sa référence
     * @param {string} ref Réference du dossier de location
     * @param {DossierLocation} body Caractéristique du dossier de location à modifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public dossiersLocationRefPut(ref: string, body: DossierLocation, options?: any) {
        return DossiersLocationsApiFp(this.configuration).dossiersLocationRefPut(ref, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Dupliquer dossier location
     * @param {string} ref Réference du dossier de location à dupliquer
     * @param {Bien} body Bien associé au nouveau dossier de location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public dupliquerDossierLocation(ref: string, body: Bien, options?: any) {
        return DossiersLocationsApiFp(this.configuration).dupliquerDossierLocation(ref, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Forcer le statut du dossier location
     * @param {string} ref Réference du dossier de location à dupliquer
     * @param {InlineObject} statut 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public forcerStatutDossierLocation(ref: string, statut: InlineObject, options?: any) {
        return DossiersLocationsApiFp(this.configuration).forcerStatutDossierLocation(ref, statut, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Récupérer l\'archive des pièces justificatives
     * @param {string} ref Référence du dossier de location
     * @param {string} refPers Référence du dossier particulier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public getArchive(ref: string, refPers: string, options?: any) {
        return DossiersLocationsApiFp(this.configuration).getArchive(ref, refPers, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Récuperer le dépôt du partenaire
     * @param {string} ref Référence du dossier de location
     * @param {string} [dateNaissance] Filtre sur la date de naissance du candidat - Format YYYY-MM-DD
     * @param {string} [nom] Filtre sur le nom du candidat
     * @param {string} [prenom] Filtre sur le prenom du candidat
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public getDepotPartenaire(ref: string, dateNaissance?: string, nom?: string, prenom?: string, options?: any) {
        return DossiersLocationsApiFp(this.configuration).getDepotPartenaire(ref, dateNaissance, nom, prenom, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Récupération d\'un dossier personne
     * @param {string} ref Référence du dossier de location
     * @param {string} refPers Référence du dossier particulier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public getDossierParticulierByDossierLocationAndReference(ref: string, refPers: string, options?: any) {
        return DossiersLocationsApiFp(this.configuration).getDossierParticulierByDossierLocationAndReference(ref, refPers, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Récupérer la liste des dossiers de location
     * @param {number} [adb] Uniquement pour un utilisateur Bessé - ignoré sinon. Id de l\&#39;AdB en cours. Les résultats concerneront uniquement cet AdB, ainsi que les AdB de son regroupement le cas échéant.
     * @param {string} [refDossier] 
     * @param {string} [refMandat] 
     * @param {string} [ville] 
     * @param {string} [statut] 
     * @param {boolean} [gli] 
     * @param {number} [site] 
     * @param {string} [nomLocataire] 
     * @param {string} [prenomLocataire] 
     * @param {number} [page] L\&#39;index de la page (l\&#39;index de la première page est 0)
     * @param {number} [taille] Le nombre d\&#39;éléments à retourner
     * @param {Array<string>} [tri] Format : attribut1:asc,attribut2:desc   Nom de l\&#39;attribut égal au nom du champ envoyé
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public getDossiersLocation(adb?: number, refDossier?: string, refMandat?: string, ville?: string, statut?: string, gli?: boolean, site?: number, nomLocataire?: string, prenomLocataire?: string, page?: number, taille?: number, tri?: Array<string>, options?: any) {
        return DossiersLocationsApiFp(this.configuration).getDossiersLocation(adb, refDossier, refMandat, ville, statut, gli, site, nomLocataire, prenomLocataire, page, taille, tri, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Récupérer le rapport d\'algo
     * @param {string} ref Réference du dossier de location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public getRapportAlgo(ref: string, options?: any) {
        return DossiersLocationsApiFp(this.configuration).getRapportAlgo(ref, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Lancer l\'algo
     * @param {string} ref Réference du dossier de location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public lancerAlgo(ref: string, options?: any) {
        return DossiersLocationsApiFp(this.configuration).lancerAlgo(ref, options)(this.axios, this.basePath);
    }

    /**
     * Permet de récupérer la liste des pièces justificatives à fournir pour le dossier de location et le dossier particulier passés en paramètre dans l\'url. Le lien entre les deux dossiers est vérifié afin d\'ajouter de la sécurité à l\'appel.
     * @summary Connaitre les pièces justificatives à fournir pour une personne
     * @param {string} ref Réference du dossier de location
     * @param {string} refPers Réference du dossier particulier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public listePiecesGet(ref: string, refPers: string, options?: any) {
        return DossiersLocationsApiFp(this.configuration).listePiecesGet(ref, refPers, options)(this.axios, this.basePath);
    }

    /**
     * ajout piece
     * @summary ajout piece
     * @param {string} ref Référence du dossier de location
     * @param {string} refPers Référence du dossier particulier
     * @param {Array<DetailPiece>} body Données à mettre à jour
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public postAjouterPiece(ref: string, refPers: string, body: Array<DetailPiece>, options?: any) {
        return DossiersLocationsApiFp(this.configuration).postAjouterPiece(ref, refPers, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Ajout dans un dépôt les infos pour un candidat et ses pièces associées
     * @param {string} ref Référence du dossier de location
     * @param {DepotPartenaire} body Données du dépôt
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public postDepotPartenaire(ref: string, body: DepotPartenaire, options?: any) {
        return DossiersLocationsApiFp(this.configuration).postDepotPartenaire(ref, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary finalise le transfert du dossier location depuis un partenaire
     * @param {string} ref Référence du dossier de location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public postFinaliserTransfert(ref: string, options?: any) {
        return DossiersLocationsApiFp(this.configuration).postFinaliserTransfert(ref, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary ajoute ou remplace une archive de pièces pour une personne du dossier
     * @param {string} ref Référence du dossier de location
     * @param {string} refPers Référence du dossier particulier
     * @param {Fichier} archive Archive à ajouter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public putArchivePieces(ref: string, refPers: string, archive: Fichier, options?: any) {
        return DossiersLocationsApiFp(this.configuration).putArchivePieces(ref, refPers, archive, options)(this.axios, this.basePath);
    }

    /**
     * Ce service permet de réouvrir un dossier validé avec erreurs (etat = waiting)
     * @summary Réouvrir le dossier pieces validé avec erreurs
     * @param {string} ref Référence du dossier de location
     * @param {string} refPers Référence du dossier particulier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public reouvrirDossierPieces(ref: string, refPers: string, options?: any) {
        return DossiersLocationsApiFp(this.configuration).reouvrirDossierPieces(ref, refPers, options)(this.axios, this.basePath);
    }

    /**
     * Ce service permet de synchroniser l\'état du dossier Vertuloo et la liste des pièces justificatives avec celui du dossier pieces
     * @summary Synchroniser le dossier Vertuloo avec celui de pieces
     * @param {string} ref Référence du dossier de location
     * @param {string} refPers Référence du dossier particulier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersLocationsApi
     */
    public synchroniserPieces(ref: string, refPers: string, options?: any) {
        return DossiersLocationsApiFp(this.configuration).synchroniserPieces(ref, refPers, options)(this.axios, this.basePath);
    }

}


/**
 * DossiersParticuliersApi - axios parameter creator
 * @export
 */
export const DossiersParticuliersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Mise à jour en Ged des archives des dossiers de location qui n\'ont pas été synchronisés
         * @param {number} [nbDossier] Le nombre de dossier Netheos à récupérer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArchives(nbDossier?: number, options: any = {}): RequestArgs {
            const localVarPath = `/dossiers-particuliers/all/archives`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nbDossier !== undefined) {
                localVarQueryParameter['nbDossier'] = nbDossier;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DossiersParticuliersApi - functional programming interface
 * @export
 */
export const DossiersParticuliersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Mise à jour en Ged des archives des dossiers de location qui n\'ont pas été synchronisés
         * @param {number} [nbDossier] Le nombre de dossier Netheos à récupérer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArchives(nbDossier?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DossiersParticuliersApiAxiosParamCreator(configuration).updateArchives(nbDossier, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DossiersParticuliersApi - factory interface
 * @export
 */
export const DossiersParticuliersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Mise à jour en Ged des archives des dossiers de location qui n\'ont pas été synchronisés
         * @param {number} [nbDossier] Le nombre de dossier Netheos à récupérer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArchives(nbDossier?: number, options?: any) {
            return DossiersParticuliersApiFp(configuration).updateArchives(nbDossier, options)(axios, basePath);
        },
    };
};

/**
 * DossiersParticuliersApi - object-oriented interface
 * @export
 * @class DossiersParticuliersApi
 * @extends {BaseAPI}
 */
export class DossiersParticuliersApi extends BaseAPI {
    /**
     * 
     * @summary Mise à jour en Ged des archives des dossiers de location qui n\'ont pas été synchronisés
     * @param {number} [nbDossier] Le nombre de dossier Netheos à récupérer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersParticuliersApi
     */
    public updateArchives(nbDossier?: number, options?: any) {
        return DossiersParticuliersApiFp(this.configuration).updateArchives(nbDossier, options)(this.axios, this.basePath);
    }

}


/**
 * DossiersProfessionnelsApi - axios parameter creator
 * @export
 */
export const DossiersProfessionnelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Renvoie l\'ensemble des situations professionnelles.
         * @summary Récupérer la liste des situations professionnelles depuis le référentiel Situation Professionnelle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSituationsProfessionnelles(options: any = {}): RequestArgs {
            const localVarPath = `/situations-professionnelles`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DossiersProfessionnelsApi - functional programming interface
 * @export
 */
export const DossiersProfessionnelsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Renvoie l\'ensemble des situations professionnelles.
         * @summary Récupérer la liste des situations professionnelles depuis le référentiel Situation Professionnelle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSituationsProfessionnelles(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SituationProfessionnelle>> {
            const localVarAxiosArgs = DossiersProfessionnelsApiAxiosParamCreator(configuration).getSituationsProfessionnelles(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DossiersProfessionnelsApi - factory interface
 * @export
 */
export const DossiersProfessionnelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Renvoie l\'ensemble des situations professionnelles.
         * @summary Récupérer la liste des situations professionnelles depuis le référentiel Situation Professionnelle
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSituationsProfessionnelles(options?: any) {
            return DossiersProfessionnelsApiFp(configuration).getSituationsProfessionnelles(options)(axios, basePath);
        },
    };
};

/**
 * DossiersProfessionnelsApi - object-oriented interface
 * @export
 * @class DossiersProfessionnelsApi
 * @extends {BaseAPI}
 */
export class DossiersProfessionnelsApi extends BaseAPI {
    /**
     * Renvoie l\'ensemble des situations professionnelles.
     * @summary Récupérer la liste des situations professionnelles depuis le référentiel Situation Professionnelle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossiersProfessionnelsApi
     */
    public getSituationsProfessionnelles(options?: any) {
        return DossiersProfessionnelsApiFp(this.configuration).getSituationsProfessionnelles(options)(this.axios, this.basePath);
    }

}


/**
 * FichiersApi - axios parameter creator
 * @export
 */
export const FichiersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Le fichier sera enregistré dans le répertoire \\\\\\xbcged\\Data\\Agrements\\\\{identifiant-utilisateur} (le x de xbcged est à remplacer par d, r ou p selon l\'environnement)
         * @summary Chargement d\'un fichier sur les serveurs
         * @param {any} file Le fichier à télécharger
         * @param {string} identifiantUtilisateur Correspond au username Keycloak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fichiersTeleverserPost(file: any, identifiantUtilisateur: string, options: any = {}): RequestArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling fichiersTeleverserPost.');
            }
            // verify required parameter 'identifiantUtilisateur' is not null or undefined
            if (identifiantUtilisateur === null || identifiantUtilisateur === undefined) {
                throw new RequiredError('identifiantUtilisateur','Required parameter identifiantUtilisateur was null or undefined when calling fichiersTeleverserPost.');
            }
            const localVarPath = `/fichiers/televerser`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (identifiantUtilisateur !== undefined) { 
                localVarFormParams.append('identifiantUtilisateur', identifiantUtilisateur as any);
            }
    
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FichiersApi - functional programming interface
 * @export
 */
export const FichiersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Le fichier sera enregistré dans le répertoire \\\\\\xbcged\\Data\\Agrements\\\\{identifiant-utilisateur} (le x de xbcged est à remplacer par d, r ou p selon l\'environnement)
         * @summary Chargement d\'un fichier sur les serveurs
         * @param {any} file Le fichier à télécharger
         * @param {string} identifiantUtilisateur Correspond au username Keycloak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fichiersTeleverserPost(file: any, identifiantUtilisateur: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = FichiersApiAxiosParamCreator(configuration).fichiersTeleverserPost(file, identifiantUtilisateur, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FichiersApi - factory interface
 * @export
 */
export const FichiersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Le fichier sera enregistré dans le répertoire \\\\\\xbcged\\Data\\Agrements\\\\{identifiant-utilisateur} (le x de xbcged est à remplacer par d, r ou p selon l\'environnement)
         * @summary Chargement d\'un fichier sur les serveurs
         * @param {any} file Le fichier à télécharger
         * @param {string} identifiantUtilisateur Correspond au username Keycloak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fichiersTeleverserPost(file: any, identifiantUtilisateur: string, options?: any) {
            return FichiersApiFp(configuration).fichiersTeleverserPost(file, identifiantUtilisateur, options)(axios, basePath);
        },
    };
};

/**
 * FichiersApi - object-oriented interface
 * @export
 * @class FichiersApi
 * @extends {BaseAPI}
 */
export class FichiersApi extends BaseAPI {
    /**
     * Le fichier sera enregistré dans le répertoire \\\\\\xbcged\\Data\\Agrements\\\\{identifiant-utilisateur} (le x de xbcged est à remplacer par d, r ou p selon l\'environnement)
     * @summary Chargement d\'un fichier sur les serveurs
     * @param {any} file Le fichier à télécharger
     * @param {string} identifiantUtilisateur Correspond au username Keycloak
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FichiersApi
     */
    public fichiersTeleverserPost(file: any, identifiantUtilisateur: string, options?: any) {
        return FichiersApiFp(this.configuration).fichiersTeleverserPost(file, identifiantUtilisateur, options)(this.axios, this.basePath);
    }

}


/**
 * LocalisationApi - axios parameter creator
 * @export
 */
export const LocalisationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Un seul critère doit être renseigné
         * @summary 5 premières villes dont le nom ou le code postal commence par le critère fourni
         * @param {string} [codePostal] Code postal complet ou partiel (début)
         * @param {string} [nom] Nom complet ou partiel (début) de la commune
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communesGet(codePostal?: string, nom?: string, options: any = {}): RequestArgs {
            const localVarPath = `/communes`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codePostal !== undefined) {
                localVarQueryParameter['codePostal'] = codePostal;
            }

            if (nom !== undefined) {
                localVarQueryParameter['nom'] = nom;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Liste des départements depuis le référentiel Départements
         * @summary Liste des départements depuis le référentiel Départements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departementsGet(options: any = {}): RequestArgs {
            const localVarPath = `/departements`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Récupération d\'un type de voie
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTypeVoieByCode(code: string, options: any = {}): RequestArgs {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling getTypeVoieByCode.');
            }
            const localVarPath = `/types-voies/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Liste des pays depuis le référentiel Pays
         * @summary Liste des pays depuis le référentiel Pays
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paysGet(options: any = {}): RequestArgs {
            const localVarPath = `/pays`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Liste des types de voies
         * @summary Liste des types de voies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        typesVoiesGet(options: any = {}): RequestArgs {
            const localVarPath = `/types-voies`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocalisationApi - functional programming interface
 * @export
 */
export const LocalisationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Un seul critère doit être renseigné
         * @summary 5 premières villes dont le nom ou le code postal commence par le critère fourni
         * @param {string} [codePostal] Code postal complet ou partiel (début)
         * @param {string} [nom] Nom complet ou partiel (début) de la commune
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communesGet(codePostal?: string, nom?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Commune>> {
            const localVarAxiosArgs = LocalisationApiAxiosParamCreator(configuration).communesGet(codePostal, nom, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Liste des départements depuis le référentiel Départements
         * @summary Liste des départements depuis le référentiel Départements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departementsGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Departement>> {
            const localVarAxiosArgs = LocalisationApiAxiosParamCreator(configuration).departementsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Récupération d\'un type de voie
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTypeVoieByCode(code: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeVoie> {
            const localVarAxiosArgs = LocalisationApiAxiosParamCreator(configuration).getTypeVoieByCode(code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Liste des pays depuis le référentiel Pays
         * @summary Liste des pays depuis le référentiel Pays
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paysGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pays>> {
            const localVarAxiosArgs = LocalisationApiAxiosParamCreator(configuration).paysGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Liste des types de voies
         * @summary Liste des types de voies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        typesVoiesGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TypeVoie>> {
            const localVarAxiosArgs = LocalisationApiAxiosParamCreator(configuration).typesVoiesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LocalisationApi - factory interface
 * @export
 */
export const LocalisationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Un seul critère doit être renseigné
         * @summary 5 premières villes dont le nom ou le code postal commence par le critère fourni
         * @param {string} [codePostal] Code postal complet ou partiel (début)
         * @param {string} [nom] Nom complet ou partiel (début) de la commune
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communesGet(codePostal?: string, nom?: string, options?: any) {
            return LocalisationApiFp(configuration).communesGet(codePostal, nom, options)(axios, basePath);
        },
        /**
         * Liste des départements depuis le référentiel Départements
         * @summary Liste des départements depuis le référentiel Départements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departementsGet(options?: any) {
            return LocalisationApiFp(configuration).departementsGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary Récupération d\'un type de voie
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTypeVoieByCode(code: string, options?: any) {
            return LocalisationApiFp(configuration).getTypeVoieByCode(code, options)(axios, basePath);
        },
        /**
         * Liste des pays depuis le référentiel Pays
         * @summary Liste des pays depuis le référentiel Pays
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paysGet(options?: any) {
            return LocalisationApiFp(configuration).paysGet(options)(axios, basePath);
        },
        /**
         * Liste des types de voies
         * @summary Liste des types de voies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        typesVoiesGet(options?: any) {
            return LocalisationApiFp(configuration).typesVoiesGet(options)(axios, basePath);
        },
    };
};

/**
 * LocalisationApi - object-oriented interface
 * @export
 * @class LocalisationApi
 * @extends {BaseAPI}
 */
export class LocalisationApi extends BaseAPI {
    /**
     * Un seul critère doit être renseigné
     * @summary 5 premières villes dont le nom ou le code postal commence par le critère fourni
     * @param {string} [codePostal] Code postal complet ou partiel (début)
     * @param {string} [nom] Nom complet ou partiel (début) de la commune
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalisationApi
     */
    public communesGet(codePostal?: string, nom?: string, options?: any) {
        return LocalisationApiFp(this.configuration).communesGet(codePostal, nom, options)(this.axios, this.basePath);
    }

    /**
     * Liste des départements depuis le référentiel Départements
     * @summary Liste des départements depuis le référentiel Départements
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalisationApi
     */
    public departementsGet(options?: any) {
        return LocalisationApiFp(this.configuration).departementsGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Récupération d\'un type de voie
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalisationApi
     */
    public getTypeVoieByCode(code: string, options?: any) {
        return LocalisationApiFp(this.configuration).getTypeVoieByCode(code, options)(this.axios, this.basePath);
    }

    /**
     * Liste des pays depuis le référentiel Pays
     * @summary Liste des pays depuis le référentiel Pays
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalisationApi
     */
    public paysGet(options?: any) {
        return LocalisationApiFp(this.configuration).paysGet(options)(this.axios, this.basePath);
    }

    /**
     * Liste des types de voies
     * @summary Liste des types de voies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalisationApi
     */
    public typesVoiesGet(options?: any) {
        return LocalisationApiFp(this.configuration).typesVoiesGet(options)(this.axios, this.basePath);
    }

}


/**
 * PartenaireApi - axios parameter creator
 * @export
 */
export const PartenaireApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Récupération d\'un partenaire par son code
         * @param {string} code Code du partenaire
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartenaireParCode(code: string, options: any = {}): RequestArgs {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling getPartenaireParCode.');
            }
            const localVarPath = `/partenaires/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartenaireApi - functional programming interface
 * @export
 */
export const PartenaireApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Récupération d\'un partenaire par son code
         * @param {string} code Code du partenaire
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartenaireParCode(code: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Partenaire> {
            const localVarAxiosArgs = PartenaireApiAxiosParamCreator(configuration).getPartenaireParCode(code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PartenaireApi - factory interface
 * @export
 */
export const PartenaireApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Récupération d\'un partenaire par son code
         * @param {string} code Code du partenaire
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartenaireParCode(code: string, options?: any) {
            return PartenaireApiFp(configuration).getPartenaireParCode(code, options)(axios, basePath);
        },
    };
};

/**
 * PartenaireApi - object-oriented interface
 * @export
 * @class PartenaireApi
 * @extends {BaseAPI}
 */
export class PartenaireApi extends BaseAPI {
    /**
     * 
     * @summary Récupération d\'un partenaire par son code
     * @param {string} code Code du partenaire
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartenaireApi
     */
    public getPartenaireParCode(code: string, options?: any) {
        return PartenaireApiFp(this.configuration).getPartenaireParCode(code, options)(this.axios, this.basePath);
    }

}


/**
 * UtilisateursApi - axios parameter creator
 * @export
 */
export const UtilisateursApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Activation ou réactivation d\'un compte utilisateur
         * @summary Activation d\'un compte utilisateur
         * @param {string} username Username de l\&#39;utilisateur à activer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activerUtilisateur(username: string, options: any = {}): RequestArgs {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username','Required parameter username was null or undefined when calling activerUtilisateur.');
            }
            const localVarPath = `/utilisateurs/{username}/activer`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Désactivation d\'un compte utilisateur
         * @summary Désactivation d\'un compte utilisateur
         * @param {string} username Username de l\&#39;utilisateur côté keycloak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        desactiverUtilisateur(username: string, options: any = {}): RequestArgs {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username','Required parameter username was null or undefined when calling desactiverUtilisateur.');
            }
            const localVarPath = `/utilisateurs/{username}/desactiver`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Liste des fonctions possibles pour un utilisateur
         * @summary Liste des fonctions utilisateurs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fonctionsPostesGet(options: any = {}): RequestArgs {
            const localVarPath = `/fonctions-postes`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Récupération d\'un profil
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfilById(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProfilById.');
            }
            const localVarPath = `/profils/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupération d\'un compte utilisateur avec ses détails
         * @summary Récupération d\'un compte utilisateur
         * @param {string} username Username de l\&#39;utilisateur à récupérer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUtilisateurByUsername(username: string, options: any = {}): RequestArgs {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username','Required parameter username was null or undefined when calling getUtilisateurByUsername.');
            }
            const localVarPath = `/utilisateurs/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Liste des profils Bessé pour une application donnée
         * @param {string} codeApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilsBesseGet(codeApplication: string, options: any = {}): RequestArgs {
            // verify required parameter 'codeApplication' is not null or undefined
            if (codeApplication === null || codeApplication === undefined) {
                throw new RequiredError('codeApplication','Required parameter codeApplication was null or undefined when calling profilsBesseGet.');
            }
            const localVarPath = `/profils-besse`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codeApplication !== undefined) {
                localVarQueryParameter['codeApplication'] = codeApplication;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Activation d\'un compte suite à une demande de création d\'un compte utilisateur. Lors de la demande de création de compte, un mail contenant le token d\'activation aura été envoyé.
         * @summary Activation d\'un compte utilisateur
         * @param {Token} [token] Token d\&#39;activation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursActiverPut(token?: Token, options: any = {}): RequestArgs {
            const localVarPath = `/utilisateurs/activer`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Token" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(token !== undefined ? token : {}) : (token || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Récupération des utilisateurs d\'un AdB et de son regroupement
         * @param {number} numAdherent Numéro de l\&#39;administrateur de bien
         * @param {string} [nom] Filtre sur le nom de l\&#39;utilisateur (correspondance partielle)
         * @param {string} [prenom] Filtre sur le prénom de l\&#39;utilisateur (correspondance partielle)
         * @param {string} [statut] Filtre sur le statut de l\&#39;utilisateur (correspondance partielle)
         * @param {string} [fonction] Filtre sur le libellé de la fonction (poste) de l\&#39;utilisateur (correspondance partielle)
         * @param {number} [page] L\&#39;index de la page (l\&#39;index de la première page est 0)
         * @param {number} [taille] Le nombre d\&#39;éléments à retourner
         * @param {Array<string>} [tri] Format : attribut1:asc,attribut2:desc Nom de l\&#39;attribut égal au nom du champ envoyé
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursAdministrateurBienNumAdherentGet(numAdherent: number, nom?: string, prenom?: string, statut?: string, fonction?: string, page?: number, taille?: number, tri?: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'numAdherent' is not null or undefined
            if (numAdherent === null || numAdherent === undefined) {
                throw new RequiredError('numAdherent','Required parameter numAdherent was null or undefined when calling utilisateursAdministrateurBienNumAdherentGet.');
            }
            const localVarPath = `/utilisateurs/administrateur-bien/{numAdherent}`
                .replace(`{${"numAdherent"}}`, encodeURIComponent(String(numAdherent)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nom !== undefined) {
                localVarQueryParameter['nom'] = nom;
            }

            if (prenom !== undefined) {
                localVarQueryParameter['prenom'] = prenom;
            }

            if (statut !== undefined) {
                localVarQueryParameter['statut'] = statut;
            }

            if (fonction !== undefined) {
                localVarQueryParameter['fonction'] = fonction;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (taille !== undefined) {
                localVarQueryParameter['taille'] = taille;
            }

            if (tri) {
                localVarQueryParameter['tri'] = tri.join(COLLECTION_FORMATS.csv);
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Récupération des utilisateurs Bessé
         * @param {string} [nom] Filtre sur le nom de l\&#39;utilisateur (correspondance partielle)
         * @param {string} [prenom] Filtre sur le prénom de l\&#39;utilisateur (correspondance partielle)
         * @param {string} [statut] Filtre sur le statut de l\&#39;utilisateur (correspondance partielle)
         * @param {number} [page] L\&#39;index de la page (l\&#39;index de la première page est 0)
         * @param {number} [taille] Le nombre d\&#39;éléments à retourner
         * @param {Array<string>} [tri] Format : attribut1:asc,attribut2:desc Nom de l\&#39;attribut égal au nom du champ envoyé
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursBesseGet(nom?: string, prenom?: string, statut?: string, page?: number, taille?: number, tri?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/utilisateurs/besse`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nom !== undefined) {
                localVarQueryParameter['nom'] = nom;
            }

            if (prenom !== undefined) {
                localVarQueryParameter['prenom'] = prenom;
            }

            if (statut !== undefined) {
                localVarQueryParameter['statut'] = statut;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (taille !== undefined) {
                localVarQueryParameter['taille'] = taille;
            }

            if (tri) {
                localVarQueryParameter['tri'] = tri.join(COLLECTION_FORMATS.csv);
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modification du mot de passe de l\'utilisateur suite à une demande de réinitialisation.
         * @summary Modification du mot de passe
         * @param {InlineObject2} [tokenMdp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursMdpModifierPut(tokenMdp?: InlineObject2, options: any = {}): RequestArgs {
            const localVarPath = `/utilisateurs/mdp/modifier`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"InlineObject2" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(tokenMdp !== undefined ? tokenMdp : {}) : (tokenMdp || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Réinitialisation du mot de passe pour un utilisateur, cela déclenche une création d\'un token de réinitialisation et un envoi de mail.
         * @summary Demande de réinitialisation du mot de passe
         * @param {InlineObject1} [utilisateurId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursMdpReinitialiserPost(utilisateurId?: InlineObject1, options: any = {}): RequestArgs {
            const localVarPath = `/utilisateurs/mdp/reinitialiser`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"InlineObject1" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(utilisateurId !== undefined ? utilisateurId : {}) : (utilisateurId || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifier l\'existence et la validité d\'un token de réinitialisation de mot de passe.
         * @summary Vérification la demande de reinitialisation du MDP
         * @param {Token} [token] Token de réinitialisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursMdpVerifierPost(token?: Token, options: any = {}): RequestArgs {
            const localVarPath = `/utilisateurs/mdp/verifier`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Token" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(token !== undefined ? token : {}) : (token || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Création d\'un utilisateur au sein de Keycloak et l\'AD
         * @summary Création d\'un compte utilisateur
         * @param {Utilisateur} body Caractéristique de l\&#39;utilisateur à créer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursPost(body: Utilisateur, options: any = {}): RequestArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling utilisateursPost.');
            }
            const localVarPath = `/utilisateurs`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Utilisateur" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mise à jour d\'un utilisateur
         * @summary Mise à jour d\'un compte utilisateur
         * @param {string} username Username de l\&#39;utilisateur à mettre à jour
         * @param {Utilisateur} body Données à mettre à jour
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursUsernamePut(username: string, body: Utilisateur, options: any = {}): RequestArgs {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username','Required parameter username was null or undefined when calling utilisateursUsernamePut.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling utilisateursUsernamePut.');
            }
            const localVarPath = `/utilisateurs/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Utilisateur" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilisateursApi - functional programming interface
 * @export
 */
export const UtilisateursApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Activation ou réactivation d\'un compte utilisateur
         * @summary Activation d\'un compte utilisateur
         * @param {string} username Username de l\&#39;utilisateur à activer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activerUtilisateur(username: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = UtilisateursApiAxiosParamCreator(configuration).activerUtilisateur(username, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Désactivation d\'un compte utilisateur
         * @summary Désactivation d\'un compte utilisateur
         * @param {string} username Username de l\&#39;utilisateur côté keycloak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        desactiverUtilisateur(username: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = UtilisateursApiAxiosParamCreator(configuration).desactiverUtilisateur(username, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Liste des fonctions possibles pour un utilisateur
         * @summary Liste des fonctions utilisateurs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fonctionsPostesGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FonctionPoste>> {
            const localVarAxiosArgs = UtilisateursApiAxiosParamCreator(configuration).fonctionsPostesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Récupération d\'un profil
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfilById(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profil> {
            const localVarAxiosArgs = UtilisateursApiAxiosParamCreator(configuration).getProfilById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Récupération d\'un compte utilisateur avec ses détails
         * @summary Récupération d\'un compte utilisateur
         * @param {string} username Username de l\&#39;utilisateur à récupérer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUtilisateurByUsername(username: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Utilisateur> {
            const localVarAxiosArgs = UtilisateursApiAxiosParamCreator(configuration).getUtilisateurByUsername(username, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Liste des profils Bessé pour une application donnée
         * @param {string} codeApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilsBesseGet(codeApplication: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Profil>> {
            const localVarAxiosArgs = UtilisateursApiAxiosParamCreator(configuration).profilsBesseGet(codeApplication, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Activation d\'un compte suite à une demande de création d\'un compte utilisateur. Lors de la demande de création de compte, un mail contenant le token d\'activation aura été envoyé.
         * @summary Activation d\'un compte utilisateur
         * @param {Token} [token] Token d\&#39;activation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursActiverPut(token?: Token, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = UtilisateursApiAxiosParamCreator(configuration).utilisateursActiverPut(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Récupération des utilisateurs d\'un AdB et de son regroupement
         * @param {number} numAdherent Numéro de l\&#39;administrateur de bien
         * @param {string} [nom] Filtre sur le nom de l\&#39;utilisateur (correspondance partielle)
         * @param {string} [prenom] Filtre sur le prénom de l\&#39;utilisateur (correspondance partielle)
         * @param {string} [statut] Filtre sur le statut de l\&#39;utilisateur (correspondance partielle)
         * @param {string} [fonction] Filtre sur le libellé de la fonction (poste) de l\&#39;utilisateur (correspondance partielle)
         * @param {number} [page] L\&#39;index de la page (l\&#39;index de la première page est 0)
         * @param {number} [taille] Le nombre d\&#39;éléments à retourner
         * @param {Array<string>} [tri] Format : attribut1:asc,attribut2:desc Nom de l\&#39;attribut égal au nom du champ envoyé
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursAdministrateurBienNumAdherentGet(numAdherent: number, nom?: string, prenom?: string, statut?: string, fonction?: string, page?: number, taille?: number, tri?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUtilisateurs> {
            const localVarAxiosArgs = UtilisateursApiAxiosParamCreator(configuration).utilisateursAdministrateurBienNumAdherentGet(numAdherent, nom, prenom, statut, fonction, page, taille, tri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Récupération des utilisateurs Bessé
         * @param {string} [nom] Filtre sur le nom de l\&#39;utilisateur (correspondance partielle)
         * @param {string} [prenom] Filtre sur le prénom de l\&#39;utilisateur (correspondance partielle)
         * @param {string} [statut] Filtre sur le statut de l\&#39;utilisateur (correspondance partielle)
         * @param {number} [page] L\&#39;index de la page (l\&#39;index de la première page est 0)
         * @param {number} [taille] Le nombre d\&#39;éléments à retourner
         * @param {Array<string>} [tri] Format : attribut1:asc,attribut2:desc Nom de l\&#39;attribut égal au nom du champ envoyé
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursBesseGet(nom?: string, prenom?: string, statut?: string, page?: number, taille?: number, tri?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUtilisateurs> {
            const localVarAxiosArgs = UtilisateursApiAxiosParamCreator(configuration).utilisateursBesseGet(nom, prenom, statut, page, taille, tri, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Modification du mot de passe de l\'utilisateur suite à une demande de réinitialisation.
         * @summary Modification du mot de passe
         * @param {InlineObject2} [tokenMdp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursMdpModifierPut(tokenMdp?: InlineObject2, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = UtilisateursApiAxiosParamCreator(configuration).utilisateursMdpModifierPut(tokenMdp, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Réinitialisation du mot de passe pour un utilisateur, cela déclenche une création d\'un token de réinitialisation et un envoi de mail.
         * @summary Demande de réinitialisation du mot de passe
         * @param {InlineObject1} [utilisateurId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursMdpReinitialiserPost(utilisateurId?: InlineObject1, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = UtilisateursApiAxiosParamCreator(configuration).utilisateursMdpReinitialiserPost(utilisateurId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Verifier l\'existence et la validité d\'un token de réinitialisation de mot de passe.
         * @summary Vérification la demande de reinitialisation du MDP
         * @param {Token} [token] Token de réinitialisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursMdpVerifierPost(token?: Token, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = UtilisateursApiAxiosParamCreator(configuration).utilisateursMdpVerifierPost(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Création d\'un utilisateur au sein de Keycloak et l\'AD
         * @summary Création d\'un compte utilisateur
         * @param {Utilisateur} body Caractéristique de l\&#39;utilisateur à créer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursPost(body: Utilisateur, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Utilisateur> {
            const localVarAxiosArgs = UtilisateursApiAxiosParamCreator(configuration).utilisateursPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Mise à jour d\'un utilisateur
         * @summary Mise à jour d\'un compte utilisateur
         * @param {string} username Username de l\&#39;utilisateur à mettre à jour
         * @param {Utilisateur} body Données à mettre à jour
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursUsernamePut(username: string, body: Utilisateur, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = UtilisateursApiAxiosParamCreator(configuration).utilisateursUsernamePut(username, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UtilisateursApi - factory interface
 * @export
 */
export const UtilisateursApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Activation ou réactivation d\'un compte utilisateur
         * @summary Activation d\'un compte utilisateur
         * @param {string} username Username de l\&#39;utilisateur à activer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activerUtilisateur(username: string, options?: any) {
            return UtilisateursApiFp(configuration).activerUtilisateur(username, options)(axios, basePath);
        },
        /**
         * Désactivation d\'un compte utilisateur
         * @summary Désactivation d\'un compte utilisateur
         * @param {string} username Username de l\&#39;utilisateur côté keycloak
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        desactiverUtilisateur(username: string, options?: any) {
            return UtilisateursApiFp(configuration).desactiverUtilisateur(username, options)(axios, basePath);
        },
        /**
         * Liste des fonctions possibles pour un utilisateur
         * @summary Liste des fonctions utilisateurs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fonctionsPostesGet(options?: any) {
            return UtilisateursApiFp(configuration).fonctionsPostesGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary Récupération d\'un profil
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfilById(id: number, options?: any) {
            return UtilisateursApiFp(configuration).getProfilById(id, options)(axios, basePath);
        },
        /**
         * Récupération d\'un compte utilisateur avec ses détails
         * @summary Récupération d\'un compte utilisateur
         * @param {string} username Username de l\&#39;utilisateur à récupérer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUtilisateurByUsername(username: string, options?: any) {
            return UtilisateursApiFp(configuration).getUtilisateurByUsername(username, options)(axios, basePath);
        },
        /**
         * 
         * @summary Liste des profils Bessé pour une application donnée
         * @param {string} codeApplication 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilsBesseGet(codeApplication: string, options?: any) {
            return UtilisateursApiFp(configuration).profilsBesseGet(codeApplication, options)(axios, basePath);
        },
        /**
         * Activation d\'un compte suite à une demande de création d\'un compte utilisateur. Lors de la demande de création de compte, un mail contenant le token d\'activation aura été envoyé.
         * @summary Activation d\'un compte utilisateur
         * @param {Token} [token] Token d\&#39;activation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursActiverPut(token?: Token, options?: any) {
            return UtilisateursApiFp(configuration).utilisateursActiverPut(token, options)(axios, basePath);
        },
        /**
         * 
         * @summary Récupération des utilisateurs d\'un AdB et de son regroupement
         * @param {number} numAdherent Numéro de l\&#39;administrateur de bien
         * @param {string} [nom] Filtre sur le nom de l\&#39;utilisateur (correspondance partielle)
         * @param {string} [prenom] Filtre sur le prénom de l\&#39;utilisateur (correspondance partielle)
         * @param {string} [statut] Filtre sur le statut de l\&#39;utilisateur (correspondance partielle)
         * @param {string} [fonction] Filtre sur le libellé de la fonction (poste) de l\&#39;utilisateur (correspondance partielle)
         * @param {number} [page] L\&#39;index de la page (l\&#39;index de la première page est 0)
         * @param {number} [taille] Le nombre d\&#39;éléments à retourner
         * @param {Array<string>} [tri] Format : attribut1:asc,attribut2:desc Nom de l\&#39;attribut égal au nom du champ envoyé
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursAdministrateurBienNumAdherentGet(numAdherent: number, nom?: string, prenom?: string, statut?: string, fonction?: string, page?: number, taille?: number, tri?: Array<string>, options?: any) {
            return UtilisateursApiFp(configuration).utilisateursAdministrateurBienNumAdherentGet(numAdherent, nom, prenom, statut, fonction, page, taille, tri, options)(axios, basePath);
        },
        /**
         * 
         * @summary Récupération des utilisateurs Bessé
         * @param {string} [nom] Filtre sur le nom de l\&#39;utilisateur (correspondance partielle)
         * @param {string} [prenom] Filtre sur le prénom de l\&#39;utilisateur (correspondance partielle)
         * @param {string} [statut] Filtre sur le statut de l\&#39;utilisateur (correspondance partielle)
         * @param {number} [page] L\&#39;index de la page (l\&#39;index de la première page est 0)
         * @param {number} [taille] Le nombre d\&#39;éléments à retourner
         * @param {Array<string>} [tri] Format : attribut1:asc,attribut2:desc Nom de l\&#39;attribut égal au nom du champ envoyé
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursBesseGet(nom?: string, prenom?: string, statut?: string, page?: number, taille?: number, tri?: Array<string>, options?: any) {
            return UtilisateursApiFp(configuration).utilisateursBesseGet(nom, prenom, statut, page, taille, tri, options)(axios, basePath);
        },
        /**
         * Modification du mot de passe de l\'utilisateur suite à une demande de réinitialisation.
         * @summary Modification du mot de passe
         * @param {InlineObject2} [tokenMdp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursMdpModifierPut(tokenMdp?: InlineObject2, options?: any) {
            return UtilisateursApiFp(configuration).utilisateursMdpModifierPut(tokenMdp, options)(axios, basePath);
        },
        /**
         * Réinitialisation du mot de passe pour un utilisateur, cela déclenche une création d\'un token de réinitialisation et un envoi de mail.
         * @summary Demande de réinitialisation du mot de passe
         * @param {InlineObject1} [utilisateurId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursMdpReinitialiserPost(utilisateurId?: InlineObject1, options?: any) {
            return UtilisateursApiFp(configuration).utilisateursMdpReinitialiserPost(utilisateurId, options)(axios, basePath);
        },
        /**
         * Verifier l\'existence et la validité d\'un token de réinitialisation de mot de passe.
         * @summary Vérification la demande de reinitialisation du MDP
         * @param {Token} [token] Token de réinitialisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursMdpVerifierPost(token?: Token, options?: any) {
            return UtilisateursApiFp(configuration).utilisateursMdpVerifierPost(token, options)(axios, basePath);
        },
        /**
         * Création d\'un utilisateur au sein de Keycloak et l\'AD
         * @summary Création d\'un compte utilisateur
         * @param {Utilisateur} body Caractéristique de l\&#39;utilisateur à créer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursPost(body: Utilisateur, options?: any) {
            return UtilisateursApiFp(configuration).utilisateursPost(body, options)(axios, basePath);
        },
        /**
         * Mise à jour d\'un utilisateur
         * @summary Mise à jour d\'un compte utilisateur
         * @param {string} username Username de l\&#39;utilisateur à mettre à jour
         * @param {Utilisateur} body Données à mettre à jour
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilisateursUsernamePut(username: string, body: Utilisateur, options?: any) {
            return UtilisateursApiFp(configuration).utilisateursUsernamePut(username, body, options)(axios, basePath);
        },
    };
};

/**
 * UtilisateursApi - object-oriented interface
 * @export
 * @class UtilisateursApi
 * @extends {BaseAPI}
 */
export class UtilisateursApi extends BaseAPI {
    /**
     * Activation ou réactivation d\'un compte utilisateur
     * @summary Activation d\'un compte utilisateur
     * @param {string} username Username de l\&#39;utilisateur à activer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public activerUtilisateur(username: string, options?: any) {
        return UtilisateursApiFp(this.configuration).activerUtilisateur(username, options)(this.axios, this.basePath);
    }

    /**
     * Désactivation d\'un compte utilisateur
     * @summary Désactivation d\'un compte utilisateur
     * @param {string} username Username de l\&#39;utilisateur côté keycloak
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public desactiverUtilisateur(username: string, options?: any) {
        return UtilisateursApiFp(this.configuration).desactiverUtilisateur(username, options)(this.axios, this.basePath);
    }

    /**
     * Liste des fonctions possibles pour un utilisateur
     * @summary Liste des fonctions utilisateurs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public fonctionsPostesGet(options?: any) {
        return UtilisateursApiFp(this.configuration).fonctionsPostesGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Récupération d\'un profil
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public getProfilById(id: number, options?: any) {
        return UtilisateursApiFp(this.configuration).getProfilById(id, options)(this.axios, this.basePath);
    }

    /**
     * Récupération d\'un compte utilisateur avec ses détails
     * @summary Récupération d\'un compte utilisateur
     * @param {string} username Username de l\&#39;utilisateur à récupérer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public getUtilisateurByUsername(username: string, options?: any) {
        return UtilisateursApiFp(this.configuration).getUtilisateurByUsername(username, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Liste des profils Bessé pour une application donnée
     * @param {string} codeApplication 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public profilsBesseGet(codeApplication: string, options?: any) {
        return UtilisateursApiFp(this.configuration).profilsBesseGet(codeApplication, options)(this.axios, this.basePath);
    }

    /**
     * Activation d\'un compte suite à une demande de création d\'un compte utilisateur. Lors de la demande de création de compte, un mail contenant le token d\'activation aura été envoyé.
     * @summary Activation d\'un compte utilisateur
     * @param {Token} [token] Token d\&#39;activation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public utilisateursActiverPut(token?: Token, options?: any) {
        return UtilisateursApiFp(this.configuration).utilisateursActiverPut(token, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Récupération des utilisateurs d\'un AdB et de son regroupement
     * @param {number} numAdherent Numéro de l\&#39;administrateur de bien
     * @param {string} [nom] Filtre sur le nom de l\&#39;utilisateur (correspondance partielle)
     * @param {string} [prenom] Filtre sur le prénom de l\&#39;utilisateur (correspondance partielle)
     * @param {string} [statut] Filtre sur le statut de l\&#39;utilisateur (correspondance partielle)
     * @param {string} [fonction] Filtre sur le libellé de la fonction (poste) de l\&#39;utilisateur (correspondance partielle)
     * @param {number} [page] L\&#39;index de la page (l\&#39;index de la première page est 0)
     * @param {number} [taille] Le nombre d\&#39;éléments à retourner
     * @param {Array<string>} [tri] Format : attribut1:asc,attribut2:desc Nom de l\&#39;attribut égal au nom du champ envoyé
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public utilisateursAdministrateurBienNumAdherentGet(numAdherent: number, nom?: string, prenom?: string, statut?: string, fonction?: string, page?: number, taille?: number, tri?: Array<string>, options?: any) {
        return UtilisateursApiFp(this.configuration).utilisateursAdministrateurBienNumAdherentGet(numAdherent, nom, prenom, statut, fonction, page, taille, tri, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Récupération des utilisateurs Bessé
     * @param {string} [nom] Filtre sur le nom de l\&#39;utilisateur (correspondance partielle)
     * @param {string} [prenom] Filtre sur le prénom de l\&#39;utilisateur (correspondance partielle)
     * @param {string} [statut] Filtre sur le statut de l\&#39;utilisateur (correspondance partielle)
     * @param {number} [page] L\&#39;index de la page (l\&#39;index de la première page est 0)
     * @param {number} [taille] Le nombre d\&#39;éléments à retourner
     * @param {Array<string>} [tri] Format : attribut1:asc,attribut2:desc Nom de l\&#39;attribut égal au nom du champ envoyé
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public utilisateursBesseGet(nom?: string, prenom?: string, statut?: string, page?: number, taille?: number, tri?: Array<string>, options?: any) {
        return UtilisateursApiFp(this.configuration).utilisateursBesseGet(nom, prenom, statut, page, taille, tri, options)(this.axios, this.basePath);
    }

    /**
     * Modification du mot de passe de l\'utilisateur suite à une demande de réinitialisation.
     * @summary Modification du mot de passe
     * @param {InlineObject2} [tokenMdp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public utilisateursMdpModifierPut(tokenMdp?: InlineObject2, options?: any) {
        return UtilisateursApiFp(this.configuration).utilisateursMdpModifierPut(tokenMdp, options)(this.axios, this.basePath);
    }

    /**
     * Réinitialisation du mot de passe pour un utilisateur, cela déclenche une création d\'un token de réinitialisation et un envoi de mail.
     * @summary Demande de réinitialisation du mot de passe
     * @param {InlineObject1} [utilisateurId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public utilisateursMdpReinitialiserPost(utilisateurId?: InlineObject1, options?: any) {
        return UtilisateursApiFp(this.configuration).utilisateursMdpReinitialiserPost(utilisateurId, options)(this.axios, this.basePath);
    }

    /**
     * Verifier l\'existence et la validité d\'un token de réinitialisation de mot de passe.
     * @summary Vérification la demande de reinitialisation du MDP
     * @param {Token} [token] Token de réinitialisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public utilisateursMdpVerifierPost(token?: Token, options?: any) {
        return UtilisateursApiFp(this.configuration).utilisateursMdpVerifierPost(token, options)(this.axios, this.basePath);
    }

    /**
     * Création d\'un utilisateur au sein de Keycloak et l\'AD
     * @summary Création d\'un compte utilisateur
     * @param {Utilisateur} body Caractéristique de l\&#39;utilisateur à créer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public utilisateursPost(body: Utilisateur, options?: any) {
        return UtilisateursApiFp(this.configuration).utilisateursPost(body, options)(this.axios, this.basePath);
    }

    /**
     * Mise à jour d\'un utilisateur
     * @summary Mise à jour d\'un compte utilisateur
     * @param {string} username Username de l\&#39;utilisateur à mettre à jour
     * @param {Utilisateur} body Données à mettre à jour
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilisateursApi
     */
    public utilisateursUsernamePut(username: string, body: Utilisateur, options?: any) {
        return UtilisateursApiFp(this.configuration).utilisateursUsernamePut(username, body, options)(this.axios, this.basePath);
    }

}


/**
 * UtilitairesApi - axios parameter creator
 * @export
 */
export const UtilitairesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Nous contacter
         * @param {Contact} body Infos à envoyer à l\&#39;equipe Vertuloo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nousContacterPost(body: Contact, options: any = {}): RequestArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling nousContacterPost.');
            }
            const localVarPath = `/nous-contacter`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Contact" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilitairesApi - functional programming interface
 * @export
 */
export const UtilitairesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Nous contacter
         * @param {Contact} body Infos à envoyer à l\&#39;equipe Vertuloo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nousContacterPost(body: Contact, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = UtilitairesApiAxiosParamCreator(configuration).nousContacterPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UtilitairesApi - factory interface
 * @export
 */
export const UtilitairesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Nous contacter
         * @param {Contact} body Infos à envoyer à l\&#39;equipe Vertuloo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nousContacterPost(body: Contact, options?: any) {
            return UtilitairesApiFp(configuration).nousContacterPost(body, options)(axios, basePath);
        },
    };
};

/**
 * UtilitairesApi - object-oriented interface
 * @export
 * @class UtilitairesApi
 * @extends {BaseAPI}
 */
export class UtilitairesApi extends BaseAPI {
    /**
     * 
     * @summary Nous contacter
     * @param {Contact} body Infos à envoyer à l\&#39;equipe Vertuloo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitairesApi
     */
    public nousContacterPost(body: Contact, options?: any) {
        return UtilitairesApiFp(this.configuration).nousContacterPost(body, options)(this.axios, this.basePath);
    }

}


