import { Theme } from '@material-ui/core/styles';
import ImgBg from '../../../../ressources/images/besse-immobilier.jpg';

const styles = (theme: Theme) =>
  ({
    container: {
      backgroundColor: theme.palette.primary.main,
      height: 540,
      width: '100%',
      display: 'flex',
      alignItems: 'flex-end',
      padding: '0px !important',
      margin: 0,
      '& h2': {
        margin: 0,
      },
      '& h1': {
        marginTop: 0,
        fontWeight: 'normal',
        fontSize: '2.4em',
        lineHeight: '1.3em',

        maxWidth: 420,
      },
    },
    imageBg: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      height: '100%',
      backgroundImage: 'url(' + ImgBg + ')',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      flex: 1,
    },
    texte: {
      flex: 1,
      padding: '24px 62px 0 62px',
      width: '50%',
      color: 'white',
      textAlign: 'right',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    '@media all and (max-width: 789px)': {
      imageBg: {
        display: 'none',
      },
    },
    '@media all and (max-width: 500px)': {
      container: {
        height: 280,
        alignItems: 'center',
        '& h1': {
          fontSize: '1.3em',
        },
      },
      texte: {
        alignItems: 'flex-start',
        width: '90%',
        padding: '78px 12px 12px 12px',
        textAlign: 'left',
      },
    },
  } as any);

export default styles;
