import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { isPristine } from 'redux-form';
import CheminDeFer from '../../../components/cheminDeFer/CheminDeFer';
import Layout from '../../../components/Layout';
import LoadingBoundary from '../../../components/LoadingBoundary';
import { history } from '../../../helpers/history';
import {
  ActionCreators as BienActionCreator,
  Selectors as BienSelector,
} from '../../../redux/Bien';
import {
  ActionCreators as DossierLocationActionCreators,
  Selectors as DossierLocationSelectors,
} from '../../../redux/DossierLocation';
import {
  ActionCreators as LocalisationActionCreator,
  Selectors as LocalisationSelectors,
} from '../../../redux/Localisation';
import {
  ActionCreators as TypeVoieActions,
  Selectors as TypeVoieSelector,
} from '../../../redux/TypeVoie';
import {
  Bien,
  Commune,
  NombrePieces,
  TypeBien,
  TypeVoie,
} from '../../../services/Api/swagger';
import Libelle from '../../../_i18n/fr.json';
import RenderError from '../../ErrorContainer';
import BienForm from './AjouterBienForm';
import { copyBienFormToModel, IBienFormData } from './helpers';
import styles from './style';

interface Props {
  bienData?: Bien;
  bienFetching: boolean;
  bienError: any;
  typesVoie: TypeVoie[];
  typeVoieFetching: boolean;
  typeVoieError: any;
  typesBien: TypeBien[];
  typesBiensFetching: boolean;
  typesBiensError: any;
  nombrePieces: NombrePieces[];
  nombrePiecesFetching: boolean;
  nombrePiecesError: any;
  communes: Commune[];
  ajouterBien(bien: Bien): void;
  modifierBien(bien: Bien): void;
  preRemplirBien(referenceMandat: string, numeroLot: string): void;
  chargerTypesBien(): void;
  chargerListTypeVoie(): void;
  chargerNombrePieces(): void;
  supprimerErrorBien(): void;
  chargerCommunes(commune: Commune): void;
  pristine: boolean;
  statutDossier?: string;
  dossierDuplique?: string;
  dupliquerDossier(params): void;
  duplicationFetching: boolean;
  duplicationError: any;
}

class BienPage extends React.Component<Props> {
  componentDidMount() {
    this.loadInitData();
  }

  loadInitData = () => {
    this.props.chargerTypesBien();
    this.props.chargerListTypeVoie();
    this.props.chargerNombrePieces();
  };

  handlePrecedent = () => {
    if (this.props.dossierDuplique) {
      history.push('/dashboard');
    } else {
      history.push('/dossier-location/pre-formulaire');
    }
  };

  handleSubmit = (bienForm: IBienFormData) => {
    const {
      bienData,
      ajouterBien,
      modifierBien,
      statutDossier,
      dupliquerDossier,
    } = this.props;

    const bienModel = copyBienFormToModel(bienForm);

    if (this.props.dossierDuplique) {
      dupliquerDossier({ ref: this.props.dossierDuplique, bien: bienModel });
    } else if (!this.props.pristine) {
      if (bienData && bienData.identifiant) {
        bienModel.identifiant = bienData.identifiant;
        modifierBien(bienModel);
      } else {
        ajouterBien(bienModel);
      }
    } else {
      if (!bienData || !bienData.identifiant) {
        ajouterBien(bienModel);
      }
      if (statutDossier && statutDossier === 'EN_COURS_DE_CREATION') {
        history.push('/dossier-location/situation-personnelle');
      } else {
        history.push('/dossier-location/synthese');
      }
    }
  };

  chargerCommunes = (commune: Commune) => {
    this.props.chargerCommunes(commune);
  };

  render() {
    const {
      typesVoie,
      typesBien,
      nombrePieces,
      preRemplirBien,
      nombrePiecesFetching,
      typeVoieError,
      typesBiensError,
      nombrePiecesError,
      bienFetching,
      bienError,
      communes,
      typeVoieFetching,
      typesBiensFetching,
      supprimerErrorBien,
      duplicationFetching,
      duplicationError,
    } = this.props;

    return (
      <React.Fragment>
        <Layout title={Libelle.pageBien.titre}>
          <CheminDeFer isEtape="monBien" />
          <LoadingBoundary
            loading={
              nombrePiecesFetching || typeVoieFetching || typesBiensFetching
            }
            error={typeVoieError || typesBiensError || nombrePiecesError}
            onRetry={this.loadInitData}
          >
            <BienForm
              onSubmit={this.handleSubmit}
              onPrecedent={this.handlePrecedent}
              onChangeBien={preRemplirBien}
              typesVoie={typesVoie}
              typesBien={typesBien}
              nombrePieces={nombrePieces}
              isFetching={bienFetching || duplicationFetching}
              onChangeCommune={this.chargerCommunes}
              communes={communes}
            />
            <Grid container justify="flex-end" style={{ marginTop: 12 }}>
              {bienError || duplicationError ? (
                <RenderError errors={bienError} onClose={supprimerErrorBien} />
              ) : (
                ''
              )}
            </Grid>
          </LoadingBoundary>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  bienData:
    DossierLocationSelectors.getBienData(state) || BienSelector.getBien(state),
  bienFetching: BienSelector.getBienFetching(state),
  bienError: BienSelector.getBienError(state),

  nombrePieces: BienSelector.getNombrePieces(state),
  nombrePiecesFetching: BienSelector.getNombrePiecesFetching(state),
  nombrePiecesError: BienSelector.getNombrePiecesError(state),

  typesBien: BienSelector.getTypesBien(state),
  typesBiensFetching: BienSelector.getTypesBienFetching(state),
  typesBiensError: BienSelector.getTypesBienError(state),

  typesVoie: TypeVoieSelector.getListTypeVoie(state),
  typeVoieFetching: TypeVoieSelector.getListTypeVoieFetching(state),
  typeVoieError: TypeVoieSelector.getListTypeVoieError(state),
  communes: LocalisationSelectors.getLocalisations(state),
  pristine: isPristine('BienForm')(state),
  statutDossier: DossierLocationSelectors.getStatutDossier(state),
  dossierDuplique: DossierLocationSelectors.getDossierDuplique(state),
  duplicationFetching: DossierLocationSelectors.getDuplicationFetching(state),
  duplicationError: DossierLocationSelectors.getDuplicationError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  supprimerErrorBien: () => dispatch(BienActionCreator.removeError()),
  ajouterBien: (bien: Bien) =>
    dispatch(
      BienActionCreator.addBienAsync.request({ bien, form: 'BienForm' }),
    ),
  modifierBien: (bien: Bien) =>
    dispatch(
      BienActionCreator.updateBienAsync.request({ bien, form: 'BienForm' }),
    ),
  preRemplirBien: (referenceMandat: string, numeroLot: string) =>
    dispatch(
      BienActionCreator.lancerChargementBien.request({
        referenceMandat,
        numeroLot,
      }),
    ),
  chargerTypesBien: () =>
    dispatch(BienActionCreator.loadTypeBienAsync.request()),
  chargerNombrePieces: () =>
    dispatch(BienActionCreator.loadNombrePieces.request()),
  chargerListTypeVoie: () =>
    dispatch(TypeVoieActions.loadTypeVoieAsync.request()),
  chargerCommunes: (commune: Commune) =>
    dispatch(LocalisationActionCreator.loadLocalisationsAsync.request(commune)),
  dupliquerDossier: params =>
    dispatch(DossierLocationActionCreators.dupliquerDossier.request(params)),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BienPage),
);
