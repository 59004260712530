import { Theme } from '@material-ui/core/styles';

const styles = (_theme: Theme) =>
({
  info: {
    borderLeft: '5px solid #ffb549',
    background: 'rgba(255, 181, 73, 0.1)',
    padding: '4px 20px 4px 8px',
    fontStyle: 'italic',
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
  },
  icon: {
    color: '#FFB549',
    marginRight: 8,
  },
} as any);

export default styles;
