import { withStyles } from '@material-ui/core';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import logoNoir from '../../../../ressources/images/logo_vertuloo_noir.png';
import patricia from '../../../../ressources/images/patricia.jpg';
import severine from '../../../../ressources/images/severine.jpg';
import claude from '../../../../ressources/images/temoignage_claude.jpg';
import xavier from '../../../../ressources/images/xavier.jpg';
import defaultStyles from '../styles';
import ComposantTemoignage from './ComposantTemoignage';
import styles from './styles';

interface IProps {
  cookiesValid: boolean;
  classes?: any;
}
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const BlocTemoignages = (props: IProps) => {
  return (
    <div className={props.classes.containerBg}>
      <div className={props.classes.container}>
        <img src={logoNoir} width={100} alt="immobilier & construction" />
        <h2> Nos témoignages clients </h2>
        <Carousel responsive={responsive}>
          <ComposantTemoignage
            altImage="Témoignage Claude"
            image={claude}
            title={'Gimcovermeille'}
            nom="Claude Barthomeuf"
            profession="Directeur Gérance"
            idVideo="lzx14eMasHg"
            cookiesValid={props.cookiesValid}
            buttonTitle="Voir la vidéo"
          />
          <ComposantTemoignage
            altImage="Témoignage Xavier"
            image={xavier}
            title={'Century 21<br />Groupe Martinot'}
            nom="Xavier Edel"
            profession="Directeur Gestion Locative et Développement"
            idVideo="xgnxGThceq0"
            cookiesValid={props.cookiesValid}
            buttonTitle="Ecouter le podcast"
          />
          <ComposantTemoignage
            image={patricia}
            altImage="Témoignage Patricia"
            title={'CPH Immobilier'}
            nom="Patricia Gueroult"
            profession="Responsable service location"
            idVideo="gCSbxHOtLGo"
            cookiesValid={props.cookiesValid}
          />
          <ComposantTemoignage
            image={severine}
            altImage="Témoignage Séverine"
            title={'CID Gestion'}
            nom="Séverine Brissaud"
            profession="Assistante Commerciale"
            idVideo="mEgks18LqQo"
            cookiesValid={props.cookiesValid}
          />
        </Carousel>
        <div className={props.classes.containerYoutube} />
      </div>
    </div>
  );
};

export default withStyles(theme => ({
  ...styles(theme),
  ...defaultStyles(theme),
}))(BlocTemoignages);
