import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    root: {
      width: '100%',
      minWidth: 198, // fix select input size
    },
    textFieldRoot: {
      border: '1px solid ' + theme.palette.primary.main,
      '& > div': {
        backgroundColor: 'white !important',
      },
    },
    textField: {
      borderRadius: '0',
      backgroundColor: 'transparent',
    },
    select: {
      padding: '0 46px 0 12px',
      height: 39,
      lineHeight: '49px',
      width: 'calc(100% - 58px)',
      fontWeight: 'bold',
      fontSize: '0.8rem',
    },
    uncontrolledSelect: {
      padding: '0 46px 0 12px',
      height: 39,
      width: 'calc(100% - 58px)',
      fontWeight: 'bold',
      fontSize: '0.8rem',
    },
    textFieldLabelError: {},
    textFieldLabelFocused: {},
    textFieldLabelAsterisk: {
      '&$textFieldLabelError': {
        color: theme.palette.common.black,
      },
    },
    textFieldLabel: {
      fontSize: '0.8rem',
      fontWeight: 'bold',
      color: theme.palette.common.black,
      transform: 'translate(12px, 14px) scale(1)',
      '&$textFieldLabelFocused': {
        fontWeight: 'normal',
        color: theme.palette.common.black,
      },
      '&$textFieldLabelError': {
        color: theme.palette.common.black,
      },
      '&.MuiInputLabel-shrink': {
        transform: 'translate(12px, 4px) scale(0.75)',
        fontSize: '1em',
        fontWeight: 'normal',
        color: theme.palette.grey[400],
      },
    },
    formControl: {
      minWidth: 120,
    },
    errorMessage: {
      color: 'red',
    },
    iconSelect: {
      color: theme.palette.secondary.main,
      top: 0,
      height: '100%',
      width: '40px',
    },
    underline: {
      '&::before, &::after': {
        borderBottomWidth: '0',
      },
    },
  } as any);

export default styles;
