import { DropdownItem } from '../../components/select/Select';
import {
  AdministrateurBien,
  FonctionPoste,
  Profil,
  Site,
  Utilisateur,
  UtilisateurDetailAdbProfilSites,
  UtilisateurRoleEnum,
} from '../../services/Api/swagger';

/**
 * Construit les options pour la liste des fonctions d'un utilisateur ADB
 * (conversion pour être conforme au format attendu par le composant Select)
 * Valeur par défaut pour éviter erreur TypeScript
 */
export const construireListFonctionsOptions = (
  fonctionsPostes: FonctionPoste[],
): DropdownItem[] =>
  fonctionsPostes.map(
    (item: FonctionPoste): DropdownItem => ({
      key: item.identifiant || 0,
      text: item.libelle || '',
      value: item.identifiant || '',
    }),
  );

export const construireListProfils = (profils: Profil[]): DropdownItem[] =>
  profils.map(
    (item: Profil): DropdownItem => ({
      key: item.identifiant || 0,
      text: item.nom || '',
      value: item.identifiant || '',
    }),
  );

export const construireListRegroupements = (
  regroupements: AdministrateurBien[],
): DropdownItem[] =>
  regroupements.map(
    (item: AdministrateurBien): DropdownItem => ({
      key: item.identifiant || 0,
      text: item.nom || '',
      value: item.identifiant || '',
    }),
  );

export const construireListAdbDetail = (
  detail: Site[] | undefined,
  refAdb,
): DropdownItem[] | undefined => {
  return (
    detail &&
    detail.map(
      (item: Site): DropdownItem => ({
        key: item.numero + refAdb || 0,
        text: item.nom || '',
        value: item.numero || '',
      }),
    )
  );
};
export const construireListProfilsBesse = (profils: Profil[]): DropdownItem[] =>
  profils.map(
    (item: Profil): DropdownItem => ({
      key: item.identifiant || 0,
      text: item.nom || '',
      value: item.identifiant || '',
    }),
  );
/**
 * Transforme les données saisies dans le formulaire Utilisateur dans le format attendu par l'API
 * @param values
 */
export const transformUtilisateurDataToModel = (values): Utilisateur => {
  const construireListAdb: any[] =
    values.adbs &&
    values.adbs.map(list => {
      const sitesValues =
        list && list.sites && list.sites.map(site => site.value);
      return {
        ...list,
        refAdb: Number(list.refAdb),
        codeApplication: 'AGREMENT',
        profil: Number(list.profil),
        sites: sitesValues,
      };
    });
  const utilisateur: Utilisateur = {
    nom: values.nom,
    prenom: values.prenom,
    mail: values.mail,
    role: UtilisateurRoleEnum['ADB'],
    fonction: Number(values.fonction),
    detailAdbProfilSites: construireListAdb,
    motDePasse: values.motDePasse,
  };
  return utilisateur;
};

/**
 * Transforme les données saisies dans le formulaire utilisateur BESSE dans le format attendu par l'API
 * @param values
 */
export const transformUtilisateurBesseDataToModel = (values): Utilisateur => {
  const utilisateur: Utilisateur = {
    nom: values.nom,
    prenom: values.prenom,
    mail: values.mail,
    role: UtilisateurRoleEnum['BESSE'],
    profilBesse: values.profilBesse,
    motDePasse: values.motDePasse,
  };
  return utilisateur;
};

export const getIdsAdb = (utilisateurConnecte: Utilisateur) => {
  const getDetailAdbs: UtilisateurDetailAdbProfilSites[] | undefined =
    utilisateurConnecte && utilisateurConnecte.detailAdbProfilSites;
  const listIdAdbs = getDetailAdbs && getDetailAdbs.map(adb => adb.refAdb);
  return listIdAdbs || [];
};

export const getProfilsAdb = adbsUtilisateurs => {
  return (
    adbsUtilisateurs &&
    adbsUtilisateurs.map((adb, i) =>
      i === 0 ? adb.profil : ' / ' + adb.profil,
    )
  );
};
export const getInfoTableau = (results, postes, isBesse) => {
  return results.map(result => ({
    ...result,
    numAdherent:
      result.detailAdbProfilSites &&
      result.detailAdbProfilSites.map((adb, i) =>
        i === 0 ? adb.nomAdb : ' / ' + adb.nomAdb,
      ),
    fonction: postes
      ? postes.find(fonction => fonction.identifiant === result.fonction)
      : '-',
    profil: isBesse
      ? result.nomProfilBesse
      : result.detailAdbProfilSites &&
        result.detailAdbProfilSites.map((adb, i) =>
          i === 0 ? adb.nomProfil : ' / ' + adb.nomProfil,
        ),
  }));
};
