import React from 'react';
import Layout from '../../components/Layout';
import Libelle from '../../_i18n/fr.json';
import Accueil from './Accueil';

class HomePage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout variant={true} title={Libelle.pageAccueil.titre}>
          <Accueil />
        </Layout>
      </React.Fragment>
    );
  }
}

export default HomePage;
