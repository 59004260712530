import React from 'react';

// tslint:disable: max-line-length
export default class SituationProSVG extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="83"
        height="83"
        viewBox="0 0 67 67"
      >
        <defs>
          <path id="a" d="M0 .57h40.482v31.384H0z" />
          <path id="c" d="M.193.105h4.539v4.026H.193z" />
          <path id="e" d="M.723.105H18.69v10.47H.723z" />
        </defs>
        <g fillRule="evenodd" id="Mask" fill="rgba(92, 81, 117, 0.25)">
          <circle cx="33.5" cy="33.5" r="33.5" />
          <g
            transform="translate(13.4 20.51)"
            id="Mask"
            fill="rgba(92, 81, 117, 0.25)"
          >
            <mask id="b" fill="#fff">
              <use />
            </mask>
            <path
              fill="#FFF"
              d="M40.407 21.212l-2.045-8.405-15.047 3.66.337 1.385c.151.62-.23 1.243-.849 1.395l-3.466.843a1.155 1.155 0 0 1-1.395-.849l-.336-1.384L2.56 21.518l-.439-1.801 35.802-8.711.28 1.147L35.876 2.6A2.657 2.657 0 0 0 32.666.645l-6.763 1.647v-.003l-3.336.812-.55-2.246.546 2.249-10.431 2.539-.001-.003-3.335.812-.558-2.281.555 2.284L2.031 8.1a2.658 2.658 0 0 0-1.955 3.212l4.53 18.611a2.659 2.659 0 0 0 3.21 1.956l30.637-7.456a2.66 2.66 0 0 0 1.954-3.21"
            />
          </g>
          <path
            fill="#FFF"
            d="M25.46 22.07l8.693-2.116-8.695 2.116a.896.896 0 0 0-.658 1.081l.176.717-.175-.717a.896.896 0 0 1 .659-1.081"
          />
          <g transform="translate(34.015 16.387)">
            <mask id="d" fill="#fff">
              <use />
            </mask>
            <path
              fill="#FFF"
              d="M4.555 3.412l.177.719-.175-.719a4.339 4.339 0 0 0-.002 0"
              mask="url(#d)"
            />
          </g>
          <path
            fill="#000"
            d="M15.521 40.227l.44 1.802 15.045-3.662-.219-.9 5.71-1.389.218.901 15.046-3.661-.159-.655-.28-1.147z"
          />
          <path
            fill="#000"
            d="M30.787 37.467l.22.9.335 1.385c.152.619.775 1 1.395.848l3.466-.843c.619-.151 1-.775.849-1.394l-.337-1.385-.219-.9-5.71 1.389z"
          />
          <g transform="translate(20.615 16.387)">
            <mask id="f" fill="#fff">
              <use />
            </mask>
            <path
              fill="#000"
              d="M4.916 9.763h.004L4.36 7.48l-.176-.717a.896.896 0 0 1 .658-1.082l8.693-2.116a.892.892 0 0 1 1.08.659l.186.752.55 2.246 3.335-.81.004-.002-.56-2.28-.176-.718A4.336 4.336 0 0 0 12.723.229L4.03 2.344A4.336 4.336 0 0 0 .846 7.576l.177.717.558 2.281 3.335-.81z"
            />
          </g>
        </g>
      </svg>
    );
  }
}
// tslint:enable: max-line-length
