import { CircularProgress } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from './styles';

interface Props {
  label: string;
  type?: 'button' | 'submit' | 'reset';
  color?: 'default' | 'inherit' | 'primary' | 'secondary' | 'action';
  onClick?(event): void;
  onSubmit?: any;
  icon?: JSX.Element;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
  classes: any;
  fetching?: boolean;
  style?: any;
  className?: string;
}

/**
 * Composant affichant un bouton.
 */
const Button = (props: Props) => {
  const {
    color,
    classes,
    icon,
    label,
    onClick,
    size,
    type,
    variant,
    fetching,
    onSubmit,
    style,
  } = props;

  let className = classes.root as any;

  if (icon) {
    className += ' ' + classes.iconButton;
  }
  if (fetching) {
    return <CircularProgress />;
  }
  return (
    <MuiButton
      type={type || 'button'}
      color={color as any}
      variant={variant || 'contained'}
      onClick={onClick}
      onSubmit={onSubmit}
      size={size || 'large'}
      className={className + ' ' + props.className}
      classes={{
        root: classes.root,
        containedPrimary: classes.containedPrimary,
      }}
      style={style}
    >
      {icon && color === 'default' && (
        <span className={classes.icon + ' ' + classes.prevIcon}>{icon}</span>
      )}
      {label}
      {icon && color !== 'default' && (
        <span className={classes.icon + ' ' + classes.nextIcon}>{icon}</span>
      )}
    </MuiButton>
  );
};

export default withStyles(styles)(Button);
