import { Theme } from '@material-ui/core/styles';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
  ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    datepicker: {
      color: theme.palette.common.black,
      border: '1px solid ' + theme.palette.primary.main,
    },
    input: {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      padding: '15px 12px 7px',
      fontSize: '0.9rem',
      fontWeight: 'bold',
    },
    inputLabelError: {},
    inputLabelFocused: {},
    inputLabelShrink: {},
    inputLabelAsterisk: {
      '&$inputLabelError': {
        color: theme.palette.common.black,
      },
    },
    inputLabel: {
      fontSize: '0.8rem',
      color: theme.palette.common.black,
      fontWeight: 'bold',
      transform: 'translate(12px, 14px) scale(1)',
      '&$inputLabelFocused': {
        color: theme.palette.grey[400],
      },
      '&$inputLabelError': {
        color: theme.palette.common.black,
      },
      '&$inputLabelShrink': {
        transform: 'translate(12px, 4px) scale(0.75)',
        fontSize: '1em',
        fontWeight: 'normal',
        color: theme.palette.grey[400],
      },
    },
    underline: {
      '&::after, &::before': {
        border: 'none',
      },
    },
    iconCalendar: {
      marginLeft: 4,
      marginTop: 2,
    },
    iconButton: {
      '& svg': {
        fill: theme.palette.primary.main,
      },
      '&:hover': {
        '& svg': {},
      },
    },
  } as any);
// tslint:enable: quotemark

export default styles;
