import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import Bouton from '../../../../../components/bouton/Bouton';
import InputField from '../../../../../components/input/InputField';
import { isEmail } from '../../../../../helpers/validators';
import ErreursConstante from '../../../../../_constantes/erreursConstantes.json';
import styles from '../styles';

export interface ContactFormData {
  nom: string;
  prenom: string;
  email: string;
  societe: string;
  telephone?: string;
  message: string;
  response: string;
}

interface ContactFormError {
  nom?: string;
  prenom?: string;
  email?: string;
  societe?: string;
  telephone?: string;
  message?: string;
}
interface Props {
  classes?: any;
  fetching?: boolean;
  formValues: ContactFormData;
  errorCaptcha?: string;
  onCaptchaValue(value?: string): void;
  withoutMessage?: boolean;
}
class ContactForm extends React.Component<
  InjectedFormProps<ContactFormData> & Props
> {
  onChange = value => {
    this.props.onCaptchaValue(value);
  };

  render() {
    const {
      classes,
      handleSubmit,
      fetching,
      errorCaptcha,
      withoutMessage,
    } = this.props;

    return (
      <div className={classes.container}>
        <h2>Vous souhaitez être contacté ?</h2>
        <div>
          <form
            onSubmit={handleSubmit}
            noValidate
            className={classes.formContainer}
          >
            <div className={classes.formContact}>
              <Grid container wrap="nowrap" className={classes.inputForm}>
                <Field
                  component={InputField}
                  name="nom"
                  required
                  label="Nom"
                  maxLength={50}
                />{' '}
                <Field
                  component={InputField}
                  name="prenom"
                  required
                  label="Prénom"
                  maxLength={50}
                />
              </Grid>
              <Field
                component={InputField}
                name="societe"
                required
                label="Société"
                maxLength={80}
              />
              <Field
                component={InputField}
                name="telephone"
                label="Téléphone"
                maxLength={20}
              />
              <Field
                component={InputField}
                name="email"
                required
                label="E-mail"
                maxLength={65}
              />
              {!withoutMessage && (
                <Field
                  component={InputField}
                  name="message"
                  multiline
                  required
                  rows={3}
                  label="Message"
                  placeholder="Ajouter un message"
                  maxLength={500}
                  note=" Si vous souhaitez une offre personnalisée ou essayer vertuloo gratuitement n'hésitez pas à nous indiquer le nombre de lots gérés, assurés, non assurés."
                />
              )}
              <Grid container direction="column">
                <ReCAPTCHA
                  sitekey="6LchDdsUAAAAAD1UiVPur8IYgfIhZAVsxjlCKRq2"
                  onChange={this.onChange}
                />
                {errorCaptcha && (
                  <Typography variant="body2" color="error">
                    {errorCaptcha}
                  </Typography>
                )}
              </Grid>
              <Grid container justify="flex-end">
                <Bouton
                  label="Envoyer"
                  type="submit"
                  variant="contained"
                  color="primary"
                  fetching={fetching}
                />
              </Grid>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const validateForm = (values: ContactFormData): ContactFormError => {
  const errors: ContactFormError = {} as ContactFormError;

  if (!values.nom) {
    errors.nom = ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (!values.prenom) {
    errors.prenom = ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (!values.email) {
    errors.email = ErreursConstante.formulaire.generale.champObligatoire;
  } else if (values.email && !isEmail(values.email)) {
    errors.email = ErreursConstante.formulaire.generale.emailIncorrect;
  }
  if (!values.societe) {
    errors.societe = ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (!values.message) {
    errors.message = ErreursConstante.formulaire.generale.champObligatoire;
  }

  return errors;
};

const mapStateToProps = (state, props) => ({
  formValues: getFormValues('contactForm')(state),
});

const reduxConnection = reduxForm<ContactFormData>({
  form: 'contactForm',
  validate: validateForm,
})(ContactForm);

export default withStyles(styles)(connect(mapStateToProps)(reduxConnection));
