import Types from 'Types';
import {
  ActionType,
  createAsyncAction,
  createStandardAction,
  getType,
} from 'typesafe-actions';
import {
  dossierParticulierToModificationSituationPersonnelle,
  ISituationPersonnelleFormData,
} from '../../containers/DossierLocation/SituationPersonnellePage/helpers';
import {
  dossierParticulierToModificationSituationProfessionnelle,
  SituationProfessionnelleFormData,
} from '../../containers/DossierLocation/SituationProfessionnellePage/helpers';
import {
  Bien,
  DossierLocation,
  DossierLocationCompositionLocationEnum,
  PageDossiersLocation,
  RapportCalculAlgo,
  RapportDossierLocation,
  RetourLocationAcceptee,
} from '../../services/Api/swagger';
import { Selectors as SituationProSelectors } from '../SituationProfessionnelle';

export interface DossierLocationState {
  data?: DossierLocation;
  dataRetourLocationAcceptee?: RetourLocationAcceptee;
  fetchInfo: Types.IFetchInfos;
  dossierFetchInfo: Types.IFetchInfos;
  filtreRecherche?: any;
  dossiersLocation?: PageDossiersLocation;
  searchFetchInfo: Types.IFetchInfos;
  selectedDossier?: string;
  selectedPerson?: string;
  rapportAlgo?: RapportDossierLocation;
  algoFetchInfo: Types.IFetchInfos;
  calculFetchInfo: Types.IFetchInfos;
  duplicationDossier?: string;
  dupliquerFetchInfo: Types.IFetchInfos;
  accepterRefuserLocationFetchInfo: Types.IFetchInfos;
  forcerStatutFetchInfo: Types.IFetchInfos;
  fetchInfoGenerer: Types.IFetchInfos;
  pdf64?: any;
}

/**
 * Init value est temporaire en attentant
 * l'US de récupération du dossier de location
 */
export const initialState: DossierLocationState = {
  data: undefined, // dossier location en cours de visualisation/ajout/modification
  dataRetourLocationAcceptee: undefined,
  fetchInfo: {
    error: undefined,
    fetching: false,
  },
  dossierFetchInfo: {
    error: undefined,
    fetching: false,
  },
  searchFetchInfo: {
    error: undefined,
    fetching: false,
  },
  algoFetchInfo: {
    error: undefined,
    fetching: false,
  },
  // tslint:disable-next-line:max-line-length
  dossiersLocation: undefined, // liste de tous les dossiers de location de l'adb sélectionnée ou des adbs d'un utilisateur
  selectedDossier: undefined, // dossier à afficher dans la synthese
  selectedPerson: undefined, // personne affichée dans la synthese => permet d'accéder à la modification rapidement
  rapportAlgo: undefined,
  pdf64: undefined,
  calculFetchInfo: {
    error: undefined,
    fetching: false,
  },
  dupliquerFetchInfo: {
    error: undefined,
    fetching: false,
  },
  accepterRefuserLocationFetchInfo: {
    error: undefined,
    fetching: false,
  },
  duplicationDossier: undefined,
  forcerStatutFetchInfo: {
    error: undefined,
    fetching: false,
  },
  fetchInfoGenerer: {
    error: undefined,
    fetching: false,
  },
};

export const createOrUpdateDossierLocationAsync = createAsyncAction(
  '@@App/dossierLocation/ADD_UPDATE_REQUEST',
  '@@App/dossierLocation/ADD_UPDATE_SUCCESS',
  '@@App/dossierLocation/ADD_UPDATE_FAILURE',
)<DossierLocation, DossierLocation, any>();

export const changeCompositionLocation = createStandardAction(
  '@@App/dossierLocation/changeCompositionLocation',
)<DossierLocationCompositionLocationEnum>();

export const redirectSelectAdbSitePage = createStandardAction(
  '@@App/dossierLocation/REDIRECT_SELECT_ADB_SITE',
)<void>();

export const supprimerDossierLocationError = createStandardAction(
  '@@App/dossierLocation/DELETE_ERROR',
)<void>();

export const resetDossierLocationParcoursCreation = createStandardAction(
  '@@App/dossierLocation/PARCOURS_CREATION/RESET_STORE',
)<void>();

export const getDossiersLocation = createAsyncAction(
  '@@App/dossierLocation/GET_DOSSIERS_LOCATION_REQUEST',
  '@@App/dossierLocation/GET_DOSSIERS_LOCATION_SUCCESS',
  '@@App/dossierLocation/GET_DOSSIERS_LOCATION_FAILURE',
)<any, PageDossiersLocation, any>();

export const setFiltreRechercheDossiers = createStandardAction(
  '@@App/dossierLocation/SET_FILTRE_RECHERCHE_DOSSIERS',
)<any>(); // refDossierLocation

export const setSelectedDossier = createStandardAction(
  '@@App/dossierLocation/SET_SELECTED_DOSSIER',
)<string>();

export const setSelectedPerson = createStandardAction(
  '@@App/dossierLocation/SET_SELECTED_PERSO',
)<string>();

export const addGarant = createStandardAction(
  '@@App/dossierLocation/ADD_GARANT',
)();

export const getRapportAlgo = createAsyncAction(
  '@@App/dossierLocation/GET_RAPPORT_ALGO_REQUEST',
  '@@App/dossierLocation/GET_RAPPORT_ALGO_SUCCESS',
  '@@App/dossierLocation/GET_RAPPORT_ALGO_FAILURE',
)<string, RapportCalculAlgo, any>();

export const getSyntheseDossier = createAsyncAction(
  '@@App/dossierLocation/GET_SYNTHESE_REQUEST',
  '@@App/dossierLocation/GET_SYNTHESE_SUCCESS',
  '@@App/dossierLocation/GET_SYNTHESE_FAILURE',
)<string, DossierLocation, any>();

export const resetSelectedPerson = createStandardAction(
  '@@App/dossierLocation/RESET_SELECTED_PERSO',
)();

export const calculLoyerMax = createAsyncAction(
  '@@App/dossierLocation/CALCUL_LOYER_REQUEST',
  '@@App/dossierLocation/CALCUL_LOYER_SUCCESS',
  '@@App/dossierLocation/CALCUL_LOYER_FAILURE',
)<string, void, any>();

export const setDuplicationDossier = createStandardAction(
  '@@App/dossierLocation/SET_DUPLICATION_DOSSIER',
)<string>(); // refDossierLocation

export const resetDuplicationDossier = createStandardAction(
  '@@App/dossierLocation/RESET_DUPLICATION_DOSSIER',
)();

export const dupliquerDossier = createAsyncAction(
  '@@App/dossierLocation/DUPLIQUER_DOSSIER_REQUEST',
  '@@App/dossierLocation/DUPLIQUER_DOSSIER_SUCCESS',
  '@@App/dossierLocation/DUPLIQUER_DOSSIER_FAILURE',
)<any, DossierLocation, any>();

export const accepterDossier = createAsyncAction(
  '@@App/dossierLocation/ACCEPTER_DOSSIER_REQUEST',
  '@@App/dossierLocation/ACCEPTER_DOSSIER_SUCCESS',
  '@@App/dossierLocation/ACCEPTER_DOSSIER_FAILURE',
)<string, RetourLocationAcceptee, any>();

export const annulerAcceptationDossier = createAsyncAction(
  '@@App/dossierLocation/ANNULER_ACCEPTATION_DOSSIER_REQUEST',
  '@@App/dossierLocation/ANNULER_ACCEPTATION_DOSSIER_SUCCESS',
  '@@App/dossierLocation/ANNULER_ACCEPTATION_DOSSIER_FAILURE',
)<string, void, any>();

export const supprimerAcceptationOuRefusDossierError = createStandardAction(
  '@@App/dossierLocation/supprimerAcceptationOuRefusDossierError',
)();

export const forcerStatutDossier = createAsyncAction(
  '@@App/dossierLocation/FORCER_STATUT_DOSSIER_REQUEST',
  '@@App/dossierLocation/FORCER_STATUT_DOSSIER_SUCCESS',
  '@@App/dossierLocation/FORCER_STATUT_DOSSIER_FAILURE',
)<any, void, any>();

export const deleteDossierLocation = createAsyncAction(
  '@@App/dossierLocation/DELETE_DOSSIER_REQUEST',
  '@@App/dossierLocation/DELETE_DOSSIER_SUCCESS',
  '@@App/dossierLocation/DELETE_DOSSIER_FAILURE',
)<string, void, any>();

export const genererCertification = createAsyncAction(
  '@@App/dossierLocation/GENERER_CERTIFICAT_REQUEST',
  '@@App/dossierLocation/GENERER_CERTIFICAT_SUCCESS',
  '@@App/dossierLocation/GENERER_CERTIFICAT_FAILURE',
)<string, Blob, any>();

export const ActionCreators = {
  supprimerDossierLocationError,
  changeCompositionLocation,
  redirectSelectAdbSitePage,
  createOrUpdateDossierLocationAsync,
  resetDossierLocationParcoursCreation,
  getDossiersLocation,
  setFiltreRechercheDossiers,
  setSelectedDossier,
  addGarant,
  getRapportAlgo,
  getSyntheseDossier,
  setSelectedPerson,
  resetSelectedPerson,
  calculLoyerMax,
  setDuplicationDossier,
  resetDuplicationDossier,
  dupliquerDossier,
  accepterDossier,
  annulerAcceptationDossier,
  supprimerAcceptationOuRefusDossierError,
  forcerStatutDossier,
  deleteDossierLocation,
  genererCertification,
};

const getDossierLocation = (
  state: Types.RootState,
): DossierLocation | undefined => state.dossierLocation.data;

const getDossierLocationFetching = (state: Types.RootState): boolean =>
  state.dossierLocation.fetchInfo.fetching;

const getDossierLocationError = (state: Types.RootState): any | undefined =>
  state.dossierLocation.fetchInfo && state.dossierLocation.fetchInfo.error;

const getResultDossierLocation = (
  state: Types.RootState,
): PageDossiersLocation | undefined => state.dossierLocation.dossiersLocation;

const getFiltreRechercheDossierLocation = (state: Types.RootState): any =>
  state.dossierLocation.filtreRecherche;

const getResultDossierLocationFetching = (state: Types.RootState): boolean =>
  state.dossierLocation.searchFetchInfo &&
  state.dossierLocation.searchFetchInfo.fetching;

const getResultDossierLocationError = (
  state: Types.RootState,
): any | undefined =>
  state.dossierLocation.searchFetchInfo &&
  state.dossierLocation.searchFetchInfo.error;

const getSelectedDossier = (state: Types.RootState): string | undefined =>
  (state.dossierLocation &&
    state.dossierLocation.data &&
    state.dossierLocation.data.reference) ||
  (state.dossierLocation && state.dossierLocation.selectedDossier);

const getStatutDossier = (state: Types.RootState): string | undefined =>
  state.dossierLocation &&
  state.dossierLocation.data &&
  state.dossierLocation.data.statut;
const getSelectedPerson = (state: Types.RootState): string | undefined =>
  state.dossierLocation && state.dossierLocation.selectedPerson;
const getAlgo = (state: Types.RootState): RapportDossierLocation | undefined =>
  state.dossierLocation.rapportAlgo && state.dossierLocation.rapportAlgo;

const getAlgoError = (state: Types.RootState): any | undefined =>
  state.dossierLocation.algoFetchInfo &&
  state.dossierLocation.algoFetchInfo.error;

const getAlgoFetching = (state: Types.RootState): boolean =>
  state.dossierLocation.algoFetchInfo &&
  state.dossierLocation.algoFetchInfo.fetching;

const getDossierSyntheseFetching = (
  state: Types.RootState,
): boolean | undefined => state.dossierLocation.dossierFetchInfo.fetching;

const getDossierSyntheseError = (state: Types.RootState): any | undefined =>
  state.dossierLocation.dossierFetchInfo.error;

const getCompositionLocation = (state: Types.RootState): string | undefined =>
  state.dossierLocation.data && state.dossierLocation.data.compositionLocation;

// Modifier infos Dossier
const getBienData = (state: Types.RootState): Bien | undefined =>
  state.dossierLocation.data && state.dossierLocation.data.bien;

const getSituationPersoData = (
  state: Types.RootState,
): ISituationPersonnelleFormData | undefined => {
  const data = state.dossierLocation && state.dossierLocation.data;
  if (
    data &&
    data.dossiersParticuliers &&
    state.dossierLocation.selectedPerson
  ) {
    let situationPersoData = data.dossiersParticuliers.find(
      dos => dos.reference === state.dossierLocation.selectedPerson,
    );
    let situationPersoConjoint;
    if (
      state.dossierLocation.data &&
      state.dossierLocation.data.compositionLocation === 'COUPLE' &&
      situationPersoData &&
      situationPersoData.typeCompte !== 'GARANT'
    ) {
      situationPersoData = data.dossiersParticuliers[0];
      situationPersoConjoint = data.dossiersParticuliers.find(
        d => !d.principal && d.typeCompte === 'LOCATAIRE',
      );
    }
    return (
      situationPersoData &&
      dossierParticulierToModificationSituationPersonnelle(
        situationPersoData,
        situationPersoConjoint,
      )
    );
  }
  return undefined;
};

const getSituationProData = (
  state: Types.RootState,
): SituationProfessionnelleFormData | undefined => {
  const data = state.dossierLocation && state.dossierLocation.data;
  if (
    data &&
    data.dossiersParticuliers &&
    state.dossierLocation.selectedPerson
  ) {
    let situationProData = data.dossiersParticuliers.find(
      dos => dos.reference === state.dossierLocation.selectedPerson,
    );
    let situationProConjoint;
    if (
      state.dossierLocation.data &&
      state.dossierLocation.data.compositionLocation === 'COUPLE' &&
      situationProData &&
      situationProData.typeCompte !== 'GARANT'
    ) {
      situationProData = data.dossiersParticuliers[0];
      situationProConjoint = data.dossiersParticuliers.find(
        d => !d.principal && d.typeCompte === 'LOCATAIRE',
      );
    }

    const listSituationsProfessionnelles = SituationProSelectors.getListSituationProfessionnelle(
      state,
    );
    return (
      situationProData &&
      dossierParticulierToModificationSituationProfessionnelle(
        situationProData,
        situationProConjoint,
        listSituationsProfessionnelles,
      )
    );
  }
  return undefined;
};

const getTokenNetheos = (state: Types.RootState): string | undefined => {
  const data = state.dossierLocation && state.dossierLocation.data;
  if (
    data &&
    data.dossiersParticuliers &&
    state.dossierLocation.selectedPerson
  ) {
    let dataSelectedPerson = data.dossiersParticuliers.find(
      dos => dos.reference === state.dossierLocation.selectedPerson,
    );

    if (
      data &&
      data.compositionLocation === 'COUPLE' &&
      dataSelectedPerson &&
      dataSelectedPerson.typeCompte !== 'GARANT'
    ) {
      dataSelectedPerson = data.dossiersParticuliers[0];
    }
    return (
      dataSelectedPerson &&
      dataSelectedPerson.dossierPieces &&
      dataSelectedPerson.dossierPieces.accessToken
    );
  }
  return undefined;
};

const getTokenConjointNetheos = (
  state: Types.RootState,
): string | undefined => {
  const data = state.dossierLocation && state.dossierLocation.data;
  if (
    data &&
    data.dossiersParticuliers &&
    state.dossierLocation.selectedPerson
  ) {
    const dataSelectedPerson = data.dossiersParticuliers.find(
      dos => dos.reference === state.dossierLocation.selectedPerson,
    );
    if (
      data &&
      data.compositionLocation === 'COUPLE' &&
      dataSelectedPerson &&
      dataSelectedPerson.typeCompte !== 'GARANT'
    ) {
      const dataConjoint = data.dossiersParticuliers.find(
        d => !d.principal && d.typeCompte === 'LOCATAIRE',
      );
      return (
        dataConjoint &&
        dataConjoint.dossierPieces &&
        dataConjoint.dossierPieces.accessToken
      );
    }
  }
  return undefined;
};

const getCalculError = (state: Types.RootState): any | undefined =>
  state.dossierLocation.calculFetchInfo &&
  state.dossierLocation.calculFetchInfo.error;

const getCalculFetching = (state: Types.RootState): boolean =>
  state.dossierLocation.calculFetchInfo &&
  state.dossierLocation.calculFetchInfo.fetching;

const getDossierDuplique = (state: Types.RootState): string | undefined =>
  state.dossierLocation.duplicationDossier;

const getDuplicationFetching = (state: Types.RootState): boolean =>
  state.dossierLocation.dupliquerFetchInfo &&
  state.dossierLocation.dupliquerFetchInfo.fetching;

const getAccepterRefuserLocationError = (
  state: Types.RootState,
): any | undefined =>
  state.dossierLocation.accepterRefuserLocationFetchInfo &&
  state.dossierLocation.accepterRefuserLocationFetchInfo.error;

const getAccepterRefuserLocationFetching = (state: Types.RootState): boolean =>
  state.dossierLocation.accepterRefuserLocationFetchInfo &&
  state.dossierLocation.accepterRefuserLocationFetchInfo.fetching;

const getDuplicationError = (state: Types.RootState): any | undefined =>
  state.dossierLocation.dupliquerFetchInfo.error;

const getForcerStatutFetching = (state: Types.RootState): boolean =>
  state.dossierLocation.forcerStatutFetchInfo &&
  state.dossierLocation.forcerStatutFetchInfo.fetching;

const getForcerStatutError = (state: Types.RootState): any | undefined =>
  state.dossierLocation.forcerStatutFetchInfo.error;

const getPDF = (state: Types.RootState): any | undefined =>
  state.dossierLocation && state.dossierLocation.pdf64;

const getGenererFetching = (state: Types.RootState): boolean =>
  state.dossierLocation.fetchInfoGenerer &&
  state.dossierLocation.fetchInfoGenerer.fetching;

const getGenererError = (state: Types.RootState): boolean =>
  state.dossierLocation.fetchInfoGenerer &&
  state.dossierLocation.fetchInfoGenerer.error;
const getRetourLocationAcceptee = (state: Types.RootState): any =>
  state.dossierLocation && state.dossierLocation.dataRetourLocationAcceptee;

export const Selectors = {
  getDossierLocation,
  getSelectedDossier,
  getDossierLocationFetching,
  getDossierLocationError,
  getResultDossierLocation,
  getResultDossierLocationFetching,
  getResultDossierLocationError,
  getFiltreRechercheDossierLocation,
  getAlgo,
  getAlgoError,
  getAlgoFetching,
  getDossierSyntheseFetching,
  getDossierSyntheseError,
  getCompositionLocation,
  getBienData,
  getSituationPersoData,
  getSituationProData,
  getSelectedPerson,
  getTokenNetheos,
  getTokenConjointNetheos,
  getStatutDossier,
  getCalculFetching,
  getCalculError,
  getDossierDuplique,
  getDuplicationFetching,
  getAccepterRefuserLocationError,
  getAccepterRefuserLocationFetching,
  getDuplicationError,
  getForcerStatutFetching,
  getForcerStatutError,
  getPDF,
  getGenererFetching,
  getGenererError,
  getRetourLocationAcceptee,
};

export const reducer = (
  state: DossierLocationState = initialState,
  action: ActionType<typeof ActionCreators>,
): DossierLocationState => {
  switch (action.type) {
    case getType(createOrUpdateDossierLocationAsync.request):
      return {
        ...state,
        fetchInfo: {
          fetching: true,
          error: undefined,
        },
      };

    case getType(createOrUpdateDossierLocationAsync.failure):
      return {
        ...state,
        fetchInfo: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(createOrUpdateDossierLocationAsync.success):
      return {
        ...state,
        data: action.payload,
        fetchInfo: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(changeCompositionLocation):
      return {
        ...state,
      };
    case getType(supprimerDossierLocationError):
      return {
        ...state,
        fetchInfo: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(resetDossierLocationParcoursCreation):
      return {
        ...state,
        data: undefined,
        selectedPerson: undefined,
        fetchInfo: {
          fetching: false,
          error: undefined,
        },
      };

    case getType(getDossiersLocation.request):
      return {
        ...state,
        dossiersLocation: undefined,
        selectedPerson: undefined,
        selectedDossier: undefined,
        duplicationDossier: undefined,
        searchFetchInfo: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(setFiltreRechercheDossiers):
      return {
        ...state,
        filtreRecherche: action.payload,
      };
    case getType(getDossiersLocation.success):
      return {
        ...state,
        dossiersLocation: action.payload,
        data: undefined,
        searchFetchInfo: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(getDossiersLocation.failure):
      return {
        ...state,
        searchFetchInfo: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(setSelectedDossier):
      return {
        ...state,
        selectedDossier: action.payload,
      };
    case getType(setSelectedPerson):
      return {
        ...state,
        selectedPerson: action.payload,
      };
    case getType(resetSelectedPerson):
      return {
        ...state,
        selectedPerson: undefined,
      };
    case getType(getSyntheseDossier.request):
      return {
        ...state,
        data: undefined,
        dossierFetchInfo: {
          fetching: true,
          error: undefined,
        },
      };

    case getType(getSyntheseDossier.success):
      return {
        ...state,
        data: action.payload,
        calculFetchInfo: {
          fetching: false,
          error: undefined,
        },
        dossierFetchInfo: {
          fetching: false,
          error: undefined,
        },
      };

    case getType(getSyntheseDossier.failure):
      return {
        ...state,
        dossierFetchInfo: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(addGarant):
      return {
        ...state,
      };
    case getType(getRapportAlgo.request):
      return {
        ...state,
        rapportAlgo: undefined,
        algoFetchInfo: {
          fetching: true,
          error: undefined,
        },
      };

    case getType(getRapportAlgo.success):
      return {
        ...state,
        rapportAlgo: action.payload.dossierLocation,
        algoFetchInfo: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(getRapportAlgo.failure):
      return {
        ...state,
        algoFetchInfo: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(calculLoyerMax.request):
      return {
        ...state,
        calculFetchInfo: {
          fetching: true,
          error: undefined,
        },
      };

    case getType(calculLoyerMax.success):
      return {
        ...state,
        calculFetchInfo: {
          fetching: false,
          error: undefined,
        },
      };

    case getType(calculLoyerMax.failure):
      return {
        ...state,
        calculFetchInfo: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(setDuplicationDossier):
      return {
        ...state,
        duplicationDossier: action.payload,
      };
    case getType(resetDuplicationDossier):
      return {
        ...state,
        duplicationDossier: undefined,
      };

    case getType(dupliquerDossier.request):
      return {
        ...state,
        dupliquerFetchInfo: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(accepterDossier.request):
      return {
        ...state,
        dataRetourLocationAcceptee: undefined,
        accepterRefuserLocationFetchInfo: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(accepterDossier.success):
      return {
        ...state,
        dataRetourLocationAcceptee: action.payload,
        accepterRefuserLocationFetchInfo: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(accepterDossier.failure):
      return {
        ...state,
        dataRetourLocationAcceptee: undefined,
        accepterRefuserLocationFetchInfo: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(annulerAcceptationDossier.request):
      return {
        ...state,
        accepterRefuserLocationFetchInfo: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(annulerAcceptationDossier.success):
      return {
        ...state,
        accepterRefuserLocationFetchInfo: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(annulerAcceptationDossier.failure):
      return {
        ...state,
        accepterRefuserLocationFetchInfo: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(supprimerAcceptationOuRefusDossierError):
      return {
        ...state,
        accepterRefuserLocationFetchInfo: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(dupliquerDossier.success):
      return {
        ...state,
        duplicationDossier: undefined,
        dupliquerFetchInfo: {
          fetching: false,
          error: undefined,
        },
      };

    case getType(dupliquerDossier.failure):
      return {
        ...state,
        dupliquerFetchInfo: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(forcerStatutDossier.request):
      return {
        ...state,
        forcerStatutFetchInfo: {
          fetching: true,
          error: undefined,
        },
      };

    case getType(forcerStatutDossier.success):
      return {
        ...state,
        forcerStatutFetchInfo: {
          fetching: false,
          error: undefined,
        },
      };

    case getType(forcerStatutDossier.failure):
      return {
        ...state,
        forcerStatutFetchInfo: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(deleteDossierLocation.request):
      return {
        ...state,
        fetchInfo: {
          fetching: true,
          error: undefined,
        },
      };

    case getType(deleteDossierLocation.success):
      return {
        ...state,
        fetchInfo: {
          fetching: false,
          error: undefined,
        },
      };

    case getType(deleteDossierLocation.failure):
      return {
        ...state,
        fetchInfo: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(genererCertification.request):
      return {
        ...state,
        fetchInfoGenerer: {
          fetching: true,
          error: undefined,
        },
      };

    case getType(genererCertification.success):
      return {
        ...state,
        pdf64: action.payload,
        fetchInfoGenerer: {
          fetching: false,
          error: undefined,
        },
      };

    case getType(genererCertification.failure):
      return {
        ...state,
        fetchInfoGenerer: {
          fetching: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
