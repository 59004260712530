import { emphasize, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    // tslint:disable quotemark
    root: {
      width: '100%',
      flexGrow: 1,
      minWidth: 290,
      border: '1px solid ' + theme.palette.primary.main,
      '& .AutoComplete-label': {
        top: 0,
        left: 0,
        zIndex: 1,
        position: 'absolute',
        color: '#000000',
        fontSize: '0.8rem',
        transform: 'translate(12px, 14px) scale(1)',
        fontWeight: 'bold',
        transition:
          'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        '&.Mui-focused, &.AutoComplete-labelShrink': {
          transform: 'translate(12px, 4px) scale(0.75)',
          fontSize: '1em',
          fontWeight: 'normal',
          color: theme.palette.grey[400],
        },
      },
      '& label + .MuiInput-formControl': {
        marginTop: 0,
        height: 39,
      },
    },
    input: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 0,
      height: 39,
      background: 'white',
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
      paddingLeft: 12,
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2),
      fontSize: 13,
    },
    singleValue: {
      padding: '15px 12px 7px 0',
      fontSize: '0.9rem',
      fontWeight: 'bold',
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      bottom: 6,
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 10,
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing(2),
    },
    // tslint:enable quotemark
  } as any);

export default styles;
