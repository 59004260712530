import { Button, FormControl, TextField, withStyles } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';
import styles from './styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

export interface IOwnProps {
  label?: string;
  required?: boolean;
  type?: string;
  classes?: any;
  minValue?: number;
  maxValue?: number;
  initialValue?: number;
  onIncrement?: any;
  onDecrement?: any;
}

const ButtonCounter = props => (
  <Button variant="outlined" {...props}>
    {props.children}
  </Button>
);

interface IState {
  value: number;
}

class InputCounter extends React.Component<IOwnProps, IState> {
  constructor(props) {
    super(props);
    const inputValue = props.initialValue;
    this.state = {
      value: inputValue,
    };
  }

  componentDidUpdate(prevProps: IOwnProps, prevState: IState) {
    const initialValue = this.props.initialValue || 0;
    if (prevState.value !== initialValue) {
      this.setState({
        value: initialValue,
      });
    }
  }

  onIncrement = () => {
    let val = this.state.value;
    const { maxValue, onIncrement } = this.props;
    if ((maxValue && val < maxValue) || !maxValue) {
      val = val + 1;
      this.setState({
        value: val,
      });

      onIncrement();
    }
  };

  onDecrement = () => {
    let val = this.state.value;
    const { minValue, onDecrement } = this.props;
    if ((minValue && val > minValue) || (!minValue && val > 0)) {
      val = val - 1;
      this.setState({
        value: val,
      });

      onDecrement();
    }
  };

  render() {
    const { required, classes, label } = this.props;

    return (
      <FormControl className={classes.counterRoot}>
        <InputLabel className={classes.counterLabel}>
          {label} {required && '*'}
        </InputLabel>
        <ButtonCounter
          className={classes.buttonCounter}
          onClick={this.onDecrement}
        >
          <RemoveIcon />
        </ButtonCounter>
        <TextField
          value={this.state.value}
          required={required}
          className={classes.counterTextField}
          InputProps={{
            classes: {
              input: classes.textFieldCounter,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabel,
            },
          }}
          variant="filled"
        />
        <ButtonCounter
          className={classes.buttonCounter}
          onClick={this.onIncrement}
        >
          <AddIcon />
        </ButtonCounter>
      </FormControl>
    );
  }
}

export default withStyles(styles)(InputCounter);
