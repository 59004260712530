import React from 'react';

// tslint:disable: max-line-length
export default class ColocataireSVG extends React.Component {
  render() {
    return (
      <svg width="83" height="83" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <circle id="path-1" cx="41.5" cy="41.5" r="41.5" />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <mask id="mask-2" fill="white">
            <use href="#path-1" />
          </mask>
          <use id="Mask" fill="rgba(92, 81, 117, 0.25)" href="#path-1" />
          <g id="Group-29" mask="url(#mask-2)">
            <g transform="translate(-9.000000, 23.000000)">
              <path
                d="M17.6068,0.0002 C8.8818,0.0002 1.8088,7.1412 1.8088,15.9502 C1.8088,24.7592 8.8818,31.9012 17.6068,31.9012 C26.3328,31.9012 33.4058,24.7592 33.4058,15.9502 C33.4058,7.1412 26.3328,0.0002 17.6068,0.0002"
                id="Fill-1"
                fill="#FFFFFF"
              />
              <path
                d="M23.7572,34.7311 L23.4582,35.0021 C20.1472,38.0071 15.0582,37.8781 11.9032,34.7081 C10.1652,34.7491 8.5232,35.1601 7.0372,35.8531 C7.1022,35.8421 7.1452,35.8361 7.1452,35.8361 L8.1942,59.2971 C11.0072,59.9611 13.9412,60.3161 16.9582,60.3161 C20.4762,60.3161 23.8762,59.8271 27.1102,58.9351 L28.1422,35.8361 C26.7942,35.2131 25.3172,34.8241 23.7572,34.7311"
                id="Fill-3"
                fill="#FFFFFF"
              />
              <path
                d="M28.1419,35.8361 L27.1099,58.9351 C29.9569,58.1491 32.6759,57.0551 35.2139,55.6681 L35.2139,46.8821 C35.2139,41.9811 32.3159,37.7671 28.1419,35.8361"
                id="Fill-5"
                fill="#000000"
              />
              <path
                d="M18.4037,42.3639 C18.4037,42.7629 18.0807,43.0869 17.6807,43.0869 C17.2827,43.0869 16.9587,42.7629 16.9587,42.3639 C16.9587,41.9649 17.2827,41.6409 17.6807,41.6409 C18.0807,41.6409 18.4037,41.9649 18.4037,42.3639"
                id="Fill-9"
                fill="#000000"
              />
              <path
                d="M18.4037,49.9313 C18.4037,50.3303 18.0807,50.6543 17.6807,50.6543 C17.2827,50.6543 16.9587,50.3303 16.9587,49.9313 C16.9587,49.5323 17.2827,49.2083 17.6807,49.2083 C18.0807,49.2083 18.4037,49.5323 18.4037,49.9313"
                id="Fill-11"
                fill="#000000"
              />
              <path
                d="M86.3226,43.3102 C86.3226,43.7092 85.9996,44.0332 85.5996,44.0332 C85.2006,44.0332 84.8776,43.7092 84.8776,43.3102 C84.8776,42.9112 85.2006,42.5872 85.5996,42.5872 C85.9996,42.5872 86.3226,42.9112 86.3226,43.3102"
                id="Fill-13"
                fill="#000000"
              />
              <path
                d="M86.3226,50.8781 C86.3226,51.2771 85.9996,51.6011 85.5996,51.6011 C85.2006,51.6011 84.8776,51.2771 84.8776,50.8781 C84.8776,50.4791 85.2006,50.1551 85.5996,50.1551 C85.9996,50.1551 86.3226,50.4791 86.3226,50.8781"
                id="Fill-15"
                fill="#000000"
              />
              <path
                d="M51.2357,0.2692 C42.5097,0.2692 35.4377,7.4102 35.4377,16.2192 C35.4377,25.0292 42.5097,32.1702 51.2357,32.1702 C59.9617,32.1702 67.0337,25.0292 67.0337,16.2192 C67.0337,7.4102 59.9617,0.2692 51.2357,0.2692"
                id="Fill-17"
                fill="#FFFFFF"
              />
              <path
                d="M61.7708,36.1052 C60.4228,35.4812 58.9448,35.0932 57.3858,35.0002 L57.0868,35.2712 C53.7758,38.2762 48.6858,38.1472 45.5318,34.9772 C43.7938,35.0182 42.1508,35.4292 40.6668,36.1222 C36.5118,38.0622 33.6278,42.2632 33.6278,47.1512 L33.6278,56.6062 C36.2078,57.8892 38.9528,58.8892 41.8218,59.5662 C44.6358,60.2302 47.5698,60.5852 50.5868,60.5852 C54.1048,60.5852 57.5048,60.0962 60.7388,59.2042 C63.5848,58.4182 66.3038,57.3242 68.8428,55.9372 L68.8428,47.1512 C68.8428,42.2502 65.9448,38.0362 61.7708,36.1052"
                id="Fill-19"
                fill="#FFFFFF"
              />
              <path
                d="M51.2357,43.3102 C51.2357,43.7092 50.9127,44.0332 50.5127,44.0332 C50.1137,44.0332 49.7907,43.7092 49.7907,43.3102 C49.7907,42.9112 50.1137,42.5872 50.5127,42.5872 C50.9127,42.5872 51.2357,42.9112 51.2357,43.3102"
                id="Fill-21"
                fill="#000000"
              />
              <path
                d="M51.2357,50.8781 C51.2357,51.2771 50.9127,51.6011 50.5127,51.6011 C50.1137,51.6011 49.7907,51.2771 49.7907,50.8781 C49.7907,50.4791 50.1137,50.1551 50.5127,50.1551 C50.9127,50.1551 51.2357,50.4791 51.2357,50.8781"
                id="Fill-23"
                fill="#000000"
              />
              <path
                d="M84.8001,0.0002 C75.9951,0.0002 68.8591,7.2062 68.8591,16.0942 C68.8591,24.9832 75.9951,32.1882 84.8001,32.1882 C93.6041,32.1882 100.7401,24.9832 100.7401,16.0942 C100.7401,7.2062 93.6041,0.0002 84.8001,0.0002"
                id="Fill-25"
                fill="#FFFFFF"
              />
              <path
                d="M95.43,36.1589 C94.071,35.5299 92.578,35.1379 91.005,35.0439 L90.703,35.3179 C87.363,38.3499 82.227,38.2199 79.044,35.0209 C77.291,35.0619 75.633,35.4769 74.135,36.1769 C69.944,38.1329 67.034,42.3729 67.034,47.3049 L67.034,56.8449 C69.636,58.1399 72.406,59.1479 75.301,59.8319 C78.141,60.5019 81.101,60.8599 84.146,60.8599 C87.695,60.8599 91.125,60.3659 94.388,59.4659 C97.261,58.6729 100.004,57.5689 102.566,56.1689 L102.566,47.3049 C102.566,42.3589 99.641,38.1079 95.43,36.1589"
                id="Fill-27"
                fill="#000000"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
// tslint:enable: max-line-length
