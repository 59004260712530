import { Theme } from '@material-ui/core/styles';

// tslint:disable: quotemark
const styles = (_theme: Theme) =>
({
  container: {
    position: 'fixed',
    bottom: 24,
    right: 24,
    background: '#124779',
    color: 'white',
    boxShadow: '0 0 10px rgba(0,0,0,0.2)',
    borderRadius: 16,
    fontWeight: 'bold',
    padding: '0px !important',
    '& a': {
      fontSize: 17,
      letterSpacing: 0.5,
      lineHeight: '25px',
      padding: '16px 12px',
      paddingRight: 18,
      color: 'white',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: 10,
      },
      '& .vertuloo-font-mdSm': {
        paddingLeft: 4,
      },
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      background: '#124779',
      width: '28px',
      zIndex: -1,
      bottom: -6,
      right: 8,
      height: 28,
      transform: 'rotate(45deg)',
    },
    '&:hover, &:focus': {
      background: '#082037',
      '&:before': { background: '#082037' },
    },
  },
  text2: {
    fontSize: 20,
    letterSpacing: 1,
  },
  '@media all and (max-width: 789px)': {
    container: {
      '& a': {
        fontSize: 14,
        '& span': {
          display: 'flex',
        },
        '& .vertuloo-font-mdSm': {
          fontSize: 18,
          padding: '0 4px',
        },
      },
    },
    text2: {
      fontSize: 14,
    },
  },
} as any);
// tslint:enable: quotemark

export default styles;
