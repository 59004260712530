import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Titre from '../../../components/titre/Titre';
import Libelle from '../../../_i18n/fr.json';
import styles from './styles';
import ContactForm from './ContactForm';
import BlocFonctions from './BlocFonctions';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
}

class Landing extends React.Component<Props> {
  render() {
    // <Link to="/protection-des-donnees">
    //   {Libelle.pageLanding.rgpd}
    // </Link>;
    const { classes } = this.props;
    return (
      <>
        <Grid
          container
          justify="center"
          className={classes.bigTitleLanding + ' bigTitle'}
        >
          <Titre libelle={Libelle.pageLanding.h1} variant="h1" />
          <Titre
            libelle="avec vertuloo votre plateforme digitale"
            variant="h2"
          />
        </Grid>
        <div className={classes.contactContainer}>
          <div className={classes.grosRond} />
          <iframe
            className={classes.iframeYoutube}
            title="youtube"
            width="645"
            height="365"
            src="https://www.youtube-nocookie.com/embed/R6eiU7S4mlE"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
          <ContactForm />
        </div>
        <BlocFonctions />
        <div className={classes.container} />
      </>
    );
  }
}

export default withStyles(styles)(Landing);
