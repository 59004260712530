import React from 'react';
import { AdministrateurBienDetail } from '../../../../services/Api/swagger';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import styles from '../../styles';
/**
 * Titre des colonnes de notre tableau des profils utilisateurs.
 */
const dataHeaderProfils = [
  {
    name: 'Identifiant',
    prop: 'idProfilProps',
  },
  {
    name: 'Nom',
    prop: 'nomProfilProps',
  },
];
export interface Props {
  classes?: any;
  dataConsultation: AdministrateurBienDetail;
}
const ProfilsUtilisateurs = (props: Props) => {
  const { dataConsultation, classes } = props;

  return (
    <Grid item>
      <h3>Liste des profils utilisateurs</h3>
      {dataConsultation.applications !== undefined &&
      dataConsultation.applications[0] &&
      dataConsultation.applications[0].profils ? (
        <Table className={'boxShadow'}>
          <TableHead>
            <TableRow className={classes.tableauProfilsHeader}>
              {dataHeaderProfils.map(n => {
                return (
                  <TableCell key={n.prop} component="th" scope="row">
                    {n.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataConsultation.applications[0].profils.map(n => {
              return (
                <TableRow
                  key={n.identifiant}
                  className={classes.tableauProfils}
                >
                  <TableCell>{n.nom}</TableCell>
                  <TableCell>{n.description}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <span>Aucun profil renseigné</span>
      )}
    </Grid>
  );
};

export default withStyles(styles)(ProfilsUtilisateurs);
