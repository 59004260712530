import { withStyles } from '@material-ui/core';
import React from 'react';
import infographiePdf from '../../../../ressources/images/infographie-vertuloo-en-chiffres.pdf';
import infographie from '../../../../ressources/images/infographie-vertuloo.jpg';
import defaultStyles from '../styles';
import styles from './styles';

interface IProps {
  classes?: any;
}

const BlocInfographie = (props: IProps) => (
  <div className={props.classes.containerFlex}>
    <div>
      <img src={infographie} width={400} alt="Immo Matin" />
    </div>
    <div className={props.classes.description}>
      <h2>
        VERTULOO : <br />
        RETOUR EN CHIFFRES
      </h2>
      <p>
        Après une année d'utilisation de la plateforme digitale Vertuloo chez
        différents administrateurs de biens, nous avons analysé les impacts
        d'acceptation sur leurs dossiers de location. <br />
        Des dossiers refusés hier par les assureurs peuvent être aujourd'hui
        couverts grâce à Vertuloo contre les impayés de loyers.
      </p>
      <a
        href={infographiePdf}
        target="_blank"
        rel="noopener noreferrer"
        className={props.classes.linkPlus}
      >
        en savoir plus
      </a>
    </div>
  </div>
);

export default withStyles(theme => ({
  ...styles(theme),
  ...defaultStyles(theme),
}))(BlocInfographie);
