import { Theme } from '@material-ui/core/styles';
import { bleu } from '../../../theme';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
  ({
    accueilBloc: {
      padding: 0,
      margin: '34px auto !important',
      [theme.breakpoints.down('sm')]: {
        padding: '0 1.25rem',
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        width: 900,
      },
      [theme.breakpoints.up('lg')]: {
        width: 1100,
      },
    },
    accueilBlocEntete: {
      backgroundColor: 'red',
    },
    blocEnteteLogo: {
      marginTop: '-3.25rem !important',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    blocEnteteSlogan: {
      paddingTop: 38,
      paddingLeft: 50,
      paddingBottom: 42,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 10,
        paddingLeft: 0,
        margin: 'auto',
      },
      '& > h1': {
        fontSize: 24,
        fontWeight: 'bold',
      },
    },
    blocEnteteSousTitre: {
      fontSize: 24,
      marginBottom: 23,
    },
    blocEnteteAvantages: {
      flexWrap: 'nowrap',
      '& :first-child': {
        marginLeft: 0,
      },
    },
    blocEnteteAvantage: {
      marginLeft: 50,
      display: 'flex',
      alignItems: 'center',
      fontSize: 18,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 20,
      },
    },
    blocEntetePictoAvantages: {
      borderRadius: '50%',
      width: 25,
      height: 25,
      marginRight: 8,
    },
    accueilBlocCCM: {
      margin: '35px auto',
      '& > h2': {
        margin: 0,
        marginBottom: '1.25rem',
      },
    },
    accueilBlocDescriptif: {
      margin: '35px auto',
      color: theme.palette.common.black,
      '& > p ': {
        margin: 'auto',
        marginTop: 16,
        [theme.breakpoints.down('sm')]: {
          width: '90%',
        },
        [theme.breakpoints.up('md')]: {
          width: 900,
        },
      },
    },
    accueilBlocTemoignages: {
      padding: '1.875rem 0',
    },
    linkPlus: {
      textDecoration: 'none',
      color: '#FFF',
      background: theme.palette.primary.main,
      borderRadius: 20,
      padding: '8px 16px 10px 16px',
      fontWeight: '600',
      fontSize: 13,
      boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.4)',
    },
    linkPlusBleu: {
      background: bleu,
      '&:hover, &:focus, &:active': {
        background: '#012644',
      },
    },
    containerYoutube: {
      width: 'calc(100% - 24px)',
      margin: '24px 4px',
      display: 'flex',
      justifyContent: 'center',
    },
    iframeYoutube: {
      borderRadius: '4px',
      border: 0,
    },
    '@media all and (max-width: 650px)': {
      iframeYoutube: {
        width: 480,
        height: 280,
      },
    },
    '@media all and (max-width: 500px)': {
      iframeYoutube: {
        width: 420,
        height: 230,
      },
    },
  } as any);
// tslint:enable: quotemark

export default styles;
