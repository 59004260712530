import { Grid } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Layout from '../../../components/Layout';
import Snack from '../../../components/Snack';
import {
  ActionCreators as UtilisateursActionsCreators,
  Selectors as UtilisateursSelectors,
} from '../../../redux/Utilisateurs';
import { Profil, Utilisateur } from '../../../services/Api/swagger';
import { history } from '../../../helpers/history';
import Libelle from '../../../_i18n/fr.json';
import { transformUtilisateurBesseDataToModel } from '../helpers';
import UtilisateurBesseForm from '../UtilisateurBesseForm';

interface Props {
  utilisateur?: Utilisateur;
  ajouterUtilisateur(params: Utilisateur): void;
  utilisateurConnecte?: Utilisateur;
  getProfilsBesse(params: string): void;
  profilsBesse?: Profil[];
  fetching: boolean;
  utilisateurError?: any;
  emptyErrorMessages(): void;
}

class ConsulterUtilisateurBesse extends React.Component<Props> {
  componentDidMount() {
    // on récupère la liste des profils BESSE
    this.props.getProfilsBesse('AGREMENT');
  }
  handleSubmit = values => {
    const transformedValues = transformUtilisateurBesseDataToModel(values);
    this.props.ajouterUtilisateur(transformedValues);
  };

  handlePrecedent = () => {
    history.push('/mes-utilisateurs');
  };

  handleClose = () => {
    this.props.emptyErrorMessages();
  };
  renderError = (error: any) => {
    return (
      <Snack
        onClose={this.handleClose}
        open={true}
        variant="error"
        message={error.message}
      />
    );
  };
  render() {
    const {
      utilisateurError,
      utilisateurConnecte,
      profilsBesse,
      fetching,
    } = this.props;
    const utilisateurBESSE =
      utilisateurConnecte && utilisateurConnecte.role === 'BESSE';
    return (
      <Layout title={Libelle.pageUtilisateurs.ajouter.titre}>
        <div>
          <h2>{Libelle.pageUtilisateurs.ajouter.titre} Bessé</h2>
          <UtilisateurBesseForm
            profilsBesse={profilsBesse}
            onSubmit={this.handleSubmit}
            handlePrecedent={this.handlePrecedent}
            utilisateurBESSE={utilisateurBESSE}
            isFetching={fetching}
          />
          <Grid container justify="flex-end" style={{ marginTop: 12 }}>
            {utilisateurError && this.renderError(utilisateurError)}
          </Grid>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  utilisateurConnecte: UtilisateursSelectors.getUtilisateurConnecte(state),
  profilsBesse: UtilisateursSelectors.getProfilsBesseListe(state),
  fetching: UtilisateursSelectors.getUtilisateurFetching(state),
  utilisateurError: UtilisateursSelectors.getUtilisateurError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getProfilsBesse: (params: string) =>
    dispatch(UtilisateursActionsCreators.getProfilsBesse.request(params)),
  ajouterUtilisateur: (params: Utilisateur) =>
    dispatch(UtilisateursActionsCreators.ajouterUtilisateur.request(params)),
  emptyErrorMessages: () =>
    dispatch(UtilisateursActionsCreators.emptyErrorMessages()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConsulterUtilisateurBesse);
