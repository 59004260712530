import { withStyles } from '@material-ui/core';
import React from 'react';
import {
  getInfoMaintenance,
  getMessageMaintenance,
} from '../../../../services/Config';
import defaultStyles from '../styles';
import styles from './styles';

interface IProps {
  classes?: any;
}

class BlocMaintenance extends React.Component<IProps> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {getInfoMaintenance() === 'true' && (
          <div className={classes.maintenanceTexte}>
            <h2>INFORMATION</h2>
            <h3>
              {getMessageMaintenance()}
            </h3>
            {/* <div className={classes.divider} /> */}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(theme => ({
  ...styles(theme),
  ...defaultStyles(theme),
}))(BlocMaintenance);
