import React from 'react';

const SituationPersoIconSVG = () => (
  <svg
    id="Calque_1"
    data-name="Calque 1"
    viewBox="0 0 500 500"
    width="20"
    height="20"
    fill="#000"
  >
    <defs>
      <style>{'.cls-1{fill:#000;}'}</style>
      <mask
        id="mask"
        x={90.151}
        y={304.542}
        width={306.191}
        height={190.003}
        maskUnits="userSpaceOnUse"
        fill="#000"
      >
        <g id="mask-2" fill="#000">
          <polygon
            id="path-1"
            className="cls-1"
            points="90.151 304.542 396.342 304.542 396.342 494.537 90.151 494.537 90.151 304.542"
          />
        </g>
      </mask>
    </defs>
    <g id="Group-10" fill="#000">
      <path
        id="Fill-1"
        fill="#000"
        className="cls-2"
        d="M243.246,33.68c61.411,0,111.36,49.641,111.36,110.664s-49.949,110.678-111.36,110.678-111.36-49.655-111.36-110.678S181.835,33.68,243.246,33.68m0,246.885c75.858,0,137.368-60.981,137.368-136.221S319.1,8.137,243.246,8.137,105.879,69.118,105.879,144.344s61.509,136.221,137.367,136.221"
      />
      <g id="Group-5" fill="#000">
        <g className="cls-3" fill="#000">
          <path
            id="Fill-3"
            fill="#000"
            className="cls-2"
            d="M237.607,468.988a309.934,309.934,0,0,1-72.178-8.769L159.4,339.09a79.6,79.6,0,0,1,25.356-8.227,100.235,100.235,0,0,0,120.2.542,80.069,80.069,0,0,1,18.593,6.059l-6.042,120.99a311.517,311.517,0,0,1-79.9,10.534m59.119-164.252-2.6,2.321a73.732,73.732,0,0,1-100.478-2.515C136.28,305.848,90.151,351.848,90.151,408.507v52.045a336.941,336.941,0,0,0,306.191-5.725v-46.32c0-55.38-44.063-100.519-99.616-103.771"
          />
        </g>
      </g>
      <path
        id="Fill-6"
        fill="#000"
        className="cls-2"
        d="M243.246,378.371a9.862,9.862,0,1,0,10.047,9.867,9.95,9.95,0,0,0-10.047-9.867"
      />
      <path
        id="Fill-8"
        fill="#000"
        className="cls-2"
        d="M243.246,420.063a9.862,9.862,0,1,0,10.047,9.867,9.95,9.95,0,0,0-10.047-9.867"
      />
    </g>
  </svg>
);

export default SituationPersoIconSVG;
