import {
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Libelle from '../../_i18n/fr.json';
import styles from './styles';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  // Numero de page en cours
  page: number;
  // Nombre de resultat total
  nbrElementTotal: number;
  // Nombre d'élément par page
  nbrElementParPage: number;
  // Liste des options représentant le nombre d'élément souhaitant être affiché par page
  nbrElementParPageOptions?: number[];
  // Fonction pour changer de page
  onChangerPage: (event, page) => void;
  // Fonction pour changer le nombre de résultat affiché
  onChangerNbrResultatParPage: (event, nbrElement) => void;
  colSpan: number;
}

class Pagination extends React.Component<Props> {
  /**
   * Valeurs d'initialisation
   */
  public static defaultProps = {
    nbrElementParPageOptions: [5, 10, 20],
  };

  /**
   * Internationalisation des informlations de navigation
   */
  resultatAffiche = () => {
    const { nbrElementTotal, page, nbrElementParPage } = this.props;
    const from = nbrElementTotal === 0 ? 0 : page * nbrElementParPage + 1;
    const to = Math.min(nbrElementTotal, (page + 1) * nbrElementParPage);
    return `${from}-${to} sur ${nbrElementTotal}`;
  };

  render() {
    const {
      page,
      nbrElementTotal,
      nbrElementParPage,
      nbrElementParPageOptions,
      onChangerPage,
      onChangerNbrResultatParPage,
      classes,
      colSpan,
    } = this.props;

    return (
      <TableFooter>
        <TableRow className={classes.footer}>
          <TableCell>
            {nbrElementTotal}
            {nbrElementTotal > 0
              ? Libelle.tableau.resultat
              : Libelle.tableau.aucun}{' '}
          </TableCell>
          <TablePagination
            colSpan={colSpan}
            count={nbrElementTotal}
            rowsPerPage={nbrElementParPage}
            rowsPerPageOptions={nbrElementParPageOptions}
            labelDisplayedRows={this.resultatAffiche}
            labelRowsPerPage={Libelle.tableau.pagination.resultat}
            page={page}
            onChangePage={(event, page) => onChangerPage(event, page)}
            onChangeRowsPerPage={event =>
              onChangerNbrResultatParPage(event, event.target.value)
            }
          />
        </TableRow>
      </TableFooter>
    );
  }
}

export default withStyles(styles)(Pagination);
