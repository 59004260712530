import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { isPristine } from 'redux-form';
import Types from 'Types';
import CheminDeFer from '../../../components/cheminDeFer/CheminDeFer';
import Layout from '../../../components/Layout';
import LoadingBoundary from '../../../components/LoadingBoundary';
import { history } from '../../../helpers/history';
import { Selectors as DossierLocationSelector } from '../../../redux/DossierLocation';
import {
  ActionCreators as LocalisationActionCreator,
  Selectors as LocalisationSelectors,
} from '../../../redux/Localisation';
import {
  ActionCreators as SituationPersonnelleActionCreator,
  Selectors as SituationPersonnelleSelector,
} from '../../../redux/SituationPersonnelle';
import {
  ActionCreators as TypeVoieActions,
  Selectors as TypeVoieSelectors,
} from '../../../redux/TypeVoie';
import {
  Commune,
  DossierLocation,
  TypeVoie,
} from '../../../services/Api/swagger';
import Libelle from '../../../_i18n/fr.json';
import RenderError from '../../ErrorContainer';
import {
  getSituationPersonnelleModel,
  ISituationPersonnelleFormData,
  qualites,
  situationMaritale,
  statutOccupation,
} from './helpers';
import SituationPersonnelleForm from './situationPersonnelleForm';
import styles from './style';

interface Props {
  situationPersonnelleData: Types.SituationPersonnelleData;
  dossierLocation: DossierLocation;
  situationPersonnelleError: any;
  situationPersonnelleFetching: boolean;
  typesVoie: TypeVoie[];
  typeVoieFetching: boolean;
  typeVoieError: any;
  chargerListTypeVoie(): void;
  creerOuModifierSituationPersonnelle(
    situationPersonnelle: Types.SituationPersonnelleData,
  );
  supprimerSituationPersonnelleError(): void;
  chargerCommunes(commune: Commune): void;
  communes: Commune[];
  classes?: any;
  compositionLocation?: string;
  dossierLocationRef: string;
  pristine: boolean;
}

class AjouterSituationPersonnellePage extends React.Component<Props> {
  componentDidMount() {
    this.loadInitData();
  }

  loadInitData = () => {
    this.props.chargerListTypeVoie();
  };

  handleSubmit = (situationPersonnelleForm: ISituationPersonnelleFormData) => {
    const {
      dossierLocation,
      creerOuModifierSituationPersonnelle,
      dossierLocationRef,
      compositionLocation,
    } = this.props;

    const situations = getSituationPersonnelleModel(
      situationPersonnelleForm,
      dossierLocation,
      dossierLocationRef,
      compositionLocation,
    );
    if (!this.props.pristine) {
      creerOuModifierSituationPersonnelle(situations);
    } else {
      history.push('/dossier-location/situation-professionnelle');
    }
  };

  chargerCommunes = (commune: Commune) => {
    this.props.chargerCommunes(commune);
  };

  render() {
    const {
      typesVoie,
      situationPersonnelleError,
      dossierLocation,
      typeVoieFetching,
      typeVoieError,
      situationPersonnelleFetching,
      supprimerSituationPersonnelleError,
      communes,
    } = this.props;

    return (
      <React.Fragment>
        <Layout title={Libelle.pageSituationPerso.titre}>
          <CheminDeFer isEtape="situationPersonnelle" />
          <LoadingBoundary
            loading={typeVoieFetching}
            error={typeVoieError}
            onRetry={this.loadInitData}
          >
            <SituationPersonnelleForm
              onSubmit={this.handleSubmit}
              listTypeVoie={typesVoie}
              listSituations={situationMaritale}
              statutOccupation={statutOccupation}
              qualites={qualites}
              formErrors={situationPersonnelleError}
              dossierLocation={dossierLocation}
              onChangeCommune={this.chargerCommunes}
              isFetching={situationPersonnelleFetching}
              communes={communes}
            />
            <Grid container justify="flex-end" style={{ marginTop: 12 }}>
              {situationPersonnelleError ? (
                <RenderError
                  errors={situationPersonnelleError}
                  onClose={supprimerSituationPersonnelleError}
                />
              ) : (
                ''
              )}
            </Grid>
          </LoadingBoundary>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  situationPersonnelleError: SituationPersonnelleSelector.getSituationPersonnelleError(
    state,
  ),
  situationPersonnelleFetching: SituationPersonnelleSelector.getSituationPersonnelleFetching(
    state,
  ),
  situationPersonnelleData: SituationPersonnelleSelector.getSituationPersonnelle(
    state,
  ),
  typesVoie: TypeVoieSelectors.getListTypeVoie(state),
  typeVoieFetching: TypeVoieSelectors.getListTypeVoieFetching(state),
  typeVoieError: TypeVoieSelectors.getListTypeVoieError(state),
  dossierLocation: DossierLocationSelector.getDossierLocation(state),
  communes: LocalisationSelectors.getLocalisations(state),
  dossierLocationRef: DossierLocationSelector.getSelectedDossier(state),
  compositionLocation: DossierLocationSelector.getCompositionLocation(state),
  pristine: isPristine('situationPersonnelleForm')(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  chargerCommunes: (commune: Commune) =>
    dispatch(LocalisationActionCreator.loadLocalisationsAsync.request(commune)),
  creerOuModifierSituationPersonnelle: (
    situationPersonnelle: Types.SituationPersonnelleData,
  ) =>
    dispatch(
      SituationPersonnelleActionCreator.createOrUpdateSituationPersonnelleAsync.request(
        situationPersonnelle,
      ),
    ),
  chargerListTypeVoie: () =>
    dispatch(TypeVoieActions.loadTypeVoieAsync.request()),
  supprimerSituationPersonnelleError: () =>
    dispatch(SituationPersonnelleActionCreator.removeError()),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AjouterSituationPersonnellePage),
);
