import Types from 'Types';
import {
  ActionType,
  createAsyncAction,
  createStandardAction,
  getType,
} from 'typesafe-actions';
import { DossierPiecesId } from '../../services/Api/swagger';

export interface PiecesState {
  token?: string;
  tokenConjoint?: string;
  fetchDossier: Types.IFetchInfos;
  fetchArchive: Types.IFetchInfos;
  archive?: Blob;
}

/**
 * Init value est temporaire en attentant
 * l'US de récupération du dossier de location
 */
export const initialState: PiecesState = {
  token: '',
  tokenConjoint: '',
  fetchDossier: {
    error: undefined,
    fetching: false,
  },
  archive: new Blob(),
  fetchArchive: {
    error: undefined,
    fetching: false,
  },
};

export const saveTokenNetheos = createStandardAction(
  '@@app/pieces/saveTokenNetheos',
)<DossierPiecesId>();

export const saveTokenConjointNetheos = createStandardAction(
  '@@app/pieces/saveTokenConjointNetheos',
)<DossierPiecesId>();

export const getArchiveNetheos = createAsyncAction(
  '@@app/pieces/GET_ARCHIVE_REQUEST',
  '@@app/pieces/GET_ARCHIVE_SUCCESS',
  '@@app/pieces/GET_ARCHIVE_FAILURE',
)<any, Blob, any>();

export const rouvrirDossierNetheos = createAsyncAction(
  '@@app/pieces/ROUVRIR_DOSSIER_REQUEST',
  '@@app/pieces/ROUVRIR_DOSSIER_SUCCESS',
  '@@app/pieces/ROUVRIR_DOSSIER_FAILURE',
)<any, void, any>();

export const resetToken = createStandardAction('@@app/pieces/RESET_PIECES')();

export const ActionCreators = {
  saveTokenNetheos,
  saveTokenConjointNetheos,
  getArchiveNetheos,
  resetToken,
  rouvrirDossierNetheos,
};

const getTokenNetheos = (state: Types.RootState): string | undefined =>
  state.pieces.token;

const getTokenConjointNetheos = (state: Types.RootState): string | undefined =>
  state.pieces.tokenConjoint;

const getArchive = (state: Types.RootState): any | undefined =>
  state.pieces.archive;

const getArchiveFetching = (state: Types.RootState): boolean =>
  state.pieces.fetchArchive && state.pieces.fetchArchive.fetching;

const getArchiveError = (state: Types.RootState): any | undefined =>
  state.pieces.fetchArchive.error;

export const Selectors = {
  getTokenNetheos,
  getTokenConjointNetheos,
  getArchive,
  getArchiveFetching,
  getArchiveError,
};

export const reducer = (
  state: PiecesState = initialState,
  action: ActionType<typeof ActionCreators>,
): PiecesState => {
  switch (action.type) {
    case getType(saveTokenNetheos):
      return {
        ...state,
        token: action.payload.accessToken,
        tokenConjoint: undefined,
      };
    case getType(saveTokenConjointNetheos):
      return {
        ...state,
        tokenConjoint: action.payload.accessToken,
      };
    case getType(getArchiveNetheos.request):
      return {
        ...state,
        archive: undefined,
        fetchArchive: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(getArchiveNetheos.success):
      return {
        ...state,
        archive: action.payload,
        fetchArchive: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(getArchiveNetheos.failure):
      return {
        ...state,
        fetchArchive: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(resetToken):
      return {
        ...state,
        token: undefined,
        tokenConjoint: undefined,
      };

    case getType(rouvrirDossierNetheos.request):
      return {
        ...state,
        fetchDossier: {
          fetching: true,
          error: undefined,
        },
      };
    case getType(rouvrirDossierNetheos.success):
      return {
        ...state,
        fetchDossier: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(rouvrirDossierNetheos.failure):
      return {
        ...state,
        fetchDossier: {
          fetching: false,
          error: action.payload,
        },
      };
  }
  return state;
};
