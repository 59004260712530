import { Grid, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Types } from 'Types';
import Bouton from '../../../components/bouton/Bouton';
import FormContainer from '../../../components/FormContainer';
import Layout from '../../../components/Layout';
import LoadingBoundary from '../../../components/LoadingBoundary';
import Snack from '../../../components/Snack';
import Spinner from '../../../components/spinner/Spinner';
import {
  ActionCreators as AdbActionCreators,
  Selectors as AdbSelectors,
} from '../../../redux/AdministrateurDeBien';
import { AdministrateurBienDetail } from '../../../services/Api/swagger';
import { getUsername } from '../../../services/Authentification/authentification';
import styles from '../styles';
import ContratLoyerImpayes from './ContratsLoyersImpayes';
import DetailActivite from './DetailActivite';
import ProfilsUtilisateurs from './ProfilsUtilisateurs';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  adbDetail: AdministrateurBienDetail[];
  adbId: number;
  activerAdb(params): void;
  desactiverAdb(params): void;
  username: string;
  activerFetching: boolean;
  activerError?: any;
  emptyErrorMessages(): void;
  adbFetching: boolean;
  adbError: any;
  // Résulat à afficher
}

class ConsulterAdbPage extends React.Component<Props> {
  /**
   * Fonction permettant de délencher l'activation'.
   */
  handleActiver(id) {
    const params = {
      id,
      adbActiver: {
        username: this.props.username,
      },
    };
    this.props.activerAdb(params);
  }
  /**
   * Fonction permettant de délencher la désactivation.
   */
  handleDesactiver(id) {
    this.props.desactiverAdb(id);
  }

  renderField = (label, value) => {
    return (
      <p className={this.props.classes.paragraph}>
        {label} : <strong>{value}</strong>
      </p>
    );
  };

  handleClose = () => {
    this.props.emptyErrorMessages();
  };

  renderError = (error: any) => {
    return (
      <Snack
        onClose={this.handleClose}
        open={true}
        variant="error"
        message={error.message}
      />
    );
  };
  render() {
    const {
      classes,
      activerFetching,
      activerError,
      adbError,
      adbFetching,
    } = this.props;
    const dataConsultation =
      this.props.adbDetail &&
      this.props.adbId &&
      this.props.adbDetail.find(
        detail => detail.identifiant === this.props.adbId,
      );

    return (
      <Layout title={'Consulter un adb'}>
        <div>
          {dataConsultation ? (
            <LoadingBoundary loading={adbFetching} error={adbError}>
              <>
                <Link
                  href="/mes-administrateurs-de-biens"
                  color="inherit"
                  className={classes.link}
                >
                  <ArrowLeft /> Retour au tableau
                </Link>
                <Grid container alignItems="center" justify="space-between">
                  <Grid item className={classes.containerTitle}>
                    <h2 className={classes.titreSecondaire}>
                      Visualisation d'un Adb
                    </h2>
                    {dataConsultation.applications &&
                      dataConsultation.applications[0] &&
                      dataConsultation.applications[0].parametrage &&
                      dataConsultation.applications[0].parametrage.actif && (
                        <Link
                          href="/dashboard"
                          color="secondary"
                          underline="always"
                        >
                          Voir les dossiers de location
                        </Link>
                      )}
                  </Grid>

                  <Grid item>
                    {dataConsultation.applications &&
                      dataConsultation.applications[0] &&
                      dataConsultation.applications[0].parametrage &&
                      dataConsultation.applications[0].parametrage.actif ? (
                      <Bouton
                        label="Bloquer"
                        variant="contained"
                        color="primary"
                        size="medium"
                        fetching={activerFetching}
                        onClick={() =>
                          this.handleDesactiver(dataConsultation.identifiant)
                        }
                      />
                    ) : (
                      <Bouton
                        label="Activer"
                        variant="contained"
                        color="primary"
                        fetching={activerFetching}
                        size="medium"
                        onClick={() =>
                          this.handleActiver(dataConsultation.identifiant)
                        }
                      />
                    )}
                  </Grid>
                </Grid>
                <FormContainer>
                  <Grid className={classes.ligne}>
                    {this.renderField(
                      'Identifiant adhérent',
                      dataConsultation.identifiant,
                    )}
                    {dataConsultation.titreAdb &&
                      this.renderField(
                        'Statut',
                        dataConsultation.titreAdb.libelle,
                      )}
                    {this.renderField('Nom adhérent', dataConsultation.nom)}

                    {dataConsultation.commercial &&
                      this.renderField(
                        'Nom commercial',
                        dataConsultation.commercial.prenom +
                        ' ' +
                        dataConsultation.commercial.nom,
                      )}
                    {dataConsultation.adresse1 &&
                      this.renderField(
                        'Adresse - Ligne 1',
                        dataConsultation.adresse1,
                      )}
                    {dataConsultation.adresse2 &&
                      this.renderField(
                        'Adresse - Ligne 2',
                        dataConsultation.adresse2,
                      )}
                    {dataConsultation.adresse3 &&
                      this.renderField(
                        'Adresse - Ligne 3',
                        dataConsultation.adresse3,
                      )}
                    {this.renderField(
                      'Code Postal',
                      dataConsultation.codePostal,
                    )}
                    {this.renderField('Ville', dataConsultation.ville)}
                    {dataConsultation.applications !== undefined && (
                      <>
                        <DetailActivite
                          dataConsultation={dataConsultation}
                          renderField={this.renderField}
                        />
                        <ContratLoyerImpayes
                          dataConsultation={dataConsultation}
                        />
                        <ProfilsUtilisateurs
                          dataConsultation={dataConsultation}
                        />
                      </>
                    )}
                  </Grid>

                  <Grid container justify="flex-end" style={{ marginTop: 12 }}>
                    {activerError && this.renderError(activerError)}
                  </Grid>
                </FormContainer>
              </>
            </LoadingBoundary>
          ) : (
            <Spinner />
          )}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state: Types.RootState) => {
  return {
    adbDetail: AdbSelectors.getADBDetail(state),
    adbId: AdbSelectors.getAdbId(state),
    username: getUsername(),
    activerFetching: AdbSelectors.activerAdbFetching(state),
    activerError: AdbSelectors.activerAdbError(state),
    adbFetching: AdbSelectors.getADBFetching(state),
    adbError: AdbSelectors.getADBError(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    activerAdb: params =>
      dispatch(AdbActionCreators.activerAdb.request(params)),
    desactiverAdb: params =>
      dispatch(AdbActionCreators.desactiverAdb.request(params)),
    emptyErrorMessages: () => dispatch(AdbActionCreators.emptyErrorMessages()),
  };
};
export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ConsulterAdbPage),
);
