import { Button, IconButton, withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { history } from '../../../helpers/history';
import LogoHeader from '../../../ressources/images/besse-immobilier-construction.jpg';
import logoLabelisation from '../../../ressources/images/labelisationFI.png';
import logo from '../../../ressources/images/logo_vertuloo_v.png';
import styles from '../styles';

//

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  cookiesValid?: boolean;
}

interface State {
  // Etat d'ouverture du menu
  menuOpen: boolean;
  // Gestion du menu
  anchorEl?: null | HTMLButtonElement;
  // taille de l'ecran
  width: number;

  left: boolean;
}

class HeaderStatique extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      width: 0,
      menuOpen: false,
      anchorEl: null,
      left: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      width: window.screen.width,
    });
  }

  /**
   * Connexion Keycloak
   */
  handleConnexion = () => {
    // loginIfRequired();
    history.push('/dashboard');

    if (window.MatomoTagManager) {
      window._paq.push([
        'trackEvent',
        'Connexion',
        'Clic - CTA',
        'Accueil - Menu Connexion',
      ]);
    }
  };

  /**
   * Connexion Keycloak
   */
  handleContact = () => {
    // loginIfRequired();

    history.push('/contact');
  };

  onClickEnSavoirPlus = () => {
    if (window.MatomoTagManager) {
      window._paq.push([
        'trackEvent',
        'En savoir plus',
        'Lien',
        'Accueil - Menu En savoir plus',
      ]);
    }
  };

  onClickOnParleNous = () => {
    if (window.MatomoTagManager) {
      window._paq.push([
        'trackEvent',
        'On parle de nous',
        'Lien',
        'Accueil - Menu On parle de nous',
      ]);
    }
  };
  onClickContact = () => {
    if (window.MatomoTagManager) {
      window._paq.push([
        'trackEvent',
        'Contact',
        'Lien',
        'Accueil - Menu Contact',
      ]);
    }
  };

  onClickLogo = () => {
    if (window.MatomoTagManager) {
      window._paq.push([
        'trackEvent',
        'Accueil',
        'Clic - CTA',
        'Accueil - Logo Bessé',
      ]);
    }
  };

  onClickHome = () => {
    if (window.MatomoTagManager) {
      window._paq.push([
        'trackEvent',
        'Accueil',
        'Accueil',
        'Lien - Menu vertuloo',
      ]);
    }
  };

  onClickLab = () => {
    if (window.MatomoTagManager) {
      window._paq.push([
        'trackEvent',
        'Accueil',
        'Lien',
        'Labelisation Finance 2021',
      ]);
    }
  };

  toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    this.setState({ ...this.state, left: open });
  };

  sideList = () => {
    return (
      <div
        role="presentation"
        onClick={this.toggleDrawer(false)}
        onKeyDown={this.toggleDrawer(false)}
        className={this.props.classes.liensResponsive}
      >
        <List>
          {' '}
          <ListItem button>
            <a href={'/'}>Vertuloo </a>
          </ListItem>{' '}
          <ListItem button>
            <a href={'/en-savoir-plus'}>En savoir plus </a>
          </ListItem>{' '}
          <ListItem button>
            <a href="/#contact">Contact</a>
          </ListItem>
          <ListItem button>
            <ListItemText
              onClick={this.handleConnexion}
              primary="Mon espace client"
            />
          </ListItem>
        </List>
        <Divider />
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    const menuBurgerSmallSize = (
      <div className={classes.menuResponsive}>
        <IconButton
          aria-label="menu"
          aria-owns={'simple-menu'}
          aria-haspopup="true"
          onClick={this.toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          open={this.state.left}
          onClose={this.toggleDrawer(false)}
          classes={{ paper: classes.leftToggle }}
        >
          {this.sideList()}
        </Drawer>
      </div>
    );
    const path = window.location.pathname;
    return (
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <div className={classes.logoContainer}>
            <a href={'/'} onClick={this.onClickLogo}>
              <div className={classes.logo}>
                <img
                  src={LogoHeader}
                  alt="Logo Besse Immobilier et Construction"
                />
              </div>
            </a>
            <a href={'/labelisation'} onClick={this.onClickLab}>
              <div className={classes.logoLab}>
                <img
                  src={logoLabelisation}
                  alt=" labelisation finance innovation "
                />
              </div>
            </a>
          </div>
          <div className={classes.menu}>
            {menuBurgerSmallSize}
            <div className={classes.menuNormal}>
              {' '}
              <a
                href={'/'}
                className={path === '/' && classes.activeLink}
                onClick={this.onClickHome}
              >
                Vertuloo
              </a>
              <a
                href={'/on-parle-de-nous'}
                className={path === '/on-parle-de-nous' && classes.activeLink}
                onClick={this.onClickOnParleNous}
              >
                On parle de nous
              </a>
              <a
                href={'/en-savoir-plus'}
                onClick={this.onClickEnSavoirPlus}
                className={path === '/en-savoir-plus' && classes.activeLink}
              >
                EN savoir plus
              </a>
              <a href="/#contact" onClick={this.onClickContact}>
                Contact
              </a>
              <div className={classes.connexionContainer}>
                <Button
                  className={classes.connexionButton}
                  onClick={this.handleConnexion}
                >
                  <span>
                    Mon espace
                    <br />
                    client
                  </span>
                  <img src={logo} width={45} alt="Vertuloo" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(HeaderStatique);
