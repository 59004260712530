import { AxiosInstance, AxiosResponse } from 'axios';
import ErreursConstante from '../../_constantes/erreursConstantes.json';
import {
  FonctionPoste,
  PageUtilisateurs,
  Profil,
  Token,
  Utilisateur,
  UtilisateursApiFactory,
} from './swagger';

export interface IApi {
  ajouterUtilisateur(utilisateur: Utilisateur): Promise<Utilisateur>;
  getFonctionsPostes(): Promise<FonctionPoste[]>;
  getUtilisateurByUsername(username: string): Promise<Utilisateur>;
  getProfilsBesse(codeApplication: string): Promise<Profil[]>;
  getAllUtilisateursBesse(params): Promise<PageUtilisateurs>;
  getAllUtilisateursAdb(params): Promise<PageUtilisateurs>;
  desactiverUtilisateur(username: string): Promise<void>;
  activerUtilisateur(username: string): Promise<void>;
  modifierUtilisateur(params): Promise<void>;
  getProfilById(id: number): Promise<Profil>;
  activerCompteUtilisateur(token: Token): Promise<void>;
}

const create = (endpoint: string, axiosClient: AxiosInstance) => {
  const utilisateursApi = UtilisateursApiFactory({}, endpoint, axiosClient);

  /**
   * Permet d'enregistrer un utilisateur
   *
   * @param params : Critères de recherche
   */
  const ajouterUtilisateur = (
    utilisateur: Utilisateur,
  ): Promise<Utilisateur> => {
    const promise: Promise<Utilisateur> = new Promise((resolve, reject) => {
      utilisateursApi
        .utilisateursPost(utilisateur)
        .then((response: AxiosResponse<Utilisateur>) => {
          resolve(response.data);
        })
        .catch(error => {
          switch (error.request.status) {
            case 400:
              error.message = ErreursConstante.apiUtilisateurs.creer[400];
              break;
            case 403:
              error.message = ErreursConstante.apiUtilisateurs.creer[403];
              break;
            case 409:
              error.message = ErreursConstante.apiUtilisateurs.creer[409];
              break;
            case 500:
              error.message = ErreursConstante.apiUtilisateurs.creer[500];
              break;
            default:
              error.message = ErreursConstante.apiUtilisateurs.creer[500];
              break;
          }
          reject(error);
        });
    });
    return promise;
  };

  /**
   * Permet de ramener la istedes postes
   *
   * @param params : Critères de recherche
   */
  const getFonctionsPostes = (): Promise<FonctionPoste[]> =>
    new Promise((resolve, reject) => {
      utilisateursApi
        .fonctionsPostesGet()
        .then((response: AxiosResponse<FonctionPoste[]>) => {
          resolve(response.data);
        })
        .catch(error => {
          switch (error.request.status) {
            case 404:
              error.message =
                ErreursConstante.apiAdministrateursDeBiens.consulter[404];
              break;
            default:
              error.message =
                ErreursConstante.apiAdministrateursDeBiens.consulter[500];
              break;
          }
          reject(error);
        });
    });

  /**
   * Permet de ramener la istedes postes
   *
   * @param params : Critères de recherche
   */
  const getUtilisateurByUsername = (username: string): Promise<Utilisateur> =>
    new Promise((resolve, reject) => {
      utilisateursApi
        .getUtilisateurByUsername(username)
        .then((response: AxiosResponse<Utilisateur>) => {
          resolve(response.data);
        })
        .catch(error => {
          switch (error.request.status) {
            case 404:
              error.message =
                ErreursConstante.apiAdministrateursDeBiens.consulter[404];
              break;
            default:
              error.message =
                ErreursConstante.apiAdministrateursDeBiens.consulter[500];
              break;
          }
          reject(error);
        });
    });
  /**
   * Permet de ramener la istedes postes
   *
   * @param params : Critères de recherche
   */
  const getProfilsBesse = (codeApplication: string): Promise<Profil[]> =>
    new Promise((resolve, reject) => {
      utilisateursApi
        .profilsBesseGet(codeApplication)
        .then((response: AxiosResponse<Profil[]>) => {
          resolve(response.data);
        })
        .catch(error => {
          switch (error.request.status) {
            case 404:
              error.message =
                ErreursConstante.apiAdministrateursDeBiens.consulter[404];
              break;
            default:
              error.message =
                ErreursConstante.apiAdministrateursDeBiens.consulter[500];
              break;
          }
          reject(error);
        });
    });

  /**
   * Permet de ramener la iste des utilisateurs besse
   *
   * @param params : Critères de recherche
   */
  const getAllUtilisateursBesse = (params): Promise<PageUtilisateurs> =>
    new Promise((resolve, reject) => {
      utilisateursApi
        .utilisateursBesseGet(
          params.nom,
          params.prenom,
          params.statut,
          params.page,
          params.taille,
          [params.tri],
          {},
        )
        .then((response: AxiosResponse<PageUtilisateurs>) => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  /**
   * Permet de ramener la iste des utilisateurs besse
   *
   * @param params : Critères de recherche
   */
  const getAllUtilisateursAdb = (params): Promise<PageUtilisateurs> =>
    new Promise((resolve, reject) => {
      utilisateursApi
        .utilisateursAdministrateurBienNumAdherentGet(
          params.numAdherent,
          params.nom,
          params.prenom,
          params.statut,
          params.fonction,
          params.page,
          params.taille,
          [params.tri],
          {},
        )
        .then((response: AxiosResponse<PageUtilisateurs>) => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });

  /**
   * Permet de desactiver un utilisateur
   *
   * @param params : Critères de recherche
   */
  const desactiverUtilisateur = (params: any): Promise<void> =>
    new Promise((resolve, reject) => {
      utilisateursApi
        .desactiverUtilisateur(params)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });

  /**
   * Permet d'activer un utilisateur
   *
   * @param params : Critères de recherche
   */
  const activerUtilisateur = (params: any): Promise<void> =>
    new Promise((resolve, reject) => {
      utilisateursApi
        .activerUtilisateur(params)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });

  /*
   * Modification d'un utilisateur
   *
   * @param params : Critères de recherche
   */
  const modifierUtilisateur = (params): Promise<void> =>
    new Promise((resolve, reject) => {
      utilisateursApi
        .utilisateursUsernamePut(params.username, params.body)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });

  /*
   * Récupérer les infos du profil de l'utilisateur connecté
   *
   * @param params : id du profil
   */
  const getProfilById = (id: number): Promise<Profil> =>
    new Promise((resolve, reject) => {
      utilisateursApi
        .getProfilById(id)
        .then((response: AxiosResponse<Profil>) => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });

  /*
   * Activer utilisateur
   *
   * @param params : token
   */
  const activerCompteUtilisateur = (token: Token): Promise<void> =>
    new Promise((resolve, reject) => {
      utilisateursApi
        .utilisateursActiverPut(token)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });

  return {
    ajouterUtilisateur,
    getFonctionsPostes,
    getUtilisateurByUsername,
    getProfilsBesse,
    getAllUtilisateursBesse,
    getAllUtilisateursAdb,
    desactiverUtilisateur,
    activerUtilisateur,
    modifierUtilisateur,
    getProfilById,
    activerCompteUtilisateur,
  };
};

export default create;
