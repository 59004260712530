import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import { Divider as MaterialDivider } from '@material-ui/core';

interface Props {
  classes?: any;
}
const styles = (_theme: Theme) =>
({
  divider: {
    margin: '24px 0',
  },
} as any);
// tslint:enable: quotemark

const Divider = (props: Props) => {
  return <MaterialDivider className={props.classes.divider} />;
};

export default withStyles(styles)(Divider);
