import styles from './styles';
import React from 'react';
import { withStyles } from '@material-ui/styles';
import Check from '@material-ui/icons/Check';

interface Props {
  classes?: any;
  checked?: boolean;
}

const IconCheckbox = (props: Props) => {
  if (props.checked) {
    return (
      <div className={props.classes.icon}>
        <Check />
      </div>
    );
  }
  return <div className={props.classes.icon} />;
};

export default withStyles(styles)(IconCheckbox);
