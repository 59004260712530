import {
  FormControl,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import MaskedInput from 'react-text-mask';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { isInteger } from '../../helpers/validators';
import Dialog from '../Dialog';
import styles from './styles';

export interface IOwnProps {
  input?: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  label?: string;
  required?: boolean;
  type?: string;
  classes?: any;
  size?: number;
  maxLength?: number;
  max?: number;
  min?: number;
  disabled?: boolean;
  mask?: any[];
  note?: string;
  imageHelpInfo?: ImageBitmap | any;
  classNote?: any;
  numberAfterDecimal?: number;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
}

function TextMaskCustom(props) {
  const { inputRef, mask, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      guide={false}
      showMask
    />
  );
}

class InputField extends React.Component<IOwnProps> {
  handleChange = event => {
    const { type, min, max, input, numberAfterDecimal } = this.props;
    let val = event.target.value;

    const minVal = min || 0;

    if (
      val &&
      type === 'number' &&
      numberAfterDecimal !== undefined &&
      isInteger(val)
    ) {
      val = Math.floor(val).toFixed(numberAfterDecimal);
    }
    if (val && max && type === 'number' && parseInt(val, 0) > max) {
      val = max;
    }
    if (val && type === 'number' && parseInt(val, 0) < minVal) {
      val = minVal;
    }
    if (input) {
      input.onChange(val);
    }
  };

  render() {
    const {
      meta: { error, touched },
      required,
      classes,
      type,
      size,
      maxLength,
      max,
      input,
      label,
      disabled,
      min,
      mask,
      note,
      imageHelpInfo,
      classNote,
      placeholder,
      multiline,
      rows,
    } = this.props;
    return (
      <FormControl className={classes.root}>
        <TextField
          {...input}
          disabled={disabled}
          label={label}
          error={error && touched}
          multiline={multiline}
          placeholder={placeholder}
          rows={rows}
          required={required}
          className={classes.textFieldRoot}
          InputProps={{
            classes: {
              input: classes.textField,
              underline: classes.underline,
            },
            inputComponent: mask && TextMaskCustom,
          }}
          inputProps={{ size, maxLength, min: min || 0, max, mask }}
          onChange={this.handleChange}
          InputLabelProps={{
            classes: {
              root: classes.textFieldLabel,
              focused: classes.textFieldLabelFocused,
              error: classes.textFieldLabelError,
              asterisk: classes.textFieldLabelAsterisk,
            },
          }}
          variant="filled"
          type={type ? type : 'text'}
        />
        {imageHelpInfo && <Dialog image={imageHelpInfo} />}
        {note && (
          <Typography
            className={[classes.caption, classNote].join(' ')}
            variant="caption"
            dangerouslySetInnerHTML={{ __html: note }}
          />
        )}
        {error && touched && (
          <Typography color="error" variant="caption">
            {error}
          </Typography>
        )}
      </FormControl>
    );
  }
}

export default withStyles(styles)(InputField);
