import { Theme } from '@material-ui/core/styles';
import { gris1 } from '../../../../theme';

const styles = (theme: Theme) =>
  ({
    container: {
      maxWidth: '880px',
      margin: '48px auto',
      '& > div': {
        display: 'flex',
        alignItems: 'flex-end',
      },
      '& h2': {
        fontSize: 32,
      },
    },
    inputForm: {
      '& > div:first-of-type': {
        marginRight: 4,
      },
      '& > div:last-of-type': {
        marginLeft: 4,
      },
    },
    formContact: {
      maxWidth: 700,
      '& > div': {
        marginBottom: 16,
      },
      '& .MuiInputBase-root': {
        background: 'white',
      },
    },
    subtitle: {
      '& > h2': {
        fontSize: 18,
        padding: 0,
        color: gris1,
      },
    },
  } as any);

export default styles;
