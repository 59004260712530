import React from 'react';
import { AdministrateurBienDetail } from '../../../../services/Api/swagger';
import { Grid } from '@material-ui/core';

import { formatDateLong } from '../../../../helpers/formatters';

export interface Props {
  classes?: any;
  dataConsultation: AdministrateurBienDetail;
  renderField(label: string, value: string): void;
}
const DetailActivite = (props: Props) => {
  const { dataConsultation, renderField } = props;

  if (
    dataConsultation &&
    dataConsultation.applications &&
    dataConsultation.applications[0] &&
    dataConsultation.applications[0].parametrage
  ) {
    return (
      <Grid item>
        <h3>Détail activité Gestion Locative</h3>
        {dataConsultation.applications[0].parametrage.codeDeblocage &&
          renderField(
            'Code de déblocage',
            dataConsultation.applications[0].parametrage.codeDeblocage,
          )}
        {dataConsultation.applications[0].parametrage.actif &&
          dataConsultation.applications[0].parametrage.dateActivation &&
          renderField(
            "Date d'activation",
            formatDateLong(
              dataConsultation.applications[0].parametrage.dateActivation,
            ),
          )}

        {dataConsultation.applications[0].parametrage.utilisateurActivant &&
          dataConsultation.applications[0].parametrage.utilisateurActivant
            .nom &&
          dataConsultation.applications[0].parametrage.utilisateurActivant
            .prenom &&
          renderField(
            'Activé par',
            dataConsultation.applications[0].parametrage.utilisateurActivant
              .prenom +
            ' ' +
            dataConsultation.applications[0].parametrage.utilisateurActivant
              .nom,
          )}
      </Grid>
    );
  }
  return null;
};

export default DetailActivite;
