import moment from 'moment';
import RegEx from '../_constantes/regexConstantes.json';

const patternTelephone = new RegExp(RegEx.telephone);
const patternAlphaNumeric = new RegExp(RegEx.alphaNumeric);
const patternApe = new RegExp(RegEx.codeApe);

export const dateFormat = 'DD/MM/YYYY';
export const dateFormatApi = 'YYYY-MM-DD';
export const dateFormatYearMonth = 'MM/YYYY';

/** age en jours */
export const maxEnfantsAge = moment(new Date()).add(-25, 'year');
export const minEnfantsAge = moment(new Date()).add(0, 'year');
export const maxLocataireAge = moment(new Date()).add(-36499, 'days');
export const minLocataireAge = moment(new Date()).add(-5840, 'days');

export const isTelephone = (value: string): boolean => {
  const num = value.replace(/\s/g, '');
  return patternTelephone.test(num);
};

export const isEmail = (value: string): boolean =>
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value);

export const isMotDePasse = (value: string): boolean =>
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/.test(value);

export const isAlphaNumeric = (value: string): boolean =>
  patternAlphaNumeric.test(value);

export const isAgeEnfantCorrect = (value: string): boolean => {
  const dateNaissance = moment(value, dateFormatApi);
  return dateNaissance >= maxEnfantsAge && dateNaissance <= minEnfantsAge;
};

export const isAgeLocataireCorrect = (value: string): boolean => {
  const dateNaissance = moment(value, dateFormatApi);
  return dateNaissance >= maxLocataireAge && dateNaissance <= minLocataireAge;
};

export const isActiviteAvantXjours = (
  dateDebutSiutation: string | undefined,
  nombreJours: number,
) => {
  const dateLimitDebutActivite = moment(new Date()).add(nombreJours, 'days');
  const dateActivite =
    (dateDebutSiutation && moment(dateDebutSiutation, dateFormatApi)) ||
    moment(new Date());

  return dateLimitDebutActivite > dateActivite;
};

export const isActiviteAvantDernierBulletin = (
  dateDebutSituation: string | undefined,
  dateDernierBulletin: string | undefined,
  numeroMois: number,
) => {
  if (dateDebutSituation && dateDernierBulletin) {
    const dateBulletin =
      dateDernierBulletin &&
      moment(dateDernierBulletin)
        .startOf('month')
        .subtract(numeroMois, 'month');
    const dateActivite =
      dateDebutSituation && moment(dateDebutSituation, dateFormatApi);

    return dateActivite <= dateBulletin;
  } else return false;
};

export const isDateDebutSituationPlus1an = (
  dateDebutSituation: string | undefined,
) => {
  return dateDebutSituation && (moment(dateDebutSituation).add(1, 'y') <= moment(new Date()));
};

export const isDateInfAujourdhui = (date: string) =>
  moment(date, dateFormatApi) <= moment(new Date());

export const getDateApiFromString = (date?: string) =>
  date && moment(date, dateFormatApi);

export const compareDates = (date1: string, date2: string, strict: boolean) =>
  strict
    ? moment(date1, dateFormatApi) > moment(date2, dateFormatApi)
    : moment(date1, dateFormatApi) >= moment(date2, dateFormatApi);

export const estSiretValide = (siret: string): boolean => {
  const siretFormatte = (siret && siret.replace(/\s/g, '')) || siret;
  let estValide =
    (siretFormatte &&
      siretFormatte.length === 14 &&
      !isNaN(Number(siretFormatte))) ||
    false;

  if (estValide) {
    // Donc le SIRET est un numérique à 14 chiffres
    // Les 9 premiers chiffres sont ceux du SIREN (ou RCS), les 4 suivants
    // correspondent au numéro d'établissement
    // et enfin le dernier chiffre est une clef de LUHN.
    let somme = 0;
    let tmp;
    for (let cpt = 0; cpt < siretFormatte.length; cpt++) {
      if (cpt % 2 === 0) {
        // Les positions impaires : 1er, 3è, 5è, etc...
        tmp = Number(siretFormatte.charAt(cpt)) * 2; // On le multiplie par 2
        // Si le résultat est supérieur à 9, on lui soustrait 9
        tmp = tmp > 9 ? (tmp -= 9) : tmp;
      } else {
        tmp = siretFormatte.charAt(cpt);
      }

      somme += Number(tmp);
    }

    // Si la somme est un multiple de 10 alors le SIRET est valide
    estValide = somme % 10 === 0;
  }
  return estValide;
};

export const estApeValide = (codeApe: string): boolean =>
  patternApe.test(codeApe);

export const isValidationPossible = (date: Date): boolean => {
  const todayMoins20 = moment(new Date()).subtract(20, 'days');
  return moment(date) >= todayMoins20;
};

export const isInteger = (value: number): boolean => Number.isInteger(+value);
