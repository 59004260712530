import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import CheminDeFer from '../../../components/cheminDeFer/CheminDeFer';
import Chip from '../../../components/Chip';
import FormButtons from '../../../components/FormButtons';
import Layout from '../../../components/Layout';
import { history } from '../../../helpers/history';
import { getTitleCandidat } from '../../../helpers/utils';
import { Selectors as DossierLocationSelectors } from '../../../redux/DossierLocation';
import { Selectors } from '../../../redux/Pieces';
import { Selectors as SituationPersoSelectors } from '../../../redux/SituationPersonnelle';
import {
  DossierLocation,
  DossierParticulierDetailTypeCompteEnum,
} from '../../../services/Api/swagger';
import Libelle from '../../../_i18n/fr.json';
import IframePieces from './IframePieces';
import styles from './styles';

interface Props {
  classes?: any;
  token: string;
  tokenConjoint?: string;
  dossierLocation: DossierLocation;
  typeCompte?: DossierParticulierDetailTypeCompteEnum;
  refDossierLocataire?: string;
  selectedDossier?: string;
  selectedPerson?: string;
  nomPrenomLocataire?: string;
}

interface State {
  selected: string;
}

class PiecesJustificativesPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: 'candidat',
    };
  }

  handlePrecedent() {
    history.push('/dossier-location/situation-professionnelle');
  }

  handleSuivant = () => {
    history.push('/dossier-location/synthese');
  };

  handleSelectCandidat = (ref: string) => {
    this.setState({
      selected: ref,
    });
  };
  render() {
    const {
      dossierLocation,
      typeCompte,
      refDossierLocataire,
      token,
      tokenConjoint,
      classes,
      selectedDossier,
      selectedPerson,
      nomPrenomLocataire,
    } = this.props;

    const titlePage =
      dossierLocation &&
      getTitleCandidat(
        dossierLocation,
        typeCompte,
        dossierLocation.dossiersParticuliers,
        refDossierLocataire || undefined,
        selectedPerson || undefined,
        nomPrenomLocataire || undefined,
      );

    return (
      <React.Fragment>
        <Layout title={Libelle.pageJustificatifs.titre}>
          <CheminDeFer isEtape="justificatifs" />

          <h5 className="titlePage">{titlePage}</h5>
          {tokenConjoint ? (
            <>
              <Grid container wrap="nowrap" alignItems="flex-start">
                <Chip
                  active={this.state.selected === 'candidat'}
                  key={'candidat'}
                  onClick={() => this.handleSelectCandidat('candidat')}
                  label={'Ajout des pièces pour le/la candidat(e)'}
                  id="candidat"
                />
                <Chip
                  active={this.state.selected === 'conjoint'}
                  key={'conjoint'}
                  onClick={() => this.handleSelectCandidat('conjoint')}
                  label={'Ajout des pièces pour le/la conjoint(e)'}
                  id={'conjoint'}
                />
              </Grid>
              <div className={classes.iframeWrapper}>
                {this.state.selected === 'candidat' && (
                  <>
                    <IframePieces token={token} />
                  </>
                )}
                {this.state.selected === 'conjoint' && (
                  <>
                    <IframePieces token={tokenConjoint} isConjoint />
                  </>
                )}
              </div>
            </>
          ) : (
            <div className={classes.iframeWrapper}>
              <IframePieces token={token} />
            </div>
          )}
          <FormButtons
            onPrecedent={this.handlePrecedent}
            onClick={this.handleSuivant}
            fetching={false}
            canCancel={selectedDossier}
          />
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const modifIframeNetheos = DossierLocationSelectors.getTokenNetheos(state);
  const modifIframeNetheosConjoint = DossierLocationSelectors.getTokenConjointNetheos(
    state,
  );

  const token = Selectors.getTokenNetheos(state)
    ? Selectors.getTokenNetheos(state)
    : modifIframeNetheos;

  const tokenConjoint = Selectors.getTokenConjointNetheos(state)
    ? Selectors.getTokenConjointNetheos(state)
    : modifIframeNetheosConjoint;
  return {
    token,
    tokenConjoint,
    typeCompte: SituationPersoSelectors.getTypeCompte(state),
    refDossierLocataire: SituationPersoSelectors.getRefLocataireGarant(state),
    dossierLocation: DossierLocationSelectors.getDossierLocation(state),
    // si selectedDossier existe (et non la référence de l'object data, ça signifie qu'on est en modification d'un dossier)
    selectedDossier: DossierLocationSelectors.getSelectedDossier(state),
    selectedPerson: DossierLocationSelectors.getSelectedPerson(state),
    nomPrenomLocataire: SituationPersoSelectors.getNomLocataire(state),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps)(PiecesJustificativesPage),
);
