import React from 'react';
import Layout from '../../components/Layout';
import Blocs from './Blocs';

class OnParleNousPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout variant={true} title="En savoir plus">
          <Blocs />
        </Layout>
      </React.Fragment>
    );
  }
}

export default OnParleNousPage;
