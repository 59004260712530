import { withStyles } from '@material-ui/core';
import React from 'react';
import { Cookies, withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Types } from 'Types';
import Snack from '../../../components/Snack';
import {
  ActionCreators as ContactActions,
  Selectors as ContactSelectors,
} from '../../../redux/NousContacterRedux';
import Libelle from '../../../_i18n/fr.json';
import BlocBackground from './BlocBackground';
import BlocQuiSommesNous from './BlocQuiSommesNous';
import styles from './styles';
import BlocDescription from './BlocDescription';
import BlocFonctions from './BlocFonctions';
import BlocAvantages from './BlocAvantages';
import BlocOffres from './BlocOffres';
import BlocDevis from './BlocDevis';
import BlocTemoignages from './BlocTemoignages';
import ContactForm from './ContactForm';
import BulleFixed from '../../../components/BulleFixed';
import BlocMaintenance from './BlocMaintenance';
interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  confirmationFormContact?: boolean;
  emptyConfirmMessageContact(): void;
  cookies: Cookies;
}
interface State {
  cookiesValid: string;
  cookiesValidPub: string;
}
class Accueil extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const cookies = this.getCookies();
    const cookiesPub = this.getCookiesPub();
    this.state = {
      cookiesValid: cookies,
      cookiesValidPub: cookiesPub,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.cookiesValid !== prevState.cookiesValid) {
    }
  }
  /**
   * Récupération d'un cookie coté client
   */
  getCookies = () => {
    return this.props.cookies.get('performanceIsCheck');
  };
  getCookiesPub = () => {
    return this.props.cookies.get('publicitaireIsCheck');
  };
  handleClose = () => {
    this.props.emptyConfirmMessageContact();
  };
  render() {
    const { classes, confirmationFormContact } = this.props;
    return (
      <>
        {/** Titre */}
        <BlocBackground />
        <BlocMaintenance />
        <BlocQuiSommesNous
          cookiesValid={this.state.cookiesValid === 'true'}
        />{' '}
        <BlocTemoignages cookiesValid={this.state.cookiesValidPub === 'true'} />
        <BlocDescription />
        <BlocFonctions cookiesValid={this.state.cookiesValid === 'true'} />
        <BlocAvantages cookiesValid={this.state.cookiesValid === 'true'} />
        <BlocOffres />
        <BlocDevis cookiesValid={this.state.cookiesValid === 'true'} />
        <div className={classes.containerYoutube}>
          <iframe
            className={classes.iframeYoutube}
            title="youtube"
            width="645"
            height="367"
            src={
              this.state.cookiesValidPub === 'true'
                ? 'https://www.youtube.com/embed/R6eiU7S4mlE'
                : 'https://www.youtube-nocookie.com/embed/R6eiU7S4mlE'
            }
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>
        <ContactForm cookiesValid={this.state.cookiesValid === 'true'} />
        <BulleFixed cookiesValid={this.state.cookiesValid === 'true'} />
        <Snack
          onClose={this.handleClose}
          open={confirmationFormContact || false}
          variant="success"
          message={Libelle.pageAccueil.confirmFormContact}
        />
      </>
    );
  }
}

const mapStateToProps = (state: Types.RootState) => {
  return {
    confirmationFormContact: ContactSelectors.getNousContacterConfirmation(
      state,
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    emptyConfirmMessageContact: () =>
      dispatch(ContactActions.emptyConfirmMessageContact()),
  };
};
export default withCookies(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(Accueil),
  ),
);
