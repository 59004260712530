import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
({
  containerRelative: {
    position: 'relative',
  },
  container: {
    padding: '62px 12px',
    margin: 'auto',
    maxWidth: 880,
    textAlign: 'center',
    '& h2': {
      fontSize: 26,
      margin: 0,
    },
    '& h3': {
      textTransform: 'initial',
      fontSize: 14,
      color: '#808080',
    },
  },
  divider: {
    height: 2,
    background: theme.palette.primary.main,
    width: 100,
    margin: '24px auto',
  },
  containerFonctions: {
    display: 'flex',
    flexWrap: 'wrap',
    zIndex: 10,
    position: 'relative',
  },
  fonction: {
    border: '2px solid ' + theme.palette.primary.main,
    padding: 16,
    width: 'calc(50% - 16px)',
    background: 'white',
    marginBottom: 32,
    '&:nth-child(odd)': {
      marginRight: 16,
    },
    '&:nth-child(even)': {
      marginLeft: 16,
    },
    '& p': {
      fontSize: 20,
      fontWeight: '500',
      margin: 0,
    },
    '& span': {
      fontSize: 16,
    },
  },
  grosRond: {
    zIndex: 1,
    width: 200,
    height: 200,
    borderRadius: '100%',
    background: 'rgba(102, 153, 0, 0.3)',
    position: 'absolute',
    top: 60,
    right: -70,
  },
  moyenRond: {
    zIndex: 1,
    width: 100,
    height: 100,
    borderRadius: '100%',
    background: 'rgba(102, 153, 0, 0.3)',
    position: 'absolute',
    top: 140,
    left: 50,
  },
  petitRond: {
    zIndex: 1,
    width: 50,
    height: 50,
    borderRadius: '100%',
    background: 'rgba(102, 153, 0, 0.3)',
    position: 'absolute',
    top: 260,
    left: 250,
  },
  '@media all and (max-width: 580px)': {
    containerFonctions: {
      display: 'block',
    },
    fonction: {
      width: '98%',
      '&:nth-child(odd)': {
        marginRight: 0,
      },
      '&:nth-child(even)': {
        marginLeft: 0,
      },
    },
    petitRond: {
      display: 'none',
    },
    moyenRond: {
      display: 'none',
    },
    grosRond: {
      display: 'none',
    },
  },
} as any);

export default styles;
