import { Theme } from '@material-ui/core/styles';

const styles = (_theme: Theme) =>
({
  containerBg: {
    position: 'relative',
    padding: '48px 0',
    width: '100%',
    background: 'rgba(102, 153, 0, 0.3)',
  },
  forme: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  container: {
    margin: 'auto',
    maxWidth: '880px',
    textAlign: 'center',
    '& h2': {
      fontSize: 26,
      margin: 0,
    },
    '& h3': {
      textTransform: 'initial',
      fontSize: 14,
    },
    '& h4': {
      margin: 0,
    },
  },

  containerAvantages: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 24,
  },
  avantage: {
    width: 'calc((100% / 3) - 16px)',
    margin: '16px 8px',
  },
  containerBloc: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '2px solid #FFF',
    padding: 16,
    height: 'calc(100% - 64px)',
    marginTop: 8,
    '& p': {
      fontSize: 15,
      fontWeight: '600',
      margin: 0,
    },
    '& > div': {
      margin: '16px 0',
    },
  },
  '@media all and (max-width: 789px)': {
    forme: {
      display: 'none',
    },
    containerAvantages: {
      display: 'block',
    },

    avantage: {
      width: '80%',
      margin: '16px auto',
    },
  },
} as any);

export default styles;
