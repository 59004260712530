import { Grid, Typography } from '@material-ui/core';
import memoizeOne from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import Bouton from '../../../components/bouton/Bouton';
import FormContainer from '../../../components/FormContainer';
import InputField from '../../../components/input/InputField';
import InputPassword from '../../../components/input/InputPassword';
import Select from '../../../components/select/Select';
import { isEmail, isMotDePasse } from '../../../helpers/validators';
import { Profil } from '../../../services/Api/swagger/api.js';
import ErreursConstante from '../../../_constantes/erreursConstantes.json';
import { construireListProfilsBesse } from '../helpers';

interface UtilisateurFormErrors {
  nom?: string;
  prenom?: string;
  mail?: string;
  profilBesse?: string;
  motDePasse?: string;
  confirmationMotDePasse?: string;
}

interface UtilisateurFormData {
  nom: string;
  prenom: string;
  mail: string;
  profilBesse: string;
  motDePasse?: string;
  confirmationMotDePasse: string;
}
interface CustomProps {
  formValues: UtilisateurFormData;
  formErrors?: UtilisateurFormErrors;
  classes?: any;
  handlePrecedent(): void;
  utilisateurBESSE: boolean;
  profilsBesse?: Profil[];
  isFetching: boolean;
  initialValues?: any;
  isModify?: boolean;
}

type Props = InjectedFormProps<UtilisateurFormData> & CustomProps;

const memoizeConstruireProfilsBesseOptions = memoizeOne(
  construireListProfilsBesse,
);
class UtilisateurBesseForm extends React.Component<Props> {
  // componentDidMount() {
  //   this.props.dispatch(actionReducer(parameter)).then(()=>{
  //     this.props.initialize(this.props.data)
  //     this.setState({load:true})
  //  })
  // }
  render() {
    const { profilsBesse, isFetching, isModify } = this.props;

    const optionsProfilsBesse =
      profilsBesse && memoizeConstruireProfilsBesseOptions(profilsBesse);

    return (
      <form onSubmit={this.props.handleSubmit} noValidate>
        <FormContainer>
          <Grid container spacing={1}>
            <Grid item>
              <Field name="nom" component={InputField} label="Nom" required />
            </Grid>
            <Grid item>
              <Field
                name="prenom"
                component={InputField}
                label="Prénom"
                required
              />
            </Grid>
            <Grid item>
              <Field
                name="mail"
                component={InputField}
                label="E-mail"
                required
                size={50}
              />
            </Grid>
          </Grid>

          {!isModify && (
            <Grid container spacing={1}>
              <Grid item>
                <Field
                  label="Mot de passe"
                  name="motDePasse"
                  component={InputPassword}
                  type="password"
                  required
                />
              </Grid>
              <Grid item>
                <Field
                  label="Confirmation de votre mot de passe"
                  name="confirmationMotDePasse"
                  component={InputPassword}
                  type="password"
                  size={25}
                  required
                />
              </Grid>
            </Grid>
          )}

          {!isModify && (
            <Grid container spacing={0}>
              <Typography style={{ marginTop: '-8px' }} variant="caption">
                Le mot de passe doit avoir au moins 8 caractères, 1 majuscule, 1
                minuscule et 1 chiffre.
              </Typography>
            </Grid>
          )}
          <Grid container spacing={1}>
            <Grid item>
              {optionsProfilsBesse && (
                <Field
                  name="profilBesse"
                  component={Select}
                  label="Profil Bessé"
                  options={optionsProfilsBesse}
                  required
                />
              )}
            </Grid>
          </Grid>
        </FormContainer>

        <Grid container justify="space-between">
          <Grid item>
            <Bouton
              label="Annuler"
              variant="contained"
              color="default"
              size="medium"
              type="submit"
              onClick={this.props.handlePrecedent}
            />
          </Grid>
          <Grid item>
            <Bouton
              fetching={isFetching}
              label="Enregistrer"
              variant="contained"
              color="primary"
              size="medium"
              type="submit"
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}
/**
 * Méthode de validation du formulaire
 * @param values
 */
const validateForm = (
  values: UtilisateurFormData,
  props,
): UtilisateurFormErrors => {
  const errors: UtilisateurFormErrors = {} as UtilisateurFormErrors;

  if (!values.nom) {
    errors.nom = ErreursConstante.formulaire.generale.champObligatoire;
  }
  if (!values.prenom) {
    errors.prenom = ErreursConstante.formulaire.generale.champObligatoire;
  }
  if (!values.mail) {
    errors.mail = ErreursConstante.formulaire.generale.champObligatoire;
  } else if (values.mail && values.mail && !isEmail(values.mail)) {
    errors.mail = ErreursConstante.formulaire.generale.emailIncorrect;
  }
  if (!values.profilBesse) {
    errors.profilBesse = ErreursConstante.formulaire.generale.champObligatoire;
  }
  if (
    (!values.motDePasse || !isMotDePasse(values.motDePasse)) &&
    !props.isModify
  ) {
    errors.motDePasse = ErreursConstante.formulaire.generale.motPasse;
  }

  if (values.motDePasse !== values.confirmationMotDePasse && !props.isModify) {
    errors.confirmationMotDePasse =
      ErreursConstante.formulaire.generale.confirmationMotPasse;
  }
  return errors;
};

const mapStateToProps = (state, props) => ({
  formValues:
    getFormValues('utilisateurBesseForm')(state) || props.initialValues,
});

export default connect(mapStateToProps)(
  reduxForm<UtilisateurFormData>({
    form: 'utilisateurBesseForm',
    validate: validateForm,
    keepDirtyOnReinitialize: true,
  })(UtilisateurBesseForm),
);
