import {
  ActionType,
  createAsyncAction,
  createStandardAction,
  getType,
} from 'typesafe-actions';
import { Contact } from '../../services/Api/swagger';

export interface NousContacterState {
  data?: Contact;
  error?: any;
  fetching: boolean;
  confirmation: boolean;
}

export const initialState: NousContacterState = {
  data: undefined,
  error: undefined,
  fetching: false,
  confirmation: false,
};

export const postNousContacter = createAsyncAction(
  '@@App/contact/NOUS_CONTACTER_REQUEST',
  '@@App/contact/NOUS_CONTACTER_SUCCESS',
  '@@App/contact/NOUS_CONTACTER_FAILURE',
)<Contact, void, any>();

export const emptyConfirmMessageContact = createStandardAction(
  '@@App/contact/EMPTY_CONFIRM_CONTACT',
)();
export const ActionCreators = {
  postNousContacter,
  emptyConfirmMessageContact,
};

const getNousContacterError = (state): any | undefined => state.contact.error;

const getNousContacterFetching = (state): boolean => state.contact.fetching;

const getNousContacterConfirmation = (state): boolean =>
  state.contact.confirmation;

export const Selectors = {
  getNousContacterError,
  getNousContacterFetching,
  getNousContacterConfirmation,
};

export const reducer = (
  state: NousContacterState = initialState,
  action: ActionType<typeof ActionCreators>,
): NousContacterState => {
  switch (action.type) {
    case getType(postNousContacter.request):
      return {
        ...state,
        fetching: true,
        error: undefined,
        confirmation: false,
      };
    case getType(postNousContacter.success):
      return {
        ...state,
        fetching: false,
        error: undefined,
        confirmation: true,
      };
    case getType(postNousContacter.failure):
      return {
        ...state,
        fetching: false,
        error: action.payload,
        confirmation: false,
      };
    case getType(emptyConfirmMessageContact):
      return {
        ...state,
        confirmation: false,
      };
  }
  return state;
};
