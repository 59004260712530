import Types from 'Types';
import {
  ActionType,
  createAsyncAction,
  createStandardAction,
  getType,
} from 'typesafe-actions';
import {
  AdministrateurBien,
  AdministrateurBienDetail,
  AdministrateurBienList,
  Site,
} from '../../services/Api/swagger';

// Types

export interface AdbState {
  selectedSite?: Site;
  searchParams?: any;
  searchResults?: AdministrateurBienList;
  searchFetchInfos: Types.IFetchInfos;
  adbFetchInfos: Types.IFetchInfos;
  adbDetailFetchInfos: Types.IFetchInfos;
  adbRegroupements?: AdministrateurBien[];
  idAdb?: number;
  adbDetail?: AdministrateurBienDetail[];
  activerAdbFetchInfos: Types.IFetchInfos;
}

// Initial state

export const initialState: AdbState = {
  selectedSite: undefined,
  searchParams: undefined,
  searchResults: undefined,
  searchFetchInfos: {
    fetching: false,
    error: undefined,
  },
  adbFetchInfos: {
    fetching: false,
    error: undefined,
  },
  adbDetailFetchInfos: {
    fetching: false,
    error: undefined,
  },
  adbRegroupements: undefined,
  idAdb: undefined,
  adbDetail: undefined,
  activerAdbFetchInfos: {
    fetching: false,
    error: undefined,
  },
};

// Action types and action creators

const search = createAsyncAction(
  '@@app/adb/SEARCH_REQUEST',
  '@@app/adb/SEARCH_SUCCESS',
  '@@app/adb/SEARCH_FAILURE',
)<any, AdministrateurBienList, Error>();
// FIXME Typer les paramètres d'entrée

export const getADBRegroupement = createAsyncAction(
  '@@App/adb/GET_ADBREGROUPEMENT_REQUEST',
  '@@App/adb/GET_ADBREGROUPEMENT_SUCCESS',
  '@@App/adb/GET_ADBREGROUPEMENT_FAILURE',
)<number, AdministrateurBien[], any>();

export const getADBIdDetail = createAsyncAction(
  '@@App/adb/GET_ADBDETAIL_REQUEST',
  '@@App/adb/GET_ADBDETAIL_SUCCESS',
  '@@App/adb/GET_ADBDETAIL_FAILURE',
)<number, AdministrateurBienDetail, any>();

export const setSelectedSite = createStandardAction(
  '@@App/adb/SET_SELECTED_SITE',
)<Site>();

export const updateAdbId = createStandardAction('@@App/adb/UPDATE_ADB_ID')<
  number
>();

export const activerAdb = createAsyncAction(
  '@@App/adb/ACTIVER_ADB_REQUEST',
  '@@App/adb/ACTIVER_ADB_SUCCESS',
  '@@App/adb/ACTIVER_ADB_FAILURE',
)<any, AdministrateurBienDetail, any>();

export const desactiverAdb = createAsyncAction(
  '@@App/adb/DESACTIVER_ADB_REQUEST',
  '@@App/adb/DESACTIVER_ADB_SUCCESS',
  '@@App/adb/DESACTIVER_ADB_FAILURE',
)<string, AdministrateurBienDetail, any>();

const emptyErrorMessages = createStandardAction(
  '@@app/adb/EmptyErrorMessages',
)();
export const ActionCreators = {
  search,
  getADBRegroupement,
  getADBIdDetail,
  setSelectedSite,
  updateAdbId,
  activerAdb,
  desactiverAdb,
  emptyErrorMessages,
};

// Selectors

const getSelectedSite = (
  state: Types.RootState,
): AdministrateurBien | undefined => state.adb.selectedSite;

const getSearchResults = (state: Types.RootState): AdministrateurBien[] =>
  (state.adb.searchResults && state.adb.searchResults.resultat) || [];

const getSearchCount = (state: Types.RootState): number =>
  (state.adb.searchResults && state.adb.searchResults.total) || 0;

const getSearchFetching = (state: Types.RootState): boolean =>
  state.adb.searchFetchInfos.fetching;

const getSearchError = (state: Types.RootState): Error | undefined =>
  state.adb.searchFetchInfos.error;

const getADBRegroupementList = (
  state: Types.RootState,
): AdministrateurBien[] | undefined => state.adb && state.adb.adbRegroupements;

const getADBError = (state: Types.RootState): boolean =>
  state.adb.adbDetailFetchInfos.error;

const getADBDetail = (
  state: Types.RootState,
): AdministrateurBienDetail[] | undefined => state.adb && state.adb.adbDetail;

const getADBFetching = (state: Types.RootState): boolean =>
  state.adb.adbDetailFetchInfos.fetching;

const getAdbId = (state: Types.RootState): number | undefined =>
  state.adb.idAdb;

const getSelectedAdbDetail = (
  state: Types.RootState,
): AdministrateurBienDetail | undefined => {
  const idAdb = state.adb.idAdb;
  const adbsDetail = state.adb.adbDetail;
  const selectedAdbDetail =
    adbsDetail && adbsDetail.find(adb => adb.identifiant === idAdb);
  return selectedAdbDetail;
};

const activerAdbFetching = (state: Types.RootState): boolean =>
  state.adb.activerAdbFetchInfos.fetching;

const activerAdbError = (state: Types.RootState): Error | undefined =>
  state.adb.activerAdbFetchInfos.error;

export const Selectors = {
  getSearchResults,
  getSearchCount,
  getSearchFetching,
  getSearchError,
  getSelectedSite,
  getADBRegroupementList,
  getADBFetching,
  getADBDetail,
  getADBError,
  getAdbId,
  getSelectedAdbDetail,
  activerAdbFetching,
  activerAdbError,
};

// Reducer

export const reducer = (
  state: AdbState = initialState,
  action: ActionType<typeof ActionCreators>,
): AdbState => {
  switch (action.type) {
    case getType(search.request):
      return {
        ...state,
        searchParams: action.payload,
        searchResults: undefined,
        searchFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };

    case getType(search.success):
      return {
        ...state,
        searchResults: action.payload,
        searchFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };

    case getType(search.failure):
      return {
        ...state,
        searchResults: undefined,
        searchFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(getADBRegroupement.request):
      return {
        ...state,
        adbFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };

    case getType(getADBRegroupement.success):
      return {
        ...state,
        adbRegroupements: action.payload,
        adbFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };

    case getType(getADBRegroupement.failure):
      return {
        ...state,
        adbFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(getADBIdDetail.request):
      return {
        ...state,
        adbDetailFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };

    case getType(getADBIdDetail.success):
      if (state.adbDetail) {
        let newAdbDetail = state.adbDetail;

        // si l'id existe deja dans le tableau
        const idExistant = state.adbDetail.findIndex(
          detail => detail.identifiant === action.payload.identifiant,
        );
        // tslint:disable-next-line: prefer-conditional-expression
        if (idExistant === -1) {
          newAdbDetail = state.adbDetail.concat([action.payload]);
        }

        return {
          ...state,
          adbDetail: newAdbDetail,
          adbDetailFetchInfos: {
            fetching: false,
            error: undefined,
          },
        };
      } else {
        return {
          ...state,
          adbDetail: [action.payload],
          adbDetailFetchInfos: {
            fetching: false,
            error: undefined,
          },
        };
      }

    case getType(getADBIdDetail.failure):
      return {
        ...state,
        adbDetailFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(setSelectedSite):
      return {
        ...state,
        selectedSite: action.payload,
        adbDetailFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(updateAdbId):
      return {
        ...state,
        idAdb: action.payload,
      };
    case getType(activerAdb.request):
      return {
        ...state,
        activerAdbFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };

    case getType(activerAdb.success):
      if (state.adbDetail) {
        const newAdbDetail = state.adbDetail;
        // si l'id existe deja dans le tableau
        const idExistant = state.adbDetail.findIndex(
          detail => detail.identifiant === action.payload.identifiant,
        );
        if (idExistant === -1) {
          // on ajoute le détail dans le tableau s'il n'existe pas déjà => ne devrait jamais arriver mais au cas où
          newAdbDetail.push(action.payload);
        } else {
          // on remplace le détail dans le tableau s'il existe déjà
          newAdbDetail[idExistant] = action.payload;
        }

        return {
          ...state,
          adbDetail: newAdbDetail,
          activerAdbFetchInfos: {
            fetching: false,
            error: undefined,
          },
        };
      } else {
        return {
          ...state,
          adbDetail: [action.payload],
          activerAdbFetchInfos: {
            fetching: false,
            error: undefined,
          },
        };
      }

    case getType(activerAdb.failure):
      return {
        ...state,
        activerAdbFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(desactiverAdb.request):
      return {
        ...state,
        activerAdbFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };

    case getType(desactiverAdb.success):
      if (state.adbDetail) {
        const newAdbDetail = state.adbDetail;
        // si l'id existe deja dans le tableau
        const idExistant = state.adbDetail.findIndex(
          detail => detail.identifiant === action.payload.identifiant,
        );
        if (idExistant === -1) {
          // on ajoute le détail dans le tableau s'il n'existe pas déjà => ne devrait jamais arriver mais au cas où
          newAdbDetail.push(action.payload);
        } else {
          // on remplace le détail dans le tableau s'il existe déjà
          newAdbDetail[idExistant] = action.payload;
        }

        return {
          ...state,
          adbDetail: newAdbDetail,
          activerAdbFetchInfos: {
            fetching: false,
            error: undefined,
          },
        };
      } else {
        return {
          ...state,
          adbDetail: [action.payload],
          activerAdbFetchInfos: {
            fetching: false,
            error: undefined,
          },
        };
      }

    case getType(desactiverAdb.failure):
      return {
        ...state,
        activerAdbFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(emptyErrorMessages):
      return {
        ...state,
        activerAdbFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };
    default:
      return state;
  }
};
