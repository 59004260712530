import {
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
  withStyles,
} from '@material-ui/core';
import FailureIcon from '@material-ui/icons/Cancel';
import ValidIcon from '@material-ui/icons/CheckCircle';
import WaitingIcon from '@material-ui/icons/Timelapse';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Bouton from '../../../../../../components/bouton/Bouton';
import Error from '../../../../../../components/Error';
import { formatDateCourt } from '../../../../../../helpers/formatters';
import { history } from '../../../../../../helpers/history';
import { ActionCreators as DossierLocationActionCreators } from '../../../../../../redux/DossierLocation';
import { Selectors as PartenaireSelectors } from '../../../../../../redux/Partenaire';
import {
  ActionCreators as PiecesActionCreators,
  Selectors as PiecesSelectors,
} from '../../../../../../redux/Pieces';
import { ActionCreators as SituationPersoActionCreators } from '../../../../../../redux/SituationPersonnelle';
import {
  ActionCreators as SituationProActionCreators,
  Selectors as SituationProSelectors,
} from '../../../../../../redux/SituationProfessionnelle';
import ModifyIcon from '../../../../../../ressources/images/ModifyIcon.svg';
import PiecesIcon from '../../../../../../ressources/images/PiecesIcon.svg';
import SituationPersoIcon from '../../../../../../ressources/images/SituationPersoIcon.svg';
import SituationProIcon from '../../../../../../ressources/images/SituationProIcon.svg';
import {
  DossierParticulierDetail,
  SituationProfessionnelle,
} from '../../../../../../services/Api/swagger';
import messages from '../../../../../../_i18n/fr.json';
import styles from '../../styles';
import { isDateDebutSituationPlus1an } from '../../../../../../helpers/validators';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  personne?: DossierParticulierDetail;
  listSituationPro: SituationProfessionnelle[];
  getListSituationPro(): void;
  cantModify: boolean;
  isConjoint?: boolean;
  allEtudiant?: boolean;
  hasGarant?: boolean;
  canAddGarant?: boolean;
  addGarant(ref: string): void;
  resetSituationPro(): void;
  getArchiveNetheos(params): void;
  archive?: Blob;
  archiveFetching: boolean;
  archiveError?: any;
  resetSelectedPerson(): void;
  rouvrirDossierNetheos(params): void;
  isControleDossier: boolean;
  isCumulsUtilises: boolean;
}

class InfoCandidat extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.getListSituationPro();
  }

  handleDownloadArchive = () => {
    if (this.props.personne) {
      const params = {
        ref: this.props.personne.refDossierLocation,
        refPers: this.props.personne.reference,
      };
      this.props.getArchiveNetheos(params);
    }
  };

  addGarant = () => {
    if (
      this.props.personne &&
      this.props.personne.refDossierLocation &&
      this.props.personne.reference
    ) {
      this.props.resetSituationPro();
      this.props.resetSelectedPerson();
      this.props.addGarant(this.props.personne.reference);
      history.push('/dossier-location/situation-personnelle');
    }
  };

  modifyNetheos = () => {
    if (this.props.personne) {
      const params = {
        ref: this.props.personne.refDossierLocation,
        refPers: this.props.personne.reference,
      };
      if (this.props.personne.statut === 'BLOQUE') {
        this.props.rouvrirDossierNetheos(params);
      }
      history.push('/dossier-location/pieces-justificatives');
    }
  };

  render() {
    const {
      personne,
      classes,
      listSituationPro,
      cantModify,
      isConjoint,
      allEtudiant,
      hasGarant,
      canAddGarant,
      archiveFetching,
      archiveError,
      isControleDossier,
      isCumulsUtilises,
    } = this.props;

    if (personne) {
      const situationPro =
        listSituationPro &&
        personne.codeStatutProfessionnel &&
        listSituationPro.find(
          situation =>
            situation.statutsProfessionnels &&
            situation.statutsProfessionnels.find(
              statut => statut.code === personne.codeStatutProfessionnel,
            ),
        );
      const statutPro =
        situationPro &&
        situationPro.statutsProfessionnels &&
        personne.codeStatutProfessionnel &&
        situationPro.statutsProfessionnels.find(
          statut => statut.code === personne.codeStatutProfessionnel,
        );
      const dossierNetheos = personne.dossierPieces;
      const isDateDebutActivitePlus1an = isDateDebutSituationPlus1an(personne.dateDebutSituationProfessionnelle);
      return (
        <Grid container justify="flex-start" className={classes.candidat}>
          <div>
            <h4>
              <img src={SituationPersoIcon} width="27" height="27" alt="" /> Identité
              et coordonnées {/* true + false  */}
              {cantModify || personne.statut === 'VALIDE' ? null : (
                <Link href="/dossier-location/situation-personnelle">
                  <img alt="modifier situation personnelle"
                    src={ModifyIcon}
                    width={24}
                    height={24}
                    className={classes.modifyIcon}
                  />
                </Link>
              )}
            </h4>
            <p className="capitalize">
              {personne.qualite + ' '}
              <strong>{personne.prenom + ' ' + personne.nom}</strong>
            </p>
            <p>
              Né(e) le :{' '}
              {personne.dateNaissance && (
                <strong>{formatDateCourt(personne.dateNaissance)}</strong>
              )}
            </p>

            {!isConjoint && (
              <p>Mail :{personne.email && <strong>{personne.email}</strong>}</p>
            )}

            {!isConjoint && (
              <p>
                N° portable :{' '}
                {personne.telephonePortable && (
                  <strong>{personne.telephonePortable}</strong>
                )}
              </p>
            )}
            {!isConjoint && (
              <p>
                Adresse actuelle :{' '}
                <strong>
                  {personne.numeroBatiment &&
                    <span> bât. {personne.numeroBatiment}, </span>
                  }
                  {personne.numero}{' '}
                  {personne.typeVoie && personne.typeVoie.toLowerCase()}{' '}
                  {personne.adresse && personne.adresse}{', '}
                  {personne.codePostal && personne.codePostal}{' '}
                  {personne.ville && personne.ville}{' '}
                  {personne.dateEntree &&
                    ' depuis le ' + formatDateCourt(personne.dateEntree)}
                  {personne.statutOccupation &&
                    ' en qualité de ' +
                    messages.statutOccupation[personne.statutOccupation]}
                </strong>
              </p>
            )}
            {!isConjoint && (
              <p>
                Situation :{' '}
                <strong>
                  {personne.situationMaritale &&
                    messages.situationMaritale[personne.situationMaritale]}
                  {personne.enfants && personne.enfants.length > 0 && (
                    <span>, {personne.enfants.length + ' enfant(s)'}</span>
                  )}
                </strong>
              </p>
            )}
            <div className={classes.blocCandidat}>
              <h4>
                <img src={SituationProIcon} width="27" height="27" alt="" />
                Situation professionnelle{' '}
                {cantModify || personne.statut === 'VALIDE' ? null : (
                  <Link href="/dossier-location/situation-professionnelle">
                    <img alt="modifier situation profesionnelle"
                      src={ModifyIcon}
                      width={24}
                      height={24}
                      className={classes.modifyIcon}
                    />
                  </Link>
                )}
              </h4>

              <p>
                {situationPro &&
                  situationPro.code !== 'ETU' &&
                  situationPro.code !== 'RET' &&
                  situationPro.code !== 'RCH' &&
                  situationPro.code !== 'SAC' &&
                  situationPro.libelle}{' '}
                <strong>{statutPro && statutPro.libelle} </strong>
                {personne.dateDebutSituationProfessionnelle && (
                  <span>
                    depuis le
                    <strong>
                      {formatDateCourt(
                        personne.dateDebutSituationProfessionnelle,
                      )}
                    </strong>
                  </span>
                )}{' '}
                {personne.dateFinSituationProfessionnelle && (
                  <span>
                    jusqu'à
                    <strong>
                      {formatDateCourt(
                        personne.dateFinSituationProfessionnelle,
                      )}
                    </strong>
                  </span>
                )}
              </p>
              {(personne.salaireNetImposableM1 ||
                personne.salaireNetImposableM1 === 0) && (
                  <>
                    <h5>Revenus </h5>
                    <p>
                      Salaires mensuels :
                      <strong>
                        {personne.salaireNetImposableM1 + '€'}
                        {(personne.salaireNetImposableM2 || personne.salaireNetImposableM2 === 0) && (
                          ', ' + personne.salaireNetImposableM2 + '€')}
                        {(personne.salaireNetImposableM3 || personne.salaireNetImposableM3 === 0) && (
                          ', ' + personne.salaireNetImposableM3 + '€ ')}
                      </strong>
                    </p>
                    {isCumulsUtilises && isDateDebutActivitePlus1an && (
                      <p>
                        Cumul annuel salaires :
                        <strong>
                          {personne.cumulSalaireNetImposableM1 + '€'}
                          {(personne.cumulSalaireNetImposableM2 || personne.cumulSalaireNetImposableM2 === 0) && (
                            ', ' + personne.cumulSalaireNetImposableM2 + '€')}
                          {(personne.cumulSalaireNetImposableM3 || personne.cumulSalaireNetImposableM3 === 0) && (
                            ', ' + personne.cumulSalaireNetImposableM3 + '€ ')}
                        </strong>
                      </p>
                    )}
                  </>
                )}
              {(personne.revenuIndividuelDeclare ||
                personne.revenuIndividuelDeclare === 0) && (
                  <p>
                    Revenu individuel déclaré :
                    <strong>{personne.revenuIndividuelDeclare}€ </strong>
                  </p>
                )}
              {personne.revenuFiscalReference && (
                  <p>
                    Revenu fiscal de référence imposable :
                    <strong>{personne.revenuFiscalReference}€ </strong>
                  </p>
                )}
              <div className={classes.blocCandidat}>
                <h4>
                  <img src={PiecesIcon} width="27" height="27" alt="" />
                  Les pièces du dossier{' '}
                  {cantModify ||
                    !isControleDossier ||
                    personne.statut === 'VALIDE' ||
                    (dossierNetheos &&
                      dossierNetheos.statut === 'ACCEPTED') ? null : (
                    <Button
                      onClick={this.modifyNetheos}
                      className={classes.buttonPieces}
                    >
                      <img alt="modifier les pièces du dossier"
                        src={ModifyIcon}
                        width={24}
                        height={24}
                        className={classes.modifyIcon}
                      />
                    </Button>
                  )}
                </h4>
                {dossierNetheos && dossierNetheos.reference && (
                  <p>
                    Numéro personne :{' '}
                    <strong>{dossierNetheos.reference}</strong>
                  </p>
                )}
                {((dossierNetheos &&
                  dossierNetheos.piecesJustificatives &&
                  dossierNetheos.piecesJustificatives.length > 0) ||
                  (!isControleDossier && personne.statut === 'VALIDE')) && (
                    <Grid container className={classes.downloadArchive}>
                      <p>Téléchargez votre archive :</p>
                      <Link
                        component="button"
                        // tslint:disable-next-line: jsx-no-lambda
                        onClick={() => this.handleDownloadArchive()}
                        underline="always"
                        variant="body2"
                      >
                        documents.zip
                      </Link>
                      {archiveFetching && <CircularProgress size={20} />}
                      {archiveError && (
                        <Typography color="error" variant="body2">
                          {'Erreur de téléchargement, veuillez réessayer'}
                        </Typography>
                      )}
                    </Grid>
                  )}
                {(!isControleDossier && personne.statut !== 'VALIDE') && (
                  <p>Les pièces justificatives n'ont pas encore été envoyées par le partenaire</p>
                )}
                {dossierNetheos &&
                  dossierNetheos.piecesJustificatives &&
                  dossierNetheos.piecesJustificatives.length > 0 ? (
                  dossierNetheos.piecesJustificatives.map((piece, i) => {
                    let icon = (
                      <ValidIcon
                        className={classes.icon + ' ' + classes.iconValid}
                      />
                    );
                    if (
                      piece.pieceJustificativeErreurs &&
                      piece.pieceJustificativeErreurs.length > 0
                    ) {
                      icon = (
                        <FailureIcon
                          className={classes.icon + ' ' + classes.iconFailed}
                        />
                      );
                    } else if (piece.statut === 'NOT_VALIDATED') {
                      icon = (
                        <WaitingIcon
                          className={classes.icon + ' ' + classes.iconWait}
                        />
                      );
                    }

                    const errors = piece.pieceJustificativeErreurs
                      ? piece.pieceJustificativeErreurs.map(error => (
                        <Error
                          className={classes.errorPiece}
                          key={error.code}
                          text={error.libelle || ''}
                        />
                      ))
                      : '';

                    return (
                      <>
                        <p className={classes.pieces} key={i}>
                          {icon}
                          <strong> {piece.libelle}</strong>
                        </p>
                        {errors}
                      </>
                    );
                  })
                ) : (isControleDossier && (
                  <p>Vous n'avez pas encore ajouté de pièces justificatives</p>
                ))}
              </div>
              {/* Si étudiant */}
              {personne &&
                personne.codeStatutProfessionnel === 'ETU' &&
                allEtudiant &&
                canAddGarant &&
                !hasGarant && (
                  <Bouton
                    onClick={this.addGarant}
                    className="bouton-garant"
                    label="+ Ajouter un(e) garant(e)"
                    color="primary"
                    variant="contained"
                    size="small"
                  />
                )}
            </div>
          </div>
        </Grid>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  listSituationPro: SituationProSelectors.getListSituationProfessionnelle(
    state,
  ),
  archive: PiecesSelectors.getArchive(state),
  archiveFetching: PiecesSelectors.getArchiveFetching(state),
  archiveError: PiecesSelectors.getArchiveError(state),
  isControleDossier: PartenaireSelectors.getControleDossier(state),
  isCumulsUtilises: PartenaireSelectors.getCumulsUtilises(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getListSituationPro: () =>
    dispatch(
      SituationProActionCreators.getListSituationProfessionnelleAsync.request(),
    ),
  addGarant: (ref: string) =>
    dispatch(SituationPersoActionCreators.resetSituationPersoAndAddGarant(ref)),
  resetSituationPro: () =>
    dispatch(SituationProActionCreators.resetSituationProParcoursCreation()),
  getArchiveNetheos: params =>
    dispatch(PiecesActionCreators.getArchiveNetheos.request(params)),
  resetSelectedPerson: () =>
    dispatch(DossierLocationActionCreators.resetSelectedPerson()),
  rouvrirDossierNetheos: params =>
    dispatch(PiecesActionCreators.rouvrirDossierNetheos.request(params)),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InfoCandidat),
);
