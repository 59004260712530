export const isPathAuthorizedForUser = (
  path,
  profil,
  refBien?,
  statutDossier?,
): boolean => {
  if (profil && !profil.fonctions.find(f => f === 'FRONT_LECTURE')) {
    return false;
  } else if (path.startsWith('/dashboard')) {
    return true;
  } else if (profil && profil.fonctions) {
    if (
      path.startsWith('/mes-administrateurs-de-biens') &&
      profil.fonctions.find(f => f === 'PRMDRTADB_LECTURE')
    ) {
      return true;
    } else if (
      path === '/mes-utilisateurs' &&
      (profil.fonctions.find(f => f === 'PRMUTIBC_LECTURE') ||
        profil.fonctions.find(f => f === 'PRMUTIADB_LECTURE'))
    ) {
      return true;
    } else if (
      (path === '/mes-utilisateurs/ajouter' ||
        path === '/mes-utilisateurs/modifier') &&
      profil.fonctions.find(f => f === 'PRMUTIADB_ECRITURE')
    ) {
      return true;
    } else if (
      (path === '/mes-utilisateurs/modifierBesse' ||
        path === '/mes-utilisateurs/ajouterBesse') &&
      profil.fonctions.find(f => f === 'PRMUTIBC_ECRITURE')
    ) {
      return true;
    } else if (
      path.startsWith('/dossier-location') &&
      (profil.fonctions.find(f => f === 'TDBDOSLOC_MODIFICATION_MIENS') ||
        profil.fonctions.find(f => f === 'TDBDOSLOC_MODIFICATION_TOUS'))
    ) {
      if (
        (path.startsWith('/dossier-location/situation-personnelle') ||
          path.startsWith('/dossier-location/situation-professionnelle')) &&
        (!refBien || (statutDossier && statutDossier === 'NON_VALIDE'))
      ) {
        return false;
      }
      return true;
    }
  }
  return false;
};
