import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import memoizeOne from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  arrayInsert,
  arrayRemove,
  Field,
  FieldArray,
  getFormValues,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import Bouton from '../../../components/bouton/Bouton';
import Divider from '../../../components/Divider';
import FormContainer from '../../../components/FormContainer';
import InputField from '../../../components/input/InputField';
import InputPassword from '../../../components/input/InputPassword';
import Select, { DropdownItem } from '../../../components/select/Select';
import TransferList from '../../../components/TransferList';
import { isEmail, isMotDePasse } from '../../../helpers/validators';
import {
  AdministrateurBien,
  AdministrateurBienDetail,
  ApplicationImmobilierDetail,
  FonctionPoste,
  Utilisateur,
  UtilisateurDetailAdbProfilSites,
} from '../../../services/Api/swagger/api.js';
import ErreursConstante from '../../../_constantes/erreursConstantes.json';
import {
  construireListFonctionsOptions,
  construireListRegroupements,
} from '../helpers';
import AjouterSitesAdb from './AjouterSitesAdb';

export interface UtilisateurFormErrors {
  nom?: string;
  prenom?: string;
  mail?: string;
  listAdb?: string;
  adbs?: any;
  motDePasse?: string;
  confirmationMotDePasse?: string;
}

export interface UtilisateurFormData {
  nom: string;
  prenom: string;
  mail: string;
  fonction: string;
  listAdb: DropdownItem[];
  adbs?: UtilisateurDetailAdbProfilSites[];
  motDePasse?: string;
  confirmationMotDePasse: string;
}
interface CustomProps {
  formValues: UtilisateurFormData;
  formErrors?: UtilisateurFormErrors;
  classes?: any;
  fonctionsPostes?: FonctionPoste[];
  adbDetailProfils?: ApplicationImmobilierDetail[];
  adbDetail?: AdministrateurBienDetail[];
  adbRegroupementList?: AdministrateurBien[];
  getAdbDetail(params: number): void;
  adbFetching: boolean;
  adbDetailFetching: boolean;
  handlePrecedent(): void;
  utilisateurBESSE: boolean;
  utilisateurConnecte: Utilisateur;
  pushArray(form: string, field: string, index: number, value: any): void;
  removeArray(form: string, field: string, index: number): void;
  isFetching: boolean;
  disabled?: boolean;
  isModify?: boolean;
}

type Props = InjectedFormProps<UtilisateurFormData> & CustomProps;

const memoizeConstruireFonctionsOptions = memoizeOne(
  construireListFonctionsOptions,
);
const memoizeConstruireRegroupements = memoizeOne(construireListRegroupements);

class UtilisateurAdbForm extends React.Component<Props> {
  handleChangeAdbRegroupements = (value: any) => {
    const id = value.value;
    this.props.getAdbDetail(id);

    const index =
      this.props.formValues && this.props.formValues.adbs
        ? this.props.formValues.adbs.length
        : 0;
    this.props.pushArray('ajouterUtilisateurForm', 'adbs', index, {
      refAdb: id,
    });
  };

  handleRemoveAdbRegroupements = (value: DropdownItem) => {
    //  this.props.getAdbDetail(Number(value.value));
    const index =
      this.props.formValues.adbs &&
      this.props.formValues.adbs.findIndex(
        adb => adb.refAdb === Number(value.value),
      );

    if (index || index === 0) {
      this.props.removeArray('ajouterUtilisateurForm', 'adbs', index);
    }
  };

  renderAdbs = ({ fields, meta }) => {
    return (
      <>
        {fields.length > 0 && <Divider />}

        {fields.map((adb, index) => {
          return (
            <AjouterSitesAdb
              key={index}
              index={index}
              adb={adb}
              getAdbDetail={this.props.getAdbDetail}
              fields={fields}
              formValues={this.props.formValues}
              adbDetail={this.props.adbDetail}
              utilisateurConnecteAdb={
                this.props.utilisateurConnecte.detailAdbProfilSites
              }
              disabled={this.props.disabled}
            />
          );
        })}
      </>
    );
  };
  render() {
    const {
      fonctionsPostes,
      adbRegroupementList,
      isFetching,
      disabled,
      isModify,
    } = this.props;

    const optionsFonctionsPostes =
      (fonctionsPostes && memoizeConstruireFonctionsOptions(fonctionsPostes)) ||
      [];

    const optionsRegroupements =
      (adbRegroupementList &&
        memoizeConstruireRegroupements(adbRegroupementList)) ||
      [];

    return (
      <form onSubmit={this.props.handleSubmit} noValidate>
        <FormContainer>
          <Grid container spacing={1}>
            <Grid item>
              <Field
                name="nom"
                component={InputField}
                label="Nom"
                required
                disabled={disabled}
              />
            </Grid>
            <Grid item>
              <Field
                name="prenom"
                component={InputField}
                label="Prénom"
                required
                disabled={disabled}
              />
            </Grid>
            <Grid item>
              <Field
                name="mail"
                component={InputField}
                label="E-mail"
                required
                size={50}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          {!isModify && (
            <Grid container spacing={1}>
              <Grid item>
                <Field
                  label="Mot de passe"
                  name="motDePasse"
                  component={InputPassword}
                  type="password"
                  required
                />
              </Grid>
              <Grid item>
                <Field
                  label="Confirmation de votre mot de passe"
                  name="confirmationMotDePasse"
                  component={InputPassword}
                  type="password"
                  size={25}
                  required
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={0}>
            <Typography style={{ marginTop: '-8px' }} variant="caption">
              Le mot de passe doit avoir au moins 8 caractères, 1 majuscule, 1
              minuscule et 1 chiffre.
            </Typography>
          </Grid>
          <Grid container spacing={1}>
            <Grid item>
              <Field
                name="fonction"
                component={Select}
                label="Fonction"
                options={optionsFonctionsPostes}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </FormContainer>
        <Grid container>
          <h3>Regroupements</h3>
        </Grid>
        <FormContainer>
          <Grid container spacing={1}>
            <Grid item>
              <Field
                name="listAdb"
                component={TransferList}
                label="Administrateur de biens"
                required
                options={optionsRegroupements}
                onAdd={this.handleChangeAdbRegroupements}
                onRemove={this.handleRemoveAdbRegroupements}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </FormContainer>
        <FieldArray name="adbs" component={this.renderAdbs} />
        <Grid container justify="space-between">
          <Grid item>
            <Bouton
              label="Annuler"
              variant="contained"
              color="default"
              size="medium"
              type="submit"
              onClick={this.props.handlePrecedent}
            />
          </Grid>
          <Grid item>
            {!disabled && (
              <Bouton
                fetching={isFetching}
                label="Enregistrer"
                variant="contained"
                color="primary"
                size="medium"
                type="submit"
              />
            )}
          </Grid>
        </Grid>
      </form>
    );
  }
}
/**
 * Méthode de validation du formulaire
 * @param values
 */
const validateForm = (
  values: UtilisateurFormData,
  props,
): UtilisateurFormErrors => {
  const errors: UtilisateurFormErrors = {} as UtilisateurFormErrors;

  if (!values.nom) {
    errors.nom = ErreursConstante.formulaire.generale.champObligatoire;
  }
  if (!values.prenom) {
    errors.prenom = ErreursConstante.formulaire.generale.champObligatoire;
  }
  if (!values.mail) {
    errors.mail = ErreursConstante.formulaire.generale.champObligatoire;
  } else if (values.mail && values.mail && !isEmail(values.mail)) {
    errors.mail = ErreursConstante.formulaire.generale.emailIncorrect;
  }
  if (!values.listAdb) {
    errors.listAdb = ErreursConstante.formulaire.generale.champObligatoire;
  }
  if (!values.adbs) {
    errors.adbs = ErreursConstante.formulaire.generale.champObligatoire;
  } else if (values.adbs.length > 0) {
    const adbsErrors = [] as any[];
    values.adbs.map((adb, index) => {
      const adbError = {} as any;
      if (!adb.profil) {
        adbError.profil = ErreursConstante.formulaire.generale.champObligatoire;
      }
      if (!adb.sites || !adb.sites.length) {
        adbError.sites = ErreursConstante.formulaire.generale.champObligatoire;
      }
      adbsErrors[index] = adbError;
    });
    if (adbsErrors.length) {
      errors.adbs = adbsErrors;
    }
  }

  if (
    (!values.motDePasse || !isMotDePasse(values.motDePasse)) &&
    !props.isModify
  ) {
    errors.motDePasse = ErreursConstante.formulaire.generale.motPasse;
  }

  if (values.motDePasse !== values.confirmationMotDePasse && !props.isModify) {
    errors.confirmationMotDePasse =
      ErreursConstante.formulaire.generale.confirmationMotPasse;
  }
  return errors;
};

const mapStateToProps = (state, props) => ({
  formValues: getFormValues('ajouterUtilisateurForm')(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  pushArray: (form: string, field: string, index: number, value: any) =>
    dispatch(arrayInsert(form, field, index, value)),
  removeArray: (form: string, field: string, index: number) =>
    dispatch(arrayRemove(form, field, index)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<UtilisateurFormData>({
    form: 'ajouterUtilisateurForm',
    validate: validateForm,
  })(UtilisateurAdbForm),
);
