import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    candidat: {
      background: 'white',
      padding: 24,
      paddingLeft: 56,
    },
    table: {
      "fontSize": 13,
      "marginBottom": 12,
      '& tr': {
        height: 35,
      },
      '& th, & td': {
        fontSize: 12,
        padding: '4px 12px',
        borderBottomWidth: 0.5,
        borderRightWidth: 0.5,
        borderLeftWidth: 0.5,
        borderTopWidth: 0,
      },
    },
  } as any);

export default styles;
