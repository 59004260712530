import { withStyles } from '@material-ui/core';
import React from 'react';
import imgBertrand from '../../../../ressources/images/bertrand-mulot.jpg';
import points from '../../../../ressources/images/points.png';
import defaultStyles from '../styles';
import styles from './styles';

interface IProps {
  classes?: any;
  cookiesValid: boolean;
}

class BlocQuiSommesNous extends React.Component<IProps> {

  onClickEvent = () => {
    if (window.MatomoTagManager) {
      window._paq.push([
        'trackEvent',
        'Site Bessé',
        'Clic - CTA',
        'Accueil - En savoir plus site Bessé',
      ]);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.texte}>
          <h3>Qui sommes nous</h3>
          <h4>
            <span className={classes.besse}>Bessé</span> Immobilier &
            Construction
            <br />
            <span className={classes.subtitle}>Conseil en assurances</span>
          </h4>
          <div className={classes.divider} />
          <p>
            <strong>Bessé Immobilier & Construction</strong> est l'un des
            leaders du courtage en assurances dans le secteur de l'immobilier.
            Au-delà de l'expertise du conseil en assurances proposé aux
            professionnels (gestionnaires locatifs, syndics de copropriété),
            notre volonté est de vous offrir des solutions ultra-spécialisées et
            novatrices, en réponse à vos attentes.
            <br />
            Vous nous avez suggéré{' '}
            <span className="vertuloo-font-sm vert">vertuloo</span>, <br />
            nous l'avons développé pour vous.
          </p>
          <h5>
            Bertrand MULOT <span>- Directeur</span>
          </h5>
          <a
            href="https://www.besse.fr/fr/vos-secteurs-dactivites/immobilier"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.linkPlus + ' ' + classes.linkPlusBleu}
            onClick={this.onClickEvent}
          >
            en savoir plus
          </a>
        </div>
        <div className={classes.containerImage}>
          <img
            src={points}
            className={classes.dots}
            alt="immobilier"
            width={70}
          />
          <img src={imgBertrand} width="300" alt="Bertrand Mulot" />
          <div className={classes.rectangle} />
        </div>
      </div>
    );
  }
}

export default withStyles(theme => ({
  ...styles(theme),
  ...defaultStyles(theme),
}))(BlocQuiSommesNous);
