import { Theme } from '@material-ui/core/styles';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
  ({
    footerLogo: {
      display: 'flex',
      alignItems: 'center',
      height: 89,
      backgroundColor: theme.palette.primary.main,
      padding: '0 1.25rem',
    },
    footerLogoBesse: {
      padding: 4,
      background: 'white',
      marginLeft: 24,
    },
    footerLiens: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      height: 23,
      backgroundColor: theme.palette.common.black,
      padding: 0,
      margin: 0,
      paddingTop: 3,
    },
    footerLien: {
      listStyle: 'none',
      textTransform: 'uppercase',
      color: theme.palette.common.white,
      fontSize: 10,
      marginLeft: 10,
      marginTop: 2,
    },
  } as any);
// tslint:enable: quotemark

export default styles;
