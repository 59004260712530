import { AxiosInstance, AxiosResponse } from 'axios';
import ErreursConstante from '../../_constantes/erreursConstantes.json';
import {
  AdministrateurBien,
  AdministrateurBienApiFactory,
  AdministrateurBienDetail,
  AdministrateurBienList,
} from './swagger';

export interface IApi {
  getAllAdministrateursDeBiens(params): Promise<AdministrateurBienList>;
  getAdbIdDetail(params: number): Promise<AdministrateurBienDetail>;
  // getAdbIdDetail(params: number): Promise<AdministrateurBienDetail>;
  getAdbRegroupement(params: number): Promise<AdministrateurBien[]>;
  activerAdb(params): Promise<AdministrateurBienDetail>;
  desactiverAdb(params): Promise<AdministrateurBienDetail>;
}

const create = (endpoint: string, axiosClient: AxiosInstance) => {
  const administrateurBienApi = AdministrateurBienApiFactory(
    {},
    endpoint,
    axiosClient,
  );

  /**
   * Permet de ramener la liste complète des Adminsitrateurs de biens
   *
   * @param params : Critères de recherche
   */
  const getAllAdministrateursDeBiens = (
    params,
  ): Promise<AdministrateurBienList> => {
    const promise: Promise<AdministrateurBienList> = new Promise(
      (resolve, reject) => {
        administrateurBienApi
          .administrateursBiensGet(
            params.numAdherent,
            params.nomAdherent,
            params.commercial,
            params.departement,
            params.codePostal,
            params.ville,
            params.actif,
            params.page,
            params.taille,
            [params.tri],
            {},
          )
          .then((response: AxiosResponse<AdministrateurBienList>) => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      },
    );
    return promise;
  };

  const getAdbIdDetail = (
    params: number,
  ): Promise<AdministrateurBienDetail> => {
    const promise: Promise<AdministrateurBienDetail> = new Promise(
      (resolve, reject) => {
        administrateurBienApi
          .administrateursBiensIdGet(params, {})
          .then((response: AxiosResponse<AdministrateurBienDetail>) => {
            resolve(response.data);
          })
          .catch(error => {
            switch (error.request.status) {
              case 404:
                error.message =
                  ErreursConstante.apiAdministrateursDeBiens.consulter[404];
                break;
              default:
                error.message =
                  ErreursConstante.apiAdministrateursDeBiens.consulter[500];
                break;
            }
            reject(error);
          });
      },
    );

    return promise;
  };

  /**
   * Permet de ramener un Adminsitrateur de biens
   *
   * @param params : Identifiant de l'administrateur de biens
   */
  const getAdbRegroupement = (
    params: number,
  ): Promise<AdministrateurBien[]> => {
    const promise: Promise<AdministrateurBien[]> = new Promise(
      (resolve, reject) => {
        administrateurBienApi
          .administrateursBiensIdRegroupementGet(params, 'AGREMENT')
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            switch (error.request.status) {
              case 404:
                error.message =
                  ErreursConstante.apiAdministrateursDeBiens.consulter[404];
                break;
              default:
                error.message =
                  ErreursConstante.apiAdministrateursDeBiens.consulter[500];
                break;
            }
            reject(error);
          });
      },
    );
    return promise;
  };

  /**
   * Permet d'activer un administrateur de biens
   * @param params: Identifiant de l'adb
   */
  const activerAdb = (params): Promise<AdministrateurBienDetail> => {
    return new Promise((resolve, reject) => {
      administrateurBienApi
        .administrateursBiensIdActiverPost(params.id, params.adbActiver)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          error.message =
            ErreursConstante.apiAdministrateursDeBiens.activer[
              error.request.status
            ];
          reject(error);
        });
    });
  };
  /**
   * Permet de désactiver un administrateur de biens
   * @param params: Identifiant de l'adb
   */
  const desactiverAdb = (params): Promise<AdministrateurBienDetail> => {
    return new Promise((resolve, reject) => {
      administrateurBienApi
        .administrateursBiensIdDesactiverPost(params)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          error.message =
            ErreursConstante.apiAdministrateursDeBiens.activer[
              error.request.status
            ];
          reject(error);
        });
    });
  };
  return {
    getAllAdministrateursDeBiens,
    getAdbIdDetail,
    getAdbRegroupement,
    activerAdb,
    desactiverAdb,
  };
};

export default create;
