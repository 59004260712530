import React from 'react';

// tslint:disable: max-line-length
export default class PiecesSVG extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="83"
        height="83"
        viewBox="0 0 67 67"
      >
        <defs>
          <path id="a" d="M0 42.18h36.199V0H0z" />
        </defs>
        <g fillRule="evenodd" id="Mask" fill="rgba(92, 81, 117, 0.25)">
          <circle cx="33.5" cy="33.5" r="33.5" />
          <g transform="translate(15.462 12.37)">
            <path
              fill="#000"
              d="M36.171 10.328a.912.912 0 0 0-.665-1.105l-3.356-.836.292-5.246a1.436 1.436 0 0 0-1.355-1.513l-1.235-.069v35.367c0 .582-.473 1.053-1.054 1.053H4.006A1.41 1.41 0 0 0 5.4 39.231h13.053l11.62 2.893a1.887 1.887 0 0 0 2.285-1.374l3.389-13.608a1.886 1.886 0 0 0-1.375-2.286l-.828-.207a.91.91 0 0 1-.663-1.105l3.29-13.216z"
            />
            <mask id="b" fill="#fff">
              <use />
            </mask>
            <path
              fill="#FFF"
              d="M4.646 11.496H16.1v-1.271H4.646v1.271zm25.206 25.43V1.054C29.852.472 29.38 0 28.798 0H1.053C.473 0 0 .472 0 1.054v35.872c0 .582.472 1.053 1.053 1.053h27.745c.582 0 1.054-.471 1.054-1.053z"
            />
            <path
              fill="#000"
              d="M4.647 11.496H16.1v-1.271H4.647zM4.646 14.947h15.461v-1.273H4.646zM4.646 18.735h6.184v-1.272H4.646z"
              mask="url(#b)"
            />
          </g>
        </g>
      </svg>
    );
  }
}
// tslint:enable: max-line-length
