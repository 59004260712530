import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Bouton from '../bouton/Bouton';
import messages from './messages';

interface IProps {
  children: JSX.Element | JSX.Element[] | string;
  error?: any; // FIXME Typer l'erreur
  loading?: boolean; //
  onRetry?(): void; // Méthode à appeler
  classes: any;
  className?: any;
  message?: string; // Message d'erreur à afficher en cas d'erreur de chargement
}

const styles = () => ({
  message: {
    marginBottom: 16,
  },
});

/**
 * Composant pour gérer les chargements en cours :
 * - Affichage d'un loader si chargement en cours
 * - Affichage d'un message et d'un bouton "Réessayer" en cas d'erreur
 * - Affichage des enfants sinon
 */
class LoadingErrorBoundary extends React.PureComponent<IProps> {
  render() {
    const { children, classes, error, loading, onRetry, message } = this.props;
    if (loading) {
      return (
        <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
          <CircularProgress />
        </div>
      );
    } else if (error) {
      return (
        <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
          <Typography
            align="center"
            variant="body2"
            className={classes.message}
          >
            {message || messages.loadingErrorMessage}
          </Typography>
          {onRetry && (
            <Bouton
              label={messages.retry}
              variant="contained"
              color="primary"
              size="medium"
              onClick={onRetry}
            />
          )}
        </div>
      );
    }
    return children;
  }
}

export default withStyles(styles)(LoadingErrorBoundary);
