import { getConfig } from '@bc/react-config';

export const getApiEndpoint = () => {
  return getConfig('REACT_APP_AGREMENT_API_ENDPOINT') || '';
};

export const getKeycloakUrl = () => {
  return getConfig('REACT_APP_AGREMENT_KEYCLOAK_URL') || '';
};

export const getSentryUrl = () => {
  return getConfig('REACT_APP_AGREMENT_SENTRY_URL') || '';
};

export const getSentryEnvironment = () => {
  return getConfig('REACT_APP_AGREMENT_SENTRY_ENVIRONMENT') || '';
};

export const getNetheosEndpoint = () => {
  return getConfig('REACT_APP_AGREMENT_NETHEOS_URL') || '';
};
export const getKeycloakRealm = () => {
  return getConfig('REACT_APP_AGREMENT_KEYCLOAK_REALM') || '';
};

export const getKeycloakClientId = () => {
  return getConfig('REACT_APP_AGREMENT_KEYCLOAK_CLIENT_ID') || '';
};

export const getInfoMaintenance = () => {
  return getConfig('REACT_APP_AGREMENT_INFOMAINTENANCE') || '';
};

export const getMessageMaintenance = () => {
  return getConfig('REACT_APP_AGREMENT_MSGINFOMAINTENANCE') || '';
};

export const getStateMessageInformation = () => {
  return getConfig('REACT_APP_MESSAGE_INFORMATION_STATE') || '';
};

export const getMatomoAnalyticsManager = () => {
  return getConfig('REACT_APP_AGREMENT_MATOMO') || '';
};

export const getAcceptioCookieManager = () => {
  return getConfig('REACT_APP_AGREMENT_AXEPTIO') || '';
};