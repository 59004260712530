import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    container: {
      padding: '48px 12px 24px 12px',
      margin: 'auto',
      maxWidth: 880,
      '& h3': {
        fontSize: 28,
        fontWeight: 'normal',
        lineHeight: '37px',
        textAlign: 'right',
        textTransform: 'initial',
      },
    },
    uppercase: {
      fontWeight: 'bold',
    },
    containerTexte: {
      display: 'flex',
      marginTop: 16,
      '& > div': {
        flex: 1,
      },

      '& p': {
        fontSize: 15,
        paddingRight: 24,
      },
    },
    containerImage: {
      position: 'relative',
      textAlign: 'center',
      padding: 16,
    },
    dots: {
      left: 0,
      top: 0,
      position: 'absolute',
    },
    rectangle: {
      height: 206,
      width: 210,
      background: theme.palette.primary.main,
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: -1,
    },
    '@media all and (max-width: 789px)': {
      containerTexte: {
        display: 'block',
      },
      dots: {
        display: 'none',
      },
      rectangle: {
        display: 'none',
      },
      containerImage: {
        '& img': {
          margin: 'auto',
          marginTop: 24,
          width: '90%',
        },
      },
      container: {
        '& h3': {
          textAlign: 'left',
        },
      },
    },
  } as any);

export default styles;
