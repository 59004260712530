import { withStyles } from '@material-ui/core';
import React from 'react';
import BlocAvantages from './BlocAvantages';
import BlocBackground from './BlocBackground';
import BlocDevis from './BlocDevis';
import BlocFonctions from './BlocFonctions';
import BlocQuiSommesNous from './BlocQuiSommesNous';
import styles from './styles';
import ContactForm from '../../HomePage/Accueil/ContactForm';
import BulleFixed from '../../../components/BulleFixed';
import { Cookies, withCookies } from 'react-cookie';
import BlocInfographie from './BlocInfographie';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  cookies: Cookies;
}
interface State {
  cookiesValid: string;
}
class Blocs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const cookies = this.getCookies();
    this.state = {
      cookiesValid: cookies,
    };
  }

  /**
   * Récupération d'un cookie coté client
   */
  getCookies = () => {
    return this.props.cookies.get('performanceIsCheck');
  };
  render() {
    return (
      <>
        {/** Titre */}
        <BlocBackground />
        <BlocQuiSommesNous />
        <BlocDevis cookiesValid={this.state.cookiesValid === 'true'} />
        <BlocInfographie />
        <BlocFonctions />
        <BlocAvantages />
        <BulleFixed cookiesValid={this.state.cookiesValid === 'true'} />
        <ContactForm cookiesValid={this.state.cookiesValid === 'true'} />
      </>
    );
  }
}

export default withCookies(withStyles(styles)(Blocs));
