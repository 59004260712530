import { Theme } from '@material-ui/core/styles';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
  ({
    container: {
      maxWidth: 1080,
      margin: 'auto',
    },
  } as any);
// tslint:enable: quotemark

export default styles;
