import { Grid } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getFormValues } from 'redux-form';
import Layout from '../../../components/Layout';
import LoadingBoundary from '../../../components/LoadingBoundary';
import { DropdownItem } from '../../../components/select/Select';
import Snack from '../../../components/Snack';
import { history } from '../../../helpers/history';
import {
  ActionCreators as AdbActionsCreators,
  Selectors as AdbSelectors,
} from '../../../redux/AdministrateurDeBien';
import {
  ActionCreators as UtilisateursActionsCreators,
  Selectors as UtilisateursSelectors,
} from '../../../redux/Utilisateurs';
import {
  AdministrateurBien,
  AdministrateurBienDetail,
  FonctionPoste,
  Utilisateur,
} from '../../../services/Api/swagger';
import Libelle from '../../../_i18n/fr.json';
import { transformUtilisateurDataToModel } from '../helpers';
import UtilisateurAdbForm from '../UtilisateurAdbForm';

interface Props {
  utilisateurError?: any;
  utilisateur: Utilisateur;
  getFonctionsPostes(): void;
  fonctionsPostes?: FonctionPoste[];
  getADBRegroupementList(params: number): void;
  getAdbDetail(params: number): void;
  adbRegroupementList?: AdministrateurBien[];
  adbDetail?: AdministrateurBienDetail[];
  formValues: {
    Utilisateur;
    listAdb: DropdownItem[];
  };
  adbFetching: boolean;
  adbDetailFetching: boolean;
  modifierUtilisateur(params: any): void;
  utilisateurConnecte?: Utilisateur;
  utilisateurFetching: boolean;
  adbError?: any;
  fonctionsPostesError?: any;
  emptyErrorMessages(): void;
  adbId: number;
  adbSelectedDetail: AdministrateurBienDetail;
  isChargeClienteleBesse: boolean;
}

interface State {
  listDetailAdb?: AdministrateurBienDetail[];
}

class ConsulterUtilisateurAdb extends React.Component<Props> {
  componentDidMount = () => {
    this.handleLoadData();
  };

  handleLoadData = () => {
    this.props.getFonctionsPostes();
    if (
      this.props.utilisateurConnecte &&
      this.props.utilisateurConnecte.role === 'BESSE'
    ) {
      this.props.getADBRegroupementList(this.props.adbId);
    }
  };
  handleSubmit = values => {
    const transformedValues = transformUtilisateurDataToModel(values);

    const params = {
      ...{ username: values.identifiant },
      ...{ body: transformedValues },
    };
    this.props.modifierUtilisateur(params);
  };

  handlePrecedent = () => {
    history.push('/mes-utilisateurs');
  };

  renderAdbList = () => {
    const utilisateur = this.props.utilisateurConnecte;
    if (utilisateur) {
      if (utilisateur.role === 'BESSE') {
        return this.props.adbRegroupementList;
      } else {
        const adbList =
          utilisateur.detailAdbProfilSites &&
          utilisateur.detailAdbProfilSites.map(adb => ({
            identifiant: adb.refAdb,
            nom: adb.nomAdb,
          }));
        return adbList;
      }
    } else {
      return null;
    }
  };

  handleClose = () => {
    this.props.emptyErrorMessages();
  };
  renderError = (error: any) => {
    return (
      <Snack
        onClose={this.handleClose}
        open={true}
        variant="error"
        message={error.message}
      />
    );
  };

  buildInitialValues = (utilisateur: Utilisateur) => {
    const initialValues = {
      ...utilisateur,
      listAdb:
        utilisateur.detailAdbProfilSites &&
        utilisateur.detailAdbProfilSites.map(detail => ({
          key: detail.refAdb,
          text: detail.nomAdb,
          value: detail.refAdb,
        })),
      adbs:
        utilisateur.detailAdbProfilSites &&
        utilisateur.detailAdbProfilSites.map(detail => ({
          refAdb: detail.refAdb,
          profil: detail.profil,
          sites:
            detail.sites &&
            detail.sites.map(site => ({
              key: detail.refAdb && site + detail.refAdb,
              value: site,
            })),
        })),
    };
    return initialValues;
  };
  render() {
    const {
      adbDetail,
      adbFetching,
      fonctionsPostes,
      fonctionsPostesError,
      getAdbDetail,
      utilisateurConnecte,
      utilisateurFetching,
      utilisateurError,
      adbSelectedDetail,
      utilisateur,
      isChargeClienteleBesse,
    } = this.props;

    const utilisateurBESSE =
      utilisateurConnecte && utilisateurConnecte.role === 'BESSE';

    const disabled = isChargeClienteleBesse;
    const adbList = this.renderAdbList();

    let initialValues;
    if (!utilisateurFetching && utilisateur) {
      initialValues = this.buildInitialValues(utilisateur);
    }

    if (utilisateurConnecte && adbList && adbList.length > 0) {
      return (
        <Layout title="Modifier utilisateur">
          <div>
            <h2>
              Modifier utilisateur {Libelle.pageUtilisateurs.ajouter.pourAdb}{' '}
              {adbSelectedDetail && adbSelectedDetail.nom}
            </h2>
            <LoadingBoundary
              loading={utilisateurFetching && adbFetching}
              error={fonctionsPostesError}
              onRetry={this.handleLoadData}
            >
              <>
                <UtilisateurAdbForm
                  getAdbDetail={getAdbDetail}
                  adbDetail={adbDetail}
                  adbRegroupementList={adbList}
                  onSubmit={this.handleSubmit}
                  fonctionsPostes={fonctionsPostes}
                  adbFetching={adbFetching}
                  adbDetailFetching={adbFetching}
                  handlePrecedent={this.handlePrecedent}
                  utilisateurBESSE={utilisateurBESSE}
                  utilisateurConnecte={utilisateurConnecte}
                  isFetching={utilisateurFetching}
                  initialValues={initialValues}
                  disabled={disabled}
                  isModify={true}
                />
                <Grid container justify="flex-end" style={{ marginTop: 12 }}>
                  {utilisateurError && this.renderError(utilisateurError)}
                </Grid>
              </>
            </LoadingBoundary>
          </div>
        </Layout>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  utilisateurError: UtilisateursSelectors.getUtilisateurError(state),
  fonctionsPostes: UtilisateursSelectors.getListFonctionsPostes(state),
  adbRegroupementList: AdbSelectors.getADBRegroupementList(state),
  adbDetail: AdbSelectors.getADBDetail(state),
  formValues: getFormValues('ajouterUtilisateurForm')(state),
  adbFetching: AdbSelectors.getADBFetching(state),
  adbError: AdbSelectors.getADBError(state),
  adbDetailFetching: AdbSelectors.getADBFetching(state),
  utilisateurConnecte: UtilisateursSelectors.getUtilisateurConnecte(state),
  utilisateurFetching: UtilisateursSelectors.getUtilisateurFetching(state),
  fonctionsPostesError: UtilisateursSelectors.fonctionsPostesError(state),
  adbId: AdbSelectors.getAdbId(state),
  adbSelectedDetail: AdbSelectors.getSelectedAdbDetail(state),
  utilisateur: UtilisateursSelectors.getUtilisateur(state),
  isChargeClienteleBesse: UtilisateursSelectors.isChargeClienteleBesse(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getFonctionsPostes: () =>
    dispatch(UtilisateursActionsCreators.getFonctionsPostes.request()),
  getADBRegroupementList: (params: number) =>
    dispatch(AdbActionsCreators.getADBRegroupement.request(params)),
  getAdbDetail: (params: number) =>
    dispatch(AdbActionsCreators.getADBIdDetail.request(params)),
  ajouterUtilisateur: (params: Utilisateur) =>
    dispatch(UtilisateursActionsCreators.ajouterUtilisateur.request(params)),
  emptyErrorMessages: () =>
    dispatch(UtilisateursActionsCreators.emptyErrorMessages()),
  modifierUtilisateur: params =>
    dispatch(UtilisateursActionsCreators.modifierUtilisateur.request(params)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConsulterUtilisateurAdb);
