import MomentUtils from '@date-io/moment';
import { createMuiTheme, FormControl, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import moment from 'moment';
import 'moment/locale/fr';
import React from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { dateFormat, dateFormatApi } from '../../helpers/validators';
import CalendarIcon from '../../ressources/images/CalendarSVG';
import { theme } from '../../theme';
import messages from './messages';
import styles from './styles';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  // Variante de Style
  // inline : Ouverture sur le champ clique
  // dialog : Ouverture en mode modal
  variante?: 'inline' | 'dialog';
  isYearMonth?: boolean;
  isYear?: boolean;
  // Variant de l'input
  varianteInput?: 'filled' | 'outlined' | 'standard';
  // Libelle de l'input
  label: string;
  // Le champ est-il obligatoire ?
  required?: boolean;
  // Le champ est-il désactivé ?
  disabled?: boolean;
  maxDate?: Date;
  minDate?: Date;
  minDateMessage?: string;
  maxDateMessage?: string;
  invalidDateMessage?: string;
  input?: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  onBlur?: any;
}

interface State {
  // Date sélectionnée
  dateSelectionnee: any;
}

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: theme.palette.primary.main,
    },
    secondary: {
      main: theme.palette.secondary.main,
    },
    error: {
      main: '#FF4176',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiPickersMonth: {
      monthSelected: {
        color: theme.palette.common.black,
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: '2px',
        lineHeight: '1.6em',
        color: '#FF4176',
      },
      contained: {
        margin: 0,
      },
    },
    MuiSvgIcon: {
      root: {
        fill: theme.palette.secondary.main,
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
      },
      adornedEnd: {
        backgroundColor: '#FFF',
        color: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: '#746b89',
        },
      },
    },
    MuiIconButton: {
      root: {
        width: '20px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: theme.palette.common.white,
      },
    },
    MuiPickersInlineWrapper: {
      popoverPaper: {
        paddingBottom: '0',
        borderRadius: '0',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: '#dedde6',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#746b89',
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: '#dedde6',
        '&>*': {
          backgroundColor: '#dedde6',
        },
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: theme.palette.common.black,
      },
    },
    MuiPickersDay: {
      day: {
        color: theme.palette.common.black,
      },
      daySelected: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.common.white,
        },
      },
      current: {
        color: theme.palette.common.white,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: theme.palette.common.black,
      },
    },
  },
} as ThemeOptions);

/**
 * Composant affichant un message d'erreur sous forme de snack.
 */
class InputCalendrier extends React.Component<Props, State> {
  /**
   * Valeurs d'initialisation
   */
  public static defaultProps = {};

  constructor(props: Props) {
    super(props);
    let value: Date | any = null;
    if (props.input && props.input.value) {
      value = new Date(props.input && props.input.value);
    } else if (props.meta && props.meta.initial) {
      value = new Date(props.meta && props.meta.initial);
    }

    this.state = {
      dateSelectionnee: value,
    };

    if (this.props.input) {
      this.props.input.onChange(
        value ? moment(value).format(dateFormatApi) : null,
      );
    }
  }

  /**
   * Changement de la date sélectionnée
   */
  handleDateChange = date => {
    this.setState({
      dateSelectionnee: date,
    });
    if (this.props.input) {
      const newDate = moment(date).format(dateFormatApi);
      this.props.input.onChange(newDate === 'Invalid date' ? null : newDate);
    }
  };

  handleBlur = () => {
    if (this.props.input) {
      this.props.input.onChange(
        this.state.dateSelectionnee
          ? moment(this.state.dateSelectionnee).format(dateFormatApi)
          : null,
      );
    }
  };

  handleError = date => {
    if (this.props.input && !date) {
      this.props.input.onChange('');
    }
  };

  render() {
    const {
      variante,
      varianteInput,
      label,
      required,
      disabled,
      classes,
      input,
      maxDate,
      minDate,
      meta,
      isYearMonth,
      isYear,
      minDateMessage,
      maxDateMessage,
      invalidDateMessage,
    } = this.props;
    const { dateSelectionnee } = this.state;

    const commonProps = {
      autoOk: true,
      label,
      ...input,
      value: dateSelectionnee,
      onError: this.handleError,
      onChange: this.handleDateChange,
      onBlur: this.props.onBlur,
      minDate: minDate ? minDate : undefined,
      maxDate: maxDate ? maxDate : new Date(),
      variant: variante,
      inputVariant: varianteInput,
      className: classes.datepicker,
      InputProps: {
        classes: {
          input: classes.input,
          underline: classes.underline,
        },
        error: meta.touched && !!meta.error,
      },
      InputLabelProps: {
        classes: {
          root: classes.inputLabel,
          focused: classes.inputLabelFocused,
          error: classes.inputLabelError,
          asterisk: classes.inputLabelAsterisk,
          shrink: classes.inputLabelShrink,
        },
      },
      required,
      disabled,
      // le format par défaut pour le mode mois est bon, pas besoin de le re-spécifier
      format: isYearMonth || isYear ? undefined : dateFormat,
      invalidDateMessage: invalidDateMessage || messages.invalidDateMessage,
      minDateMessage: !(meta.touched && !!meta.error)
        ? minDateMessage || messages.minDateMessage
        : '',
      maxDateMessage: !(meta.touched && !!meta.error)
        ? maxDateMessage || messages.maxDateMessage
        : '',
      error: meta.touched && !!meta.error,
    };

    const keyboardProps = {
      KeyboardButtonProps: {
        classes: {
          root: classes.iconButton,
        },
      },
      keyboardIcon: (
        <span className={classes.iconCalendar}>
          <CalendarIcon />
        </span>
      ),
    };
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={materialTheme}>
          <FormControl className={classes.root}>
            {isYear ? (
              <DatePicker {...commonProps} views={['year']} />
            ) : isYearMonth ? (
              <DatePicker {...commonProps} views={['month', 'year']} />
            ) : (
              <KeyboardDatePicker {...commonProps} {...keyboardProps} />
            )}
            {(meta.touched || meta.active || meta.dirty) && meta.error && (
              <Typography color="error" variant="caption">
                {meta.error}
              </Typography>
            )}
          </FormControl>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(InputCalendrier);
