import { Theme } from '@material-ui/core/styles';
import { gris1 } from '../../../../theme';

const styles = (theme: Theme) =>
  ({
    container: {
      position: 'relative',
      zIndex: 10,
      maxWidth: '500px',
      margin: 'auto',
      boxShadow: '0px 4px 12px rgba(0,0,0,0.2)',
      borderRadius: 4,
      background: 'white',
      padding: '24px',
      '& > div': {
        display: 'flex',
        alignItems: 'flex-end',
      },
      '& h2': {
        fontSize: 24,
        margin: 0,
        padding: 0,
      },
      '& p': {
        color: '#AAA',
      },
    },
    inputForm: {
      '& > div:first-of-type': {
        marginRight: 4,
      },
      '& > div:last-of-type': {
        marginLeft: 4,
      },
    },
    formContact: {
      maxWidth: 500,
      '& > div': {
        marginBottom: 16,
      },
      '& .MuiInputBase-root': {
        background: 'white',
      },
    },
    subtitle: {
      '& > h2': {
        fontSize: 18,
        padding: 0,
        color: gris1,
      },
    },
    '@media all and (max-width: 650px)': {
      captcha: {
        maxWidth: 500,
      },
    },
  } as any);

export default styles;
