import { push } from 'connected-react-router';
import { stopSubmit } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';
import Types from 'Types';
import { ActionType } from 'typesafe-actions';
import {
  copyDossierParticulierToSituationPersonnelleType,
  copySituationPersonnelleTypeToSituationPersonnelle as copyDossierParticulier,
} from '../../containers/DossierLocation/SituationPersonnellePage/helpers';
import { wrapError } from '../../helpers/utils';
import { Selectors as dossierLocationSelectors } from '../../redux/DossierLocation';
import { ActionCreators } from '../../redux/SituationPersonnelle';
import { IApi } from '../../services/Api';
import {
  DossierParticulier,
  SituationPersonnelleDossierParticulier,
} from '../../services/Api/swagger';

export function* lancerCreationOuModificationSituationPersonnelle(
  api: IApi,
  action: ActionType<
    typeof ActionCreators.createOrUpdateSituationPersonnelleAsync.request
  >,
) {
  const { situationPrincipale, situationConjoint } = action.payload;
  const dossierLocationRef = yield select(
    dossierLocationSelectors.getSelectedDossier,
  );
  try {
    yield* lancerCreationModificationUnitaire(
      api,
      situationPrincipale,
      dossierLocationRef,
      'principal',
    );
    if (situationConjoint) {
      yield* lancerCreationModificationUnitaire(
        api,
        situationConjoint,
        dossierLocationRef,
        'secondaire',
      );
    }

    yield put(ActionCreators.createOrUpdateSituationPersonnelleAsync.success());
    yield put(push('/dossier-location/situation-professionnelle'));
  } catch (error) {
    yield put(stopSubmit('situationPersonnelleForm', error.content));
    yield put(
      ActionCreators.createOrUpdateSituationPersonnelleAsync.failure(error),
    );
  }
}

export function* lancerCreationModificationUnitaire(
  api: IApi,
  situationPersonnelle: Types.SituationPersonnelleDossierParticulierType,
  dossierLocationRef: string,
  prefixe: Types.PrefixeCompte,
) {
  try {
    if (situationPersonnelle.reference) {
      const dossierParticulier: SituationPersonnelleDossierParticulier = copyDossierParticulier(
        situationPersonnelle,
      );

      yield call(api.putSituationPersonnelle, {
        dossierLocationRef,
        dossierPersonneRef: situationPersonnelle.reference,
        situationPersonnelle: dossierParticulier,
      });
      yield put(
        ActionCreators.updateSituationPersonnelleAsync.success({
          situation: situationPersonnelle,
          prefixe,
        }),
      );
    } else {
      const newSituationPersonnelle: DossierParticulier = yield call(
        api.postSituationPersonnelle,
        {
          dossierLocationRef,
          situationPersonnelle,
        },
      );

      const situationPersonnelleType: Types.SituationPersonnelleDossierParticulierType = copyDossierParticulierToSituationPersonnelleType(
        newSituationPersonnelle,
      );
      yield put(
        ActionCreators.addSituationPersonnelleAsync.success({
          situation: situationPersonnelleType,
          prefixe,
        }),
      );
    }
  } catch (error) {
    const wrappedError = wrapError(error, prefixe);
    throw wrappedError;
  }
}
