import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import Titre from '../../components/titre/Titre';
import Layout from '../../components/Layout';
import styles from '../../components/Layout/styles';
import Libelle from '../../_i18n/fr.json';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
}

class ConfigurationPage extends React.Component<Props> {
  render() {
    return (
      <React.Fragment>
        <Layout title={Libelle.pageConfiguration.titre}>
          <Grid container justify="center">
            <Titre libelle={Libelle.pageConfiguration.h1} variant="h1" />
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Variables d'environnements</TableCell>
                <TableCell align="right">Valeurs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="REACT_APP_NAME">
                <TableCell component="th" scope="row">
                  REACT_APP_NAME
                </TableCell>
                <TableCell align="right">
                  {process.env.REACT_APP_NAME}
                </TableCell>
              </TableRow>
              <TableRow key="REACT_APP_WEBSITE_NAME">
                <TableCell component="th" scope="row">
                  REACT_APP_WEBSITE_NAME
                </TableCell>
                <TableCell align="right">
                  {process.env.REACT_APP_WEBSITE_NAME}
                </TableCell>
              </TableRow>
              <TableRow key="REACT_APP_VERSION">
                <TableCell component="th" scope="row">
                  REACT_APP_VERSION
                </TableCell>
                <TableCell align="right">
                  {process.env.REACT_APP_VERSION}
                </TableCell>
              </TableRow>
              <TableRow key="REACT_APP_AGREMENT_API_ENDPOINT">
                <TableCell component="th" scope="row">
                  REACT_APP_AGREMENT_API_ENDPOINT
                </TableCell>
                <TableCell align="right">
                  {process.env.REACT_APP_AGREMENT_API_ENDPOINT}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ConfigurationPage);
