import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { persistReducer } from 'redux-persist';
import ReduxPersistConfig from '../config/ReduxPersistConfig';
import { history } from '../helpers/history';
import { reducer as adbReducer } from './AdministrateurDeBien';
import { reducer as bienReducer } from './Bien';
import { reducer as dossierLocationReducer } from './DossierLocation';
import { reducer as localisationReducer } from './Localisation';
import { reducer as nousContacterReducer } from './NousContacterRedux';
import { reducer as partenaireReducer } from './Partenaire';
import { reducer as piecesReducer } from './Pieces';
import { reducer as situationPersonnelleReducer } from './SituationPersonnelle';
import { reducer as situationProfessionnelleReducer } from './SituationProfessionnelle';
import { reducer as typeVoieReducer } from './TypeVoie';
import { reducer as utilisateursReducer } from './Utilisateurs';

// Création du root reducer

const rootReducer = combineReducers({
  adb: adbReducer,
  utilisateurs: utilisateursReducer,
  router: connectRouter(history),
  dossierLocation: dossierLocationReducer,
  bien: bienReducer,
  situationPersonnelle: situationPersonnelleReducer,
  localisation: localisationReducer,
  situationProfessionnelle: situationProfessionnelleReducer,
  pieces: piecesReducer,
  typeVoie: typeVoieReducer,
  form: formReducer,
  contact: nousContacterReducer,
  partenaire: partenaireReducer,
});

// Activation de Redux Persist

let finalReducers = rootReducer;
if (ReduxPersistConfig.active) {
  finalReducers = persistReducer(ReduxPersistConfig.storeConfig, rootReducer);
}
export default finalReducers;
