import { ActionType, createAsyncAction, getType } from 'typesafe-actions';
import { Commune } from '../../services/Api/swagger';

export interface LocalisationState {
  data?: Commune[];
  error?: any;
  fetching: boolean;
}

export const initialState: LocalisationState = {
  data: [],
  error: undefined,
  fetching: false,
};

export const loadLocalisationsAsync = createAsyncAction(
  '@@App/localisation/LOAD_REQUEST',
  '@@App/localisation/LOAD_SUCCESS',
  '@@App/localisation/LOAD_FAILURE',
)<Commune, Commune[], any>();

export const ActionCreators = {
  loadLocalisationsAsync,
};

const getLocalisations = (state): Commune[] | undefined =>
  state.localisation.data &&
  state.localisation.data.map(option => ({
    value: option.codePostal + '-' + option.nom,
    label: option.nom + ' (' + option.codePostal + ')',
  }));

const getLocalisationsError = (state): any | undefined =>
  state.localisation.error;

const getLocalisationsFetching = (state): boolean =>
  state.localisation.fetching;

export const Selectors = {
  getLocalisations,
  getLocalisationsError,
  getLocalisationsFetching,
};

export const reducer = (
  state: LocalisationState = initialState,
  action: ActionType<typeof ActionCreators>,
): LocalisationState => {
  switch (action.type) {
    case getType(loadLocalisationsAsync.request):
      return {
        ...state,
        fetching: true,
        error: undefined,
      };
    case getType(loadLocalisationsAsync.success):
      return {
        data: action.payload,
        fetching: false,
        error: undefined,
      };
    case getType(loadLocalisationsAsync.failure):
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
  }
  return state;
};
