import React from 'react';

export const ProfileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="22"
    viewBox="0 0 13 22"
  >
    <defs>
      <path id="a" d="M0 0h12.91v20.451H0z" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(0 0)">
      <path
        fill="#808080"
        d="M10.91 18.451H2v-.747a4.46 4.46 0 0 1 4.455-4.455 4.46 4.46 0 0 1 4.455 4.455v.747zM2.329 6.126A4.13 4.13 0 0 1 6.455 2a4.13 4.13 0 0 1 4.126 4.126 4.13 4.13 0 0 1-4.126 4.126 4.131 4.131 0 0 1-4.126-4.126zm6.579 5.61a6.131 6.131 0 0 0 3.673-5.61A6.133 6.133 0 0 0 6.455 0 6.133 6.133 0 0 0 .329 6.126a6.132 6.132 0 0 0 3.674 5.61A6.463 6.463 0 0 0 0 17.704v1.747a1 1 0 0 0 1 1h10.91a1 1 0 0 0 1-1v-1.747a6.462 6.462 0 0 0-4.002-5.968z"
        mask="url(#b)"
      />
    </g>
  </svg>
);
