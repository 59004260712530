import React from 'react';

// tslint:disable: max-line-length
export default class SyntheseSVG extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="83"
        height="83"
        viewBox="0 0 67 67"
      >
        <defs>
          <path id="a" d="M.914.61h32.192v40.62H.914z" />
          <path id="c" d="M0 .078h29.851v37.98H0z" />
          <path id="e" d="M0 43.215h36.199V1.03H0z" />
        </defs>
        <g fillRule="evenodd" id="Mask" fill="rgba(92, 81, 117, 0.25)">
          <circle cx="33.5" cy="33.5" r="33.5" />
          <g transform="translate(15.462 12.37)">
            <g transform="translate(3.092 1.984)">
              <mask id="b" fill="#fff">
                <use />
              </mask>
              <path
                fill="#000"
                d="M33.079 9.378a.913.913 0 0 0-.665-1.105l-3.357-.836.293-5.245A1.437 1.437 0 0 0 27.995.678L26.76.61v9.266a3.098 3.098 0 0 1 2.168 1.342 3.117 3.117 0 0 1-.859 4.32 3.059 3.059 0 0 1-1.309.483V35.977c0 .581-.473 1.053-1.054 1.053H.914a1.408 1.408 0 0 0 1.392 1.25H15.36l11.619 2.894a1.887 1.887 0 0 0 2.287-1.374l3.388-13.608a1.887 1.887 0 0 0-1.375-2.286l-.827-.205a.913.913 0 0 1-.664-1.106l3.291-13.217z"
              />
            </g>
            <path
              fill="#FFF"
              d="M19.298 33.132a2.155 2.155 0 1 1 .001-4.31 2.155 2.155 0 0 1 0 4.31m0-5.952a3.798 3.798 0 1 0 0 7.595 3.798 3.798 0 0 0 0-7.595"
            />
            <g transform="translate(0 .953)" fill="#FFF">
              <mask id="d" fill="#fff">
                <use />
              </mask>
              <path
                fill="#FFF"
                d="M19.298 35.034a5.009 5.009 0 1 1 0-10.017 5.009 5.009 0 0 1 0 10.017zM4.646 15.944h6.185v-1.271H4.646v1.272zm0-3.786h15.462v-1.272H4.646v1.272zm0-3.45H16.1V7.436H4.646v1.272zm25.205 8.344c-.221.03-.443.044-.664.027a.622.622 0 0 1-1.132.441l-.227-.338-4.33 2.893 1.878 2.808-1.85 1.235-.659.441-5.723-8.56.66-.44 1.85-1.237 1.875 2.807 4.33-2.895-.226-.338a.62.62 0 0 1 .84-.876 3.099 3.099 0 0 1 1.226-1.63 3.088 3.088 0 0 1 2.152-.484V1.132c0-.583-.472-1.054-1.053-1.054H1.054C.47.078-.001.55-.001 1.132v35.871c0 .583.472 1.055 1.055 1.055h27.744c.58 0 1.051-.47 1.052-1.052l.001-.003V17.052z"
              />
            </g>
            <mask id="f" fill="#fff">
              <use />
            </mask>
            <path
              fill="#000"
              d="M4.647 9.66H16.1V8.388H4.647zM4.647 13.111h15.461v-1.272H4.647zM4.647 16.898h6.184v-1.272H4.647z"
            />
          </g>
        </g>
      </svg>
    );
  }
}
// tslint:enable: max-line-length
