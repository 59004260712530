import { Grid, Link, Typography } from '@material-ui/core';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import FormContainer from '../../../components/FormContainer';
import Layout from '../../../components/Layout';
import LoadingBoundary from '../../../components/LoadingBoundary';
import {
  ActionCreators as DossierLocationActionCreators,
  Selectors as DossierLocationSelectors,
} from '../../../redux/DossierLocation';
import { RapportDossierLocation } from '../../../services/Api/swagger';
import Libelle from '../../../_i18n/fr.json';
import styles from '../SyntheseDossierLocationPage/Synthese/styles';
import CalculLocataires from './CalculLocataires';

interface Props {
  classes?: any;
  getRapportAlgo(ref): void;
  selectedDossier: string;
  rapportAlgo: RapportDossierLocation;
  error?: any;
  fetching: boolean;
}

class CalculLoyerPage extends React.Component<Props> {
  componentDidMount() {
    this.loadInitData();
  }

  loadInitData = () => {
    if (this.props.selectedDossier) {
      this.props.getRapportAlgo(this.props.selectedDossier);
    }
  };

  render() {
    const {
      selectedDossier,
      classes,
      rapportAlgo,
      error,
      fetching,
    } = this.props;

    return (
      <Layout title={Libelle.pageBien.titre}>
        <div>
          {' '}
          <Link
            href="/dossier-location/synthese"
            color="inherit"
            className="backLink"
          >
            <ArrowLeft /> Retour à la synthèse
          </Link>
          <Typography variant="h4" className={classes.statut}>
            Détail du calcul du dossier {selectedDossier}
          </Typography>
          <LoadingBoundary
            loading={fetching}
            error={error}
            onRetry={this.loadInitData}
          >
            <FormContainer>
              <Grid container className={classes.synthese}>
                {rapportAlgo && (
                  <div>
                    <h3>Calcul du loyer max conseillé</h3>
                    <p>
                      Revenus mensuels du dossier :
                      <strong>{rapportAlgo.revenusMensuelsDossier}€</strong>
                    </p>
                    <p>
                      CAF mensuelles du dossier :
                      <strong>{rapportAlgo.aidesSocialesMensuelles}€</strong>
                    </p>
                    <p>
                      Ressources mensuelles du dossier :
                      <strong>
                        {rapportAlgo.ressourcesMensuellesDossier}€
                      </strong>
                    </p>
                    <p>
                      Charges mensuelles du dossier :
                      <strong>{rapportAlgo.chargesMensuellesDossier}€</strong>
                    </p>
                    <h5>
                      Loyer max conseillé :{' '}
                      <strong>
                        {rapportAlgo.calculLoyerMaxConseille &&
                          rapportAlgo.calculLoyerMaxConseille.loyerMaxConseille}
                        €
                      </strong>
                    </h5>
                    <br />
                    <h3>Calcul des charges du dossier</h3>
                    {rapportAlgo.calculLoyerMaxConseille && (
                      <>
                        <p>
                          Code postal du bien :{' '}
                          <strong>
                            {rapportAlgo.bien && rapportAlgo.bien.codePostal}
                          </strong>
                        </p>
                        <p>
                          Charge UC :{' '}
                          <strong>
                            {rapportAlgo.calculLoyerMaxConseille.chargeUc}€
                          </strong>
                        </p>
                        <p>
                          Nombre UC :{' '}
                          <strong>
                            {rapportAlgo.calculLoyerMaxConseille.nombreUc}
                          </strong>
                        </p>
                        <p>
                          Nombre UC Adultes :{' '}
                          <strong>
                            {
                              rapportAlgo.calculLoyerMaxConseille
                                .nombreUcAdultes
                            }
                          </strong>
                        </p>
                        <p>
                          Nombre UC Enfants de 14 ans et moins :{' '}
                          <strong>
                            {
                              rapportAlgo.calculLoyerMaxConseille
                                .nombreUcEnfants14AnsEtMoins
                            }
                          </strong>
                        </p>
                        <p>
                          Nombre UC Enfants de plus de 14 ans :{' '}
                          <strong>
                            {
                              rapportAlgo.calculLoyerMaxConseille
                                .nombreUcEnfantsPlusDe14Ans
                            }
                          </strong>
                        </p>
                      </>
                    )}
                  </div>
                )}

                <CalculLocataires
                  data={
                    rapportAlgo && rapportAlgo.dossiersParticuliers
                      ? rapportAlgo.dossiersParticuliers
                      : []
                  }
                  typeDossier={
                    (rapportAlgo && rapportAlgo.compositionLocation) || ''
                  }
                />
              </Grid>
            </FormContainer>
          </LoadingBoundary>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  selectedDossier: DossierLocationSelectors.getSelectedDossier(state),
  rapportAlgo: DossierLocationSelectors.getAlgo(state),
  error: DossierLocationSelectors.getAlgoError(state),
  fetching: DossierLocationSelectors.getAlgoFetching(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getRapportAlgo: (ref: string) =>
    dispatch(DossierLocationActionCreators.getRapportAlgo.request(ref)),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CalculLoyerPage),
);
