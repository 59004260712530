import moment from 'moment';
import 'moment/locale/fr';

/**
 * Formatte une date sous la forme "22 Mai 2000"
 *
 * @param date : Date d'entrée a formatter
 */
export const formatDateLong = (date: any): string =>
  moment(date).format('D MMMM YYYY');

/**
 * Formatte une date sous la forme "22/05/2000"
 *
 * @param date : Date d'entrée a formatter
 */
export const formatDateCourt = (date: any): string =>
  moment(date).format('DD/MM/YYYY');

export const formatDateToJanuary = (date: any): string =>
  moment(date).format('YYYY-01-01');

export const showMonth = (date: any): string =>
  moment(date).format('MMMM YYYY');

export const formatterEspaces = (
  numeroTelephone: string | undefined,
): string | undefined =>
  numeroTelephone ? numeroTelephone.replace(/\s/g, '') : numeroTelephone;

export const maskNumTelephone = () => {
  return [
    /[0]/,
    /[1-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
  ];
};

export const maskNumSiret = () => {
  return [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ' ',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];
};
