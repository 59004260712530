import { Theme } from '@material-ui/core/styles';
import { erreur } from '../../../../theme';

const styles = (theme: Theme) =>
  ({
    container: {
      padding: '27px 12px 0 12px',
      margin: 'auto',
      maxWidth: 880,
      display: 'flex',
    },
    maintenanceTexte: {
      textAlign: 'center',
      margin: 'auto',
      border: '2px solid ' + erreur,
      padding: '0 16px 4px 16px',
      '& h2': {
        marginBottom: 0,
      },
      '& h3': {
        textTransform: 'initial',
      },
    },
    divider: {
      height: 2,
      background: theme.palette.primary.main,
      width: 100,
      margin: '12px auto 0 auto',
    },
    '@media all and (max-width: 789px)': {
      container: {
        display: 'block',
      },
    },
  } as any);

export default styles;
