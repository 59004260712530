import { withStyles } from '@material-ui/core';
import React from 'react';
import { isAuthenticated } from '../../services/Authentification/authentification';
import HeaderAuthenticated from './HeaderAuthenticated';
import HeaderStatique from './HeaderStatique';
import styles from './styles';

//

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  cookiesValid?: boolean;
}

interface State {
  // Etat d'ouverture du menu
  menuOpen: boolean;
  // Gestion du menu
  anchorEl?: null | HTMLButtonElement;
  // taille de l'ecran
  width: number;

  left: boolean;
}

class IHeader extends React.Component<Props, State> {

  render() {
    return isAuthenticated() ? (
      <HeaderAuthenticated />
    ) : (
      <HeaderStatique cookiesValid={this.props.cookiesValid} />
    );
  }
}

export default withStyles(styles)(IHeader);
