import { withStyles } from '@material-ui/core';
import React from 'react';
import fonction1 from '../../../../ressources/images/fonction_1_big.png';
import fonction2 from '../../../../ressources/images/fonction_2_big.png';
import fonction3 from '../../../../ressources/images/fonction_3_big.png';
import fonction4 from '../../../../ressources/images/fonction_4_big.png';
import defaultStyles from '../styles';
import styles from './styles';

interface IProps {
  classes?: any;
}

const BlocFonctions = (props: IProps) => (
  <div className={props.classes.container} id="fonctions">
    <div className={props.classes.contentFonctions}>
      <img src={fonction1} height={70} alt="Fonctions Vertuloo" />
      <div>
        <h2>Contrôle automatisé de l'authenticité et de la conformité</h2>
        <h3>des pièces du dossier de candidature de locataire.</h3>
      </div>
    </div>
    <div className={props.classes.contentTexte}>
      <p>
        La plateforme <span className="vert">vertuloo</span> a été{' '}
        <strong>
          développée avec les solutions technologiques les plus avancées.
          <br />
        </strong>{' '}
        Elle offre ainsi, <strong>en temps réel</strong>, le{' '}
        <strong>contrôle</strong>, la{' '}
        <strong>vérification de conformité</strong> et d’
        <strong>authenticité</strong> de chacune des pièces du dossier (bulletin
        de salaire, avis d’imposition, carte d'identité, etc.),
      </p>
      <br />
      <p>La nouvelle plateforme digitale permet, en temps réel, de&nbsp;:</p>
      <ul>
        <li>
          Réaliser le <strong>contrôle complet </strong>d’un dossier type en 60
          secondes (moyenne),
        </li>
        <li>
          Sécuriser la conformité du dossier sur{' '}
          <strong>114 points de contrôles</strong>,
        </li>
        <li>
          {' '}
          <strong>Détecter les documents falsifiés</strong>,
        </li>
        <li>
          Bénéficier des{' '}
          <strong>actualisations de la plateforme et de ses bases</strong>.
        </li>
      </ul>
    </div>
    <div className={props.classes.divider} />
    {/* Analyse*/}
    <div className={props.classes.contentFonctions}>
      <img src={fonction2} height={70} alt="Fonctions Vertuloo" />
      <div>
        <h2>Analyse pragmatique et sécurisée de la solvabilité</h2>
        <h3> des candidats</h3>
      </div>
    </div>

    <div className={props.classes.contentTexte}>
      <p>
        <strong>
          Le modèle d’analyse de <span className="vert">vertuloo</span> dépasse
          les traditionnelles logiques d’analyse
        </strong>{' '}
        de solvabilité qui consiste à accepter uniquement des candidats
        locataires dont les revenus représentent 3 fois le loyer.
        <br />
        L’observation des données et composants issus du dossier du candidat est{' '}
        <strong>
          complétée par l’intégration des données de multiples bases à la fois
          macroéconomiques et sociologiques.
        </strong>
      </p>
      <br />
      <p>
        Cette nouvelle approche ouverte dans l’analyse des dossiers permet de :
      </p>
      <ul>
        <li>
          Déterminer avec plus de précision{' '}
          <strong>la capacité locative</strong> de chaque candidat (loyer max
          conseillé)
        </li>
        <li>
          <strong>Faciliter l’orientation du candidat</strong> vers la location
          qui lui correspond et préserve son reste à vivre
        </li>
        <li>
          <strong>Favoriser l'accès</strong> au logement pour plus de candidat
          locataire et réduire ainsi les vacances locatives
        </li>
        <li>
          <strong>Ouvrir l’offre GLI a un spectre plus large</strong> de profils
          de candidats locataires (CDD, intérimaires, CDI période d’essai….)
        </li>
      </ul>
    </div>
    <div className={props.classes.divider} />
    {/* Outil d'aide*/}
    <div className={props.classes.contentFonctions}>
      <img src={fonction3} height={70} alt="Fonctions Vertuloo" />
      <div>
        <h2> Outil d'aide pour éclairer la prise de décisions</h2>
        <h3>de l'administrateur de biens</h3>
      </div>
    </div>
    <div className={props.classes.contentTexte}>
      <p>
        La plateforme vertuloo restitue un indicateur de la capacité locative du
        candidat locataire : <strong>Le loyer Max Conseillé</strong>.<br /> Il
        est le résultat du{' '}
        <strong>modèle d’analyse de solvabilité développé</strong>{' '}
        spécifiquement par nos équipes actuarielles.{' '}
      </p>
      <br />
      <p>
        Son modèle obéit au principe de préservation du reste à vivre de chaque
        candidat locataire
        <br />
        <strong>
          Vertuloo est un outil d'aide à la décision de l'administrateur de
          biens
        </strong>{' '}
        pour retenir le locataire le mieux adapté pour un bien donné, venant{' '}
        <strong>renforcer sa prestation</strong> vis vis de son mandant.
      </p>
    </div>
    <div className={props.classes.divider} />
    {/* Certification*/}
    <div className={props.classes.contentFonctions}>
      <img src={fonction4} height={70} alt="Fonctions Vertuloo" />
      <div>
        <h2>
          Certification assurance qui garantit 100% des dossiers acceptés{' '}
        </h2>
        <h3>contre le risque des loyers impayés</h3>
      </div>
    </div>

    <div className={props.classes.contentTexte}>
      <p>
        Pour les lots disposant d’une garantie contre les loyers impayés, la
        plateforme délivre une certification Assurance instantanée qui atteste
        de{' '}
        <strong>
          la bonne application du contrat d’assurance contre les loyers impayés
          pour 100% des dossiers acceptés.
        </strong>{' '}
        <br />
        <br />
        Cette certification Assurance engage l’assureur d’un point de vue
        contractuel puisqu’intégrée dans les conditions particulières et
        générales de votre contrat d’assurance.
        <br />
        <br />
        Avec Vertuloo,{' '}
        <strong>le risque de refus de garantie est supprimé</strong>, votre
        contrat RC Professionnelle et l’activité gestion locative sont protégés.
      </p>
    </div>
  </div>
);

export default withStyles(theme => ({
  ...styles(theme),
  ...defaultStyles(theme),
}))(BlocFonctions);
