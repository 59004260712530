import { Theme } from '@material-ui/core/styles';
import { gris3, gris4 } from '../../theme';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
  ({
    layout: {
      margin: 'auto',
      minHeight: 'calc(100vh - 11.625rem)',
      '& > div, & > form': {
        paddingLeft: 68,
        paddingRight: 68,
      },
      '& > div': {
        marginTop: 58,
      },
      '& .bigTitle': {
        marginTop: 0,
        background: theme.palette.primary.main,
        padding: '158px 12px 32px 12px',
        color: '#FFF',
        fontWeight: 'bold',
      },
      '&.full': {
        maxWidth: '100%',
        padding: 0,
        '& > div, & > form': {
          paddingLeft: 12,
          paddingRight: 12,
          marginTop: 'inherit',
        },
      },
      '& blockquote': {
        margin: 0,
        marginTop: '0.75rem',
        fontStyle: 'italic',
      },
      '& ul': {
        margin: 0,
        padding: 0,
      },
      '& li': {
        listStyle: 'none',
      },
      '& .fondGris': {
        backgroundColor: gris4,
      },
      '& .fondGrisFonce': {
        backgroundColor: gris3,
      },
      '& .inputForm': {
        margin: 10,
      },
      '& .acc-hidden-title': {
        position: 'absolute',
        left: -9999,
      },
      '& .textUppercase': {
        textTransform: 'uppercase',
      },
      '& .textAlignCenter': {
        textAlign: 'center',
      },
      '& .noMargin': {
        margin: 0,
      },
      '& .marginTop24': {
        marginTop: 24,
      },
      '& h3': {
        textTransform: 'uppercase',
        margin: '0 0 12px 0',
        fontSize: 15,
      },
      '& .boxShadow': {
        boxShadow:
          ' 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
      },
      '& .defaultLink': {
        marginLeft: 4,
        textDecoration: 'underline',
        marginBottom: 12,
      },
      '& button': {
        fontFamily: 'Montserrat, sans-serif',
      },
      '& .capitalize': {
        textTransform: 'capitalize',
      },
      '& .titlePage': {
        margin: 0,
        marginBottom: 12,
        textTransform: 'uppercase',
        paddingLeft: 68,
        paddingRight: 68,
      },
      '& .backLink': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
      },
      '& .cancelLink': {
        color: '#000',
        marginTop: 16,
      },
    },
  } as any);
// tslint:enable: quotemark

export default styles;
