import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import styles from './styles';
import { WrappedFieldInputProps } from 'redux-form';
import RadioItem from './RadioItem';
import IconRadio from './IconRadio';

interface Props {
  id: string;
  name: string;
  input?: WrappedFieldInputProps;
  meta?: any;
  label: string;
  items: RadioItem[];
  classes?: any;
  required?: boolean;
}

interface State {
  value?: string;
}
class InputRadio extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let value =
      (props.input && props.input.value) || (props.meta && props.meta.initial);
    if (!value && props.items && props.items.length > 0) {
      value = props.items[0].value;
    }
    this.state = {
      value,
    };
    if (this.props.input) {
      this.props.input.onChange(value);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let value =
      (this.props.input && this.props.input.value) ||
      (this.props.meta && this.props.meta.initial);
    if (!value && prevProps.items && prevProps.items.length > 0) {
      value = prevProps.items[0].value;
    }
    if (prevState.value !== value) {
      this.setState({
        value,
      });
      if (this.props.input) {
        this.props.input.onChange(value);
      }
    }
  }

  handleChange = e => {
    e.preventDefault();
    this.setState({ value: e.target.value });
    if (this.props.input) {
      this.props.input.onChange(e.target.value);
    }
  };

  render() {
    const { classes, name, label, items, required } = this.props;
    const { value } = this.state;
    return (
      <FormControl className={classes.root}>
        <FormLabel className={classes.formLabel}>{label} {required ? '*' : ''}</FormLabel>
        <RadioGroup
          className={classes.radioGroup}
          name={name}
          onChange={this.handleChange}
          value={value}
          row
        >
          {items.map((item, index) => (
            <FormControlLabel
              key={item.value}
              label={item.label}
              value={item.value}
              control={
                <Radio
                  icon={<IconRadio />}
                  checkedIcon={<IconRadio checked />}
                />
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
}

export default withStyles(styles)(InputRadio);
