import { Grid, withStyles } from '@material-ui/core';
import memoizeOne from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  arrayInsert,
  arrayRemove,
  Field,
  FieldArray,
  getFormValues,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import AutoCompleteSelect from '../../../components/AutoComplete';
import FormButtons from '../../../components/FormButtons';
import FormContainer from '../../../components/FormContainer';
import InputCounter from '../../../components/input/InputCounter';
import InputField from '../../../components/input/InputField';
import InputCalendrier from '../../../components/inputCalendrier/InputCalendrier';
import Select from '../../../components/select/Select';
import { maskNumTelephone } from '../../../helpers/formatters';
import { history } from '../../../helpers/history';
import { getTitleCandidat } from '../../../helpers/utils';
import {
  isAgeLocataireCorrect,
  isAlphaNumeric,
  isEmail,
  isTelephone,
  maxEnfantsAge,
  maxLocataireAge,
  minEnfantsAge,
  minLocataireAge,
} from '../../../helpers/validators';
import { Selectors as DossierLocationSelectors } from '../../../redux/DossierLocation';
import {
  Commune,
  DossierLocation,
  TypeVoie,
} from '../../../services/Api/swagger';
import ErreursConstante from '../../../_constantes/erreursConstantes.json';
import Libelle from '../../../_i18n/fr.json';
import {
  copySituationPersonnelleModelToForm,
  ISituationPersonnelleError,
  ISituationPersonnelleFormData,
} from './helpers';
import ConjointForm from './situationPersonnelleConjointForm';
import styles from './style';

interface Props {
  formValues: ISituationPersonnelleFormData;
  pushArray(field: string, index: number, value: any): void;
  removeArray(field: string, index: number): void;
  listTypeVoie: TypeVoie[];
  dossierLocation: DossierLocation;
  statutOccupation: [];
  listSituations: [];
  qualites: [];
  formErrors?: any;
  isFetching: boolean;
  classes?: any;
  onChangeCommune?(commune: Commune): void;
  communes: Commune[];
  selectedDossier?: string;
  selectedPerson?: string;
}

const construireListTypeVoieOptions = (listTypeVoie: TypeVoie[]) => {
  return listTypeVoie
    ? listTypeVoie.map(s => ({ key: s.code, text: s.libelle, value: s.code }))
    : [];
};

const renderEnfants = ({ fields, meta }) =>
  fields.map((item, index) => {
    return (
      <Grid item key={index}>
        <Field
          name={`${item}.dateNaissance`}
          component={InputCalendrier}
          label={'Enfant n°' + (index + 1) + ' né(e) le'}
          variante="inline"
          varianteInput="filled"
          minDate={maxEnfantsAge}
          maxDate={minEnfantsAge}
          required
          isYear
        />
      </Grid>
    );
  });

const memoizeConstruireListTypeVoieOptions = memoizeOne(
  construireListTypeVoieOptions,
);

class SituationPersonnelleForm extends React.Component<
  InjectedFormProps<ISituationPersonnelleFormData> & Props
> {
  handlePrecedent() {
    history.push('/dossier-location/bien');
  }

  increment = () => {
    const { pushArray } = this.props;
    if (this.props.formValues && this.props.formValues.principal.enfants) {
      const enfants = this.props.formValues.principal.enfants;
      pushArray('principal.enfants', enfants.length, { dateNaissance: '' });
    } else if (this.props.formValues) {
      pushArray('principal.enfants', 0, { dateNaissance: '' });
    }
  };

  decrement = () => {
    const { removeArray } = this.props;
    if (this.props.formValues && this.props.formValues.principal.enfants) {
      const enfants = this.props.formValues.principal.enfants;
      removeArray('principal.enfants', enfants.length - 1);
    } else if (this.props.formValues) {
      removeArray('principal.enfants', 0);
    }
  };

  render() {
    const {
      listTypeVoie,
      statutOccupation,
      qualites,
      handleSubmit,
      dossierLocation,
      isFetching,
      selectedDossier,
      selectedPerson,
      formValues,
    } = this.props;
    const listVoies = memoizeConstruireListTypeVoieOptions(listTypeVoie);
    const nombreEnfans =
      formValues && formValues.principal.enfants
        ? formValues.principal.enfants.length
        : 0;

    let conjointForm;
    if (
      dossierLocation &&
      dossierLocation.compositionLocation === 'COUPLE' &&
      formValues &&
      formValues.principal.typeCompte !== 'GARANT'
    ) {
      conjointForm = (
        <ConjointForm classes={this.props.classes} qualites={qualites} />
      );
    }

    const titlePage =
      dossierLocation &&
      formValues &&
      getTitleCandidat(
        dossierLocation,
        formValues.principal.typeCompte,
        dossierLocation.dossiersParticuliers,
        formValues.principal.refDossierLocataire || undefined,
        selectedPerson || undefined,
        undefined,
      );

    const textSection1 =
      formValues && formValues.principal.typeCompte === 'GARANT'
        ? 'sectionGarant'
        : 'section1';

    return (
      <>
        <h5 className="titlePage">{titlePage}</h5>
        <form onSubmit={handleSubmit} noValidate>
          <h3>{Libelle.pageSituationPerso.sections[textSection1]}</h3>
          <FormContainer>
            <Grid container spacing={2}>
              <Grid item>
                <Field
                  name="principal.qualite"
                  component={Select}
                  options={qualites}
                  label="Qualité"
                  required
                />
              </Grid>
              <Grid item>
                <Field
                  name="principal.nom"
                  component={InputField}
                  label="Nom"
                  maxLength={29}
                  required
                />
              </Grid>
              <Grid item>
                <Field
                  name="principal.nomDeNaissance"
                  component={InputField}
                  label="Nom de naissance"
                  maxLength={29}
                  note="(si différent du nom d'usage)"
                />
              </Grid>
              <Grid item>
                <Field
                  name="principal.prenom"
                  component={InputField}
                  label="Prénom"
                  maxLength={29}
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <Field
                  name="principal.dateNaissance"
                  component={InputCalendrier}
                  label="Date de naissance"
                  required
                  variante="inline"
                  varianteInput="filled"
                  minDate={maxLocataireAge}
                  maxDate={minLocataireAge}
                />
              </Grid>
              <Grid item>
                <Field
                  name="principal.email"
                  component={InputField}
                  label="Mail"
                  required
                />
              </Grid>
              <Grid item>
                <Field
                  name="principal.telephoneFixe"
                  component={InputField}
                  label="Fixe"
                  mask={maskNumTelephone()}
                />
              </Grid>
              <Grid item>
                <Field
                  name="principal.telephonePortable"
                  component={InputField}
                  label="Mobile"
                  mask={maskNumTelephone()}
                  required
                />
              </Grid>
            </Grid>
          </FormContainer>

          <h3>{Libelle.pageSituationPerso.sections.section2}</h3>
          <FormContainer>
            <Grid container spacing={2}>
              <Grid item>
                <Field
                  name="principal.dateEntree"
                  component={InputCalendrier}
                  isYear={true}
                  type="date"
                  label="Date d'entrée"
                  placeholder="Date d'entrée"
                  variante="inline"
                  varianteInput="filled"
                  minDateMessage="Merci de vérifier la date d'entrée"
                  maxDateMessage="La date d'entrée doit être inférieure à la date du jour"
                  invalidDateMessage="Merci de vérifier la date d'entrée"
                />
              </Grid>
              <Grid item>
                <Field
                  name="principal.statutOccupation"
                  component={Select}
                  options={statutOccupation}
                  label="Statut"
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Field
                  name="principal.numero"
                  component={InputField}
                  label="Numéro"
                  required
                  type="text"
                  maxLength={10}
                />
              </Grid>

              <Grid item>
                <Field
                  name="principal.typeVoie"
                  component={Select}
                  options={listVoies}
                  label="Type de voie"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="principal.adresse"
                  component={InputField}
                  label="Nom de la voie"
                  maxLength={40}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <Field
                  name="principal.numeroBatiment"
                  component={InputField}
                  label="N° de bâtiment"
                  maxLength={4}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <Field
                  required
                  name="principal.codePostalVille"
                  component={AutoCompleteSelect}
                  onChangeSelection={this.props.onChangeCommune}
                  label={'Code postal / ville'}
                  options={this.props.communes || []}
                />
              </Grid>
            </Grid>
          </FormContainer>
          {conjointForm}
          <h3>{Libelle.pageSituationPerso.sections.section4}</h3>
          <FormContainer>
            <Grid container spacing={2}>
              <Grid item>
                <Field
                  name="principal.situationMaritale"
                  component={Select}
                  options={this.props.listSituations}
                  type="text"
                  label="Situation Maritale"
                  required
                />
              </Grid>

              <Grid item>
                <InputCounter
                  label="Nombre d’enfants à charge figurant sur l’avis d’imposition"
                  minValue={0}
                  maxValue={10}
                  onIncrement={this.increment}
                  onDecrement={this.decrement}
                  initialValue={nombreEnfans}
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <FieldArray name="principal.enfants" component={renderEnfants} />
            </Grid>
          </FormContainer>
          <FormButtons
            onPrecedent={this.handlePrecedent}
            fetching={isFetching}
            canCancel={selectedDossier}
          />
        </form>
      </>
    );
  }
}

/**
 * Méthode de validation du formulaire situation personnelle
 * @param values
 */
const validateForm = (
  values: ISituationPersonnelleFormData,
): ISituationPersonnelleError => {
  const errors: ISituationPersonnelleError = {} as ISituationPersonnelleError;
  errors.secondaire = {};
  errors.principal = {};

  if (values.principal && !values.principal.qualite) {
    errors.principal.qualite =
      ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (values.principal && !values.principal.nom) {
    errors.principal.nom =
      ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (values.principal && values.principal && !values.principal.prenom) {
    errors.principal.prenom =
      ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (values.principal && !values.principal.dateNaissance) {
    errors.principal.dateNaissance =
      ErreursConstante.formulaire.generale.champObligatoire;
  } else if (
    values.principal &&
    values.principal.dateNaissance &&
    !isAgeLocataireCorrect(values.principal.dateNaissance)
  ) {
    errors.principal.dateNaissance =
      ErreursConstante.formulaire.generale.verifierDateNaissance;
  }

  if (values.principal && !values.principal.email) {
    errors.principal.email =
      ErreursConstante.formulaire.generale.champObligatoire;
  } else if (
    values.principal &&
    values.principal.email &&
    !isEmail(values.principal.email)
  ) {
    errors.principal.email =
      ErreursConstante.formulaire.generale.emailIncorrect;
  }

  if (
    values.principal &&
    values.principal.telephoneFixe &&
    !isTelephone(values.principal.telephoneFixe)
  ) {
    errors.principal.telephoneFixe =
      ErreursConstante.formulaire.generale.formatTelephoneIncorrect;
  }

  if (values.principal && !values.principal.telephonePortable) {
    errors.principal.telephonePortable =
      ErreursConstante.formulaire.generale.champObligatoire;
  } else if (
    values.principal &&
    values.principal.telephonePortable &&
    !isTelephone(values.principal.telephonePortable)
  ) {
    errors.principal.telephonePortable =
      ErreursConstante.formulaire.generale.formatTelephoneIncorrect;
  }

  if (values.principal && !values.principal.statutOccupation) {
    errors.principal.statutOccupation =
      ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (values.principal && !values.principal.numero) {
    errors.principal.numero =
      ErreursConstante.formulaire.generale.champObligatoire;
  } else if (
    values.principal &&
    values.principal.numero &&
    !isAlphaNumeric(values.principal.numero)
  ) {
    errors.principal.numero =
      ErreursConstante.formulaire.generale.formatAlphaNumeric;
  }

  if (
    values.principal &&
    values.principal.numeroBatiment &&
    !isAlphaNumeric(values.principal.numeroBatiment)
  ) {
    errors.principal.numeroBatiment =
      ErreursConstante.formulaire.generale.formatAlphaNumeric;
  }

  if (values.principal && !values.principal.typeVoie) {
    errors.principal.typeVoie =
      ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (values.principal && !values.principal.adresse) {
    errors.principal.adresse =
      ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (values.principal && !values.principal.ville) {
    errors.principal.ville =
      ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (values.principal && !values.principal.codePostalVille) {
    errors.principal.codePostalVille =
      ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (values.principal && !values.principal.situationMaritale) {
    errors.principal.situationMaritale =
      ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (values.secondaire && !values.secondaire.nom) {
    errors.secondaire.nom =
      ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (values.secondaire && !values.secondaire.prenom) {
    errors.secondaire.prenom =
      ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (values.secondaire && !values.secondaire.dateNaissance) {
    errors.secondaire.dateNaissance =
      ErreursConstante.formulaire.generale.champObligatoire;
  } else if (
    values.secondaire &&
    values.secondaire.dateNaissance &&
    !isAgeLocataireCorrect(values.secondaire.dateNaissance)
  ) {
    errors.secondaire.dateNaissance =
      ErreursConstante.formulaire.generale.verifierDateNaissance;
  }

  if (values.secondaire && !values.secondaire.qualite) {
    errors.secondaire.qualite =
      ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (
    values.principal &&
    values.principal.enfants &&
    values.principal.enfants.length > 0
  ) {
    const enfantsErrors = [] as string[];
    values.principal.enfants.map((enfant, index) => {
      const enfantError = {} as any;
      if (!enfant.dateNaissance) {
        enfantError.dateNaissance =
          ErreursConstante.formulaire.generale.champObligatoire;
        enfantsErrors[index] = enfantError;
      } else {
        // if (!isAgeEnfantCorrect(enfant.dateNaissance)) {
        //   enfantError.dateNaissance =
        //     ErreursConstante.formulaire.generale.verifierDateNaissance;
        //   enfantsErrors[index] = enfantError;
        // }
      }
    });
    if (enfantsErrors.length) {
      errors.principal.enfants = enfantsErrors;
    }
  }
  return errors;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  pushArray: (field: string, index: number, value: any) =>
    dispatch(arrayInsert('situationPersonnelleForm', field, index, value)),
  removeArray: (field: string, index: number) =>
    dispatch(arrayRemove('situationPersonnelleForm', field, index)),
});

const mapStateToProps = (state, props) => {
  const modifSituationPersoData = DossierLocationSelectors.getSituationPersoData(
    state,
  );

  const situationPersoData =
    (state.situationPersonnelle.data &&
      state.situationPersonnelle.data.situationPrincipale.nom) ||
      !modifSituationPersoData
      ? copySituationPersonnelleModelToForm(state.situationPersonnelle.data)
      : modifSituationPersoData;
  return {
    formValues: getFormValues('situationPersonnelleForm')(state),
    initialValues: situationPersoData,
    // si selectedDossier existe (et non la référence de l'object data, ça signifie qu'on est en modification d'un dossier)
    selectedDossier: DossierLocationSelectors.getSelectedDossier(state),
    selectedPerson: DossierLocationSelectors.getSelectedPerson(state),
  };
};

const reduxConnection = reduxForm<ISituationPersonnelleFormData>({
  form: 'situationPersonnelleForm',
  validate: validateForm,
})(SituationPersonnelleForm);

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(reduxConnection),
);
