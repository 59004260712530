import { Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { getNetheosEndpoint } from '../../../../services/Config';
import styles from '../styles';

interface Props {
  classes?: any;
  token: string;
  isConjoint?: boolean;
}

interface State {
  heightIframe: number;
  heightIframeConjoint: number;
  message: string;
  state: string;
}

const MESSAGE_TEXTS = {
  SUSPENDED:
    "Une ou plusieurs des pièces que vous avez soumises sont en cours d'examen par nos opérateurs. Merci de revenir plus tard.",
  FINALIZED:
    'Vos pièces ont été analysées, nous vous invitons à consulter le résultat sur votre page de synthèse',
  WAITING: "Vos pièces justificatives sont en cours d'examen.",
  ACCEPTED:
    'Vos pièces justificatives ont été validées. <br /> Vous pouvez les consulter sur la synthèse de votre dossier.',
  REJECTED: 'Vos pièces justificatives ont été refusées.',
};

const ERROR_TEXTS = {
  BROWSER_NOT_SUPPORTED:
    "Désolé, ce navigateur n'est pas supporté, veuillez utiliser Chrome, Firefox, Safari, IE10+, Edge ou Opera.",
};

const GENERIC_ERROR_TEXT =
  'Nos serveurs sont en cours de maintenance, merci de revenir plus tard.';

class PiecesJustificativesPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      heightIframe: 500,
      heightIframeConjoint: 500,
      message: '',
      state: '',
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.updateHeight);
  }

  showMessage = text => {
    this.setState({
      message: text,
    });
  };
  updateHeight = message => {
    let event = message.data;
    try {
      event = JSON.parse(message.data);
    } catch (e) {
      event = message.data;
    }
    if (event.type === 'displayEvent') {
      // Adjust iframe size function of how big the SignBook want to be
      if (event.cause === 'heightChanged') {
        // const signbook = document.getElementById('signbook');
        // const currentHeight = signbook && signbook.css('height').split('px')[0];
        const newHeight = event.height + 20;
        if (this.props.isConjoint) {
          this.setState({
            heightIframeConjoint: newHeight,
          });
        } else {
          this.setState({
            heightIframe: newHeight,
          });
        }
      } else if (event.cause === 'pageChanged') {
        window.scrollTo(0, 0);
      }
    } else if (event.type === 'userEvent') {
      // All user actions => used by web analytics libraries like Google Analytics
      console.log('user event : ' + event.action);
    } else if (event.type === 'clientFileEvent') {
      // Event coming from eKeynox SaaS with data concerning the state of the client file
      //  console.log('client file state : ' + event.state);

      if (
        (event.changedState &&
          (event.changedState.from === 'PENDING' &&
            (event.changedState.to === 'WAITING' ||
              event.changedState.to === 'ACCEPTED'))) ||
        event.participantState === 'WAITING'
      ) {
        this.showMessage(MESSAGE_TEXTS['FINALIZED']);
        this.setState({
          state: event.state,
        });
      } else {
        if (event.state !== 'PENDING') {
          this.showMessage(MESSAGE_TEXTS[event.state]);
          this.setState({
            state: event.state,
          });
        }
      }
    } else if (event.type === 'errorEvent') {
      // Errors detected by the SignBook (server errors, invalid state, unsupported browser, etc.)
      this.showMessage(
        (ERROR_TEXTS[event.cause]
          ? ERROR_TEXTS[event.cause]
          : GENERIC_ERROR_TEXT) +
        '(' +
        event.cause +
        ')',
      );
    }
  };
  render() {
    const { token, isConjoint } = this.props;
    const { message, heightIframe, heightIframeConjoint, state } = this.state;
    return (
      <>
        {message && (
          <div className={this.props.classes.infoMessage}>
            <Typography dangerouslySetInnerHTML={{ __html: message }} />
          </div>
        )}
        {state !== 'ACCEPTED' &&
          state !== 'REFUSED' &&
          state !== 'SUSPENDED' &&
          state !== 'WAITING' && (
            <iframe
              src={getNetheosEndpoint() + token}
              title="piece"
              className={this.props.classes.iframe}
              width="100%"
              height={isConjoint ? heightIframeConjoint : heightIframe}
              frameBorder="no"
              allow="microphone; camera"
            />
          )}
      </>
    );
  }
}

export default withStyles(styles)(PiecesJustificativesPage);
