import { Theme } from '@material-ui/core/styles';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
  ({
    landingPage: {
      overflow: 'hidden',
      '& > footer > div': {
        justifyContent: 'center',
      },
    },
    container: {
      maxWidth: 1080,
      margin: 'auto',
    },
    bigTitleLanding: {
      flexDirection: 'column',
      alignItems: 'center',
      '& h1': {
        textAlign: 'center',
        paddingBottom: 12,
      },
      '& h2': {
        margin: 0,
        padding: 0,
        fontSize: 18,
      },
    },
    contactContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      maxWidth: '1464px',
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
    },
    iframeYoutube: {
      borderRadius: '4px',
      border: 0,
      marginRight: 24,
      zIndex: 10,
    },
    grosRond: {
      zIndex: 1,
      width: 200,
      height: 200,
      borderRadius: '100%',
      background: 'rgba(102, 153, 0, 0.3)',
      position: 'absolute',
      top: 332,
      left: 9,
    },
    '@media all and (max-width: 1000px)': {
      contactContainer: {
        flexDirection: 'column',
        '& iframe': {
          marginRight: 0,
          marginBottom: 24,
        },
      },
    },
    '@media all and (max-width: 650px)': {
      iframeYoutube: {
        width: 480,
        height: 280,
      },
      captcha: {
        maxWidth: 500,
      },
    },
    '@media all and (max-width: 500px)': {
      bigTitleLanding: {
        paddingTop: '94px !important',
      },

      iframeYoutube: {
        width: 420,
        height: 230,
      },
    },
  } as any);
// tslint:enable: quotemark

export default styles;
