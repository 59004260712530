import { Link, withStyles } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import LogoBesse from '../../ressources/images/besse-immobilier-construction.jpg';
import LogoFooter from '../../ressources/images/logo_vertuloo_blanc.png';
import Libelle from '../../_i18n/fr.json';
import styles from './styles';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes: any;
}

class Footer extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <footer className={classes.footer}>
        <div className={classes.footerLogo}>
          <img src={LogoFooter} className={classes.footerLogoImg} height={40} alt="" />{' '}
          <div className={classes.footerLogoBesse}>
            {' '}
            <img src={LogoBesse} height={60} alt="" />
          </div>
        </div>
        <ul className={classes.footerLiens}>
          <li className={classes.footerLien}>
            <Link
              component={RouterLink}
              className={classes.footerLien}
              {...{ to: '/mentions-legales' } as any}
            >
              {Libelle.footer.mentionsLegales.libelle}
            </Link>
          </li>
          <li className={classes.footerLien}>
            <Link
              component={RouterLink}
              className={classes.footerLien}
              {...{ to: '/protection-des-donnees' } as any}
            >
              {Libelle.footer.protectionDonneesPersonnelles.libelle}
            </Link>
          </li>
        </ul>
      </footer>
    );
  }
}

export default withStyles(styles)(Footer);
