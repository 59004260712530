import { Theme } from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { withStyles } from '@material-ui/styles';
import React from 'react';

interface Props {
  classes?: any;
  text: string;
  size?: 'small' | 'large';
  className?: string;
}
const styles = (theme: Theme) =>
  ({
    error: {
      borderLeft: '5px solid #ffb549',
      background: 'rgba(255, 181, 73, 0.1)',
      padding: '4px 20px 4px 8px',
      fontStyle: 'italic',
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
    },
    icon: {
      color: '#FFB549',
      marginRight: 8,
    },
  } as any);
// tslint:enable: quotemark

const Error = (props: Props) => {
  let className = props.classes.error;
  if (props.className) {
    className += ' ' + props.className;
  }
  return (
    <div className={className}>
      <ErrorOutline fontSize={'small'} className={props.classes.icon} />
      <span>{props.text}</span>
    </div>
  );
};

export default withStyles(styles)(Error);
