import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    container: {
      width: '100%',
      background: theme.palette.primary.main,
      padding: '8px',
      textAlign: 'center',
      '& p': {
        fontSize: 20,
        color: '#FFF',
        margin: 'auto',
        maxWidth: 392,
      },
    },

    '@media all and (max-width: 580px)': {
      container: {
        '& img': {
          width: 280,
        },
      },
    },
  } as any);

export default styles;
