import React from 'react';
import { AdministrateurBienDetail } from '../../../../services/Api/swagger';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import styles from '../../styles';

/**
 * Titre des colonnes de notre tableau des contrats loyers impayés.
 */
const dataHeaderContrats = [
  {
    name: 'N° Contrat',
    prop: 'numContratProps',
  },
  {
    name: "Taux d'effort en %",
    prop: 'tauxEffortProps',
  },
];
export interface Props {
  classes?: any;
  dataConsultation: AdministrateurBienDetail;
}
const ContratsLoyerImpayes = (props: Props) => {
  const { dataConsultation, classes } = props;

  return (
    <Grid item>
      <h3>Contrats loyers impayes</h3>
      {dataConsultation.contratsLoyerImpaye !== undefined &&
      dataConsultation.contratsLoyerImpaye.length > 0 ? (
        <Table className={'boxShadow'}>
          <TableHead>
            <TableRow className={classes.tableauProfilsHeader}>
              {dataHeaderContrats.map(n => {
                return (
                  <TableCell key={n.prop} component="th" scope="row">
                    {n.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataConsultation.contratsLoyerImpaye.map(n => {
              return (
                <TableRow key={n.numero} className={classes.tableauProfils}>
                  <TableCell>{n.numero}</TableCell>
                  <TableCell>{n.tauxEffort}%</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <span>Aucun contrat renseigné</span>
      )}
    </Grid>
  );
};

export default withStyles(styles)(ContratsLoyerImpayes);
