import { Theme } from '@material-ui/core/styles';
import { gris2 } from '../../../theme';

const styles = (theme: Theme) =>
    ({
        formContainer: {
            width: '40%',
            margin: 'auto',
            marginTop: '5%',
        },
        icon: {
            color: gris2,
            marginRight: 8,
        },
    } as any);

export default styles;
