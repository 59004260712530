import React from 'react';
import Layout from '../../../components/Layout';
import Libelle from '../../../_i18n/fr.json';
import Synthese from './Synthese';

class SyntheseDossierLocationPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout title={Libelle.pageSynthese.titre}>
          <Synthese />
        </Layout>
      </React.Fragment>
    );
  }
}

export default SyntheseDossierLocationPage;
