import { Theme } from '@material-ui/core/styles';
import { gris2 } from '../../theme';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
  ({
    containerDialog: {
      display: 'flex',
      alignItems: 'center',
    },
    iconButton: {
      "padding": 0,
      "marginRight": 12,
      "position": 'absolute',
      "right": 0,
      "top": 7,
      "color": gris2,
      '&:hover': {
        background: 'transparent',
        color: '#000',
      },
    },
    closeButton: {
      '&:hover': {
        background: 'transparent',
        color: '#000',
      },
    },
  } as any);
// tslint:enable: quotemark

export default styles;
