import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    container: {
      width: '100%',
      background: theme.palette.primary.main,
      padding: '16px',
      textAlign: 'center',
      '& a': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '& > span': {
          width: 'fit-content',
          maxWidth: 256,
          margin: 'auto',
          color: theme.palette.primary.main,
          background: 'white',
          borderRadius: 53,
          padding: '8px 62px 10px 62px',
          fontWeight: '600',
          lineHeight: '18px',
          fontSize: 16,
          boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.4)',
          textTransform: 'initial',
          display: 'flex',
          flexDirection: 'column',
          '&:hover, &:focus, &:active': {
            background: 'white',
            boxShadow: 'inset 0px 1px 1px rgba(0, 0, 0, 0.4)',
          },
        },
      },
    },
    containerTexte: {
      padding: '52px 12px',
      margin: 'auto',
      maxWidth: 880,
      '& p': {
        margin: '12px 0',
        position: 'relative',
        paddingLeft: 12,
        '&:before': {
          content: '""',
          width: 5,
          height: 5,
          background: '#808080',
          position: 'absolute',
          borderRadius: '100%',
          top: 8,
          left: -4,
        },
      },
      '& h2': {
        fontSize: 16,
        margin: 0,
        '& > span': {
          fontWeight: 'bold',
          fontSize: 26,
        },
      },
      '& h3': {
        margin: 0,
        fontSize: 25,
        textTransform: 'initial',
      },
      '& h4': {
        margin: 0,
        fontSize: 14,
      },
    },
    divider: {
      height: 6,
      background: theme.palette.primary.main,
      width: 100,
      margin: '18px 0',
    },
  } as any);

export default styles;
