import { Theme } from '@material-ui/core/styles';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
  ({
    formRechercherAdb: {
      padding: 34,
      width: '100%',
      margin: 0,
    },
    bouton: {
      '& button': {
        float: 'right',
      },
    },
    ligne: {
      '& > div': {
        marginTop: 24,
      },
    },
    paragraph: {
      margin: 4,
    },
    titreSecondaire: {
      marginRight: 24,
    },
    tableauProfils: {
      background: 'white',
      height: 40,
      '& > td': {
        fontSize: 13,
        color: theme.palette.common.black,
        borderBottom: 'none',
        borderWidth: '1px',
        borderLeft: 'none',
        '&:last-child': {
          borderRight: 'none',
        },
        padding: '4px 8px',
      },
    },
    tableauProfilsHeader: {
      height: 40,
      '& > th': {
        fontSize: 10,
        padding: '4px 8px',
        lineHeight: '1rem',
        fontWeight: '500',
        borderLeft: 'none',
        '&:last-child': {
          borderRight: 'none',
        },
      },
    },
    link: {
      display: 'flex',
      alignItems: 'center',
    },
    containerTitle: {
      display: 'flex',
      alignItems: 'center',
    },
  } as any);
// tslint:enable: quotemark

export default styles;
