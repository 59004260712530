import { Button, withStyles } from '@material-ui/core';
import React from 'react';
import { Cookies, withCookies } from 'react-cookie';
import BlocBackground from './BlocBackground';
import styles from './styles';
import moment from 'moment';
import image from '../../../ressources/images/imgBertrandOnParleNous.png';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  cookies: Cookies;
}
class Blocs extends React.Component<Props> {
  /**
   * Récupération d'un cookie coté client
   */
  getCookies = () => {
    return this.props.cookies.get('publicitaireIsCheck');
  };

  setCookies = () => {
    const datePlus6 = moment(new Date()).add(6, 'month');
    this.props.cookies.set('publicitaireIsCheck', true, {
      expires: datePlus6.toDate(),
    });
    window.location.reload();
  };

  render() {
    const { classes } = this.props;
    const cookies = this.getCookies();
    return (
      <>
        {/** Titre */}
        <BlocBackground />
        <div className={classes.container}>
          <div className={classes.grosRond} />
          <h2>À LA UNE</h2>
          <iframe
            className={classes.iframeYoutube}
            title="youtube"
            width="645"
            height="367"
            src={
              cookies === 'true'
                ? 'https://www.youtube.com/embed/erO3go-cQn0'
                : 'https://www.youtube-nocookie.com/embed/erO3go-cQn0'
            }
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
          <div>
            <span className={classes.date}>septembre 2020</span>
            <h4>
              Présentation de Vertuloo, la solution conçue par Bessé Immobilier
              & Construction,
              <br /> pour les Administrateurs de Biens, au député Mickael Nogal
            </h4>
          </div>
        </div>
        <div className={classes.divider} />
        <div className={classes.containerFlex}>
          {cookies === 'true' ? (
            <iframe
              title="youtube"
              width="100%"
              height="300"
              scrolling="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/910480666&color=%237f9d00&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
            />
          ) : (
            <div className={classes.cookieConsent}>
              <p>
                Ce contenu utilise des cookies publicitaires, nous avons besoin
                de votre consentement pour l'afficher.
              </p>
              <div className={classes.cookieButton}>
                <a href="/protection-des-donnees">Détails</a>
                <Button
                  onClick={this.setCookies}
                  color="primary"
                  variant="contained"
                >
                  Accepter
                </Button>
              </div>
            </div>
          )}
          <div className={classes.description}>
            <h2>
              MY SWEET'IMMO
              <p className={classes.date}>octobre 2020</p>
            </h2>
            <p>
              {' '}
              Bertrand Mulot, directeur de Bessé Immobilier et Construction
              présente Vertuloo, la nouvelle plateforme digitale visant à
              sécuriser et développer la gestion locative au micro d'Alexis
              Thiebaut.
            </p>
          </div>
        </div>
        <div className={classes.divider} />{' '}
        <div className={classes.containerFlex}>
          <div className={classes.description}>
            <h2>
              IMMO MATIN
              <p className={classes.date}>mars 2020</p>
            </h2>
            <p>
              "Outil Vertuloo : Une nouvelle approche de la gestion locative.
              Outil digital tout juste lancé, revendique trois vertus : élargir,
              simplifier et sécuriser la gestion des dossiers de location.
              Présentation avec Bertrand Mulot, directeur de Bessé Immobilier et
              Construction, son créateur."
            </p>
            <a
              href="https://www.immomatin.com/logiciels/logiciels-gestion/outil-vertuloo-une-nouvelle-approche-de-la-gestion-locative.html "
              target="_blank"
              rel="noopener noreferrer"
              className={classes.linkPlus}
            >
              en savoir plus
            </a>
          </div>
          <div>
            <div className={classes.moyenRond} />
            <img src={image} width={500} alt="Immo Matin" />
          </div>
        </div>
        <div className={classes.containerFlex}>
          <div>
            <iframe
              className={classes.iframeYoutube}
              title="youtube"
              width="500"
              height="289"
              src={
                cookies === 'true'
                  ? 'https://www.youtube.com/embed/36P6Rv4h4pE'
                  : 'https://www.youtube-nocookie.com/embed/36P6Rv4h4pE'
              }
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>{' '}
          <div className={classes.description}>
            <h2>
              MY SWEET'IMMO
              <p className={classes.date}>février 2020</p>
            </h2>
            <p>
              Bertrand Mulot, directeur de Bessé Immobilier et Construction
              était au micro d'Ariane Artinian.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withCookies(withStyles(styles)(Blocs));
