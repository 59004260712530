import { AxiosInstance } from 'axios';
import { Contact, UtilitairesApiFactory } from './swagger';

export interface IApi {
  postNousContacter(params: Contact): Promise<void>;
}

const create = (endpoint: string, axiosClient: AxiosInstance) => {
  const utilitaireApi = UtilitairesApiFactory({}, endpoint, axiosClient);

  /**
   * Enregistrer une situation personnelle
   * On créé element par element
   * Meme si l'API permet de faire une création en masse
   *
   * @param params : Critères de recherche
   */
  const postNousContacter = (params: Contact): Promise<void> => {
    const promise: Promise<void> = new Promise((resolve, reject) => {
      utilitaireApi
        .nousContacterPost(params, {})
        .then(() => resolve())
        .catch(error => reject(error));
    });
    return promise;
  };

  return {
    postNousContacter,
  };
};

export default create;
