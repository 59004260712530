import { Theme } from '@material-ui/core/styles';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
  ({
    containerAll: {
      marginTop: 58,
    },
    preformulaireBloc: {
      padding: 30,
      margin: '3.125rem 0',
    },
    blocLibelleTypeDossier: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      color: theme.palette.common.black,
      marginTop: 15,
    },
    libelleTypeDossier: {
      paddingTop: 10,
      fontSize: 12,
      textAlign: 'center',
    },
    boutonRadio: {
      position: 'absolute',
      opacity: 0,
      width: 0,
      height: 0,
      '&:checked + label > span': {
        fontWeight: 'bold',
      },
      '&:checked + label > svg #Mask': {
        fill: theme.palette.primary.main,
      },
    },
    container: {
      width: '80%',
      minWidth: 200,
      textAlign: 'center',
      margin: 'auto',
      marginTop: '5%',
    },
    conteneurAction: {
      padding: 20,
    },
  } as any);
// tslint:enable: quotemark

export default styles;
