import { Theme } from '@material-ui/core/styles';
import { erreur, gris2 } from '../../theme';

const styles = (theme: Theme) =>
  ({
    root: {
      width: '100%',
      fontWeight: 600,
      fontSize: '0.8rem',
      '&:hover $textField': {
        backgroundColor: theme.palette.common.white,
      },
    },
    textFieldRoot: {
      border: '1px solid ' + theme.palette.primary.main,
    },
    textField: {
      backgroundColor: theme.palette.common.white,
      borderRadius: '0',
      padding: '15px 12px 7px',
      fontSize: '0.9rem',
    },
    textFieldLabelFocused: {},
    textFieldLabelError: {},
    textFieldLabelAsterisk: {
      '&$textFieldLabelError': {
        color: theme.palette.common.black,
      },
    },
    textFieldLabel: {
      fontWeight: 'bold',
      fontSize: '0.8rem',
      color: theme.palette.common.black,
      transform: 'translate(12px, 14px) scale(1)',
      '&$textFieldLabelFocused': {
        fontWeight: 'normal',
        color: theme.palette.common.black,
      },
      '&$textFieldLabelError': {
        color: theme.palette.common.black,
      },
      '&.MuiInputLabel-shrink': {
        transform: 'translate(12px, 4px) scale(0.75)',
        fontSize: '1em',
        fontWeight: 'normal',
        color: theme.palette.grey[400],
      },
    },
    errorMessage: {
      color: erreur,
    },
    underline: {
      '&::before, &::after': {
        borderBottomWidth: '0',
      },
    },
    counterRoot: {
      display: 'flex',
      flexDirection: 'row',
    },
    counterTextField: {
      width: '2.5rem',
    },
    textFieldCounter: {
      padding: '11px 8px',
      textAlign: 'center',
      background: 'white',
    },
    counterLabel: {
      position: 'relative',
      transform: 'none',
      display: 'flex',
      alignItems: 'center',
      margin: '0 12px',
      color: theme.palette.common.black,
      fontWeight: 'bold',
      fontSize: '0.8rem',
    },
    buttonCounter: {
      background: theme.palette.primary.main,
      color: '#FFF',
      borderRadius: '0',
      border: 'none',
      width: 40,
      minWidth: 40,
      '&:hover': {
        backgroundColor: gris2,
      },
      '& svg': {
        color: '#FFF',
      },
    },
    caption: {
      fontStyle: 'italic',
    },
    inputPassword: {
      background: '#FFF',
      '&:hover': {
        background: '#FFF',
      },
    },
    showPassword: {
      padding: 4,
      '&:hover': {
        background: 'none',
        color: '#444',
      },
    },
  } as any);

export default styles;
