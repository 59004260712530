import { push } from 'connected-react-router';
import { call, put, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { ActionCreators, Selectors } from '../../redux/Utilisateurs';
import { IApi } from '../../services/Api';
import { history } from '../../helpers/history';
import {
  FonctionPoste,
  PageUtilisateurs,
  Profil,
  Utilisateur,
  UtilisateurRoleEnum,
} from '../../services/Api/swagger';

export function* ajouterUtilisateur(
  api: IApi,
  action: ActionType<typeof ActionCreators.ajouterUtilisateur.request>,
) {
  try {
    const result: Utilisateur = yield call(
      api.ajouterUtilisateur,
      action.payload,
    );
    yield put(ActionCreators.ajouterUtilisateur.success(result));
    yield put(ActionCreators.confirmationCreationUtilisateur(true));
    yield put(push('/mes-utilisateurs'));
  } catch (error) {
    yield put(ActionCreators.ajouterUtilisateur.failure(error));
  }
}

export function* getFonctionsPostes(
  api: IApi,
  action: ActionType<typeof ActionCreators.getFonctionsPostes.request>,
) {
  try {
    const result: FonctionPoste[] = yield call(api.getFonctionsPostes);
    yield put(ActionCreators.getFonctionsPostes.success(result));
  } catch (error) {
    yield put(ActionCreators.getFonctionsPostes.failure(error));
  }
}

export function* getUtilisateurByUsername(
  api: IApi,
  action: ActionType<typeof ActionCreators.getUtilisateurByUsername.request>,
) {
  try {
    const result: Utilisateur = yield call(
      api.getUtilisateurByUsername,
      action.payload,
    );
    yield put(ActionCreators.getUtilisateurByUsername.success(result));
  } catch (error) {
    yield put(ActionCreators.getUtilisateurByUsername.failure(error));
  }
}

export function* getUtilisateurConnecteByUsername(
  api: IApi,
  action: ActionType<
    typeof ActionCreators.getUtilisateurConnecteByUsername.request
  >,
) {
  try {
    const result: Utilisateur = yield call(
      api.getUtilisateurByUsername,
      action.payload,
    );
    yield put(ActionCreators.getUtilisateurConnecteByUsername.success(result));

    const id = yield select(Selectors.getProfilId);

    if (id) {
      const profilResult: Profil = yield call(api.getProfilById, id);
      yield put(ActionCreators.getProfil.success(profilResult));

      if (result) {
        // Si l'on vient d'un lien qui contient l'url d'un dossier (après authentification) on redirige vers la synthèse
        const refPath = new URLSearchParams(history.location.search).get("refDossier");
        if (refPath) {
          yield put(push('/dossier-location/synthese?refDossier=' + refPath));
        } else if (result.role === UtilisateurRoleEnum.BESSE) {
          // Si l'on est un admin BESSE on redirige vers la page adb
          yield put(push('/mes-administrateurs-de-biens'));
        }
      }
    }
  } catch (error) {
    yield put(ActionCreators.getUtilisateurConnecteByUsername.failure(error));
  }
}

export function* getProfilsBesse(
  api: IApi,
  action: ActionType<typeof ActionCreators.getProfilsBesse.request>,
) {
  try {
    const result: Profil[] = yield call(api.getProfilsBesse, action.payload);
    yield put(ActionCreators.getProfilsBesse.success(result));
  } catch (error) {
    yield put(ActionCreators.getProfilsBesse.failure(error));
  }
}

export function* getAllUtilisateursBesse(
  api: IApi,
  action: ActionType<typeof ActionCreators.getAllUtilisateursBesse.request>,
) {
  try {
    const result: PageUtilisateurs = yield call(
      api.getAllUtilisateursBesse,
      action.payload,
    );
    yield put(ActionCreators.getAllUtilisateursBesse.success(result));
  } catch (error) {
    yield put(ActionCreators.getAllUtilisateursBesse.failure(error));
  }
}

export function* getAllUtilisateursAdb(
  api: IApi,
  action: ActionType<typeof ActionCreators.getAllUtilisateursAdb.request>,
) {
  try {
    const result: PageUtilisateurs = yield call(
      api.getAllUtilisateursAdb,
      action.payload,
    );
    yield put(ActionCreators.getAllUtilisateursAdb.success(result));
  } catch (error) {
    yield put(ActionCreators.getAllUtilisateursAdb.failure(error));
  }
}

export function* desactiverUtilisateur(
  api: IApi,
  action: ActionType<typeof ActionCreators.desactiverUtilisateur.request>,
) {
  try {
    const username = action.payload;
    yield call(api.desactiverUtilisateur, username);
    yield put(ActionCreators.desactiverUtilisateur.success());
    yield put(
      ActionCreators.changerUtilisateurStatut({ username, actif: false }),
    );
  } catch (error) {
    yield put(ActionCreators.desactiverUtilisateur.failure(error));
  }
}

export function* activerUtilisateur(
  api: IApi,
  action: ActionType<typeof ActionCreators.activerUtilisateur.request>,
) {
  try {
    const username = action.payload;
    yield call(api.activerUtilisateur, username);
    yield put(ActionCreators.activerUtilisateur.success());
    yield put(
      ActionCreators.changerUtilisateurStatut({ username, actif: true }),
    );
  } catch (error) {
    yield put(ActionCreators.activerUtilisateur.failure(error));
  }
}

export function* modifierUtilisateur(
  api: IApi,
  action: ActionType<typeof ActionCreators.modifierUtilisateur.request>,
) {
  try {
    yield call(api.modifierUtilisateur, action.payload);
    yield put(ActionCreators.modifierUtilisateur.success());
    yield put(ActionCreators.confirmationCreationUtilisateur(true));
    yield put(push('/mes-utilisateurs'));
  } catch (error) {
    yield put(ActionCreators.modifierUtilisateur.failure(error));
  }
}

export function* activerCompteUtilisateur(
  api: IApi,
  action: ActionType<typeof ActionCreators.activerCompteUtilisateur.request>,
) {
  try {
    yield call(api.activerCompteUtilisateur, action.payload);
    yield put(ActionCreators.activerCompteUtilisateur.success());
  } catch (error) {
    yield put(ActionCreators.activerCompteUtilisateur.failure(error));
  }
}
