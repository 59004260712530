/**
 * Encapsule une erreur pour faciliter son traitement.
 * On gère en particulier une propriété "name" qui permet d'identifier facilement le type d'erreur.
 * @param error

 */
import { DossierParticulierDetail } from '../services/Api/swagger/api.js';
import ErreursConstante from '../_constantes/erreursConstantes.json';

export const wrapError = (error: any, prefixe?: string): any => {
  let wrappedError = {};
  if (error) {
    if (error.response.status === 403) {
      wrappedError = {
        code: 'ErreurMetier',
        content: resolveErrorMetier(error.response.data),
      };
    } else if (error.response.status === 400) {
      wrappedError = {
        code: 'ErreurFormulaire',
        content: resolveErrorForm(error.response.data, prefixe),
      };
    } else {
      wrappedError = {
        code: 'UnexpectedError',
        content: ErreursConstante.message.general,
      };
    }
  }
  return wrappedError;
};

export const resolveErrorForm = (
  content: Array<{ code: string; element: string }>,
  prefixe?: string,
): any => {
  const errorResolved = !prefixe ? {} : { [prefixe]: {} };
  if (content) {
    content.forEach(s => {
      if (prefixe) {
        errorResolved[prefixe][s.element] =
          ErreursConstante.formulaire.generale.champsErrone;
      } else {
        errorResolved[s.element] =
          ErreursConstante.formulaire.generale.champsErrone;
      }
    });
  }
  return errorResolved;
};

export const resolveErrorMetier = (
  content: Array<{ code: string; element: string }>,
): any => {
  const errorResolved = {};
  if (content) {
    content.forEach(s => {
      let message = ErreursConstante.message[s.code];
      message = message
        ? ErreursConstante.message[s.code]
        : ErreursConstante.message.general;
      errorResolved[s.code] = message;
    });
  }
  return errorResolved;
};

export const getTitleCandidat = (
  dossierLocation,
  typeCompte,
  dossiersParticuliers: DossierParticulierDetail[] | undefined,
  refLocataire: string | undefined,
  refPersonSelected: string | undefined,
  nomPrenomLocataire: string | undefined,
) => {
  let nameSelectedPerson = ''; // nom de la personne selectionnée en ajout ou modification
  let intituleCandidat = ' du(de la) candidat(e) '; // intitulé du candidat ( candidat, locataire ou couple)
  // selection du candidat en cours de modification
  const dossierSelectedPerson =
    refPersonSelected &&
    dossiersParticuliers &&
    dossiersParticuliers.find(dos => dos.reference === refPersonSelected);

  const typeCompteCalcule =
    typeCompte || (dossierSelectedPerson && dossierSelectedPerson.typeCompte);
  // Si un dossier sélectionné existe c'est qu'on est en modification sinon ajout
  const ajoutOuModification = dossierSelectedPerson
    ? ' : Modification'
    : ' : Ajout';
  // affichage de la référence du dossier
  const refDossier = 'Dossier n°' + dossierLocation.reference;

  // Afficher le nom et prénom du candidat
  // en modification
  if (dossierSelectedPerson) {
    nameSelectedPerson =
      dossierSelectedPerson.prenom + ' ' + dossierSelectedPerson.nom;
    // en ajout
  } else if (nomPrenomLocataire) {
    nameSelectedPerson = nomPrenomLocataire;
  }
  // si c'est un COUPLE
  if (
    dossierLocation &&
    dossierLocation.compositionLocation === 'COUPLE' &&
    typeCompteCalcule !== 'GARANT'
  ) {
    intituleCandidat = ' du couple ';
    const nameCandidat =
      dossiersParticuliers &&
      dossiersParticuliers[0] &&
      dossiersParticuliers[0].prenom &&
      dossiersParticuliers[0].nom &&
      dossiersParticuliers[0].prenom + ' ' + dossiersParticuliers[0].nom;
    const nameConjoint =
      dossiersParticuliers &&
      dossiersParticuliers[1] &&
      dossiersParticuliers[1].prenom &&
      dossiersParticuliers[1].nom &&
      dossiersParticuliers[1].prenom + ' ' + dossiersParticuliers[1].nom;
    nameSelectedPerson = nameCandidat
      ? nameCandidat + ' et ' + nameConjoint
      : ' ';
  }

  // si c'est une colocation
  if (dossierLocation && dossierLocation.compositionLocation === 'COLOCATION') {
    intituleCandidat = ' du(de la) locataire ';
  }

  // Affichage du titre pour un garant
  if (typeCompteCalcule && typeCompteCalcule === 'GARANT') {
    let locataireReferent;
    if (refLocataire) {
      locataireReferent =
        dossiersParticuliers &&
        refLocataire &&
        dossiersParticuliers.find(
          locataire => locataire.reference === refLocataire,
        );
    } else {
      locataireReferent =
        dossiersParticuliers &&
        dossiersParticuliers.find(
          locataire =>
            dossierSelectedPerson &&
            locataire.reference === dossierSelectedPerson.refDossierLocataire,
        );
    }
    const nomLocataire =
      locataireReferent &&
      locataireReferent.prenom + ' ' + locataireReferent.nom;
    return (
      refDossier +
      ajoutOuModification +
      " d'un(e) garant(e) pour le(a) candidat(e) " +
      nomLocataire
    );
  } else {
    return nameSelectedPerson
      ? refDossier + ajoutOuModification + intituleCandidat + nameSelectedPerson
      : refDossier + ajoutOuModification + intituleCandidat;
  }
};

export const getDateNaissance = (
  dossierLocation,
  dateNaissance: any,
  isConjoint: boolean,
) => {
  if (dateNaissance) {
    return dateNaissance;
  } else {
    if (isConjoint) {
      return (
        dossierLocation.dossiersParticuliers &&
        dossierLocation.dossiersParticuliers[1] &&
        dossierLocation.dossiersParticuliers[1].dateNaissance
      );
    } else {
      return (
        dossierLocation.dossiersParticuliers &&
        dossierLocation.dossiersParticuliers[0] &&
        dossierLocation.dossiersParticuliers[0].dateNaissance
      );
    }
  }
};

export const b64toBlob = b64Data => {
  const byteCharacters = btoa(unescape(encodeURIComponent(b64Data)));
  const byteArrays = [];
  const sliceSize = 512;

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    // @ts-ignore
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: 'application/pdf' });
};

export const getCookie = (cname: string) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
