import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    containerFlex: {
      display: 'flex',
      maxWidth: '880px',
      padding: '48px 12px 48px 12px',
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      '& h4': {
        fontSize: 15,
      },
    },
    description: {
      //  maxWidth: 400,
      marginLeft: 24,
      '& p': {
        marginBottom: 24,
      },
    },
  } as any);

export default styles;
