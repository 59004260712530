import React from 'react';
import Landing from './Landing';
import Libelle from '../../_i18n/fr.json';
import Layout from '../../components/Layout';
import styles from './Landing/styles';
import { withStyles } from '@material-ui/core';

interface Props {
  classes?: any;
}
class LandingPage extends React.Component<Props> {
  render() {
    return (
      <React.Fragment>
        <div className={this.props.classes.landingPage}>
          <Layout title={Libelle.pageLanding.titre} noFollow>
            <Landing />
          </Layout>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(LandingPage);
