import { Grid, Link, withStyles } from '@material-ui/core';
import React from 'react';
import { formatDateCourt } from '../../../../../helpers/formatters';
import BienIconNoir from '../../../../../ressources/images/BienIconNoir.svg';
import ModifyIcon from '../../../../../ressources/images/ModifyIcon.svg';
import { DossierLocation } from '../../../../../services/Api/swagger';
import messages from '../../../../../_i18n/fr.json';
import styles from '../styles';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  data: DossierLocation;
  cantModify: boolean;
  nomPartenaire?: string;
  urlPartenaire?: string;
}

interface State {
  openHistorique: boolean;
}
class InfoDossier extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openHistorique: false,
    };
  }
  render() {
    const {
      data,
      classes,
      data: { bien },
      cantModify,
      nomPartenaire,
      urlPartenaire,
    } = this.props;

    let nombrePieces = bien && bien.nombrePieces.slice(1, 2);
    if (bien && bien.nombrePieces === 'P6_ET_PLUS') {
      nombrePieces = '6 ou plus';
    }

    const actionsForcer =
      data.actions &&
      data.actions.filter(act => act.typeAction === 'FORCER_STATUT');
    const dernierActionForcer =
      actionsForcer && actionsForcer[actionsForcer.length - 1];
    const dateForcer =
      actionsForcer &&
      dernierActionForcer &&
      dernierActionForcer.date &&
      formatDateCourt(actionsForcer[actionsForcer.length - 1].date);
    return (
      <Grid container justify="space-between">
        {bien && (
          <div className={classes.contentBien}>
            <h4>
              <img src={BienIconNoir} width="27" height="27" alt="" />
              Le bien{' '}
              {!cantModify && (
                <Link href="/dossier-location/bien">
                  <img alt="modifier le bien"
                    src={ModifyIcon}
                    width={24}
                    height={24}
                    className={classes.modifyIcon}
                  />
                </Link>
              )}
            </h4>
            <p>
              Adresse totale :
              <strong>
                {bien.numeroBatiment &&
                  <span> bât. {bien.numeroBatiment}, </span>
                }
                {bien.numero} {bien.typeVoie} {bien.adresse}
                {', '}
                {bien.codePostal} {bien.ville}
              </strong>
            </p>
            <p>
              Détails :
              <strong>
                {bien.typeBien}, {bien.meuble ? 'meublé, ' : ''}
                {bien.superficie}
                m², {nombrePieces} pièce(s)
              </strong>
            </p>
            <p>
              Loyer :
              <strong>
                {bien.loyerMensuel + bien.chargesMensuelles}€ (dont charges :{' '}
                {bien.chargesMensuelles}€)
              </strong>
            </p>
            <p>
              Assuré : <strong>{bien.garantieLi ? 'oui' : 'non'}</strong>
            </p>
            <p>
              Référence mandat : <strong>{bien.referenceMandat}</strong>
            </p>
            <p>
              Numéro du lot : <strong>{bien.numeroLot}</strong>
            </p>
            <p>
              Usage : <strong>{bien.typeResidence}</strong>
            </p>
          </div>
        )}
        <div className={classes.textRight}>
          <h4>Synthèse</h4>
          <p>
            Référence dossier : <strong>{data.reference}</strong>
          </p>
          {nomPartenaire && urlPartenaire && (
            <p>
              Visualisez le dossier chez&nbsp;
              <a href={urlPartenaire} target="_blank" rel="noopener noreferrer">{nomPartenaire}</a>
            </p>
          )}
          <p>
            Date de création du dossier :
            <strong>{formatDateCourt(data.dateCreation)}</strong>
          </p>
          <p>
            Demandeur : <strong>{data.createur}</strong>
          </p>
          <p>
            Date de demande de validation :
            <strong>
              {data.dateDemandeValidation
                ? formatDateCourt(data.dateDemandeValidation)
                : '-'}
            </strong>
          </p>
          <p>
            Type de dossier :{' '}
            <strong>{messages.typeDossier[data.compositionLocation]}</strong>
          </p>
          <br />
          {dateForcer && dernierActionForcer && (
            <p>
              Le statut a été forcé le <strong>{dateForcer}</strong> par{' '}
              <strong>{dernierActionForcer.utilisateur}</strong>
              <br /> Commentaire :{' '}
              <strong>{dernierActionForcer.message}</strong>
            </p>
          )}
          {actionsForcer && actionsForcer.length > 1 && (
            <div>
              <small>
                <Link
                  underline="always"
                  color="secondary"
                  component="button"
                  onClick={() =>
                    this.setState({
                      openHistorique: !this.state.openHistorique,
                    })
                  }
                >
                  {this.state.openHistorique
                    ? "Masquer l'historique"
                    : "Voir l'historique"}
                </Link>
              </small>
              <br />
              {this.state.openHistorique &&
                actionsForcer &&
                actionsForcer
                  .slice(0, actionsForcer.length - 1)
                  .map((act, i) => (
                    <p key={i}>
                      Le statut a été forcé le{' '}
                      <strong>{act.date && formatDateCourt(act.date)}</strong>{' '}
                      par <strong>{act.utilisateur}</strong>
                      <br /> Commentaire : <strong>{act.message}</strong>
                    </p>
                  ))}
            </div>
          )}
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(InfoDossier);
