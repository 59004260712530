import {
  FormControl,
  IconButton,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import styles from './styles';

export interface IOwnProps {
  input?: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  label?: string;
  required?: boolean;
  type?: string;
  classes?: any;
  size?: number;
  disabled?: boolean;
  note?: string;
}

interface IState {
  showPassword: boolean;
}
class InputPassword extends React.Component<IOwnProps, IState> {
  constructor(props: IOwnProps) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }
  handleChange = event => {
    const { input } = this.props;
    const val = event.target.value;
    if (input) {
      input.onChange(val);
    }
  };

  handleTogglePassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  render() {
    const {
      label,
      note,
      meta: { error, touched },
      required,
      size,
      classes,
      disabled,
      input,
    } = this.props;
    return (
      <div>
        <div>
          <FormControl className={classes.root}>
            <TextField
              {...input}
              disabled={disabled}
              label={label}
              error={error && touched}
              required={required}
              className={classes.textFieldRoot}
              InputProps={{
                classes: {
                  root: classes.inputPassword,
                  input: classes.textField,
                  underline: classes.underline,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className={classes.showPassword}
                      onClick={this.handleTogglePassword}
                      aria-label={
                        this.state.showPassword
                          ? 'Masquer le mot de passe'
                          : 'Afficher le mot de passe'
                      }
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{ size }}
              onChange={this.handleChange}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabel,
                  focused: classes.textFieldLabelFocused,
                  error: classes.textFieldLabelError,
                  asterisk: classes.textFieldLabelAsterisk,
                },
              }}
              variant="filled"
              type={this.state.showPassword ? 'text' : 'password'}
            />
          </FormControl>
        </div>
        {note && (
          <div>
            <Typography className={classes.caption} variant="caption">
              {note}
            </Typography>
          </div>
        )}
        {error && touched && (
          <div style={{ maxWidth: 300 }}>
            <Typography color="error" variant="caption">
              {error}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(InputPassword);
