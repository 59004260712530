import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Titre from '../../components/titre/Titre';
import Libelle from '../../_i18n/fr.json';
import { Cookies, withCookies } from 'react-cookie';
import styles from './styles';
import {
  AXPETIO_COOKIE_NAME,
  MATOMO_SESSION_NAME,
} from '../../axeptio.injector';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  cookies: Cookies;
}

class ProtectionDonnees extends React.Component<Props> {
  /**
   * Stockage d'un cookie coté client
   */
  setCookies = () => {
    this.props.cookies.set(AXPETIO_COOKIE_NAME, false);
    this.props.cookies.set('performanceIsCheck', false);
    this.props.cookies.set('publicitaireIsCheck', false);

    sessionStorage.setItem(MATOMO_SESSION_NAME, 'false');
  };
  render() {
    return (
      <React.Fragment>
        <Grid container justify="center" className="bigTitle">
          <Titre libelle={Libelle.pageProtection.h1} variant="h1" />
        </Grid>
        <div className={this.props.classes.container}>
          <Grid item spacing={2}>
            <p>
              Dans le cadre des services d’accompagnement et de conseil en
              assurances que nous vous apportons, ainsi que pour l’exécution de
              vos contrats d’assurances, des données personnelles seront
              collectées et traitées par CB.IPL - 135 boulevard Haussmann 75008
              Paris. <br /> <br />
              Plus précisément, le traitement de ces données personnelles nous
              permettra d’analyser efficacement le dossier de candidature, de
              mettre en œuvre la gestion et l’exécution de vos contrats
              d’assurances ainsi que la gestion de vos sinistres et de vos
              indemnisations.. Vos données pourront aussi être utilisées pour
              nous permettre de respecter nos obligations de vigilance (lutte
              contre la fraude, le blanchiment de capitaux et le financement du
              terrorisme). Elles pourront faire l’objet d’analyses et de
              statistiques afin d’améliorer notre accompagnement, nos services
              et nos produits et ainsi optimiser la relation client.
              <br /> <br />
              Ces traitements sont fondés sur l’exécution du contrat qui nous
              lie, le respect de nos obligations légales de connaissance de nos
              clients et de vigilance, ainsi que sur l’intérêt légitime du
              courtier en assurances (bases juridiques).
              <br /> <br />
              Les données requises doivent être impérativement renseignées afin
              que nous puissions gérer vos demandes de souscription et
              l’exécution de vos contrats. En cas de fausses déclarations ou
              d’omissions, les conséquences peuvent être le refus de vos
              souscriptions, la nullité du contrat souscrit ou la réduction des
              garanties.
              <br /> <br />
              Les données seront conservées tant que le contrat est actif
              (éventuellement augmentée de la durée de prescription liée à
              toutes actions découlant de la souscription). En cas de sinistre
              ou de litige, la durée de conservation est prorogée jusqu’à
              l’écoulement des prescriptions desdites actions. Lorsqu’une
              obligation légale nous impose de devoir disposer des données
              personnelles, elles seront conservées aussi longtemps que cette
              obligation légale s’impose à nous. En l’absence de conclusion d’un
              contrat, les données personnelles sont conservées pour une durée
              de 3 ans depuis le dernier contact.
              <br /> <br />
              Dans le cadre susmentionné, les données pourront être communiquées
              aux autres sociétés Bessé, aux assureurs et aux experts, à nos
              sous-traitants (comme nos prestataires informatiques), aux
              autorités publiques et à nos partenaires intermédiaires en
              assurances.
              <br /> <br />
              En outre, certaines données personnelles pourront être utilisées,
              mais avec l’accord exprès du titulaire, par nos partenaires
              commerciaux afin de vous proposer d’autres services et offres
              visant à mieux vous accompagner.
              <br /> <br />
              Conformément à la loi, chaque titulaire peut demander l’accès, la
              rectification, l’effacement ou la portabilité de ses données,
              définir des consignes relatives à leur sort après son décès,
              choisir d’en limiter l’usage ou s’opposer à leur traitement en
              écrivant par courriel à notre Délégué à la Protection des Données
              : <a href="mailto:dpo@besse.fr">dpo@besse.fr</a>. Si le titulaire
              a donné une autorisation expresse à l’utilisation de certaines de
              ses données, il peut de la même manière la retirer à tout moment
              sous réserve qu’il ne s’agisse pas d’informations indispensables à
              l’application de son contrat. En cas de réclamation, le titulaire
              peut choisir de saisir la CNIL.
              <br />
              Le titulaire peut demander à ce que la prise de décision
              automatisée permettant de définir le profil de solvabilité du
              candidat à la location qui est fondée sur un algorithme de
              solvabilité fasse l’objet d’une intervention humaine s’il estime
              que le résultat obtenu est erroné. Pour cela il suffit au
              titulaire d’en faire la demande à{' '}
              <a href="support.vertuloo@besse.fr">support.vertuloo@besse.fr</a>.
              <br /> <br />
              Le traitement des données personnelles est effectué de manière
              sécurisée dans notre système d’information. Nous veillons à
              sensibiliser et former régulièrement nos collaborateurs à la bonne
              préservation des données personnelles.
              <br /> <br />
            </p>
          </Grid>
          <Grid container justify="center">
            <Titre libelle="En savoir plus sur les cookies" variant="h1" />
          </Grid>
          <Grid item spacing={2}>
            <h3>Qu’est-ce qu’un « cookie » ? </h3>

            <p>
              Lorsque vous consultez un site internet, sous réserve de votre
              choix et des paramètres choisis dans votre navigateur, des
              fichiers de données peuvent être déposés temporairement en mémoire
              ou sur un espace dédié du disque dur de votre ordinateur. <br />
              <br />
              Le dépôt et la lecture des cookies permettent au détenteur d’un
              site de notamment mieux comprendre comment les internautes
              interagissent avec le site, d’établir des statistiques de visites,
              d’accéder à des services sécurisés et ainsi améliorer la
              morphologie du site.
              <br />
              La durée de validité d’un cookie est de 6 mois maximum. A l’issue
              de ce délai, le consentement de l’utilisateur sera de nouveau
              sollicité via l’affichage du bandeau d’information. <br />
              <br />
            </p>
            <h3>Quels types de cookies sont utilisés sur le site ?</h3>
            <p>
              Deux types de cookies sont utilisés sur ce site.
              <br />
              <br />
              <strong>Cookies strictement nécessaires : </strong>
              <br />
              Ces cookies sont indispensables pour vous permettre de naviguer au
              sein du site et d’utiliser les fonctionnalités proposées,
              notamment pour accéder aux sections sécurisées.
              <br />
              Sans ces cookies, les services demandées ne pourront pas être
              assurés.
              <br />
              <br />
              <strong>Cookies de performance : </strong>
              <br />
              Ces cookies nous permettent de déterminer le nombre de visites et
              les sources du trafic sur notre site web, afin de mesurer et
              d’améliorer les performances. Ils nous aident également à
              identifier les pages les plus / moins visitées et à évaluer
              comment les visiteurs naviguent sur le site. <br />
              Toutes les informations collectées par ces cookies, sont agrégées
              et donc anonymisées. Si vous n’acceptez pas ces cookies, nous ne
              pourrons pas savoir quand vous aurez réalisé votre visite sur
              notre site web, et nous ne pourrons pas mesurer sa performance.
              <br />
              <br />
              <strong>Comment paramétrer vos cookies ?</strong> <br />
              Le paramétrage des cookies se fait par le bandeau d’information
              qui apparait lors de la 1ère connexion.
              <br />
              Ce bandeau vous sera proposé tous les 6 mois.
              <br />
              Vous pouvez l’afficher de nouveau en cliquant{' '}
              <a href="/" onClick={this.setCookies}>
                ici.
              </a>{' '}
            </p>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withCookies(withStyles(styles)(ProtectionDonnees));
