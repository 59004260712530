import { push } from 'connected-react-router';
import { stopSubmit } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';
import Types from 'Types';
import { ActionType } from 'typesafe-actions';
import { wrapError } from '../../helpers/utils';
import { Selectors as DossierLocationSelector } from '../../redux/DossierLocation';
import { Selectors as PartenaireSelector } from '../../redux/Partenaire';
import { ActionCreators as PiecesActionCreators } from '../../redux/Pieces';
import { Selectors as SituationPersonnelleSelector } from '../../redux/SituationPersonnelle';
import { ActionCreators } from '../../redux/SituationProfessionnelle';
import { IApi } from '../../services/Api';
import { SituationProfessionnelleDossierParticulier } from '../../services/Api/swagger';

export function* getListSituationProfessionnelles(
  api: IApi,
  action: ActionType<
    typeof ActionCreators.getListSituationProfessionnelleAsync.request
  >,
) {
  try {
    const resultats = yield call(api.getListSituationsProfessionnelles);
    yield put(
      ActionCreators.getListSituationProfessionnelleAsync.success(resultats),
    );
  } catch (error) {
    yield put(
      ActionCreators.getListSituationProfessionnelleAsync.failure(error),
    );
  }
}

export function* addUpdateSituationProfessionnelle(
  api: IApi,
  action: ActionType<
    typeof ActionCreators.addOrUpdateSituationProfessionnelleAsync.request
  >,
) {
  try {
    const { principal, secondaire } = action.payload;
    const situationPersonnelle: Types.SituationPersonnelleData = yield select(
      SituationPersonnelleSelector.getSituationPersonnelle,
    );

    // récupération de la référence du dossier
    const refDossierLocation: string = yield select(
      DossierLocationSelector.getSelectedDossier,
    ) || '';

    // récupération de la référence de la personne
    const refDossierParticulierPrincipal =
      principal.reference ||
      (situationPersonnelle &&
        situationPersonnelle.situationPrincipale &&
        situationPersonnelle.situationPrincipale.reference) ||
      '';
    if (
      principal &&
      (principal.statut === 'INCOMPLET' ||
        principal.statut === 'BLOQUE' ||
        principal.statut === '')
    ) {
      yield* addUpdateSituationProfessionnelleUnitaire(
        api,
        principal,
        refDossierLocation,
        refDossierParticulierPrincipal,
        'principal',
      );
    }

    if (
      secondaire &&
      (secondaire.statut === 'INCOMPLET' ||
        secondaire.statut === 'BLOQUE' ||
        secondaire.statut === '')
    ) {
      const refDossierParticulierSecondaire =
        secondaire.reference ||
        (situationPersonnelle &&
          situationPersonnelle.situationConjoint &&
          situationPersonnelle.situationConjoint.reference) ||
        '';

      yield* addUpdateSituationProfessionnelleUnitaire(
        api,
        secondaire,
        refDossierLocation,
        refDossierParticulierSecondaire,
        'secondaire',
      );
    }

    yield put(
      ActionCreators.addOrUpdateSituationProfessionnelleAsync.success(
        action.payload,
      ),
    );
    // vérification si partenaire contrôle les pièces justificatives
    const isControleDossier: boolean = yield select(PartenaireSelector.getControleDossier)
    if (isControleDossier) {
      yield put(push('/dossier-location/pieces-justificatives'));
    } else {
      yield put(push('/dossier-location/synthese'));
    }
  } catch (error) {
    yield put(stopSubmit('situationProfessionnelleForm', error.content));
    yield put(
      ActionCreators.addOrUpdateSituationProfessionnelleAsync.failure(error),
    );
  }
}

export function* addUpdateSituationProfessionnelleUnitaire(
  api: IApi,
  situationProfessionnelle: SituationProfessionnelleDossierParticulier,
  dossierLocationRef: string,
  dossierParticulierRef: string,
  prefixeForm: string,
) {
  try {
    const resultats = yield call(api.putSituationProfessionnelle, {
      dossierLocationRef,
      dossierParticulierRef,
      situationProfessionnelle,
    });
    if (
      resultats.parcoursNetheos != null &&
      resultats.parcoursNetheos.startsWith('CO')
    ) {
      yield put(PiecesActionCreators.saveTokenConjointNetheos(resultats));
    } else {
      yield put(PiecesActionCreators.saveTokenNetheos(resultats));
    }
  } catch (error) {
    const wrappedError = wrapError(error, prefixeForm);
    throw wrappedError;
  }
}
