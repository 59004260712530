import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { ActionCreators } from '../../redux/AdministrateurDeBien';
import { IApi } from '../../services/Api';
import {
  AdministrateurBien,
  AdministrateurBienDetail,
  AdministrateurBienList,
} from '../../services/Api/swagger';

export function* searchAdministrateursDeBiens(
  api: IApi,
  action: ActionType<typeof ActionCreators.search.request>,
) {
  try {
    const result: AdministrateurBienList = yield call(
      api.getAllAdministrateursDeBiens,
      action.payload,
    );
    yield put(ActionCreators.search.success(result));
  } catch (error) {
    yield put(ActionCreators.search.failure(error));
  }
}

export function* getAdbRegroupement(
  api: IApi,
  action: ActionType<typeof ActionCreators.getADBRegroupement.request>,
) {
  try {
    const result: AdministrateurBien[] = yield call(
      api.getAdbRegroupement,
      action.payload,
    );
    yield put(ActionCreators.getADBRegroupement.success(result));
  } catch (error) {
    yield put(ActionCreators.getADBRegroupement.failure(error));
  }
}

export function* getAdbDetail(
  api: IApi,
  action: ActionType<typeof ActionCreators.getADBIdDetail.request>,
) {
  try {
    const result: AdministrateurBienDetail = yield call(
      api.getAdbIdDetail,
      action.payload,
    );

    yield put(ActionCreators.getADBIdDetail.success(result));
  } catch (error) {
    yield put(ActionCreators.getADBIdDetail.failure(error));
  }
}

export function* activerAdb(
  api: IApi,
  action: ActionType<typeof ActionCreators.activerAdb.request>,
) {
  try {
    const result: AdministrateurBienDetail = yield call(
      api.activerAdb,
      action.payload,
    );
    yield put(ActionCreators.activerAdb.success(result));
    // refresh
    yield put(push('/mes-administrateurs-de-biens/consulter'));
  } catch (error) {
    yield put(ActionCreators.activerAdb.failure(error));
  }
}

export function* desactiverAdb(
  api: IApi,
  action: ActionType<typeof ActionCreators.desactiverAdb.request>,
) {
  try {
    const result: AdministrateurBienDetail = yield call(
      api.desactiverAdb,
      action.payload,
    );
    yield put(ActionCreators.desactiverAdb.success(result));
    // refresh
    yield put(push('/mes-administrateurs-de-biens/consulter'));
  } catch (error) {
    yield put(ActionCreators.desactiverAdb.failure(error));
  }
}
