import { withStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';
import defaultStyles from '../styles';
import logoNoir from '../../../../ressources/images/logo_vertuloo_noir.png';
import avantage1 from '../../../../ressources/images/avantage_1.png';
import avantage2 from '../../../../ressources/images/avantage_2.png';
import avantage3 from '../../../../ressources/images/avantage_3.png';
import avantage4 from '../../../../ressources/images/avantage_4.png';
import avantage5 from '../../../../ressources/images/avantage_5.png';
import avantage6 from '../../../../ressources/images/avantage_6.png';
import avantage7 from '../../../../ressources/images/avantage_7.png';
import avantage8 from '../../../../ressources/images/avantage_8.png';
import avantage9 from '../../../../ressources/images/avantage_9.png';
import avantageForme from '../../../../ressources/images/avantage_forme.png';

interface IProps {
  classes?: any;
}

const BlocAvantages = (props: IProps) => (
  <div className={props.classes.containerBg} id="avantages">
    <img src={avantageForme} className={props.classes.forme} height={300} alt="" />
    <div className={props.classes.container}>
      <img src={logoNoir} width={100} alt="immobilier & construction" />
      <h2>vous offre au quotidien,</h2>
      <h3>une plateforme d'avantages</h3>

      <div className={props.classes.containerAvantages}>
        <div className={props.classes.avantage}>
          <h2>Sécurisation</h2>
          <h4>de votre activité</h4>
          <div className={props.classes.containerBloc}>
            {' '}
            <div className={props.classes.bloc}>
              <img
                src={avantage4}
                width={40}
                alt="Sécurisation de l'authenticité des pièces"
              />
              <p>Sécurisation de l'authenticité des pièces</p>
              <span>
                vertuloo a été développé avec les solutions technologiques les
                plus avancées. Elle vous offre ainsi, en temps réel, le
                contrôle, la vérification de conformité et d’authenticité de
                chacune des pièces du dossier.{' '}
              </span>
            </div>{' '}
            <div className={props.classes.bloc}>
              <img src={avantage1} width={40} alt="certification assurance" />
              <p>Certification assurance</p>
              <span>
                A l’issue du processus de traitement, vertuloo vous délivre un
                certificat qui engage l’assureur pour les dossiers mis en
                garantie dans des solutions d’assurance contre le risque
                locatif.
              </span>
            </div>{' '}
            <div className={props.classes.bloc}>
              <img src={avantage7} width={40} alt="locataires garantis" />
              <p>100% des locataires garantis</p>
              <span>
                Pour les lots disposant d’une garantie contre les loyers
                impayés, la plateforme vous délivre immédiatement, une
                certification Assurance qui atteste l’effectivité du contrat
                d’assurance contre les loyers impayés pour 100% des dossiers
                acceptés.{' '}
              </span>
            </div>
          </div>
        </div>
        <div className={props.classes.avantage}>
          <h2>Développement</h2>
          <h4>de votre activité</h4>
          <div className={props.classes.containerBloc}>
            <div className={props.classes.bloc}>
              <img src={avantage2} width={40} alt="propriétaire/bailleur" />
              <p>Renforcement de ma relation avec le propriétaire/bailleur</p>
              <span>
                vertuloo vous donne les moyens de consolider votre relation avec
                le propriétaire/bailleur en mettant en valeur votre
                professionnalisme.{' '}
              </span>
            </div>{' '}
            <div className={props.classes.bloc}>
              <img src={avantage5} width={40} alt="la vacance locative" />
              <p>Diminution de la vacance locative</p>
              <span>
                Le modèle d’analyse de vertuloo vous permet de favoriser
                l’éligibilité au logement pour plus de candidats locataires et
                réduire ainsi les vacances locatives de vos
                propriétaires/bailleurs.
              </span>
            </div>{' '}
            <div className={props.classes.bloc}>
              <img
                src={avantage8}
                width={40}
                alt="Conquête de nouveaux mandats"
              />
              <p>Conquête de nouveaux mandats</p>
              <span>
                vertuloo vous permet de vous enrichir de nouveaux services
                sécurisants et efficients pour contribuer à créer la différence
                face à vos concurrents et vis-à-vis des nouveaux bailleurs qui
                envisagent de vous confier son/ses bien(s) en gestion{' '}
              </span>
            </div>
          </div>
        </div>
        <div className={props.classes.avantage}>
          <h2>Productivité</h2>
          <h4>de votre activité</h4>
          <div className={props.classes.containerBloc}>
            <div className={props.classes.bloc}>
              <img src={avantage3} width={40} alt="Gain de temps" />
              <p>Gain de temps</p>
              <span>
                vertuloo vous offre un véritable gain de temps pour la gestion
                des dossiers. Il simplifie et sécurise la constitution et le
                contrôle des dossiers de vos candidats locataires, libérant
                ainsi les équipes vers des taches à plus forte valeur ajoutée.{' '}
              </span>
            </div>{' '}
            <div className={props.classes.bloc}>
              <img src={avantage6} width={40} alt="dossier" />
              <p>Simplification de la gestion du dossier</p>
              <span>
                vertuloo contribue à améliorer les conditions de travail de vos
                équipes gestion locative en sécurisant leur prestation vis-à-vis
                des propriétaires bailleurs.{' '}
              </span>
            </div>{' '}
            <div className={props.classes.bloc}>
              <img src={avantage9} width={40} alt="mise en location" />
              <p>Accèlere la mise en location</p>
              <span>
                vertuloo vous permet d’accélérer la mise en location en
                orientant plus facilement les candidats vers les biens qui
                correspondent à leur capacité locative tout en préservant leur
                reste à vivre.{' '}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default withStyles(theme => ({
  ...styles(theme),
  ...defaultStyles(theme),
}))(BlocAvantages);
