import React from 'react';
import Layout from '../../components/Layout';
import { Link } from '@material-ui/core';

/* eslint-disable react/prefer-stateless-function */
export default class UnauthorizedPage extends React.PureComponent {
  render() {
    return (
      <Layout title="Accès non autorisé">
        <div
          style={{
            margin: '0 24px',
            padding: '158px 12px 32px 12px',
            textAlign: 'center',
          }}
        >
          <h1>Accès interdit</h1>
          <p>Vous n'êtes pas autorisé à accéder à cette page.</p>
          <Link href="/dashboard">Retour au dashboard</Link>
        </div>
      </Layout>
    );
  }
}
