import { withStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';

interface IProps {
  classes?: any;
}

const BlocBackground = (props: IProps) => (
  <div className={props.classes.container}>
    <div className={props.classes.texte}>
      <h2>On parle de nous</h2>
    </div>
  </div>
);

export default withStyles(styles)(BlocBackground);
