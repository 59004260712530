import React from 'react';
import Layout from '../../components/Layout';
import Blocs from './Blocs';

class LabelisationPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout variant={true} title="Labelisation Finance Innovation">
          <Blocs />
        </Layout>
      </React.Fragment>
    );
  }
}

export default LabelisationPage;
