import cloneDeep from 'lodash/cloneDeep';
import { PageUtilisateurs } from '../../services/Api/swagger';

export const changeUtilisateurStatut = (
  resultList: PageUtilisateurs | undefined,
  username: string,
  actif: boolean,
) => {
  const newResultList = cloneDeep(resultList);
  if (newResultList && newResultList.elements) {
    const elements = newResultList.elements;
    for (let it = 0; it < elements.length; it++) {
      if (elements[it].identifiant === username) {
        elements[it] = {
          ...elements[it],
          actif,
        };
      }
    }
    newResultList.elements = elements;
  }

  return newResultList;
};
