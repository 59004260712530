import { getCookie } from './helpers/utils';
export const AXPETIO_COOKIE_NAME = 'vertulooCookiesIsCheck';
export const MATOMO_SESSION_NAME = 'matomoTrackingEnabled';

const AxeptioInjector = (clientId: string) => {
  const cookie = getCookie(AXPETIO_COOKIE_NAME);
  const parsedCookie = cookie && JSON.parse(cookie);
  sessionStorage.setItem(MATOMO_SESSION_NAME, 'false');
  if (parsedCookie && parsedCookie.Matomo) {
    // Inject Matomo tracking session based on axptio cookie once Matomo is enabled
    sessionStorage.setItem(MATOMO_SESSION_NAME, 'true');
  }

  window._axcb = window._axcb || [];
  window._axcb.push((axeptio: any) => {
    axeptio.on('cookies:complete', (choices: any) => {
      if (choices.Matomo) {
        if (sessionStorage.getItem(MATOMO_SESSION_NAME) === 'false') {
          sessionStorage.setItem(MATOMO_SESSION_NAME, 'true');
          window.location.reload();
        }
      } else {
        if (sessionStorage.getItem(MATOMO_SESSION_NAME) === 'true') {
          sessionStorage.removeItem(MATOMO_SESSION_NAME);
          window.location.reload();
        }
      }
    });
  });

  window.axeptioSettings = {
    clientId,
    cookiesVersion: 'agrement pro-fr',
    jsonCookieName: AXPETIO_COOKIE_NAME,
    userCookiesDuration: 180,
  };
  const element = document.querySelector('body');
  const script = document.createElement('script');
  script.src = '//static.axept.io/sdk-slim.js';
  script.async = true;
  element && element.appendChild(script);
};

export default AxeptioInjector;
