import React from 'react';

// tslint:disable: max-line-length
export default class SituationPersoSVG extends React.Component {
  render() {
    return (
      <svg width="83" height="83" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <circle id="celibataireSVG_1" cx="41.5" cy="41.5" r="41.5" />
          <polygon
            id="celibataireSVG_3"
            points=".123 .197 9.116 .197 9.116 25.638 .123 25.638"
          />
          <polygon
            id="celibataireSVG_5"
            points=".009 .391 35.107 .391 35.107 35.827 .009 35.827"
          />
        </defs>
        <g fill="none">
          <g>
            <use
              id="Mask"
              fill="rgba(92, 81, 117, 0.25)"
              href="#celibataireSVG_1"
            />
          </g>
          <g transform="translate(22.000000, 16.000000)">
            <path
              d="M9.0747,65.717 C12.4237,66.589 15.9357,67.055 19.5577,67.055 C23.2107,67.055 26.7507,66.579 30.1237,65.694 L31.2607,40.253 C29.7637,39.561 28.1217,39.128 26.3897,39.025 L26.0577,39.326 C22.3797,42.665 16.7257,42.521 13.2207,39 C11.2917,39.045 9.4677,39.502 7.8177,40.272 C7.8887,40.259 7.9367,40.253 7.9367,40.253 L9.0747,65.717 Z"
              fill="#FFF"
            />
            <g transform="translate(30.000000, 40.055300)">
              <mask id="celibataireSVG_4" fill="#fff">
                <use href="#celibataireSVG_3" />
              </mask>
              <g />
              <path
                d="M1.2603,0.1973 L0.1233,25.6383 C3.2843,24.8083 6.2973,23.6143 9.1163,22.1053 L9.1163,12.4683 C9.1163,7.0233 5.8973,2.3423 1.2603,0.1973"
                fill="#000"
                mask="url(#celibataireSVG_4)"
              />
            </g>
            <path
              d="M7.937,40.2526 C7.937,40.2526 7.889,40.2586 7.818,40.2716 C3.203,42.4256 0,47.0936 0,52.5236 L0,62.1606 C2.843,63.6836 5.883,64.8866 9.075,65.7166 L7.937,40.2526 Z"
              fill="#000"
            />
            <g transform="translate(2.000000, 0.000000)">
              <mask id="mask-6" fill="#fff">
                <use href="#celibataireSVG_5" />
              </mask>
              <g />
              <path
                d="M17.5581,0.3907 C7.8661,0.3907 0.0091,8.3237 0.0091,18.1087 C0.0091,27.8937 7.8661,35.8267 17.5581,35.8267 C27.2501,35.8267 35.1071,27.8937 35.1071,18.1087 C35.1071,8.3237 27.2501,0.3907 17.5581,0.3907"
                fill="#FFF"
                mask="url(#mask-6)"
              />
            </g>
            <path
              d="M20.3608,47.5041 C20.3608,47.9471 20.0018,48.3071 19.5578,48.3071 C19.1148,48.3071 18.7558,47.9471 18.7558,47.5041 C18.7558,47.0611 19.1148,46.7011 19.5578,46.7011 C20.0018,46.7011 20.3608,47.0611 20.3608,47.5041"
              fill="#000"
            />
            <path
              d="M20.3608,55.9099 C20.3608,56.3529 20.0018,56.7129 19.5578,56.7129 C19.1148,56.7129 18.7558,56.3529 18.7558,55.9099 C18.7558,55.4669 19.1148,55.1069 19.5578,55.1069 C20.0018,55.1069 20.3608,55.4669 20.3608,55.9099"
              fill="#000"
            />
          </g>
        </g>
      </svg>
    );
  }
}
// tslint:enable: max-line-length
