import { Theme } from '@material-ui/core/styles';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
  ({
    headerContainer: {
      width: '100%',
      position: 'absolute',
    },
    logoContainer: {
      display: 'flex',
    },
    header: {
      height: 72,
      display: 'flex',
      justifyContent: 'space-between',
      margin: 'auto',
      marginTop: 48,
      maxWidth: '80%',
      background: '#FFF',
      width: '100%',
    },
    headerContainerAuthenticated: {
      width: '100%',
      boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
    },
    headerAuthenticated: {
      height: 72,
      display: 'flex',
      justifyContent: 'space-between',
      margin: 'auto',
      maxWidth: 1080,
      background: '#FFF',
      width: '100%',
    },
    logo: {
      boxSizing: 'content-box',
      padding: 8,
      width: 174,
      height: 87,
      boxShadow:
        ' 0 1px 3px 2px rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 2px rgba(0, 0, 0, 0.14)',
      background: '#FFF',
      '& img': {
        width: 174,
      },
    },
    logoLab: {
      display: 'flex',
      justifyContent: 'flex-start',
      paddingLeft: 12,
      '& img': {
        width: 100,
      },
    },
    logoAuthenticated: {
      marginTop: 16,
    },
    menu: {
      alignItems: 'center',
      display: 'flex',
      '& a': {
        textTransform: 'uppercase',
        color: '#000',
        textDecoration: 'none',
        padding: '0 24px',
        fontWeight: 600,
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
    liensResponsive: {
      alignItems: 'center',
      display: 'flex',
      '& a, & span': {
        textTransform: 'uppercase',
        color: '#000',
        textDecoration: 'none',
        fontWeight: 600,
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
    menuNormal: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: '100%',
      '& a': {
        textTransform: 'uppercase',
        color: '#000',
        textDecoration: 'none',
        padding: '0 24px',
        fontWeight: 600,
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
    activeLink: {
      color: theme.palette.primary.main + ' !important',
    },
    connexionContainer: {
      margin: 12,
      borderLeft: '1px solid ' + theme.palette.primary.main,
      '& button': {
        padding: 0,
        marginLeft: 15,
        textTransform: 'initial',
        lineHeight: '19px',
        fontSize: 15,
        fontWeight: 500,
        background: 'transparent',
        color: theme.palette.primary.main,
        textAlign: 'right',
        '&:hover, &:active, &:focus': {
          background: 'transparent',
          color: '#000',
        },
        '& img': {
          paddingLeft: 8,
        },
      },
    },
    connexionAuthenticated: {
      display: 'flex',
    },
    identifiant: {
      display: 'flex',
      alignItems: 'center',
      '& p': {
        margin: 0,
      },
      '& svg': {
        margin: '0 12px',
      },
      '& small': {
        maxWidth: '160px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'block',
        whiteSpace: 'nowrap',
      },
    },
    menuResponsive: {
      display: 'none',
    },
    logoV: {
      marginRight: 16,
    },
    '@media all and (max-width: 789px)': {
      menuResponsive: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        marginRight: 16,
      },
      menuNormal: {
        display: 'none',
      },
    },
    '@media all and (max-width: 500px)': {
      header: {
        marginTop: 0,
        maxWidth: '100%',
        width: '100%',
      },
      logo: {
        padding: '6px 16px',
        width: 'inherit',
        height: 'auto',
        boxShadow: 'none',
        '& img': {
          width: 107,
        },
      },
    },
  } as any);
// tslint:enable: quotemark

export default styles;
