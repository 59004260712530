import {
  Button,
  CircularProgress,
  IconButton,
  Link,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import MoreVert from '@material-ui/icons/MoreVert';
import classnames from 'classnames';
import React from 'react';
import { isValidationPossible } from '../../helpers/validators';
import BienIcon from '../../ressources/images/BienIcon.svg';
import BienIconRefus from '../../ressources/images/BienIconRefus.svg';
import InputSwitch from '../inputSwitch';
import Statut from '../Statut';
import styles from './styles';
import { Profil } from '../../services/Api/swagger';
import Snack from '../Snack';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  // Type de données à afficher (Administrateurs de Biens ou Dossier de location)
  type: 'adb' | 'utilisateurs' | 'dossiersLocation';
  // Liste d'élément à afficher
  donneesTableau: any;
  alerteEnCoursSuppression: boolean;

  // Fonction executée au clic sur sur une ligne
  onConsulter(idLigne: string | number): void;

  // Fonction suppression
  onDelete(idLigne: string | number): void;

  // generer certificat d'agrement
  onGenerer(idLigne: string | number);
  onChangeStatut?(id, statut): void;

  isChargeClienteleBesse?: boolean;

  onSetDuplicationDossier?(ref: string): void;

  onSetAccepterDossier?(ref: string): void;

  onSetRefuserDossier?(ref: string): void;

  onSetAccepterRefuserDossier?(dossierLocationItem: any): void;

  isAccepterRefuserLocationLoading?: boolean;
  canAcceptRefuseLocation?: boolean;
  infoProfil?: Profil;
  genererLoading?: boolean;
}

interface State {
  // Etat d'ouverture du menu
  menuOpen: string;
  // Gestion du menu
  anchorEl?: null | HTMLButtonElement;
  accepterOuRefuser: string;
  alerteEnCoursSuppression: boolean;
  messageEnCoursSuppressionDejaAffiche: boolean;
}

class Body extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      menuOpen: '',
      anchorEl: null,
      accepterOuRefuser: '',
      alerteEnCoursSuppression: false,
      messageEnCoursSuppressionDejaAffiche: false,
    };
  }

  /**
   * Ouverture du menu
   */
  handleOpen = (e, id) => {
    this.setState({
      menuOpen: id,
      anchorEl: e.currentTarget,
    });
  };

  /**
   * Fermeture du menu
   */
  handleClose = () => {
    this.setState({
      menuOpen: '',
      anchorEl: null,
      alerteEnCoursSuppression: false,
    });
  };

  handleAccepter = (onSetAccepterDossier, ref) => {
    this.setState({
      accepterOuRefuser: 'Accepter',
    });
    onSetAccepterDossier(ref);
  };

  handleRefuser = (onSetRefuserDossier, ref) => {
    this.setState({
      accepterOuRefuser: 'Refuser',
    });
    onSetRefuserDossier(ref);
  };

  isLigneBleue = (alerteEnCoursSuppression, n) => {
    if (
      alerteEnCoursSuppression &&
      !this.state.messageEnCoursSuppressionDejaAffiche
    ) {
      this.setState({
        alerteEnCoursSuppression: true,
        messageEnCoursSuppressionDejaAffiche: true,
      });
    }
    if (n.enCoursSuppression) {
      return true;
    }
    return false;
  };

  render() {
    const {
      classes,
      donneesTableau,
      onConsulter,
      type,
      onChangeStatut,
      isChargeClienteleBesse,
      onSetDuplicationDossier,
      onSetAccepterDossier,
      onSetRefuserDossier,
      isAccepterRefuserLocationLoading,
      onSetAccepterRefuserDossier,
      canAcceptRefuseLocation,
      infoProfil,
      onDelete,
      onGenerer,
      genererLoading,
    } = this.props;
    const { anchorEl, menuOpen } = this.state;
    return (
      <TableBody>
        <Snack
          onClose={this.handleClose}
          open={this.state.alerteEnCoursSuppression}
          variant="error"
          message="Les dossiers de location écrits en bleu seront supprimés dans moins de 30 jours"
        />
        {donneesTableau.map(n => {
          return (
            <TableRow
              key={n.identifiant}
              hover={true}
              className={classnames(
                this.isLigneBleue(this.props.alerteEnCoursSuppression, n)
                  ? classes.ligneTableauBleu
                  : classes.ligneTableau,
                {
                  disabled: type === 'utilisateurs' && !n.actif,
                },
              )}
            >
              {type === 'adb' && (
                /**
                 * Administrateurs de biens
                 */
                <React.Fragment>
                  <TableCell onClick={() => onConsulter(n.identifiant)}>
                    {n.identifiant}
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.identifiant)}>
                    {n.nom}
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.identifiant)}>
                    {n.adresse2 ? n.adresse1 + ' - ' + n.adresse2 : n.adresse1}
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.identifiant)}>
                    {n.codePostal}
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.identifiant)}>
                    {n.ville}
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.identifiant)}>
                    {n.commercial
                      ? n.commercial.nom + ' ' + n.commercial.prenom
                      : ''}
                  </TableCell>
                  <TableCell className="ignoreDisable">
                    {n.actif && <Check />}
                  </TableCell>
                </React.Fragment>
              )}
              {type === 'dossiersLocation' && (
                /**
                 * Dossier de location
                 */
                <React.Fragment>
                  <TableCell onClick={() => onConsulter(n.refLocation)}>
                    {n.site}
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.refLocation)}>
                    {n.refLocation}
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.refLocation)}>
                    {n.refMandat}
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.refLocation)}>
                    {n.numeroLot}
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.refLocation)}>
                    {n.ville}
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.refLocation)}>
                    {n.adresse}
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.refLocation)}>
                    {n.loyerCharges !== 0 && n.loyerCharges + '€'}
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.refLocation)}>
                    {n.loyerMaxConseille && n.loyerMaxConseille + '€'}
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.refLocation)}>
                    {n.locReferent}
                  </TableCell>
                  <TableCell
                    style={{ wordBreak: 'inherit' }}
                    onClick={() => onConsulter(n.refLocation)}
                  >
                    <div className={n.gli === 'Non' ? 'gliNon' : 'gliOui'}>
                      {n.gli}
                    </div>
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.refLocation)}>
                    <Statut statut={n.statut} />
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.refLocation)} className='enPlace'>
                    {n.locationAcceptee === 2 && (
                      <img
                        src={BienIconRefus}
                        width="22"
                        height="22"
                        alt="location refusée"
                      />
                    )}
                    {n.locationAcceptee === 1 && (
                      <img
                        src={BienIcon}
                        width="22"
                        height="22"
                        alt="location acceptée"
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-controls={'menu-actions' + n.refLocation}
                      aria-haspopup="true"
                      onClick={e => this.handleOpen(e, n.refLocation)}
                      aria-label='Actions sur le dossier'
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      id={'menu-actions' + n.refLocation}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={n.refLocation === menuOpen}
                      onClose={this.handleClose}
                      className={classes.menuTableau}
                      classes={{ paper: classes.menuTableau }}
                    >
                      <MenuItem
                        component={Link}
                        onClick={() => onConsulter(n.refLocation)}
                      >
                        Consulter / Modifier
                      </MenuItem>
                      {infoProfil &&
                        infoProfil.fonctions &&
                        infoProfil.fonctions.find(
                          f => f === 'TDBDOSLOC_SUPPRESSION',
                        ) &&
                        (n.statut === 'INCOMPLET' ||
                          n.statut === 'NON_VALIDE') && (
                          <MenuItem
                            onClick={() => {
                              onDelete(n.refLocation);
                            }}
                          >
                            {' '}
                            Supprimer le dossier
                          </MenuItem>
                        )}
                      {n.locationAcceptee && n.statut === 'VALIDE_AGREE' && (
                        <MenuItem
                          onClick={() => {
                            onGenerer(n.refLocation);
                          }}
                        >
                          {' '}
                          Génerer certificat d'agrement
                          {genererLoading && (
                            <CircularProgress
                              className={classes.menuSpinner}
                              size={17}
                            />
                          )}
                        </MenuItem>
                      )}
                      {(n.statut === 'VALIDE' ||
                        n.statut === 'VALIDE_AGREE' ||
                        n.statut === 'VALIDE_AGREE_DIFFERE' ||
                        n.statut === 'VALIDE_NON_AGREE') &&
                        (!isValidationPossible(n.dateDemandeValidation) ? (
                          <Tooltip title="Ce dossier a dépassé la delai pour la duplication">
                            <p className={classes.disabledButton}>Dupliquer</p>
                          </Tooltip>
                        ) : (
                          <Link
                            className={classes.button}
                            component={Button}
                            onClick={() =>
                              onSetDuplicationDossier &&
                              onSetDuplicationDossier(n.refLocation)
                            }
                          >
                            Dupliquer
                          </Link>
                        ))}
                      {(n.statut === 'VALIDE' ||
                        n.statut === 'VALIDE_AGREE' ||
                        n.statut === 'VALIDE_AGREE_DIFFERE' ||
                        n.statut === 'VALIDE_NON_AGREE') &&
                        (canAcceptRefuseLocation &&
                          n.locationAcceptee !== 0 &&
                          n.locationAcceptee !== null) && (
                          <MenuItem
                            component={Link}
                            onClick={() =>
                              onSetAccepterRefuserDossier &&
                              onSetAccepterRefuserDossier(n)
                            }
                          >
                            {n.locationAcceptee === 1
                              ? 'Refuser location'
                              : 'Accepter location'}

                            {isAccepterRefuserLocationLoading && (
                              <CircularProgress
                                className={classes.menuSpinner}
                                size={17}
                              />
                            )}
                          </MenuItem>
                        )}
                      {(n.statut === 'VALIDE_AGREE' ||
                        n.statut === 'VALIDE_AGREE_DIFFERE') &&
                        (canAcceptRefuseLocation &&
                          (n.locationAcceptee === 0 ||
                            n.locationAcceptee === null)) && (
                          <MenuItem
                            component={Link}
                            onClick={() =>
                              this.handleAccepter(
                                onSetAccepterDossier && onSetAccepterDossier,
                                n.refLocation,
                              )
                            }
                          >
                            Accepter location
                            {isAccepterRefuserLocationLoading &&
                              this.state.accepterOuRefuser === 'Accepter' && (
                                <CircularProgress
                                  className={classes.menuSpinner}
                                  size={17}
                                />
                              )}
                          </MenuItem>
                        )}
                      {(n.statut === 'VALIDE' ||
                        n.statut === 'VALIDE_AGREE' ||
                        n.statut === 'VALIDE_AGREE_DIFFERE' ||
                        n.statut === 'VALIDE_NON_AGREE') &&
                        (canAcceptRefuseLocation &&
                          (n.locationAcceptee === 0 ||
                            n.locationAcceptee === null)) && (
                          <MenuItem
                            component={Link}
                            onClick={() =>
                              this.handleRefuser(
                                onSetRefuserDossier && onSetRefuserDossier,
                                n.refLocation,
                              )
                            }
                          >
                            Refuser location
                            {isAccepterRefuserLocationLoading &&
                              this.state.accepterOuRefuser === 'Refuser' && (
                                <CircularProgress
                                  className={classes.menuSpinner}
                                  size={17}
                                />
                              )}
                          </MenuItem>
                        )}
                    </Menu>
                  </TableCell>
                </React.Fragment>
              )}
              {type === 'utilisateurs' && (
                /**
                 * Utilisateurs
                 */
                <React.Fragment>
                  {n.numAdherent && (
                    <TableCell onClick={() => onConsulter(n.identifiant)}>
                      {n.numAdherent}
                    </TableCell>
                  )}
                  <TableCell onClick={() => onConsulter(n.identifiant)}>
                    {n.nom}
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.identifiant)}>
                    {n.prenom}
                  </TableCell>
                  <TableCell onClick={() => onConsulter(n.identifiant)}>
                    {n.mail}
                  </TableCell>
                  {n.fonction && (
                    <TableCell onClick={() => onConsulter(n.identifiant)}>
                      {n.fonction.libelle}
                    </TableCell>
                  )}
                  <TableCell onClick={() => onConsulter(n.identifiant)}>
                    {n.profil && n.profil}
                  </TableCell>
                  <TableCell className="ignoreDisable">
                    <InputSwitch
                      disabled={isChargeClienteleBesse}
                      id={n.identifiant}
                      onChange={onChangeStatut}
                      value={n.actif}
                      label="Actif"
                    />
                  </TableCell>
                </React.Fragment>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }
}

export default withStyles(styles)(Body);
