import { Bien, BienDetail } from '../../../services/Api/swagger';

export interface IBienFormData {
  numeroBatiment?: string;
  typeVoie?: string;
  numero?: string;
  adresse?: string;
  codePostalVille?: any;
  numeroLot?: string;
  typeBien?: string;
  meuble?: boolean;
  superficie?: number;
  nombrePieces?: string;
  loyerMensuel?: number;
  chargesMensuelles?: number;
  garantieLi?: 'Avec' | 'Sans';
  referenceMandat?: string;
  nomProprietaire?: string;
  prenomProprietaire?: string;
  mailProprietaire?: string;
  telephoneFixeProprietaire?: string;
  telephonePortableProprietaire?: string;
}

export interface IBienFormError {
  identifiant?: string;
  numeroBatiment?: string;
  typeVoie?: string;
  numero?: string;
  adresse?: string;
  codePostalVille?: string;
  numeroLot?: string;
  typeBien?: string;
  meuble?: string;
  superficie?: string;
  nombrePieces?: string;
  loyerMensuel?: string;
  chargesMensuelles?: string;
  garantieLi?: string;
  referenceMandat?: string;
  nomProprietaire?: string;
  prenomProprietaire?: string;
  mailProprietaire?: string;
  telephoneFixeProprietaire?: string;
  telephonePortableProprietaire?: string;
}

export const copyBienFormToModel = (bienForm: IBienFormData): Bien => {
  const bien = {
    ...bienForm,
    codePostal:
      bienForm.codePostalVille && bienForm.codePostalVille.value.split('-')[0],
    ville:
      bienForm.codePostalVille && bienForm.codePostalVille.value.split('-')[1],
    garantieLi:
      bienForm.garantieLi && bienForm.garantieLi === 'Avec' ? true : false,
  } as Bien;

  return bien;
};

export const copyBienDetailModelToForm = (
  bienDetail: BienDetail,
): IBienFormData => {
  let bienForm = {} as IBienFormData;
  if (bienDetail) {
    const bien = copyBienDetailToBien(bienDetail);
    bienForm = {
      ...bien,
      codePostalVille: bien.codePostal &&
        bien.ville && {
          value: bien.codePostal + '-' + bien.ville,
          label: bien.ville + '(' + bien.codePostal + ')',
        },
      garantieLi: bien.garantieLi ? 'Avec' : 'Sans',
    } as IBienFormData;
  }
  return bienForm;
};

export const copyBienDetailToBien = (bienDetail: BienDetail) => {
  const { dateCreation, dateModification, ...bien } = bienDetail;
  return bien;
};

export const copyBienToBienDetail = (bien: Bien) => {
  const bienDetail = { ...bien } as BienDetail;
  return bienDetail;
};
