import { withStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';
import defaultStyles from '../styles';
import imgVertuloo from '../../../../ressources/images/photo_vertuloo.png';
import pointsVert from '../../../../ressources/images/points-vert.png';

interface IProps {
  classes?: any;
}

const BlocQuiSommesNous = (props: IProps) => (
  <div className={props.classes.container}>
    <h3>
      Découvrez <span className="vertuloo-font-md vert">vertuloo</span>,<br />
      <strong>la nouvelle plateforme digitale de référence</strong>
      <br />
      développée par <span className={props.classes.uppercase}>Bessé</span>{' '}
      Immobilier & Construction
    </h3>
    <div className={props.classes.containerTexte}>
      <div className={props.classes.texte}>
        <div className={props.classes.divider} />
        <p>
          <span className="vertuloo-font-sm vert">vertuloo</span> est la réponse
          aux besoins exprimés par les administrateurs de biens pour la
          sécurisation et le développement de leur activité de gestion locative.
          <br />
          <br />
          Dédiée aux administrateurs de biens, cette plateforme offre une
          solution qui s’appuie sur les technologies les plus avancées, de
          nouveaux modèles d’analyses et des données macroéconomiques et
          sociologiques actualisées en temps réel.
        </p>
      </div>
      <div className={props.classes.containerImage}>
        <img
          src={pointsVert}
          className={props.classes.dots}
          alt="immobilier"
          width={70}
        />
        <img src={imgVertuloo} width="400" alt="Vertuloo" />
        <div className={props.classes.rectangle} />
      </div>
    </div>
  </div>
);

export default withStyles(theme => ({
  ...styles(theme),
  ...defaultStyles(theme),
}))(BlocQuiSommesNous);
