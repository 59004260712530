import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Tableau from '../../../../components/tableau/Tableau';
import styles from '../../styles';
import { AdministrateurBien } from '../../../../services/Api/swagger';
import { adbTableauHeader } from '../tableauHeader';

interface Props {
  classes?: any; // Surcharge de style CSS injecté par withStyles
  page: number;
  nbrElementParPage: number;
  sortColumn: string;
  sortOrder: 'asc' | 'desc';
  searchCount: number;
  searchResults: AdministrateurBien[];

  onChangeNbrElementParPage(nbrElementParPage: number): void;

  onChangePage(page: number): void;

  onChangeTri(sortColumn: string, sortOrder: string): void;

  onConsulter(id: number): void;

  onDelete(id: number): void;

  onGenerer(id: number);
}

interface State {
  message: string; // Message  (Erreur / Information)
}

class ResultatsRecherche extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      message: '',
    };
  }

  handleTrier = (event, colonneId) => {
    event.preventDefault();
    let order = 'asc';
    if (this.props.sortOrder === 'asc') {
      order = 'desc';
    }
    this.props.onChangeTri(colonneId, order);
  };

  handleChangerPage = (event, page) => {
    event.preventDefault();
    this.props.onChangePage(page);
  };

  handlechangerNbrResultatParPage = (event, nbrElement) => {
    event.preventDefault();
    this.props.onChangeNbrElementParPage(nbrElement);
  };

  handleConsulter = (id: number) => {
    this.props.onConsulter(id);
  };

  onDelete = ref => {};
  onGenerer = ref => {};

  render() {
    const {
      nbrElementParPage,
      page,
      searchResults,
      searchCount,
      sortColumn,
      sortOrder,
    } = this.props;
    return (
      <Grid container>
        <Tableau
          isPaginer={true}
          page={page}
          type="adb"
          headerDonnees={adbTableauHeader}
          trierPar={sortColumn}
          ordreDeTrie={sortOrder}
          donneesTableau={searchResults}
          alerteEnCoursSuppression={false}
          nbrElementTotal={searchCount}
          nbrElementParPage={nbrElementParPage}
          onTrier={this.handleTrier}
          onConsulter={this.handleConsulter}
          onDelete={this.onDelete}
          onGenerer={this.onGenerer}
          onChangerPage={this.handleChangerPage}
          onChangerNbrResultatParPage={this.handlechangerNbrResultatParPage}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(ResultatsRecherche);
