import { ConfigProvider } from '@bc/react-config';
import 'core-js/stable'; // Needed for redux-saga es6 generator support
import 'regenerator-runtime/runtime'; // Needed for redux-saga es6 generator support
import 'url-polyfill';
import 'whatwg-fetch'; // Fetch polyfill
import { CircularProgress } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import ErrorBoundary from './components/ErrorBoundary';
import ReduxProvider from './components/ReduxProvider';
import Routes from './containers/Routes';
import './index.css';
import { handleConfigLoaded } from './services/Authentification/authentification';
import theme from './theme';

// Locale par defaut : FR
moment.locale('fr');

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  // tslint:disable-next-line: jsx-wrap-multiline
  <ErrorBoundary>
    <ConfigProvider
      onLoad={handleConfigLoaded}
      loading={<CircularProgress />}
      url={window.location.origin + '/conf/config.json'}
    >
      <CookiesProvider>
        <ReduxProvider>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Routes />
          </MuiThemeProvider>
        </ReduxProvider>
      </CookiesProvider>
    </ConfigProvider>
  </ErrorBoundary>,
  MOUNT_NODE,
);
