import { Theme } from '@material-ui/core/styles';
import { bleu, gris1 } from '../../../theme';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
({
  container: {
    textAlign: 'center',
    maxWidth: '880px',
    padding: '48px 12px 48px 12px',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    '& h4': {
      fontSize: 15,
      marginTop: 0,
    },
  },
  containerFlex: {
    display: 'flex',
    maxWidth: '880px',
    padding: '48px 12px 48px 12px',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    '& h4': {
      fontSize: 15,
    },
  },
  iframeYoutube: {
    borderRadius: '4px',
    border: 0,
    marginRight: 24,
    zIndex: 10,
    marginBottom: 12,
  },
  grosRond: {
    zIndex: -1,
    width: 200,
    height: 200,
    borderRadius: '100%',
    background: 'rgba(102, 153, 0, 0.3)',
    position: 'absolute',
    top: 332,
    left: 9,
  },
  moyenRond: {
    zIndex: -1,
    width: 150,
    height: 150,
    borderRadius: '100%',
    background: 'rgba(102, 153, 0, 0.3)',
    position: 'absolute',
    top: 254,
    right: 302,
  },
  divider: {
    height: 2,
    background: theme.palette.primary.main,
    maxWidth: 260,
    width: '30%',
    margin: 'auto',
  },
  description: {
    maxWidth: 400,
    marginLeft: 24,
  },
  cookieConsent: {
    background: '#F6F6F6',
    borderRadius: 4,
    alignItems: 'center',
    padding: 18,
  },
  cookieButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& a': {
      marginRight: 12,
      color: theme.palette.primary.main,
    },
  },
  linkPlus: {
    textDecoration: 'none',
    color: '#FFF',
    borderRadius: 20,
    padding: '8px 16px 10px 16px',
    fontWeight: '600',
    fontSize: 13,
    boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.4)',
    background: bleu,
    '&:hover, &:focus, &:active': {
      background: '#012644',
    },
  },
  date: {
    fontSize: 13,
    color: gris1,
    marginTop: 0,
    fontWeight: 'normal',
    marginBottom: 4,
  },
  '@media all and (max-width: 1000px)': {
    container: {
      flexDirection: 'column',
      '& iframe': {
        marginRight: 0,
        marginBottom: 24,
      },
    },
  },
  '@media all and (max-width: 650px)': {
    iframeYoutube: {
      width: 480,
      height: 280,
    },
  },
  '@media all and (max-width: 500px)': {
    iframeYoutube: {
      width: 420,
      height: 230,
    },
  },
} as any);
// tslint:enable: quotemark

export default styles;
