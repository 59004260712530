import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Libelle from '../../_i18n/fr.json';
import styles from './styles';
import { HeaderDonnees } from './Tableau';

export function HeaderTableauFromJSON(json: any): HeaderDonnees {
  return {
    id: json['id'],
    idTriable: json['idTriable'] || null,
    libelle: json['libelle'],
    isTriable: json['isTriable'],
  };
}
interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  // Identifiant de la colonne de trie
  trierPar: string;
  // Direction du trie asc ou desc
  ordreDeTrie: 'asc' | 'desc';
  // Fonction pour trier
  onTrier: (event, idColonne) => void;
  headerDonnees: HeaderDonnees[];
}

class Header extends React.Component<Props> {
  render() {
    const {
      classes,
      trierPar,
      ordreDeTrie,
      onTrier,
      headerDonnees,
    } = this.props;

    // FIXME Passer plutôt par une méthode de render du header
    const dataHeader = headerDonnees.map(HeaderTableauFromJSON);

    return (
      <TableHead>
        <TableRow>
          {dataHeader.map(n => {
            const id = n.idTriable ? n.idTriable : n.id;
            return (
              <TableCell
                key={n.id}
                component="th"
                scope="row"
                className={classes.header}
              >
                {n.isTriable ? (
                  <Tooltip title={Libelle.tableau.header.trier + n.libelle}>
                    <TableSortLabel
                      className={classes.headerSort}
                      active={trierPar === id}
                      direction={ordreDeTrie}
                      onClick={event => onTrier(event, id)}
                    >
                      {n.libelle}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  <React.Fragment>{n.libelle}</React.Fragment>
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
}

export default withStyles(styles)(Header);
