import React from 'react';
import { Helmet } from 'react-helmet';

interface IProps {
  title?: string;
  noFollow?: boolean;
  matomoTag?: string;
}

const MetaDatas = (props: IProps) => (
  <Helmet>
    <meta charSet="utf-8" />
    {(window.location.hostname !== 'www.vertuloo.fr' || props.noFollow) && (
      <meta name="robots" content={'noindex,nofollow'} />
    )}
    <meta
      name="viewport"
      content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
    />

    <link
      href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500"
      rel="stylesheet"
    />
    <link rel="icon" href="../../favicon.ico?v=2" />
    <title>{props.title || 'Vertuloo'}</title>
    {props.matomoTag &&
      sessionStorage.getItem('matomoTrackingEnabled') === 'true' && (
        <script type="text/javascript">
          {`
        window._paq = window._paq || [];
        window._paq.push(['trackPageView']);
        window._paq.push(['enableLinkTracking']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://pbcanalytics.cabinet-besse.net/js/container_${props.matomoTag
            }.js'; s.parentNode.insertBefore(g,s);
      `}
        </script>
      )}
  </Helmet>
);
export default MetaDatas;
