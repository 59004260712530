import { withStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';
import defaultStyles from '../styles';
import logoVert from '../../../../ressources/images/logo_vertuloo_vert.png';
import fonction1 from '../../../../ressources/images/fonction_1.png';
import fonction2 from '../../../../ressources/images/fonction_2.png';
import fonction3 from '../../../../ressources/images/fonction_3.png';
import fonction4 from '../../../../ressources/images/fonction_4.png';

interface IProps {
  classes?: any;
  cookiesValid: boolean;
}

class BlocFonctions extends React.Component<IProps> {

  onClickEvent = () => {
    if (window.MatomoTagManager) {
      window._paq.push([
        'trackEvent',
        'Fonctions principales',
        'Clic - CTA',
        'Accueil - Fonctions principales',
      ]);
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <img src={logoVert} width={100} alt="immobilier & construction" />
        <h2>Les fonctions principales</h2>
        <h3>pour sécuriser votre portefeuille locatif</h3>
        <div className={classes.divider} />

        <div className={classes.containerFonctions}>
          <div className={classes.fonction}>
            <img src={fonction1} width={50} alt="controle automatisé" />
            <p>Contrôle automatisé de l'authenticité et de la conformité</p>
            <span>des pièces du dossier de candidature de locataire.</span>
          </div>
          <div className={classes.fonction}>
            <img src={fonction2} width={50} alt="analyse pragmatique" />
            <p>
              Analyse pragmatique et sécurisée de la solvabilité des candidats.
            </p>
          </div>
          <div className={classes.fonction}>
            <img
              src={fonction3}
              width={50}
              alt="outil administrateurs de biens "
            />
            <p>
              Outil d'aide pour éclairer la prise de décisions de
              l'administrateur de biens
            </p>
          </div>
          <div className={classes.fonction}>
            <img src={fonction4} width={25} alt="certification assurance" />
            <p>Certification assurance contre le risque de loyers impayés</p>
            <span>
              qui atteste l'effectivité du contrat pour 100% des dossiers
              acceptés
            </span>
          </div>
        </div>
        <a
          href="/en-savoir-plus/#fonctions"
          className={classes.linkPlus}
          onClick={this.onClickEvent}
        >
          en savoir plus
        </a>
      </div>
    );
  }
}

export default withStyles(theme => ({
  ...styles(theme),
  ...defaultStyles(theme),
}))(BlocFonctions);
