import { Grid, Theme, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import Bouton from '../../components/bouton/Bouton';
import Layout from '../../components/Layout';
import { history } from '../../helpers/history';
import Libelle from '../../_i18n/fr.json';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
}

const styles = (theme: Theme) =>
  ({
    pageIntrouvable: {
      "textAlign": 'center',
      '& h1': {
        letterSpacing: 2,
        fontSize: 42,
        padding: 24,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
      },
      '& h2': {
        paddingBottom: 12,
      },
    },
    caption: {
      marginTop: 24,
      margin: 12,
    },
  } as any);

class PageIntrouvable extends React.Component<Props> {
  onBackHome = () => {
    history.push('/');
  };
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Layout title={Libelle.pageIntrouvable.titre}>
          <Grid
            className={classes.pageIntrouvable}
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Typography variant="h1">OUPS !</Typography>
            <Typography variant="h2">{Libelle.pageIntrouvable.h1}</Typography>
            <Grid item>{Libelle.pageIntrouvable.libelle}</Grid>
            <Typography variant="caption" className={classes.caption}>
              Vous pouvez vérifier votre URL ou retourner à la page d'accueil
            </Typography>
            <Bouton
              onClick={this.onBackHome}
              label="Retour à la page d'accueil"
              color="primary"
            />
          </Grid>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PageIntrouvable);
