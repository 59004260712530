import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Titre from '../../../components/titre/Titre';
import Libelle from '../../../_i18n/fr.json';
import styles from './styles';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
}

class MentionsLegales extends React.Component<Props> {
  render() {
    // <Link to="/protection-des-donnees">
    //   {Libelle.pageMentionsLegales.rgpd}
    // </Link>;
    const { classes } = this.props;
    return (
      <>
        <Grid container justify="center" className="bigTitle">
          <Titre libelle={Libelle.pageMentionsLegales.h1} variant="h1" />
        </Grid>
        <div className={classes.container}>
          {' '}
          <Grid item spacing={2}>
            <Titre
              libelle="IDENTIFICATION « POUR LE SITE DE VERTULOO »"
              variant="h4"
            />

            <p>
              VERTULOO est une marque de <strong>CB.IPL</strong>
            </p>
            <p>
              Ce site est édité par CB.IPL, société par actions simplifiées au
              capital de 75 450 euros, immatriculée au RCS de Paris sous le
              n°433 869 427, dont le siège social est situé 135 Boulevard
              Haussmann 75008 Paris.
              <br />
              <br />
              Conseil et courtier en assurances (exerçant conformément aux
              dispositions de l’article L521-2-1°b) du Code des Assurances - La
              liste des fournisseurs actifs est indiquée au point 10).
              Immatriculation à l’Organisme pour le Registre des Intermédiaires
              en Assurances (ORIAS) sous le numéro 07 019 245 – www.orias.fr.{' '}
              <strong>CB.IPL</strong> est soumis au contrôle de l’Autorité de
              Contrôle Prudentiel et de Résolution (ACPR), 4 place de Budapest
              75009 Paris.
              <br />
              <br />
              Directeur de la publication : Monsieur Pierre BESSÉ. <br />
              Responsable de la rédaction et hébergeur : GIE INFO 2, 46 bis rue
              des Hauts Pavés - 44000 Nantes <br />
              <br />
              Traitement des réclamations et Médiation : retrouvez toutes les
              informations sur le site{' '}
              <a href="http://www.besse.fr/">www.besse.fr</a>
            </p>
            <Titre libelle="CONDITIONS D'UTILISATION" variant="h4" />
            <p>
              Nous vous recommandons de lire attentivement les présentes
              conditions d’utilisation ("les Conditions") et de les consulter de
              manière régulière car elles sont susceptibles d’être modifiées.
              <br />
              <br />
              En accédant à ce site, vous acceptez d’être lié(e) par ces
              Conditions, et notamment par notre politique en matière de gestion
              des <a href="/protection-des-donnees">données personnelles</a>. Si
              vous n’acceptez pas d’être lié(e) par ces Conditions, vous ne
              devez pas accéder au présent site ni en consulter les pages.
            </p>
            <ul className={classes.firstList}>
              <li>
                Bien que <strong>CB.IPL</strong> ait pris soin de s’assurer que
                les informations présentées sur le site soient correctes au
                moment de leur mise en ligne, <strong>CB.IPL</strong> ne
                garantit aucunement leur exactitude et leur exhaustivité et ne
                saurait en conséquence être tenu responsable d’éventuelles
                erreurs, inexactitudes ou omissions les concernant. Sans aucune
                garantie de quelque nature que ce soit quant à son contenu.{' '}
                <strong>CB.IPL</strong> ne pourra être tenu responsable de tout
                dommage lié au contenu, à l’utilisation du site ou à tout
                message qui pourrait être envoyé via le site.
                <br />
                La responsabilité de <strong>CB.IPL</strong> ne pourra pas non
                plus être recherchée en cas de panne, inaccessibilité,
                défaillance, bug ou difficulté de fonctionnement de tout ou
                partie du site.
              </li>
              <li>
                <strong>CB.IPL</strong> est le propriétaire exclusif ou détient
                les droits d’usage de tous les éléments constituant le site et
                de tous les droits de propriété intellectuelle qui y sont
                attachés, ce qui inclut sans limitation : les marques, les
                logos, les noms de domaine, les photographies, les
                illustrations, les images animées ou non, les films, les
                graphismes, les commentaires, les textes, les sons, les
                applications informatiques, les logiciels. L’ensemble de ces
                éléments est protégé par la loi.
                <br />
                Toute représentation, utilisation, reproduction, exploitation,
                adaptation, modification, publication ou distribution de tout ou
                partie du site et des droits qui y sont attachés, faites sans le
                consentement préalable, exprès et écrit de{' '}
                <strong>CB.IPL</strong>
                , est illicite et constituera une contrefaçon quel que soient
                les supports et les moyens utilisés.
                <br />
                Aucun manquement ou retard de <strong>CB.IPL</strong> à engager
                une quelconque procédure pour faire respecter ses droits, ne
                saurait être considéré ou constituer une renonciation ou une
                renonciation ultérieure à exercer un tel droit.
              </li>
              <li>
                Il est strictement interdit de rendre tout ou partie de ce site
                disponible sur un autre site, que ce soit par l’utilisation d’un
                hyperlien ou de toute autre manière, à moins d’y avoir été
                expressément autorisé au préalable et par écrit par{' '}
                <strong>CB.IPL</strong>. Pour obtenir une autorisation, vous
                devez envoyer soit un courriel soit un courrier postal à{' '}
                <strong>CB.IPL</strong> aux coordonnées mentionnées ci-dessous
                au point 8. avec les informations suivantes :<br />
                <ul className={classes.secondList}>
                  <li>
                    Votre nom ou le nom du contact technique ayant la
                    responsabilité des liens,
                  </li>
                  <li>Le nom de votre société,</li>
                  <li>
                    Votre adresse mèl et votre numéro de téléphone ou l’adresse
                    mèl et le numéro de téléphone du contact technique
                    responsable des liens,
                  </li>
                  <li>
                    L’adresse ou les adresses des sites sur lesquels les liens
                    vers la page d’accueil figureront.
                  </li>
                </ul>
              </li>
              <li>
                Ce site peut contenir des liens hypertextes vers d’autres sites,
                mis en place avec l’autorisation de <strong>CB.IPL</strong>.
                Néanmoins, <strong>CB.IPL</strong> ne contrôle en aucune manière
                le contenu de ces sites et n’assumera en conséquence aucune
                responsabilité liée à ces sites.
              </li>
              <li>
                <strong>CB.IPL</strong> se réserve le droit à tout moment et
                sans avis préalable d'améliorer, de modifier, de suspendre ou de
                cesser de manière permanente tout ou partie du site et de
                restreindre ou d’interdire l’accès à ce dernier.
              </li>
              <li>
                Les informations contenues dans le site ne constituent en aucun
                cas des conseils, une offre commerciale, une offre à contracter
                ou des avis juridiques. Pour des raisons de sécurité, il est
                prudent de considérer qu'une demande de garantie d'assurance, de
                prise d'effet de garantie, ou de déclaration de sinistre, n'aura
                été prise en compte qu'à partir du moment où elle aura donné
                lieu à une réponse personnalisée du destinataire (à l'exclusion
                donc de tout procédé automatique d'accusé de réception ou de
                lecture). A défaut, votre message devra être considéré comme non
                reçu.
              </li>
              <li>
                Les présentes Conditions peuvent être modifiées à tout moment
                par <strong>CB.IPL</strong>. L’utilisation de ce site à la suite
                de ces changements emporte votre acceptation de ces derniers.
              </li>
              <li>
                Pour toute question ou demande que vous pourriez avoir en
                relation avec le présent site, les présentes Conditions ou sur{' '}
                <strong>CB.IPL</strong>, vous pouvez nous écrire par courriel à
                l’adresse suivante :{' '}
                <a href="mailto:support.vertuloo@besse.fr">
                  support.vertuloo@besse.fr
                </a>{' '}
                ou par courrier postal à l’adresse suivante :{' '}
                <strong>CB.IPL</strong>, 46 bis rue des Hauts Pavés BP 80205
                44002 Nantes cedex 01.
              </li>
              <li>
                Les présentes Conditions et le contenu du présent site sont
                régis par la loi française dont les tribunaux ont compétence
                exclusive dans le règlement de tout litige. En cas de litige
                concernant un consommateur non professionnel, conformément à L
                133-4 du Code de la consommation, vous disposez de la faculté de
                saisir un médiateur. Liste des médiateurs disponible sur simple
                demande à l’adresse indiquée au point 8.
              </li>
              <li>
                Liste des fournisseurs actifs disponible sur{' '}
                <a href="http://www.besse.fr/">www.besse.fr</a>
              </li>
            </ul>
          </Grid>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(MentionsLegales);
