import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from './styles';
import cx from 'classnames';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  // Libelle à afficher
  libelle: string;
  // Titre cache ?
  isVisible?: boolean;
  // Niveau du titre
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

/**
 * Composant affichant les titres.
 */
class Titre extends React.PureComponent<Props> {
  /**
   * Valeurs d'initialisation
   */
  public static defaultProps = {
    isVisible: true,
  };

  render() {
    const { classes, libelle, variant, isVisible } = this.props;
    return (
      <Typography
        variant={variant}
        component={variant}
        className={cx(classes, classes.root, {
          'acc-hidden-title': !isVisible,
        })}
        dangerouslySetInnerHTML={{ __html: libelle }}
      />
    );
  }
}
export default withStyles(styles)(Titre);
