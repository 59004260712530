import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { ActionCreators as AdbActionCreators } from '../redux/AdministrateurDeBien';
import { ActionCreators as BienActionCreators } from '../redux/Bien';
import { ActionCreators as DossierLocationActionCreators } from '../redux/DossierLocation';
import { ActionCreators as LocalisationActionCreators } from '../redux/Localisation';
import { ActionCreators as NousContacterActionCreators } from '../redux/NousContacterRedux';
import { ActionCreators as PartenaireActionCreators } from '../redux/Partenaire';
import { ActionCreators as PiecesActionCreators } from '../redux/Pieces';
import { ActionCreators as SituationPersonnelleActionCreators } from '../redux/SituationPersonnelle';
import { ActionCreators as SituationProfessionnelleCreators } from '../redux/SituationProfessionnelle';
import { ActionCreators as TypeVoieActionCreators } from '../redux/TypeVoie';
import { ActionCreators as UtilisateursActionCreators } from '../redux/Utilisateurs';
import Api, { IApi } from '../services/Api';
import { getApiEndpoint } from '../services/Config';
import {
  activerAdb,
  desactiverAdb,
  getAdbDetail,
  getAdbRegroupement,
  searchAdministrateursDeBiens,
} from './AdministrateurDeBien';
import {
  lancerChargementBien,
  lancerCreationBien,
  lancerModificationBien,
  loadNombrePieces,
  loadTypeBienAsync,
} from './Bien';
import {
  accepterDossier,
  addOrUpdateDossierLocation,
  annulerAcceptationDossier,
  calculLoyerMax,
  dupliquerDossier,
  forcerStatutDossier,
  getArchiveNetheos,
  getDossiersLocation,
  getRapportAlgo,
  getSyntheseDossier,
  redirectionPageCreationBien,
  rouvrirDossierNetheos,
  deleteDossierLocation,
  genererCertification,
} from './DossierLocation';
import {
  lancerChangementLocalisation,
  lancerRecupererListTypeVoie,
} from './Localisation';
import { postNousContacter } from './NousContacter';
import { getPartenaireParCode } from './Partenaire';
import { lancerCreationOuModificationSituationPersonnelle } from './SituationPersonnelle';
import {
  addUpdateSituationProfessionnelle,
  getListSituationProfessionnelles,
} from './SituationProfessionnelle';
import {
  activerCompteUtilisateur,
  activerUtilisateur,
  ajouterUtilisateur,
  desactiverUtilisateur,
  getAllUtilisateursAdb,
  getAllUtilisateursBesse,
  getFonctionsPostes,
  getProfilsBesse,
  getUtilisateurByUsername,
  getUtilisateurConnecteByUsername,
  modifierUtilisateur,
} from './Utilisateurs';

// Instanciation de l'API

export default function* root() {
  const api: IApi = Api.create(getApiEndpoint() || 'http://localhost:8090/api');
  yield all([
    takeLatest(
      getType(AdbActionCreators.search.request),
      searchAdministrateursDeBiens,
      api,
    ),
    takeLatest(
      getType(AdbActionCreators.getADBRegroupement.request),
      getAdbRegroupement,
      api,
    ),
    takeEvery(
      getType(AdbActionCreators.getADBIdDetail.request),
      getAdbDetail,
      api,
    ),
    takeLatest(getType(AdbActionCreators.activerAdb.request), activerAdb, api),
    takeLatest(
      getType(AdbActionCreators.desactiverAdb.request),
      desactiverAdb,
      api,
    ),
    takeLatest(
      getType(
        SituationPersonnelleActionCreators
          .createOrUpdateSituationPersonnelleAsync.request,
      ),
      lancerCreationOuModificationSituationPersonnelle,
      api,
    ),
    takeLatest(
      getType(LocalisationActionCreators.loadLocalisationsAsync.request),
      lancerChangementLocalisation,
      api,
    ),
    takeLatest(
      getType(TypeVoieActionCreators.loadTypeVoieAsync.request),
      lancerRecupererListTypeVoie,
      api,
    ),
    takeLatest(
      getType(UtilisateursActionCreators.getFonctionsPostes.request),
      getFonctionsPostes,
      api,
    ),
    takeLatest(
      getType(UtilisateursActionCreators.ajouterUtilisateur.request),
      ajouterUtilisateur,
      api,
    ),
    takeLatest(
      getType(UtilisateursActionCreators.getUtilisateurByUsername.request),
      getUtilisateurByUsername,
      api,
    ),
    takeLatest(
      getType(
        UtilisateursActionCreators.getUtilisateurConnecteByUsername.request,
      ),
      getUtilisateurConnecteByUsername,
      api,
    ),
    takeLatest(
      getType(UtilisateursActionCreators.getProfilsBesse.request),
      getProfilsBesse,
      api,
    ),
    takeLatest(
      getType(UtilisateursActionCreators.getAllUtilisateursBesse.request),
      getAllUtilisateursBesse,
      api,
    ),
    takeLatest(
      getType(UtilisateursActionCreators.getAllUtilisateursAdb.request),
      getAllUtilisateursAdb,
      api,
    ),
    takeLatest(
      getType(UtilisateursActionCreators.desactiverUtilisateur.request),
      desactiverUtilisateur,
      api,
    ),
    takeLatest(
      getType(UtilisateursActionCreators.activerUtilisateur.request),
      activerUtilisateur,
      api,
    ),
    takeLatest(
      getType(UtilisateursActionCreators.modifierUtilisateur.request),
      modifierUtilisateur,
      api,
    ),
    takeLatest(
      getType(UtilisateursActionCreators.activerCompteUtilisateur.request),
      activerCompteUtilisateur,
      api,
    ),
    takeLatest(
      getType(BienActionCreators.addBienAsync.request),
      lancerCreationBien,
      api,
    ),
    takeLatest(
      getType(BienActionCreators.updateBienAsync.request),
      lancerModificationBien,
      api,
    ),
    takeLatest(
      getType(BienActionCreators.lancerChargementBien.request),
      lancerChargementBien,
      api,
    ),
    takeLatest(
      getType(BienActionCreators.loadTypeBienAsync.request),
      loadTypeBienAsync,
      api,
    ),
    takeLatest(
      getType(BienActionCreators.loadNombrePieces.request),
      loadNombrePieces,
      api,
    ),
    takeLatest(
      getType(DossierLocationActionCreators.redirectSelectAdbSitePage),
      redirectionPageCreationBien,
      api,
    ),
    takeLatest(
      getType(
        DossierLocationActionCreators.createOrUpdateDossierLocationAsync
          .request,
      ),
      addOrUpdateDossierLocation,
      api,
    ),
    takeLatest(
      getType(DossierLocationActionCreators.getDossiersLocation.request),
      getDossiersLocation,
      api,
    ),
    takeLatest(
      getType(
        SituationProfessionnelleCreators.getListSituationProfessionnelleAsync
          .request,
      ),
      getListSituationProfessionnelles,
      api,
    ),
    takeLatest(
      getType(
        SituationProfessionnelleCreators
          .addOrUpdateSituationProfessionnelleAsync.request,
      ),
      addUpdateSituationProfessionnelle,
      api,
    ),
    takeLatest(
      getType(DossierLocationActionCreators.getSyntheseDossier.request),
      getSyntheseDossier,
      api,
    ),
    takeLatest(
      getType(PiecesActionCreators.getArchiveNetheos.request),
      getArchiveNetheos,
      api,
    ),
    takeLatest(
      getType(DossierLocationActionCreators.getRapportAlgo.request),
      getRapportAlgo,
      api,
    ),
    takeLatest(
      getType(PiecesActionCreators.rouvrirDossierNetheos.request),
      rouvrirDossierNetheos,
      api,
    ),
    takeLatest(
      getType(DossierLocationActionCreators.calculLoyerMax.request),
      calculLoyerMax,
      api,
    ),
    takeLatest(
      getType(DossierLocationActionCreators.dupliquerDossier.request),
      dupliquerDossier,
      api,
    ),
    takeLatest(
      getType(DossierLocationActionCreators.accepterDossier.request),
      accepterDossier,
      api,
    ),
    takeLatest(
      getType(DossierLocationActionCreators.annulerAcceptationDossier.request),
      annulerAcceptationDossier,
      api,
    ),
    takeLatest(
      getType(DossierLocationActionCreators.forcerStatutDossier.request),
      forcerStatutDossier,
      api,
    ),
    takeLatest(
      getType(NousContacterActionCreators.postNousContacter.request),
      postNousContacter,
      api,
    ),
    takeLatest(
      getType(DossierLocationActionCreators.deleteDossierLocation.request),
      deleteDossierLocation,
      api,
    ),
    takeLatest(
      getType(DossierLocationActionCreators.genererCertification.request),
      genererCertification,
      api,
    ),
    takeLatest(
      getType(PartenaireActionCreators.getPartenaireParCode.request),
      getPartenaireParCode,
      api,
    ),
  ]);
}
