import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    container: {
      width: '100%',
      background: theme.palette.primary.main,
      padding: '16px',
      textAlign: 'center',
      '& a': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '& > span': {
          width: 'fit-content',
          maxWidth: 276,
          margin: 'auto',
          color: theme.palette.primary.main,
          background: 'white',
          borderRadius: 53,
          padding: '8px 62px 10px 62px',
          fontWeight: '600',
          lineHeight: '18px',
          fontSize: 16,
          boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.4)',
          textTransform: 'initial',
          display: 'flex',
          flexDirection: 'column',
          '&:hover, &:focus, &:active': {
            background: 'white',
            boxShadow: 'inset 0px 1px 1px rgba(0, 0, 0, 0.4)',
          },
        },
      },
    },
    contentTexte: {
      border: '1px solid ' + theme.palette.primary.main,
      padding: '24px',
      margin: '24px auto',
      maxWidth: 880,
      fontSize: 16,
      color: '#494949',
      '& p': {
        margin: 0,
      },
      '& ul': {
        marginBottom: 16,
        '& li': {
          paddingLeft: 24,
          position: 'relative',
          '&:before': {
            content: '""',
            width: 3,
            height: 3,
            background: '#808080',
            position: 'absolute',
            borderRadius: '100%',
            top: 10,
            left: 12,
          },
        },
      },
    },
  } as any);

export default styles;
