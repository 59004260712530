import { Theme } from '@material-ui/core/styles';
import { couleurPrimaire } from '../../../../theme';

const styles = (theme: Theme) =>
  ({
    containerBg: {
      position: 'relative',
      padding: '48px 0',
      width: '100%',
      background: 'rgba(102, 153, 0, 0.3)',
    },
    container: {
      margin: 'auto',
      maxWidth: '880px',
      textAlign: 'center',
      '& h2': {
        fontSize: 26,
        margin: 0,
      },
      '& h3': {
        textTransform: 'initial',
        fontSize: 14,
      },
      '& h4': {
        margin: 0,
      },
    },
    containerYoutube: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 24,
    },

    temoignage: {
      height: 'calc(100% - 20px)',
      margin: '16px',
    },
    containerBloc: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '2px solid ' + couleurPrimaire,
      padding: 26,
      height: '100%',
      marginTop: 8,
      '& h3': {
        fontSize: 17,
        fontWeight: '600',
        margin: 0,
        marginBottom: 4,
      },
      '& p': {
        fontSize: 13,
        margin: 0,
        marginBottom: 4,
      },
      '& a': {
        display: 'block',
        marginTop: 8,
      },
    },
    arrow: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },
    '@media all and (max-width: 789px)': {
      containerYoutube: {
        display: 'block',
      },
      temoignage: {
        width: '80%',
        margin: '16px auto',
      },
    },
  } as any);

export default styles;
