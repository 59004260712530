import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import Error from '@material-ui/icons/Error';
import React from 'react';
import { erreur } from '../../theme';

interface Props {
  classes?: any;
  onClose(): void;
  open: boolean;
  variant: string;
  message: string;
}
const styles = (theme: Theme) =>
  ({
    icon: {
      fontSize: 20,
      marginRight: 12,
    },
    successIcon: {
      color: theme.palette.primary.main,
    },
    errorIcon: {
      color: erreur,
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  } as any);
// tslint:enable: quotemark

const Snack = (props: Props) => (
  <Snackbar
    className={props.classes.anchorOrigin}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    open={props.open}
    onClose={props.onClose}
  >
    <SnackbarContent
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={props.classes.message}>
          {props.variant === 'success' && (
            <CheckCircle
              className={props.classes.icon + ' ' + props.classes.successIcon}
            />
          )}
          {props.variant === 'error' && (
            <Error
              className={props.classes.icon + ' ' + props.classes.errorIcon}
            />
          )}
          {props.message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={props.onClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  </Snackbar>
);

export default withStyles(styles)(Snack);
