import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import styles from './styles';

export interface IProps {
  id: string;
  value?: boolean;
  label: string;
  onChange?(id: string, checked: boolean): void;
  classes?: any;
  disabled?: boolean;
}

export interface IState {
  checked?: boolean;
}

class InputSwitch extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      checked: props.value && props.value,
    };
  }

  handleChange = e => {
    this.setState({
      checked: e.target.checked,
    });
    if (this.props.onChange) {
      this.props.onChange(this.props.id, e.target.checked);
    }
  };

  render() {
    const { label, classes, disabled } = this.props;
    return (
      <>
        <Switch
          disabled={disabled}
          checked={this.state.checked}
          onChange={this.handleChange}
          value={this.state.checked}
          inputProps={{ 'aria-label': label }}
          classes={{
            root: classes.root,
            track: classes.track,
            thumb: classes.thumb,
            checked: classes.checked,
            switchBase: classes.switchBase,
          }}
        />
        {label && <span>{label}</span>}
      </>
    );
  }
}

export default withStyles(styles)(InputSwitch);
