import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import React from 'react';
import Body from './Body';
import Header from './Header';
import Pagination from './Pagination';
import styles from './styles';
import { Profil } from '../../services/Api/swagger';

export interface HeaderDonnees {
  id: string;
  idTriable?: string;
  libelle: string;
  isTriable: boolean;
}
interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  // Tableau : Fonction executée au clic sur une ligne
  onConsulter(id: string | number): void;
  // Tableau : Data permettant de peupler le tableau
  donneesTableau: any;
  alerteEnCoursSuppression: boolean;
  // Données a afficher dans le header
  headerDonnees: HeaderDonnees[];
  // Tableau : Type de données à afficher (Administrateurs de Biens ou Dossier de location)
  type: 'adb' | 'utilisateurs' | 'dossiersLocation';
  // Trie : Identifiant de la colonne de trie
  trierPar: string;
  // Trie : Direction du trie asc ou desc
  ordreDeTrie: 'asc' | 'desc';
  // Trie : Fonction executée au clic sur un bandeau du header
  onTrier: (event, idColonne) => void;

  // Pagination : Afficher / cacher
  isPaginer: boolean;
  // Pagination : Numero de page en cours
  page: number;
  // Pagination : Nombre de resultat total
  nbrElementTotal: number;
  // Pagination : Nombre d'élément par page
  nbrElementParPage: number;
  // Pagination : Liste des options représentant le nombre d'élément souhaitant être affiché par page
  nombreElementParPageOptions?: number[];
  // Pagination : Fonction executée au clic sur la pagination
  onChangerPage: (event, page) => void;
  // Pagination : Fonction executée au clic sur le nombre d'élément par page
  onChangerNbrResultatParPage: (event, nbrElement) => void;
  onChangeStatut?(id, statut): void;
  isChargeClienteleBesse?: boolean;
  isAccepterRefuserLocationLoading?: boolean;
  onSetDuplicationDossier?(ref: string): void;
  onSetAccepterDossier?(ref: string): void;
  onSetRefuserDossier?(ref: string): void;
  onSetAccepterRefuserDossier?(dossierLocationItem: any): void;
  canAcceptRefuseLocation?: boolean;
  infoProfil?: Profil;
  onDelete(id: string | number): void;
  onGenerer(id: string | number);
  genererLoading?: boolean;
}

/**
 * Composant affichant un Tableau.
 */
class Tableau extends React.Component<Props> {
  render() {
    const {
      isPaginer,
      onConsulter,
      onTrier,
      donneesTableau,
      alerteEnCoursSuppression,
      classes,
      trierPar,
      ordreDeTrie,
      nbrElementTotal,
      nbrElementParPage,
      page,
      onChangerPage,
      onChangerNbrResultatParPage,
      nombreElementParPageOptions,
      headerDonnees,
      type,
      onChangeStatut,
      isChargeClienteleBesse,
      onSetDuplicationDossier,
      onSetAccepterDossier,
      onSetRefuserDossier,
      onSetAccepterRefuserDossier,
      isAccepterRefuserLocationLoading,
      canAcceptRefuseLocation,
      infoProfil,
      onDelete,
      onGenerer,
      genererLoading,
    } = this.props;

    return (
      <Table className={classes.tableau}>
        <Header
          headerDonnees={headerDonnees}
          trierPar={trierPar}
          ordreDeTrie={ordreDeTrie}
          onTrier={onTrier}
        />
        <Body
          donneesTableau={donneesTableau}
          alerteEnCoursSuppression={alerteEnCoursSuppression}
          onConsulter={onConsulter}
          onGenerer={onGenerer}
          onDelete={onDelete}
          type={type}
          onChangeStatut={onChangeStatut}
          isChargeClienteleBesse={isChargeClienteleBesse}
          onSetDuplicationDossier={onSetDuplicationDossier}
          onSetAccepterDossier={onSetAccepterDossier}
          onSetRefuserDossier={onSetRefuserDossier}
          onSetAccepterRefuserDossier={onSetAccepterRefuserDossier}
          isAccepterRefuserLocationLoading={isAccepterRefuserLocationLoading}
          genererLoading={genererLoading}
          canAcceptRefuseLocation={canAcceptRefuseLocation}
          infoProfil={infoProfil}
        />
        {isPaginer && (
          <Pagination
            page={page}
            colSpan={headerDonnees.length - 1}
            nbrElementTotal={nbrElementTotal}
            nbrElementParPage={nbrElementParPage}
            nbrElementParPageOptions={nombreElementParPageOptions}
            onChangerPage={onChangerPage}
            onChangerNbrResultatParPage={onChangerNbrResultatParPage}
          />
        )}
      </Table>
    );
  }
}

export default withStyles(styles)(Tableau);
