import { AxiosResponse, AxiosInstance } from 'axios';

import {
    PartenaireApiFactory, Partenaire,
} from './swagger';

export interface IApi {
    getPartenaireParCode(code: string): Promise<Partenaire>;
}

const create = (endpoint: string, axiosClient: AxiosInstance) => {
    const partenaireApi = PartenaireApiFactory(
        {},
        endpoint,
        axiosClient,
    );

    const getPartenaireParCode = (code: string): Promise<Partenaire> => {
        const promise: Promise<Partenaire> = new Promise(
            (resolve, reject) => {
                partenaireApi
                    .getPartenaireParCode(
                        code,
                        {},
                    )
                    .then((response: AxiosResponse<Partenaire>) => {
                        resolve(response.data);
                    })
                    .catch(error => reject(error));
            },
        );
        return promise;
    };

    return {
        getPartenaireParCode,
    };
};

export default create;
