import { Grid, withStyles } from '@material-ui/core';
import memoizeOne from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import AutoComplete from '../../../components/AutoComplete';
import FormButtons from '../../../components/FormButtons';
import FormContainer from '../../../components/FormContainer';
import InputField from '../../../components/input/InputField';
import InputCheckbox from '../../../components/inputCheckbox/InputCheckbox';
import InputRadio from '../../../components/inputRadio/InputRadio';
import Select from '../../../components/select/Select';
import { isAlphaNumeric, isInteger } from '../../../helpers/validators';
import { Selectors as DossierLocationSelectors } from '../../../redux/DossierLocation';
import {
  Commune,
  NombrePieces,
  TypeBien,
  TypeVoie,
} from '../../../services/Api/swagger';
import ErreursConstante from '../../../_constantes/erreursConstantes.json';
import Libelle from '../../../_i18n/fr.json';
import {
  copyBienDetailModelToForm,
  IBienFormData,
  IBienFormError,
} from './helpers';
import styles from './style';

interface Props {
  handleSubmit: void;
  formValues: IBienFormData;
  formErrors?: any;
  classes?: any;
  typesVoie: TypeVoie[];
  typesBien: TypeBien[];
  nombrePieces: NombrePieces[];
  onChangeBien: any;
  isFetching: boolean;
  onPrecedent: any;

  onChangeCommune?(commune: Commune): void;

  communes: Commune[];
}

const construireTypeVoieOptions = (typesVoie: TypeVoie[]) => {
  return typesVoie
    ? typesVoie.map(s => ({ key: s.code, text: s.libelle, value: s.code }))
    : [];
};

const memoizeConstruireTypesVoieOptions = memoizeOne(construireTypeVoieOptions);

const construireTypesBienOptions = (typesBien: TypeBien[]) => {
  return typesBien
    ? typesBien.map(s => ({ key: s.code, text: s.libelle, value: s.code }))
    : [];
};

const memoizeConstruireTypesBienOptions = memoizeOne(
  construireTypesBienOptions,
);

const construireNombrePiecesOptions = (nombrePieces: NombrePieces[]) => {
  return nombrePieces
    ? nombrePieces.map(s => ({ key: s.code, text: s.libelle, value: s.code }))
    : [];
};

const memoizeConstruireNombrePiecesOptions = memoizeOne(
  construireNombrePiecesOptions,
);

class BienForm extends React.Component<
  InjectedFormProps<IBienFormData> & Props
> {
  onChangeReferenceMandat = value => {
    const { formValues } = this.props;
    const { referenceMandat, numeroLot } = formValues;
    if (value && value !== referenceMandat && numeroLot) {
      this.preRemplirFormulaire(value, numeroLot);
    }
  };

  onChangeNumeroLot = value => {
    const { formValues } = this.props;
    const { referenceMandat, numeroLot } = formValues;
    if (value && value !== numeroLot && referenceMandat) {
      this.preRemplirFormulaire(referenceMandat, value);
    }
  };

  preRemplirFormulaire = (referenceMandat: string, numeroLot: string) => {
    const { onChangeBien } = this.props;
    if (referenceMandat && numeroLot) {
      onChangeBien(referenceMandat, numeroLot);
    }
  };

  render() {
    const {
      handleSubmit,
      typesVoie,
      typesBien,
      nombrePieces,
      isFetching,
      onPrecedent,
    } = this.props;

    const typesBienOptions = memoizeConstruireTypesBienOptions(typesBien);
    const typesVoiesOptions = memoizeConstruireTypesVoieOptions(typesVoie);
    const nombrePiecesOptions = memoizeConstruireNombrePiecesOptions(
      nombrePieces,
    );

    return (
      <form onSubmit={handleSubmit} noValidate>
        <h3>{Libelle.pageBien.sections.section1}</h3>
        <FormContainer>
          <Grid item xs={3}>
            <Field
              name="referenceMandat"
              component={InputField}
              onChange={this.onChangeReferenceMandat}
              label="Référence Mandat"
              maxLength={20}
              required
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              name="numeroLot"
              component={InputField}
              onChange={this.onChangeNumeroLot}
              label="Numéro de lot"
              maxLength={20}
              required
            />
          </Grid>
        </FormContainer>
        <h3>{Libelle.pageBien.sections.section2}</h3>
        <FormContainer>
          <Grid item xs={2}>
            <Field
              name="numero"
              component={InputField}
              label="N° voie"
              required
              maxLength={10}
              type="text"
            />
          </Grid>

          <Grid item xs={3}>
            <Field
              name="typeVoie"
              component={Select}
              options={typesVoiesOptions}
              label="Type de voie"
              required
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              name="adresse"
              component={InputField}
              label="Nom de la voie"
              maxLength={40}
              required
            />
          </Grid>
          <Grid item xs={2}>
            <Field
              name="numeroBatiment"
              component={InputField}
              label="N° du Batiment"
              maxLength={4}
            />
          </Grid>
          <Grid item xs={2} />

          <Grid item>
            <Field
              name="codePostalVille"
              required
              component={AutoComplete}
              onChangeSelection={this.props.onChangeCommune}
              label={'Code postal / ville'}
              options={this.props.communes || []}
            />
          </Grid>
        </FormContainer>

        <h3>{Libelle.pageBien.sections.section3}</h3>
        <FormContainer>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Field
                name="typeBien"
                component={Select}
                options={typesBienOptions}
                label="Type de logement"
                required
                type="text"
              />
            </Grid>
            <Grid item xs={2}>
              <Field
                name="superficie"
                component={InputField}
                label="Superficie"
                max={999}
                required
                type="number"
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="nombrePieces"
                component={Select}
                options={nombrePiecesOptions}
                label="Nombre de pièces"
                required
                type="text"
              />
            </Grid>
            <Grid item xs={2}>
              <Field
                name="loyerMensuel"
                component={InputField}
                label="Loyer"
                required
                type="number"
                max={9999}
              />
            </Grid>
            <Grid item xs={2}>
              <Field
                name="chargesMensuelles"
                component={InputField}
                label="Charges"
                max={9999}
                required
                type="number"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <Field
                name="meuble"
                component={InputCheckbox}
                label="Meublé"
                required
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="garantieLi"
                component={InputRadio}
                items={[
                  { value: 'Avec', label: 'Avec' },
                  { value: 'Sans', label: 'Sans' },
                ]}
                label="Garantie Loyers impayés"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="typeResidence"
                component={InputRadio}
                items={[
                  { value: 'PRINCIPALE', label: 'Principale' },
                  { value: 'SECONDAIRE', label: 'Secondaire' },
                ]}
                label="Pour une résidence"
                required
              />
            </Grid>
          </Grid>
        </FormContainer>
        <FormButtons onPrecedent={onPrecedent} fetching={isFetching} />
      </form>
    );
  }
}

const validateForm = (values: IBienFormData): IBienFormError => {
  const errors: IBienFormError = {} as IBienFormError;
  if (!values.referenceMandat) {
    errors.referenceMandat =
      ErreursConstante.formulaire.generale.champObligatoire;
  }
  if (!values.numeroLot) {
    errors.numeroLot = ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (!values.typeVoie) {
    errors.typeVoie = ErreursConstante.formulaire.generale.champObligatoire;
  }
  if (!values.numero) {
    errors.numero = ErreursConstante.formulaire.generale.champObligatoire;
  } else if (!isAlphaNumeric(values.numero)) {
    errors.numero = ErreursConstante.formulaire.generale.formatAlphaNumeric;
  }

  if (values.numeroBatiment && !isAlphaNumeric(values.numeroBatiment)) {
    errors.numeroBatiment =
      ErreursConstante.formulaire.generale.formatAlphaNumeric;
  }
  if (!values.adresse) {
    errors.adresse = ErreursConstante.formulaire.generale.champObligatoire;
  }
  if (!values.codePostalVille) {
    errors.codePostalVille =
      ErreursConstante.formulaire.generale.champObligatoire;
  }

  if (!values.typeBien) {
    errors.typeBien = ErreursConstante.formulaire.generale.champObligatoire;
  }
  if (!values.superficie) {
    errors.superficie = ErreursConstante.formulaire.generale.champObligatoire;
  } else if (values.superficie && !isInteger(values.superficie)) {
    errors.superficie = ErreursConstante.formulaire.generale.champEntierErreur;
  }
  if (!values.nombrePieces) {
    errors.nombrePieces = ErreursConstante.formulaire.generale.champObligatoire;
  }
  if (!values.loyerMensuel) {
    errors.loyerMensuel = ErreursConstante.formulaire.generale.champObligatoire;
  } else if (values.loyerMensuel && !isInteger(values.loyerMensuel)) {
    errors.loyerMensuel =
      ErreursConstante.formulaire.generale.champEntierErreur;
  }

  if (!values.chargesMensuelles) {
    errors.chargesMensuelles =
      ErreursConstante.formulaire.generale.champObligatoire;
  } else if (values.chargesMensuelles && !isInteger(values.chargesMensuelles)) {
    errors.chargesMensuelles =
      ErreursConstante.formulaire.generale.champEntierErreur;
  }
  if (!values.referenceMandat) {
    errors.referenceMandat =
      ErreursConstante.formulaire.generale.champObligatoire;
  }
  return errors;
};

const mapStateToProps = (state, props) => {
  const modifBienData = DossierLocationSelectors.getBienData(state);
  const bienData = state.bien.data ? state.bien.data : modifBienData;
  return {
    formValues: getFormValues('BienForm')(state),
    initialValues: copyBienDetailModelToForm(bienData),
    validate: validateForm,
  };
};

const reduxConnection = reduxForm<IBienFormData>({
  form: 'BienForm',
  enableReinitialize: true,
})(BienForm);

export default withStyles(styles)(connect(mapStateToProps)(reduxConnection));
