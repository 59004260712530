import { withStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';
import defaultStyles from '../styles';
import logoVert from '../../../../ressources/images/logo_vertuloo_vert.png';
import checkVert from '../../../../ressources/images/offre_check.png';
import certification from '../../../../ressources/images/offre_certif.png';

interface IProps {
  classes?: any;
}

const BlocOffres = (props: IProps) => (
  <div className={props.classes.container}>
    <img src={logoVert} width={100} alt="immobilier & construction" />
    <h2>Choisissez l'offre qui vous correspond</h2>
    <div className={props.classes.divider} />
    <div className={props.classes.containerOffres}>
      <div className={props.classes.offre}>
        <div className={props.classes.titre}>
          Offre <strong>Starter</strong>
        </div>

        <div
          className={
            props.classes.assureContainer + ' ' + props.classes.assureStarter
          }
        >
          <p>
            lot <br />
            <strong>assuré</strong>
          </p>
        </div>
        <div className={props.classes.containerTexte}>
          <div>
            <p>
              <strong>
                Contrôle automatisé de l’authenticité et de la conformité
              </strong>{' '}
              des pièces du dossier de candidature du locataire.
            </p>
            <div
              className={props.classes.images + ' ' + props.classes.imageGrey}
            >
              <img src={checkVert} width={30} height={30} alt="" />
            </div>
          </div>
          <div className={props.classes.separator + ' ' + props.classes.grey} />
          <div>
            <p>
              <strong>
                Analyse pragmatique et sécurisée de la solvabilité des
                candidats.
              </strong>
            </p>
            <div
              className={props.classes.images + ' ' + props.classes.imageGrey}
            >
              <img src={checkVert} width={30} height={30} alt="" />
            </div>
          </div>
          <div className={props.classes.separator + ' ' + props.classes.grey} />
          <div>
            <p>
              <strong>
                Outil d’aide pour éclairer la prise de décision de
                l’administrateur de biens.
              </strong>{' '}
            </p>
            <div
              className={props.classes.images + ' ' + props.classes.imageGrey}
            >
              <img src={checkVert} width={30} height={30} alt="" />
            </div>
          </div>
          <div className={props.classes.separator + ' ' + props.classes.grey} />
          <div>
            <p>
              <strong>
                Certification assurance contre le risque de loyers impayés
              </strong>{' '}
              qui atteste de l’effectivité du contrat pour 100% des dossiers
              accepté
            </p>
            <div
              className={props.classes.images + ' ' + props.classes.imageGrey}
            >
              <img src={certification} width={50} height={48} alt="" />
            </div>
          </div>
        </div>
        <div className={props.classes.button + ' ' + props.classes.buttonGrey}>
          <div>
            <p>1,5€*</p>
            <span>par mois</span>
          </div>
        </div>
      </div>
      <div className={props.classes.offrePremium}>
        <div className={props.classes.titrePremium}>
          Offre <strong>Premium</strong>
        </div>
        <div className={props.classes.dividerDeux}>
          <span>lot</span>
        </div>
        <div className={props.classes.assureContainer}>
          <p>assuré</p>
          <p>
            non
            <br />
            assuré
          </p>
        </div>
        <div className={props.classes.containerTexte}>
          <div>
            <p>
              <strong>
                Contrôle automatisé de l’authenticité et de la conformité
              </strong>{' '}
              des pièces du dossier de candidature du locataire.
            </p>
            <div className={props.classes.images}>
              <img src={checkVert} width={30} height={30} alt="" />
              <img src={checkVert} width={30} height={30} alt="" />
            </div>
          </div>
          <div className={props.classes.separator} />
          <div>
            <p>
              <strong>
                Analyse pragmatique et sécurisée de la solvabilité des
                candidats.
              </strong>
            </p>
            <div className={props.classes.images}>
              <img src={checkVert} width={30} height={30} alt="" />
              <img src={checkVert} width={30} height={30} alt="" />
            </div>
          </div>
          <div className={props.classes.separator} />
          <div>
            <p>
              <strong>
                Outil d’aide pour éclairer la prise de décision de
                l’administrateur de biens.
              </strong>{' '}
            </p>
            <div className={props.classes.images}>
              <img src={checkVert} width={30} height={30} alt="" />
              <img src={checkVert} width={30} height={30} alt="" />
            </div>
          </div>
          <div className={props.classes.separator} />
          <div>
            <p>
              <strong>
                Certification assurance contre le risque de loyers impayés
              </strong>{' '}
              qui atteste de l’effectivité du contrat pour 100% des dossiers
              accepté
            </p>
            <div className={props.classes.images}>
              <img src={certification} width={50} height={48} alt="" />
              <div />
            </div>
          </div>
          <div className={props.classes.separatorVertical} />
        </div>
        <div className={props.classes.button}>
          <div>
            <p>1,5€*</p>
            <span>par mois</span>
          </div>
          <div>
            <p>offert**</p>
          </div>
        </div>
      </div>
    </div>
    <p>* intégré à l'assurance pour un lot quittancé à 600€/mois</p>
    <p>** offert si + de 75% des lots sont assurés en GLI</p>
  </div>
);

export default withStyles(theme => ({
  ...styles(theme),
  ...defaultStyles(theme),
}))(BlocOffres);
