import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import React from 'react';
import Bouton from '../../../../components/bouton/Bouton';
import UncontrolledInputField from '../../../../components/input/UncontrolledInputField';
import InputCheckbox from '../../../../components/inputCheckbox/InputCheckbox';
import styles from '../../styles';

interface Props {
  classes?: any; // Surcharge de style CSS injecté par withStyles
  onChangeCritere(critere: string, value: string | boolean): void;
  onRechercher(): void;
  initialValues: { [key: string]: any };
}

class CriteresRecherche extends React.Component<Props> {

  handleUpdateCritere = (id, value) => {
    this.props.onChangeCritere(id, value);
  };

  handleUpdateCritereCheckBox = (id, event: boolean) => {
    this.props.onChangeCritere(id, event);
  };
  /**
   * Fonction permettant de déclencher la recherche.
   */
  handleRechercher = () => {
    this.props.onRechercher();
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        spacing={2}
        justify="space-between"
        className={cx(classes.formRechercherAdb, 'fondGrisFonce')}
      >
        <Grid item xs={3}>
          <UncontrolledInputField
            id="numAdherent"
            label="Identifiant adhérent"
            handleChange={this.handleUpdateCritere}
            type="number"
            size={7}
            max={9999999}
          />
        </Grid>
        <Grid item xs={3}>
          <UncontrolledInputField
            id="nomAdherent"
            label="Nom adhérent"
            handleChange={this.handleUpdateCritere}
          />
        </Grid>
        <Grid item xs={3}>
          <UncontrolledInputField
            id="commercial"
            label="Nom du commercial"
            handleChange={this.handleUpdateCritere}
          />
        </Grid>
        <Grid item xs={3}>
          <UncontrolledInputField
            id="departement"
            label="Département"
            handleChange={this.handleUpdateCritere}
          />
        </Grid>
        <Grid item xs={3}>
          <UncontrolledInputField
            id="codePostal"
            label="Code postal"
            handleChange={this.handleUpdateCritere}
          />
        </Grid>
        <Grid item xs={3}>
          <UncontrolledInputField
            id="ville"
            label="Ville"
            handleChange={this.handleUpdateCritere}
          />
        </Grid>
        <Grid item xs={3}>
          <InputCheckbox
            id="actif"
            name="actif"
            value={this.props.initialValues.actif}
            label="Actif"
            onChangeValue={this.handleUpdateCritereCheckBox}
          />
        </Grid>
        <Grid item className={classes.bouton} xs={3}>
          <Bouton
            label="Rechercher"
            variant="contained"
            color="primary"
            size="medium"
            onClick={this.handleRechercher}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(CriteresRecherche);
