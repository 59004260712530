import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    container: {
      padding: '62px 12px',
      margin: 'auto',
      maxWidth: 880,
      textAlign: 'center',
      '& h2': {
        fontSize: 26,
        margin: 0,
      },
      '& p': {
        margin: 0,
      },
    },
    containerOffres: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: 16,
    },
    offrePremium: {
      width: 'calc(50% - 16px)',
      marginRight: 16,
      border: '2px solid ' + theme.palette.primary.main,
    },
    offre: {
      width: 'calc(50% - 16px)',
      marginRight: 16,
      border: '2px solid #808080',
    },
    dividerDeux: {
      position: 'relative',
      marginTop: 42,
      marginLeft: 'calc(100% - 162px)',
      height: 1,
      width: 154,
      background: theme.palette.primary.main,
      '& span': {
        position: 'absolute',
        top: -12,
        left: '37%',
        padding: '0 12px',
        background: 'white',
      },
    },
    assureContainer: {
      display: 'flex',
      alignItems: 'center',
      width: 154,
      marginLeft: 'calc(100% - 162px)',
      fontWeight: 'bold',
      justifyContent: 'space-around',
    },
    assureStarter: {
      marginTop: 42,
    },
    titrePremium: {
      background: theme.palette.primary.main,
      width: '100%',
      padding: 20,
      fontSize: 26,
      color: 'white',
    },
    titre: {
      background: '#808080',
      width: '100%',
      padding: 20,
      fontSize: 26,
      color: 'white',
    },
    containerTexte: {
      textAlign: 'right',
      position: 'relative',
      padding: 8,
      '& > div': {
        display: 'flex',
        alignItems: 'center',
      },
      '& p': {
        flex: 1,
        marginRight: 24,
      },
    },
    images: {
      width: 154,
      display: 'flex',
      justifyContent: 'space-between',
      '& > div': {
        width: 56,
      },
      '& img': {
        margin: '0 20px',
      },
    },
    separator: {
      height: 1,
      background: theme.palette.primary.main,
      width: '100%',
      margin: '8px 0',
    },
    divider: {
      height: 2,
      background: theme.palette.primary.main,
      width: 100,
      margin: '24px auto',
    },
    separatorVertical: {
      width: 1,
      background: theme.palette.primary.main,
      height: 'calc(100% + 26px)',
      top: -30,
      right: '85px',
      position: 'absolute',
    },
    button: {
      display: 'flex',
      width: 190,
      marginBottom: 16,
      marginLeft: 'calc(100% - 190px)',
      justifyContent: 'flex-end',
      '& div': {
        flex: 1,
        background: theme.palette.primary.main,
        padding: 4,
        color: 'white',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: 4,
        fontSize: 19,
        maxWidth: 77,
        lineHeight: '18px',
        '& span': {
          fontSize: 13,
          fontWeight: 'normal',
        },
      },
    },
    grey: {
      background: '#808080',
    },
    buttonGrey: {
      justifyContent: 'center',
      width: 120,
      marginLeft: 'calc(100% - 140px)',
    },
    imageGrey: {
      justifyContent: 'center',
    },
    '@media all and (max-width: 789px)': {
      containerOffres: {
        display: 'block',
      },
      offrePremium: {
        width: '80%',
        margin: '16px auto',
      },
      offre: {
        width: '80%',
        margin: '16px auto',
      },
    },
    '@media all and (max-width: 580px)': {
      containerOffres: {
        display: 'block',
      },
      offrePremium: {
        width: '98%',
        margin: '16px auto',
      },
      offre: {
        width: '98%',
        margin: '16px auto',
      },
    },
  } as any);

export default styles;
