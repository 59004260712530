import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from './styles';
import { Grid } from '@material-ui/core';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  // Couleur du spinner
  couleur?: 'primary' | 'secondary' | 'inherit' | undefined;
  // Taille du spinner
  taille?: number;
}

/**
 * Composant affichant un message d'erreur sous forme de snack.
 */
class Spinner extends React.PureComponent<Props> {
  /**
   * Valeurs d'initialisation
   */
  public static defaultProps = {
    taille: 80,
  };

  public render() {
    const { couleur, taille } = this.props;

    return (
      <Grid container justify="center">
        <CircularProgress color={couleur} size={taille} />
      </Grid>
    );
  }
}
export default withStyles(styles)(Spinner);
