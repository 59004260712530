import { AxiosInstance, AxiosResponse } from 'axios';
import { ISituationProfessionnellePresentation } from '../../containers/DossierLocation/SituationProfessionnellePage/helpers';
import {
  DossierPiecesId,
  DossierParticulier,
  DossierParticulierTypeCompteEnum,
  DossiersLocationsApiFactory,
  DossiersProfessionnelsApiFactory,
  SituationPersonnelleDossierParticulier,
  SituationProfessionnelle,
} from './swagger';

export interface IApi {
  postSituationPersonnelle(params): Promise<DossierParticulier>;
  putSituationPersonnelle(params): Promise<void>;
  getListSituationsProfessionnelles(): Promise<SituationProfessionnelle[]>;
  putSituationProfessionnelle(params): Promise<DossierPiecesId>;
}

const create = (endpoint: string, axiosClient: AxiosInstance) => {
  const dossierLocationApi = DossiersLocationsApiFactory(
    {},
    endpoint,
    axiosClient,
  );

  const SituationProfessionnelleApi = DossiersProfessionnelsApiFactory(
    {},
    endpoint,
    axiosClient,
  );

  /**
   * Enregistrer une situation personnelle
   * On créé element par element
   * Meme si l'API permet de faire une création en masse
   *
   * @param params : Critères de recherche
   */
  const postSituationPersonnelle = (params: {
    dossierLocationRef: string;
    situationPersonnelle: DossierParticulier;
  }): Promise<DossierParticulier> => {
    const promise: Promise<DossierParticulier> = new Promise(
      (resolve, reject) => {
        const situationPerso = {
          ...params.situationPersonnelle,
          typeCompte:
            params.situationPersonnelle.typeCompte ||
            DossierParticulierTypeCompteEnum.LOCATAIRE,
        };
        dossierLocationApi
          .dossiersLocationRefDossiersParticuliersPost(
            params.dossierLocationRef,
            situationPerso,
            {},
          )
          .then((response: AxiosResponse<DossierParticulier>) => {
            resolve(response.data);
          })
          .catch(error => reject(error));
      },
    );
    return promise;
  };

  const putSituationPersonnelle = (params: {
    dossierLocationRef: string;
    dossierPersonneRef: string;
    situationPersonnelle: SituationPersonnelleDossierParticulier;
  }): Promise<void> => {
    const promise: Promise<void> = new Promise((resolve, reject) => {
      dossierLocationApi
        .dossiersLocationRefDossiersParticuliersRefPersSituationPersonnellePut(
          params.dossierLocationRef,
          params.dossierPersonneRef,
          params.situationPersonnelle,
          {},
        )
        .then(() => resolve())
        .catch(error => reject(error));
    });
    return promise;
  };

  const getListSituationsProfessionnelles = (): Promise<
    SituationProfessionnelle[]
  > => {
    const promise: Promise<SituationProfessionnelle[]> = new Promise(
      (resolve, reject) => {
        SituationProfessionnelleApi.getSituationsProfessionnelles({})
          .then((response: AxiosResponse<SituationProfessionnelle[]>) =>
            resolve(response.data),
          )
          .catch(error => reject(error));
      },
    );
    return promise;
  };

  const putSituationProfessionnelle = (params: {
    dossierLocationRef: string;
    dossierParticulierRef: string;
    situationProfessionnelle: ISituationProfessionnellePresentation;
  }): Promise<DossierPiecesId> => {
    const promise: Promise<DossierPiecesId> = new Promise((resolve, reject) => {
      dossierLocationApi
        .dossiersLocationRefDossiersParticuliersRefPersSituationProfessionnellePut(
          params.dossierLocationRef,
          params.dossierParticulierRef,
          params.situationProfessionnelle,
          {},
        )
        .then(response => resolve(response.data))
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
    return promise;
  };

  return {
    postSituationPersonnelle,
    putSituationPersonnelle,
    getListSituationsProfessionnelles,
    putSituationProfessionnelle,
  };
};

export default create;
