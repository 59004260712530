import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { ActionCreators as NousContacterCreators } from '../../redux/NousContacterRedux';
import { IApi } from '../../services/Api';

export function* postNousContacter(
  api: IApi,
  action: ActionType<typeof NousContacterCreators.postNousContacter.request>,
) {
  try {
    yield call(api.postNousContacter, action.payload);
    yield put(NousContacterCreators.postNousContacter.success());
    yield put(push('/'));
  } catch (error) {
    yield put(NousContacterCreators.postNousContacter.failure(error));
  }
}
