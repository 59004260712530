import {
  CircularProgress,
  Grid,
  Link,
  Typography,
  withStyles,
} from '@material-ui/core';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import IconInfo from '@material-ui/icons/InfoOutlined';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Bouton from '../../../../components/bouton/Bouton';
import CheminDeFer from '../../../../components/cheminDeFer/CheminDeFer';
import DialogStatut from '../../../../components/DialogStatut';
import FormContainer from '../../../../components/FormContainer';
import LoadingBoundary from '../../../../components/LoadingBoundary';
import Statut from '../../../../components/Statut';
import Titre from '../../../../components/titre/Titre';
import { history } from '../../../../helpers/history';
import { ActionCreators as BienActionCreators } from '../../../../redux/Bien';
import {
  ActionCreators as DossierLocationActionCreators,
  Selectors as DossierLocationSelectors,
} from '../../../../redux/DossierLocation';
import { ActionCreators as PartenaireActionCreators, Selectors as PartenaireSelectors } from '../../../../redux/Partenaire';
import { ActionCreators as PiecesActionCreators } from '../../../../redux/Pieces';
import { ActionCreators as SituationPersoActionCreators } from '../../../../redux/SituationPersonnelle';
import { ActionCreators as SituationProActionCreators } from '../../../../redux/SituationProfessionnelle';
import { Selectors as UtilisateursSelectors } from '../../../../redux/Utilisateurs';
import {
  DossierLocation,
  Partenaire,
  Profil,
  Utilisateur,
} from '../../../../services/Api/swagger';
import { default as Libelle } from '../../../../_i18n/fr.json';
import InfoCandidats from './InfoCandidats';
import InfoDossier from './InfoDossier';
import styles from './styles';
import InputCheckbox from '../../../../components/inputCheckbox/InputCheckbox';

interface Props {
  // Surcharge de style CSS injecté par withStyles
  classes?: any;
  getSynthese(ref: string): void;
  synthese: DossierLocation;
  error: any;
  fetching: boolean;
  selectedDossier?: string;
  resetSituationPro(): void;
  resetSelectedPerson(): void;
  addColoc(ref: string): void;
  addLocataire(ref: string): void;
  setSelectedPerson(ref: string): void;
  resetParcours(): void;
  calculLoyerMax(ref: string): void;
  calculLoyerFetching: boolean;
  calculLoyerError?: any;
  infoProfil?: Profil;
  forcerStatutDossier(params): void;
  forcerStatutFetching: boolean;
  utilisateurConnecte: Utilisateur;
  getPartenaire(code: string): void;
  isControleDossier: boolean;
  partenaire: Partenaire;
}

interface State {
  infoSaisiesOk: boolean;
}

class Synthese extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      infoSaisiesOk: false,
    };
  }

  componentDidMount() {
    this.handleLoadData();
  }

  handleLoadData = () => {
    // Si l'on vient d'un lien qui contient l'url du dossier on charge le dossier référencé
    const refPath = new URLSearchParams(history.location.search).get("refDossier");
    if (refPath) {
      this.props.getSynthese(refPath);
    } else if (this.props.selectedDossier) {
      this.props.getSynthese(this.props.selectedDossier);
    }

    this.props.resetParcours();
  };

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.selectedDossier &&
      prevProps.selectedDossier &&
      prevProps.selectedDossier !== this.props.selectedDossier
    ) {
      this.props.getSynthese(this.props.selectedDossier);
    } else if (this.props.synthese && this.props.synthese.partenaire && !this.props.partenaire) {
      // Une fois la synthèse chargée, on charge le partenaire le cas échéant
      this.props.getPartenaire(this.props.synthese.partenaire.codePartenaire);
    }
  }

  /**
   * Fonction permettant de valider le dossier.
   */
  handleValider = () => {
    history.push('/');
  };

  handlePrecedent() {
    if (this.props.isControleDossier) {
      history.push('/dossier-location/pieces-justificatives');
    } else {
      // si le dossier a été créé par un partenaire qui ne souhaite pas de contrôle de PJ
      history.push('/dossier-location/situation-professionnelle');
    }
  }

  handlePrecedentDashboard() {
    history.push('/dashboard');
  }

  handleSuivant() {
    history.push('/dashboard');
  }

  addColoc = reference => {
    if (reference) {
      this.props.resetSituationPro();
      this.props.resetSelectedPerson();
      this.props.addColoc(reference);
      history.push('/dossier-location/situation-personnelle');
    }
  };

  addLocataire = reference => {
    if (reference) {
      this.props.resetSituationPro();
      this.props.resetSelectedPerson();
      this.props.addLocataire(reference);
      history.push('/dossier-location/situation-personnelle');
    }
  };

  calculLoyerMax = reference => {
    if (reference) {
      this.props.calculLoyerMax(reference);
    }
  };

  handleForcerStatutDossier = params => {
    if (this.props.synthese && this.props.synthese.reference) {
      const parameters = {
        ref: this.props.synthese.reference,
        ...params,
      };
      this.props.forcerStatutDossier(parameters);
    }
  };

  handleClickInfoSaisies = () => {
    this.setState({
      infoSaisiesOk: !this.state.infoSaisiesOk,
    });
  };

  render() {
    const {
      synthese,
      classes,
      error,
      fetching,
      selectedDossier,
      setSelectedPerson,
      calculLoyerError,
      calculLoyerFetching,
      infoProfil,
      forcerStatutFetching,
      utilisateurConnecte,
      partenaire,
    } = this.props;

    const isBesse = utilisateurConnecte.role === 'BESSE';
    const canModifyStatut =
      infoProfil &&
      infoProfil.fonctions &&
      infoProfil.fonctions.find(f => f === 'SYNTH_MODIFICATION_STATUT');
    const cantModify =
      (synthese &&
        synthese.statut &&
        (synthese.statut === 'VALIDE_AGREE' ||
          synthese.statut === 'VALIDE_AGREE_DIFFERE' ||
          synthese.statut === 'VALIDE_NON_AGREE' ||
          synthese.statut === 'NON_VALIDE' ||
          (partenaire && (synthese.statut === 'EN_TRANSFERT' || synthese.statut === 'VALIDE')))) ||
      false;
    const messageStatut =
      synthese &&
      synthese.statut &&
      Libelle.messageStatutDossierLocation[synthese.statut];
    const nomPartenaire = partenaire ? partenaire.nom : undefined;
    const urlPartenaire = synthese && synthese.partenaire
      ? synthese.partenaire.urlFront
      : undefined;

    // VALIDATION INFORMATIONS SAISIES
    return (
      <div>
        <Grid container justify="center">
          <Titre
            libelle={Libelle.pageSynthese.h1}
            variant="h1"
            isVisible={false}
          />
        </Grid>
        {/* Chemin de fer */}
        {!cantModify && <CheminDeFer isEtape="synthese" />}
        {cantModify && (
          <Grid
            container
            justify="space-between"
            className={classes.containerLink}
          >
            <Link href="/dashboard" color="inherit" className="backLink">
              <ArrowLeft /> Retour au dashboard
            </Link>
          </Grid>
        )}
        <div className={classes.synthese}>
          <LoadingBoundary
            error={error}
            loading={fetching}
            onRetry={this.handleLoadData}
          >
            <>
              <Grid
                container
                direction="column"
                className={classes.containerStatut}
              >
                <div className={classes.statut}>
                  <Grid container alignItems="center">
                    <p>
                      <strong>Statut :</strong>
                    </p>
                    {forcerStatutFetching ? (
                      <CircularProgress />
                    ) : (
                      <Statut statut={synthese && synthese.statut} />
                    )}
                    {canModifyStatut && (
                      <DialogStatut
                        handleForcerStatutDossier={
                          this.handleForcerStatutDossier
                        }
                      />
                    )}
                  </Grid>
                  {messageStatut && (
                    <Grid
                      container
                      alignItems="center"
                      className={classes.messageStatut}
                    >
                      <IconInfo fontSize="small" />
                      <small>{messageStatut}</small>
                    </Grid>
                  )}
                </div>
                <Grid
                  container
                  className={classes.loyermaxConseille}
                  alignItems="center"
                >
                  <strong>
                    Loyer max conseillé :{' '}
                    {synthese &&
                      (synthese.loyerMaxConseille ||
                        synthese.loyerMaxConseille === 0) ? (
                      synthese.loyerMaxConseille + '€'
                    ) : !this.state.infoSaisiesOk ? (
                      <InputCheckbox
                        id="infoSaisiesOk"
                        name="infoSaisiesOk"
                        value={this.state.infoSaisiesOk}
                        label="En cochant cette case, le signataire du présent dossier certifie que les informations qu’il a saisies sont exactes et conformes aux documents et informations qui lui ont été transmis par le candidat locataire."
                        onChangeValue={this.handleClickInfoSaisies}
                      />
                    ) : (
                      <Bouton
                        label="Calcul loyer max"
                        variant="contained"
                        color="primary"
                        size="small"
                        fetching={calculLoyerFetching}
                        onClick={() =>
                          this.calculLoyerMax(synthese.reference)
                        }
                      />
                    )}
                  </strong>
                  {calculLoyerError && (
                    <Typography variant="caption" color="error">
                      La solvabilité ne peut être déterminée, Merci de corriger
                      les informations du dossier
                    </Typography>
                  )}
                  {isBesse &&
                    synthese &&
                    (synthese.loyerMaxConseille ||
                      synthese.loyerMaxConseille === 0) && (
                      <Link
                        underline="always"
                        href="/dossier-location/calcul-loyer"
                      >
                        détail du calcul
                      </Link>
                    )}
                </Grid>
              </Grid>
              <FormContainer>
                {synthese && (
                  <>
                    <InfoDossier data={synthese} cantModify={cantModify} nomPartenaire={nomPartenaire} urlPartenaire={urlPartenaire} />
                    <InfoCandidats
                      data={synthese && synthese.dossiersParticuliers}
                      typeDossier={synthese && synthese.compositionLocation}
                      cantModify={cantModify}
                      addColoc={this.addColoc}
                      addLocataire={this.addLocataire}
                      selectedDossier={selectedDossier}
                      setSelectedPerson={setSelectedPerson}
                      statutDossier={synthese.statut}
                    />
                  </>
                )}
              </FormContainer>
            </>
          </LoadingBoundary>
        </div>
        <Grid container spacing={10} justify="space-between">
          <Grid item />
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  synthese: DossierLocationSelectors.getDossierLocation(state),
  fetching: DossierLocationSelectors.getDossierSyntheseFetching(state)
    || PartenaireSelectors.getPartenaireFetching(state),
  error: DossierLocationSelectors.getDossierSyntheseError(state),
  selectedDossier: DossierLocationSelectors.getSelectedDossier(state),
  calculLoyerFetching: DossierLocationSelectors.getCalculFetching(state),
  calculLoyerError: DossierLocationSelectors.getCalculError(state),
  infoProfil: UtilisateursSelectors.getProfilUtilisateur(state),
  forcerStatutFetching: DossierLocationSelectors.getForcerStatutFetching(state),
  utilisateurConnecte: UtilisateursSelectors.getUtilisateurConnecte(state),
  isControleDossier: PartenaireSelectors.getControleDossier(state),
  partenaire: PartenaireSelectors.getPartenaire(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getSynthese: (ref: string) =>
    dispatch(DossierLocationActionCreators.getSyntheseDossier.request(ref)),
  resetSituationPro: () =>
    dispatch(SituationProActionCreators.resetSituationProParcoursCreation()),
  addColoc: (ref: string) =>
    dispatch(SituationPersoActionCreators.resetSituationPersoAndAddColoc(ref)),
  addLocataire: (ref: string) =>
    dispatch(
      SituationPersoActionCreators.resetSituationPersoAndAddLocataire(ref),
    ),
  setSelectedPerson: (ref: string) =>
    dispatch(DossierLocationActionCreators.setSelectedPerson(ref)),
  resetSelectedPerson: () =>
    dispatch(DossierLocationActionCreators.resetSelectedPerson()),
  resetParcours: () => {
    dispatch(BienActionCreators.resetBienParcoursCreation());
    dispatch(
      SituationPersoActionCreators.resetSituationPersonnelleParcoursCreation(),
    );
    dispatch(SituationProActionCreators.resetSituationProParcoursCreation());
    dispatch(PiecesActionCreators.resetToken());
    dispatch(PartenaireActionCreators.resetPartenaire());
  },
  calculLoyerMax: ref =>
    dispatch(DossierLocationActionCreators.calculLoyerMax.request(ref)),
  forcerStatutDossier: params =>
    dispatch(DossierLocationActionCreators.forcerStatutDossier.request(params)),
  getPartenaire: (code: string) =>
    dispatch(PartenaireActionCreators.getPartenaireParCode.request(code)),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Synthese),
);
