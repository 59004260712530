import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Types from 'Types';
import Layout from '../../../components/Layout';
import LoadingBoundary from '../../../components/LoadingBoundary';
import { history } from '../../../helpers/history';
import {
  ActionCreators as AdbActionCreators,
  Selectors as AdbSelectors,
} from '../../../redux/AdministrateurDeBien';
import {
  AdministrateurBien,
  AdministrateurBienDetail,
} from '../../../services/Api/swagger';
import Libelle from '../../../_i18n/fr.json';
import CriteresRecherche from './CriteresRecherche';
import ResultatsRecherche from './ResultatsRecherche';

export interface IProps {
  searchAdministrateursDeBiens(params: any): void;

  searchResults: AdministrateurBien[];
  searchCount: number;
  searching: boolean;
  searchError: any; // FIXME Typer l'erreur
  getAdbIdDetail(params: number): void;

  updateAdbId(params: number): void;

  adbDetail: AdministrateurBienDetail[];
}

interface IState {
  criteres: ICriteresRecherche;
  pagination: IPagination;
}

interface ICriteresRecherche {
  numAdherent?: number;
  nomAdherent?: string;
  commercial?: string;
  departement?: string;
  codePostal?: number;
  ville?: string;
  actif?: boolean;
}

interface IPagination {
  orderBy: string;
  sortColumn: string;
  sortOrder: 'asc' | 'desc';
  page: number;
  nbrElementParPage: number;
}

const DEFAULT_NBR_ELEMENT_PAR_PAGE = 20;
const DEFAULT_SORT_COLUMN = 'identifiant';
const DEFAULT_SORT_ORDER = 'asc';

const criteresDefaut: ICriteresRecherche = {};

const paginationDefaut: IPagination = {
  page: 0,
  nbrElementParPage: DEFAULT_NBR_ELEMENT_PAR_PAGE,
  orderBy: DEFAULT_SORT_COLUMN,
  sortColumn: 'identifiant',
  sortOrder: 'asc',
};

class RechercheAdbPage extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      criteres: criteresDefaut,
      pagination: paginationDefaut,
    };
  }

  componentDidMount() {
    this.lancerRecherche(true);
  }

  handleChangeCritere = (id: string, value: string | boolean) => {
    this.setState({
      criteres: {
        ...this.state.criteres,
        [id]: value,
      },
    });
  };

  handleRechercher = () => {
    // Si on lance la recherche par le bouton Rechercher (et donc changement de critères)
    // on réinitialise le numéro de page
    this.lancerRecherche(true);
  };

  lancerRecherche = (resetPage: boolean) => {
    const { criteres, pagination } = this.state;
    // On construit la requête de recherche en cumulant les critères de recherche
    // et les infos de pagination

    const page = resetPage ? 0 : pagination.page || 0;

    const params = {
      ...(criteres.numAdherent ? { numAdherent: criteres.numAdherent } : {}),
      ...(criteres.nomAdherent ? { nomAdherent: criteres.nomAdherent } : {}),
      ...(criteres.commercial ? { commercial: criteres.commercial } : {}),
      ...(criteres.departement ? { departement: criteres.departement } : {}),
      ...(criteres.codePostal ? { codePostal: criteres.codePostal } : {}),
      ...(criteres.ville ? { ville: criteres.ville } : {}),
      ...(criteres.actif ? { actif: criteres.actif } : {}),
      ...{ page },
      ...(pagination.nbrElementParPage
        ? { taille: pagination.nbrElementParPage }
        : { taille: DEFAULT_NBR_ELEMENT_PAR_PAGE }),
      ...(pagination.sortColumn
        ? {
            tri: `${pagination.sortColumn}:${pagination.sortOrder ||
              DEFAULT_SORT_ORDER}`,
          }
        : { tri: `${DEFAULT_SORT_COLUMN}:${DEFAULT_SORT_ORDER}` }),
    };
    if (resetPage) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          page: 0,
        },
      });
    }
    this.props.searchAdministrateursDeBiens(params);
  };

  handleReessayer = () => {
    this.lancerRecherche(true);
  };

  handleChangeTri = (sortColumn: string, sortOrder: 'asc' | 'desc') => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          sortColumn,
          sortOrder,
        },
      },
      () => {
        this.lancerRecherche(false);
      },
    );
  };

  handleChangePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.lancerRecherche(false);
      },
    );
  };

  handleChangeNbrElementParPage = (nbrElementParPage: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          nbrElementParPage,
        },
      },
      () => {
        this.lancerRecherche(false);
      },
    );
  };

  handleConsulter = (id: number) => {
    this.props.updateAdbId(id);
    this.props.getAdbIdDetail(id);
    history.push('/mes-administrateurs-de-biens/consulter');
  };

  onDelete = () => {};
  onGenerer = () => {};

  render() {
    const { searchResults, searchCount, searching, searchError } = this.props;
    const { criteres, pagination } = this.state;
    const initialValues = {
      numAdherent: criteres.numAdherent,
      nomAdherent: criteres.nomAdherent,
      commercial: criteres.commercial,
      departement: criteres.departement,
      codePostal: criteres.codePostal,
      ville: criteres.ville,
      actif: criteres.actif,
    };
    return (
      <React.Fragment>
        <Layout title={Libelle.pageRechercherAdb.titre}>
          <div>
            <CriteresRecherche
              initialValues={initialValues}
              onChangeCritere={this.handleChangeCritere}
              onRechercher={this.handleRechercher}
            />
            <LoadingBoundary
              error={searchError}
              loading={searching}
              onRetry={this.handleReessayer}
            >
              {searchResults && (
                <ResultatsRecherche
                  onChangeNbrElementParPage={this.handleChangeNbrElementParPage}
                  onChangePage={this.handleChangePage}
                  onChangeTri={this.handleChangeTri}
                  onConsulter={this.handleConsulter}
                  onDelete={this.onDelete}
                  onGenerer={this.onGenerer}
                  searchResults={searchResults}
                  searchCount={searchCount}
                  nbrElementParPage={pagination.nbrElementParPage}
                  sortColumn={pagination.sortColumn}
                  sortOrder={pagination.sortOrder}
                  page={pagination.page}
                />
              )}
            </LoadingBoundary>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: Types.RootState) => {
  return {
    searchResults: AdbSelectors.getSearchResults(state),
    searchCount: AdbSelectors.getSearchCount(state),
    searching: AdbSelectors.getSearchFetching(state),
    searchError: AdbSelectors.getSearchError(state),
    adbDetail: AdbSelectors.getADBDetail(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    searchAdministrateursDeBiens: params =>
      dispatch(AdbActionCreators.search.request(params)),
    getAdbIdDetail: (params: number) =>
      dispatch(AdbActionCreators.getADBIdDetail.request(params)),
    updateAdbId: (params: number) =>
      dispatch(AdbActionCreators.updateAdbId(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RechercheAdbPage);
