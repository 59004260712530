import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    root: {
      boxShadow: 'none',
      borderRadius: 2,
      textTransform: 'none',
      '& .iconLeft': {
        marginRight: 16,
      },
      '& .iconRight': {
        marginLeft: 16,
      },
      '&:hover, &:focus, &:active': {
        boxShadow: 'none',
      },
    },
    containedPrimary: {
      boxShadow:
        ' 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
      textTransform: 'none',
      fontSize: 13,
      color: '#000',
      fontWeight: 'bold',
    },
    iconButton: {
      background: 'transparent',
      boxShadow: 'none',
      padding: 0,
      fontWeight: 'bold',
      textTransform: 'none',
      fontSize: '1.1em',
      '&:hover, &:focus, &:active': {
        background: 'transparent',
      },
    },
    icon: {
      background: theme.palette.primary.main,
      borderRadius: '100%',
      width: 30,
      height: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    prevIcon: {
      marginRight: 12,
    },
    nextIcon: {
      marginLeft: 12,
    },
  } as any);

export default styles;
