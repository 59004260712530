import Types from 'Types';
import {
  ActionType,
  createAsyncAction,
  createStandardAction,
  getType,
} from 'typesafe-actions';
import {
  SituationProfessionnelle,
  SituationProfessionnelleDossierParticulier,
} from '../../services/Api/swagger';

export interface SituationProfessionnelleState {
  situationPro?: SituationProfessionnelleDossierParticulier;
  situationProFetching: Types.IFetchInfos;
  listSituationProfessionnelle: SituationProfessionnelle[];
  listSituationProFetching: Types.IFetchInfos;
}

export const initialState: SituationProfessionnelleState = {
  situationPro: undefined,
  situationProFetching: {
    fetching: false,
    error: undefined,
  },
  listSituationProFetching: {
    fetching: false,
    error: undefined,
  },
  listSituationProfessionnelle: [],
};

export const getListSituationProfessionnelleAsync = createAsyncAction(
  '@@App/situationProfessionnelle/LOAD_REQUEST',
  '@@App/situationProfessionnelle/LOAD_SUCCESS',
  '@@App/situationProfessionnelle/LOAD_FAILURE',
)<void, SituationProfessionnelle[], any>();

export const addOrUpdateSituationProfessionnelleAsync = createAsyncAction(
  '@@App/situationProfessionnelle/ADD_UPDATE_REQUEST',
  '@@App/situationProfessionnelle/ADD_UPDATE_SUCCESS',
  '@@App/situationProfessionnelle/ADD_UPDATE_FAILURE',
)<any, SituationProfessionnelleDossierParticulier, any>();

export const removeSituationProfessionnelleError = createStandardAction(
  '@@App/situationProfessionnelle/REMOVE_ERROR',
)<void>();

export const resetSituationProParcoursCreation = createStandardAction(
  '@@App/situationPersonnelle/PARCOURS_CREATION/RESET_STORE',
)<void>();

export const ActionCreators = {
  getListSituationProfessionnelleAsync,
  addOrUpdateSituationProfessionnelleAsync,
  removeSituationProfessionnelleError,
  resetSituationProParcoursCreation,
};

const getSituationProfessionnelle = (
  state,
): SituationProfessionnelleDossierParticulier =>
  state.situationProfessionnelle.situationPro;

const getSituationProfessionnelleFetching = (state): boolean =>
  state.situationProfessionnelle.situationProFetching.fetching;

const getSituationProfessionnelleError = (state): Types.IFetchInfos =>
  state.situationProfessionnelle.situationProFetching.error;

const getListSituationProfessionnelle = (state): SituationProfessionnelle[] =>
  state.situationProfessionnelle &&
  state.situationProfessionnelle.listSituationProfessionnelle;

const getListSituationProFetching = (state): boolean =>
  state.situationProfessionnelle.listSituationProFetching.fetching;

const getListSituationProError = (state): boolean =>
  state.situationProfessionnelle.listSituationProFetching.error;

export const Selectors = {
  getSituationProfessionnelle,
  getSituationProfessionnelleError,
  getSituationProfessionnelleFetching,
  getListSituationProfessionnelle,
  getListSituationProFetching,
  getListSituationProError,
};

export const reducer = (
  state: SituationProfessionnelleState = initialState,
  action: ActionType<typeof ActionCreators>,
): SituationProfessionnelleState => {
  switch (action.type) {
    case getType(getListSituationProfessionnelleAsync.request):
      return {
        ...state,
        listSituationProFetching: {
          fetching: true,
          error: undefined,
        },
      };

    case getType(getListSituationProfessionnelleAsync.success):
      return {
        ...state,
        listSituationProfessionnelle: action.payload,
        listSituationProFetching: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(getListSituationProfessionnelleAsync.failure):
      return {
        ...state,
        listSituationProFetching: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(addOrUpdateSituationProfessionnelleAsync.request):
      return {
        ...state,
        situationProFetching: {
          fetching: true,
          error: undefined,
        },
      };

    case getType(addOrUpdateSituationProfessionnelleAsync.success):
      return {
        ...state,
        situationPro: action.payload,
        situationProFetching: {
          fetching: false,
          error: undefined,
        },
      };

    case getType(addOrUpdateSituationProfessionnelleAsync.failure):
      return {
        ...state,
        situationProFetching: {
          fetching: false,
          error: action.payload,
        },
      };

    case getType(removeSituationProfessionnelleError):
      return {
        ...state,
        situationProFetching: {
          fetching: false,
          error: undefined,
        },
      };
    case getType(resetSituationProParcoursCreation):
      return {
        ...state,
        situationPro: undefined,
        situationProFetching: {
          error: undefined,
          fetching: false,
        },
      };
  }
  return state;
};
