import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import memoizeOne from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import {
  change,
  Field,
  getFormValues,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import Bouton from '../../../components/bouton/Bouton';
import FormContainer from '../../../components/FormContainer';
import Select from '../../../components/select/Select';
import {
  AdministrateurBien,
  AdministrateurBienDetail,
  Site,
} from '../../../services/Api/swagger';
import ErreursConstante from '../../../_constantes/erreursConstantes.json';
import styles from './style';

export interface ISelectAdbSiteFormData {
  adb: string;
  site: string;
}

export interface ISelectAdbSiteFormError {
  adb?: string;
  site?: string;
}

interface CustomProps {
  listAdb?: AdministrateurBienDetail[];
  listSite?: Site[];
  selectedAdbDetail: AdministrateurBien;
  selectedSiteDetail: Site;
  isFetching?: boolean;

  onSelectAdb(idAdb: number): void;

  onSelectSite(idSite: number): void;

  handleSubmit: void;
  formValues: ISelectAdbSiteFormData;
  classes?: any;
}

const construireListAdb = (listAdb: AdministrateurBienDetail[]) => {
  return listAdb
    ? listAdb.map(s => ({
        key: s.identifiant || 0,
        text: s.nom || '',
        value: s.identifiant ? String(s.identifiant) : '',
      }))
    : [];
};

const construireListSite = (listSite: Site[]) => {
  return listSite
    ? listSite.map(s => ({
        key: s.numero || 0,
        text: s.nom || '',
        value: s.numero ? String(s.numero) : '',
      }))
    : [];
};

const memoizeListAdbOptions = memoizeOne(construireListAdb);
const memoizeListSiteOptions = memoizeOne(construireListSite);

type Props = InjectedFormProps<ISelectAdbSiteFormData> & CustomProps;

class SelectAdbSiteForm extends React.Component<Props> {
  onChangeAdb = idAdb => {
    change('selectAdbSiteForm', 'site', '');
    this.props.onSelectAdb(idAdb);
  };

  onChangeSite = idSite => {
    this.props.onSelectSite(idSite);
  };

  render() {
    const { listAdb, listSite, isFetching, handleSubmit, classes } = this.props;

    const listAdbsOptions = memoizeListAdbOptions(listAdb);
    const listSitesOptions = memoizeListSiteOptions(listSite);

    return (
      <form
        onSubmit={handleSubmit}
        noValidate
        className={classes.formContainer}
      >
        <FormContainer>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                name="adb"
                component={Select}
                onChange={this.onChangeAdb}
                options={listAdbsOptions}
                label="ADB à selectionner"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="site"
                component={Select}
                onChange={this.onChangeSite}
                options={listSitesOptions}
                label="Site de l'ADB"
                required
              />
            </Grid>
          </Grid>
        </FormContainer>
        <Grid container justify="center">
          <Grid item>
            <Bouton
              fetching={isFetching}
              label="Valider"
              variant="contained"
              color="primary"
              size="medium"
              type="submit"
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

const validateForm = (
  values: ISelectAdbSiteFormData,
): ISelectAdbSiteFormError => {
  const errors: ISelectAdbSiteFormError = {} as ISelectAdbSiteFormError;

  if (!values.adb) {
    errors.adb = ErreursConstante.formulaire.generale.adbObligatoire;
  }

  if (!values.site) {
    errors.site = ErreursConstante.formulaire.generale.siteObligatoire;
  }

  return errors;
};

const mapStateToProps = (state, props) => ({
  formValues: getFormValues('selectAdbSiteForm')(state),
  initialValues: {
    adb: props.selectedAdbDetail
      ? props.selectedAdbDetail.identifiant + ''
      : '',
    site: props.selectedSiteDetail ? props.selectedSiteDetail.numero + '' : '',
  },
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    null,
  )(
    reduxForm<ISelectAdbSiteFormData>({
      form: 'selectAdbSiteForm',
      enableReinitialize: true,
      validate: validateForm,
    })(SelectAdbSiteForm),
  ),
);
