import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    container: {
      backgroundColor: theme.palette.primary.main,
      height: 350,
      width: '100%',
      padding: '24px 62px',
      display: 'flex',
      alignItems: 'center',
      '& h2': {
        margin: 0,
        letterSpacing: 1,
        fontSize: 42,
      },
    },
    texte: {
      color: 'white',
      width: '100%',
      marginTop: 78,
      padding: '0 32px',
      textAlign: 'center',
    },
    '@media all and (max-width: 789px)': {
      container: {
        '& h2': {
          fontSize: 30,
        },
      },
    },
  } as any);

export default styles;
