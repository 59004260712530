import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import ArrowLeft from '@material-ui/icons/ArrowBackIos';
import ArrowRight from '@material-ui/icons/ArrowForwardIos';
import { withStyles } from '@material-ui/styles';
import isEqual from 'lodash.isequal';
import React from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { DropdownItem } from '../select/Select';
import styles from './styles';

interface Props {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  id: string;
  value: string;
  type?: string;
  label: string;
  name: string;
  size?: 'small' | 'middle' | 'big';
  hasError?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
  onChangeValue: any;
  onBlur?: any;
  classes?: any;
  options?: DropdownItem[];
  onAdd?(value: any): void;
  onRemove?(value: any): void;
  disabled?: boolean;
}

interface State {
  optionsLeft: DropdownItem[];
  choosenOptions: DropdownItem[];
}
class TransferList extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      optionsLeft: this.props.options || ([] as DropdownItem[]),
      choosenOptions:
        (props.input && props.input.value) ||
        (props.meta && props.meta.initial) ||
        ([] as DropdownItem[]),
    };
  }

  updateOptions = () => {
    const defaultValues = this.props.input.value || this.props.meta.initial;
    if (this.props.options && defaultValues) {
      const choosenOptions = this.props.options.filter(item =>
        defaultValues.find(item2 => item.key === item2.key),
      );
      const newOptionsLeft = this.removeToArray(
        choosenOptions,
        this.props.options,
      );
      this.setState({
        optionsLeft: newOptionsLeft,
        choosenOptions,
      });
    }
  };
  componentDidMount() {
    this.updateOptions();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !isEqual(prevProps.options, this.props.options) ||
      !isEqual(prevProps.meta.initial, this.props.meta.initial)
    ) {
      this.updateOptions();
    }
  }

  removeToArray = (
    checkedValues: DropdownItem[],
    optionsLeft: DropdownItem[],
  ) => {
    return optionsLeft.filter(item => !checkedValues.includes(item));
  };

  addToArray = (checkedValues: DropdownItem, optionsLeft: DropdownItem[]) => {
    return optionsLeft.filter(item => item.value === checkedValues.value);
  };

  handleToggle = value => {
    const { choosenOptions, optionsLeft } = this.state;
    const checkedOptions = this.addToArray(value, optionsLeft);
    // Retirer de la liste les options à gauche
    const newoptionsLeft = this.removeToArray([value], optionsLeft);

    this.setState({
      // ajout à droite des options cochées
      choosenOptions: choosenOptions.concat(checkedOptions),
      optionsLeft: newoptionsLeft,
    });
    if (this.props.input) {
      this.props.input.onChange(
        this.state.choosenOptions.concat(checkedOptions),
      );
    }
    if (this.props.onAdd) {
      this.props.onAdd(value);
    }
  };

  handleToggleRight = value => {
    const { choosenOptions, optionsLeft } = this.state;
    const newoptionsLeft = optionsLeft.concat(
      this.addToArray(value, choosenOptions),
    );
    const newchoosenOptions = this.removeToArray(
      this.addToArray(value, choosenOptions),
      this.state.choosenOptions,
    );
    this.setState({
      choosenOptions: newchoosenOptions,
      optionsLeft: newoptionsLeft,
    });
    if (this.props.input) {
      this.props.input.onChange(newchoosenOptions);
    }
    if (this.props.onRemove) {
      this.props.onRemove(value);
    }
  };

  renderCustomList = (items: DropdownItem[], right: boolean) => (
    <Paper className={this.props.classes.paper}>
      <List component="div" role="list">
        {items.map(item => {
          const labelId = `transfer-list-item-${item.value}-label`;

          return (
            <ListItem
              key={item.value}
              role="listitem"
              button
              className={this.props.classes.listItem}
            >
              <Button
                id={labelId}
                className={this.props.classes.buttonList}
                color="secondary"
                disabled={this.props.disabled}
                onClick={
                  right
                    ? () => this.handleToggleRight(item)
                    : () => this.handleToggle(item)
                }
                classes={{
                  root: this.props.classes.rootCheckbox,
                  label: this.props.classes.checkbox,
                }}
              >
                {item.text || ''}
              </Button>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );
  render() {
    const {
      classes,
      meta: { error, touched },
    } = this.props;
    const { optionsLeft, choosenOptions } = this.state;
    return (
      <>
        <Typography variant="button">{this.props.label}</Typography>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item>{this.renderCustomList(optionsLeft, false)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                disabled
                aria-label="move selected choosenOptions"
              >
                <ArrowRight style={{ fontSize: 20 }} />
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                disabled
                aria-label="move selected optionsLeft"
              >
                <ArrowLeft style={{ fontSize: 20 }} />
              </Button>
            </Grid>
          </Grid>
          <Grid item>{this.renderCustomList(choosenOptions, true)}</Grid>
        </Grid>
        {error && touched && (
          <Typography color="error" variant="caption">
            {error}
          </Typography>
        )}
      </>
    );
  }
}
export default withStyles(styles)(TransferList);
