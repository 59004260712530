import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { ActionCreators as PartenaireCreators } from '../../redux/Partenaire';
import { IApi } from '../../services/Api';

export function* getPartenaireParCode(
    api: IApi,
    action: ActionType<typeof PartenaireCreators.getPartenaireParCode.request>,
) {
    try {
        const codePartenaire = action.payload;
        const result = yield call(api.getPartenaireParCode, codePartenaire);
        yield put(PartenaireCreators.getPartenaireParCode.success(result));
    } catch (error) {
        yield put(PartenaireCreators.getPartenaireParCode.failure(error));
    }
}