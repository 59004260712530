import { AxiosInstance, AxiosResponse } from 'axios';
import { wrapError } from '../../helpers/utils';
import {
  DossierLocation,
  DossiersLocationsApiFactory,
  PageDossiersLocation,
  RapportCalculAlgo,
  RetourLocationAcceptee,
} from './swagger';

export interface IApi {
  addDossierLocation(
    dossierLocation: DossierLocation,
  ): Promise<DossierLocation>;

  updateDossierLocation(dossierLocation: DossierLocation): Promise<void>;

  getSyntheseDossier(ref: string): Promise<DossierLocation>;

  getDossiersLocation(params): Promise<PageDossiersLocation>;

  getArchiveNetheos(params): Promise<Blob>;

  getRapportAlgo(param): Promise<RapportCalculAlgo>;

  rouvrirDossierNetheos(params): Promise<void>;

  calculLoyerMax(param: string): Promise<void>;

  dupliquerDossier(params): Promise<DossierLocation>;

  accepterDossier(params): Promise<RetourLocationAcceptee>;

  annulerAcceptationDossier(params): Promise<void>;

  forcerStatutDossier(params): Promise<Response>;

  deleteDossierLocation(ref: string): Promise<void>;

  genererCertification(ref: string): Promise<any>;
}

const create = (endpoint: string, axiosClient: AxiosInstance) => {
  const dossierLocationApi = DossiersLocationsApiFactory(
    {},
    endpoint,
    axiosClient,
  );

  const addDossierLocation = (
    dossierLocation: DossierLocation,
  ): Promise<DossierLocation> => {
    const promise: Promise<DossierLocation> = new Promise((resolve, reject) => {
      dossierLocationApi
        .dossiersLocationPost(dossierLocation, {})
        .then((response: AxiosResponse<DossierLocation>) => {
          resolve(response.data);
        })
        .catch(error => reject(wrapError(error)));
    });
    return promise;
  };

  const updateDossierLocation = (
    dossierLocation: DossierLocation,
  ): Promise<void> => {
    const promise: Promise<void> = new Promise((resolve, reject) => {
      dossierLocationApi
        .dossiersLocationRefPut(
          dossierLocation.reference || '',
          dossierLocation,
          {},
        )
        .then(() => {
          resolve();
        })
        .catch(error => reject(wrapError(error)));
    });
    return promise;
  };

  const getSyntheseDossier = (ref: string): Promise<DossierLocation> => {
    const promise: Promise<DossierLocation> = new Promise((resolve, reject) => {
      dossierLocationApi
        .dossiersLocationRefGet(ref, {})
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(wrapError(error)));
    });
    return promise;
  };

  const deleteDossierLocation = (ref): Promise<void> =>
    new Promise((resolve, reject) => {
      dossierLocationApi
        .dossiersLocationRefAnnulerDossierPut(ref)
        .then(() => {
          resolve();
        })
        .catch(error => reject(wrapError(error)));
    });

  const genererCertification = (ref): Promise<any> =>
    new Promise((resolve, reject) => {
      dossierLocationApi
        .dossiersLocationRefGenererCertificatGet(ref, {
          headers: {
            Accept: '*/*',
          },
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(wrapError(error)));
    });
  /**
   * Permet de ramener la iste des dossiers de location
   *
   * @param params : Critères de recherche
   */
  const getDossiersLocation = (params): Promise<PageDossiersLocation> =>
    new Promise((resolve, reject) => {
      dossierLocationApi
        .getDossiersLocation(
          params.adb,
          params.refDossier,
          params.refMandat,
          params.ville,
          params.statut,
          params.gli,
          params.site,
          params.nomLocataire,
          params.prenomLocataire,
          params.page,
          params.taille,
          [params.tri],
          {},
        )
        .then((response: AxiosResponse<PageDossiersLocation>) => {
          resolve(response.data);
        })
        .catch(error => reject(wrapError(error)));
    });

  /**
   * Permet de ramener l'archive de documents du Dossier Netheos
   *
   * @param params : Critères de recherche
   */
  const getArchiveNetheos = (params): Promise<Blob> =>
    new Promise((resolve, reject) => {
      dossierLocationApi
        .getArchive(params.ref, params.refPers, {
          responseType: 'arraybuffer',
          headers: {
            Accept: '*/*',
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response: AxiosResponse<Blob>) => {
          resolve(response.data);
        })
        .catch(error => reject(wrapError(error)));
    });

  /**
   * Permet de ramener l'algo d'un dossier
   *
   * @param params : Critères de recherche
   */
  const getRapportAlgo = (param): Promise<RapportCalculAlgo> =>
    new Promise((resolve, reject) => {
      dossierLocationApi
        .getRapportAlgo(param)
        .then((response: AxiosResponse<RapportCalculAlgo>) => {
          resolve(response.data);
        })
        .catch(error => {
          // L'api retourne toujours un 501 même quand ok
          if (error.response.status === 501) {
            resolve(error.response.data);
          }
          reject(wrapError(error));
        });
    });

  /**
   * Permet de réouvrir un Dossier Netheos
   *
   * @param params : Critères de recherche
   */
  const rouvrirDossierNetheos = (params): Promise<void> =>
    new Promise((resolve, reject) => {
      dossierLocationApi
        .reouvrirDossierPieces(params.ref, params.refPers)
        .then(() => {
          resolve();
        })
        .catch(error => reject(wrapError(error)));
    });

  /**
   * Lancement de l'algo pour le calcul loyer max
   *
   * @param params : Critères de recherche
   */
  const calculLoyerMax = (ref): Promise<void> =>
    new Promise((resolve, reject) => {
      dossierLocationApi
        .lancerAlgo(ref)
        .then(() => {
          resolve();
        })
        .catch(error => reject(wrapError(error)));
    });

  const dupliquerDossier = (params): Promise<DossierLocation> =>
    new Promise((resolve, reject) => {
      dossierLocationApi
        .dupliquerDossierLocation(params.ref, params.bien)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(wrapError(error)));
    });

  const accepterDossier = (ref): Promise<RetourLocationAcceptee> =>
    new Promise((resolve, reject) => {
      dossierLocationApi
        .accepterLocation(ref)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(wrapError(error)));
    });

  const annulerAcceptationDossier = (ref): Promise<void> =>
    new Promise((resolve, reject) => {
      dossierLocationApi
        .annulerAcceptationLocation(ref)
        .then(() => resolve())
        .catch(error => reject(wrapError(error)));
    });

  const forcerStatutDossier = (params): Promise<Response> =>
    new Promise((resolve, reject) => {
      dossierLocationApi
        .forcerStatutDossierLocation(params.ref, {
          statut: params.statut,
          message: params.message,
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(wrapError(error)));
    });
  return {
    addDossierLocation,
    updateDossierLocation,
    getSyntheseDossier,
    deleteDossierLocation,
    getDossiersLocation,
    getArchiveNetheos,
    getRapportAlgo,
    rouvrirDossierNetheos,
    calculLoyerMax,
    dupliquerDossier,
    accepterDossier,
    annulerAcceptationDossier,
    forcerStatutDossier,
    genererCertification,
  };
};

export default create;
