import React from 'react';
import Layout from '../../../components/Layout';
import Libelle from '../../../_i18n/fr.json';
import SituationProfessionnelleForm from './SituationProfessionnelleForm';
import Helmet from 'react-helmet';
import CheminDeFer from '../../../components/cheminDeFer/CheminDeFer';

class SituationProfessionnellePage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet title={Libelle.pageSituationPro.titre} />
        <Layout title={Libelle.pageSituationPro.titre}>
          <CheminDeFer isEtape="situationProfessionnelle" />
          <SituationProfessionnelleForm />
        </Layout>
      </React.Fragment>
    );
  }
}

export default SituationProfessionnellePage;
