import Axios, { AxiosRequestConfig } from 'axios';
import { getToken } from '../Authentification/authentification';
import createAdministrateursDeBiensApi, {
  IApi as IAdministrateursDeBiensApi,
} from './AdministrateursDeBiens';
import createBienApi, { IApi as IBienApi } from './Bien';
import createDossierLocationApi, {
  IApi as IDossierLocationApi,
} from './DossierLocation';
import createDossierParticulierApi, {
  IApi as IDossierParticulierApi,
} from './DossierParticulier';
import createLocalisationApi, {
  IApi as ILocalisationApi,
} from './Localisation';
import createNousContacterApi, {
  IApi as INousContacterApi,
} from './NousContacter';
import createPartenaireApi, {
  IApi as IPartenaireApi,
} from './Partenaire';
import createUtilisateursApi, {
  IApi as IUtilisateursApi,
} from './Utilisateurs';

/**
 * Création du client Axios
 */
const axiosClient = Axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  // tslint:disable: quotemark
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  // tslint:enable: quotemark
});

/**
 * Intercepteur Axios
 */
axiosClient.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    /**
     * Permet d'ajouter dans le header de nos requêtes le Bearer Token de Keycloak.
     */
    const token = getToken();
    if (token !== undefined && token !== null && token) {
      config.headers.common['Authorization'] = `Bearer ${getToken()}`;
    }
    // Do something before request is sent
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  },
);

/**
 * Crée un nouveau client d'API
 * @param endpoint
 */
const create = (endpoint: string): IApi => {
  return {
    ...createAdministrateursDeBiensApi(endpoint, axiosClient),
    ...createDossierParticulierApi(endpoint, axiosClient),
    ...createLocalisationApi(endpoint, axiosClient),
    ...createBienApi(endpoint, axiosClient),
    ...createUtilisateursApi(endpoint, axiosClient),
    ...createDossierLocationApi(endpoint, axiosClient),
    ...createNousContacterApi(endpoint, axiosClient),
    ...createPartenaireApi(endpoint, axiosClient),
  };
};

// Fusion des interfaces des API en une interface unique

export type IApi = IAdministrateursDeBiensApi &
  IUtilisateursApi &
  IDossierParticulierApi &
  IBienApi &
  ILocalisationApi &
  IDossierLocationApi &
  INousContacterApi &
  IPartenaireApi;

export default { create };
